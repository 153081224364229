import { observable, action, computed, override } from "mobx";
import {
  ListJsonI,
  ListItemsPropsI,
  ListPropsI,
  ListItemsI,
} from "./Type/List";
import { ItemI, ItemJsonI, ItemPropsI } from "./Type/Item";
import Model from "Stores/Model";
import { EntityModel } from "Entity/EntityModel";
import { ModelPropsI } from "Stores/Model/Type/Model";

const debug = require("debug")("treks:store:list");

/**
 * Generic List
 * @todo use mobx utilities
 */
export default class List extends Model {
  isList = true; // not available to model constructor

  static ERROR_ON_ITEM_DIRECT_PROP_ACCESS = true;
  static WARN_JSON_PROP_INVALID = false;

  static ignoredProps = ["visibleItems", "list", ...Model.ignoredProps];

  get ModelType() {
    return require("./Item").default;
  }

  constructor(items?: ListItemsPropsI<List>) {
    super();
    if (items) this.setItems(items);
  }

  /**
   * override Model.create so we don't add to EM
   */
  static create<T extends typeof Model>(
    this: T,
    observable: boolean = true,
    realtionships: boolean = true,
    entity: boolean = false,
  ): InstanceType<T> {
    return super.create(observable, realtionships, entity) as InstanceType<T>;
  }

  static fromProps<T extends typeof List>(
    this: T,
    props?: ListPropsI<InstanceType<T>>,
  ): InstanceType<T> {
    const model = this.create() as List;
    model.setProps(props);
    return model as InstanceType<T>;
  }

  static fromItems<T extends typeof List>(
    items: ListItemsPropsI<InstanceType<T>>[] = [],
  ) {
    const model = this.create();
    items && model.setItems(items);
    return model as InstanceType<T>;
  }

  /**
   * Create model - non-observable
   */
  createModel(props: ItemPropsI<this>, isJSON: boolean = false) {
    let model;
    if (props instanceof Model || props instanceof EntityModel) {
      model = props;
    } else {
      // allow overriding props
      props = this.createItemProps(props);
      model = this.ModelType.findModel(props) || this.ModelType.create(false);
      if (isJSON) {
        model.setJSON(props);
      } else {
        model.setProps(props);
      }
    }
    return model;
  }

  /**
   * All Item creation should go through createItem() or createItemFromJSON()
   */
  createItem<T extends Model>(
    props: ModelPropsI<T> = {},
    list = this,
    isJSON = false,
  ): T {
    const model = this.createModel(props, isJSON);
    const item = this.createListContext(model, list);
    item.makeObservable(); // observable requires context
    return item;
  }

  /**
   * Alternate creation from JSON.
   * @note Does not check props.
   */
  createItemFromJSON<T extends Model>(
    json: ItemJsonI<T> = {},
    list = this,
  ): T {
    return this.createItem(json, list, true);
  }

  /**
   * allows overriding props creation
   */
  createItemProps(props: ItemPropsI<this> = {}): ItemPropsI<this> {
    return props;
  }

  _items = observable([]);

  /**
   * @property {string[]} items
   */
  @computed get items(): InstanceType<this['ModelType']>[] {
    return this._items;
  }

  set items(_) {
    throw new Error("Use setItems() to prevent deleting this.items reference");
  }

  @computed get visibleItems(): any[] {
    return this.items.filter((item) => !item.trashed);
  }

  /**
   * Creates a context where Item.list returns list
   * @param {Item} model
   * @deprecated
   */
  createListContext<T extends Model>(model: T, list: List = this) {
    // item has implemented model
    if (model.model) {
      model.list = list;
      return model;
    }
    // @todo why ListItem fails on PFAScoreSnap
    //const item = new ListItem(model, list)
    const context = new Proxy(model, {
      get: function (_, prop, receiver) {
        if (prop === "__list" || prop === "list") return list;
        if (prop === "__hasContext") return true;
        if (prop === "__model" || prop === "model") {
          return model;
        }
        return Reflect.get(model, prop, receiver);
      },
    });
    return context; // return proxy
  }

  /**
   * Set the list props with special case for items
   * If props is an array, call setItems instead
   * @param {object} props
   */
  @override setProps(props: ListPropsI<this>, depth?: number) {
    if (!props) return;
    if (Array.isArray(props)) {
      return this.setItems(props);
    }
    const { items, ...nonItems } = props; // eslint-disable-line no-unused-vars
    Model.prototype.setProps.call(this, nonItems, depth);
    // set items after props in case list needs to pass same props to it's items
    if (props && props.items) {
      this.setItems(props.items);
    }
  }

  /**
   * Set items without removing reference to this.items
   */
  @action setItems(
    items: ListItemsPropsI<this> | ListItemsI<this> = [],
    list = this,
  ) {
    const models = items.map((model) =>
      this.createItem(model as ModelPropsI<this>, list),
    );
    this.items.splice(0, this.items.length, ...models);
  }

  /**
   * Does not check props when creating items
   */
  @action setItemsFromJSON(items) {
    debug("set items from JSON", { items });
    const models = items.map((props) => this.createItemFromJSON(props));
    this.setItems(models);
  }

  @action clearItems() {
    this.items.splice(0, this.items.length);
  }

  /**
   * Update existing items matching id|uid or create new items
   * @param {array} props
   */
  @action updateItems(propsList: ListItemsPropsI<this>, isJSON = false) {
    let items = [];
    propsList.forEach((props) => {
      let model: Model;
      if (typeof props.id !== "undefined") {
        model = this.getItemById(props.id as string);
      }
      if (!model && typeof props.uid !== "undefined") {
        model = this.getItemByUid(props.uid as string);
      }
      if (model) {
        if (isJSON) {
          model.setJSON(props);
        } else {
          model.setProps(props);
        }
      } else {
        items.push(props);
      }
    });
    if (items.length > 0) {
      this.addItems(items, isJSON);
    }
  }

  /**
   * Update an existing item matching id|uid or create new item
   * @param {object} props
   */
  @action updateItem(props: ItemPropsI<this>, isJSON = false) {
    let model: Model;
    if (typeof props.id !== "undefined") {
      model = this.getItemById(props.id as string);
    } else if (typeof props.uid !== "undefined") {
      model = this.getItemByUid(props.uid as string);
    }
    if (model) {
      if (isJSON) {
        model.setJSON(props);
      } else {
        model.setProps(props);
      }
    } else {
      this.addItem(this.createItem(props, this, isJSON))
    }
  }

  @action addItems(propsList: ListItemsPropsI<this>, isJSON = false) {
    const uniqueItems = propsList.filter((item) => !this.hasItem(item));
    const items = uniqueItems.map((props) => {
      return this.createItem(props, this, isJSON);
    });
    this.items.push(...items);
  }

  hasItem(model: ItemI) {
    return (
      this.items.includes(model) || (model.uid && this.getItemByUid(model.uid))
    );
  }

  @action addItem(props: ItemPropsI<this>, index?: number): ItemPropsI<this> {
    debug("adding item", props);
    const item = this.createItem(props);
    this.items.splice(index || this.items.length, 0, item);
    return item as ItemPropsI<this>;
  }

  @action addItemBefore(
    props: ItemPropsI<this>,
    item: ItemPropsI<this>,
  ): ItemPropsI<this> {
    return this.addItem(props, this.getItemIndex(item));
  }

  @action addItemAfter(
    props: ItemPropsI<this>,
    item: ItemPropsI<this>,
  ): ItemPropsI<this> {
    return this.addItem(props, this.getItemIndex(item) + 1);
  }

  /**
   * @returns The removed item or void if the item doesn't exist
   */
  @action removeItem(item: ItemPropsI<this>): ItemI | void {
    const index = this.getItemIndex(item);
    debug("removing item", item, index);
    if (index !== -1) {
      return this.items.splice(index, 1).pop();
    }
  }

  @action replaceItem(item: ItemPropsI<this>, newItem: ItemPropsI<this>) {
    const index = this.getItemIndex(item);
    debug("replacing item", item, index);
    if (index !== -1) {
      this.items.splice(index, 1, newItem);
    }
  }

  /**
   * Find first item matching either
   *  a) ModelPropsI - all object key => value pairs
   *  b) Function - query callback function
   */
  findItem<T extends Model>(query: ItemPropsI<this> | Function): ItemI {
    return this.items.find((item) => {
      if (typeof query === "function") return query(item);
      return !Object.entries(query).some(([key, value]) => item[key] !== value);
    });
  }

  getItemById(id: string): ItemI {
    if (typeof id === "undefined") {
      throw new TypeError("id parameter is required");
    }
    return this.getItemByIdString(id);
  }

  getItemByUid(uid: string): ItemI {
    if (typeof uid === "undefined") {
      throw new TypeError("uid parameter is required");
    }
    return this.items.find((item) => item.uid?.toString() === uid?.toString());
  }

  getItemByIndex(index: number): ItemI {
    return this.items[index];
  }

  getItemByIdString(id: string): ItemI {
    if (typeof id === "undefined") {
      throw new TypeError("id parameter is required");
    }
    return this.items.find(
      (item) => id && item?.id?.toString() === id.toString(),
    );
  }

  getFirstItem(): ItemI {
    return this.items.length && this.items[0];
  }

  getLastItem(): ItemI {
    return this.items.slice(-1).pop();
  }

  getItemIndex(model: ItemPropsI<this>): number {
    const index = this.items.indexOf(model);
    if (index !== -1) return index; // faster
    // item may be a proxy
    return this.items.findIndex((item) => item.uid === model.uid);
  }

  getPrevIte(item: ItemPropsI<this>): ItemPropsI<this> {
    const { items } = this;
    const index = this.getItemIndex(item);
    if (index !== -1 && index > 0) {
      return items[index - 1];
    }
  }

  getNextItem(item: ItemPropsI<this>): ItemPropsI<this> {
    const { items } = this;
    const index = this.getItemIndex(item);
    if (index !== -1 && index < items.length - 1) {
      return items[index + 1];
    }
  }

  getUids(): string[] {
    return this.items.map((item) => item.uid);
  }

  getIds(): string[] {
    return this.items.map((item) => item.id);
  }

  propsToJSON() {
    const json = Model.prototype.toJSON.call(this);
    delete json.uid;
    delete json.id;
    return json;
  }

  toJSON<T extends Model>(): ListJsonI<this> {
    const json = this.propsToJSON();
    const items = this.items.map((item) => item.toJSON());
    return { ...json, items };
  }

  async asyncToJSON<T extends Model>(): Promise<ListJsonI<this>> {
    const json = this.propsToJSON();
    const items = await Promise.all(
      this.items.map((item) => item.asyncToJSON()),
    );
    return { ...json, items } as ListJsonI<this>;
  }

  toJSONRef<T extends Model>(): ListJsonI<this> {
    const items = this.items.map((item) => item.toJSONRef());
    const json = { items };
    debug("toJSONRef", { model: this, json });
    return json as ListJsonI<this>;
  }

  updateItemFromJSON(json: ItemJsonI<this>) {
    this.updateItem(json, true)
  }

  addItemFromJSON(json: ItemJsonI<this>) {
    this.updateItem(json, true)
  }

  addItemsFromJSON(items: ItemJsonI<this>[]) {
    const models = items?.map((props) => this.createItemFromJSON(props));
    this.updateItems(models, true);
  }

  @override fromJSON(json: ListJsonI<this> = {}) {
    if (json && json.items) {
      const models = json.items.map((props) => this.createItemFromJSON(props));
      this.setItems(models);
    }
    if (json) {
      Object.entries(json).forEach(([name, value]) => {
        if (name === "items") return;
        try {
          this.setProp(name as keyof Model, value);
        } catch (error) {
          if (List.WARN_JSON_PROP_INVALID) {
            console.warn("List.fromJSON: Error setting prop", {
              self: this,
              name,
              value,
            });
          }
        }
      });
    }
    return this;
  }

  /**
   * @important Use model.fromJSON unless you want to ensure all network requests complete
   * @note model.fromJSON suffices since models are observed and UI updated when network requests complete
   * @param {string} data
   */
  @override async asyncFromJSON(data: ListJsonI<this> = {}) {
    this.setItems([]);
    if (data && data.items) {
      const models = await Promise.all(
        data.items.map((props) => this.createItemFromJSON(props)),
      );
      this.setItems(models);
    }
    if (data) {
      Object.entries(data).forEach(([name, value]) => {
        if (name === "items") return;
        try {
          this.setProp(name as keyof Model, value);
        } catch (error) {
          console.warn("List.fromJSON: Error setting prop", {
            self: this,
            name,
            value,
          });
        }
      });
    }
    return this;
  }
}
