import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import CalendarRow from './CalendarRow'
import Icon from '../../../theme/Icon'
import Pressable from '../../../theme/Pressable';
import { when } from 'mobx';
import { stores } from 'Stores'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import { CalendarAccountList, CalendarAccount } from 'Stores/Calendar'
import { TimeSpanListDaySettings } from 'Stores/ActionPlanner/TimeSpan/Settings'

type CalendarAccountRowProps = {
  isOpen?: boolean;
  list: CalendarAccountList;
  account: CalendarAccount;
  timeSpanList: TimeSpanListDaySettings;
}

class CalendarAccountRow extends React.Component<CalendarAccountRowProps> {

  itemRef = null

  static defaultProps = {
    isOpen: true,
  }

  uiState = ArbitraryModel.fromProps({
    isOpen: false,
    isReady: false
  })

  static propTypes = {
    timeSpanList: PropTypes.objectOf(() => stores.TimeSpanList).isRequired,
  }

  componentDidMount() {
    when(
      () => this.props.account.fetchState.isFetched, 
      () => setTimeout(() => {
        this.uiState.setState({
          isOpen: this.props.isOpen,
          isReady: true
        })
      }, 300)
    )
  }

  onCalendarItemRef = ref => this.itemRef = ref

  onClickDropdownBtn = event => {
    event.preventDefault()
    if (!this.uiState.isReady) {
      return // wait for ui to load
    }
    if (this.uiState.isOpen) {
      Object.assign(this.itemRef.style, {
        height: this.itemRef.scrollHeight + 'px',
        overflow: 'hidden' 
      })
    }
    setTimeout(() => this.uiState.isOpen = !this.uiState.isOpen, 50)
  }

  render() {
    const { account, timeSpanList } = this.props
    const { service, email, fetchState } = account
    const { isOpen } = this.uiState
    const opacity = isOpen ? 1 : 0
    const height = isOpen ? (fetchState.isFetched ? this.itemRef.scrollHeight : 'auto') : 0
    // handle browsers not giving scrollHeight
    if (isOpen) {
      setTimeout(() => {
        Object.assign(this.itemRef.style, { height: 'auto', overflow: 'visible' })
      }, 300)
    }
    return (
      <div className="calendar-account">
        <header>
          <Pressable
            onClick={this.onClickDropdownBtn} 
            className={'btn-calendar-dropdown' + (isOpen ? ' open' : ' closed')}
          >
            <h5 className="calendar-title">{service} - {email}</h5>
            <Icon
              set="fa"
              icon="chevron-right"
              size="xs"
              color={'#808284'}
              className="icon-calendar-dropdown"
            />
          </Pressable>
        </header>
        <div
          ref={this.onCalendarItemRef}
          className="calendar-item"
          style={{ opacity, height, overflow: 'hidden' }}
        >
          <table>
            <thead>
              <tr>
                <td>Calendar Name</td>
                <td>Category</td>
                <td>Action Planner</td>
              </tr>
            </thead>
            <tbody>
              {
                account.items.map(calendar => (
                  <CalendarRow 
                    key={calendar.id} 
                    account={account} 
                    calendar={calendar} 
                    timeSpanList={timeSpanList}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default observer(CalendarAccountRow)