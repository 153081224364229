import React from 'react'
import './Tooltip/Tooltip.scss'

export default class Tooltip extends React.Component {

  render() {
    const {
      tooltipStyle,
      text,
      placement,
      children,
      show,
      onRef,
      className = '',
      onMouseEnter,
      onMouseLeave
    } = this.props
    const showClass = show === false ? '' : ' show' // backward compat

    return (
      <div 
        ref={ref => onRef && onRef(ref)}
        className={
          'tooltip bs-tooltip-' 
          + placement 
          + showClass 
          + className
          }
        x-placement={placement}
        style={tooltipStyle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="arrow"></div>
        <div className="tooltip-inner">{text || children}</div>
      </div>
    )
  }
}