import { observable, action, override } from 'mobx'
import Model from '../Model'
import BillingApiRequest from '../Service/BillingApiRequest';

const debug = require('debug')('treks:store:account:billing')

/**
 * Recurly billing info
 * @see https://dev.recurly.com/docs
 * @see https://github.com/umayr/recurly-js
 */
export default class BillingInfo extends Model {

  /**
   * @property {string}
   */
  @observable first_name = null

  /**
   * @property {string}
   */
  @observable last_name = null

  /**
   * @property {string}
   */
  @observable company = null

  /**
   * @property {string}
   */
  @observable address1 = null

  /**
   * @property {string}
   */
  @observable address2 = null

  /**
   * @property {string}
   */
  @observable city = null

  /**
   * @property {string}
   */
  @observable state = null

  /**
   * @property {string}
   */
  @observable zip = null

  /**
   * @property {string}
   */
  @observable country = null

  /**
   * @property {string}
   */
  @observable phone = null

  /**
   * @property {string}
   */
  @observable vat_number = null

  /**
   * @property {string}
   */
  @observable ip_address = null

  /**
   * @property {string}
   */
  @observable ip_address_country = null

  /**
   * @property {string}
   */
  @observable card_type = null

  /**
   * @property {string}
   */
  @observable number = null

  /**
   * @property {string}
   */
  @observable year = null

  /**
   * @property {string}
   */
  @observable month = null

  /**
   * @property {string}
   */
  @observable first_six = null

  /**
   * @property {string}
   */
  @observable last_four = null

  constructor() {
    super()
    this.fetchState = new BillingApiRequest()
  }

  /**
   * Fetch the billing info
   */
  @override async fetch() {
    debug('fetch billing info')
    return this.fetchState.get('account/billing')
      .then(resp => {
        debug('resp', resp)
        Object.assign(this, resp.billing_info)
      })
  }

  /**
   * Create the billing info
   */
  @action async create() {
    const info = this.getCreateBillingInfoValues()
    debug('create billing info', { info })
    return this.fetchState.post('account/billing/create', info)
      .then(resp => {
        debug('resp', resp)
        Object.assign(this, resp.billing_info)
      })
  }

  /**
   * Values for Create billing info request
   */
  getCreateBillingInfoValues() {
    const {
      first_name,
      last_name,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      phone,
      ip_address,
      number,
      year,
      month,
    } = this
    return {
      first_name,
      last_name,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      phone,
      ip_address,
      number,
      year,
      month,
    }
  }

  @action setFromPaymentFormFieldValues(values) {
    const { month, year, cvv } = values.card_meta
    delete values.card_meta
    values = { ...values, month, year, cvv  }
    Object.assign(this, values)
  }

}
