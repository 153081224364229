import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:project:assignments:item:input')

class Input extends React.Component {

  getTextWidth(text, font) {
    var canvas = this.canvas || (this.canvas = document.createElement('canvas'))
    var context = canvas.getContext("2d")
    context.font = font
    var metrics = context.measureText(text)
    return metrics.width
  }

  onChangeStatus = event => {
    this.props.onChangeStatus(this.props.assignment, event.target.value)
  }

  render() {
    const { assignment } = this.props
    const width = this.getTextWidth(assignment.status, '17px "Myriad Pro"')
    debug('render input', { width, status: assignment.status })
    return (
      <input
        className="field field-status"
        value={assignment.status}
        style={{ width }}
        onChange={this.onChangeStatus}
      />
    )
  }
}

export default observer(Input)