import React from 'react'
import { observer } from 'mobx-react'
import { stores, getStore } from 'Stores'
import Calendar, { CalendarAccountList } from 'Stores/Calendar'
import { TimeSpanListDaySettings } from 'Stores/ActionPlanner/TimeSpan/Settings'
import { getAppNotifications } from 'AppNotifications'
import Pressable from 'theme/Pressable'
import { LucideSquare, LucideSquareCheck, LucideSquareCheckBig } from 'lucide-react'

const debug = require('debug')('treks:component:calendar:accounts')

type CalendarsSidePanelProps = {
  getCalendarAccountList: () => CalendarAccountList;
  getTimeSpanList: () => TimeSpanListDaySettings;
}

class CalendarsSidePanel extends React.Component<CalendarsSidePanelProps> {

  static defaultProps = {
    getCalendarAccountList: () => getStore(stores.CalendarAccountList),
    getTimeSpanList: () => getStore(stores.ActionPlanner).dayTimespans
  }

  componentDidMount() {
    this.props.getCalendarAccountList().fetch()
      .catch(error => getAppNotifications().error(error.message))
  }

  onClickShowOnCalendarView = (calendar: Calendar) => {
    const showOnCalendarView = !calendar.showOnCalendarView
    calendar.setProps({ showOnCalendarView })
  }

  renderCalendar(calendar: Calendar) {
    return (
      <div className='calendar'>
        <div className='calendar-color'>
          <div className='calendar-color-box' style={{ backgroundColor: calendar.foregroundColor }}></div>
        </div>
        <div className='calendar-check'>
          <Pressable onClick={() => this.onClickShowOnCalendarView(calendar)}>
            {
              calendar.showOnCalendarView ? (
                <LucideSquareCheckBig size={20} />
              ) : (
                <LucideSquare size={20} />
              )
            }
          </Pressable>
        </div>
        <div className='calendar-title'>{calendar.title}</div>
      </div>
    )
  }

  render() {
    const { getCalendarAccountList } = this.props
    const calendarAccountList = getCalendarAccountList()
    const calendars = calendarAccountList.items.map(account => account.items).flat()
    const calendarsManual = calendars.filter(calendar => calendar.actionPlanner === 'manual')
    const calendarsAuto = calendars.filter(calendar => calendar.actionPlanner === 'automatic')

    debug('render', { calendarAccountList })
    return (
      <div className="calendars-side-panel">
        <h3 className='panel-label'>Calendars</h3>


        <h3 className='list-label label-auto'>Automatic</h3>
        <div className='calendar-list'>
          {
            calendarsAuto.map(calendar => (
              this.renderCalendar(calendar)
            ))
          }
        </div>

        <h3 className='list-label label-manual'>Manual</h3>
        <div className='calendar-list'>
          {
            calendarsManual.map(calendar => (
              this.renderCalendar(calendar)
            ))
          }
        </div>
      </div>
    )
  }
}

export default observer(CalendarsSidePanel)