import { override } from 'mobx'
import TaskItem from 'Stores/Task';
import { stores } from 'Stores';
import { hasOne } from 'Relationships/RelationshipDecorators';
import { TaskLabel } from './TaskLabel';
import List from 'Stores/Lists';
import { LabelGroupTaskList } from './LabelGroupTaskList';

export class TaskLabelList extends List {

  get ModelType(): TaskLabel {
    return stores.TaskLabel
  }

  @hasOne(() => TaskItem, (task: TaskItem) => task.taskLabelList)
  task: TaskItem

  @hasOne(() => LabelGroupTaskList, (taskList: LabelGroupTaskList) => taskList.taskLabelList)
  taskList: LabelGroupTaskList

  @override async fetch() {
    return this.fetchState.get('task/label/list', { taskId: this.task.id })
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}