import React from 'react'
import PlannerSettingsItem from './PlannerSettingsItem'
import Tooltip from 'theme/Tooltip'
import { observer } from 'mobx-react'
import Resizable from 'Modules/Resizable'
import stores, { getStore } from 'Stores'
import TimespanContextMenu from '../ActionPlannerTimespan/TimespanContextMenu'
import { observable } from 'mobx'
import { formatDateToTime } from 'lib/utils/DateUtils'
import { round } from 'Stores/utils'

const debug = require('debug')('treks:planner:settings:timespan')

class PlannerSettingsTimespan extends React.Component {

  uiState = observable({
    shiftKey: false,
    showContextMenu: false,
    contextMenuPos: {}
  })

  static defaultProps = {
    resizeNextTimespan: true,
    constrainTimespans: true,
    get actionPlannerStore() {
      return getStore(stores.ActionPlanner)
    }
  }

  resizeState = {
    lastDuration: null,
    startDuration: null
  }

  handleKeyDown = event => {
    this.uiState.shiftKey = event.shiftKey
  }

  handleKeyUp = event => {
    this.uiState.shiftKey = event.shiftKey
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
    this.focusIfIsCreating()

    // debug
    global.piece = this.props.plannerFlow.timespanFlow.find(t => t.type === 'personal')
    if (!global.day) global.day = this.props.day
  }

  focusIfIsCreating() {
    const { plannerSettings, timespanPiece, day } = this.props
    if (timespanPiece.isCreating) {
      plannerSettings.setFocusedTimespanPiece(timespanPiece, day)
    }
  }

  componentDidUnMount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  onResize = ({ height }) => {
    const sticky = this.uiState.shiftKey
    const { timespanPiece, plannerUtils, plannerFlow } = this.props
    const { lastDuration } = this.resizeState
    const heightDuration = plannerUtils.getDurationFromHeight(height)
    const deltaDuration = heightDuration - lastDuration
    const lastPiece = plannerFlow.getLastTimespanPiece(timespanPiece)
    const lastPieceEndDuration = lastPiece.endDuration
    const nextLastPieceEndDuration = lastPieceEndDuration + deltaDuration
    const nextDeltaDuration = round(nextLastPieceEndDuration, sticky ? 5 : 1) - lastPieceEndDuration
    const appliedDelta = plannerFlow.resizeTimespanPiece(timespanPiece, nextDeltaDuration, true)
    const nextDuration = lastDuration + appliedDelta
    this.resizeState.lastDuration = nextDuration
    debug('resize', { heightDuration, lastDuration, deltaDuration, nextDeltaDuration, appliedDelta, nextDuration })
  }

  onResizeStart = () => {
    const { timespan, onResizeStart, timespanPiece } = this.props
    debug('resize start', { timespan })
    document.body.style.cursor = 'row-resize'
    timespanPiece.isResizing = true
    this.resizeState.startDuration = timespanPiece.duration
    this.resizeState.lastDuration = timespanPiece.duration
    onResizeStart && onResizeStart({ timespanPiece, timespan })
  }

  onResizeEnd = () => {
    const { timespan, onResizeStop, timespanPiece } = this.props
    debug('resize stop', { timespan })
    document.body.style.cursor = ''
    timespanPiece.isResizing = false
    this.props.plannerFlow.roundTimespans()
    onResizeStop && onResizeStop({ timespanPiece, timespan })
  }

  onClick = () => {
    const { onClick, timespan, timespanPiece } = this.props
    onClick && onClick({ timespan, timespanPiece })
  }

  onShowContextMenu = event => {
    const { timespan, actionPlannerStore } = this.props
    actionPlannerStore.setFocusedTimespan(timespan)
    if (localStorage.noContextMenu) return // disable in dev
    event.preventDefault()
    event.stopPropagation()
    const { clientX, clientY } = event.nativeEvent
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.addEventListener('scroll', this.onHideContextMenu)
    Object.assign(this.uiState, { 
      showContextMenu: true, 
      contextMenuPos: { left: clientX, top: clientY }
    })
    debug('show context menu', timespan, this.uiState)
  }

  onHideContextMenu = () => {
    Object.assign(this.uiState, { 
      showContextMenu: false, 
      contextMenuPos: {}
    })
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.removeEventListener('scroll', this.onHideContextMenu)
  }

  onCreateTimespan = () => {
    this.props.day.saveDaySettings()
  }

  onDeleteTimespan = () => {
    this.props.day.saveDaySettings()
  }

  render() {
    const { timespan, timespanPiece, plannerSettings, day, plannerUtils, plannerFlow } = this.props
    const { showContextMenu, contextMenuPos } = this.uiState
    const { focusedTimespanPiece } = plannerSettings
    const { totalDurationEmpty } = day
    const { uid, duration, type } = timespanPiece
    const height = plannerUtils.getHeightFromDuration(duration)
    const maxHeight = plannerUtils.getHeightFromDuration(duration + totalDurationEmpty)
    const size = { width: '100%', height }
    const isActiveClassName = (focusedTimespanPiece === timespanPiece)  ? ' active' : ''
    const typeClassName = ' type-' + type
    const tooltipStyle = { 
      left: 'calc( 50% - 40px )',
      bottom: 5,
      opacity: 0.5
    }
    debug('render timespan', { day, timespan, timespanPiece, focusedTimespanPiece, height, duration, maxHeight })
    return (
      <Resizable
        key={uid}
        data-id={uid}
        style={{ ...size }} 
        className={"action-planner-pane" + isActiveClassName + typeClassName}
        onContextMenu={this.onShowContextMenu}
        resizeHandleClassName={'action-planner-settings-resize-handle'}
        resize={'vertical'}
        onResize={ this.onResize}
        onResizeStart={this.onResizeStart}
        onResizeEnd={this.onResizeEnd}
        onMouseDown={this.onClick}
      >
        <PlannerSettingsItem timespan={timespan} day={day} />
        {
          timespanPiece.isResizing && (
            <Tooltip placement="top" tooltipStyle={tooltipStyle}>
              {
                formatDateToTime(timespanPiece.endDate)
              }
            </Tooltip>
          )
        }

        {/* <Tooltip placement="right" tooltipStyle={{
          ...tooltipStyle,
          left: 'calc( 100% + 5px )',
        }}>
          {
            timespanPiece.duration
          }
        </Tooltip> */}

        {
          showContextMenu && (
            <TimespanContextMenu
              contextMenuPos={contextMenuPos}
              plannerFlow={plannerFlow}
              timespan={timespan}
              timespanPiece={timespanPiece}
              onHideContextMenu={this.onHideContextMenu}
              onCreateTimespan={this.onCreateTimespan}
              onDeleteTimespan={this.onDeleteTimespan}
            />
          )
        }
      </Resizable>
    )
  }
  
}

export default observer(PlannerSettingsTimespan)