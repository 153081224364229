import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import TaskFileDetail from '../TaskFileDetail'

class FileItem extends React.Component {

  ui = observable({
    showFileDetail: false
  })

  static defaultProps = {
    fileUploadingIcon: 'spinner'
  }

  onClick = event => {
    event.preventDefault()
    this.ui.showFileDetail = true
  }

  onpenFileInNewWindow(file) {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(file)
    link.download = file.name
    link.click()
  }

  openBlobInNewWindow(blob) {
    const type = 'application/octet-stream'
    const fileBlob = blob.type ? blob : new Blob([blob], { type })
    this.onpenFileInNewWindow(fileBlob)
  }

  onCloseModal = () => {
    this.ui.showFileDetail = false
  }

  render() {
    const { fileUploadingIcon, fileItem } = this.props
    const { id, name, isRemote, isUploaded, type } = fileItem
    const fileIsUploaded = !isRemote && !isUploaded
    return (
      <>
        <a className="file-item" onClick={this.onClick} href={'/file/' + id}>
          <Icon 
            set="file" 
            icon={type ? type : 'file'} 
            size={16}
            color={'#808284'} 
          />
          <span className="file-name">{name}</span>
          {
            fileIsUploaded && (
              <Icon
                set="fa"
                icon={fileUploadingIcon}
                size="xs"
                color={'#808284'}
                className={'modal-download-icon ' + (isUploaded ? 'file-uploaded' : 'file-uploading')}
              />
            )
          }
        </a>
        <TaskFileDetail
          fileItem={this.props.fileItem}
          show={this.ui.showFileDetail}
          onClose={this.onCloseModal}
        />
      </>
    )
  }
}

export default observer(FileItem)