import { observable, computed, action, when, makeObservable } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'
import { JsonAny } from 'Stores/Model/Type/Json';

const debug = require('debug')('treks:stores:service:request')

export type PromiseStates = '' | 'pending' | 'fulfilled' | 'rejected';
export type PromiseValue = JsonAny;

export interface PromiseBasedObservable {
  value: PromiseValue;
  state: PromiseStates;
}

/**
 * Wraps a Request Promise with state
 */
export class RequestState {

  static initialReqState:PromiseBasedObservable = { value: null, state: null }

  constructor() {
    makeObservable(this)
  }

  @observable request:PromiseBasedObservable = RequestState.initialReqState

  @computed get isBusy(): boolean {
    return this.state === 'pending'
  }


  @computed get isFetched(): boolean {
    return ['rejected', 'fulfilled'].includes(this.state)
  }

  async whenFetched(): Promise<void> {
    return new Promise(resolve => {
      if (this.isFetched) return resolve()
      when(() => this.isFetched, () => resolve())
    })
  }

  /**
   * Fetch wasinitiated, either complete or in progress
   */
  @computed get wasFetched(): boolean {
    return this.isBusy || this.isFetched
  }

  @computed get isSuccess(): boolean {
    return this.state === 'fulfilled'
  }

  @computed get state(): PromiseStates {
    debug('get state', this.request)
    return this.request?.state
  }

  @computed get value(): PromiseValue {
    debug('get value', this.request)
    return this.request?.value
  }

  @computed get error(): PromiseValue {
    debug('get error', this.request)
    return this.request?.state === 'rejected' && this.request.value
  }

  @action resetRequest = <T,>(): IPromiseBasedObservable<T> => {
    Object.assign(this.request, RequestState.initialReqState)
    return this.request as IPromiseBasedObservable<T>
  }

  @action setRequest = <T,>(request: any): IPromiseBasedObservable<T> => {
    this.request = this.createRequest(request)
    return this.request as IPromiseBasedObservable<T>
  }

  @action createRequest = <T,>(request: any): IPromiseBasedObservable<T> => {
    return fromPromise(Promise.resolve(request))
  }
  
}