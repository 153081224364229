import React from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import Dropdown from 'react-bootstrap/Dropdown';
import FormControlInput from '../../theme/Form/FormControlInput';
import Row from 'react-bootstrap/Row';
import Button from '../../theme/Button';
import { getAppNotifications } from '../../AppNotifications';
import Form from 'react-bootstrap/Form';
import FormStore, { createValidator } from 'Stores/Form'

const debug = require('debug')('treks:task:AddUserDropdown')

class AddUserDropdown extends React.Component {

  ref = null

  static defaultProps = {
    headerLabel: 'Create a new user',
    btnSubmitVariant: 'primary',
    btnSubmitLabel: 'Assign User',
    formErrorMessage: 'Please fix the errors in the user details',
    form: FormStore.fromFields([
      {
        name: 'name',
        validate: createValidator(
          name => name && name.length > 3,
          new Error('Please enter a valid name of more than 3 characters')
        )
      },
      {
        name: 'email',
        validate: createValidator(
          email => email && email.match(/.+@.+\..+/g), 
          new Error('Please enter a valid email address for the user'),
        ),
        submitOnEnter: true,
        focus: true
      }
    ])
  }

  componentDidMount() {
    document.body.addEventListener('click', this.onDocumentClick)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onDocumentClick)
  }

  onDocumentClick = event => {
    const domRef = ReactDOM.findDOMNode(this.ref)
    if (!this.isNodeChildOf(event.target, domRef)) {
      this.props.onHide()
    }
  }

  isNodeChildOf(domNode, parentNode) {
    while(domNode && domNode !== parentNode) {
      domNode = domNode.parentNode
    }
    return domNode === parentNode
  }

  onFieldChange = ({ name }, value) => {
    debug('onFieldChange', { name, value })
    this.props.store.set(name, value)
  }

  onFieldKeyPress = (field, event) => {
    if (event.nativeEvent.key === 'Enter') {
      field.submitOnEnter && this.onSubmit()
    }
  }

  onSubmit = () => {
    const { name, email } = this.props.store
    const { form, formErrorMessage } = this.props
    form.setFieldValues({ name, email })
    form.validateFields()
    if (form.hasErrors()) {
      // @todo close notification when no error and it's open
      return getAppNotifications().error(formErrorMessage)
    }
    this.props.onAddUser()
  }

  render() {
    const { btnSubmitVariant, btnSubmitLabel, headerLabel, store, form } = this.props
    debug('render', this.props)
    return (
      <Dropdown.Menu show className="task-member-add-dropdown" ref={ref => this.ref = ref}>
        <Dropdown.Header>{headerLabel}</Dropdown.Header>
        {
          form.fields.map(field => {
            return (
              <Row key={field.name}>
                <FormControlInput
                  placeholder={field.name}
                  value={store[field.name]}
                  onChange={value => this.onFieldChange(field, value)}
                  focus={field.focus}
                  onKeyPress={event => this.onFieldKeyPress(field, event)}
                />
                {
                  field.hasErrors && (
                    field.errors.map(({ message }) => (
                      <Form.Control.Feedback type="invalid" key={message}>
                        {message}
                      </Form.Control.Feedback>
                    ))
                  )
                }
              </Row>
            )
          })
        }
        <Row>
          <Button onClick={this.onSubmit} variant={btnSubmitVariant}>{btnSubmitLabel}</Button>
        </Row>
      </Dropdown.Menu>
    )
  }
}

export default observer(AddUserDropdown)