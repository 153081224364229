import React from 'react'
import PropTypes from 'prop-types'
import Select from 'theme/Form/Select'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'

class FieldWithinRange extends React.Component {

  static propTypes = {
    options: PropTypes.object,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    options: {
      range: [],
      unit: []
    }
  }

  @observable values

  defaultValues = { 
    label: '',
    range: '',
    value: '',
    unit: ''
  }

  constructor() {
    super()
    if (!this.values) {
      this.values = this.defaultValues
    }
    makeObservable(this)
  }

  getLabel(values) {
    const { range, value, unit } = values
    const rangeLabel = range && range.label
    const unitLabel = unit && unit.label.toLowerCase()
    const label = `${rangeLabel} ${value} ${unitLabel}`
    return label
  }

  componentDidMount() {
    this.values = this.props.value || this.defaultValues
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.values = this.props.value || this.defaultValues
    }
  }

  onChangeRange = range => this.onChange({ range })

  onChangeValue = ({ target: { value } }) => {
    this.onChange({ value })
  }

  onChangeUnit = unit => this.onChange({ unit })

  @action onChange = value => {
    const values = { ...this.values, ...value }
    const label = this.getLabel(values)
    this.values = { ...values, label }
    this.props.onChange(this.values)
  }

  render() {
    const { options, className } = this.props
    return (
      <div className={'filter-within-list ' + className}>
        <Select
          name="range"
          className="react-select filter-within-item"
          options={options.range}
          onChange={this.onChangeRange}
          value={this.values.range}
        />
        <input
          name="value"
          className="filter-within-item"
          onChange={this.onChangeValue}
          value={this.values.value}
          type="number"
        />
        <Select
          name="unit"
          className="react-select filter-within-item"
          options={options.unit}
          onChange={this.onChangeUnit}
          value={this.values.unit}
        />
      </div>
     )
  }
}

export default observer(FieldWithinRange)