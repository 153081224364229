import React from 'react'
import { observer } from 'mobx-react'
import Select from '../../theme/Form/Select'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import AddUserDropdown from '../ui/AddUserDropdown';
import { UserList } from 'Stores/User';
import PropTypes from 'prop-types'
import TaskItem from 'Stores/Task';

const debug = require('debug')('treks:task:assign')

class TaskAssign extends React.Component {

  static propTypes = {
    userList: PropTypes.objectOf(() => UserList).isRequired,
    item: PropTypes.objectOf(() => TaskItem).isRequired
  }

  static defaultProps = {
    taskAssignPlaceholder: 'Assign Task...',
    addMemberPlaceholder: 'Add Members',
  }

  store = ArbitraryModel.fromProps({
    isAddingUser: false,
    name: null,
    email: null
  })

  onAddUser = async () => {
    const { name, email } = this.store
    const { item, userList } = this.props
    const newUser = await userList.addItem({ name, email }).save()
    item.setAssignedUser(newUser)
    item.save()
    this.store.setState({ name: null, email: null, isAddingUser: false })
    debug('onCreateOption', { item, newUser })
  }

  onCreateOption = name => {
    this.store.setState({ name, isAddingUser: true })
  }

  onChange = option => {
    const { item, userList } = this.props
    item.setAssignedUser(option && userList.getItemById(option.value))
    item.save()
    debug('set item user', item)
  }

  onHideAddUser = () => {
    this.store.setState({ isAddingUser: false })
  }

  render() {
    const { item, userList, taskAssignPlaceholder } = this.props
    const { isAddingUser } = this.store
    const { assignedUser } = item
    const options = userList.items
      .map(({ name, id }) => ({ 
        label: name, 
        value: id 
      }))
    const value = assignedUser 
      ? options.find(({value}) => value === assignedUser.id) 
      : null
    debug('render', { item, assignedUser, isAddingUser, options, value })
    return (
      <div className="task-member-assign">
        <Select 
          isClearable
          hasAddOption
          createOptionPosition={'first'}
          placeholder={taskAssignPlaceholder}
          onChange={this.onChange}
          onCreateOption={this.onCreateOption}
          options={options} 
          value={value}
        />
        {
          isAddingUser && (
            <AddUserDropdown
              store={this.store}
              onHide={this.onHideAddUser}
              onAddUser={this.onAddUser}
            />
          )
        }
      </div>
    )
  }
}

export default observer(TaskAssign)