import React from 'react'
import { observer } from 'mobx-react'
import GoalDataValue from './GoalDataValue';
import Pressable from 'theme/Pressable';
import Icon from 'theme/Icon';

const BtnDelete = ({ onClick }) => (
  <Pressable
    className="btn-add"
    onClick={onClick}
  >
    <Icon icon={'trash'} />
  </Pressable>
)

class GoalDataItem extends React.Component {

  onDelete = () => {
    this.props.item.trashed = true
  }

  render() {
    const { item } = this.props
    return (
      <tr>
        <td>{item.createdTSFormatted}</td>
        <td>
          <GoalDataValue item={item} />
        </td>
        <td>{item.units}</td>
        <td>
          <BtnDelete onClick={this.onDelete} />
        </td>
      </tr>
    )
  }
}

export default observer(GoalDataItem)
