export const whenIdle = global.requestAnimationFrame || global.setImmediate || setTimeout

/**
 * Triggers a {cb} callback once {fn} condition is true
 * @example
 * whenCondition(
    () => document.querySelector('#app'), 
    document.querySelector('#app').scrollIntoView()
   )
 */
export const whenCondition = (fn:Function, cb:Function) => {
  whenIdle(() => {
    if (fn()) return cb()
    whenCondition(fn, cb)
  })
}