import React from 'react'
import { observer } from 'mobx-react';
import SingleValue from './SingleValue'
import Pressable from '../../Pressable';
import "./SelectPressable.scss"

class PressableSinglevalue extends React.Component {

  render() {
    const { childProps, className, ...props } = this.props
    return (
      <Pressable
        {...props}
        className={"select-pressable " + className}
      >
        <SingleValue {...childProps}></SingleValue>
      </Pressable>
    )
  }
}



export default observer(PressableSinglevalue)