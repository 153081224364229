import React from 'react'
import { observer } from 'mobx-react'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import Button from '../../theme/Button'
import Icon from '../../theme/Icon'
import { AccountSettings, AccountSettingsMenu } from '../../Components/Account/Settings'
import { withRouter } from 'react-router-dom'

export default withRouter(observer(class AccountSettingsModal extends React.Component {

  modalStore = ModalStore.fromProps({
    title: 'MY ACCOUNT',
    icon: 'my_account',
    show: true,
  })

  handleClose = () => {
    this.modalStore.setState({ show: false })
    this.props.history.push('/planner')
  }

  render() {
    const { show, title, icon } = this.modalStore
    return (
      <Modal 
          show={show} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="settings-modal modal-size-80"
        >
        <Modal.Header>
            <Modal.Title>
              <Icon icon={icon} className="modal-title-icon" />
              {title}
            </Modal.Title>
            <Button variant="outline" onClick={this.handleClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <AccountSettingsMenu />
            <AccountSettings />
          </Modal.Body>
        </Modal>
    )
  }
}))