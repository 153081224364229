import React from 'react';
import { observer } from 'mobx-react';

const debug = require('debug')('treks:habit:title');

class HabitCustomFrequency extends React.Component {
    inputRef = null;

    constructor(props) {
        super(props);
        const { habit } = props;
        debug('component created', habit);
        this.state = {
            selected: {
                Su: false,
                Mo: false,
                Tu: false,
                We: false,
                Th: false,
                Fr: false,
                Sa: false,
            },
        };
    }

    onChange = (ref) => {
        const {selected} = this.state 
        selected[ref] = !selected[ref]
        this.setState(selected)
    };



    render() {
        const { habit } = this.props;
        const { selected } = this.state;
        const options = Object.keys(selected);
        let selectedArray = Object.keys(selected).filter((v) => selected[v] === true);
        console.log('render custom freq', habit, selectedArray);
        return (
            <>
                {options.map((s) => (
                    <div key={s} onClick={() => this.onChange(s)} className={'day-of-week ' + (selectedArray.indexOf(s) > -1 ? 'selected': '')}>
                        {s.substr(0,1)}
                    </div>
                ))}
            </>
        );
    }
}

export default observer(HabitCustomFrequency)
