import { getTimespansToTrackStore } from "Containers/PFA/stores/PFATimespansToTrackStore";
import { computed, IComputedValue } from "mobx";
import { TimeSpanItem } from "Stores/ActionPlanner/TimeSpan";

type Option = {
  label: string;
  value: string;
};

export const timespansToTrackOptions: IComputedValue<Option[]> = computed(() =>
  getTimespansToTrackStore().allTimespans.map((timespan: TimeSpanItem) => {
    return {
      value: timespan.type,
      label: timespan.title,
    };
  })
);

export const getTimespansToTrackOptionsStore = () =>
  timespansToTrackOptions.get();
