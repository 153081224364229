import React from 'react'
import PropTypes from 'prop-types'
import Select from 'theme/Form/Select'
import { observer } from 'mobx-react'
import { computed, makeObservable, observable } from 'mobx'
import stores, { getStore } from 'Stores'

class FilterFieldAssignedMembers extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.array // initial selected options only
  }

  static defaultProps = {
    name: 'assigned',
    label: 'Assigned To',
    userList: getStore(stores.UserList),
    value: null
  }

  @observable value = null
  @computed get options() {
    const { userList, value } = this.props
    const notSelected = value && value.value
      ? userList.items.filter(user => {
        return !value.value.find(option => user.id === option.value)
      })
      : userList.items
    return notSelected.map(user => ({
      label: user.name,
      value: user.id
    }))
  }

  constructor() {
    super()
    makeObservable(this)
  }

  componentDidMount() {
    const { value, userList } = this.props
    this.value = value
    userList.fetched()
  }

  componentDidUpdate(prevProps) {
    const { value, userList } = this.props
    if (value !== prevProps.value) {
      this.value = value
    }
    userList.fetched()
  }

  onChange = value => {
    const { name, label, onChange } = this.props
    this.value = value
    onChange({ name, label, value })
  }

  render() {
    return (
      <Select
        isMulti
        options={this.options}
        onChange={this.onChange}
        value={this.value}
      />
     )
  }
}

export default observer(FilterFieldAssignedMembers)