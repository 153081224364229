import { override } from "mobx";
import { PlannerFlow } from "./PlannerFlow";
import { TimespanPieceFlow } from "./TimespanPieceFlow";

export class PlannerFlowSplitAtDay extends PlannerFlow {

  @override get timespanFlow(): TimespanPieceFlow[] {
    return this.timespanFlowSplitAtDay
  }

}