import React from 'react'
import { observer } from 'mobx-react'
import TaskDetail from '../Components/TaskDetail'
import { getStore, models } from 'Stores'
import { reaction } from 'mobx'
import { getMQTTState } from 'uiState/MQTTState'
import { waitForCondition } from 'lib/utils'

const debug = console.log // require('debug')('treks:container:task')

class TaskDetailContainer extends React.Component {

  actionPlannerStore = getStore(models.ActionPlanner)

  /**
   * Reaction disposer
   */
  disposer = null

  componentDidMount() {
    const { focusedItem } = this.actionPlannerStore
    if (!focusedItem) return // no focused task, nothing to do
    if (!focusedItem.item?.id) {
      focusedItem.fetch()
    }
    this.disposer = reaction(() => focusedItem?.item, (item) => {
      debug('TaskDetailContainer', item, item?.id)
      if (item?.id) {
        focusedItem.save()
      }
    })
    this.onMqtt()
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer()
    this.offMqtt()
  }

  /**
   * @todo move this to app level. 
   */
  onMqtt() {
    const mqttSyncClient = getMQTTState().getClient()
    mqttSyncClient.on('create', this.onCreateModel)
    mqttSyncClient.on('update', this.onUpdateModel)
  }

  offMqtt() {
    const mqttSyncClient = getMQTTState().getClient()
    mqttSyncClient.removeListener('create', this.onCreateModel)
    mqttSyncClient.removeListener('update', this.onUpdateModel)
  }

  onCreateModel = async (data) => {
    debug('mqtt create', data)
    if (data.model.Activity) {
      const activity = data.model.Activity
      const item = await this.getActivityTask(activity)
     debug('mqtt: onCreateModel', { item, activity })
     item?.activityList.updateItemFromJSON(activity)
    }
  }

  onUpdateModel = async (data) => {
    debug('mqtt update', data)
    if (data.model.Activity) {
      const activity = data.model.Activity
      const item = await this.getActivityTask(activity)
      debug('mqtt: onUpdateModel', { item, activity })
      item?.activityList.updateItemFromJSON(activity)
    }
  }

  async getActivityTask(activity) {
    const task = await waitForCondition(() => {
      const task = models.TaskItem.findById(activity.taskId)
      return task
    })
    console.log('found activity task', task, task.id)
    return task
  }

  getFocusedTask() {
    const item = this.actionPlannerStore.focusedItem?.item
    return item
  }

  render() {
    const { pane } = this.props
    const { actionPlannerStore } = this
    const item = this.getFocusedTask()
    debug('render', { actionPlannerStore, item })
    /**
     * @todo add loading animation for saved focused item
     * actionPlannerStore.focusedItem.fetchState.state !== 'fulfilled' && <Loading />
     */
    if (!item) return null
    return (
      <TaskDetail
        store={actionPlannerStore}
        list={actionPlannerStore}
        item={item}
        pane={pane}
      />
    )
  }
}

export default observer(TaskDetailContainer)