import React from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import { getStore, models} from 'Stores'

import { FormField } from 'theme/Form'
import fields from './ProfileFields'
import { getAppNotifications } from '../../../AppNotifications'
import AccountRegisterFooter from './AccountRegisterFooter'

const debug = require('debug')('treks:account:register:profile')

const headerText = 'Welcome to TREKS!'
const infoText = 'Finish setting up your account'
const ContinueLabel = 'Continue'

export default withRouter(observer(class AccountRegisterProfile extends React.Component {

  formControlRefs = {}

  accountRegisterStore = getStore(models.AccountRegister)

  sessionStore = getStore(models.Session)
  accountStore = this.sessionStore.Account
  formStore = models.Form.fromFields(fields)

  subscriptions = []

  static defaultProps = {
    hideSavedProfileFields: true,
    profileCreateErrorMsg: 'An error occurred while trying to create your account profile',
    accountExistsErrorMsg: 'An account with this email already exists. Please login to your existing account',
    agreeToTOSErrorMsg: 'You must agree to the terms of service to complete your registration'
  }

  componentDidMount() {
    debug('get session', models.Session)
    autorun(
      () => this.formStore.setFieldValues(this.accountRegisterStore.toJSON())
    )
  }

  onClickContinue = async () => {
    const { accountExistsErrorMsg, profileCreateErrorMsg, agreeToTOSErrorMsg, history } = this.props
    if (!this.accountRegisterStore.agreedToTOS) {
      return getAppNotifications().error(agreeToTOSErrorMsg)
    }
    try {
      const accountProps = this.formStore.getFieldValues()
      const billingAccount = await this.accountRegisterStore.createBillingAccount()
      const authorizeNetAccountId = billingAccount.customerProfileId
      const accountDetails = { ...accountProps, authorizeNetAccountId }
      this.accountStore.setProps(accountDetails)
      debug('saving account details', { accountDetails, billingAccount })
      await this.sessionStore.isAuthenticated()
        .then(() => this.accountStore.edit())
        .catch(() => this.accountRegisterStore.registerAccount())
        .then(() => history.push('/account/register/team'))
    } catch(error) {
      debug('profile create error', error)
      // @todo move error shape knowledge to server response parser
      if (error && error.fields) {
        if (error.fields.email && error.fields.email[0] === 'email is not unique') {
          return getAppNotifications().error(accountExistsErrorMsg)
        }
        Object.values(error.fields).forEach(errors => {
          errors.forEach(error => getAppNotifications().error(error))
        })
      } else {
        getAppNotifications().error(profileCreateErrorMsg)
      }
    }
  }

  onFieldValueChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  render() {
    const { sessionStore, accountStore, accountRegisterStore, formStore } = this
    const { hideSavedProfileFields } = this.props
    debug('render', { sessionStore, accountStore, formStore })

    const busy = sessionStore.isAuthReqPending
      || accountStore.fetchState.state === 'pending'
      || accountRegisterStore.createBillingAccountState.state === 'pending'

    return (
      <Container className="account-register account-register-profile settings">
        <Row className="centered-header">
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              {infoText}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form className="account-register-form">

            {
              this.formStore.fields.map(field => {
                const { name, disabled } = field
                if (hideSavedProfileFields && accountRegisterStore[name]) {
                  return null
                }
                return (
                  <FormField
                    key={name}
                    {...field}
                    disabled={disabled || busy} 
                    onChange={value => this.onFieldValueChange(field, value)} 
                  />
                )
              })
            }
            
            <Form.Group controlId="formGroupName">
              <div className="form-btn-container">
                <Button 
                  onClick={this.onClickContinue} 
                  variant="primary"
                  disabled={busy}
                >
                  {ContinueLabel}
                </Button>
              </div>
            </Form.Group>

          </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountRegisterFooter />
          </Col>
        </Row>
      </Container>
    )
  }
}))