/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import './SubMenu.scss';
import { observer } from 'mobx-react';
import { getStore, models } from 'Stores';
import HabitInstance from './HabitInstance';

class HabitInstances extends React.Component {
    async componentDidMount() {
        const { habit, instanceList } = this.props;

        instanceList.fetch(habit.id);
    }

    static defaultProps = {
        get instanceList() {
            return getStore(models.InstanceList);
        },
    };

    render() {
        const { habit, instanceList } = this.props;

        console.log('render', { habit, instanceList });
        return (
            <div
                style={{
                    display: 'block',
                    width: '100%',
                    padding: 12,
                    borderRadius: 5,
                    minWidth: 150,
                    paddingTop: 5,
                }}
            >
                {/* {instanceList.items.length} {JSON.stringify(instanceList)} */}
                {instanceList.items.map((instance, idx) => {
                    return <HabitInstance instance={instance} habit={habit} />;
                })}
                <hr />
                <div style={{ paddingLeft: 15, marginTop: 10 }}>
                    {' '}
                    <a
                        href="#newinstance"
                        onClick={() => {
                            instanceList.newItem(habit);
                        }}
                    >
                        + Add an Instance
                    </a>
                </div>
            </div>
        );
    }
}

export default observer(HabitInstances)
