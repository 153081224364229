import { action, computed, observable } from "mobx";
import Model from "Stores/Model";

export class PFAScoreWeightsModel extends Model {

  @observable plan: number = 25
  @observable focus: number = 35
  @observable accomplish: number = 40

  @computed get keys(): string[] {
    return ['plan', 'focus', 'accomplish']
  }

  @computed get values(): number[] {
    return [this.plan, this.focus, this.accomplish]
  }

  @action setValues(values) {
    const { plan, focus, accomplish } = values
    this.setProps({ plan, focus, accomplish })
  }

  @computed get rangeValues(): number[] {
    return [this.plan, this.plan + this.focus]
  }

  @action setRangeValues(values) {
    const plan = values[0]
    const focus = values[1] - values[0]
    const accomplish = 100 - values[1] + values[0]
    this.setProps({ plan, focus, accomplish })
  }

  getNextKey(key: string) {
    return this.keys[(this.keys.indexOf(key) + 1) % this.keys.length]
  }

  getTotalWeights() {
    return this.keys.reduce((sum, key) => sum + this.getProp(key), 0)
  }
}