import { observable, action, computed, override } from 'mobx';
import { Item } from '../Lists'
import GroupItem from '../Group/GroupItem'
import moment from 'moment'
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class ProjectStatusAssignmentItem extends Item {

  @observable status:string = ''

	@hasOne(() => GroupItem)
  assignedTo:GroupItem

  @observable duration:number = 0

  @computed get durationFormatted() {
    return moment.utc(this.duration * 1000 * 60).format('H:mm')
  }

  @override async fetch() {
    return this.fetchState.get('project/member/' + this.id)
      .then((resp: any) => {
        const { user } = resp.data
        Object.assign(this, user)
        return resp
      })
  }

  @action async edit() {
    return this.fetchState.postJson('project/member/edit/' + this.id, this.toJSON())
  }

  @action add() {
    return this.fetchState.postJson('project/member/add', this.toJSON())
  }

}