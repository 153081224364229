import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import TaskStartDate from './TaskStartDate';
import { TaskItem, TaskList } from 'Stores/Task';
import { TaskSticky } from 'Components/ActionPlanner/Task/TaskSticky'

const debug = require('debug')('treks:task:checkbox')

class TaskOnPlanner extends React.Component {

  static propTypes = {
    item: PropTypes.objectOf(() => TaskItem),
    list: PropTypes.objectOf(() => TaskList),
  }

  static defaultProps = {
    icon: 'times',
    iconNot: 'plus',
    iconSize: '1x',
    iconSizeNot: '1x',
    label: 'On Planner: ',
    labelNot: 'Not on Planner '
  }

  onClick = event => {
    const { item } = this.props
    debug('toggle check', item)
    event.preventDefault()
    event.stopPropagation()
    item.toggleOnPlanner()
    item.save()
  }

  render() {
    const { item, iconSize, icon, list, label, labelNot, iconNot, iconSizeNot } = this.props
    const { onPlanner, trashed } = item
    debug('render', item)
    const color = !onPlanner ? '#dcdddf' : '#808285'
    const onPlannerClassName = onPlanner ? ' is-on-planner' : ''
    return (
      <div className={'task-on-planner' + onPlannerClassName}>
        <label className={onPlanner ? 'on-planner' : 'not-on-planner'}>{onPlanner ? label : labelNot}</label>
        {
          onPlanner && !trashed && <TaskStartDate item={item} list={list} />
        }
        {
          !trashed && (
            <Pressable onClick={this.onClick} className="row-icon task-on-planner-icon">
              <Icon set="fa" name={onPlanner ? icon : iconNot} color={color} size={onPlanner ? iconSize : iconSizeNot} />
            </Pressable>
          )
        }
        {
          item.isSticky ? <TaskSticky color={'#666'} /> : null
        }
      </div>
    )
  }
}

export default observer(TaskOnPlanner)