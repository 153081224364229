import { darkenShade, lightenShade } from "./ColorUtils"

const initTime = (new Date().getTime())

export function toJSON(obj) {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch(err) {} // eslint-disable-line no-empty
}

export function clone(obj) {
  return Array.isArray(obj)
    ? [...obj]
    : {...obj}
}

export function human(obj) {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch(error) {
    return clone(obj)
  }
}

export function timeElapsedSinceAppStart() {
  return  (new Date().getTime()) - initTime
}

/**
 * Resolves a promise after {millisecs}
 */
export async function wait(millisecs) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), millisecs)
  })
}

/**
 * Resolves a promise after fn returns true, running the function each {intervalMs}
 */
export async function waitForCondition(testFn, intervalMs = 50) {
  return new Promise(resolve => {
    const run = () => setTimeout(async () => {
      const result = await testFn()
      if (result) {
        resolve(result)
      } else {
        run()
      }
    }, intervalMs)
    run()
  })
}

export function getRandomColor(letters = '0123456789ABCDEF') {
  let color = '#';
  const len = letters.length
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * len)];
  }
  return color;
}

/**
 * Darken a color
 * @param {string} color (RGB or HEX)
 * @param {number} percent (1-100)
 */
export function darkenColor(color, percent) {
  return darkenShade(color, percent/100)
}

/**
 * Lighten a color
 * @param {string} color (RGB or HEX)
 * @param {number} percent (1-100)
 */
export function lightenColor(color, percent) {
  return lightenShade(color, percent/100)
}

/**
 * Parse JSON, throw errors optional
 * @param {string} json 
 * @param {boolean} throwErr
 */
export function parseJSON(json, throwErr = false) {
  try {
    return JSON.parse(json)
  } catch(e) {
    if (throwErr) throw (e)
    return null
  }
}

/**
 * Watch {obj} for changes to named {propName}
 * @param {object} obj 
 * @param {string} propName 
 */
export function watchPropertyChange(obj, propName) {
  // save in another property
  obj['__' + propName] = obj[propName];

  // overwrite with accessor
  Object.defineProperty(obj, propName, {
    get: function () {
      return obj['__' + propName];
    },

    set: function (value) {
      debugger; // eslint-disable-line no-debugger
      obj['__' + propName] = value;
    }
  });
}

/**
 * Will wait for idle {timeout} before executing
 * A second {fn} call from same {scope} cancels the initial call and restarts {timeout}
 * @param {function} fn
 * @param {number} timeout ms to wait
 * @param {object} scope Defaults to caller fn
 */
const idleTimers = new Map()
export function waitForIdle(fn, timeout = 500, scope = null) {
  scope = scope || this
  clearTimeout(idleTimers.get(scope))
  idleTimers.set(scope, setTimeout(() => fn(), timeout))
}
