import { observable } from "mobx";
import { once } from "Stores/utils";
import { createOptions } from "./createOptions";

export const getHabitPointsEarnedAfterOptions = once(() => {
  const options = [
    {
      label: 'Each Habit',
      value: 'each'
    },
    ...createOptions(1, 10, (_, index) => {
      return { label: String(index + ' each'), value: String(index) }
    }),
    {
      label: 'All Habits',
      value: 'all'
    }
  ]
  return observable(options)
});