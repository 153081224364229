/**
 * Cross browser and device event
 */
export default class NormalizedEvent {

  event = null

  supportIE = false

  constructor(event) {
    this.event = event.nativeEvent || event
    this.supportIE && eventIEShim(this.event)
  }

  preventDefault() {
    this.event.cancelable && this.event.preventDefault()
  }

  stopPropagation() {
    this.event.stopPropagation()
  }

  get target() {
    return this.event.target
  }

  composedPath() {
    return this.event.composedPath() || this.event.path || [this.target]
  }

  get cancellable() {
    return this.event.cancellable
  }

  get clientX() {
    return this.event.changedTouches ? this.event.changedTouches[0].clientX : this.event.clientX
  }

  get clientY() {
    return this.event.changedTouches ? this.event.changedTouches[0].clientY : this.event.clientY
  }

  get button() {
    return typeof this.event.button !== undefined ? this.event.button : this.event.which - 1
  }

}

const eventIEShim = (event) => {
  event = event || window.event; // IE-ism
  // If pageX/Y aren't available and clientX/Y
  // are, calculate pageX/Y - logic taken from jQuery
  // Calculate pageX/Y if missing and clientX/Y available
  if (event.pageX === null && event.clientX !== null) {
    eventDoc = (event.target?.ownerDocument) || document;
    doc = eventDoc.documentElement;
    body = eventDoc.body;

    event.pageX = event.clientX +
      (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
      (doc && doc.clientLeft || body && body.clientLeft || 0);
    event.pageY = event.clientY +
      (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
      (doc && doc.clientTop  || body && body.clientTop  || 0 );
  }
}