import { computed } from 'mobx'
import TaskItem from '..';
import CommentItem from '../../Comment/CommentItem';

export class TaskCommentItem extends CommentItem {

  @computed get task(): TaskItem {
    return this.list.task
  }

}