import React from 'react'
import { observer } from 'mobx-react'

export const GoalSubGoalTitle = observer(({ subgoal }) => {

  const onChange = event => {
    subgoal.title = event.target.value
    subgoal.save()
  }

  return (
    <input 
      onChange={onChange}
      value={subgoal.title}
      className={'title title-input input-borderless'}
      placeholder={'Subgoal title'}
    />
  )
})