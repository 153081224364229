import GoalActivityItem from './GoalActivityItem'
import { ActivityList } from '../../Activity'
import GoalItem from '../GoalItem'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class GoalActivityList extends ActivityList {

  get ModelType() {
    return GoalActivityItem
  }

  /**
   * @property {string}
   */
  type = 'goal'

  /**
   * @property {GoalItem}
   */

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.activityList)
  goal: GoalItem

}
