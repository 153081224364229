import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import SprintEndDate from './SprintEndDate'

class ProjectSprintItem extends React.Component {

  render() {
    const { sprint } = this.props
    return (
      <div className="sprint list-row row-flex">
        <div className="list-col icon">
          <Icon icon="time_zone" />
        </div>
        <div className="list-col sprint-num">
          Sprint #{sprint.sprintNumber}
        </div>
        <div className="list-col task-count">
          {sprint.taskCount}
        </div>
        <div className="list-col complete-percent">
          {sprint.taskCompletePercent}%/{sprint.taskTargetPercent}%
        </div>
        <div className="list-col sprint-date">
          <SprintEndDate sprint={sprint} />
        </div>
      </div>
    )
  }
}

export default observer(ProjectSprintItem)
