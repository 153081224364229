/**
 * Calendar date manipulation utils
 */
const MILLISECS_PER_MIN = 1000*60

export default class CalendarUtils {

  static millisecsToMins(ms:number) {
    return (ms / MILLISECS_PER_MIN)
  }

  static minsToMillisecs(min:number) {
    return (min * MILLISECS_PER_MIN)
  }

  static addMins(date:Date, mins:number) {
    return new Date(new Date(date).getTime() + minsToMillisecs(mins))
  }

  static subtractMins(date:Date, mins:number) {
    return addMins(date, -mins)
  }
  
}

export const {
  millisecsToMins,
  minsToMillisecs,
  addMins,
  subtractMins
} = CalendarUtils