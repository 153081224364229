import React, { useState, useEffect } from 'react'

export default function PaymentIcon({ size = 24, icon, variant = 'dark', width, height }) {
  const [source, setSource] = useState(null)
  useEffect(() => {
    (async () => setSource(await import('./' + variant + '/' + icon + '.png')))()
  }, [variant, icon])

  if (!icon || !source) return null
    const sizeUnit = size && (typeof size === 'number' ? size + 'px' : size)
    return (
      <img
        src={source}
        className={'icon-' + icon}
        style={{
          height: height || sizeUnit,
          width: width || 'auto',
        }}
        alt={icon}
      ></img>
    )
}