import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import GoalDetailFieldText from './Fields/GoalDetailFieldText';

const debug = require('debug')('treks:goal:details:item')

class GoalDetailItem extends React.Component {

  @action
  onFieldChange = value => {
    const { field, goal } = this.props
    field.value = value
    goal[field.name] = value
    debug('field change', { field, value, detail: goal[field.name] })
    goal.save()
  }

  render() {
    const { field, goal } = this.props
    const value = goal[field.name]
    console.log('render', { field, goal, value, name: field.name })
    const Field = field.Component 
      ? <field.Component field={field} goal={goal} /> 
      : (
        <div className="list-col detail-col">
          <GoalDetailFieldText
            field={{name: field.name, value}}
            onChange={this.onFieldChange}
          />
        </div>
      )
    const Label = (!field.label || typeof field.label === 'string')
      ? <div className="goal-details-label">{field.label}</div> 
      : <field.label field={field} /> 
    return (
      <div className="detail list-row row-flex">
        <div className="list-col detail-col">
          {Label}
        </div>
        <div className="list-col detail-col">
          {Field}
        </div>
      </div>
    )
  }
}

export default observer(GoalDetailItem)
