import React from 'react'
import Account from './Account'
import AccountSettingsModal from './AccountSettingsModal'
import AccountRegisterModal from './Register/AccountRegisterModal'
import AccountLoginModal from './AccountLoginModal'
import AccountPasswordModal from './AccountPasswordModal'
import AccountLogout from './AccountLogout'
import { Route, Switch } from 'react-router-dom'

const AccountRoutes = () => (
  <Switch>  
    <Route path="/account/password" component={AccountPasswordModal} />
    <Route path="/account/settings" component={AccountSettingsModal} />
    <Route path="/account/register" component={AccountRegisterModal} />
    <Route path="/account/login" component={AccountLoginModal} />
    <Route path="/account/logout" component={AccountLogout} />
    <Route path="/account" component={Account} /> 
  </Switch>
)

export default AccountRoutes