import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from './utils'
import { formatDate } from 'lib/utils/DateUtils'

export const DueDateField = observer(({ item, fields, dateFormatOpts }) => {
  const { dueDate } = item
  const dueDateFormatted = dueDate ? formatDate(dueDate, dateFormatOpts) : ''
  return (
    fieldIsEnabled(fields, 'due_date') ? (
      <div className="attribute attribute-due-date">
        {dueDateFormatted}
      </div>
    ) : null
  )
})