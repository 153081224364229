import { observer } from "mobx-react";
import React from "react";
import MainPaneTabsHeader from './TabsHeader'
import './MainPaneTabs.scss'

class MainPaneTabsContainer extends React.Component {

  componentDidMount() {
    const { pane } = this.props
    if (pane.focusedTab) {
      pane.setFocusedTab(pane.focusedTab)
    }
  }

  onClickTabBtn = (event, tab) => {
    event.preventDefault()
    this.props.pane.setFocusedTab(tab)
  }

  onClickClose = event => {
    const { pane } = this.props
    event.preventDefault()
    pane.removeTab(pane.focusedTab)
    if (pane.tabs.length) {
      // const tabs = pane.tabs.map(tab => tab.name)
      // const ordered = pane.tabsOrderedByLastFocus.map(tab => tab.name)
      // console.log('focus', { pane, tabs, ordered, last: pane.lastFocusedTab })
      pane.setFocusedTab(pane.lastFocusedTab)
    }
  }

  render() {
    const { mainPaneGroup, pane, onPaneScroll, EmptyComponent } = this.props
    const isScrollingClass = pane.isPendingFocusOnTab ? ' is-scrolling' : ''
    return (
      <div className="main-pane-tabs">
        <MainPaneTabsHeader
          pane={pane}
          onClickClose={this.onClickClose}
          onClickTabBtn={this.onClickTabBtn}
        />
        <div className={"main-pane-tabs-container" + isScrollingClass}>
        {
          pane.tabs.map(tab => {
            const Component = tab.component || EmptyComponent
            const focusedClass = pane.focusedTab === tab ? ' focused-tab' : ''
            return (
              <div
                key={tab.uid}
                id={'main-pane-tab-' + tab.uid}
                className={"main-pane-tab main-pane-component md-light-scrollbar" + focusedClass}
                onScroll={onPaneScroll}
              >
                <Component
                  parentPane={pane}
                  pane={tab}
                  mainPaneGroup={mainPaneGroup}
                  props={tab.props}
                />
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default observer(MainPaneTabsContainer)