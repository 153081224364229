import moment from "moment"

const dateFormatOpts = {
  dateFormat: 'MMMM D, YYYY',
  timeFormat: 'h:mm A',
}

export function formatDate(date:Date, opts:any = dateFormatOpts):string {
  const { dateFormat, timeFormat } = opts
  const time = getTimeInMinutesFromDate(date)
  const format = dateFormat + (time ? '     ' +  timeFormat : '')
  return date && moment(date).isValid() ? moment(date).format(format) : ''
}

export function formatDateToTime(date:Date, format: string):string {
  return formatDate(date, {
    dateFormat: '',
    timeFormat: format || 'h:mm A',
  })
}

export function getTimeInMinutesFromDate(date:Date):Number {
  return date && (date.getHours() * 60) + date.getMinutes()
}

export function minsToTimeFormatted(mins: number): string {
  mins = parseFloat(String(mins))
  const PM = mins > 12*60
  return convertMinsToHrsMins(PM ? mins - 12*60 : mins) 
    + ' ' + (PM ? 'PM' : 'AM')
}

export function convertMinsToHrsMins(mins: number): string {
  const h = Math.floor(mins / 60)
  const extra = mins % 60
  const m = extra < 10 ? '0' + extra : extra
  return `${h}:${m}`
}
