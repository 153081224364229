import { ResponsiveBar } from '@nivo/bar'
import { getPlanningData } from './planningData'

const keys = ['planning']
const defaultProps = {
  width: 500,
  height: 400,
  margin: { top: 60, right: 110, bottom: 60, left: 80 },
  indexBy: 'timespan',
  keys,
  padding: 0.6,
  labelTextColor: 'inherit:darker(1.4)',
  labelSkipWidth: 16,
  labelSkipHeight: 16,
  label:  false,
  valueFormat: value => value + '%',
  axisLeft: {
    format: value => value + '%'
  },
  maxValue: 100,
  colors: bar => bar.data.color,
}

export const PlanningBarGraph = (props) => {
  const allProps = { ...defaultProps, ...props }
  const { width, height, pfaPlanScoreStore } = allProps
  const data = getPlanningData(pfaPlanScoreStore)
  return (
    <div style={{ width, height }}>
      <ResponsiveBar {...allProps} data={data} />
    </div>
  )
}