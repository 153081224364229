import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const debug = require('debug')('treks:project:metrics:burndown:graph')


class BurnDownGraph extends React.Component {

  getYMaxCeil(scale = 10) {
    const yMax = this.props.data[0]
      .reduce((max, { y }) => Math.max(y, max), 0)
    return Math.ceil(yMax/scale) * scale
  }

  render() {
    const { data } = this.props
    debug('render', data)
    return (
      <ResponsiveBar
        data={data}
        reverse={true}
        keys={[
            "Total Completed",
            "Work Completed",
            "Work Remaining",
            "Work Removed",
            "Work Added",
            "Work Forecast Completed",
            "Work Forecast Remaining",
        ]}
        indexBy="day"
        margin={{
            "top": 50,
            "right": 50,
            "bottom": 50,
            "left": 50
        }}
        padding={0.3}
        colors={({ id, data }) => data[`${id}Color`]}
        defs={[
            {
                "id": "dots",
                "type": "patternDots",
                "background": "inherit",
                "color": "#38bcb2",
                "size": 4,
                "padding": 1,
                "stagger": true
            },
            {
                "id": "lines",
                "type": "patternLines",
                "background": "inherit",
                "color": "#eed312",
                "rotation": -45,
                "lineWidth": 6,
                "spacing": 10
            }
        ]}
        fill={[
            {
                "match": {
                    "id": "fries"
                },
                "id": "dots"
            },
            {
                "match": {
                    "id": "sandwich"
                },
                "id": "lines"
            }
        ]}
        borderColor="inherit:darker(1.6)"
        axisBottom={null}
        axisRight={null}
        axisTop={{
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legend": "Day",
            "legendPosition": "middle",
            "legendOffset": 32
        }}
        axisLeft={{
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legend": "Task Hours",
            "legendPosition": "middle",
            "legendOffset": -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={'#ffffff'}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
            {
                "dataFrom": "keys",
                "anchor": "top-right",
                "direction": "column",
                "justify": false,
                "translateX": -20,
                "translateY": 50,
                "itemsSpacing": 2,
                "itemWidth": 150,
                "itemHeight": 20,
                "itemDirection": "left-to-right",
                "itemOpacity": 1,
                "symbolSize": 20,
                "effects": [
                    {
                        "on": "hover",
                        "style": {
                            "itemOpacity": 1
                        }
                    }
                ],
                "itemBackground": '#fff',
            }
        ]}
    />
    )
  }
}

export default BurnDownGraph
