import React from "react";
import { observer } from "mobx-react";
import "./EventDetail.scss";
import stores, { getStore } from "Stores";
import EventDetail from ".";
import ActionPlanner from "Stores/ActionPlanner";
import { reaction } from "mobx";

const debug = require("debug")("treks:container:event:detail");

class EventDetailContainer extends React.Component<{
  actionPlanner?: ActionPlanner;
}> {
  static defaultProps = {
    get actionPlanner() {
      return getStore(stores.ActionPlanner);
    },
  };

  disposeFocusItem = null;

  componentDidMount() {
    const { actionPlanner } = this.props;
    if (!actionPlanner.focusedEvent) {
      actionPlanner.fetchFocusedEventLocal();
    }
    if (this.disposeFocusItem) this.disposeFocusItem();
    this.disposeFocusItem = reaction(
      () => actionPlanner.focusedEvent,
      (focusedEvent) => {
        debug("EventDetailContainer focusedEvent Change", {
          focusedEvent,
          id: focusedEvent?.id,
        });
        if (focusedEvent?.id) {
          debug("saving event local");
          actionPlanner.saveFocusedEventLocal();
        }
      }
    );
  }

  render() {
    const { actionPlanner } = this.props;
    const { focusedEvent } = actionPlanner;
    debug("render", { focusedEvent });
    if (!focusedEvent) return null;
    return <EventDetail actionPlanner={actionPlanner} event={focusedEvent} />;
  }
}

export default observer(EventDetailContainer);
