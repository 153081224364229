import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../../theme/Icon'
import Pressable from 'theme/Pressable'

class ProjectMeetingRecurring extends React.Component {

  onPress = () => {
    const { meeting } = this.props
    meeting.recurring = !meeting.recurring
    meeting.save()
  }

  render() {
    const { meeting } = this.props
    const iconStyle = {
      opacity: meeting.recurring ? 1 : 0.2
    }
    return (
      <Pressable onClick={this.onPress}>
        <Icon icon="recurring" style={iconStyle} />
      </Pressable>
    )
  }
}

export default observer(ProjectMeetingRecurring)