import React from 'react'
import { observer } from 'mobx-react'
import DetailFileModal from './DetailFileModal'
import './DetailFileDetail.css'

const debug = require('debug')('treks:detail:file:detail')

class DetailFileDetail extends React.Component {

  render() {
    const { fileItem, show, onClose } = this.props
    debug('render', this.props)
    return (
      <DetailFileModal fileItem={fileItem} show={show} onClose={onClose} />
    )
  }
}

export default observer(DetailFileDetail)