import { action, observable } from 'mobx'
import { Item } from 'Stores/Lists'
import { ProjectItem } from 'Stores/Project'
import { OrganizationItem } from 'Stores/Organization'
import { TeamItem } from 'Stores/Team'
import { User, } from 'Stores/User'
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators'
import { CategoryTaskList } from './CategoryTaskList'
import TaskItem from 'Stores/Task'

export default class CategoryItem extends Item {

  @observable title:string

  @action setTitle(title: string) {
    this.title = title
  }

  @observable description: string

  @observable color:string

  @observable createdBy:string

  @observable isFlatList: boolean

  @observable focusOnTitle: boolean 

  @observable deleted: boolean

  @action setFocusOnTitle() {
    queueMicrotask(() => this.focusOnTitle = true)
  }

  @hasOne(() => User, (user: User) => user.createdCategoryList)
  creator: User

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.categoryList)
  project: ProjectItem

  @hasOne(() => OrganizationItem, (organization: OrganizationItem) => organization.categoryList)
  organization: OrganizationItem

  @hasOne(() => TeamItem, (team: TeamItem) => team.categoryList)
  team: TeamItem

  @hasMany(() => CategoryTaskList, (task: TaskItem) => task.category)
  taskList: CategoryTaskList

  @observable createdTS:string

  static fromJSON(data) {
    const { id, uid, title, color, deleted, createdTS, createdBy, projectId, organizationId, teamId } = data
    return super.fromJSON({
      id,
      uid,
      title,
      description,
      color,
      deleted,
      createdTS,
      createdBy,
      project: ProjectItem.fromId(projectId),
      organization: OrganizationItem.fromId(organizationId),
      team: TeamItem.fromId(teamId)
    })
  }

  toJSON() {
    const json = {
      id: this.id,
      uid: this.uid,
      title: this.title,
      description: this.description,
      color: this.color,
      deleted: this.deleted,
      createdTS: this.createdTS,
      createdBy: this.createdBy,
      projectId: this.project.id,
      organizationId: this.organization.id,
      teamId: this.team.id
    }
    return json
  }

 async fetch() {
    return this.fetchState.postJson('category/fetch/' + this.id, this.toJSON())
      .then(({ data }) => this.fromJSON(data)) as Promise<this>
  }

 async save() {
    return this.saveState.postJson('category/save', this.toJSON())
      .then(json => {
        return json?.id ? (
          this.setJSON({ id: json.id } as Partial<this>)
        ) : this
      })
  }

}
