import React from 'react'
import { observer } from 'mobx-react'
import './List.scss'

class ListView extends React.Component {

  static defaultProps = {
    className: ''
  }

  render() {
    const { className, children } = this.props
    return (
      <div className={'list-view ' + className}>
        {children}
      </div>
    )
  }
}

export default observer(ListView)