import { action } from "mobx";
import lists from './lists'
import { uid } from "Stores/utils";
import List from "Stores/Lists";

const debug = require('debug')('treks:docs:draggable:model:list')

export default class DraggableLists extends List {

  constructor() {
    super()
    this.setItems(lists)
  }

  @action getAllItems() {
    const { items } = this
    const allItems = items.map(list => [ ...list.items ]).flat()
    const allSubItems = allItems.map(item => [ ...item.subtasks, ...item.fill ]).flat()
    const mergedItems = [ ...allItems, ...allSubItems, ...this.items ]
    const uniqueMergedItems = this.items = mergedItems.filter((item, index) => mergedItems.indexOf(item) === index)
    debug('getAllItems', { allItems, allSubItems, mergedItems, uniqueMergedItems })
    return uniqueMergedItems
  }
  
  @action addEvent() {
    const events = this.items[0].items
    events.push({
      id: 'event-' + uid(),
      type: 'event',
      name: 'Event ' + (events.length + 1),
      duration: Math.max(Math.floor(Math.random() * 200), 30),
      subtasks: [],
      fill: []
    })
  }
  
  @action setListOrder = (list, order) => {
    debug('setListOrder', { order, list })
    const items = this.getItemsFromOrder(order)
    // put all items in list without removing list ref for mobx
    list.splice(0, list.length, ...items)
  }

  subtasksDuration(subtasks) {
    subtasks.reduce((total, { duration }) => total + duration, 0)
  }

}