import React from 'react'
import Icon from './Icon'

import './style/demo.css'

export default class IconsDemo extends React.Component {

  state = {
    showCodes: false
  }

  toggleShowCodes = () => {
    this.setState({
      showCodes: !this.state.showCodes
    })
  }

  render() {
    const { showCodes } = this.state
    return (
      <div className={(showCodes ? 'showCodes' : '')} id="icons">
        <div className="container-fluid header">
          <h1>treks_icons <small>font demo</small></h1>
          <label className="switch">
            <input type="checkbox" onClick={this.toggleShowCodes} /> 
            {showCodes ? 'hide' : 'show'} codes
          </label>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe800">
                <Icon name="chart-bar" /> <span className="i-name">chart-bar</span><span className="i-code">0xe800</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe801">
                <Icon name="action_planner" /> <span className="i-name">action_planner</span><span className="i-code">0xe801</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe802">
                <Icon name="add_guest" /> <span className="i-name">add_guest</span><span className="i-code">0xe802</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe803">
                <Icon name="add_to_action_planner" /> <span className="i-name">add_to_action_planner</span><span className="i-code">0xe803</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe804">
                <Icon name="alert" /> <span className="i-name">alert</span><span className="i-code">0xe804</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe805">
                <Icon name="assign" /> <span className="i-name">assign</span><span className="i-code">0xe805</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe806">
                <Icon name="attach_file" /> <span className="i-name">attach_file</span><span className="i-code">0xe806</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe807">
                <Icon name="calendar" /> <span className="i-name">calendar</span><span className="i-code">0xe807</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe808">
                <Icon name="category" /> <span className="i-name">category</span><span className="i-code">0xe808</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe809">
                <Icon name="checkbox_checked" /> <span className="i-name">checkbox_checked</span><span className="i-code">0xe809</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe80a">
                <Icon name="checkbox_empty" /> <span className="i-name">checkbox_empty</span><span className="i-code">0xe80a</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe80b">
                <Icon name="collapse" /> <span className="i-name">collapse</span><span className="i-code">0xe80b</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe80c">
                <Icon name="comment" /> <span className="i-name">comment</span><span className="i-code">0xe80c</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe80d">
                <Icon name="convert_to_event" /> <span className="i-name">convert_to_event</span><span className="i-code">0xe80d</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe80e">
                <Icon name="convert_to_project" /> <span className="i-name">convert_to_project</span><span className="i-code">0xe80e</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe80f">
                <Icon name="details" /> <span className="i-name">details</span><span className="i-code">0xe80f</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe810">
                <Icon name="drag" /> <span className="i-name">drag</span><span className="i-code">0xe810</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe811">
                <Icon name="expand" /> <span className="i-name">expand</span><span className="i-code">0xe811</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe812">
                <Icon name="goal" /> <span className="i-name">goal</span><span className="i-code">0xe812</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe813">
                <Icon name="help" /> <span className="i-name">help</span><span className="i-code">0xe813</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe814">
                <Icon name="history" /> <span className="i-name">history</span><span className="i-code">0xe814</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe815">
                <Icon name="integrations" /> <span className="i-name">integrations</span><span className="i-code">0xe815</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe816">
                <Icon name="location" /> <span className="i-name">location</span><span className="i-code">0xe816</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe817">
                <Icon name="meeting" /> <span className="i-name">meeting</span><span className="i-code">0xe817</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe818">
                <Icon name="metrics" /> <span className="i-name">metrics</span><span className="i-code">0xe818</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe819">
                <Icon name="my_account" /> <span className="i-name">my_account</span><span className="i-code">0xe819</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe81a">
                <Icon name="no_alert" /> <span className="i-name">no_alert</span><span className="i-code">0xe81a</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe81b">
                <Icon name="organization" /> <span className="i-name">organization</span><span className="i-code">0xe81b</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe81c">
                <Icon name="print" /> <span className="i-name">print</span><span className="i-code">0xe81c</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe81d">
                <Icon name="project" /> <span className="i-name">project</span><span className="i-code">0xe81d</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe81e">
                <Icon name="recurring" /> <span className="i-name">recurring</span><span className="i-code">0xe81e</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe81f">
                <Icon name="resize" /> <span className="i-name">resize</span><span className="i-code">0xe81f</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe820">
                <Icon name="settings" /> <span className="i-name">settings</span><span className="i-code">0xe820</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe821">
                <Icon name="subtasks" /> <span className="i-name">subtasks</span><span className="i-code">0xe821</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe822">
                <Icon name="task" /> <span className="i-name">task</span><span className="i-code">0xe822</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe823">
                <Icon name="task_complete" /> <span className="i-name">task_complete</span><span className="i-code">0xe823</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe824">
                <Icon name="time_zone" /> <span className="i-name">time_zone</span><span className="i-code">0xe824</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe825">
                <Icon name="trash" /> <span className="i-name">trash</span><span className="i-code">0xe825</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="the-icons span3" title="Code: 0xe826">
                <Icon name="url" /> <span className="i-name">url</span><span className="i-code">0xe826</span>
              </div>
              <div className="the-icons span3" title="Code: 0xe827">
                <Icon name="volume" /> <span className="i-name">volume</span><span className="i-code">0xe827</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}