import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from 'theme/Icon'
import Select from 'theme/Form/Select'
import Pressable from 'theme/Pressable'
import './ListViewSort.scss'
import { SortMenuList } from './SortMenuList'

class ListViewSort extends React.Component {

  static propTypes = {
    onSort: PropTypes.func.isRequired
  }

  static defaultProps = {
    sortGroupingLabel: 'Sort Within Parent',
    sortCategoryLabel: 'Sort Within Category',
    options: [
      {
        label: 'Set Order',
        value: 'set_order'
      },
      {
        label: 'Due Date',
        value: 'due_date'
      },
      {
        label: 'Assigned To',
        value: 'assigned_to'
      },
      {
        label: 'Alphabetical',
        value: 'alphabetical'
      },
      {
        label: 'Duration',
        value: 'duration'
      },
      {
        label: 'Category (alphabetical)',
        value: 'category'
      }
    ]
  }

  defSortState = {
    withinParent: true,
    withinCategory: true,
    sortOrder: 'ASC',
    sortBy: '',
  }

  sortState = observable(this.defSortState)

  onClear = () => {
    Object.assign(this.sortState, this.defSortState)
    this.props.onSort({ value: this.sortState })
  }

  onSortByChange = sortBy => {
    this.sortState.sortBy = sortBy
    this.props.onSort({ value: this.sortState })
  }

  onSortOrderChange = () => {
    this.sortState.sortOrder = this.sortState.sortOrder === 'ASC'
      ? 'DESC'
      : 'ASC'
      this.props.onSort({ value: this.sortState })
  }

  onSortGroupingChange = withinParent => {
    this.sortState.withinParent = withinParent
    this.props.onSort({ value: this.sortState })
  }

  onSortCategoryChange = withinCategory => {
    this.sortState.withinCategory = withinCategory
    this.props.onSort({ value: this.sortState })
  }

  render() {
    const { options, sortGroupingLabel, sortCategoryLabel } = this.props
    const { withinParent, withinCategory, sortOrder, sortBy } = this.sortState
    const sortLabel = sortBy ? sortBy.label : 'none'
    const MenuList = props => (
      <SortMenuList
        {...props}
        onSortGroupingChange={this.onSortGroupingChange}
        onSortCategoryChange={this.onSortCategoryChange}
        sortGroupingLabel={sortGroupingLabel}
        sortCategoryLabel={sortCategoryLabel}
        sortGroupingChecked={withinParent}
        sortCategoryChecked={withinCategory}
      />
    )
    return (
      <div className="list-view-sort-container">
        {
          <Pressable onClick={this.onSortOrderChange}>
            {
              sortOrder === 'ASC' 
                ? <Icon set="fa" icon="chevron-up" />
                : <Icon set="fa" icon="chevron-down" />
            }
          </Pressable>
        }
        <label className="filter-sort-label">
          {'Sort: '}
        </label>
        <div className="filter-sort-select">
          <Select
            isClearable
            placeholder={sortLabel}
            options={options}
            onChange={this.onSortByChange}
            value={sortBy && sortBy.value}
            components={{ MenuList }}
          />
        </div>
      </div>
     )
  }
}

export default observer(ListViewSort)