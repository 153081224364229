import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskRemoveFromPlannerIcon from '../../TaskDetail/TaskRemoveFromPlannerIcon';
import { getAppNotifications } from '../../../AppNotifications';
import Pressable from 'theme/Pressable';
import { LucidePlusCircle, LucideXCircle } from 'lucide-react';
import { CalendarEvent } from 'Stores/Calendar';

const debug = require('debug')('treks:EventHeader')

type CalendarEventHeaderProps = {
  event: CalendarEvent;
  removeEventStartMsg?: string;
  removeEventFailMsg?: string;
  removeEventSuccessMsg?: string;
  deleteEventStartMsg?: string;
  deleteEventFailMsg?: string;
  deleteEventSuccessMsg?: string;
  saveTimeout?: number;
  isHovered?: boolean;
  isFocused?: boolean;
}

class CalendarEventHeader extends React.Component<CalendarEventHeaderProps> {

  saveTimer = null

  static defaultProps = {
    removeEventStartMsg: null,
    removeEventFailMsg: 'Failed to remove event from your action planner',
    removeEventSuccessMsg: 'The Event has been removed from your action planner',
    deleteEventStartMsg: null,
    deleteEventFailMsg: 'Failed to delete event',
    deleteEventSuccessMsg: 'The Event has been deleted',
    saveTimeout: 1000
  }

  onRemoveFromPlanner = () => {
    const { event, removeEventStartMsg, removeEventFailMsg, removeEventSuccessMsg } = this.props
    if (removeEventStartMsg) getAppNotifications().show(removeEventStartMsg)
    event.removeFromPlanner()
      .then(() => getAppNotifications().warn(removeEventSuccessMsg))
      .catch(error => getAppNotifications().error(error.message || removeEventFailMsg))
  }

  onTitleChange = event => {
    debug('onTitleChange', event.target.value)
    this.props.event.title = event.target.value
    clearTimeout(this.saveTimer)
    this.saveTimer = setTimeout(() => this.props.event.save(), this.props.saveTimeout)
  }

  onPressOnPlanner = () => {
    const { event, removeEventFailMsg, removeEventSuccessMsg } = this.props
    const onPlanner = !event.onPlanner
    event.setProps({ onPlanner })
    // @todo
    // event.save()
    //   .then(() => getAppNotifications().warn(removeEventSuccessMsg))
    //   .catch(error => getAppNotifications().error(error.message || removeEventFailMsg))
  }

  render() {
    const { event, isHovered, isFocused } = this.props
    const isFetched = event.calendarAccountList?.fetchState.isFetched
    debug('render', event, { isHovered, isFocused })
    return (
      <header>
        <Pressable onClick={this.onPressOnPlanner}>
          {
            event.onPlanner ? (
              isHovered ? <LucideXCircle /> : <Icon icon="action_planner" />
            ) : (
              <LucidePlusCircle />
            )
          }
        </Pressable>
        <div className={'title' + (event.done ? ' done' : '')}>
          <input
            className="input-borderless"
            name={'event-title'}
            value={event.title}
            onChange={this.onTitleChange}
            onMouseDown={event => event.stopPropagation()}
          />  
        </div>
        {
          isFetched
            ? (
              <TaskRemoveFromPlannerIcon
                item={event}
                onClick={this.onRemoveFromPlanner}
              />
            )
            : null
        }
      </header>
    )
  }
}

export default observer(CalendarEventHeader)