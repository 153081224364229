import React from 'react'
import NormalizedEvent from './NormalizedEvent'

const debug = require('debug')('treks:draggable:resizable')

export default class Resizable extends React.Component {

  static defaultProps = {
    resize: 'both', // both|vertical|horizontal
    resizeHandleClassName: 'resize-handle',
    Handle: 'div'
  }

  elementRef = null

  startX = 0
  startY = 0
  startWidth = 0
  startHeight = 0

  onElementRef = ref => {
    const { elementRef } = this.props
    elementRef && elementRef(ref)
    if (ref) this.elementRef = ref
  }

  getElementRefDimensions() {
    const { elementRef } = this
    return elementRef.getBoundingClientRect ? elementRef.getBoundingClientRect() : {
      width: elementRef.offsetWidth,
      height: elementRef.offsetHeight
    }
  }

  onResizeStart = event => {
    event = new NormalizedEvent(event)
    if (typeof event.button === 'number' && event.button !== 0) {
      return // not left mouse click
    }
    event.preventDefault()
    event.stopPropagation()
    const { onResizeStart } = this.props
    const { ownerDocument } = event.target
    this.startX = event.clientX;
    this.startY = event.clientY;
    const { width, height } = this.getElementRefDimensions()
    this.startWidth = parseFloat(width)
    this.startHeight = parseFloat(height)
    ownerDocument.addEventListener('mousemove', this.onResize)
    ownerDocument.addEventListener('mouseup', this.onResizeEnd)
    ownerDocument.addEventListener('touchmove', this.onResize)
    ownerDocument.addEventListener('touchend', this.onResizeEnd)
    ownerDocument.addEventListener('touchcancel', this.onResizeEnd)
    onResizeStart && onResizeStart({ width, height, event })
    debug('onResizeStart', { width, height, event })
  }

  onResizeEnd = event => {
    event = new NormalizedEvent(event)
    const { onResizeEnd } = this.props
    const { ownerDocument } = event.target
    const { width, height } = this.getElementRefDimensions()
    event.preventDefault()
    event.stopPropagation()
    ownerDocument.removeEventListener('mousemove', this.onResize)
    ownerDocument.removeEventListener('mouseup', this.onResizeEnd)
    ownerDocument.removeEventListener('touchmove', this.onResize)
    ownerDocument.removeEventListener('touchend', this.onResizeEnd)
    ownerDocument.removeEventListener('touchcancel', this.onResizeEnd)
    onResizeEnd && onResizeEnd({ width, height , event })
    debug('onResizeEnd', { width, height, event })
  }

  onResize = event => {
    event = new NormalizedEvent(event)
    const { resize, onResize } = this.props
    const { startWidth, startHeight, startX, startY } = this
    const width = startWidth + event.clientX - startX
    const height = startHeight + event.clientY - startY
    event.preventDefault()
    event.stopPropagation()
    debug('onResize', { width, height, event, startWidth, startHeight, startX, startY })
    // controlled
    if (onResize) {
      return onResize({ width, height, event })
    }
    // uncontrolled
    if (resize === 'both' || resize === 'horizontal') {
      this.elementRef.style.width = width + 'px'
    }
    if (resize === 'both' || resize === 'vertical') {
      this.elementRef.style.height = height + 'px'
    }
  }

  render() {
    const {
      elementRef, // eslint-disable-line no-unused-vars 
      children, // eslint-disable-line no-unused-vars 
      resize, // eslint-disable-line no-unused-vars 
      Handle, // eslint-disable-line no-unused-vars 
      resizeHandleClassName, // eslint-disable-line no-unused-vars 
      onResize, // eslint-disable-line no-unused-vars 
      onResizeStart, // eslint-disable-line no-unused-vars 
      onResizeEnd, // eslint-disable-line no-unused-vars 
      ...componentProps
    } = this.props

    debug('render', { children, resize, componentProps, onResize, onResizeStart, onResizeEnd })

    return (
      <div
        ref={this.onElementRef}
        {...componentProps}
      >
        {children}
      </div>
    )
  }
}