import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { EventEmitter } from 'events';
import ReactSelect from 'react-select'
import Creatable from 'react-select/creatable'
import DropdownIndicator from './DropdownIndicator'
import SingleValue from './SingleValue'
import Placeholder from './Placeholder'
import MenuList from './MenuList'
import { styles as baseSelectStyles, composeStyles } from './SelectStyles'
import './Select.scss'

const debug = require('debug')('treks:form:select')

class Select extends React.Component {

  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }

  store = observable({
    isAddingOption: '',
    selectProps: observable({
      addOptionIsActive: false
    })
  })

  eventEmitter = new EventEmitter()

  static defaultProps = {
    selectPlaceholder: 'Select...',
    noOptionsMessage: () => 'No items matching search...',
    menuOpenValue: 'Search...',
    hasAddOption: false,
    addOptionLabel: 'Create New...',
    addOptionPlaceholder: 'Create New...',
    onAddOption: () => {},
    value: null /* default to controlled */
  }

  setSelectProps = props => {
    Object.assign(this.store.selectProps, props)
  }

  addSelectEventListener = (eventName, fn) => {
    this.eventEmitter.addListener(eventName, fn)
  }

  removeSelectEventListener = (eventName, fn) => {
    this.eventEmitter.removeListener(eventName, fn)
  }

  emitSelectEventListener = (eventName, params) => {
    this.props[eventName] && this.props[eventName](...params)
    window.setTimeout(() => this.eventEmitter.emit(eventName, params))
  }

  onChange = (...params) => {
    debug('onChange', { params })
    this.emitSelectEventListener('onChange', params)
  }

  onInputChange = (...params) => {
    debug('onInputChange', { params })
    this.emitSelectEventListener('onInputChange', params)
  }

  onInputKeyDown = (...params) => {
    debug('onInputKeyDown', { params })
    this.emitSelectEventListener('onInputKeyDown', params)
  }

  onMenuClose = (...params) => {
    debug('onMenuClose', { params })
    this.emitSelectEventListener('onMenuClose', params)
  }

  onCreateOption = (...params) => {
    debug('onCreateOption', { params })
    this.emitSelectEventListener('onCreateOption', params)
  }

  render() {
    const { props, store } = this
    const styles = composeStyles(props.styles, baseSelectStyles)
    // react-select assumes option.value is a string
    const options = props.options
      .map(({ label, value, ...data }) => ({ ...data, label: label ? label.toString() : '', value: value ? value.toString() : '' }))
    let selectProps = { ...props }

    // @todo bad fixes
    if (!props.isMulti ) {
      // react-select expects value to be one of the options
      if (selectProps.value && !(Object.keys(selectProps.value).includes('value'))) {
        selectProps.value = options.find(option => option.value === selectProps.value)
      }
      // react-select assumes value.value is a string
      if (!props.isMulti && selectProps.value && selectProps.value.value) {
        selectProps.value = { ...selectProps.value } // do not modify observable
        selectProps.value.value = selectProps.value.value.toString()
      }
    }
    
    debug('render', { props, selectProps, store, options })
    const SelectComponent = props.hasAddOption ? Creatable : ReactSelect
    return (
      <SelectComponent
        onCreateOption={this.onCreateOption}
        setSelectProps={this.setSelectProps}
        isAddingOption={this.store.isAddingOption}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
          SingleValue,
          Placeholder,
          MenuList
        }}
        className="react-select"
        classNamePrefix="react-select"
        {...selectProps}
        {...store.selectProps}
        options={options}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        onMenuClose={this.onMenuClose}
        onInputKeyDown={this.onInputKeyDown}
        styles={styles}
        addSelectEventListener={this.addSelectEventListener}
        removeSelectEventListener={this.removeSelectEventListener}
      /> 
    )
  }
}

export default observer(Select)