import React from 'react'
import Sortable from 'sortablejs'
import './Sortable.scss'

const debug = require('debug')('treks:docs:sortable:event')

export default class SortableEventsDocs extends React.Component {

  state = {
    events: [],
    lists: [
      {
        name: 'Personal',
        items: [],
        group: {
          name: 'timespan',
          put: ['timespan', 'event', 'nested']
        }
      },
      {
        name: 'Work',
        items: [],
        group: 'timespan'
      },
      {
        name: 'Afternoon Personal',
        items: [],
        group: 'timespan'
      }
    ]
  }

  getEventLogginOptions() {
    const options = {};
    [
      'onChoose',
      'onStart',
      'onEnd',
      'onAdd',
      'onUpdate',
      'onSort',
      'onRemove',
      'onChange',
      'onUnchoose'
    ].forEach((name) => {
      options[name] = (evt) => {
        debug({
          'event': name,
          'this': this,
          'item': evt.item,
          'from': evt.from,
          'to': evt.to,
          'oldIndex': evt.oldIndex,
          'newIndex': evt.newIndex
        });
      };
    });
    return options
  }

  componentDidMount() {
    this.addToLists()
    this.createSortables()
  }

  createSortables() {
    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
    };
    // timespans
    this.state.lists.forEach(list => Sortable.create(document.getElementById(list.name), {
      ...options,
      ...this.getEventLogginOptions(),
      group: list.group,
    }))
    // events
    Sortable.create(document.getElementById('Events'), {
      ...options,
      ...this.getEventLogginOptions(),
      group: 'event',
      ghostClass: 'event-ghost'
    })
  }

  createNestedSortable() {
    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: {
        name: 'nested',
        put: ['event', 'nested']
      }
    }
    const nestedEls = document.querySelectorAll('.nested')
    debug('nested els', nestedEls)
    Array.from(nestedEls).forEach(el => Sortable.create(el, {
      ...options,
      ...this.getEventLogginOptions(),
    }))
  }

  addToLists() {
    const listLen = 5
    const tempList = new Array(listLen).fill()
    const lists = [ ...this.state.lists ]
    lists.forEach(list => {
      let len = list.items.length
      tempList.map(() => ({
        name: list.name + ' ' + ++len,
        duration: Math.max(Math.floor(Math.random() * 200), 30)
      })).forEach(item => list.items.push(item))
    })
    this.setState({ lists })
    window.setTimeout(() => this.createNestedSortable())
  }

  addEvent() {
    const events = [ ...this.state.events ]
    events.push({
      name: 'Event ' + (events.length + 1),
      duration: Math.max(Math.floor(Math.random() * 200), 30)
    })
    this.setState({
      events
    })
  }

  render() {
    return (
      <div className="container">
        <div className="row">
        <div className="col">

          <h2>Sortable Events</h2>
          <p>
            Sortable multi list with flow around a fixed item
          </p>
          <p>
            <button onClick={() => this.addToLists()}>Add to Lists</button>
            <button onClick={() => this.addEvent()}>Add Event</button>
          </p>


          <ul id="Events">
            {
              this.state.events.map(event => <li key={event.name} style={{ height: event.duration }}>{event.name}</li>)
            }
          </ul>

          {
            this.state.lists.map((list, index) => {
              return (
                <div key={index} className="list-container">
                  <h3 className="list-name">{list.name}</h3>
                  <ul id={list.name}>
                  {
                    list.items.map(item => (
                      <li key={item.name} id={item.name} style={{ minHeight: item.duration }} className={list.name + '-item'}>
                        {item.name}
                        {
                          list.group !== 'event' && (
                            <div className="nested" style={{ minHeight: item.duration - 10 }}>
                              {
                                (new Array(Math.floor(item.duration/5)).fill()).map(() => (
                                  <li style={{ height: 5, opacity: 0 }}></li>
                                ))
                              }
                            </div>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
                </div>
              )
            })
          }

        </div>
        </div>
      </div>
    )
  }
}