import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import './styles/RangeSlider.scss'

class RangeSlider extends React.Component {

  static defaultProps = {
    value: 0
  }

  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired
  }

  onChange = (event) => {
    this.props.onChange(event.target.value)
  }

  render() {
    const { value } = this.props
    return (
      <div className='range-slider-container'>
        <div
          className='range-fill'
          style={{ 
            width: value + '%'
           }}
        ></div>
        <input type='range' value={value} onChange={this.onChange} />
      </div>
    )
  }
}

export default observer(RangeSlider)