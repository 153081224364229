import { override } from 'mobx';
import HabitMemberItem from './HabitMemberItem'
import { MemberList } from 'Stores/Member'
import HabitItem from './HabitItem'
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class HabitMemberList extends MemberList {

	@hasOne(() => HabitItem, (habit: HabitItem) => habit.members)
  habit:HabitItem

  get ModelType() {
    return HabitMemberItem
  }

  createItemProps(props:any):any {
    return { habit:this.habit, ...props }
  }

  @override async fetch() {
    return this.fetchState.get('habit/member/list/' + this.habit.id)
  }

}
