import React from 'react'
import './SubMenu.scss'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from '../../../theme/Button'
import { observer } from 'mobx-react'
import { MetricsDayPane, MetricsWeekPane, MetricsProjectPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { models } from 'Stores'

export default observer(() => {
  const addPane = pane => {
    getStore(models.MainPaneGroup).addOrUpdateItem(pane)
  }
  const onClickDay = () => addPane(MetricsDayPane)
  const onClickWeek = () => addPane(MetricsWeekPane)
  const onClickProject = () => addPane(MetricsProjectPane)
  const hasPane = pane => {
    return getStore(models.MainPaneGroup).getItemByPaneType(pane)
  }
  const dayVariant = hasPane(MetricsDayPane) ? 'secondary' : 'outline-dark'
  const weekVariant = hasPane(MetricsWeekPane) ? 'secondary' : 'outline-dark'
  const projectariant = hasPane(MetricsProjectPane) ? 'secondary' : 'outline-dark'
  return (
    <ButtonGroup aria-label="Action Planner" toggle="true">
      <Button onClick={onClickDay} variant={dayVariant} size="xs">Day</Button>
      <Button onClick={onClickWeek} variant={weekVariant} size="xs">Week</Button>
      <Button onClick={onClickProject} variant={projectariant} size="xs">Project</Button>
    </ButtonGroup>
  )
})