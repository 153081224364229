import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import Pressable from 'theme/Pressable'

class StickyTaskDoneCheckbox extends React.Component {

  onClick = () => {
    const { task} = this.props
    task.done = !task.done
  }

  render() {
    const { task } = this.props
    const icon = task.done ? 'checkbox_checked' : 'checkbox_empty'
    return (
      <Pressable
        onClick={this.onClick}
        className={'press-task-done'}
      >
         <Icon
          icon={icon}
          size={15}
          className={'icon-task-done'}
        />
      </Pressable>
     
    )
  }
}

export default observer(StickyTaskDoneCheckbox)