import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Button from '../../theme/Button'
import Icon from '../../theme/Icon'
import ActionPlannerSettings from '../../Components/ActionPlanner/ActionPlannerSettings'
import { withRouter } from 'react-router-dom'
import { observable } from 'mobx'

export default observer(withRouter(class PlannerSettings extends React.Component {

  modalStore = observable({
    title: 'ACTION PLANNER SETTINGS',
      icon: 'settings',
      show: true
  })

  handleClose = () => {
    this.modalStore.show = false
    this.props.history.push('/planner')
  }

  render() {
    const { show, title, icon } = this.modalStore
    return (
      <Modal 
          show={show} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="planner-settings-modal modal-size-90"
        >
        <Modal.Header>
            <Modal.Title>
              <Icon icon={icon} size="xs" color={'#657e82'} className="modal-title-icon" />
              {title}
            </Modal.Title>
            <Button variant="outline" onClick={this.handleClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ActionPlannerSettings />
          </Modal.Body>
        </Modal>
    )
  }
}))