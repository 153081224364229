import { observable, override } from 'mobx';
import { once } from 'Stores/utils'
import { Item } from '../Lists';

export const importWidgetItem = once(() => {
  class WidgetItem extends Item {
      @observable widgetType = null
      
      @observable i = 0
      @observable x = 0
      @observable y = 0

      @observable w = 1
      @observable h = 1
      
      @observable maxH = 2
      @observable deletedDate:Date = null


      @override fromJSON(json:any) {
          console.log('Widget fromJSON', json, json.widgetType)
          super.fromJSON(json)
      }
  }
  return WidgetItem

})