import React from 'react'
import TreksRecapture from '../../../Modules/TreksRecapture'
import config from '../../../Config/RecaptchaConfig'

const debug = require('debug')('treks:docs:captcha:react')

export default class RecaptchaReactDocs extends React.Component {

  state = {
    response: null
  }

  static defaultProps = {
    action: 'registration'
  }

  async componentDidMount() {
    const response = await this.verifyRecaptcha(this.props.action)
    this.setState({ response })
  }

  async verifyRecaptcha(action) {
    const recaptcha = new TreksRecapture(config)
    const response = await recaptcha.verify(action)
    debug('response', response) 
    return response
  }

  render() {

    const { response } = this.state

    debug('render', this.state)

    return (
      <div className="container">
        <div className="row">
        <div className="col">

          <h2>Recaptcha React</h2>
          <p>
            Will validate this user throw recapture from the treks api
          </p>
          <p>
            Capture response: { JSON.stringify(response) }
          </p>
        </div>
        </div>
      </div>
    )
  }
}