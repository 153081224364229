import Model from "."

const debug = require('debug')('treks:ModelEntityManager')

const mapKeyId = Symbol('entity.id.map')
const mapKeyUid = Symbol('entity.uid.map')
const listKey = Symbol('entity.list')
const entityAddedKey = Symbol('entity.added')

/**
 * Model entity manager keeps a single instance of each model 
 * Models are indexed by { id, uid } for performance
 * A list is kept for unindexed 
 * @todo remove list and ensure index is complete
 * @important Webpack code splitting will overwrite Types, so we cannot use instanceof ModelType
 */
export default class ModelEntityManager {

  constructor() {
    this[mapKeyId] = new Map()
    this[mapKeyUid] = new Map()
    this[listKey] = new Map()
  }

  getIdMap(ModelType: typeof Model): Map<string, Model> {
    if (!this[mapKeyId].has(ModelType)) {
      this[mapKeyId].set(ModelType, new Map())
    }
    return this[mapKeyId].get(ModelType)
  }

  getUidMap(ModelType: typeof Model): Map<string, Model> {
    if (!this[mapKeyUid].has(ModelType)) {
      this[mapKeyUid].set(ModelType, new Map())
    }
    return this[mapKeyUid].get(ModelType)
  }

  /**
   * Get List of persisted models by type
   */
  getList(ModelType: typeof Model): Model[] {
    if (!this[listKey].has(ModelType)) {
      this[listKey].set(ModelType, [])
    }
    return this[listKey].get(ModelType)
  }

  findById<T extends typeof Model>(ModelType: T, id: string): InstanceType<T> {
    return this.find(ModelType, { id })
  }

  findByUid<T extends typeof Model>(ModelType: T, uid: string): InstanceType<T> {
    return this.find(ModelType, { uid })
  }

  /**
   * Find a stored model
   * @private
   * @note we match ID and ModelType or just uid (assume UIDs are unique)
   * @note we match list since a model in a different list is a different instance
   */
  find<T extends typeof Model>(ModelType: T, props: { id?: string, uid?: string }): InstanceType<T> {
    if (!props) throw new TypeError('Props {object} must be defined')
    const { id, uid } = props
    if (!id && !uid) return // optimize
    const idMap = this.getIdMap(ModelType)
    const uidMap = this.getUidMap(ModelType)
    const model = idMap.get(id) 
      || uidMap.get(uid) 
      || this.findOne(ModelType, props) // slow
    return model as InstanceType<T>
  }

  private _createHasFilter = (ModelType: typeof Model, props = {}) => (model: Model) => {
    const keys = Object.keys(props) as (keyof Model)[]
    const matchType = model.constructor === ModelType
    const modelProps = model.getProps(keys)
    const matchProps = !keys.some(key => modelProps[key] !== props[key])
    return (matchType && matchProps)
  }

  /**
   * Find all models of type with matching props
   * @note props are compared by reference
   */
  findMany(ModelType: typeof Model, props: any) {
    const hasFilter = this._createHasFilter(ModelType, props)
    return this.getList(ModelType).filter(hasFilter)
  }

  /**
   * Find a model of type with matching props
   * @note props are compared by reference
   */
  findOne(ModelType: typeof Model, props: any) {
    const hasFilter = this._createHasFilter(ModelType, props)
    return this.getList(ModelType).find(hasFilter)
  }

  /**
   * Add model to entity manager if not existing
   */
  add(model: Model) {
    if (!model || !model.isModel) {
      throw new Error('Parameter model must be a Model')
    }
    if (model[entityAddedKey]) {
      // @todo fix dupe uid in em
      // console.warn('Model already added to entity map', model)
      return
    }
    const ModelType = model.constructor as typeof Model
    const idMap = this.getIdMap(ModelType)
    const uidMap = this.getUidMap(ModelType)
    const list = this.getList(ModelType)
    model[entityAddedKey] = true
    let addedToList = false
    if (model.hasAttribute('id')) {
      idMap.set(model.id, model)
      if (!addedToList) list.push(model)
      addedToList = true
    }
    model.onSetId((change) => {
      if (change.oldValue !== undefined) {
        idMap.delete(change.oldValue)
      }
      if (change.newValue) {
        debug('setId', change)
        idMap.set(change.newValue, model)
        if (!addedToList) list.push(model)
        addedToList = true
      }
    }) 
    if (model.hasAttribute('uid')) {
      uidMap.set(model.uid, model)
      if (!addedToList) list.push(model)
      addedToList = true
    }
    model.onSetUid((change) => {
      if (change.oldValue !== undefined) {
        uidMap.delete(change.oldValue)
      }
      if (change.newValue) {
        debug('setUid', change)
        uidMap.set(change.newValue, model)
        if (!addedToList) list.push(model)
        addedToList = true
      }
    })
  }

  /**
   * Get a model or create one
   * @deprecated
   */
  get(ModelType: typeof Model, props: { id?: string, uid?: string }): Model {
    let model = this.find(ModelType, props)
    if (!model) {
      model = ModelType.create() // fix: use create to initialize mobx, relationships
      model.setProps(props)
      debug('Created new model', ModelType.name, { model, props })
      this.add(model)
    }
    return model 
  }

}
