import React from 'react'
import PropTypes from 'prop-types'
import CheckBoxList from './FieldCheckBoxList'

export default class FilterFieldParent extends React.Component {

  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: 'parent',
    label: 'Parent',
    options: [
      {
        id: 'projects',
        label: 'Projects',
        value: 'project'
      },
      {
        id: 'goals',
        label: 'Goals',
        value: 'goal'
      },
      {
        id: 'no_parent',
        label: 'No Parent',
        value: 'no_parent'
      }
    ]
  }

  onChange = value => {
    const { name, label, onChange } = this.props
    onChange({ name, label, value })
  }

  render() {
    const { options, value } = this.props
    return (
      <CheckBoxList
        isMulti
        name="parent"
        options={options}
        onChange={this.onChange}
        value={value}
      />
     )
  }
}