import React from 'react';
import './InboxGroup.scss';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import InboxEntry from './InboxEntry';
import Icon from 'theme/FontAwesomeIcon/FontAwesomeIcon';
import AppLoadingIndicator from 'Screens/App/AppLoadingIndicator';
import ListViewSearch from 'Components/ListView/Shared/ListViewSearch';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { getStore, models } from 'Stores';

class InboxGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: null,
            scrollPos: 0,
            confirmArchive: false,
            filter: {
                archived: null,
            },
        };

        this.groupRef = React.createRef();
    }

    static defaultProps = {
        get inboxList() {
            return getStore(models.InboxList);
        },

        get mainPaneGroupStore() {
            return getStore(models.MainPaneGroup);
        },
    };

    async componentDidMount() {
        await this.loadData();
        //const { mainPaneGroup } = this.props;

        // this.props.inboxList.createProjects()
        // document
        // 	.querySelector('.main-pane-inbox .main-pane-component')
        // 	.addEventListener('scroll', (e) => this.handleScroll(e));
        // const inboxPane = getBucketList().findItem({ slug: 'inbox' });

        // mainPaneGroup.setItems([inboxPane]);
        // const pane = getBucketList().findItem({ slug: 'planner' });
        // mainPaneGroup.addOrUpdateItem(pane, {});
        
    }

    componentWillUnmount() {
        // document
        // 	.querySelector('.main-pane-inbox .main-pane-component')
        // 	.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        let elem = document.querySelector('.main-pane-inbox .main-pane-component');
        const scrollHeight = document.body.clientHeight;
        if (!elem) return
        const containerHeight = elem.getBoundingClientRect().height;

        if (!this.groupRef.current) return;
        const listHeight = this.groupRef.current.getBoundingClientRect().height;
        // const topOfElem = elem.scrollTop;

        console.log(scrollHeight, elem.scrollTop, containerHeight, listHeight, window.scrollY);
        // this.setState({
        //   scrollPos: e.target.scrollTop
        // })

        if ((scrollHeight + elem.scrollTop) + 100 > listHeight + window.scrollY) this.loadData();
    }

    async loadData() {
        if (this.props.inboxList.fetchState.isBusy) return;
        console.log('loading data');
        if (this.props.inboxList.oldest > 0) {
            await this.props.inboxList.fetch([{ type: 'before', val: this.props.inboxList.oldest }]);
        } else await this.props.inboxList.fetch(); //initital load

        return;
    }

    search(term) {
        this.props.inboxList.search(term.value);
    }

    archiveAll() {
        this.props.inboxList.archiveAll();
        this.setState({ showArchived: false });
    }

    render() {
        console.log('render', this.props.inboxList.items);
        // const { groups, entries } = this.state
        const { inboxList } = this.props;
        const { items, unreadComments, unreadActivities, showArchived, total, searching } = inboxList;

        return (
            <div className={'inbox-container'}>
                <div className="floating-menu">
                    {' '}
                    <ListViewSearch onSearch={(t) => this.search(t)} />

                    <div className="unread-counts">
                        <Button
                            style={{
                                padding: 2,
                                height: 30,
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginRight: 10,
                            }}
                            onClick={() => {
                                this.setState({
                                    showArchived: true,
                                });
                            }}
                        >
                            Archive All
                        </Button>
                        {unreadComments}c | {unreadActivities}a | Total: {total}{' '}
                        <ButtonGroup
                            aria-label="Archived"
                            toggle="true"
                            style={{
                                height: 30,
                            }}
                        >
                            <Button
                                onClick={() => this.props.inboxList.switchView()}
                                style={{ padding: 10, paddingTop: 3 }}
                                variant={showArchived ? 'outline' : 'primary'}
                                size="xs"
                            >
                                <Icon set="fa" size="xs" icon="inbox" className="icon-button"></Icon>
                            </Button>
                            <Button
                                onClick={() => this.props.inboxList.switchView()}
                                style={{ padding: 10, paddingTop: 3 }}
                                variant={!showArchived ? 'outline' : 'primary'}
                                size="xs"
                            >
                                <Icon set="fa" size="xs" icon="archive" className="icon-button"></Icon>
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

                <Modal
                    show={this.state.showArchived}
                    size="lg"
                    centered
                    dialogClassName="modal-size-80"
                    onHide={() => {
                        this.setState({ showArchived: false });
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Archive all messages?</Modal.Title>

                        <Button
                            variant="outline"
                            onMouseDown={() => {
                                this.setState({ showArchived: false });
                            }}
                            className="modal-close-button"
                        >
                            <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        This will archive all {total}/{total} messages in your inbox.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.archiveAll()}>Archive All</Button>
                        <Button
                            variant="outline"
                            onClick={() => {
                                this.setState({ showArchived: false });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {!this.props.inboxList.fetchState.isFetched && items.length === 0 ? (
                    <AppLoadingIndicator
                        style={{
                            position: 'absolute',
                            top: '70px',
                            left: 'calc(50%)',
                            zIndex: 1000,
                        }}
                    />
                ) : (
                    <div className="inbox-groups" onScroll={console.log} ref={this.groupRef}>
                        {items && items.length > 0 ? (
                            items.map((i) => <InboxEntry entry={i} comment={i.comment ? i.comment : null} />)
                        ) : (
                            <p>Empty state</p>
                        )}
                        <AppLoadingIndicator
                            style={{
                                display: (searching || (!inboxList.fetchState.isFetched && items.length > 0)) ? 'block' : 'none',
                                position: 'static',
                                top: 0,
                                left: 0,
                                margin:'auto',
                                textAlign: 'center'
                            }}
                    />
                        {/* <div
                            style={{
                                width: '100%',
                                padding: 10,
                                textAlign: 'center',
                                background: 'lightgrey',
                            }}
                            onClick={() => this.loadData()}
                        >
                            End of List (oldest id: {oldest})
                        </div> */}
                    </div>
                )}
            </div>
        );
    }
}

export default observer(InboxGroup)
