import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import moment from 'moment'
import Pressable from 'theme/Pressable'
import { when } from 'mobx'
import { getStore, models } from 'Stores'
import ActionPlanner from 'Stores/ActionPlanner'
import { dateAddDays, numDaysBetweenDates, toDayIndex, toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils'

const debug = require('debug')('treks:planner:week:header')

export default withRouter(observer(class WeekPlannerFlowHeader extends React.Component {

  whenLoginDisposer = null

  static propTypes = {
    actionPlannerStore: PropTypes.objectOf(() => ActionPlanner),
    sessionStore: PropTypes.objectOf(() => models.Session),
    fetchErrorMsg: PropTypes.string,
    updateUrl: PropTypes.bool
  }

  static defaultProps = {
    get sessionStore() {
      return getStore(models.Session)
    },
    fetchErrorMsg: 'An error occurred retrieving your timespan settings',
    updateUrl: false
  }

  async componentDidMount() {
    this.whenLoginDisposer = await when(() => this.props.sessionStore.isLoggedIn)
    // this.fetchWeekTimespans()
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.scrollToFocusedWeek).observe(this.getScrollEl())
    } else {
      window.addEventListener('resize', this.scrollToFocusedWeek)
    }
  }

  componentWillUnmount() {
    if (this.whenLoginDisposer) this.whenLoginDisposer()
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    } else {
      window.removeEventListener('resize', this.scrollToFocusedWeek)
    }
  }

  // @deprecated. Use DayFlow
  // async fetchWeekTimespans() {
  //   const { actionPlannerStore, fetchErrorMsg } = this.props
  //   try {
  //     await Promise.all(actionPlannerStore.dayList.items.map(day => day.fetched()))
  //   } catch(error) {
  //     getAppNotifications().error(error.message || fetchErrorMsg)
  //   }
  // }

  // async fetchTasks() {
  //   const { actionPlannerStore, fetchErrorMsg } = this.props
  //   try {
  //     await actionPlannerStore.fetchWeek()
  //   } catch(error) {
  //     getAppNotifications().error(error.message || fetchErrorMsg)
  //   }
  // }

  setFocusedDate(nextFocusedDate) {
    const { actionPlannerStore, updateUrl, plannerFlow } = this.props
    const { startDate, endDate } = plannerFlow
    actionPlannerStore.setFocusedDate(nextFocusedDate)
    const { weekStartDate, weekEndDate } = this.getWeekStartEndDates(nextFocusedDate)
    plannerFlow.setProps({
      startDate: weekStartDate < startDate ? weekStartDate : startDate,
      endDate: weekEndDate > endDate ? weekEndDate : endDate
    })
    if (updateUrl) {
      this.props.history.push('/week-planner/' + nextFocusedDate.getTime())
    }
    // this.fetchWeekTimespans()
    // this.fetchTasks()
  }

  getWeekStartEndDates(date) {
    const weekStartDate = toStartOfDayDate(dateAddDays(date, -toDayIndex(date)))
    const weekEndDate = new Date(moment(weekStartDate).add(6, 'days'))
    return { weekStartDate, weekEndDate }
  }

  onClickPrev = () => {
    const { focusedDate } = this.props.actionPlannerStore
    const nextFocusedDate = new Date(moment(focusedDate).subtract(1, 'week'))
    this.setFocusedDate(nextFocusedDate)
    setTimeout(() => this.scrollToFocusedWeek(), 50)
  }

  onClickNext = () => {
    const { focusedDate } = this.props.actionPlannerStore
    const nextFocusedDate = new Date(moment(focusedDate).add(1, 'week'))
    this.setFocusedDate(nextFocusedDate)
    setTimeout(() => this.scrollToFocusedWeek(), 50)
  }

  getScrollEl() {
    return document.querySelector('.planner-items-container')
  }

  scrollToFocusedWeek = () => {
    const { actionPlannerStore, plannerFlow } = this.props
    const { focusedDate } = actionPlannerStore
    const { weekStartDate } = this.getWeekStartEndDates(focusedDate)
    const { startDate } = plannerFlow
    const numScreens = numDaysBetweenDates(startDate, weekStartDate) / 7
    const scrollEl = this.getScrollEl()
    if (scrollEl) {
      scrollEl.scrollLeft = scrollEl.offsetWidth * numScreens
    }
  }

  render() {
    const { actionPlannerStore, isFloating, plannerFlow } = this.props
    const { focusedDate, opts } = actionPlannerStore
    const { weekStartDate, weekEndDate } = this.getWeekStartEndDates(focusedDate)
    const startDateTitle = moment(weekStartDate).format(opts.dateFormat)
    const endDateTitle = moment(weekEndDate).format(opts.dateFormat)
    debug('render', { weekStartDate, weekEndDate })
    return (
      <header className={'action-planner-header ' + (isFloating ? 'float' : 'inline')}>
        <div className='header-item'>
          <nav className={'action-planner-header-nav'}>
            <Pressable onClick={this.onClickPrev}>
              <Icon set="fa" icon="chevron-left" size="xs" color={'#808284'} />
            </Pressable>
            <h2 className="action-planner-title">
              <span>{startDateTitle}</span>
              <span>-</span>
              <span>{endDateTitle}</span>
            </h2>
            <Pressable onClick={this.onClickNext}>
              <Icon set="fa" icon="chevron-right" size="xs" color={'#808284'} />
            </Pressable>
          </nav>
        </div>
      </header>
    )
  }

}))
