import React from "react";
import { observer } from "mobx-react";
import Pressable from "theme/Pressable";
import Icon from "theme/Icon";

class TimespansToTrackCheckbox extends React.Component {

  static defaultProps = {
    checked: false
  };

  render() {
    const { value, checked, onChange } = this.props
    return (
      <Pressable onClick={() => onChange(value)}>
        <Icon icon={checked ? 'checkbox_checked' : 'checkbox_empty'} color={'#808285'} />
      </Pressable>
    )
  }

}

export default observer(TimespansToTrackCheckbox)
