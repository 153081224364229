import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './ProjectTabs.css'
import { LocalStorageRequest } from 'Stores/Service'
import { makeAutoObservable } from 'mobx'
import ProjectTasks from './ProjectTasks'
import ProjectFiles from './ProjectFiles'
import ProjectPeople from './ProjectPeople'
import ProjectComments from './ProjectComments'
import ProjectSettings from './ProjectSettings'
import ProjectSprints from './ProjectSprints'
import ProjectMeetings from './ProjectMeetings'
import ProjectMetrics from './ProjectMetrics'
import ProjectActivity from './ProjectActivity';
import { models } from 'Stores'
import Tabs from 'theme/Tabs'

const debug = require('debug')('treks:project:tabs')

const projectTabs = [
  'TASKS',
  'PEOPLE',
  'MEETINGS',
  'SETTINGS',
  'FILES',
  'COMMENTS',
  'SPRINTS',
  'METRICS',
  'HISTORY'
].map(label => ({ label, key: label.toLowerCase() }))


export default withRouter(observer(class ProjectTabs extends React.Component {

  static propTypes = {
    project: PropTypes.instanceOf(models.ProjectItem).isRequired,
  }

  localState = new LocalStorageRequest('ProjectTabs')

  uiState = makeAutoObservable({
    activeTab: null
  })

  setActiveTab(activeTab) {
    this.uiState.activeTab = activeTab
  }

  tabs = {
    tasks: ({ project }) => <ProjectTasks project={project} />,
    files: ({ project }) => <ProjectFiles project={project} />,
    people: ({ project }) => <ProjectPeople project={project} />,
    comments: ({ project }) => <ProjectComments project={project} />,
    settings: ({ project }) => <ProjectSettings project={project} />,
    sprints: ({ project }) => <ProjectSprints project={project} />,
    meetings: ({ project }) => <ProjectMeetings project={project} />,
    metrics: ({ project }) => <ProjectMetrics project={project} />,
    history: ({ project }) => <ProjectActivity project={project} />,
  }

  wizardTabs = {
    plan: ['tasks', 'people', 'sprints', 'settings'],
    execute: ['tasks', 'meetings', 'sprints', 'comments', 'files'],
    analyze: ['metrics', 'history']
  }

  async componentDidMount() {
    const activeTab =  this.getSelectedTabKeyFromUrl() 
      || (await this.localState.get('activeTab')) 
      || 'tasks'
    this.setActiveTab(activeTab)
    debug('mount', { activeTab })
  }

  getSelectedTabKeyFromUrl() {
    const { history } = this.props
    const matches = history.location.pathname.match(/^\/project\/tab\/([a-z]+)$/)
    return matches && matches[1]
  }

  getTabComponent(tab) {
    return this.tabs[tab]
  }

  onSelect = eventKey => {
    this.setActiveTab(eventKey)
    debug('setActiveTab', this.uiState.activeTab)
    // @todo compose routes into <MainPane> so we don't reload when url changes
    //this.props.history.push('/project/tab/' + eventKey) 
    this.localState.set('activeTab', eventKey)
  }

  render() {
    const { project, focusedWizardTabName } = this.props

    const visibleTabNames = this.wizardTabs[focusedWizardTabName || 'plan']
    
    const tabs = visibleTabNames.map(name => {
      return projectTabs.find(tab => tab.key === name)
    })

    const activeKey = visibleTabNames.includes(this.uiState.activeTab)
      ? this.uiState.activeTab
      : visibleTabNames[0]
    const ActiveTab = this.getTabComponent(activeKey)

    debug('render', { activeKey })

    return (
      <>
        <Tabs
          className="project-tabs"
          variant="pills"
          activeKey={activeKey}
          onSelect={this.onSelect}
          tabs={tabs}
        />
        <div className="tab container-scrollable md-light-scrollbar">
          {
            ActiveTab ?  <ActiveTab project={project} /> : null
          }
        </div>
      </>
    )
  }
}))