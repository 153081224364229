import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import moment from 'moment'
import Pressable from 'theme/Pressable'
import { when } from 'mobx'
import { getStore, models } from 'Stores'
import ActionPlanner from 'Stores/ActionPlanner'
import { dateAddDays, numDaysBetweenDates, toDayIndex, toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils'
import { format, setMonth } from 'date-fns';
import { LucideAlignJustify, LucideChevronLeft, LucideChevronRight, LucidePanelLeftClose, LucidePanelLeftOpen, LucidePlay } from 'lucide-react'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow'
import { Session } from 'Stores/Session'
import Select from 'theme/Form/Select'

const getMonthAbbreviations = (): string[] => {
  const abbreviations = new Array(12).fill(null).map((_, index) => {
    // Create a date set to the ith month of the current year
    const date = setMonth(new Date(), index);
    // Format the date to get the abbreviated month name
    const abbreviatedMonth = format(date, 'MMM');
    return abbreviatedMonth
  })
  return abbreviations;
};

const debug = require('debug')('treks:planner:week:header')

type WeekCalendarHeaderProps = {
  actionPlannerStore: ActionPlanner;
  plannerFlow: PlannerFlow;
  isFloating: boolean;
  onClickCloseLeftPanel: () => void;
  onClickOpenLeftPanel: () => void;
  isLeftPanelOpen: boolean;
  sessionStore?: Session;
  fetchErrorMsg?: boolean;
  updateUrl?: boolean;
  dateFormat?: string;
  yearFormat?: string;
}

export default withRouter(observer(class WeekCalendarHeader extends React.Component<WeekCalendarHeaderProps> {

  whenLoginDisposer = null
  resizeObserver = null;

  static defaultProps = {
    get sessionStore() {
      return getStore(models.Session)
    },
    fetchErrorMsg: 'An error occurred retrieving your timespan settings',
    updateUrl: false,
    dateFormat: 'MMM D',
    yearFormat: 'YYYY',
  }

  async componentDidMount() {
    this.whenLoginDisposer = await when(() => this.props.sessionStore.isLoggedIn)
    // this.fetchWeekTimespans()
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.scrollToFocusedWeek).observe(this.getScrollEl())
    } else {
      window.addEventListener('resize', this.scrollToFocusedWeek)
    }
  }

  componentWillUnmount() {
    if (this.whenLoginDisposer) this.whenLoginDisposer()
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    } else {
      window.removeEventListener('resize', this.scrollToFocusedWeek)
    }
  }

  // @deprecated. Use DayFlow
  // async fetchWeekTimespans() {
  //   const { actionPlannerStore, fetchErrorMsg } = this.props
  //   try {
  //     await Promise.all(actionPlannerStore.dayList.items.map(day => day.fetched()))
  //   } catch(error) {
  //     getAppNotifications().error(error.message || fetchErrorMsg)
  //   }
  // }

  // async fetchTasks() {
  //   const { actionPlannerStore, fetchErrorMsg } = this.props
  //   try {
  //     await actionPlannerStore.fetchWeek()
  //   } catch(error) {
  //     getAppNotifications().error(error.message || fetchErrorMsg)
  //   }
  // }

  setFocusedDate(nextFocusedDate) {
    const { actionPlannerStore, updateUrl, plannerFlow } = this.props
    const { startDate, endDate } = plannerFlow
    actionPlannerStore.setFocusedDate(nextFocusedDate)
    const { weekStartDate, weekEndDate } = this.getWeekStartEndDates(nextFocusedDate)
    plannerFlow.setProps({
      startDate: weekStartDate < startDate ? weekStartDate : startDate,
      endDate: weekEndDate > endDate ? weekEndDate : endDate
    })
    if (updateUrl) {
      this.props.history.push('/week-planner/' + nextFocusedDate.getTime())
    }
    // this.fetchWeekTimespans()
    // this.fetchTasks()
  }

  getWeekStartEndDates(date) {
    const weekStartDate = toStartOfDayDate(dateAddDays(date, -toDayIndex(date)))
    const weekEndDate = new Date(moment(weekStartDate).add(6, 'days'))
    return { weekStartDate, weekEndDate }
  }

  onClickPrev = () => {
    const { focusedDate } = this.props.actionPlannerStore
    const nextFocusedDate = new Date(moment(focusedDate).subtract(1, 'week'))
    this.setFocusedDate(nextFocusedDate)
    setTimeout(() => this.scrollToFocusedWeek(), 50)
  }

  onClickNext = () => {
    const { focusedDate } = this.props.actionPlannerStore
    const nextFocusedDate = new Date(moment(focusedDate).add(1, 'week'))
    this.setFocusedDate(nextFocusedDate)
    setTimeout(() => this.scrollToFocusedWeek(), 50)
  }

  getScrollEl() {
    return document.querySelector('.planner-items-container')
  }

  scrollToFocusedWeek = () => {
    const { actionPlannerStore, plannerFlow } = this.props
    const { focusedDate } = actionPlannerStore
    const { weekStartDate } = this.getWeekStartEndDates(focusedDate)
    const { startDate } = plannerFlow
    const numScreens = numDaysBetweenDates(startDate, weekStartDate) / 7
    const scrollEl = this.getScrollEl()
    if (scrollEl) {
      scrollEl.scrollLeft = scrollEl.offsetWidth * numScreens
    }
  }

  setMonth = (index: number) => {
    const { focusedDate } = this.props.actionPlannerStore
    const nowMonth = focusedDate.getMonth()
    const diffMonths = index - nowMonth
    const nextFocusedDate = new Date(moment(focusedDate).add(diffMonths, 'month'))
    this.setFocusedDate(nextFocusedDate)
    setTimeout(() => this.scrollToFocusedWeek(), 50)
  }

  render() {
    const { actionPlannerStore, isFloating, dateFormat, yearFormat, onClickCloseLeftPanel, onClickOpenLeftPanel, isLeftPanelOpen } = this.props
    const { focusedDate } = actionPlannerStore
    const { weekStartDate, weekEndDate } = this.getWeekStartEndDates(focusedDate)
    const startDateTitle = moment(weekStartDate).format(dateFormat).toUpperCase()
    const endDateTitle = moment(weekEndDate).format(dateFormat).toUpperCase()
    const monthNames = getMonthAbbreviations()
    
    const focusedMonth = focusedDate.getMonth()
    const focusedYear = focusedDate.getFullYear()

    const yearOptions = [focusedYear-1, focusedYear, focusedYear+1].map(year => {
      const yearDate = new Date()
      yearDate.setFullYear(year)
      return {
        label: moment(yearDate).format(yearFormat),
        value: year
      }
    })

    debug('render', { weekStartDate, weekEndDate })
    return (
      <header className={'weeek-calendar-header ' + (isFloating ? 'float' : 'inline')}>
        <div className={'panel-left-header' + (isLeftPanelOpen ? ' open' : ' closed')}>
          {
            isLeftPanelOpen ? (
              <Pressable onClick={onClickCloseLeftPanel}>
                <LucideChevronLeft color={'#6D6F72'} />
                <LucideAlignJustify color={'#6D6F72'} />
              </Pressable>
            ) : (
              <Pressable onClick={onClickOpenLeftPanel}>
                <LucideAlignJustify color={'#6D6F72'} />
                <LucideChevronRight color={'#6D6F72'} />
              </Pressable>
            )
          }
        </div>
        <div className='header-item'>
          <nav className={'calendar-header-nav'}>
            <Pressable onClick={this.onClickPrev} className="nav-button nav-button-prev" style={{ transform: 'rotate(180deg)' }}>
              <svg className="icon icon-calendar-week-prev" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z" clipRule="evenodd"/>
              </svg>
            </Pressable>
            <h2 className="nav-title">
              <span>{startDateTitle}</span>
              <span>-</span>
              <span>{endDateTitle}</span>
            </h2>
            <Pressable onClick={this.onClickNext}  className="nav-button nav-button-next">
              <svg className="icon icon-calendar-week-next" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z" clipRule="evenodd"/>
              </svg>
            </Pressable>
          </nav>
        </div>
        <div className='header-item'>
          <nav className={'calendar-months'}>
            {
              monthNames.map((monthName, i) => {
                return (
                  <Pressable
                    key={monthName}
                    className={'calendar-month' + (focusedMonth === i ? ' focused' : '')}
                    onClick={() => this.setMonth(i)}
                  >
                    {monthName}
                  </Pressable>
                )
              })
            }
          </nav>
        </div>
        <div className='header-item'>
          <nav className='calendar-years'>
            <Select
              style={{ width: 200 }}
              value={String(focusedYear)}
              options={yearOptions}
            />
          </nav>
        </div>
      </header>
    )
  }

}))
