import { createContext, useContext } from "react";
import { createSubTasksStore } from "./SubtasksStore";

const SubTasksContext = createContext(null);

export const useSubTasksStore = () => {
  const store = useContext(SubTasksContext);
  if (!store) throw new Error("useStore must be used within a StoreProvider");
  return store;
};

export const SubTasksProvider = ({ children }) => {
  const store = createSubTasksStore(); // Create a unique store instance
  return (
    <SubTasksContext.Provider value={store}>
      {children}
    </SubTasksContext.Provider>
  )
};
