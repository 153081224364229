import TaskItem from "Stores/Task"
import { TimespanPieceFlow } from "./TimespanPieceFlow"
import { DatedItemFlow } from "./Event/DatedItemFlow"
import { TaskPieceFlow } from "./TaskPieceFlow"
import { toStartOfDayDate } from "../ActionPlannerUtils"
import { PlannerFlow } from "./PlannerFlow"
import { getTaskPieceUid, toTaskPiece } from "./PlannerFlowUtils"

export  const getTaskPiecesFlow = (
  timespanPieces: TimespanPieceFlow[], 
  tasks: TaskItem[], 
  events: DatedItemFlow[],
  plannerFlow: PlannerFlow
): TaskPieceFlow[] => {
    
  let timespanPiecesLeft = [ ...timespanPieces ]
  let currTimespan = timespanPiecesLeft.shift()
  let timespanDurationLeft = currTimespan.duration

  if (!currTimespan) return []

  const taskPieces = [] // do not overwrite
  const tasksLeft = [ ...tasks ]
  const doneTasksLeft = tasksLeft.filter(task => task.done)
  const notDoneTasks = tasksLeft.filter(task => !task.done)

  while(currTimespan && tasksLeft.length) {
    const newPieces = [] // do not overwrite

    const timespanDayTS = toStartOfDayDate(currTimespan.startDate).getTime()
    const todayTS = plannerFlow.todayDate.getTime()

    const notDoneTasksAll = currTimespan.startDuration >= plannerFlow.todayDuration ? notDoneTasks : []
    const notDoneTasksToday = notDoneTasksAll.filter(task => {
      if (!task.isEarmarked) return true
      return timespanDayTS >= todayTS && toStartOfDayDate(task.earmarkedDate).getTime() <= timespanDayTS
    })
    const doneTodayTasks = doneTasksLeft.filter(task => {
      return toStartOfDayDate(task.doneDate).getTime() === timespanDayTS
    })
    
    const task = doneTodayTasks.shift() || notDoneTasksToday.shift()
    
    if (!task) {
      currTimespan = timespanPiecesLeft.shift()
      timespanDurationLeft = currTimespan?.duration
      continue
    }
    
    tasksLeft.includes(task) && tasksLeft.splice(tasksLeft.indexOf(task), 1)
    notDoneTasks.includes(task) && notDoneTasks.splice(notDoneTasks.indexOf(task), 1)
    doneTasksLeft.includes(task) && doneTasksLeft.splice(doneTasksLeft.indexOf(task), 1)

    let taskDurationLeft = task.duration, pieceIndex = 0

    while(currTimespan && taskDurationLeft > 0) {

      const pieceUid = getTaskPieceUid({ task, pieceIndex })

      let eventsDuration = 0
      if (pieceUid === plannerFlow.resizingPieceUid) {
        taskDurationLeft = plannerFlow.resizingPieceDuration
      } else {
        eventsDuration = plannerFlow.eventsFlow.getEventsDurationOnPieceFlow(
          events,
          currTimespan,
          timespanDurationLeft,
          taskDurationLeft
        )
        taskDurationLeft += eventsDuration
      }

      let nextTaskPiece = null

      const startDuration = currTimespan.endDuration - timespanDurationLeft
      const underflow = newPieces.reduce((sum, piece) => sum + piece.duration, 0)
      const overflow = taskDurationLeft - timespanDurationLeft
      if (taskDurationLeft >= timespanDurationLeft) {
        nextTaskPiece = {
          task,
          timespanPiece: currTimespan,
          duration: timespanDurationLeft,
          addedDuration: eventsDuration,
          startDuration,
          endDuration: startDuration + timespanDurationLeft,
          underflow,
          overflow,
          pieceIndex
        }
        taskDurationLeft -= timespanDurationLeft
        currTimespan = timespanPiecesLeft.shift()
        timespanDurationLeft = currTimespan?.duration

      } else {
        nextTaskPiece = {
          task,
          timespanPiece: currTimespan,
          duration: taskDurationLeft,
          addedDuration: eventsDuration,
          startDuration,
          endDuration: startDuration + taskDurationLeft,
          underflow,
          overflow,
          pieceIndex
        }
        timespanDurationLeft -= taskDurationLeft
        taskDurationLeft = 0
      }
      newPieces.push(toTaskPiece({
        ...nextTaskPiece,
        pieces: newPieces,
        list: taskPieces,
        plannerFlow
      }))
      pieceIndex++
    }
    taskPieces.push(...newPieces)
  }
  return taskPieces
}