import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:planner:settings:item')

class PlannerSettingsItem extends React.PureComponent {

  render() {
    const { timespan } = this.props
    const { color } = timespan
    debug('render', timespan)
    return (
      <div className="action-planner-timespan" style={{ backgroundColor: color }}></div>
    )
  }
}

export default observer(PlannerSettingsItem)
