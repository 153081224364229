import React from 'react'
import Icon from 'theme/Icon'

export default class GoalDetailRow extends React.Component {
  render() {
    const { className, icon, children } = this.props
    return (
      <div className={'row' + (className ? ' ' + className : '')}>
        {
          icon && (
            <div className="row-icon">
              <Icon icon={icon} color={'#a4a4a6'} size={18} />
            </div>
          )
        }
        {children}
      </div>
    )
  }
}
