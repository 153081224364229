import TWEEN from '@tweenjs/tween.js'

// tween animation loop
const animate = (time:number) => {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}
requestAnimationFrame(animate);

/**
 * Pure JS implementation of Scrollable
 */
export default class ScrollableJS {

  debug:Function = require('debug')('treks:module:scrollable')

  _panes: Function|HTMLCollection|NodeListOf<Element>|string // eslint-disable-line no-undef
  scrollableRef: HTMLElement
  scrollStartTimer:number|undefined
  scrollEndTimer:number|undefined
  isScrolling:boolean = false
  _scrollTopStart:number = 0
  _triggeredScroll:boolean = false
  scrollStartTime:number = this.now
  scrollMaxTime:number = 1200 // millisecs
  onScroll:Function|undefined// user callback
  scrollId:string = ''
  animated:boolean = true // animate the scroll

  /**
   * Create the scrollable
   * @param scrollableRef Container
   * @param panes A function or css selector is preferred for dynamic panes. HTMLCollection of static panes.
   */
  constructor(scrollableRef:HTMLElement, panes: Function|HTMLCollection|string, onScroll: Function|undefined) {
    this.scrollableRef = scrollableRef
    this._panes = panes
    if (onScroll) this.onScroll = onScroll
    this.create()
    this.debug('created!', this)
  }

  createUid():string {
    return Math.random().toString(36).slice(2)
  }

  get panes(): HTMLCollection|NodeListOf<any> { // eslint-disable-line no-undef
    const { _panes } = this
    if (!_panes) return this.scrollableRef.childNodes
    if (typeof _panes === 'function') return _panes(this)
    return typeof _panes === 'string' ? this.scrollableRef.querySelectorAll(_panes) : _panes
  }

  get now(): number {
    return (new Date().getTime())
  }

  get scrollTopStart(): number {
    return this._scrollTopStart || this.scrollTop
  }
  set scrollTopStart(scrollTop:number) {
    this._scrollTopStart = scrollTop
  }

  get scrollTop(): number {
    return this.scrollableRef ? this.scrollableRef.scrollTop : 0
  }

  get scrollTopDelta(): number {
    return this.scrollTop - this.scrollTopStart
  }

  create() {
    try {
      this.scrollableRef.addEventListener('wheel', this.onScrollWheel, { passive: false })
    } catch(error) {
      this.scrollableRef.addEventListener('wheel', this.onScrollWheel)
    }
  }

  destroy() {
    if (this.scrollableRef) {
      this.scrollableRef.removeEventListener('wheel', this.onScrollWheel)
      delete this.scrollableRef
    }
  }

  onScrollStart() {
    this.scrollTopStart = this.scrollTop
    this.isScrolling = true
    this.scrollStartTime = this.now
    this.scrollId = this.createUid()
    this.debug('scroll start', this.scrollId)
    return this.scrollId
  }

  onScrollEnd = (scrollId:string) => {
    if (this.scrollId !== scrollId) {
      return this.debug('scroll end invalid', scrollId)
    }
    if (this.isScrolling === false) {
      return this.debug('scroll ended already', scrollId)
    }
    this.scrollTopStart = this.scrollTop
    this.isScrolling = false
    this._triggeredScroll = false
    this.debug('scroll end', scrollId)
  }

  onScrollWheel = (event:WheelEvent) => {
    const { scrollTopStart, scrollTop, isScrolling } = this
    this.debug('scroll wheel', { event, scrollTopStart, scrollTop, isScrolling })
    if (!isScrolling) {
      const direction = event.deltaY >= 0 ? 1 : -1
      const startPane = this.findStartPane(direction)
      const nextPane = this.findNextPane(startPane, direction)
      if (!nextPane) return
      const scrollId = this.onScrollStart()
      this.stopScrollEvent(event)
      this.triggerScroll(startPane, nextPane, scrollId)
      this.scrollEndTimer = window.setTimeout(() =>{
        this.onScrollEnd(scrollId)
      }, this.scrollMaxTime)
    } else {
      this.stopScrollEvent(event)
    }
  }

  stopScrollEvent(event:WheelEvent) {
    event.stopPropagation()
    event.preventDefault()
  }

  triggerScroll(startPane:Element, nextPane:Element, scrollId:string) {
    this._triggeredScroll = true
    const { onScroll, scrollTopStart, onScrollEnd } = this
    if (typeof onScroll === 'function') {
      onScroll({ startPane, nextPane, onScrollEnd, scrollId })
    } else {
      this.animated ? this.scrollToAnimated(nextPane) : this.scrollTo(nextPane)
    }
    this.debug('triggered scroll', { nextPane, startPane, onScroll, scrollTopStart })
  }

  scrollToDirection(direction:number) {
    const startPane = this.findStartPane(direction)
    const nextPane = this.findNextPane(startPane, direction)
    this.triggerScroll(startPane, nextPane, this.scrollId)
  }

  scrollTo(pane:Element) {
    this.debug('scrollTo', pane)
    if (!pane) return
    pane.scrollIntoView({behavior: "smooth"})
    this.scrollEndTimer = window.setTimeout(() => this.onScrollEnd(this.scrollId), 500)
  }

  scrollToAnimated(pane:any) {
    const { scrollId, scrollableRef } = this
    if (!pane) return
    const scrollTop = pane.offsetTop
    const store = { scrollTop: this.scrollableRef.scrollTop }
    new TWEEN.Tween(store)
      .to({ scrollTop }, 800)
      .easing(TWEEN.Easing.Exponential.Out)
      .onUpdate(() => {
        this.debug('scrollToAnimated:update', pane, store)
        this.scrollableRef.scrollTo(0, store.scrollTop)
      })
      .onComplete(() => {
        this.onScrollEnd(scrollId)
      })
      .start()
    this.debug('scrollToAnimated', { scrollableRef, pane, scrollTop })
  }

  findStartPane(direction:number):HTMLElement {
    const panes = this.panes
    const closest = Array.from(panes).reduce((closest, pane) => {
      if (!closest) return pane
      const { top:closestTop } = closest.getBoundingClientRect()
      const { top:paneTop } = pane.getBoundingClientRect()
      this.debug('find closest test', closest, closestTop, pane, paneTop)
      return Math.abs(closestTop) < Math.abs(paneTop) // closest to 0
        ? closest : pane
    }, null)
    this.debug('find closest win', closest, direction)
    return closest;
  }

  findNextPane(pane:HTMLElement, direction:number) {
    return this.getPaneByIndex(this.getPaneIndex(pane) + direction)
  }

  getPaneIndex(pane:HTMLElement) {
    const panes = this.panes
    const index = Array.from(Array.from(panes).keys()).find(i => {
      return panes[i] === pane
    })
    return index || 0
  }

  getPaneByIndex(index:number) {
    return this.panes[index]
  }
}
