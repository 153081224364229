import React from 'react'
import FormControlCheckBox from 'theme/Form/FormControlCheckBox'

export const EventAllDay = ({ allDayEvent, onChange }) => {
  const className = allDayEvent ? ' hasValue' : ''
  return (
    <div className={'event-isAllDay-checkbox' +  className}>
      <FormControlCheckBox onChange={onChange} checked={allDayEvent} />
    </div>
  )
}
export default EventAllDay