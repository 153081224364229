import React from 'react'
import { observer } from 'mobx-react'
import TaskFileModal from './TaskFileModal'
import './TaskFileDetail.css'

const debug = require('debug')('treks:task:file:detail')

class TaskFileDetail extends React.Component {

  render() {
    const { fileItem, show, onClose } = this.props
    debug('render', this.props)
    return (
      <TaskFileModal fileItem={fileItem} show={show} onClose={onClose} />
    )
  }
}

export default observer(TaskFileDetail)