import React from 'react'
import { observer } from 'mobx-react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'

const debug = require('debug')('treks:task:checkbox')

class TaskRemoveFromPlannerIcon extends React.Component {

  static defaultProps = {
    icon: 'times',
    size: '1x'
  }

  onClick = event => {
    const { item } = this.props
    debug('toggle check', item)
    event.preventDefault()
    event.stopPropagation()
    this.props.onClick(event)
  }

  render() {
    const { item, size, icon } = this.props
    debug('render', item)
    const color = item.trashed ? '#dcdddf' : '#97ABB0'
    return (
      <Pressable
        onClick={this.onClick}
        className="row-icon task-delete-icon"
        tabIndex="-1"
      >
          <Icon set="fa" name={icon} color={color} size={size} />
      </Pressable>
    )
  }
}

export default observer(TaskRemoveFromPlannerIcon)