import React from 'react'
import Sortable from '../../../Modules/ReactSortable'
import './Sortable.scss'
import SortableReactItem from './SortableReact/SortableReactItem'
import { generateUid, getEventLoggingOptions } from './SortableUtils'
import { observable, action } from 'mobx';
import { observer } from 'mobx-react'

const debug = require('debug')('treks:docs:sortable:react')

class SortableReactDocs extends React.Component {

  store = observable({
    lists: [
      {
        name: 'Event',
        items: [],
        group: 'event'
      },
      {
        name: 'Personal',
        items: [],
        group: {
          name: 'timespan',
          put: ['timespan', 'event', 'subtask']
        }
      },
      {
        name: 'Work',
        items: [],
        group: {
          name: 'timespan',
          put: ['timespan', 'event', 'subtask']
        }
      },
      {
        name: 'Afternoon Personal',
        items: [],
        group: {
          name: 'timespan',
          put: ['timespan', 'event', 'subtask']
        }
      }
    ]
  })

  items = []

  componentWillMount() {
    this.addToLists()
    global.store = this.store
  }

  addToLists() {
    const listLen = Math.floor(Math.random() * 5)
    const tempList = new Array(listLen).fill()
    this.store.lists.forEach(list => {
      let len = list.items.length
      tempList.forEach(() => {
        const id = list.name + '-' + generateUid()
        const name = list.name + ' ' + ++len
        const duration = Math.max(Math.floor(Math.random() * 200), 30)
        const subtasks = Math.random() > 0.7 && list.group !== 'event' ? (
          new Array(Math.floor(Math.random() * 5)).fill().map((val, index) => ({
            id: id + '-subtask-' + index,
            name: list.name + ' sub task ' + index,
            duration: Math.floor(Math.random()*50) + 15,
            subtasks: [],
            fill: []
          }))
        ) : []
        const fillDuration = duration - subtasks.reduce((total, { duration }) => total + duration, 0)
        const fill = (new Array(Math.max(Math.floor(fillDuration/5), 0)).fill()).map((value, index) => ({
          id: id + '-fill-' + index,
          duration: 5,
          subtasks: [],
          fill: []
        }))
        const item = {
          id,
          name,
          type: list.group.name || list.group,
          duration,
          subtasks,
          fill
        }
        list.items.push(item)
      })
    })
  }

  getAllItems() {
    const { lists } = this.store
    const allItems = lists.map(list => [ ...list.items ]).flat()
    const allSubItems = allItems.map(item => [ ...item.subtasks, ...item.fill ]).flat()
    const mergedItems = [ ...allItems, ...allSubItems, ...this.items ]
    const uniqueMergedItems = this.setItems(mergedItems.filter((item, index) => mergedItems.indexOf(item) === index))
    debug('getAllItems', { allItems, allSubItems, mergedItems, uniqueMergedItems })
    return uniqueMergedItems
  }

  getItemsFromOrder(order) {
    const allItems = this.getAllItems()
    const items = order.map(id => allItems.find(item => item.id.toString() === id.toString()))
    return items
  }

  addEvent() {
    const events = this.store.lists[0].items
    events.push({
      id: 'event-' + generateUid(),
      type: 'event',
      name: 'Event ' + (events.length + 1),
      duration: Math.max(Math.floor(Math.random() * 200), 30),
      subtasks: [],
      fill: []
    })
  }

  @action 
  onChange = (list, order, sortable, event) => {
    debug('onChange', { order, sortable, event, list })
    const items = this.getItemsFromOrder(order)
    // put all items in list without removing list ref for mobx
    list.splice(0, list.length, ...items)
  }

  onSortableRef = ref => {
    this.ref = ref
  }

  render() {

    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      ...getEventLoggingOptions()
    };

    debug('render', this.state)

    return (
      <div className="container sortable-list-container">
        <div className="row">
        <div className="col">

          <h2>Sortable React</h2>
          <p>
            Sortable multi list with flow around a fixed item
          </p>
          <p>
            <button onClick={() => this.addToLists()}>Add to Lists</button>
            <button onClick={() => this.addEvent()}>Add Event</button>
          </p>

          {
            this.store.lists.map((list, index) => {
              return (
                <div key={index} className="list-container">
                  <h3 className="list-name">{list.name}</h3>
                  <Sortable
                    ref={this.onSortableRef}
                    className="sortable-list"
                    id={list.name}
                    options={{
                      ...options,
                      group: list.group
                    }}
                    onChange={(...params) => this.onChange(list.items, ...params)}
                  >
                  {
                    list.items.map(item => (
                      <SortableReactItem
                        key={item.id}
                        list={list}
                        item={item}
                        onChange={this.onChange}
                      />
                    ))
                  }
                  </Sortable>
                </div>
              )
            })
          }

        </div>
        </div>
      </div>
    )
  }
}

export default observer(SortableReactDocs)