import React from 'react'
import Icon from 'theme/Icon'

export default class ScrollButton extends React.Component<{
  onClick: () => void;
  direction: string;
  size?: string;
}> {

  render() {
    const { direction, size = 'lg' } = this.props
    return (
      <Icon
        set="fa"
        icon={'chevron-' + direction}
        className={'btn-scroll btn-scroll-' + direction}
        size={size}
        onClick={this.props.onClick}
      />
    )
  }
}
