const debug = require('debug')('treks:draggable')

export const getElTransformPos = (el) => {
  const trans = getComputedStyle(el).getPropertyValue('transform')
  const matrix = trans.replace(/[^0-9\-.,]/g, '').split(',')
  const top = parseFloat((matrix.length > 6) ? matrix[13] : matrix[5]) || 0
  const left = parseFloat((matrix.length > 6) ? matrix[12] : matrix[4]) || 0
  debug('transform', { trans, matrix, top, left })
  return { top, left }
}

export const getElementPos = (el) => {
  return el.getBoundingClientRect ? el.getBoundingClientRect() : {
    top: el.offsetTop,
    left: el.offsetLeft
  }
}
