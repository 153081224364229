import { action, computed, observable } from "mobx";
import Model from "Stores/Model";
import TaskItem from "Stores/Task";
import { round } from "Stores/utils";
import { dateAddMins, toStartOfDayDate } from "../ActionPlannerUtils";
import { DatedItemFlow } from "./Event/DatedItemFlow";
import { PlannerFlow } from "./PlannerFlow";
import { TaskPieceFlow } from "./TaskPieceFlow";
import { TimespanProps } from "./Timespan/TimespanProps";
import { TimespanFlow } from "./Timespan/TimespanFlow";

export class TimespanPieceFlow extends Model {

  @observable startDuration: number = null

  @computed get startDate(): Date {
    return dateAddMins(this.plannerFlow.startDate, this.startDuration)
  }

  @computed get endDate(): Date {
    return dateAddMins(this.plannerFlow.startDate, this.endDuration)
  }

  @computed get startTimestamp(): number {
    return toStartOfDayDate(this.startDate).getTime()
  }

  @computed get endDuration(): number {
    return this.startDuration + this.duration
  }

  @observable flowType: string = 'timespanFlow'

  @observable plannerFlow: PlannerFlow = null

  @observable group: TimespanPieceFlow = null

  @observable timespans: TimespanFlow[] = []

  @computed get firstTimespan() {
    return this.timespans[0]
  }

  @computed get lastTimespan() {
    return this.timespans.slice(-1).pop()
  }

  @computed get unknownTimespan(): TimespanProps {
    return TimespanProps.fromProps({
      type: 'unknown',
      color: '#fff'
    }) as TimespanProps
  }

  @computed get timespanProps(): TimespanProps {
    const props = this.plannerFlow.accountTimespans.items
      .find(timespan => timespan.type === this.type)
    return props || this.unknownTimespan
  }

  @observable isResizing = false

  @computed get title(): string {
    return this.timespanProps.title
  }

  @observable type: string = ''

  @action setType(type: string) {
    this.type = type
    this.timespans.forEach(timespan => timespan.type = type)
  }

  @observable duration: number = 0

  @observable minDuration: number = 5

   @action setDuration(duration: number) {
    this.duration = duration
  }

  @computed get color(): string {
    return this.timespanProps.color
  }

  @computed get timespanFlow(): TimespanPieceFlow[] {
    return this.plannerFlow[this.flowType]
  }

  @computed get pieceIndex(): number {
    return this.timespanFlow.findIndex(piece => piece.uid === this.uid)
  }

  @computed get prevTimespanPiece(): TimespanPieceFlow {
    return this.timespanFlow[this.pieceIndex - 1]
  }

  @computed get nextTimespanPiece(): TimespanPieceFlow {
    return this.timespanFlow[this.pieceIndex + 1]
  }

  @computed get totalPiecesDuration(): number {
    return this.timespanFlow.reduce((sum, piece) => piece.duration + sum, 0)
  }

  @computed get maxTotalPiecesDuration(): number {
    return this.plannerFlow.daysLength * 24 * 60
  }

  @action resizePieceSnapTo(duration: number, modulo: number = 5) {
    this.resizePieceTo(round(duration, modulo))
  }

  /**
   * Set the duration 
   * constraining timespan duration to other timespans in list (24hr total)
   */
  @action resizePieceTo(newDuration: number) {
    const { duration } = this
    if (newDuration <= this.minDuration) newDuration = 0
    const delta = newDuration - duration
    let newDelta = delta
    if (duration + newDelta < 0) {
      newDelta = Math.max(-duration, 0)
    }
    
    // resize next pieces
    const next = this.nextTimespanPiece
    if (next) {
      const nextDuration = next.duration
      const newNextDuration = next.duration - newDelta
      next.setDuration(newNextDuration) // set directly
      newDelta = nextDuration - next.duration // actual applied delta
    }

    // constrain
    if (this.totalPiecesDuration + newDelta > this.maxTotalPiecesDuration) {
      newDelta = this.maxTotalPiecesDuration - this.totalPiecesDuration
    }
    
    this.setDuration(duration + newDelta)
    global.timespanPiece = this
  }

  @computed get isTasksOverflow(): boolean {
    const lastPiece = [ ...this.taskPieces ].pop()
    return lastPiece && lastPiece.overflow > 0
  }

  @computed get tasks(): TaskItem[] {
    return this.plannerFlow.tasks
      .filter(task => task.timespanType === this.type)
  }

  @computed get events(): DatedItemFlow[] {
    return this.plannerFlow.visibleEvents.map(event => (
      DatedItemFlow.fromProps({
        datedItem: event,
        uid: 'dated-' + event.uid,
        plannerFlow: this.plannerFlow
      }) as DatedItemFlow
    ))
  }

  @computed get stickyTasks(): DatedItemFlow[] {
    return this.plannerFlow.stickyTasks.map(task => (
      DatedItemFlow.fromProps({
        datedItem: task,
        uid: 'dated-' + task.uid,
        plannerFlow: this.plannerFlow
      }) as DatedItemFlow
    ))
  }

  @computed get typeIndex(): number {
    return this.plannerFlow.timespanTypes
      .indexOf(this.type)
  }

  @computed get timespanPiecesOfSameType() {
    return this.timespanFlow.filter(piece => piece.type === this.type)
  }

  @computed get taskPiecesFlow(): TaskPieceFlow[] {
    return this.plannerFlow.getTaskPiecesFlow(
      this.timespanPiecesOfSameType,
      this.tasks,
      [ ...this.events, ...this.stickyTasks]
    )
  }

  @computed get taskPieces(): TaskPieceFlow[] {
    return this.taskPiecesFlow.filter(piece => piece.timespanPiece.uid === this.uid)
  }

  @computed get filledDuration() {
    return this.taskPieces.reduce((sum, taskPiece) => {
      return sum + taskPiece.duration
    }, 0)
  }

  @computed get percentSpaceFilled() {
    return this.filledDuration / this.duration * 100
  }

  getTaskPiece(task: TaskItem): TaskPieceFlow {
    return this.taskPieces.find(piece => piece.task === task)
  }

  getTaskVisibleDuration(task: TaskItem): number {
    const taskPiece = this.getTaskPiece(task)
    return taskPiece?.duration || 0 // @todo fix bug in resize taskPiece
  }

  @computed get orderSnapshot(): string[] {
    return this.taskPieces.map(piece => piece.task.uid)
  }

}