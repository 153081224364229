import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskCheckBox from 'Components/TaskDetail/TaskCheckbox'
import TaskTitle from 'Components/TaskDetail/TaskTitle'
import TaskDuration from 'Components/TaskDetail/TaskDuration'

const debug = require('debug')('treks:task:item')

class SubTaskTaskItem extends React.Component {
  render() {
    const { item, list, parent } = this.props
    debug('render', { item, list, parent })
    return (
      <div className="subtask-item">
        <Icon name={'drag'} color={'#97aaae'} size={10} />
        <TaskCheckBox item={item} size={14} />
        <TaskTitle
          item={item}
          list={list}
          parent={parent}
          emptyItemProps={{
            onPlanner: false
          }}
        />
        <div className="attributes">
          <div className="actions">
            <div className="attribute">
              <Icon icon={'assign'} color={'#a4a4a6'} size={18} />
            </div>
            <div className="attribute">
              <Icon icon={'calendar'} color={'#a4a4a6'} size={18} />
            </div>
          </div>
          <TaskDuration item={item} list={list} className={'attribute'} />
        </div>
      </div>
     )
  }
}

export default observer(SubTaskTaskItem)