import React from 'react'
import { observer } from 'mobx-react'
import './TaskDetail.scss'
import TaskAttributes from './TaskAttributes'
import SubTasks from 'Components/ui/SubTasks/SubTasks'
import TaskMembers from './TaskMembers'
import TaskComments from './TaskComments/TaskComments'
import TaskActivity from './TaskActivity'
import TaskAddComment from './TaskComments/TaskAddComment'
import TaskCheckBox from './TaskCheckbox'
import TaskTitle from './TaskTitle'
import TaskDuration from './TaskDuration'
import { UserList }from 'Stores/User'
import TaskPlannerIcon from './TaskPlannerIcon';
import { getAppNotifications } from 'AppNotifications'
import { when } from 'mobx'
import { IconPlusCircle } from 'theme/Icons/IconPlusCircle'
import { SubTasksProvider } from 'Components/ui/SubTasks/store/SubTasksStoreContext'

const debug = require('debug')('treks:task:detail')

class TaskDetail extends React.Component {

  static defaultProps = {
    userList: UserList.create(), // all users that can view this task
    commentsFetchErrorMsg: 'An error occurred fetching the task comments',
    usersFetchErrorMsg: 'An error occurred loading the task users'
  }

  componentDidMount() {
    const { item, userList, usersFetchErrorMsg, commentsFetchErrorMsg } = this.props
    userList.fetch()
      .catch(error => {
        console.error(usersFetchErrorMsg, error)
        getAppNotifications().error(usersFetchErrorMsg)
      })
    this.commentsFetchDisposer = when(() => item.id, () => {
      item.commentList.fetch()
        .catch(error => {
          console.error(commentsFetchErrorMsg, error)
          getAppNotifications().error(commentsFetchErrorMsg)
        })
    }) 
  }

  componentDidUnMount() {
    if (this.commentsFetchDisposer) {
      this.commentsFetchDisposer()
    }
  }

  render() {
    const { pane, item, list, userList } = this.props
    debug('render', { pane, item })
    return (
      <div className="task-detail">
        <div className="container">
          <header className="row header">
            <TaskCheckBox item={item} iconBusySize="lg" />
            <TaskTitle item={item} list={list} listenUpdates={true} />
            <TaskPlannerIcon item={item} />
            <TaskDuration list={list} item={item} disabled={item.done} />
          </header>
        </div>
        <TaskAttributes 
          item={item} 
          list={list}
          userList={userList}
        />
        <div className="container-scrollable md-light-scrollbar">
          <SubTasksProvider>
            <SubTasks item={item} />
          </SubTasksProvider>
          <TaskMembers
            item={item}
            userList={userList}
            addMemberPlaceholder={
              <div style={{ 
                display: 'flex',
                alignItems: 'center',
              }}>
                <IconPlusCircle size={18} />
                <span style={{ marginLeft: 5 }}>{'Add Members'}</span>
              </div>
            }
          />
          <TaskComments account={item.account} commentList={item.commentList} />
          <TaskActivity task={item} activityList={item.activityList} />
        </div>
        <TaskAddComment pane={pane} account={item.account} store={item.commentList} />
      </div>
    )
  }
}

export default observer(TaskDetail)