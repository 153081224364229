import React from 'react'
import { 
  AccountRegister,
  AccountVerifyEmail,
  AccountVerifyToken,
  AccountRegisterProfile,
  AccountRegisterTeam,
  AccountRegisterAddTeammates,
  AccountRegisterComplete,
  AccountStartFreeTrial,
} from '../../../Components/Account/Register'
import { Switch, Route } from 'react-router-dom'

export class AccountReigsterRoutes extends React.Component {

  render() {
    return (
      <Switch>
        <Route path="/account/register/complete" component={AccountRegisterComplete} />
        <Route path="/account/register/start-free-trial" component={AccountStartFreeTrial} />
        <Route path="/account/register/add-teammates" component={AccountRegisterAddTeammates} />
        <Route path="/account/register/team" component={AccountRegisterTeam} />
        <Route path="/account/register/profile" component={AccountRegisterProfile} />
        <Route path="/account/register/verify-token" component={AccountVerifyToken} />
        <Route path="/account/register/verify/:email" component={AccountVerifyEmail} />
        <Route path="/account/register" component={AccountRegister} />
      </Switch>
    )
  }
}