import React from 'react'
import { observer } from 'mobx-react';
import SingleValue from './SingleValue'
import Pressable from '../../Pressable';

class PressableSinglevalue extends React.Component {

  render() {
    const { childProps, ...props } = this.props
    return (<Pressable {...props}>
      <SingleValue {...childProps}></SingleValue>
    </Pressable>)
  }
}

export default observer(PressableSinglevalue)