import { FormControlDropdown, FormControlCountryDropdown, ProfilePhotoFormControl } from 'theme/Form'
const fields = [
  {
    name: 'profileImage',
    label: 'Profile Photo',
    link: 'Upload Your Photo',
    info: 'Your photo helps teammates quickly identify you & your work on TREKS',
    Component: ProfilePhotoFormControl,
    value: null,
  },
  {
    name: 'name',
    label: 'Full Name',
    placeholder: 'First & Last Name',
    value: null,
    required: true
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Password (8 characters or longer)',
    value: null,
    type: 'password',
    required: true
  },
  {
    name: 'email',
    label: 'Primary Email',
    placeholder: '',
    disabled: true,
    value: null,
    type: 'email',
    required: true
  },
  {
    name: 'country',
    label: 'Country',
    placeholder: 'Country of Residence',
    items: [],
    Component: FormControlCountryDropdown,
    value: null,
  },
  {
    name: 'phone',
    label: 'Phone',
    placeholder: 'Phone Number (Optional)',
    value: null,
  },
  {
    name: 'phoneType',
    label: 'Phone Type',
    placeholder: 'Select Phone Type (Optional)',
    items: [
      {
        label: 'Mobile',
        value: 'mobile',
      },
      {
        label: 'Home',
        value: 'home',
      },
      {
        label: 'Work',
        value: 'work',
      },
    ],
    Component: FormControlDropdown,
    value: null,
  },
]

export default fields