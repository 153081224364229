import React from 'react'
import { DueTodayItem } from './'
import './DueToday.scss'
import { observer } from 'mobx-react'
import Sortable from '../../../Modules/ReactSortable/ReactSortable'
import stores, { getStore } from 'Stores'

const debug = require('debug')('treks:planner:DueTodayGroup')

class DueToday extends React.Component {

  static defaultProps = {
    get actionPlannerStore() {
      return getStore(stores.ActionPlanner)
    }
  }

  onChange = order => {
    debug('onChange', { order })
    const { actionPlannerStore } = this.props
    const items = actionPlannerStore.getItemsByUids(order)
      .filter(item => item) // invalid cached uids
    items.forEach(item => item.setProps({ onPlanner: false, dueDate: new Date() })) // move to dueToday
  }

  render() {
    debug('render', this.props)
    const { modelName, items , groupLabel } = this.props
    return (
      <div className="due-today-group" key={modelName}>
        {
          groupLabel ? (
            <div className="due-today-label">
              {groupLabel}
            </div>
          ) : null
        }
        <Sortable
          className="due-today-items"
          options={{
            handle: '.icon-drag',
            animation: 100,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            group: {
              name: 'dueToday',
              put: ['timespan', 'subtasks']
            },
            onStart: this.onDragStart,
            onEnd: this.onDragStop
          }}
          onChange={this.onChange}
        >
          {
            (items || []).map(item => <DueTodayItem item={item} key={item.id} />)
          }
        </Sortable>
      </div>
    )
  }

}

export default observer(DueToday)