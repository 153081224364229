import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'

export default observer(function FormControlCardNumber(field) {
  let { onChange, value } = field
  return (
    <Form.Control 
      {...field}
      value={value || ''}
      onChange={event => onChange && onChange(event.target.value)}
    />
  )
})