import Model from "Stores/Model"
import { computed, observable, override } from "mobx"
import TaskItem from "Stores/Task"
import { SubTaskListList } from "Stores/Task/SubTask"
import { MemberList } from "Stores/Member"
import { GoalItem } from "Stores/Goal"
import { ProjectItem } from "Stores/Project"
import HabitItem from "Stores/Habit/HabitItem"
import { UserI } from "Stores/User/Type/User"

type ParentModel = GoalItem & ProjectItem

export class CumulativeStats extends Model {

  @override async fetch() {
    this.model.taskList.fetchNextPage()
    // await this.model.categoryList.fetched()
    // this.model.categoryList.items.map(cat => cat.taskList.fetchNextPage())
  }

  @observable model:ParentModel

  @computed get memberList():MemberList {
    return this.model.memberList
  }

  @computed get subTasksList():SubTaskListList {
    return this.model.subTasksList
  }

  // stats
  @computed get members():UserI[] {
    return this.memberList.visibleItems
  }

  @computed get followers():UserI[] {
    return this.model.followerList.visibleItems
  }

  @computed get habits():HabitItem[] {
    return this.model.habits.visibleItems
  }

  @computed get overdueHabits():HabitItem[] {
    return this.model.habits.items.filter((habit:HabitItem) => !habit.isPercentCompleteOnTime)
  }

  @computed get subGoals():GoalItem[] {
    return this.model.subgoals.visibleItems as GoalItem[]
  }

  @computed get tasks():TaskItem[] {
    // const catTaskLists = this.model.categoryList.items.map(cat => cat.taskList)
    // const catTasks = catTaskLists.reduce((tasks, list) => [ ...tasks, ...list.visibleItems ], [])
    // return [ ...catTasks, ...this.model.taskList.visibleItems]
    return this.model.taskList.visibleItems
  }

  @computed get taskCount():TaskItem[] {
    const catTaskLists = this.model.categoryList.items.map(cat => cat.taskList)
    const catTasks = catTaskLists.reduce((sum, list) => sum + list.totalItems, 0)
    return catTasks + this.model.taskList.totalItems
  }

  @computed get blockedTasks():TaskItem[] {
    return this.tasks.filter(({ blockingList }) => blockingList && blockingList.items.length)
  }

  @computed get overdueTasks():TaskItem[] {
    return this.tasks.filter((task:TaskItem) => !task.isPercentCompleteOnTime)
  }

  @computed get totalDuration():number {
    return this.tasks.map(({ duration }) => duration)
      .reduce((sum, duration) => sum + duration, 0)
  }

  @computed get totalHours():number {
    return Number((this.totalDuration / 60).toFixed(1))
  }

  @computed get totalDurationDone():number {
    return this.tasks
      .map(({ durationDone }) => durationDone)
      .reduce((sum, duration) => sum + duration, 0)
  }

  @computed get totalHoursDone():number {
    return Number((this.totalDurationDone / 60).toFixed(1))
  }

  @computed get completePercent():number {
    if (!this.totalDurationDone) return 0
    const percent:number = (this.totalDurationDone / this.totalDuration)
    return Number(percent.toFixed(1)) * 100
  }

  @computed get planCompletePercent():number {
    return this.model.planCompletePercent
  }

  @computed get date3DaysAgo():Date {
    return new Date(this.currentDate.getTime() - 24*60*60*1000*3)
  }

  @computed get velocity():number {
    if (!this.totalDurationDone) return 0
    const totalDurationDone3Days = this.tasks
      .filter(({ doneDate }) => doneDate >= this.date3DaysAgo)
      .map(({ durationDone }) => durationDone)
      .reduce((sum, duration) => sum + duration, 0)
    return Number((totalDurationDone3Days / ( 60 * 3 )).toFixed(1))
  }
  // end stats
}