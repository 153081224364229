import React from 'react'

export default class ProjectSprintHeader extends React.Component {

  render() {
    return (
      <div className="sprint list-row row-flex">
        <div className="list-col icon"></div>
        <div className="list-col sprint-num">
          Sprint
        </div>
        <div className="list-col task-count">
          Number of Tasks
        </div>
        <div className="list-col complete-percent">
          % Complete/Target
        </div>
        <div className="list-col sprint-date">
          End Date
        </div>
      </div>
    )
  }
}