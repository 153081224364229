import { observable, action, computed } from 'mobx'
import Model from './Model'
import { importAccount } from './Account/Account'
import ApiRequest from './Service/ApiRequest'
import { importUserSettings } from './Account/UserSettings'
import { once } from './utils'
import { LocalDeviceStorage } from './Service/LocalDeviceStorage'
import { hasOne } from '../Relationships/RelationshipDecorators'
import { uid } from './utils'

const debug = require('debug')('treks:store:session')

export class Session extends Model {

  storageAdapter:LocalDeviceStorage = new LocalDeviceStorage()

  setStorageAdapter(adapter) {
    this.storageAdapter = adapter
  }

  // secure token. Do not share
  @observable token = null

  // from server unique to session token (shared by devices). Non-secure
  @observable tokenUid = null

  // client side unique to each device. Non-secure
  @observable deviceUid = uid()

  @hasOne(() =>importAccount())
  Account: ReturnType<typeof importAccount>

  @hasOne(() => importUserSettings())
  userSettings: ReturnType<typeof importUserSettings>

  @observable authState = new ApiRequest()

  @computed get isLoggedIn() {
    return this.authState.isSuccess
  }

  async isAuthenticated() {
    try {
      if (!this.isAuthReqComplete) {
        await this.auth()
      }
      return this.authState.isSuccess
    } catch(error) {
      debug('isAuth error', error)
      throw error
    }
  }

  @computed get isAuthReqComplete() {
    return this.authState.isFetched
  }

  @computed get isAuthReqPending() {
    return this.authState.isBusy
  }

  /**
   * Authenticate the current session
   * @return {object} Response data
   */
  @action async auth() {
    const token = await this.getToken()
    if (!token) {
      // @todo move side-effect out
      await this.logout()
      return false
    }
    debug('fetch account', token)
    return this.authState.post('user/auth', { token })
      .then(async resp => {
        debug('resp', resp)
        this.Account.setProps(resp.data.user)
        await this.setToken(resp.data.token)
        this.setTokenUid(resp.data.tokenUid)
        return true
      })
      .catch(error => {
        debug('Auth error', error)
        throw error
      })
  }

  /**
   * Create an authenticated session
   * @param {object} { email, password }
   */
  @action async login({ email, password }) {
    debug('login account', { email, password })
    return this.authState.post('user/login', { email, password })
      .then(resp => {
        debug('resp', resp)
        this.Account = importAccount().fromJSON(resp.data.user)
        this.setTokenUid(resp.data.tokenUid)
        return this.setToken(resp.data.token)
      })
  }

  /**
   * Logout clear session and account
   */
  @action async logout() {
    this.Account = importAccount().create()
    await this.setToken(null)
    this.setTokenUid(null)
    return this.authState.setRequest(Promise.reject(new Error('Logged out')))
      .catch(error => error) // intentional error
  }

  /**
   * Set non-crypto tokenUid used to identify this unique device session
   * @param {string} tokenUid 
   */
  setTokenUid(tokenUid) {
    this.tokenUid = tokenUid
  }

  /**
   * Set the encrypted session token to local storage
   * @param {string} token 
   */
  async setToken(token) {
    this.token = token
    return this.storageAdapter.setItem('token', token)
  }

  /**
   * Retrieve encrypted session token from local storage
   */
  async getToken() {
    if (!this.token) {
      this.token = await this.storageAdapter.getItem('token')
    }
    return this.token
  }

}

export const importSession = once(() => {
  
  return Session
})