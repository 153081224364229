import { observable, computed } from 'mobx'
import { Item } from '../Lists';
import { GoalItem } from '../Goal'
import User from '../User';
import HabitItem from '../Habit/HabitItem';
import moment from 'moment';

/**
 * Habit and Goal Data
 */
export default class DataItem extends Item {

  @observable name:string = null

  @observable owner:User = null

  @observable type:string = null

  @observable value:string = null

  @observable units:string = null

  @observable opportunityValue:string = null

  @observable createdTS:Date = null

  @observable dateFormat:string = 'MM/DD/YYYY'

  @computed get createdTSFormatted() {
    return moment(this.createdTS).format(this.dateFormat)
  }

  @observable createdBy:User = null

  @computed get goal():GoalItem {
    return this.getAttribute('goal', () => GoalItem.create())
  }
  set goal(goal:GoalItem) {
    this.setAttribute('goal', goal)
  }

  @computed get habit():HabitItem {
    return this.getAttribute('habit', () => HabitItem.create())
  }
  set habit(habit:HabitItem) {
    this.setAttribute('habit', habit)
  }

}