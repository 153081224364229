import { computed } from "mobx"
import Model from "Stores/Model"

export class PFAAccomplishScoreModel extends Model {

  @computed get planPlanningScore(): number {
    return 0
  }

  @computed get planExecutionScore(): number {
    return 0
  }

  @computed get planScore(): number {
    return 0
  }

  @computed get total(): number {
    return 0
  }

}