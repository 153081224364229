import React from 'react'
import { observer } from 'mobx-react'
import Tabs from 'theme/Tabs'
import { getPFAModalStore } from './stores/PFAModalStore'
import { getPFATabsStore } from './stores/PFATabsStore'
import Icon from 'theme/Icon'
import loadable from '@loadable/component'
import { withRouter } from 'react-router-dom'

class PFAModalBody extends React.Component {

  static defaultProps = {
    defaultTabKey: 'day',
    modelStore: getPFAModalStore(),
    tabsStore: getPFATabsStore()
  }

  componentDidMount() {
    const SettingsIcon = () => <Icon icon="settings" />
    this.props.tabsStore.setTabs([
      {
        key: 'day',
        label: 'DAY',
        component: loadable(() => import('./views/PFADayView'))
      },
      {
        key: 'week',
        label: 'WEEK'
      },
      {
        key: 'month',
        label: 'MONTH'
      },
      {
        key: 'settings',
        label: <SettingsIcon />,
        component: loadable(() => import('./views/PFASettingsView'))
      }
    ])
    this.mountDefaultTab()
  }

  mountDefaultTab() {
    const { tabsStore } = this.props
    if (!tabsStore.activeTabKey) {
      tabsStore.setActiveTabKey(this.props.defaultTabKey)
    }
    this.onSelectTab(tabsStore.activeTabKey)
  }

  handleClose = () => {
    this.props.modelStore.hide()
  }

  onSelectTab = (tabKey) => {
    this.props.tabsStore.setActiveTabKey(tabKey)
    this.props.history.push({ hash: `/pfa/${tabKey}` })
  }

  render() {
    const { tabsStore } = this.props
    const ActiveComponent = tabsStore.activeComponent
    return (
      <div className='pfa-container'>
        <Tabs
          className='pfa-modal-tabs'
          tabs={[ ...tabsStore.tabs ]}
          onSelect={this.onSelectTab}
          activeKey={tabsStore.activeTabKey}
        />
        <div className='pfa-body'>
          <ActiveComponent />
        </div>
      </div>
    )
  }
}

export default withRouter(observer(PFAModalBody))