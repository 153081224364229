import { hasOne } from "Relationships/RelationshipDecorators";
import { observable, action, override } from "mobx";
import moment from "moment";
import { ActivityItem } from "Stores/Activity";
import CommentItem from "Stores/Comment/CommentItem";
import { ListI } from "Stores/Lists/Type/List";
import { ProjectItem } from "Stores/Project";
import TaskItem, { TaskList } from "Stores/Task";
import { importUser } from "Stores/User";
import { UserI } from "Stores/User/Type/User";
import { once } from "Stores/utils";
import { Item } from "../Lists";
import InboxList from "./InboxList";

export const importInboxItem = once(() => {
  class InboxItem extends Item {
    get ListType() {
      return InboxList;
    }

    @observable sender: UserI = importUser().create(); // creator

    @observable assignedTo: UserI = importUser().create();

    @hasOne(() => TaskItem)
    task: TaskItem;

    @hasOne(() => ProjectItem)
    project: ProjectItem;

    @hasOne(() => ActivityItem)
    activity: ActivityItem;

    @hasOne(() => CommentItem)
    comment: CommentItem;

    @observable replyId: string;

    @observable taskId: Number;
    @observable projectId: Number;

    @observable title: String = null;
    @observable icon: String = null;
    @observable text: String = null;

    @observable createDate: Date = null;
    @observable archivedDate: Date = null;
    @observable readDate: Date = null;

    @observable taskList: ListI = TaskList.create();

    @action archive() {}

    @override async fromJSON(data: any) {
      const { activity, comment, task, project } = data;
      if (activity) {
        if (activity.changes) {
          const { dueDate, assignedUserId, done } = activity.changes;

          if (dueDate) this.text = moment(dueDate).format("ll");

          if (assignedUserId) {
            this.assignedTo = importUser().fromProps({ id: assignedUserId });
            await this.assignedTo.fetched();

            this.text = "Assigned to " + this.assignedTo.name;
          }

          if (done) this.text = task && task.title;
        }
        this.icon = "icon-task";
      }
      if (comment) {
        this.comment = CommentItem.fromJSON({ ...comment });
        this.icon = "icon-comment";
      }

      if (task) {
        this.task = TaskItem.fromJSON({ ...task });
        if (task.project)
          this.task.project = ProjectItem.fromJSON({ ...task.project });
      }

      if (project) {
        this.project = ProjectItem.fromJSON({ ...project });
      }

      this.sender = importUser().fromProps({
        id: data.comment ? data.comment.userId : data.activity.userId,
      });
      await this.sender.fetched();

      //fromjson without project and task
      const clone = (({ project, task, ...o }) => o)(data);

      return super.fromJSON(clone);
    }

    async save() {
      return this.saveState.postJson("inbox/save", this.toJSON());
    }
  }

  return InboxItem;
});
