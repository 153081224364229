import React from 'react'
import { observer } from 'mobx-react';
import TaskDetailRow from './TaskDetailRow'
import TaskDescription from './TaskDescription'
import TaskDueDate from './TaskDueDate'
import TaskOnPlanner from './TaskOnPlanner'
import TaskProjectSelect from './TaskProjectSelect'
import TaskCategorySelect from './TaskCategorySelect'
import TaskAssign from './TaskAssign'
import TaskFiles from './TaskFiles'
import TaskCompletion from './TaskCompletion'
import TaskEarmarked from './TaskEarmarked'
import TaskLabelSelect from './TaskLabelSelect';

const TaskAttributes = ({ item, list, userList }) => {
  return (
    <div className="container">
      <TaskCompletion item={item} />
      <TaskDetailRow icon={'details'} hasValue={item.description}>
        <TaskDescription item={item} />
      </TaskDetailRow>
      <TaskDetailRow icon={'assign'} hasValue={item.assignedUser.name}>
        <TaskAssign item={item} userList={userList} />
      </TaskDetailRow>
      <TaskDetailRow icon={'calendar'} hasValue={item.dueDate}>
        <TaskDueDate item={item} list={list} />
      </TaskDetailRow>
      {
        item.isEarmarked && (
          <TaskDetailRow hasValue={item.isEarmarked}>
            <TaskEarmarked item={item} list={list} />
          </TaskDetailRow>
        )
      }
      <TaskDetailRow icon={'project'} hasValue={item.project.title}>
        <TaskProjectSelect item={item} />
      </TaskDetailRow>
      <TaskDetailRow icon={'category'} hasValue={item.category.title}>
        <TaskCategorySelect item={item} categoryList={item.project.categoryList} />
      </TaskDetailRow>
      <TaskDetailRow icon={'hashtag'} iconSet={'fa'} hasValue={item.taskLabelList.items.length}>
        <TaskLabelSelect item={item} />
      </TaskDetailRow>
      <TaskDetailRow icon={'attach_file'} hasValue={item.fileList.items.length}>
        <TaskFiles item={item} />
      </TaskDetailRow>
      <TaskDetailRow icon={'action_planner'} className="row-on-planner" hasValue={item.onPlanner}>
        <TaskOnPlanner item={item} list={list} />
      </TaskDetailRow>
    </div>
  )
}

export default observer(TaskAttributes)