import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import GoalFileItem from './GoalFileItem'

const debug = require('debug')('treks:goal:files:list')

class GoalFilesList extends React.Component {

  state = observable({
    showModal: false
  })

  render() {
    const { fileList } = this.props.goal
    debug('render', { fileList })
    return (
      <div className="goal-file-list">
        {
          fileList.items.map(fileItem => {
            return <GoalFileItem key={fileItem.hash} fileItem={fileItem} />
          })
        }
      </div>
    )
  }
}

export default observer(GoalFilesList)
