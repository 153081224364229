import { PFAPlanScoreModel } from "Containers/PFA/models/PFAScore/PFAPlanScoreModel";


export const getExecutionPieGraphData = (planScore: PFAPlanScoreModel): any[] => {
  return [
    {
      "id": "As Planned",
      "label": "As Planned",
      "value": (100 - planScore.thrashScorePercent),
      "color": "#57BB66"
    },
    {
      "id": "Plans Changed",
      "label": "Plans Changed",
      "value": planScore.thrashScorePercent,
      "color": "#D19490"
    }
  ]
}
