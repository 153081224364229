import users from 'Stores/User/items'
const timestamp = (new Date().getTime())
const members = [
  {
    id: 1,
    user: users[0],
    role: 'owner'
  },
  {
    id: 0,
    user: users[1],
    role: 'member'
  },
]
const goals = [
  {
    id: 1,
    title: 'Lose 20 lbs from October 1, 2018 to December 31, 2018',
    members,
    createdBy: users[0],
    startDate: new Date(timestamp - 1000*60*60*24*3),
    endDate: new Date(timestamp - 1000*60*60*24*(3+2)),
    fromValue: '9:15',
    toValue: '9:30',
    status: 'active',
    units: 'minutes'
  },
  {
    id: 2,
    members,
    title: 'Lose 10 lbs from Jan 1, 2019 to Jan 31, 2019',
  },
  {
    id: 3,
    members,
    title: 'Lose 5 lbs from Feb 1, 2018 to Feb 31, 2018',
  }
]
const habits = [
  {
    id: 0,
    title: 'Morning Weigh-In',
    members,
    createdBy: users[0],
    createdTS: new Date(timestamp + 1000*60*60),
    data: {
      type: 'Minimize',
      value: 165.4
    }
  },
  {
    id: 1,
    title: 'Calorie Intake',
    members,
    createdBy: users[1],
    createdTS: new Date(timestamp + 1000*60*60*24*3),
    data: {
      type: 'Minimize',
      value: 250
    }
  },
  {
    id: 2,
    title: 'Calorie Burn',
    members,
    createdBy: users[1],
    createdTS: new Date(timestamp + 1000*60*60*24*3),
    data: {
      type: 'Maximize',
      value: 0
    }
  }
]

const meetings = [
  {
    id: 0,
    title: 'Standup',
    createdBy: users[0],
    users: [ users[0], users[1] ],
    meetingDate: new Date(timestamp - 1000*60*60*24*3)
  },
  {
    id: 1,
    title: 'Long term planning',
    createdBy: users[1],
    users,
    meetingDate: new Date(timestamp - 1000*60*60*24*1),
    recurring: true
  },
  {
    id: 2,
    title: 'Weekly Accountability',
    createdBy: users[1],
    users,
    meetingDate: new Date(timestamp + 1000*60*60*24*3),
    recurring: true
  },
  {
    id: 3,
    title: 'Monthly Reflection',
    createdBy: users[1],
    users,
    meetingDate: new Date(timestamp + 1000*60*60*24*23),
    recurring: true
  }
]

const subgoals = [
  {
    id: 0,
    title: 'Run 8.0 Min Mile for 5 Miles by Mar 31, 2018',
    createdBy: users[0],
    owner: users[1],
    startDate: new Date(timestamp - 1000*60*60*24*3),
    endDate: new Date(timestamp - 1000*60*60*24*(3+2)),
    fromValue: '9:15',
    toValue: '9:30',
    status: 'active',
    units: 'minutes'
  },
  {
    id: 1,
    title: 'MTB Lilac Trail in 3:00 Min by Mar 31, 2018',
    createdBy: users[1],
    owner: users[1],
    startDate: new Date(timestamp - 1000*60*60*24*1),
    endDate: new Date(timestamp - 1000*60*60*24*(1+2)),
    fromValue: '3:45',
    toValue: '3:49',
    status: 'active',
    units: 'miles'
  },
  {
    id: 2,
    title: 'Close $1.5mil in Sales by Dec 31, 2018',
    createdBy: users[1],
    owner: users[1],
    startDate: new Date(timestamp + 1000*60*60*24*3),
    endDate: new Date(timestamp + 1000*60*60*24*(3+2)),
    fromValue: '$150,500',
    toValue: '$225,000',
    status: 'active',
    units: '$',
    rollupData: true
  },
  {
    id: 3,
    title: 'Close $1.5mil in Sales by Dec 31, 2018',
    createdBy: users[1],
    owner: users[1],
    startDate: new Date(timestamp + 1000*60*60*24*23),
    endDate: new Date(timestamp + 1000*60*60*24*(23+2)),
    fromValue: '$250,500',
    toValue: '$225,000',
    status: 'inactive',
    units: '$',
    rollupData: true
  }
]

goals.forEach(goal => {
  goal.habits = habits
  goal.memberList = members
  goal.meetings = meetings
  goal.subgoals = subgoals
})

export {
  goals,
  habits,
  meetings,
  subgoals
}
export default goals
