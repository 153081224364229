import React from 'react'
import { getAppNotifications } from '../../../AppNotifications'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { uid } from 'Stores/utils'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import './TimespanContextMenu.scss'

const debug = require('debug')('treks:ActionPlanner:PlannerContextMenu')

class TimespanContextMenu extends React.Component {

  ref = null

  static defaultProps = {
    newTimespanDuration: 60,
    minTimespanDuration: 120,
    minTimespanDurationWarning: 'Timespan must be more than 2 hours to split',
    maxTimespanCount: 8,
    maxTimespanCountWarning: 'Maximum number of timespans reached',
    minTimespanCount: 1,
    minTimespanCountWarning: 'Minimum number of timespans reached',
    menuItems: [
      {
        title: 'Delete Timespan',
        onClick: ({ self }) => self.removeTimespan()
      },
      {
        title: 'Add Timespan Before',
        onClick: ({ self }) => self.addTimespanAbove()
      },
      {
        title: 'Add Timespan After',
        onClick: ({ self }) => self.addTimespanBelow()
      }
    ]
  }

  uiState = ArbitraryModel.create({
    timespanIndex: null,
    isCreatingTimespan: false,
    creatingTimespan: null,
    timespanPos: 0
  })

  onDocumentInteraction = event => {
    if (this.ref && !this.ref.contains(event.target)) {
      this.props.onHideContextMenu(event, null)
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentInteraction)
  }

  componentDidUnMount() {
    document.removeEventListener('mousedown', this.onDocumentInteraction)
  }

  onMenuItemClick = (event, menuItem) => {
    const { timespan } = this.props
    event.preventDefault()
    menuItem.onClick({ timespan, event, menuItem, self: this })
  }

  @action removeTimespan() {
    const { timespanPiece, plannerFlow, minTimespanCount, minTimespanCountWarning } = this.props
    if (plannerFlow.timespanFlow.length <= minTimespanCount) {
      return getAppNotifications().warn(minTimespanCountWarning)
    }
    plannerFlow.resizeTimespanPiece(timespanPiece, -timespanPiece.duration)
    
    this.props.onDeleteTimespan()
    this.props.onHideContextMenu()
  }

  addTimespanAbove() {
    this.uiState.setState({
      isCreatingTimespan: true,
      timespanPos: 0
    })
  }

  addTimespanBelow() {
    this.uiState.setState({
      isCreatingTimespan: true,
      timespanPos: 1
    })
  }

  @action addTimeSpanAtIndex(list, index, type) {
    const { newTimespanDuration, plannerFlow, timespanPiece } = this.props
    if (!this.allowCreateNewTimespan({ index, list, type })) return

    const appliedDuration = plannerFlow.resizeTimespanPiece(timespanPiece, -newTimespanDuration, false)
    const props = {
      type,
      duration: -appliedDuration,
      uid: uid(),
    }
    const newTimespan = list.addItem(props, index)
    this.uiState.setState({
      isCreatingTimespan: false
    })
    debug('added timespan at', { appliedDuration, index, props, newTimespan })
    return newTimespan
  }

  onChooseTimespanType(type) {
    const { timespanPiece } = this.props
    const { timespanPos } = this.uiState
    const timespan = timespanPiece.timespans.slice(-1).pop()
    const index = timespan.list.getItemIndex(timespan)

    debug('add timespan', index, timespanPos, timespan.list, index, type)

    this.addTimeSpanAtIndex(timespan.list, index + timespanPos, type)
    this.props.onHideContextMenu()
    this.props.onCreateTimespan()
  }

  allowCreateNewTimespan({ index, list }) {
    const {
      maxTimespanCount,
      timespanPiece,
      maxTimespanCountWarning,
      minTimespanDuration,
      minTimespanDurationWarning
    } = this.props
    console.log({ index, list })
    if (list.items.length >= maxTimespanCount) {
      getAppNotifications().warn(maxTimespanCountWarning)
      return false
    }
    if (timespanPiece.duration < minTimespanDuration) {
      getAppNotifications().warn(minTimespanDurationWarning)
      return false
    }
    return true
  }

  onRef = ref => this.ref = ref

  renderChooseTimespanType() {
    const timespans = this.props.plannerFlow.accountTimespans.visibleItems
    return (
      <div
        x-placement="bottom-start"
        aria-labelledby="dropdown-basic"
        className="timespan-type-menu dropdown-menu show"
      >
        <h4 className='header'>Choose timespan type</h4>
        {
          timespans.map(timespan => {
            return (
              <a
                key={timespan.type}
                href={'#' + timespan.type.replace(' ', '-')}
                className="dropdown-item"
                onClick={event => {
                  event.preventDefault()
                  this.onChooseTimespanType(timespan.type)
                }}
              >
                {timespan.title}
              </a>
            )
          })
        }
      </div>
    )
  }

  renderMenu() {
    return (
      <div 
        x-placement="bottom-start"
        aria-labelledby="dropdown-basic"
        className="dropdown-menu show"
      >
        {
          this.props.menuItems.map(menuItem => {
            return (
              <a
                key={menuItem.title}
                href={'#' + menuItem.title.replace(' ', '-')}
                className="dropdown-item"
                onClick={event => this.onMenuItemClick(event, menuItem)}
              >
                {menuItem.title}
              </a>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { contextMenuPos } = this.props
    const { top, left } = contextMenuPos
    const { isCreatingTimespan } = this.uiState
    return (
      <div 
        ref={this.onRef}
        style={{
          position: 'fixed',
          top: top - 80,
          left,
          zIndex: 10000
        }}
        className="timespan-context-menu"
      >
        {
          isCreatingTimespan ? this.renderChooseTimespanType() : this.renderMenu()
        }
      </div>
    )
  }
}

export default observer(TimespanContextMenu)