import React from 'react'
import { observer } from 'mobx-react'
import Goal from '../../Components/Goal'
import { getStore, models } from 'Stores'
import { GoalPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { computed } from 'mobx'

const debug = require('debug')('treks:container:goal')

class GoalContainer extends React.Component {

  goalList = getStore(models.GoalList)
  mainPaneGroupStore = getStore(models.MainPaneGroup)


  getGoalId() {
    const pane = this.mainPaneGroupStore.getItemBySlug(GoalPane.slug)
    return pane && pane.modelId
  }
    
  async componentDidMount() {
    await this.goalList.fetched()
  }

  @computed get goal() {
    const goalId = this.getGoalId()
    return goalId && this.goalList.getItemById(goalId)
  }

  render() {
    const { goalList, goal } = this
    debug('render', { goal, goalList })
    if (!goal) {
      return <p style={{ padding: 10 }}>Please choose a goal</p>
    }
    return (
      <Goal goalList={goalList} goal={goal} />
    )
  }
}

export default observer(GoalContainer)
