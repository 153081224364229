import { 
  factory, factoryRefential, factorySerialized
} from './factory'
export {
  factory, factoryRefential, factorySerialized
}

// shorten uids in dev for debugging
const defaultUidLen = process.env.NODE_ENV === 'production' ? 54 : 8
export function uid(len = defaultUidLen, sep = '-') {
  const uidPart = () => Math.random().toString(16).slice(2)
  let uid = uidPart()
  while(uid.length < len) {
    uid += sep + uidPart()
  }
  return uid.substr(0, len)
}

export function parseJSON(json) {
  try {
    return JSON.parse(json)
  } catch(e) {
    return null
  }
}

/**
 * Executes a given function only once
 * @param {Function} fn 
 */
export function once<T>(fn: Function): () => T {
  let result, isExecuted
  return () => {
    if (!isExecuted) {
      isExecuted = true
      result = fn()
    }
    return result as T
  }
}

/**
 * @example await sleep(100)
 */
export const sleep = async (ms: Number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * @example await wait(done => {
 *    // do something
 *    done()
 * })
 */
export async function waitFor(fn: Function) {
  return new Promise(fulfill => fn(fulfill))
}

/**
 * Round a number to the given modulo
 * @param {Number} num Number
 * @param {Number} mod Modulo
 */
export function round(num, mod = 5) {
  const remainder = num%mod
  return num - remainder + (remainder > mod/2 ? mod : 0)
}
