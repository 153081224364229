import { observer } from "mobx-react"
import { isSameDay } from "Stores/ActionPlanner/ActionPlannerUtils"
import { CalendarDayLabel } from "./CalendarDayLabel"
import { DayListFlow } from "Stores/ActionPlanner/PlannerFlow/DayListFlow";

type WeekDayLabelsProps = {
  actionPlannerList: DayListFlow,
  onFocusDate: (date: Date) => void;
}

export const WeekDayLabels = observer(({ actionPlannerList, onFocusDate }: WeekDayLabelsProps) => {
  return (
    <div className="week-day-labels">
      {
        actionPlannerList.items.map(planner => (
          <CalendarDayLabel
            key={planner.uid}
            date={planner.focusedDate}
            isFocused={isSameDay(planner.focusedDate, actionPlannerList.focusedDate)}
            onFocusDate={onFocusDate}
          />
        ))
      }
    </div>
  )
})