import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Icon from 'theme/Icon'
import { Button } from 'react-bootstrap'
import { PFAModalBody } from './'
import { getPFAModalStore } from './stores/PFAModalStore'
import PFAModalModel from './models/PFAModalModel'
import './styles/PFAModal.scss'
import { withRouter } from 'react-router-dom'
import { getPFATabsStore } from './stores/PFATabsStore'

class PFAModalContainer extends React.Component {

  static defaultProps = {
    modalStore: getPFAModalStore(),
    tabStore: getPFATabsStore()
  }

  static propTypes = {
    modalStore: PropTypes.instanceOf(PFAModalModel)
  }

  handleClose = () => {
    window.setTimeout(() => this.props.modalStore.hide())
    this.unmountTabRoute()
  }

  componentDidMount() {
    this.mountTabRoute()
  }

  mountTabRoute() {
    const { hash } = this.props.location
    if (hash.includes('#/pfa/')) {
      const tabKey = hash.split('/').pop()
      this.props.modalStore.show()
      this.props.tabStore.setActiveTabKey(tabKey)
    }
  }

  unmountTabRoute() {
    const { hash } = this.props.location
    if (hash.includes('#/pfa/')) {
      this.props.history.push({ hash: "" })
    }
  }

  render() {
    const { modalStore } = this.props
    return (
      <Modal 
          show={modalStore.isVisible} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="pfa-modal modal-size-80"
        >
          <Modal.Header>
            <Modal.Title>
              <Icon icon={modalStore.icon} className="modal-title-icon" />
              {modalStore.title}
            </Modal.Title>
            <Button variant="outline" onClick={this.handleClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <PFAModalBody />
          </Modal.Body>
        </Modal>
    )
  }
}

export default withRouter(observer(PFAModalContainer))