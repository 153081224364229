import React from 'react'
import Sortable from '../../../../Modules/ReactSortable'
import { getEventLoggingOptions } from '../SortableUtils'
import Resizable from '../Resizable';
import { observer } from 'mobx-react';

const debug = require('debug')('treks:docs:sortable:react:item')

export default observer(class SortableReactItem extends React.Component {

  elementRef = null

  onElementRef = ref => {
    if (ref) this.elementRef = ref
  }

  onResize = ({ height }) => {
    this.props.item.duration = height
  }

  render() {
    const { item, onChange } = this.props
    const { id, name, type, subtasks, fill } = item
    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      ...getEventLoggingOptions()
    };
    const subtasksTotalDuration = subtasks.reduce((total, { duration }) => total + duration, 0)
    const eventsTotalDuration = fill.reduce((total, { type, duration }) => type === 'event' ? total + duration : total, 0)
    const taskDuration = Math.max(subtasksTotalDuration + eventsTotalDuration, item.duration)

    debug('render', this.props)

    return (
      <Resizable
        elementRef={this.onElementRef}
        data-id={id}
        data-type={type}
        className={type + '-item item'}
        style={{ height: taskDuration }}
        resize={'vertical'}
        onResize={this.onResize}
      >
        <h5 className="title">{name}</h5>
        {
          type !== 'event' && (
            <Sortable
              className="subtask"
              style={{ minHeight: 20 }}
              options={{
                ...options,
                group: {
                  name: 'subtask',
                  put: ['timespan', 'event']
                }
              }}
              onChange={onChange ? (...params) => onChange(subtasks, ...params) : null}
            >
              {
                subtasks.map(({ id, name, type, duration }) => (
                  <div
                    key={id}
                    data-id={id}
                    className={type + '-item item'}
                    style={{ height: (type === 'event' ? duration : 'auto') }}
                  >
                    {name}
                  </div>
                ))
              }
            </Sortable>
          )
        }
        {
          type !== 'event' && (
            <Sortable
              className="nested"
              style={{ minHeight: taskDuration - 30 }}
              options={{
                ...options,
                group: {
                  name: 'event',
                  put: (from, to, el) => el.getAttribute('data-type') === 'event'
                }
              }}
              onChange={onChange ? (...params) => onChange(fill, ...params) : null}
            >
              {
                fill.map(({ id, duration, name, type }) => (
                  <div
                    key={id}
                    data-id={id}
                    className={type + '-item item'}
                    style={{ height: duration, opacity: (type === 'event' ? 1 : 0) }}
                  >
                    {name}
                  </div>
                ))
              }
            </Sortable>
          )
        }
      </Resizable>
    )
  }
})