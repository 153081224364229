import React from 'react'
import 'theme/style/settings.css'
import '../Register/AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { withRouter } from 'react-router-dom'

const headerText = 'Password reset email sent!'
const infoText = 'Please check your email for a password reset instructions'

export default withRouter(class AccountPasswordResetEmailSent extends React.Component {

  onClickContinue = () => {
    this.props.history.push('/account/login')
  }

  render() {
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <p className="info">
              {infoText}
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
})