import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { models } from 'Stores'
import Icon from '../../theme/Icon'

export default withRouter(class MainMenuItem extends React.PureComponent {

  mainPaneGroup = getStore(models.MainPaneGroup)

  openMenuInPane() {
    const { path, slug } = this.props.item
    const pane = this.mainPaneGroup.getBucketItemPropsBySlug(slug)
    if (pane) {
      this.mainPaneGroup.addPaneOrTab(pane)
    } else {
      window.open(path, '_blank').focus()
    }
  }

  onClick = event => {
    event.preventDefault()
    event.stopPropagation()
    this.openMenuInPane()
  }

  render() {
    const { path, name, icon, SubMenu } = this.props.item
    return (
      <li className="main-menu-item">
        <Link to={path} onClick={this.onClick}>
          {
            (icon && <Icon name={icon} color={'#808284'} size={20} />)
          }
          { name }
        </Link>
        {SubMenu && (
          <div className="main-menu-sub">
            <SubMenu item={this.props.item} />
          </div>
        )}
      </li>
    )
  }
})