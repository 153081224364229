const PrivatePropsMap = new WeakMap<any, PrivateProps>()
const privatePropsKey = Symbol('privateProps')

export const getPrivateProps = (context) => {
  let privateProps = context[privatePropsKey]
  if (!privateProps) {
    context[privatePropsKey] = new PrivateProps(context)
    privateProps = context[privatePropsKey]
  }
  return privateProps
}

export const getStaticPrivateProps = (context) => {
  let privateProps = PrivatePropsMap.get(context)
  if (!privateProps) {
    PrivatePropsMap.set(context, new PrivateProps(context))
    privateProps = PrivatePropsMap.get(context)
  }
  return privateProps
}

class PrivateProps {

  context: any
  map: Map<any, any> = new Map()

  constructor(context: any) {
    this.context = context
  }

  getMap = (): Map<any, any> => {
    return this.map
  }

  has = (key: any): boolean => {
    return this.getMap().has(key)
  }
  
  get = (key: any, initializer?: Function): any => {
    if (!this.has(key) && initializer) {
      this.set(key, initializer())
    }
    return this.getMap().get(key)
  }
  
  set = (key: any, value: any) => {
    this.getMap().set(key, value)
  }
}