import TaskList from '../TaskList'
import SubTask from './SubTask'
import { computed } from 'mobx'
import { once } from '../../utils'
import TaskItem from '..'

// @todo save() this
export const importSubTaskList = once(() => {
  class SubTaskList extends TaskList {

    get ModelType() {
      return SubTask()
    }
  
    @computed get task(): TaskItem {
      return this.list?.task
    }
  
    /**
     * @note Do not include props in JSON. (Infinite recursion)
     */
    toJSON() {
      return this.asyncToJSON()
    }
  
    async asyncToJSON() {
      const { title, isFlatList } = this
      const items = await Promise.all(this.items.map(item => item.asyncToJSON()))
      return { items, title, isFlatList }
    }
  
    async save() {
      // @todo remove delegation to parent when subtask api completed
      return this.task?.saveThrottled()
    }
  
  }
  return SubTaskList
})

export default importSubTaskList // @todo remove