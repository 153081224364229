import React from 'react'
import { observer } from 'mobx-react'
import FormControlInput from 'theme/Form/FormControlInput';

class GoalDetailFieldCheckbox extends React.Component {

  render() {
    const { name, value } = this.props.field
    return (
      <FormControlInput
        className="input-borderless form-text-input"
        name={name}
        value={value}
        onChange={this.props.onChange}
      />
    )
  }
}

export default observer(GoalDetailFieldCheckbox)
