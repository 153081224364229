import Model from "Stores/Model";
import { LabelTaskList } from "./LabelTaskList";
import { hasMany, hasOne } from "Relationships/RelationshipDecorators";
import TaskItem from "../TaskItem";
import { observable } from "mobx";
import { LabelGroup } from "./LabelGroup";

export class TaskLabel extends Model {

  @observable title: string

  @observable color: string

  @observable focusOnTitle: boolean 

  @hasMany(() => LabelTaskList, (task: TaskItem) => task.taskLabelList)
  taskList: LabelTaskList

  @hasOne(() => LabelGroup, (group: LabelGroup) => group.labelList)
  group: LabelGroup
  
}