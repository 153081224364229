import { observable, action, override } from 'mobx'
import UserList from '../User/UserList'
import List from '../Lists/List'
import SprintItem from './SprintItem'
import { ProjectItem } from 'Stores/Project'
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators'

export default class SprintList extends List {

  get ModelType() {
    return SprintItem
  }

  @observable title:string = ''

  @observable summary:string = ''

	@hasMany(() => UserList)
  users:UserList
	

	@hasOne(() => ProjectItem)
  project:ProjectItem

  @action createItemProps(props:any):any {
    const { project, items } = this
    return { project, sprintNumber:items.length+1, ...props }
  }

  @override async fetch() {
    const projectId = this.project && this.project.id
    return this.fetchState.get('sprint/list', { projectId })
      .then((resp:any) => this.fromJSON(resp.data))
  }

}