import React from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Icon from '../../../theme/Icon'

export default class HelpTip extends React.Component {
  state = {
    ref: null,
    show: false
  }

  onRef = ref => this.setState({ ref })

  onFocus = () => this.setState({ show: true })

  onBlur = () => this.setState({ show: false })

  render() {
    const { tooltip, children } = this.props
    return (
      <>
        <Icon 
          ref={this.onRef}
          onMouseOver={this.onFocus}
          onMouseOut={this.onBlur}
          set="fa" 
          icon="question-circle" 
          color="#657e82" 
          className="helptip"
        />
        <Overlay target={this.state.ref} show={this.state.show} placement="top">
          {props => <Tooltip {...props}>{ tooltip || children }</Tooltip>}
        </Overlay>
      </>
    )
  }
}