import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectSettings.scss'
import Form from 'Stores/Form'
import ProjectSettingList from './ProjectSettingList'
import SprintLabel from './Labels/SprintLabel'
import TasksImportLabel from './Labels/TasksImportLabel'
import StatusAssignLabel from './Labels/StatusAssignLabel';
import FieldColor from './Fields/FieldColor'
import { ProjectItem } from 'Stores/Project'
import { action } from 'mobx'
import FieldProjectStatus from './Fields/FieldProjectStatus'

const debug = require('debug')('treks:project:settings')

const fields = [
  {
    name: 'status',
    label: 'Project Status',
    Component: FieldProjectStatus
  },
  {
    name: 'color',
    value: '#f1f1ba',
    label: 'Highlight Color',
    Component: FieldColor
  },
  {
    name: 'sprintBasedProject',
    value: true,
    label: SprintLabel,
    disabled: true
  },
  {
    name: 'reqInvitation',
    value: true,
    label: 'Requires invitation to join'
  },
  {
    name: 'addToFav',
    value: true,
    label: 'Add to Favorites',
    disabled: true
  },
  {
    name: 'timeTracking',
    value: true,
    label: 'Enable time tracking',
    disabled: true
  },
  {
    name: 'incProjectPlanner',
    value: true,
    label: 'Include in Project Planner'
  },
  {
    name: 'tasksStatusBased',
    value: true,
    label: StatusAssignLabel,
    disabled: true
  },
  {
    name: 'tasksImport',
    value: true,
    label: TasksImportLabel,
    Component: () => null,
    disabled: true
  }
]

class ProjectSettingsCom extends React.Component {

  static defaultProps = {
    project: ProjectItem.create(),
    form: Form.fromFields(fields.filter(field => !field.disabled))
  }

  componentDidMount() {
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props
    if (project !== prevProps.project) {
      this.setDefaults()
    }
  }

  @action setDefaults() {
    const { project, form } = this.props
    form.fields.forEach((field, i) => {
      form.fields[i].value = project[field.name]
    })
  }

  render() {
    const { project, form } = this.props
    debug('render', { project, form })
    return (
      <div className="project-settings">
        <header className="row tab-header">
          <Icon icon="settings" size={15} />
          <div className="tab-title">Settings</div>
        </header>
        <div className="project-settings-list">
          <ProjectSettingList
            project={project}
            form={form}
          />
        </div>
      </div>
    )
  }
}

export default observer(ProjectSettingsCom)
