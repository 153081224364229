import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import './ListViewFields.scss'
import Dropdown from 'react-bootstrap/Dropdown'

class ListViewFields extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    label: 'Fields',
    placeholder: 'All',
    options: [
      {
        label: 'Due Date',
        value: 'due_date'
      },
      {
        label: 'Assigned To',
        value: 'assigned_user'
      },
      {
        label: 'Duration',
        value: 'duration'
      }
    ]
  }

  @observable fieldsState = ['due_date', 'assigned_user', 'duration']

  componentDidMount() {
    makeObservable(this)
  }

  onChange = field => {
    const index = this.fieldsState.indexOf(field.value)
    if (index === -1) {
      this.fieldsState.push(field.value)
    } else {
      this.fieldsState.splice(index, 1)
    }
    this.props.onChange(this.fieldsState)
  }

  render() {
    const { options, label } = this.props
    return (
      <div className="list-view-fields-container">
        <Dropdown className="fields-dropdown">
          <Dropdown.Toggle className="filter-fields-label">
            {label}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              options.map((option, i) => (
                <Dropdown.Item
                  key={option.value}
                  eventKey={i}
                  onClick={this.onChange.bind(this, option)}
                  active={this.fieldsState.includes(option.value)}
                >
                  {option.label}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
     )
  }
}

export default observer(ListViewFields)