import { PFAPlanScoreModel } from "Containers/PFA/models/PFAScore/PFAPlanScoreModel"

export const getPlanningData = (pfaPlanScoreStore: PFAPlanScoreModel) => {

  // [
  //   {
  //     "timespan": "Personal Morn",
  //     "planning": 60,
  //     "color": "#E6F0F9"
  //   },
  // ]

  const data = pfaPlanScoreStore.trackedTimespans.map(timespan => {
    const planScorePercent = pfaPlanScoreStore.timespanPlanScorePercent(timespan)
    return {
      timespan: timespan.title,
      planning: planScorePercent,
      color: timespan.color
    }
  })
  return data
}