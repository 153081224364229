import List from "Stores/Lists";
import { importGridItem } from './GridItem'
import { action, observable, override } from 'mobx'
import { uid } from "Stores/utils";
import TaskItem from "Stores/Task";
import { ProjectItem } from "Stores/Project";
import { GoalItem } from "Stores/Goal";

export default class GridList extends List {
  get ModelType() {
    return importGridItem()
  }
  @observable focusedItem = null

  

  @action newItem(dashboardId) {
    //let arr = [...this.items]
    let id = uid()
    let item = this.addItem({
      x: 0,
      y: Infinity,
      w: 2,
      h: 1,
      uid: id,
      i: id,
      dashboardId
      // widgets: [importWidgetItem().fromJSON({x:0, y:0, w:2, h:2})]
    })

    console.log('new item', item)

    // item.save()
    // arr.push(item)
    // this.setItems(arr)
  }

  @override async fetch(id) {
    let endpoint = 'dashboard/fetch/'
    endpoint += "?dashboardId="+id
    console.log('fetching', endpoint)
    try {
      this.fetchState.get(endpoint)
      .then(async ({ data }) => {
        console.log('dashboard fetch result', data)
        
        let promises = []
        data.forEach(async (item) => {
          console.log('taskId', item.taskId)
          if (item.y === null) item.y = Infinity
          item.i = item.uid
          let promise = null 
          switch (item.dataType){
            case 'task':
              if (item.taskId){
                promise = TaskItem.fromId(item.taskId)
                item.task = promise
              }

            break;

            case 'project':
              if (item.projectId){
                promise = ProjectItem.fromId(item.projectId)
                item.project = promise
              }
            break;

						case 'goal':
              if (item.goalId){
                promise = GoalItem.fromId(item.goalId)
                item.goal = promise
              }
            break;
          }

          if (promise)
          promises.push(promise)


        })
        await Promise.all(promises)
        this.fromJSON({ items: [...data] })



      })
    } catch (err) {
      console.log('dashboard fetch failed', err)
    }

  }
}