import Model from "Stores/Model"
import { ModelPropsI } from "Stores/Model/Type/Model"
import { getModelMapByKey } from "./RelationshipMap"
import { getRelationMap } from "./RelationshipInitializer"
import { BaseType, ModelType, PropsResolver, RelationRef, RelationState } from "./__types__"
import { RelationshipJoinTable } from "./JoinTable/RelationshipJoinTable"

const debug = require('debug')('treks:RelationshipUtils')

export class RelationshipUtils {

  /**
   * Notes the (un-initialized) descriptor for the decorated model prop
   */
  static noteRelation(model: Model, key: string, descriptor: any) {
    getRelationMap(model).set(key, descriptor)
  }

  /**
   * The last dereferenced property is stored to trace.prop
   * So (model) => model.prop1 --> 'prop1'
   * @note assumes referenced property is on the model
   */
  static _getRefKey(ref: RelationRef) {
    const trace = { prop: null }
    const traceable = new Proxy({}, {
      get(target: object, prop: string, receiver: any) {
        trace.prop = prop
      }
    })
    ref(traceable as Model)
    return trace.prop
  }

  static _validateModelType(ModelType: ModelType, BaseType: BaseType) {
    if (!(ModelType && ModelType.prototype instanceof BaseType)) {
      throw new TypeError('Relationship requires a typeof Model')
    }
  }

  static _normalizeModelType(ModelType: ModelType, BaseType: BaseType): typeof Model {
    // if we have a function that's not a Model class constructor
    if (typeof ModelType === 'function' && !(ModelType.prototype instanceof BaseType)) {
      return (ModelType as Function)() as typeof Model
    }
    return ModelType as typeof Model
  }

  // sanity check. Storable should always be the same
  static _validateStorable(storable, state: RelationState) {
    if (state.storable && storable !== state.storable) {
      throw new Error('Different storable on relation')
    }
    state.storable = storable
  }

  static _validateParentModel(parentModel: Model, state: RelationState) {
    if (state.parentModel && !Model.isSameModel(parentModel, state.parentModel)) {
      throw new Error('Different parent model')
    } 
    state.parentModel = parentModel
  }

  static _validateProps(model: Model, props: ModelPropsI<typeof model>, ref: RelationRef, key: string) {
    if (typeof ref !== 'function' || !props) return
    const refKey = this._getRefKey(ref)
    if (refKey in props) {
      console.warn(`Props must not overwrite the relationship. 
        Prop ${refKey} is not allowed in ${model.modelName}.${key}`)
    }
  }

  static _stateKey = Symbol('relationship.state')
  static _iniState: RelationState = {
    prevModel: null,
    initialized: false,
    storable: null,
    parentModel: null
  }
  static _getRelationshipState(parentModel: Model, key: string): RelationState {
    const map = getModelMapByKey(parentModel, this._stateKey)
    if (!map.has(key)) {
      map.set(key, { ...this._iniState })
    }
    return map.get(key)
  }

  static _normalizeProps(props: PropsResolver, model: Model): ModelPropsI<typeof model> {
    if (typeof props === 'function') {
      props = props.call(model, model)
    }
    return props as ModelPropsI<typeof model>
  }

  static _joinTableKey = Symbol('relationship.jointable')

  /**
   * The JoinTable is created and set on both sides of relationship
   */
  static getJoinTable(type: typeof Model, key: string, refType: typeof Model, refKey: string): RelationshipJoinTable {
    const map = getModelMapByKey(type, this._joinTableKey)
    const tableKey = `${key}-${refType.name}.${refKey}`
    let table = map.get(tableKey)
    if (!table) {
      table = new RelationshipJoinTable()
      table.ns = `${type.name}.${key}-${refType.name}.${refKey}` // debug
      map.set(tableKey, table)
      const refTableKey = `${refKey}-${type.name}.${key}`
      const refMap = getModelMapByKey(refType, this._joinTableKey)
      if (refMap.has(refTableKey)) {
        throw new Error('Other side of relation has a join table')
      }
      refMap.set(refTableKey, table)
    }
    return table
  }

}