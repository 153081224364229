import List from "Stores/Lists";
import { action, override } from 'mobx'
import { uid } from "Stores/utils";
import InstanceItem from "./InstanceItem";
import { HabitItem } from ".";

export default class InstanceList extends List {
	get ModelType() {
		return InstanceItem
	}

	@action newItem(habit) {
		let id = uid()
		return this.addItem({
			title: 'test',
			uid: id,
			habit
		})
	}

	@override async fetch(habitId) {
		let endpoint = 'habit/fetch/' + habitId + '/instances'
		let promises = []
		this.fetchState.get(endpoint)
			.then(async ({ data }) => {
				let promise = null
				data.forEach((d) => {

					promise = HabitItem.fromId(data.habitId)
					d.habit = promise

					promises.push(promise)
				})
				await Promise.all(promises)
				this.fromJSON({ items: [...data] })
			})
	}


}