import React from 'react'
import { observer } from 'mobx-react'
import Pressable from '../../../../theme/Pressable'
import Icon from '../../../../theme/Icon'
import { getAppNotifications } from '../../../../AppNotifications'

class GoalCommentNotify extends React.Component {

  static defaultProps = {
    subscribedMsg: 'You are subscribed to comment notifications',
    unsubscribedMsg: 'You are unsubscribed from comment notifications'
  }

  onClick = () => {
    const { comment, subscribedMsg, unsubscribedMsg  } = this.props
    comment.sendNotifications = !comment.sendNotifications
    if (comment.sendNotifications) {
      getAppNotifications().success(subscribedMsg)
    } else {
      getAppNotifications().show(unsubscribedMsg)
    }
    
  }

  render() {
    const { sendNotifications } = this.props.comment
    return (
      <Pressable 
        onClick={this.onClick} 
      >
        <Icon icon={sendNotifications ? 'alert' : 'no_alert'} />
      </Pressable>
    )
  }
}

export default observer(GoalCommentNotify)
