import React from 'react'
import { observer } from 'mobx-react'
import TimeSpansOverviewItem from './TimeSpansOverviewItem'
import { AddItemBtn } from 'Components/ui/AddItemBtn'
import { uid } from 'Stores/utils'
import { getRandomColor } from 'lib/utils'
import { makeAutoObservable } from 'mobx'

const debug = require('debug')('treks:planner:settings:overview')

class TimeSpansOverview extends React.Component {

  uiState = makeAutoObservable({
    creatingTimespan: null
  })

  onAddTimespan = () => {
    const { bucket } = this.props
    const color = getRandomColor('ABCDEF')
    const itemProps = { type: uid(), color }
    const timespan = bucket.addItem(itemProps)
    this.uiState.creatingTimespan = timespan
  }

  onSaveSettings = () => {
    this.props.bucket.save()
  }

  getTotalDuration(timespan) {
    return this.props.plannerFlow.timespanFlow
      .filter(piece => piece.type === timespan.type)
      .map(piece => piece.duration)
      .reduce((sum, duration) => sum += duration, 0)
  }

  get availableTimespans() {
    return this.props.bucket.items.filter(ts => {
      return ts.deleted === false || this.getTotalDuration(ts) > 0
    })
  }

  render() {
    const { plannerFlow } = this.props
    debug('render', this.props)
    return (
      <table className="planner-settings-overview">
        <tbody>
        {
          this.availableTimespans.map(item => (
            <TimeSpansOverviewItem
              key={item.type}
              plannerFlow={plannerFlow}
              timespan={item}
              isCreating={this.uiState.creatingTimespan === item}
              onAddTimespan={this.onAddTimespan}
              onSaveSettings={this.onSaveSettings}
              colorBtnStyle={{
                width: 20,
                height: 20
              }}
            />
          ))
        }
        <tr className="planner-settings-overview-item">
          <td  colSpan="3">
            <AddItemBtn
              label={'+ Add Timespan'}
              onClick={this.onAddTimespan}
              className={'btn-add-timespan-type'}
              tooltipTitle={'Enter'}
            />
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

export default observer(TimeSpansOverview)