import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { FormControlDropdown } from 'theme/Form';
import { observable, computed } from 'mobx';
import Select from 'theme/Form/Select';

class GoalCollectData extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    dataSourceIdSelectPlaceholder: 'Select {dataSource}s to collect data from',
    dataSourceSelectPlaceholder: 'Select a data source'
  }

  /**
   * Select items for dataSoure
   */
  dataSourceItems = observable([
    {
      label: 'Roll up data from related habits',
      value: 'habits',
      subList: {
        getList: goal => goal.habits,
        isMulti: true,
        show: false
      }
    },
    {
      label: 'Collect data on this goal',
      value: 'goal'
    },
    {
      label: 'Roll up data from subgoals',
      value: 'subgoals',
      subList: {
        getList: goal => goal.subgoals,
        isMulti: true,
        show: false
      }
    },
    {
      label: 'Collect data through an API',
      value: 'api'
    }
  ])

  componentDidMount() {
    this.fetchDataSourceList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.fetchDataSourceList()
    }
  }

  fetchDataSourceList() {
    const { dataSource, goal } = this.props
    const dataSourceItem = this.getDataSourceItemByValue(dataSource)
    if (dataSourceItem.subList) dataSourceItem.subList.getList(goal).fetched()
  }

  getDataSourceItemByValue(value) {
    return this.dataSourceItems.find(item => item.value === value)
  }

  onDataSourceChange = dataSource => {
    this.props.onChange({ dataSource, dataSourceId: null })
  }

  onDataSourceIdChange = dataSourceId => {
    const { dataSource } = this.props
    if (!Array.isArray(dataSourceId)) dataSourceId = [dataSourceId]
    dataSourceId = dataSourceId.map(option => option && option.value)
    this.props.onChange({ dataSource, dataSourceId })
  }

  @computed get dataSourceItem() {
    const { dataSource } = this.props
    return this.getDataSourceItemByValue(dataSource)
  }

  @computed get subOptions() {
    const { goal } = this.props
    const { dataSourceItem } = this
    const dataSourceIdItems = dataSourceItem.subList 
      ? dataSourceItem.subList.getList(goal).items 
      : []
    return dataSourceIdItems.map(({ id, title }) => ({ label: title, value: id.toString() }))
  }

  @computed get subValue() {
    const { subOptions } = this
    const { dataSourceId } = this.props
    return dataSourceId && dataSourceId.map(id => subOptions.find(option => option.value === id))
  }

  render() {
    const { dataSource, dataSourceSelectPlaceholder, dataSourceIdSelectPlaceholder } = this.props
    const { dataSourceItem, subValue, subOptions } = this
    return (
      <div className="row row-flex row-data">
        <div className="row-col">
          <FormControlDropdown
            placeholder={dataSourceSelectPlaceholder}
            items={this.dataSourceItems}
            onChange={this.onDataSourceChange}
            value={dataSource}
          />  
        </div>
        <div className="row-col">
          {
            subOptions.length ? <Select
              isMulti={dataSourceItem.subList.isMulti}
              className="select-with-border"
              placeholder={dataSourceIdSelectPlaceholder.replace('{dataSource}', dataSource)}
              options={subOptions}
              onChange={this.onDataSourceIdChange}
              defaultValue={subValue}
              value={subValue}
            /> : null
          }  
        </div>
      </div>
    )
  }
}

export default observer(GoalCollectData)
