import React from 'react';
import Routes from './Routes'
import ErrorBoundary from './Components/ErrorBoundary'
import { AppNotificationContainer } from './AppNotifications'
import { getKeyPressState } from 'uiState/KeypressState';
import { getMouseClickState } from 'uiState/MouseClickState';

const keyPressState = getKeyPressState()
const mouseClickState = getMouseClickState()

class App extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
    document.body.addEventListener('click', this.onMouseCick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
    document.body.removeEventListener('click', this.onMouseCick)
  }

  onKeyDown = event => keyPressState.setKeyDown(event)

  onKeyUp = event => keyPressState.setKeyUp(event)

  onMouseCick = event => mouseClickState.setClick(event)

  render() {
    return (
      <ErrorBoundary>
        <AppNotificationContainer />
        <Routes />
      </ErrorBoundary>
    );
  }
}

export default App;
