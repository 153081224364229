import { getAppNotifications } from "AppNotifications";
import Modal from "Containers/Modal";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { getStore, models } from "Stores";
import Calendar, { CalendarAccount, CalendarAccountList } from "Stores/Calendar";
import { Collapsable } from "theme/Collapsable";
import Icon from "theme/Icon";
import "./CalendarsModal.scss"
import { LucideChevronRight, LucideCircle, LucideCircleCheck } from "lucide-react";

export const CalendarsModal = observer(class extends React.Component<{
  getCalendarAccountList: () => CalendarAccountList;
  onHideCalendarModal: () => void;
  showCalendarModal: boolean;
  onChooseCalendar: (cal: Calendar) => void;
}> {

  static defaultProps = {
    getCalendarAccountList: () => getStore(models.CalendarAccountList)
  }

  collapseState = observable.map()

  selectedCalendar = observable.box<Calendar>()

  componentDidMount() {
    this.props.getCalendarAccountList().fetch()
      .catch(error => getAppNotifications().error(error.message))
  }

  selectCalendar = (calendar: Calendar) => {
    this.selectedCalendar.set(calendar)
  }

  toggleAccountCollapse = (calendarAccount: CalendarAccount) => {
    this.collapseState.set(calendarAccount.id, !this.collapseState.get(calendarAccount.id))
  }

  onChooseCalendar = () => {
    this.props.onChooseCalendar(this.selectedCalendar.get())
  }

  renderChooseCalendar = () => {
    const { getCalendarAccountList } = this.props
    const calendarAccountList = getCalendarAccountList()
    return (
      <div className="calendar-list-modal">
        {
          calendarAccountList.items.map(calendarAccount => (
            <div key={calendarAccount.id} className="calendar-account">
              <button
                className="btn-calendar-account btn-link"
                onClick={() => this.toggleAccountCollapse(calendarAccount)}
              >
                <LucideChevronRight style={{
                  transform: this.collapseState.get(calendarAccount.id) ? 'rotate(90deg)' : '',
                  transition: 'all 0.6s'
                }} />
                <span className="label-cal-account">
                {calendarAccount.email}
                </span>
              </button>
              <Collapsable isCollapsed={this.collapseState.get(calendarAccount.id)}>
                {
                  calendarAccount.items.map(calendar => {
                    const selected = this.selectedCalendar.get()?.id === calendar.id
                    return (
                      <button
                        key={calendar.id}
                        className={"btn-calendar btn-link" + (selected ? ' selected' : '')}
                        onClick={() => this.selectCalendar(calendar)}
                      >
                        {
                          selected ? <LucideCircleCheck /> : <LucideCircle />
                        }
                        
                        <span className="calendar-label">
                        {
                          calendar.title
                        }
                        </span>
                      </button>
                    )
                  })
                }
              </Collapsable>
            </div>
          ))
        }

        {
          this.selectedCalendar.get() ? (
            <button
              onClick={this.onChooseCalendar}
              className="btn btn-submit-calendar btn-outline-dark btn-sm"
            >Choose this Calendar</button>
          ) : null
        }
        
      </div>
    )
  }

  render() {
    const { onHideCalendarModal, showCalendarModal } = this.props
    return (
      <Modal
        icon={<Icon set={'treks'} icon={'convert_to_event'} className="modal-title-icon" />}
        show={showCalendarModal}
        title={'Choose Event Calendar'}
        component={this.renderChooseCalendar()}
        onHide={onHideCalendarModal}
      />
    )
  }
})