import { getStore } from "Stores";
import Model from "Stores/Model";
import { action, computed, observable } from "mobx";

const debug = require('debug')('treks:KeyPressState')

export class KeyPressState extends Model {

  @observable keyDownEvent?: KeyboardEvent

  @observable ctrlKeys = ['Alt', 'Control', 'Meta']

  @computed get isAnyCtrlKeyPressed(): boolean {
    return this.ctrlKeys.includes(this.keyDownEvent?.key)
  }

  @computed get isAltKeyPressed(): boolean {
    return this.keyDownEvent?.altKey
  }

  @computed get isCtrlKeyPressed(): boolean {
    return this.keyDownEvent?.ctrlKey
  }

  @computed get isShiftKeyPressed(): boolean {
    return this.keyDownEvent?.shiftKey
  }

  @computed get isMetaKeyPressed(): boolean {
    return this.keyDownEvent?.metaKey
  }

  @action setKeyDown(event: KeyboardEvent) {
    this.keyDownEvent = event
    debug('setkeyDown', event, this.isAnyCtrlKeyPressed)
  }

  @action setKeyUp(event: KeyboardEvent) {
    this.keyDownEvent = null
    debug('setkeyUp', event, this.isAnyCtrlKeyPressed)
  }

}

export const getKeyPressState = (): KeyPressState => getStore(KeyPressState as any)