import React from 'react';
import './SubMenu.scss';
import { observer } from 'mobx-react';
import Select from '../../theme/Form/Select';
import { action } from 'mobx';
import { uid } from 'Stores/utils';
import { getStore, models } from 'Stores';

class DashboardMenu extends React.Component {
    async componentDidMount() {}

    static defaultProps = {
        selectPlaceholder: 'Dashboards',
        noOptionsMessage: () => 'No Dashboards matching search...',
        menuOpenValue: 'Search Dashboards...',
        menuOpenPlaceholder: 'Search Dashboard...',
        addOptionLabel: 'Create New Dashboard...',
        get dashboardList() {
            return getStore(models.DashboardList);
        },
        fetchDashboardsErrMsg: 'Unable to fetch dashboards',
    };

    @action setDashboard(dashboard) {
        const { selected } = this.props;
        this.props.dashboardList.setFocusedItem(dashboard);
        this.forceUpdate();
        selected();
    }

    createDashboard(name) {
        const { dashboardList } = this.props;
        const dashboardProps = {
            uid: uid(),
            name,
        };
        const dashboard = dashboardList.addItem(dashboardProps);
        dashboard.save();
        this.setDashboard(dashboard);
    }

    @action onChange = (option) => {
        const { value } = option || { value: null };
        const dashboard = value && this.props.dashboardList.getItemByIdString(value);
        if (dashboard) this.setDashboard(dashboard);
        console.log('onChange', { value, option, dashboard });
    };

    @action onCreateOption = (label) => {
        this.createDashboard(label);
        console.log('onCreateOption', { label });
    };

    render() {
        const { selectPlaceholder, noOptionsMessage, menuOpenValue, menuOpenPlaceholder, addOptionLabel } =
            this.props;
        const dashboard = this.props.dashboardList.focusedItem;
        const options = this.props.dashboardList.items.map(({ name, id }) => ({
            label: name,
            value: id ? id.toString() : '',
        }));
        const value = dashboard && dashboard.name ? { label: dashboard.name, value: dashboard.id } : null;
        console.log('render', { dashboard, options, value });
        return (
            <div style={{ padding: 5, paddingTop: 10, background: 'hsl(0,0%,100%)', borderRadius: 5 }}>
                <Select
                    isClearable
                    hasAddOption
                    createOptionPosition={'first'}
                    addOptionLabel={addOptionLabel}
                    onAddOption={this.onAddOption}
                    menuOpenPlaceholder={menuOpenPlaceholder}
                    menuOpenValue={menuOpenValue}
                    noOptionsMessage={noOptionsMessage}
                    placeholder={selectPlaceholder}
                    onChange={this.onChange}
                    options={options}
                    value={value}
                    onCreateOption={this.onCreateOption}
                />
            </div>
        );
    }
}

export default observer(DashboardMenu)
