import { DayFlow } from "Stores/ActionPlanner/PlannerFlow/DayFlow";
import { TimespanPieceFlow } from "Stores/ActionPlanner/PlannerFlow/TimespanPieceFlow";
import Model from "Stores/Model";
import { modelProperty } from "Stores/Model/ModelDecorators";
import { once } from "Stores/utils";
import { action } from "mobx";

export class PlannerSettings extends Model {

  @modelProperty focusedTimespanPiece?: TimespanPieceFlow

  @modelProperty focusedDay?: DayFlow

  @action setFocusedTimespanPiece(timespanPiece: TimespanPieceFlow, day: DayFlow) {
    this.focusedTimespanPiece = timespanPiece
    this.focusedDay = day
  }

}

export const getPlannerSettings = once<PlannerSettings>(() => {
  return PlannerSettings.create(true)
})