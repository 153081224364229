import React from 'react'
import 'theme/style/settings.css'
import '../Register/AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { withRouter } from 'react-router-dom'
import stores, { getStore } from 'Stores'
import AccountRegisterFooter from '../Register/AccountRegisterFooter'
import { getAppNotifications } from 'AppNotifications'

const ResetHeader = 'Reset your Treks password'
const ResetButonLabel = 'Send Email Instructions'
const inputPlaceholder = 'name@company.com'
const passwordResetInfo = 'Please enter Treks Account Email.'
const failedSendingEmailMsg = 'An error occurred sending your password reset email'

const debug = require('debug')('treks:component:account:password:reset')

export default withRouter(class AccountPasswordReset extends React.Component {

  accountRegisterStore = getStore(stores.AccountRegister)

  onKeyUp = event => {
    const email = event.target.value
    if (event.key === 'Enter') {
      this.sendVerificationEmail()
    } else {
      this.accountRegisterStore.email = email
    }
  }

  validateEmail() {
    const { email } = this.accountRegisterStore
    return email && email.toString().match(/.+@.+\..+/);
  }

  onClick = event => {
    event.preventDefault()
    this.sendVerificationEmail()
  }

  sendVerificationEmail() {
    if (!this.validateEmail()) {
      return getAppNotifications().error('Please enter a valid email address')
    }
    return this.accountRegisterStore.sendPasswordResetEmail()
      .then(() => {
        this.props.history.push('/account/password/reset/sent')
      })
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedSendingEmailMsg)
      })
  }

  render() {
    const { registerAccountState, sendEmailState } = this.accountRegisterStore
    const busy = registerAccountState.state === 'pending' || sendEmailState.state === 'pending'
    return (
      <Container className="account-register account-register-email settings">
        <Row>
          <Col>
            <header className="header">
              {ResetHeader}
            </header>
            <Form.Group>
              <p className="info">
                {passwordResetInfo}
              </p>
              <InputGroup>
                <Form.Control
                  autoFocus
                  className="input input-email"
                  placeholder={inputPlaceholder}
                  onKeyUp={this.onKeyUp}
                  type="email"
                  disabled={busy}
                />
                <InputGroup.Append>
                  <Button 
                    onClick={this.onClick} 
                    variant="primary"
                    disabled={busy}
                  >
                    {ResetButonLabel}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountRegisterFooter />
          </Col>
        </Row>
      </Container>
    )
  }
})