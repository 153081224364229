import { observable, action, computed, override } from "mobx";
import { Item } from "../Lists";
import ProjectList from "./ProjectList";
import ProjectMemberList from "./ProjectMemberList";
import ProjectFollowerList from "./ProjectFollowerList";
import { CategoryItem, CategoryList } from "../Category";
import { OrganizationItem } from "../Organization";
import { TeamItem } from "../Team";
import { FileItem, FileList } from "../File";
import { ProjectSubTaskListList } from "Stores/Project";
import { CumulativeStats } from "Stores/Stats/CumulativeStats";
import HabitList from "Stores/Habit/HabitList";
import { GoalItem, GoalList } from "Stores/Goal";
import { ListPropsI } from "Stores/Lists/Type/List";
import { hasMany, hasOne } from "Relationships/RelationshipDecorators";
import { models } from "Stores/Stores";
import TaskItem from "Stores/Task";
import { ActivityItem, ActivityList } from "Stores/Activity";
import ProjectTaskList from "./PojectTaskList";
import ProjectMemberItem from "./ProjectMemberItem";
import { HabitItem } from "Stores/Habit";
import { ProjectThrash } from "./Thrash/ProjectThrash";
import { ProjectBlockerList } from "./Blocker/ProjectBlockerList";

const createRandomColorHex = () => {
  return "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");
};

export default class ProjectItem extends Item {
  static create(): ProjectItem {
    const project = super.create() as ProjectItem;
    project.color = createRandomColorHex();
    return project;
  }

  get ListType(): typeof ProjectList {
    return ProjectList;
  }

  cumulativeStats: CumulativeStats = CumulativeStats.fromProps({ model: this });

  @observable title: string;
  @observable description: string;
  @observable color: string;
  @observable createdTS: Date = new Date();
  @observable favorite: boolean = false;
  @observable hidden: boolean = false;
  @observable reqInvitation: boolean = false;
  @observable timeTrackingEnabled: boolean = false;
  @observable incProjectPlanner: boolean = false;
  @observable statusTasks: boolean = false;

  @observable createdBy: number;
  @observable deleted: boolean;

  @computed get done(): boolean {
    return this.status === "complete";
  }

  @observable status: "planning" | "active" | "complete" = null;

  @hasOne(
    () => OrganizationItem,
    (organization: OrganizationItem) => organization.projectList,
  )
  organization: OrganizationItem;

  @hasOne(() => TeamItem, (team: TeamItem) => team.projectList)
  team: TeamItem;

  @hasMany(
    () => ProjectMemberList,
    (member: ProjectMemberItem) => member.project,
  )
  memberList: ProjectMemberList;

  @hasMany(
    () => ProjectFollowerList,
    (member: ProjectMemberItem) => member.project,
  )
  followerList: ProjectFollowerList;

  @hasMany(() => HabitList, (habit: HabitItem) => habit.project)
  habitList: HabitList;

  @hasMany(() => GoalList, (goal: GoalItem) => goal.project)
  goalList: GoalList;

  @hasMany(() => CategoryList, (category: CategoryItem) => category.project)
  categoryList: CategoryList;

  @hasMany(() => FileList, (file: FileItem) => file.project)
  fileList: FileList;

  // deprecated
  @hasMany(() => ProjectSubTaskListList, (task: TaskItem) => task.project)
  subTasksList: ProjectSubTaskListList;

  @hasMany(() => ProjectTaskList, (task: TaskItem) => task.project)
  taskList: ProjectTaskList;

  @hasMany(() => ActivityList, (activity: ActivityItem) => activity.project)
  activityList: ActivityList;

  // no prop for this one. depceated
  @hasOne(() => models.TaskItem, (task: TaskItem) => task.project)
  task: TaskItem;

  @hasMany(() => ProjectThrash, (thrash: ProjectThrash) => thrash.project)
  thrash: ProjectThrash;

  @hasMany(() => ProjectBlockerList, (blockers: ProjectBlockerList) => blockers.project)
  blockerList: ProjectBlockerList;

  @computed get slug(): string {
    return this.title && this.title.toLowerCase().replace(/ /g, "-");
  }

  @action setTitle(title: string) {
    this.title = title;
  }

  @action setMemberList(list: Array<ListPropsI<ProjectMemberList>>) {
    this.memberList.setItems(list);
  }

  @override async save() {
    return this.saveState
      .postJson("project/save", this.toJSON())
      .then(({ data }) => {
        return data?.id ? this.setJSON({
          id: data.id
        } as Partial<this>) : this
      });
  }
}
