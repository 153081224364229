import React from 'react'
import NormalizedEvent from './NormalizedEvent'
import './Draggable.scss'
import { getElTransformPos } from './utils'

const debug = require('debug')('treks:draggable')

export default class Draggable extends React.Component {

  static defaultProps = {
    dragDirection: 'both', // both|vertical|horizontal
    handleCssSelector: null, // selector of draggalbe 
  }

  elementRef = null

  elementClone = null

  store = {
    draggable: {
      top: 0,
      left: 0,
      startTop: 0,
      startLeft: 0
    },
    client: {
      startX: 0,
      startY: 0,
    }
  }

  onElementRef = ref => {
    const { elementRef } = this.props
    elementRef && elementRef(ref)
    if (ref) this.elementRef = ref
  }

  getElementTransformPos(el) {
    // @note we are only concerned about transform pos when dragging
    const pos = getElTransformPos(el)
    debug('pos', { pos })
    return pos
  }

  onDragStart = event => {
    const { elementRef } = this
    const { handleCssSelector } = this.props
    const { draggable, client } = this.store
    event = new NormalizedEvent(event)
    if (typeof event.button === 'number' && event.button !== 0) {
      return // not left mouse click
    }
    if (handleCssSelector) {
      const handle = elementRef.querySelector(handleCssSelector)
      if (!handle) throw Error('Prop handleCssSelector does not match any element')
      if (!event.composedPath().includes(handle)) {
        return // not dragging from handle
      }
    }
    event.preventDefault()
    event.stopPropagation()
    const { onDragStart } = this.props
    const { ownerDocument } = event.target
    client.startX = event.clientX;
    client.startY = event.clientY;
    const { top, left } = this.getElementTransformPos(this.elementRef)
    draggable.startTop = parseFloat(top)
    draggable.startLeft = parseFloat(left)
    ownerDocument.addEventListener('mousemove', this.onDrag)
    ownerDocument.addEventListener('mouseup', this.onDragEnd)
    ownerDocument.addEventListener('touchmove', this.onDrag)
    ownerDocument.addEventListener('touchend', this.onDragEnd)
    ownerDocument.addEventListener('touchcancel', this.onDragEnd)
    elementRef.classList.add('dragging')
    onDragStart && onDragStart({ top, left, elementRef })
    debug('onDragStart', { top, left, elementRef })
  }

  onDragEnd = event => {
    const { elementRef } = this
    event = new NormalizedEvent(event)
    const { onDragEnd, dragDirection } = this.props
    const { ownerDocument } = event.target
    const { draggable, client } = this.store
    const deltaX = event.clientX - client.startX
    const deltaY = event.clientY - client.startY
    if (dragDirection === 'both' || dragDirection === 'horizontal') {
      draggable.left = draggable.startLeft + deltaX
    }
    if (dragDirection === 'both' || dragDirection === 'vertical') {
      draggable.top = draggable.startTop + deltaY
    }
    const { top, left } = draggable
    event.preventDefault()
    event.stopPropagation()
    ownerDocument.removeEventListener('mousemove', this.onDrag)
    ownerDocument.removeEventListener('mouseup', this.onDragEnd)
    ownerDocument.removeEventListener('touchmove', this.onDrag)
    ownerDocument.removeEventListener('touchend', this.onDragEnd)
    ownerDocument.removeEventListener('touchcancel', this.onDragEnd)
    elementRef.classList.remove('dragging')
    onDragEnd && onDragEnd({ top, left, elementRef, deltaX, deltaY })
    debug('onDragEnd', { top, left, event, elementRef })
  }

  onDrag = event => {
    const { elementRef } = this
    event = new NormalizedEvent(event)
    event.preventDefault()
    event.stopPropagation()
    const { onDrag, dragDirection } = this.props
    const { draggable, client } = this.store
    const deltaX = event.clientX - client.startX
    const deltaY = event.clientY - client.startY
    if (dragDirection === 'both' || dragDirection === 'horizontal') {
      draggable.left = draggable.startLeft + deltaX
    }
    if (dragDirection === 'both' || dragDirection === 'vertical') {
      draggable.top = draggable.startTop + deltaY
    }
    const { top, left } = draggable
    debug('onDrag', { top, left, deltaX, deltaY })
    // controlled
    if (onDrag) {
      const shouldContinue = onDrag({ top, left, elementRef, deltaX, deltaY })
      if (shouldContinue === false) return
    }
    // uncontrolled
    const transform = { x: 0, y: 0 }
    if (dragDirection === 'both' || dragDirection === 'horizontal') {
      transform.x = draggable.left
    }
    if (dragDirection === 'both' || dragDirection === 'vertical') {
      transform.y = draggable.top
    }
    window.requestAnimationFrame(() => {
      this.elementRef.style.transform = `translate(${transform.x}px, ${transform.y}px)`
    })
  }

  render() {
    const {
      elementRef, // eslint-disable-line no-unused-vars 
      children, // eslint-disable-line no-unused-vars 
      onDrag, // eslint-disable-line no-unused-vars 
      onDragStart, // eslint-disable-line no-unused-vars 
      onDragEnd, // eslint-disable-line no-unused-vars 
      dragDirection, // eslint-disable-line no-unused-vars 
      handleCssSelector, // eslint-disable-line no-unused-vars 
      ...elProps
    } = this.props

    debug('render', { dragDirection })

    return (
      <div
        ref={this.onElementRef}
        className='draggable'
        {...elProps}
        onMouseDown={this.onDragStart}
        onTouchStart={this.onDragStart}
      >
        {children}
      </div>
    )
  }
}