import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const debug = require('debug')('treks:project:metrics:blockers:graph')

class BlockersGraph extends React.Component {

  getYMaxCeil(scale = 10, index = 0) {
    const yMax = this.props.data[index].data
      .reduce((max, { y }) => Math.max(y, max), 0)
    return Math.ceil(yMax/scale) * scale
  }

  render() {
    const { data } = this.props
    const yMax = this.getYMaxCeil()
    const yMaxRight = this.getYMaxCeil(1, 2)
    debug('render', { data, yMax, yMaxRight })
    return (
      <ResponsiveLine
        className="metrics-graph-line"
        data={data}
        margin={{
          "top": 50,
          "right": 150,
          "bottom": 50,
          "left": 50
        }}
        xScale={{
          "type": "point"
        }}
        yScale={{
          "type": "linear",
          "stacked": false,
          "min": 0,
          "max": yMax
        }}
        axisTop={null}
        axisBottom={{
          "orient": "bottom",
          "tickSize": 5,
          "tickPadding": 5,
          "tickRotation": 0,
          "legend": "Week",
          "legendOffset": 45,
          "legendPosition": "middle"
        }}
        axisLeft={{
          "orient": "left",
          "tickSize": 5,
          "tickPadding": 5,
          "tickRotation": 0,
          "legend": "Time to resolution (hrs)",
          "legendOffset": -45,
          "legendPosition": "middle"
        }}
        axisRight={{
          "orient": "right",
          "tickSize": 10,
          "tickPadding": 5,
          "tickRotation": 0,
          "legend": "Blockers Identified",
          "legendOffset": 45,
          "legendPosition": "middle",
          "max": yMaxRight
        }}
        colorBy={e => e.color}
        enableGridX={false}
        enableDots={false}
        enableArea={false}
        areaOpacity={1}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
            {
                "anchor": "bottom-right",
                "direction": "column",
                "justify": true,
                "translateX": 150,
                "translateY": 0,
                "itemsSpacing": 0,
                "itemDirection": "left-to-right",
                "itemWidth": 120,
                "itemHeight": 20,
                "itemOpacity": 0.75,
                "symbolSize": 12,
                "symbolShape": "square",
                "symbolBorderColor": "rgba(0, 0, 0, .5)",
                "effects": [
                    {
                        "on": "hover",
                        "style": {
                            "itemBackground": "rgba(0, 0, 0, .03)",
                            "itemOpacity": 1
                        }
                    }
                ]
            }
        ]}
      />
    )
  }
}

export default BlockersGraph
