import { Api } from './api';
import {
  getAuthToken, saveAuthToken, getTokenHeader, createGetParams, createPostParams,
} from './request';

export default Api;
export {
  getAuthToken, saveAuthToken, getTokenHeader,
  createGetParams,
  createPostParams,
  Api,
};