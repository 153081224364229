import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:project:title')

class ProjectTitle extends React.Component {

  onChange = event => {
    debug('title changed', event)
    this.props.project.setTitle(event.target.value) 
    this.props.project.willSave()
  }

  render() {
    const { project } = this.props
    debug('render', { project })
    return (
      <input 
        className={"title title-input input-borderless "} 
        value={ project.title } 
        onChange={this.onChange}
      />
    )
  }
}

export default observer(ProjectTitle)