import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import moment from 'moment'
import { TaskItem, TaskList } from 'Stores/Task';
import { getDayPlanner } from 'Stores/ActionPlanner/Planner/DayPlanner';
import { getWeekPlanner } from 'Stores/ActionPlanner/Planner/WeekPlanner';
import { autorun } from 'mobx'

const debug = require('debug')('treks:task:start-date')

class TaskStartDate extends React.Component {

  state = {
    getStartDate: null
  }

  componentDidMount() {
    autorun(() => {
      this.setState({
        startDate: this.getStartDate()
      })
    })
  }

  get dayPlannerFlow() {
    return getDayPlanner().plannerFlow
  }

  get weekPlannerFlow() {
    return getWeekPlanner().plannerFlow
  }

  getPlannerFlow(task) {
    if (this.dayPlannerFlow.getTaskPiecesForTask(task).length) {
      return this.dayPlannerFlow
    }
    return this.weekPlannerFlow
  }

  getStartDate() {
    const { item: task } = this.props
    const plannerFlow = this.getPlannerFlow(task)
    const taskPieces = plannerFlow.getTaskPiecesForTask(task)
    const firstTaskPiece = [ ...taskPieces ].pop()
    return firstTaskPiece && plannerFlow.getTaskPieceStartDate(firstTaskPiece)
  }

  static propTypes = {
    item: PropTypes.objectOf(() => TaskItem),
    list: PropTypes.objectOf(() => TaskList)
  }

  render() {
    const { item, list } = this.props
    const { startDate } = this.state
    if (!startDate) return null
    const { dateFormat, timeFormat } = list.opts
    const dateFormatted = startDate && moment(startDate).format(dateFormat)
    const timeFormatted = startDate && moment(startDate).format(timeFormat)
    debug('render', { item, startDate, dateFormat, timeFormat, dateFormatted, timeFormatted })
    return (
      <div className="task-start-date">
        <div className="date-formatted">{dateFormatted}</div>
        <div className="time-formatted">{timeFormatted}</div>
      </div>
     )
  }
}

export default observer(TaskStartDate)