import { EventEmitter } from 'events'
import MqttTransport from './mqtt';

const debug = require("debug")("treks:mqtt:channel");

export type MQTTChannelOpts = {
  id: string
  client: MqttTransport
}

export default class MQTTChannel extends EventEmitter {

  id: string
  client: MqttTransport

  constructor({ id, client }: MQTTChannelOpts) {
    super()
    this.id = id;
    this.client = client;
    this.client.on("connect", message => {
      this.emit("connect", message)
    });
  }

  subscribe(topic: string, fn: (data: any) => void) {
    const channelTopic = this.id + "/" + topic;
    debug("subscribe", channelTopic);
    return this.client.subscribe(channelTopic, fn);
  }

  publish(topic: string, message: any) {
    const channelTopic = this.id + "/" + topic;
    debug("publish", channelTopic);
    this.client.publish(channelTopic, message);
  }

}
