import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import stores, { getStore } from 'Stores';
import Resizable from '../../../Modules/Resizable';
import { action, observable, computed, makeObservable } from 'mobx';
import { getBucketList } from 'Stores/MainPaneGroup';
import EventResizeHandle from 'Components/ActionPlanner/ActionPlannerEvents/EventResizeHandle';
import ActionPlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils';
import { CalendarEvent } from 'Stores/Calendar';
import ActionPlannerStickyTaskHeader from 'Components/ActionPlanner/StickyTasks/ActionPlannerStickyTaskHeader';
import { getAppNotifications } from 'AppNotifications';
import CalendarEventHeader from './CalendarEventHeader';


const debug = require('debug')('treks:planner:event')

class ActionPlannerEvent extends React.Component {

  updateTimer = null

  constructor() {
    super()
    makeObservable(this)
  }

  @observable isHovered = false
  @computed get isFocused() {
    const { actionPlanner, event } = this.props
    return actionPlanner.focusedEvent === event
  }

  static defaultProps = {
    errorSaveEventMsg: 'Failed to save event updates',
    get actionPlanner() {
      return getStore(stores.ActionPlanner)
    },
    get mainPaneGroup() {
      return getStore(stores.MainPaneGroup)
    },
    style: {}
  }

  static propTypes = {
    event: PropTypes.objectOf(() => stores.CalendarEvent).isRequired,
    actionPlanner: PropTypes.objectOf(() => stores.ActionPlanner).isRequired,
    plannerUtils: PropTypes.objectOf(() => ActionPlannerUtils).isRequired
  }

  queueUpdate(fn, ms = 50) {
    this.updateTimer && clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => fn(), ms)
  }

  onPress = () => {
    const { event, actionPlanner, mainPaneGroup } = this.props
    debug('press', event)
    actionPlanner.setFocusedDatedItem(event)
    if (event.datedItem instanceof CalendarEvent) {
      const pane = getBucketList().findItem({ slug: 'event-detail' })
      mainPaneGroup.addOrUpdateItem(pane, { event: event.datedItem })
    } else {
      const pane = getBucketList().findItem({ slug: 'task-detail' })
      mainPaneGroup.addOrUpdateItem(pane, { task: event.datedItem })
    }
  }

  onMouseOver = event => {
    debug('onMouseOver', event)
    this.queueUpdate(() => this.isHovered = true)
  }

  onMouseOut = event => {
    debug('onMouseOut', event)
    this.queueUpdate(() => this.isHovered = false)
  }

  @action onResizeStart = ({ height }) => {
    debug('resize start', { height })
    document.body.style.cursor = 'row-resize'
  }

  @action onResizeEnd = ({ height }) => {
    debug('resize end', { height })
    document.body.style.cursor = ''
    this.props.event.save()
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(this.props.errorSaveEventMsg)
      })
  }

  @action onResize = ({ height }) => {
    debug('resize', { height })
    const duration = this.props.plannerUtils.getDurationFromHeight(height)
    this.props.event.setDuration(duration)
  }

  render() {
    const { event, width, height, style } = this.props
    const { id, datedItem } = event
    const { isHovered, isFocused } = this
    const className = "planner-event" 
      + (isFocused ? ' focused' : '') 
      + (isHovered ? ' hovered' : '')
    const HeaderCom = datedItem instanceof CalendarEvent 
      ? (
        <CalendarEventHeader
          event={event.datedItem}
          isHovered={isHovered}
          isFocused={isFocused}
        />
      )
      : (
        <ActionPlannerStickyTaskHeader
          task={event.datedItem}
          isHovered={isHovered}
          isFocused={isFocused}
        />
      )
    debug('render', { event })
    return (
      <Resizable
        key={id}
        className={className}
        style={{ width, height, ...style }}
        onMouseDown={this.onPress}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        resizeHandleClassName={'action-planner-event-resize-handle'}
        Handle={props => <EventResizeHandle event={event} {...props} />}
        resize={'vertical'}
        onResize={this.onResize}
        onResizeStart={this.onResizeStart}
        onResizeEnd={this.onResizeEnd}
      >
        <div className="event-container">
          {
            HeaderCom
          }
        </div>
      </Resizable>
     )
  }
}

export default observer(ActionPlannerEvent)