import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import App from '../Screens/App'
import Docs from '../Screens/Docs'

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/docs" component={Docs} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  )
}
export default Routes