import {
  ObservableMap,
  observable,
  action,
  toJS,
  reaction,
  IReactionDisposer,
  computed
} from 'mobx';

/**
 * State key/value map
 */
export interface IState {
  [key: string]: any;
}

/**
 * State keys
 */
export interface IKeys extends Array<string> {
  [index: number]: string;
}

/**
 * Model settings
 */
export interface IOptions extends Object {
  dynamic?: boolean
}

/**
 * Arbitrary State Store
 */
export default class ArbitraryModel {

  static create(state:IState, opts?:IOptions) {
    const model = new this(state, opts)
    return model
  }

  static fromProps<T extends typeof this, S extends IState>(state: S, opts?:IOptions): InstanceType<T> & S {
    return this.create(state, opts) as InstanceType<T> & S
  }

  /**
   * @property {object} Arbitrary state 
   */
  stateMap:ObservableMap = observable(new Map())

  reactionDisposer:IReactionDisposer|undefined

  get state():IState {
    return computed(() => toJS(this.stateMap)).get()
  }

  opts: IOptions = {
    dynamic: false
  }

  constructor(state:IState = {}, opts?:IOptions) {
    this.opts = { ...this.opts, ...opts }
    this.createKeys(Object.keys(state))
    this.setState(state)
    if (opts?.dynamic) {
      this.setupReactions()
    }
  }

  createKeys(keys:Array<string>) {
    keys.map(key => this.createStateGetter(key))
  }

  setupReactions():void {
    this.reactionDisposer = reaction(
      () => this.getKeys(),
      keys => keys.map(key => this.createStateGetter(key))
    )
  }

  disposeReactions():void {
    this.reactionDisposer && this.reactionDisposer()
  }

  createStateGetter(key:string):void {
    if (Object.getOwnPropertyDescriptor(this, key) || key in this.constructor.prototype) return
    Object.defineProperty(this, key, {
      get: () => {
        return computed(() => this.get(key)).get()
      },
      set: value => {
        this.set(key, value)
      }
    });
  }

  validateKeyExists(key:string):void {
    if (!this.opts.dynamic && !this.has(key)) {
      throw new Error(`State not found for key "${key}"`)
    }
  }

  @action setState(state:IState):void {
    this.stateMap.merge(state)
  }

  get(key:string):any {
    this.validateKeyExists(key)
    return this.stateMap.get(key)
  }

  set(key:string, value:any):void {
    this.validateKeyExists(key)
    this.stateMap.set(key, value)
  }

  has(key:string):boolean {
    return this.stateMap.has(key)
  }

  getKeys():IKeys {
    return Array.from(this.stateMap.keys())
  }

  toJSON(): any {
    return this.getKeys().reduce((json, key) => {
      return {
        ...json,
        [key]: toJS(this.get(key))
      }
    }, {})
  }

}