import { observable, action, computed, override } from 'mobx'
import { TeamI } from 'Stores/Team/Type/Team'
import { UserI } from 'Stores/User/Type/User'
import { Item } from '../Lists'

/**
 * Allow Team|User as Generic GroupItem
 */
export default class GroupItem extends Item {

  @observable user:UserI

  @observable team:TeamI

  @computed get item():UserI|TeamI {
    return this.user || this.team
  }

  @override async fetch():Promise<any> {
    return this.fetchState.get('group/member/' + this.id)
      .then((resp: any) => {
        this.setProps(resp.data)
        return resp
      })
  }

  @action async edit():Promise<any> {
    return this.fetchState.postJson('group/member/edit/' + this.id, this.toJSON())
  }

  @action async add():Promise<any> {
    return this.fetchState.postJson('group/memeber/add', this.toJSON())
  }

}