import { observable, action, override, computed } from "mobx";
import { hasOne } from "Relationships/RelationshipDecorators";
import { GoalItem } from "Stores/Goal";
import { ProjectItem } from "Stores/Project";
import TaskItem from "Stores/Task";
import { once, uid } from "Stores/utils";
import { Item } from "../Lists";
import GridList from "./GridList";
import { importWidgetItem } from "./WidgetItem";

const debug = require("debug")("treks:models:GridItem");

export const importGridItem = once(() => {
  class GridItem extends Item {
    get ListType() {
      return GridList;
    }
    @observable i = "";
    @observable x;
    @observable y;

    @observable w = 1;
    @observable h = 1;

    @observable maxH = 5;
    @observable minH = 0;

    @observable wScale = 4;

    @observable deletedDate: Date = null;

    @observable dataType: string = "task";

    @hasOne(() => TaskItem)
    task: TaskItem;

    @hasOne(() => ProjectItem)
    project: ProjectItem;

    @observable goal: GoalItem = null;
    @observable dashboardId: number = null;

    @observable dateInterval: string = "";
    @observable startDate: Date = null;
    @observable endDate: Date = null;

    @observable widgets: Array<any> = [];

    @override fromJSON(json: any) {
      debug("GridItem fromjson", json);
      let widgetModels = [];
      JSON.parse(json.widgets).map((w) =>
        widgetModels.push(importWidgetItem().fromJSON(w))
      );
      json.widgets = widgetModels;
      super.fromJSON(json);
      return this;
    }

    @action setWidgets(arr) {
      this.widgets = arr;
      this.setMinHeight(arr);
    }
    @action setMinHeight(arr = this.widgets) {
      // let min = 2
      let maxY = -1;
      let maxH = 0;
      arr.forEach((w) => {
        // debug('w', w.y, w.h, 'maxH', maxH, 'maxY', maxY)
        if (w.y > maxY) {
          maxY = w.y;
          maxH = w.h;
        }
        if (w.y === maxY) if (w.h > maxH) maxH = w.h;
      });
      this.minH = maxH + maxY > 2 ? maxH + maxY : 2;
    }

    @computed get dataSource() {
      if (this.task && this.dataType === "task") return this.task;
      if (this.project && this.dataType === "project") return this.project;
      if (this.goal && this.dataType === "goal") return this.goal;
      return null;
    }

    @action setDataSource(source) {
      this.task = null;
      this.project = null;
      this.goal = null;
      switch (this.dataType) {
        case "task":
          this.task = source;
          break;
        case "project":
          this.project = source;
          break;
        case "goal":
          this.goal = source;
          break;
      }
      this.save();
    }

    @action changeDataType(type: string) {
      if (this.dataType === type) {
        this.dataType = "";
        return;
      }
      this.dataType = type;
    }

    @action removeWidget(index: number) {
      let arr = [...this.widgets];
      arr.splice(index, 1);

      this.widgets = arr;
    }

    @computed get grid() {
      let arr = [...this.widgets];
      let rows = [];

      //create rows with w spots
      for (let i = 0; i < this.h; i++)
        rows[i] = new Array(this.w * this.wScale);

      arr.forEach((w) => {
        let xStart = w.x;
        let xEnd = w.x + w.w;
        let yStart = w.y;
        let yEnd = w.y + w.h;

        for (let y = yStart; y < yEnd; y++)
          for (let x = xStart; x < xEnd; x++) rows[y][x] = w.i;
      });

      return rows;
    }

    @action addWidget(w = null) {
      debug(
        "adding widget to container",
        w,
        this.x,
        this.y,
        this.w,
        this.h,
        this.maxH,
        w
      );

      let arr = [...this.widgets];

      let xPos = 0;
      let yPos = Infinity;
      let foundEmptySpot = false;

      let rows = this.grid;

      if (rows[0]) {
        let reorder = true;
        rows[0].forEach((c) => {
          if (c != null) {
            reorder = false;
          }
        });

        if (reorder) {
          rows.push(rows.shift());
        }
      }
      debug("creating in the rows", rows);

      Object.values(rows).forEach((r: Array<Number>, yIdx) => {
        if (r.length === 0) r = new Array(this.w * this.wScale);
        for (let xIdx = 0; xIdx < r.length; xIdx++) {
          let c = r[xIdx];
          if ((c === null || c === undefined) && !foundEmptySpot) {
            xPos = xIdx;
            yPos = yIdx;
            foundEmptySpot = true;
            for (let hIdx = yPos; hIdx < yPos + w.info.h; hIdx++) {
              for (let wIdx = xPos; wIdx < xPos + w.info.w; wIdx++) {
                if (
                  !rows[hIdx] ||
                  wIdx > rows[hIdx].length - 1 ||
                  rows[hIdx][wIdx] !== undefined
                ) {
                  foundEmptySpot = false;
                  debug(
                    "cursor at : " +
                      xPos +
                      "," +
                      yPos +
                      " x: " +
                      wIdx +
                      " y: " +
                      hIdx +
                      " NOT OK: " +
                      rows
                  );
                } else {
                  debug(
                    "cursor at : " +
                      xPos +
                      "," +
                      yPos +
                      " yIdx: " +
                      yIdx +
                      " x: " +
                      wIdx +
                      " y: " +
                      hIdx +
                      " ok:" +
                      rows[hIdx][wIdx]
                  );
                }
              }
            }
          }
        }
      });

      if (!foundEmptySpot && this.h + w.info.h <= this.maxH) {
        this.h = this.h + w.info.h;
        debug("creating new row: new height", this.h);
      } else if (!foundEmptySpot) {
        debug("no room", this.h, w.info.h, this.maxH);
        return;
      } else {
        debug("creating widget x:" + xPos + " y:" + yPos);
      }

      let id = uid();

      let widget = importWidgetItem().create({
        x: xPos,
        y: xPos,
        uid: id,
      });
      widget.w = w ? w.info.w : 1;
      widget.h = w ? w.info.h : 1;
      widget.widgetType = w ? w.info.widgetType : null;
      widget.x = xPos;
      widget.y = yPos;
      if (w.data) {
        widget.data = w.data;
      }

      arr.push(widget);
      this.setWidgets(arr);
    }

    async save() {
      debug("saving");
      return this.saveState.postJson("dashboard/item/save", this.toJSON());
    }
  }

  return GridItem;
});
