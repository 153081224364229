import React from 'react'
import { observer } from 'mobx-react';
import PropTypes from 'prop-types'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import '../ActionPlannerEvents/ActionPlannerEvents.scss'
import EventContextMenu from 'Containers/ActionPlanner/ContextMenu/EventContextMenu'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import ActionPlannerUtils, { numDaysBetweenDates, toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils';
import DayDraggableEvent from './DayDraggableEvent';
import { createMockEvents } from 'Screens/Docs/PlannerFlow/mocks';
import { CalendarEvent } from 'Stores/Calendar';

const debug = require('debug')('treks:planner:events')

export default observer(class DayPlannerEventsFlow extends React.Component {

  uiStore = ArbitraryModel.fromProps({
    showContextMenu: false,
    contextMenuPos: {left: null, top: null }
  })

  static defaultProps = {
    stackOffset: 50, 
    maxStacks: 8,
    maxWidth: '100%',
    widthOffset: 120,
    onTaskOffset: 50,
    leftOffset: 0
  }

  static propTypes = {
    plannerFlow: PropTypes.objectOf(() => PlannerFlow).isRequired,
    plannerUtils: PropTypes.objectOf(() => ActionPlannerUtils).isRequired,
  }

  componentDidMount() {
    // this.setMockEvents()
  }

  setMockEvents() {
    const events = createMockEvents(10)
    console.log('setting events', events)
    this.props.actionPlannerStore.setEvents(events)
  }

  onShowContextMenu = ({ event, nativeEvent }) => {
    if (localStorage.noEventContextMenu) return // disable in dev
    nativeEvent.preventDefault()
    this.setFocusedEvent(event)
    const { clientX, clientY } = nativeEvent
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.addEventListener('scroll', this.onHideContextMenu)
    Object.assign(this.uiStore, { 
      showContextMenu: true, 
      contextMenuPos: { left: clientX, top: clientY }
    })
    debug('showing context menu on event', { event, nativeEvent })
  }

  onHideContextMenu = () => {
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.removeEventListener('scroll', this.onHideContextMenu)
    Object.assign(this.uiStore, {
      showContextMenu: false,
      contextMenuPos: { left: null, top: null }
    })
  }

  setFocusedEvent(event) {
    console.log('setting focused event', event)
    this.props.actionPlannerStore.setFocusedEvent(event.datedItem)
  }

  render() {
    const {
      actionPlannerStore,
      plannerFlow,
      plannerUtils,
      stackOffset,
      maxStacks,
      maxWidth,
      widthOffset,
      onTaskOffset,
      leftOffset,
      dragDirection
    } = this.props
    const { showContextMenu, contextMenuPos } = this.uiStore
    const events = plannerFlow.visibleEvents
    debug('render', { events })
    return (
      <div className="action-planner-events action-planner-items">
        {
          events.map(event => {
            return (
              <DayDraggableEvent
                key={event.uid}
                plannerFlow={plannerFlow}
                plannerUtils={plannerUtils}
                dragDirection={dragDirection}
                stackOffset={stackOffset}
                maxStacks={maxStacks}
                maxWidth={maxWidth}
                widthOffset={widthOffset}
                onTaskOffset={onTaskOffset}
                leftOffset={leftOffset}
                event={event}
                onShowContextMenu={this.onShowContextMenu}
              />
            )
          })
        }
        { 
          showContextMenu ? 
          <EventContextMenu
            event={actionPlannerStore.focusedEvent}
            contextMenuPos={contextMenuPos}
            onHideContextMenu={this.onHideContextMenu}
          /> : null 
        }
      </div>
    )
  }
})
