import { observable } from "mobx"
import { ActionPlannerOpts } from "./ActionPlanner"

/**
 * Action Planner UI calculations
 */
export default class ActionPlannerUtils {

  @observable opts: ActionPlannerOpts = observable.object({
    taskMargin: 0,
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'h:mm A',
    durationStep: 1,
    durationInterval: 15, 
    durationHeightPerInterval: 60, 
    minDuration: 5,
  })

  setOpts(opts: ActionPlannerOpts) {
    Object.assign(this.opts, opts)
  }

  get minHeight() {
    return this.getHeightFromDuration(this.opts.minDuration)
  }

  constructor(opts?: ActionPlannerOpts) {
    if (opts) this.setOpts(opts)
  }

  formatDuration(duration: number, children?: boolean) {
    const hours = Math.floor(duration/60)
    const mins = duration % 60
    const formatted = (hours ? hours + ':' : '') + (hours ? ('00' + mins).slice(-2) : mins)
    return children ? '[' + formatted + ']' : formatted
  }

  getStickyDuration(duration) {
    const { durationStep } = this.opts
    const durationMod = duration%durationStep
    const stickyDuration = durationMod < durationStep/2 
      ? duration - durationMod
      : duration - durationMod + durationStep
    return stickyDuration
  }

  getDurationMins(duration, children?: any[]) {
    let durationMins = duration
    if (children) {
      durationMins += children.map(child => {
        const { duration, children } = child
        return this.getDurationMins(duration, children)
      }).reduce((value, current) => value + current, 0)
    }
    return parseFloat(durationMins || 0)
  }

  getDurationFromHeight(height) {
    const { durationHeightPerInterval, durationInterval } = this.opts
    const durationHeight = durationHeightPerInterval / durationInterval
    return height / durationHeight
  }

  getHeightFromDuration(duration) {
    const { durationHeightPerInterval, durationInterval } = this.opts
    const durationHeight = durationHeightPerInterval / durationInterval
    return duration * durationHeight
  }

}

export function isSameDay(d1: Date, d2: Date) {
  return d1.getDate() === d2.getDate()
    && d1.getMonth() === d2.getMonth()
    && d1.getFullYear() === d2.getFullYear();
}

export const msPerMin = 60 * 1000
export const msPerHour = 60 * msPerMin
export const msPerDay = 24 * msPerHour

/**
 * @note Sun|Mon|Tue|Wed|Thu|Fri|Sat
 */
export function toDayId(date: Date): string {
  const ids = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return ids[toDayIndex(date)]
}

/**
  * @property {Number} (0-6 Sun-Sat) 
  * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
  * @note different from the dayList.items index
 */
export function toDayIndex(date: Date): number {
  return new Date(date).getDay()
}

/**
 * @note Removes the timezone offset from the date
 * @note msPerDay is now accurate as UTC days are always 24 hours (no leap)
 */
export function toUTC(date: Date): Date {
  const utcDate = new Date(date)
  utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset())
  return utcDate
}

export function numDaysBetweenDates(startDate: Date, endDate: Date) {
  return ((toUTC(endDate).getTime() - toUTC(startDate).getTime()) / msPerDay)
}

export function minsBetweenDates(startDate: Date, endDate: Date) {
  return ((toUTC(endDate).getTime() - toUTC(startDate).getTime()) / msPerMin)
}

export const dateAddDays = (date: Date, days: number): Date => {
  return new Date(date.getTime() + (days*msPerDay))
}

export const dateAddHours = (date: Date, hours: number): Date => {
  return new Date(date.getTime() + (hours*msPerHour))
}

export const dateAddMins = (date: Date, mins: number): Date => {
  return new Date(date.getTime() + (mins*msPerMin))
}

export const toDayName = (date: Date) => {
  return date.toLocaleDateString('en-gb', { weekday: 'long' })
}

export const toStartOfDayDate = (date: Date) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0))
}