import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

export default observer(class PFAScoreCard extends React.Component {

  static propTypes = {
    pfaScore: PropTypes.number,
    pfaColor: PropTypes.string
  }

  static defaultProps = {
    pfaScore: 25,
    pfaColor: ''
  }

  onClick = () => {
    this.props.pfaModalStore.show()
  }

  render() {
    const { pfaColor, pfaScore } = this.props
    return (
      <div className='pfa-label'>
        <div className='pfa-label-name'>PFA</div>
        <div
          className='pfa-label-value'
          style={{
            color: pfaColor
          }}
        >
          {Math.floor(pfaScore)}
        </div>
      </div>
    )
  }

})
