import OrderedList from 'Stores/Lists/OrderedList';
import CategoryItem from './CategoryItem'
import { computed, override } from 'mobx'
import { ProjectItem } from 'Stores/Project'
import { GoalItem } from 'Stores/Goal';
import { hasOne } from 'Relationships/RelationshipDecorators';

/**
 * Category List
 */
export default class CategoryList extends OrderedList {

  get ModelType():any {
    return CategoryItem
  }

  @hasOne(() => GoalItem, (goal: GoalItem) => goal.categoryList)
	goal: GoalItem

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.categoryList)
  project: ProjectItem

  @computed get parent(): ProjectItem | GoalItem {
    return (this.project?.id && this.project) || this.goal
  }

  @override get saveOrderKey(): string {
    const url = 'list/order/' + this.parent.modelName + '/' + this.parent?.id
    return url
  }

  @override async fetch() {
    const projectId = this.project?.id
    if (!projectId) {
      console.warn('Fetching projectList without project.id.')
      return
    }
    return this.fetchState.get('category/list', { projectId })
      .then(({ data }) => this.fromJSON({ items: data }))
  }

  toJSON() {
    const items = this.items.map(item => item.toJSON())
    return { items }
  }

  @override fromJSON(json) {
    const { project } = json
    const props = (({ project, ...props}) => props)(json) // eslint-disable-line
    super.fromJSON(props)
    if (project) {
      this.project = ProjectItem.fromId(project.id) as ProjectItem
    }
    return this
  }

}
