import ViewUserDetail from '../Form/FormControlViewUserDetail'
import UserSelect from '../Form/FormControlUserSelect'
import UserEditableText from '../Form/FormControlUserEditableText'

const userFields = [
  {
    name: 'detail',
    label: '',
    value: null,
    Component: ViewUserDetail
  },
  {
    name: 'selected',
    label: 'Sel',
    value: null,
    Component: UserSelect
  },
  {
    name: 'id',
    label: '#',
    value: null,
  },
  {
    name: 'name',
    label: 'Name',
    value: null,
    Component: UserEditableText,
  },
  {
    name: 'email',
    label: 'Email',
    value: null,
    Component: UserEditableText,
  },
  {
    name: 'plan',
    label: 'Plan',
    value: null,
  },
  {
    name: 'type',
    label: 'Type',
    value: null,
  },
  {
    name: 'seats',
    label: 'Seats',
    value: null,
  },
  {
    name: 'created',
    label: 'Reg Date',
    value: null,
  },
  {
    name: 'status',
    label: 'Status',
    value: null,
  },
  {
    name: 'act',
    label: 'Act',
    value: null,
  },
  {
    name: 'domain',
    label: 'Domain',
    value: null,
  },
  {
    name: 'state',
    label: 'State',
    value: null,
  },
  {
    name: 'country',
    label: 'Country',
    value: null,
  },
]

export default userFields