import React from 'react'
import * as Sentry from '@sentry/browser';
//import logger from '../lib/logger'
import AppError from '../Screens/App/AppError'
//import lingerprint from '../lib/lingerprint'
import StackTrace from 'stacktrace-js'

const debug = require('debug')('treks:error')

export default class ErrorBoundary extends React.Component {
  
  state = { 
    error: null,
    hasError: false,
    eventId: null
  }

  async componentDidCatch(error, info) {
    let stack, eventId
    if (process.env.NODE_ENV === 'production') {
      stack = await StackTrace.fromError(error)
      eventId = await this.logErrorToSentry(error, { ...info, stack })
    }
    debug('componentDidCatch', { eventId, error, stack, info })
    //logger.write('componentDidCatch', { error, stack, info })
    this.setState({ eventId, hasError: true, error })
  }

  async logErrorToSentry(error, info) {
    /**
     * @todo initializing sentry here to prevent infinite loop in Account.setProps()
     */
    Sentry.init({
      dsn: "https://0c67c19b5c29488b8ba2ac68bcb2e53f@sentry.io/1454728"
    });
    return new Promise(resolve => {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        resolve(eventId)
      });
    })
  }

  render() {
    const { hasError, error, eventId } = this.state
    if (hasError) {
      return (
        <AppError error={error} eventId={eventId} />
      );
    }
    return this.props.children
  }
}