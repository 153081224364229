import React from 'react'
import { observer } from 'mobx-react'
import data from './data'
import BlockersGraph from './BlockersGraph'
import { ProjectItem } from 'Stores/Project'
import { ProjectBlockerList } from 'Stores/Project/Blocker/ProjectBlockerList'
import { ProjectBlockerItem } from 'Stores/Project/Blocker/ProjectBlockerItem'

const debug = require('debug')('treks:project:metrics:blockers')

class ProjectMetricBlockers extends React.Component<{
  project: ProjectItem,
  data: any
}, {
  data: any
}> {

  static defaultProps = {
    data
  }

  componentDidMount() {
    this.setWeekBlockerData();
  }

  async setWeekBlockerData() {
    const { blockerList } = this.props.project;
    await blockerList.fetch();
    const data = this.getActivityData(blockerList);
    debug('data', data)
    this.setState({
      data
    });
  }

  getActivityData(activityList: ProjectBlockerList) {
    const { weeksBlockers } = activityList
    debug('weeksBlockers', weeksBlockers)
    const blockers = weeksBlockers.map((week, index) => {
      return {
        x: index + 1,
        y: week.blockers.length,
      };
    });
    const resolutions = weeksBlockers.map((week, index) => {
      const { unblockers } = week
      const resolutions = unblockers.map(unblocker => {
        let blocker: ProjectBlockerItem
        weeksBlockers.forEach((week) => {
          const _blocker = week.blockers.find(blocker => (
            blocker.taskId === unblocker.taskId
          ))
          if (_blocker) {
            blocker = _blocker
          }
        })
        const resolveTime = blocker ? unblocker.createDate.getTime() - blocker.createDate.getTime() : 0
        return resolveTime
      }).filter(ms => ms)
      const sum = resolutions.reduce((sum, ms) => sum + ms, 0)
      const avg = resolutions.length ? sum/resolutions.length : 0
      const max = resolutions.reduce((max, ms) => Math.max(ms, max), 0)
      const hourMs = 1000 * 60 * 60
      return {
        avg: avg / hourMs,
        max: max / hourMs
      };
    });
    const avgResolve = resolutions.map((week, index) => {
      return {
        x: index + 1,
        y: week.avg
      }
    })
    const maxResolve = resolutions.map((week, index) => {
      return {
        x: index + 1,
        y: week.max
      }
    })
    debug('blockers', {
      avgResolve,
      maxResolve,
      blockers
    })
    return [
      {
        "id": "Max time to resolution",
        "color": "#97160c",
        data: maxResolve
      },
      {
        "id": "Ave time to resolution",
        "color": "#ed6e21",
        data: avgResolve
      },
      {
        "id": "Blockers identified",
        "color": "#666666",
        data: blockers
      }
    ];
  }

  getBlockersResolveData(activityList: ProjectBlockerList) {
    const { weeksBlockers } = activityList
    const outstandingCount = weeksBlockers
      .reduce((sum, week) => {
        return sum + week.blockers.length
      }, 0)
    const resolvedCount = weeksBlockers
    .reduce((sum, week) => {
      return sum + week.unblockers.length
    }, 0)
    return { outstandingCount, resolvedCount }
  }

  render() {
    const { project } = this.props
    const data = this.state?.data || this.props.data
    debug('render', data)
    const {
      outstandingCount,
      resolvedCount
    } = this.getBlockersResolveData(project.blockerList)
    return (
      <div className="metrics-graph-container metrics-graph-blockers">
        <div className="metrics-graph">
          <div className="metrics-stats">
            <div className="metrics-stat row-flex">
              <div className="stat-value">{outstandingCount}</div>
              <label className="stat-label">Blocker Outstanding</label>
            </div>
            <div className="metrics-stat metrics-stat-resolved row-flex">
              <div className="stat-value">{resolvedCount}</div>
              <label className="stat-label">Blockers Resolved</label>
            </div>
          </div>
          <BlockersGraph data={data} />
        </div>
      </div>
    )
  }
}

export default observer(ProjectMetricBlockers)
