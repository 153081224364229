import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskRemoveFromPlannerIcon from 'Components/TaskDetail/TaskRemoveFromPlannerIcon';
import { getAppNotifications } from 'AppNotifications';
import StickyTaskDoneCheckbox from './StickyTaskDoneCheckbox'
import TaskDuration from 'Components/TaskDetail/TaskDuration';
import "./StickyTaskHeader.scss"

const debug = require('debug')('treks:StickyTaskHeader')

class StickyTaskHeader extends React.Component {

  saveTimer = null

  static defaultProps = {
    removeEventStartMsg: null,
    removeEventFailMsg: 'Failed to remove task from your action planner',
    removeEventSuccessMsg: 'The Event has been removed from your action planner',
    deleteEventStartMsg: null,
    deleteEventFailMsg: 'Failed to delete task',
    deleteEventSuccessMsg: 'The Event has been deleted',
    saveTimeout: 1000
  }

  onRemoveFromPlanner = () => {
    const { task, removeEventStartMsg, removeEventFailMsg, removeEventSuccessMsg } = this.props
    if (removeEventStartMsg) getAppNotifications().show(removeEventStartMsg)
    task.removeFromPlanner()
      .then(() => getAppNotifications().warn(removeEventSuccessMsg))
      .catch(error => getAppNotifications().error(error.message || removeEventFailMsg))
  }

  onTitleChange = ({ target }) => {
    const { task, saveTimeout } = this.props
    task.title = target.value
    clearTimeout(this.saveTimer)
    this.saveTimer = setTimeout(() => task.save(), saveTimeout)
  }

  render() {
    const { task, isHovered, isFocused, actionPlannerStore } = this.props
    debug('render', task, { isHovered, isFocused })
    return (
      <header className='planner-sticky-task-header'>
        <Icon name={'drag'} size={10} />
        {
          (isHovered)
            ? <StickyTaskDoneCheckbox task={task} />
            : <Icon icon={'calendar'} size={15} />
        }
        <div className={'title' + (task.done ? ' done' : '')}>
          <input
            className="input-borderless"
            name={'task-title'}
            value={task.title}
            onChange={this.onTitleChange}
            onMouseDown={task => task.stopPropagation()}
          />  
        </div>
        <TaskDuration
            item={task}
            list={actionPlannerStore}
            onFocus={() => {}}
            disabled={false}
          />
        <TaskRemoveFromPlannerIcon
          item={task}
          onClick={this.onRemoveFromPlanner}
        />
      </header>
    )
  }
}

export default observer(StickyTaskHeader)