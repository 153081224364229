import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskFileDetail from '../../../TaskDetail/TaskFileDetail'
import Pressable from '../../../../theme/Pressable'

const debug = require('debug')('treks:project:file:item')

class FileItem extends React.Component {

  ui = observable({
    showFileDetail: false
  })

  onClick = event => {
    event.preventDefault()
    debug('onClick', this.ui)
    this.ui.showFileDetail = true
  }

  onpenFileInNewWindow(file) {
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(file)
    link.download = file.name
    link.click()
  }

  openBlobInNewWindow(blob) {
    const type = 'application/octet-stream'
    const fileBlob = blob.type ? blob : new Blob([blob], { type })
    this.onpenFileInNewWindow(fileBlob)
  }

  onCloseModal = () => {
    debug('onClose', this.ui)
    this.ui.showFileDetail = false
  }

  /**
   * @note Do not access fileItem.file until user wants it as it will trigger the whole file donwload
   * @important The file is lazily downloaded and created on referencing fileItem.file 
   * @important This includes any operation on fileItem that references fileItem.file 
   * @important eg: JSON.stringify(fileItem), toJS(fileItem) etc.
   */
  render() {
    const { fileItem } = this.props
    const { name, type, mimeType } = fileItem
    debug('render', { fileItem, name, type, mimeType, ui: this.ui })
    return (
      <div className="file-item">
        <Pressable className="file-col file-col-6" onClick={this.onClick}>
          <Icon 
            set="file" 
            icon={type}
            color={'#808284'} 
            size={24}
          />
          <span className="file-meta file-name" title={name}>{name}</span>
        </Pressable>
        <Pressable className="file-col file-meta file-task">

        </Pressable>
        <div className="file-col">
          <Pressable className="file-meta file-owner">

          </Pressable>
          <Pressable className="file-meta file-create-date">

          </Pressable>
        </div>
        <TaskFileDetail  
          fileItem={fileItem} 
          show={this.ui.showFileDetail}
          onClose={this.onCloseModal}
        />
      </div>
    )
  }
}

export default observer(FileItem)