import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from './utils'

export const TaskParentField = observer(({ item, fields, noGroupLabel }) => {
  const backgroundColor = item.parent?.color
  // const defaultStyle = { width: 160 }
  const style = backgroundColor ? { backgroundColor } : {}
  return (
    fieldIsEnabled(fields, 'parent') ? (
      <div
        className="attribute attribute-parent text-overflow-ellipses"
        style={style}
      >
        {item.parent.id ? item.parent.title : (noGroupLabel || 'Stray Tasks')}
        {/* {' : '}
        {item.category.id ? item.category.title : 'UnCategorized'} */}
      </div>
    ) : null
  )
})