import { observer } from "mobx-react"
import DueToday from "."
import "./WeekDueToday.scss"

function hasDueItems(days) {
  return days.find(day => day.itemsDue.length > 0)
}

export const WeekDueToday = observer(({ actionPlannerStore }) => {
  const groupLabels = { 
    TaskItem: false,
    CalendarEvent: false
  }

  if (!hasDueItems(actionPlannerStore.dayList.items)) return null

  return (
    <div className="week-due-today">
      <label className="group-labels">
        Due <br />Today
      </label>
      <div className="week-due-today-day-list">
        {
          actionPlannerStore.dayList.items.map(day => (
            <DueToday
              key={day.dayId}
              items={day.itemsDue}
              groupLabels={groupLabels}
              itemHeight={25}
              maxItemsVisible={3} 
            />
          ))
        }
      </div>
    </div>
  )
})