import { override } from "mobx";
import List from "Stores/Lists";
import { PFAScoreSnap } from "./PFAScoreSnap";

export class PFAScoreSnapList extends List {

  get ModelType() {
    return PFAScoreSnap
  }

  get  timestamp() {
    return new Date().getTime() // @todo update time
  }

  @override async fetch() {
    const json = await this.fetchState.get('pfa/score/list/' + this.timestamp)
    console.log('PFAScoreSnapList resp', json, this)
    return this.fromJSON({ items: json.data})
  }

}