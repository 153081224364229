import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { stores } from 'Stores'
import userSearchFields from './Fields/UserSearch'
import { FormField } from 'theme/Form'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:theme:UserSearch')

class UserSearch extends React.Component {

  fieldsStore = stores.Form.fromFields(userSearchFields)

  onSearch = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.fieldsStore.getFieldValues())
    }
  }

  getUserSearchFieldsGroups(size = 3) {
    const fields = [...this.fieldsStore.fields]
    const groups = []
    while(fields.length) {
      groups.push(fields.splice(0, size))
    }
    return groups
  }

  onFieldChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  onFieldKeyDown = (event) => {
    debug('field change', event)
    if (event.key === 'Enter') {
      this.onSearch()
    }
  }

  render() {
    const total = this.props.total || 0
    const info = total + ' results found'

    const userSearchFieldsGroups = this.getUserSearchFieldsGroups()

    return (
      <Form className="admin-user-search" autoComplete="new-password">
        <fieldset>
          <legend>Search/Filter</legend>
          <Container>
            <Row>
              {
                userSearchFieldsGroups.map(fieldsRow => {
                  return (
                    <Col key={fieldsRow[0].name} className="col-3">
                      {
                        fieldsRow.map(field => {
                          return (
                            <FormField 
                              key={field.name}
                              {...field} 
                              onChange={(value, event) => this.onFieldChange(field, value, event)} 
                              onKeyDown={this.onFieldKeyDown}
                            />
                          )
                        })
                      }
                    </Col>
                  )
                })
              }
            </Row>
            <Row>
              <Col className="footer">
                <Row className="results-info"><div>{info}</div></Row>
                <Row><Button className="btn-search" onClick={this.onSearch}>Search</Button></Row>
              </Col>
            </Row>
          </Container>
        </fieldset>
      </Form>
    )
  }
}

export default observer(UserSearch)