import { observable, computed, action, override } from "mobx";
import { Item } from "Stores/Lists";
import ActivityList from "./ActivityList";
import { User } from "../User";
import moment from "moment";
import { DateModel } from "Stores/Model/DateModel";
import { ProjectItem } from "Stores/Project";
import { hasOne } from "Relationships/RelationshipDecorators";

type ActivityItemJson = {
  [k in keyof ActivityItem]: [ActivityItem[k]];
};

/**
 * Activity Item
 * @note Owner is derived from activity subject
 */
export default class ActivityItem extends Item {
  get ListType() {
    return ActivityList;
  }

  @observable type: string = null;

  @observable title: string = null;

  @observable description: string = null;

  @observable action: string = null;

  @observable changes: any = null;

  @observable before: ActivityItemJson = null;

  @observable after: ActivityItemJson = null;

  @observable user: User;

  @observable creator: User;

  @observable createDate = new Date();

  @observable taskId?: number;

  @observable categoryId?: number;

  @observable projectId?: number;

  @computed get thrash() {
    return Math.abs(this.beforeState?.duration - this.afterState?.duration);
  }

  @computed get beforeState() {
    return this.before ? JSON.parse(this.before) : {};
  }

  @computed get afterState() {
    return this.after ? JSON.parse(this.after) : {};
  }

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.activityList)
  project: ProjectItem;

  @computed get titleFormatted(): string {
    return this.title
      ?.replace("{user}", this.user?.name)
      .replace("{creator}", this.creator?.name);
  }

  @computed get since() {
    return moment(this.createDate).from(DateModel.currentDate);
  }

  /**
   * @param {Date}
   */
  @action setCreateDate(createDate) {
    this.createDate = new Date(createDate);
  }

  @override fromJSON(data) {
    Object.assign(this, {
      id: data.id,
      type: data.type,
      action: data.action,
      title: data.title,
      description: data.description,
      user: User.fromProps({ id: data.userId }),
      createDate: new Date(data.createDate),
      changes: data.changes ? JSON.parse(data.changes) : {},
      before: data.before,
      after: data.after,
      taskId: data.taskId,
      categoryId: data.categoryId,
      projectId: data.projectId,
    });
    return this;
  }

  toJSON() {
    const json = {
      id: this.id,
      uid: this.uid,
      type: this.type,
      action: this.action,
      title: this.title,
      description: this.description,
      user: this.user.toJSON(),
      createDate: this.createDate,
      changes: this.changes,
      before: this.before,
      after: this.after,
      taskId: this.taskId,
      categoryId: this.categoryId,
      projectId: this.projectId,
    };
    return json;
  }
}
