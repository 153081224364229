import { uid } from "Stores/utils";

export const addRandomItemsToList = lists => {
  const listLen = Math.floor(Math.random() * 5)
  const tempList = new Array(listLen).fill()
  lists.forEach(list => {
    let len = list.items.length
    tempList.forEach(() => {
      const id = list.name + '-' + uid()
      const name = list.name + ' ' + ++len
      const duration = Math.max(Math.floor(Math.random() * 200), 30)
      const subtasks = Math.random() > 0.7 && list.group !== 'event' ? (
        new Array(Math.floor(Math.random() * 5)).fill().map((val, index) => ({
          id: id + '-subtask-' + index,
          name: list.name + ' sub task ' + index,
          duration: Math.floor(Math.random()*50) + 15,
          subtasks: [],
          events: []
        }))
      ) : []
      const eventsDuration = duration - subtasks.reduce((total, { duration }) => total + duration, 0)
      const events = (new Array(Math.max(Math.floor(eventsDuration/5), 0)).fill()).map((value, index) => ({
        id: id + '-event-' + index,
        duration: 5,
        subtasks: [],
        events: []
      }))
      const item = {
        id,
        name,
        type: list.group.name || list.group,
        duration,
        subtasks,
        events
      }
      list.items.push(item)
    })
  })
}