import { override } from 'mobx'
import ProjectMemberItem from './ProjectMemberItem'
import { ProjectItem } from '.'
import MemberList from 'Stores/Member/MemberList'
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class ProjectMemberList extends MemberList {

  get ModelType() {
    return ProjectMemberItem
  }

	@hasOne(() => ProjectItem, (p: ProjectItem) => p.memberList)
  project: ProjectItem

  createItemProps(props:any):any {
    return { ...props }
  }

  @override async fetch() {
    return this.fetchState.get('project/member/list/' + this.project.id)
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}