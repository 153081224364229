import React from 'react'
import { observer } from 'mobx-react'
import stores, { getStore } from 'Stores'
import { CalendarEvent } from 'Stores/Calendar'
import Select from 'theme/Form/Select'
import { RRule, RRuleSet } from 'rrule'
import { format } from 'date-fns'
import EventRecurrenceCustomModal, { EventRecurrenceCustomState } from './EventRecurrenceCustom'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'

const debug = require('debug')('treks:event:recurrence')

type EventRecurrenceProps = {
  focusedEvent: CalendarEvent;
  event: CalendarEvent;
  recurrenceOptions: { 
    label: string|RecurrenceFn; 
    value: string|RecurrenceFn;
  }[]
}

type RecurrenceOptions = { label: string, value: string }[]

type RecurrenceFn = ({ dayNameShort, dayNameLong, monthDayNumber, monthNumber, monthNameShort, monthNameLong }: {
  dayNameShort: string;
  dayNameLong: string;
  monthDayNumber: string;
  monthNumber: string;
  monthNameShort: string;
  monthNameLong: string;
}) => string;

class EventRecurrence extends React.Component<EventRecurrenceProps> {

  static defaultProps = {
    get focusedEvent() {
      return getStore(stores.ActionPlanner).focusedEvent
    },
    recurrenceOptions: [
      {
        label: 'Does not repeat',
        value: '', // FREQ=NONE
      },
      {
        label: 'Daily',
        value: 'RRULE:FREQ=DAILY',
      },
      {
        label: ({ dayNameLong }) => `Weekly on ${dayNameLong}`,
        value: ({ dayNameShort }) => `RRULE:FREQ=WEEKLY;BYDAY=${dayNameShort}`,
      },
      {
        label: ({ dayNameLong }) => `Monthly on the first ${dayNameLong}`,
        value: ({ dayNameShort }) => `RRULE:FREQ=MONTHLY;BYDAY=1${dayNameShort}`,
      },
      {
        label: ({ monthNameLong, monthDayNumber }) => `Annually on ${monthNameLong} ${monthDayNumber}`,
        value: ({ monthNumber, monthDayNumber }) => `RRULE:FREQ=YEARLY;BYMONTH=${monthNumber};BYMONTHDAY=${monthDayNumber}`,
      },
      {
        label: 'Every weekday (Monday to Friday)',
        value: 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ],
  }

  uiState = ArbitraryModel.fromProps({
    modalOpen: false,
  })

  matchRules(rule1: string, rule2: string) {
    if (!rule1 && !rule2) return true
    // assume RRule.toString() returns same sequences
    return (rule1 && rule2) && RRule.fromString(rule1).toString() 
      === RRule.fromString(rule2).toString()
  }

  getOptions() {
    const { startDate } = this.props.event
    const dayNameShort = format(startDate, 'EEEEEE').toUpperCase();
    const dayNameLong = format(startDate, 'EEEE').toUpperCase();
    const monthDayNumber = format(startDate, 'd').toUpperCase();
    const monthNumber = format(startDate, 'M').toUpperCase();
    const monthNameShort = format(startDate, 'MM').toUpperCase();
    const monthNameLong = format(startDate, 'MMMM').toUpperCase();
    const props = {
      dayNameShort, dayNameLong, monthDayNumber, monthNumber, monthNameShort, monthNameLong
    }
    return this.props.recurrenceOptions.map(({ label, value }) => {
      return {
        label: typeof label === 'function' ? label(props) : label,
        value: typeof value === 'function' ? value(props) : value
      }
    })
  }

  createRRuleSet(recurrence: string[]) {
    const ruleset = new RRuleSet()
    Array.from(recurrence)
      .filter(rule => rule.trim())
      .forEach((rule) => {
        const rrule = RRule.fromString(rule);
        ruleset.rrule(rrule)
      });
    return ruleset
  }

  findOptionByValue(options: RecurrenceOptions, value: string) {
    const custom = options[options.length - 1]
    const option =  value === 'custom' 
      ? (
        custom
      ) : (
        options.find((option) => {
          return (
            option.value.trim() === value.trim()
            || (
              option.value.trim() !== 'custom' 
              && this.matchRules(option.value.trim(), value.trim())
            )
          )
        })
      )
    return option || (value && custom)
  }

  handleCustomRecurrence() {
    this.uiState.setState({ modalOpen: true })
  }

  onChangeRecurrence = (option: { label: string; value: string }) => {
    debug('recurrence change', option)
    if (option.value === 'custom') {
      this.handleCustomRecurrence()
    } else {
      this.props.event.setRecurrence([ option.value ])
    }
  }

  onCloseRecurrenceCustomModal = () => {
    this.uiState.setState({ modalOpen: false })
  }

  onCancelRecurrenceCustomModal = () => {
    this.uiState.setState({ modalOpen: false })
  }

  toRRuleWeekDays = (weekDays: string[]) => {
    const matches = {
      'MO': RRule.MO,
      'TU': RRule.TU,
      'WE': RRule.WE,
      'TH': RRule.TH,
      'FR': RRule.FR,
      'SA': RRule.SA,
      'SU': RRule.SU
    }
    return weekDays.map(day => matches[day])
  }

  onSaveRecurrenceCustomModal = (state: EventRecurrenceCustomState) => {
    this.uiState.setState({ modalOpen: false });

    const { repeatInterval, repeatFreq, repeatWeekDays, repeatMonthDays, endDate, endOccurrences } = state;

    let rruleStr = '';

    if (repeatInterval > 0) {
      switch (repeatFreq) {
        case 'day':
          rruleStr = new RRule({
            freq: RRule.DAILY,
            interval: repeatInterval,
            until: endDate || undefined,
            count: endOccurrences || undefined,
          }).toString();
          break;
        case 'week':
          rruleStr = new RRule({
            freq: RRule.WEEKLY,
            interval: repeatInterval,
            byweekday: this.toRRuleWeekDays(repeatWeekDays),
            until: endDate || undefined,
            count: endOccurrences || undefined,
          }).toString();
          break;
        case 'month':
          rruleStr = new RRule({
            freq: RRule.MONTHLY,
            interval: repeatInterval,
            bymonthday: [ ...repeatMonthDays ],
            until: endDate || undefined,
            count: endOccurrences || undefined,
          }).toString();
          break;
        case 'year':
          rruleStr = new RRule({
            freq: RRule.YEARLY,
            interval: repeatInterval,
            bymonthday: [ ...repeatMonthDays ],
            until: endDate || undefined,
            count: endOccurrences || undefined,
          }).toString();
          break;
      }
    }

    console.log('recurrence state', { repeatInterval, repeatFreq, repeatWeekDays, repeatMonthDays, endDate, endOccurrences })
    console.log('recurrence string', rruleStr);

    this.props.event.setRecurrence([ rruleStr ])
    this.props.event.willSave()

  };

  render() {
    const { event } = this.props
    const { modalOpen } = this.uiState
    const recurrence = event.recurrence || []
    const ruleset = this.createRRuleSet(recurrence)
    const recurrenceValue = ruleset.toString()
    const recurrenceText = ruleset.rrules()[0]?.toText()
    const options = this.getOptions()
    const option = this.findOptionByValue(options, recurrenceValue)
    debug('render', { options, option, recurrence, ruleset, recurrenceValue, recurrenceText })
    return (
      <div className="event-recurrence">
        {/* <div style={{ padding: 50 }}>string: {recurrenceValue} text: {recurrenceText}</div> */}
        <Select
          value={option}
          options={options}
          onChange={this.onChangeRecurrence}
        />
        <EventRecurrenceCustomModal
          event={event}
          show={modalOpen}
          onClose={() => this.onCloseRecurrenceCustomModal()}
          onCancel={() => this.onCancelRecurrenceCustomModal()}
          onSave={this.onSaveRecurrenceCustomModal}
        />
      </div>
    )
  }
}

export default observer(EventRecurrence)
