import React from 'react'
import { observer } from 'mobx-react'
import Avatar from 'theme/Avatar'

class MemberAvatar extends React.Component {
  render() {
    const { member, avatarStyles } = this.props
    return (
      <Avatar 
        key={member.id} 
        name={member.name}
        src={member.profileImage} 
        size={24} 
        className="avatar" 
        containerStyle={{ ...avatarStylesDefault, ...avatarStyles}}
      />
    )
  }
}

export default observer(MemberAvatar)

const avatarStylesDefault = {
  marginRight: 5,
}