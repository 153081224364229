import React from 'react'
import { observer } from 'mobx-react'

export const MeetingTitle = observer(({ meeting }) => {

  const onChange = event => {
    meeting.title = event.target.value
    meeting.save()
  }

  return (
    <input 
      onChange={onChange}
      value={meeting.title}
      className={'title title-input input-borderless'}
      placeholder={'Meeting title'}
    />
  )
})
