import React from 'react'
import { observer } from 'mobx-react'
import { SwatchesPicker } from 'react-color'
import { action, makeAutoObservable } from 'mobx'
import Pressable from 'theme/Pressable'
import './ColorPicker.scss'

class ColorPicker extends React.Component {

  pickerContainerRef = null

  static defaultProps = {
    ColorComponent: SwatchesPicker,
    containerStyle: {},
    btnStyle: {},
    className: ''
  }

  store = makeAutoObservable({
    showPicker: false
  })

  componentDidMount() {
    document.body.addEventListener('click', this.onPressOutsidePicker)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onPressOutsidePicker)
  }

  @action onChangeComplete = value => {
    this.props.onChangeComplete(value)
  }

  togglePicker = () => {
    setTimeout(() => {
      this.store.showPicker = !this.store.showPicker
    }, 50)
  }

  hidePicker = () => {
    this.store.showPicker = false
  }

  onPressPickerBtn = event => {
    event.stopPropagation()
    this.togglePicker()
  }

  onPressOutsidePicker = event => {
    if (!this.pickerContainerRef.contains(event.target)) {
      this.hidePicker()
    }
  }

  render() {
    const { field, ColorComponent, className, containerStyle, btnStyle } = this.props
    const styleColor = {
      backgroundColor: field.value,
      ...btnStyle
    }
    return (
      <div className={'color-picker ' + className} style={containerStyle}>
        <Pressable
          className="btn-color"
          style={styleColor}
          onClick={this.onPressPickerBtn}
        ></Pressable>
        <div 
          ref={ref => this.pickerContainerRef = ref}
          className={'color-picker ' + (this.store.showPicker ? 'show' : 'hide')}
        >
          <ColorComponent
            color={field.value || {}}
            onChangeComplete={this.onChangeComplete}
          />
        </div>
      </div>
    )
  }
}

export default observer(ColorPicker)