import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow"
import { observer } from "mobx-react";
import React from "react";

type WeekCalendarAllDayProps = {
  plannerFlow: PlannerFlow;
}

class WeekCalendarAllDay extends React.Component<WeekCalendarAllDayProps> {

  render() {
    return (
      <div className="week-calendar-all-day">
        <div className="all-day-label">All Day</div>
        <div className="all-day-list">

        </div>
      </div>
    )
  }
}

export default observer(WeekCalendarAllDay)