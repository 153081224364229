import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { reaction } from 'mobx';
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import { uid } from 'Stores/utils';
import Model from 'Stores/Model';

const debug = require('debug')('treks:task:duration')

class TaskDuration extends React.Component {

  inputRef = null
  reaction = null

  static defaultProps = {
    keyDownEnabled: true,
    isEditable: true,
    disabled: false
  }

  static propTypes = {
    list: PropTypes.instanceOf(Model)
  }

  store = ArbitraryModel.fromProps({
    isFocused: false,
    inputValue: 0
  })

  componentDidMount() {
    this.store.setState({
      inputValue: this.props.item.duration
    })
    this.reaction = reaction(
      () => this.props.item.duration, 
      duration => this.store.setState({
        inputValue: duration
      })
    )
  }

  componentWillUnmount() {
    if (this.reaction && this.reaction.dispose) {
      this.reaction.dispose()
    }
  }

  onChange = event => {
    debug('duration changed', event)
    if (!this.props.isEditable) return
    this.store.setState({
      inputValue: event.target.value
    })
  }

  onBlur = event => {
    const { item, onBlur, isEditable } = this.props
    if (!isEditable) return
    const inputDuration = parseFloat(this.store.inputValue)
    const minDuration = Math.max(inputDuration, item.minDuration)
    item.setDuration(minDuration)
    item.save()
    this.store.setState({ isFocused: false, inputValue: item.duration })
    onBlur && onBlur(event, item)
    debug('focus off', { item, inputDuration, minDuration })
  }

  onFocus = event => {
    const { item, list, onFocus } = this.props
    const { nativeEvent } = event
    debug('focus on', { item, list, onFocus })
    onFocus && onFocus(event, item)
    setTimeout(() => nativeEvent.target.select(), 50)
    this.store.setState({ isFocused: true })
  }

  /**
   * @todo move to TaskItem
   */
  onKeyDown = event => {
    const { item, keyDownEnabled, isEditable } = this.props
    debug('keyDown', item, event.key)
    if (!keyDownEnabled || !isEditable) return
    if (event.key === 'Enter') {
      event.stopPropagation()
      if (event.target.value) this.addEmptyItem()
    }
    if (event.key === 'Backspace' && !item.title) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
    if (event.key === 'Tab') {
      event.preventDefault()
      event.stopPropagation()
      this.refHidden && this.refHidden.focus()
    }
  }

  addEmptyItem() {
    const { list } = this.props
    const task = list.addEmptyItemAfterFocusedTask()
    const index = list.getItemIndex(task)
    debug('addEmptyItem', { task, index })
    task.setFocusOnTitle()
  }

  createEmptyItem() {
    const { list } = this.props
    const { focusedTimespan } = list
    return {
      id: uid(),
      title: '',
      duration: 15,
      onPlanner: true,
      list,
      timespan: focusedTimespan
    }
  }

  removeItem() {
    this.props.list.removeItem(this.props.item)
    this.props.list.save()
  }

  focusOnPrevOrNextItemTitle() {
    const prevItem = this.props.list.getPrevItem(this.props.item)
    const nextItem = this.props.list.getNextItem(this.props.item)
    debug('focus on prev or next ', { prevItem, nextItem })
    if (prevItem) {
      prevItem.setFocusOnTitle()
    } else if (nextItem) {
      nextItem.setFocusOnTitle()
    }
  }

  onRefHidden = ref => {
    this.refHidden = ref
  }

  render() {
    const { item, list, disabled, className } = this.props
    const { done, durationFormatted, resizeDurationFormatted, isResizing } = item
    const { isFocused, inputValue } = this.store
    debug('render', { item, list })
    const value = isResizing 
      ? resizeDurationFormatted 
      : (isFocused ? inputValue : durationFormatted)
    const classNames = [ done && 'done',  className ]
      .filter(val => val)
      .join(' ')
    return (
      <>
        <input
        ref={ref => this.inputRef = ref}
        className={"duration duration-input input-borderless " + classNames}
        value={ value } 
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        disabled={disabled ? 'disabled' : ''}
      />
      <input ref={this.onRefHidden} style={{
        margin: 0,
        padding: 0,
        opacity: 0,
        width: 0,
        overflow: 'hidden'
      }} />
      </>
    )
  }
}

export default observer(TaskDuration)