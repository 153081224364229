import React from 'react'
import Pressable from '../Pressable';
import { IconThumbOutline, IconThumbSolid } from 'theme/Icons/IconThumb';
import './Likes.scss'

export default class Likes extends React.Component {

  static defaultProps = {
    count: 0,
    iconLike: <IconThumbOutline />,
    iconLiked: <IconThumbSolid />,
    className: '',
    onClick: () => {}
  }

  render() {
    const { onClick, count, liked, iconLike, iconLiked } = this.props

    return (
      <div className={'row-flex likes ' + (liked ? 'liked' : '') }>
        <div
          className="like-count"
          style={{ color: liked && '#447cb7' }}
        >
          {count ? count : ''}
        </div>
        <Pressable
          style={{
            width: 20,
            height: 20,
            color: liked && '#447cb7',
            display: 'flex'
          }} 
          onClick={onClick}
        >
          {
            liked ? iconLiked  : iconLike
          }
        </Pressable>
      </div>
    )
  }
}