import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Button from '../../theme/Button'
import Icon from '../../theme/Icon'
import CalendarSettings from '../../Components/Calendar/Settings'

type CalendarSettingsModalProps = {
  handleClose: () => void;
  show?: boolean;
  title?: boolean;
  icon?: boolean;
}

class CalendarSettingsModal extends React.Component<CalendarSettingsModalProps> {

  static defaultProps = {
    title: 'CALENDAR SETTINGS',
    icon: 'settings',
  }

  render() {
    const { show, title, icon, handleClose } = this.props
    return (
      <Modal 
          show={show} 
          onHide={handleClose}
          size="lg"
          centered
          dialogClassName="calendar-settings-modal modal-size-80"
        >
        <Modal.Header>
            <Modal.Title>
              <Icon icon={icon} size="xs" color={'#657e82'} className="modal-title-icon" />
              {title}
            </Modal.Title>
            <Button variant="outline" onClick={handleClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <CalendarSettings />
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(CalendarSettingsModal)