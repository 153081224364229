import React from 'react'
import { observer } from 'mobx-react'
import './GoalStats.css'

const debug = require('debug')('treks:goal:stats')

const formatFixed = fixed => {
  return Math.round(fixed) - fixed ? fixed : Math.round(fixed)
}

const Stat = ({ value, suffix, label, status }) => {
  return (
    <div className="goal-stat">
      <div className="goal-stat-amount">
        <span className={'goal-stat-value goal-stat-status-' + status}>
          {value}
        </span>
        {
          suffix && <span className="stat-suffix">{suffix}</span>
        }
      </div>
      <label>{label}</label>
    </div>
  )
}

class GoalStats extends React.Component {

  inputRef = null

  get stats() {
    const { cumulativeStats:stats } = this.props.goal
    return [
      {
        value: stats.members.length,
        label: 'members',
      },
      {
        value: stats.tasks.length,
        label: 'tasks',
        status: ''
      },
      {
        value: stats.blockedTasks.length,
        label: 'blockers',
        status: 'warn'
      },
      {
        value: stats.overdueTasks.length,
        label: 'overdue tasks',
        status: 'warn'
      },
      {
        value: formatFixed(stats.totalHours),
        label: 'hours total',
      },
      {
        value: formatFixed(stats.totalHoursDone),
        label: 'hours complete',
      },
      {
        value: formatFixed(stats.completePercent) + '%',
        label: 'complete',
        status: 'success'
      },
      {
        value: formatFixed(stats.velocity),
        suffix: 'hrs/day',
        label: 'velocity (3 day)',
      }
    ]
  }

  constructor(props) {
    super()
    const { goal } = props
    debug('component created', goal)
  }

  render() {
    const { goal } = this.props
    const { stats } = this
    debug('render', goal)
    return (
      <div className="goal-detail-stats">
        {
          stats.map(stat => (<Stat key={stat.label} {...stat} />))
        }
      </div>
    )
  }
}

export default observer(GoalStats)
