export const lists = [
  {
    name: 'Event',
    items: [],
    group: 'event'
  },
  {
    name: 'Personal',
    items: [],
    group: {
      name: 'timespan',
      put: ['timespan', 'event', 'subtask']
    }
  },
  {
    name: 'Work',
    items: [],
    group: {
      name: 'timespan',
      put: ['timespan', 'event', 'subtask']
    }
  },
  {
    name: 'Afternoon Personal',
    items: [],
    group: {
      name: 'timespan',
      put: ['timespan', 'event', 'subtask']
    }
  }
]
export default lists