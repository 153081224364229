import React from 'react';
import { observer } from 'mobx-react';
import {TimePicker} from './HabitInstance'

const debug = require('debug')('treks:habit:budget');

class HabitInstanceEdit extends React.Component {
    inputRef = null;

    constructor(props) {
        super(props);
        const { instance } = props;
        debug('component created', instance);
        this.state = {
            showPlan: false,
        };
    }

    onInputRef = (ref) => {
        this.inputRef = ref;
    };

    onTitleChange = (event) => {
        debug('title changed', event);
        this.props.instance.title = event.target.value;
        this.props.instance.save();
    };

    onDurationChange = (event) => {
        debug('duration changed', event);
        this.props.instance.duration = event.target.value;
        this.props.instance.save();
    };

    onTimeChange = (option) => {
        console.log('time change', option.value);
        this.props.instance.time = parseInt(option.value);
        this.props.instance.save();
    };

    openPlan = () => {
        console.log('opening');
        this.setState({
            showPlan: true,
        });
    };

    render() {
        const { instance } = this.props;
        debug('render', instance);
        return (
            <div className="habit-instance-edit">
                <div className="header">
                    <input
                        ref={this.onInputRef}
                        className={'input-borderless title'}
                        value={instance.title}
                        onChange={this.onTitleChange}
                    />
                    <div className="timepicker">
                        <TimePicker val={parseInt(instance.time)} onChange={this.onTimeChange} />
                    </div>
                    <div className="duration">
                        Duration:
                        <input
                            ref={this.onInputRef}
                            style={{ textAlign: 'right', marginRight: 3, flexShrink: 1 }}
                            className={'input-borderless'}
                            value={instance.duration}
                            onChange={this.onDurationChange}
                        />
                        <span>minutes</span>
                    </div>
                </div>

                <div className="edit">
                    Plan
                </div>
            </div>
        );
    }
}

export default observer(HabitInstanceEdit)

