import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'

class ProjectSettingFieldCheckbox extends React.Component {

  onFieldChange = () => {
    const { onChange, field } = this.props
    onChange(!field.value, field)
  }

  render() {
    const { name, value } = this.props.field
    return (
      <Form.Check 
        type="checkbox"
        name={name}
        checked={value || false}
        onChange={this.onFieldChange}
      />
    )
  }
}

export default observer(ProjectSettingFieldCheckbox)