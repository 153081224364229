import {
  FormControlDropdown,
  FormControlCountryDropdown,
  FormControlCardNumber,
  FormControlCardMeta,
} from 'theme/Form'

const fields = [
  {
    name: 'number',
    label: 'Credit Card Number',
    placeholder: 'XXXX XXXX XXXX XXXX',
    Component: FormControlCardNumber,
    value: null,
    required: true,
  },
  {
    name: 'card_meta',
    label: 'Expiration',
    placeholder: {
      month: 'MM',
      year: 'YYYY',
      cvv: 'XXX'
    },
    Component: FormControlCardMeta,
    value: {
      month: '',
      year: '',
      cvv: ''
    },
  },
  {
    name: 'first_name',
    label: 'First Name',
    placeholder: 'First Name',
    value: null,
    required: true,
  },
  {
    name: 'last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    value: null,
    required: true,
  },
  {
    name: 'address1',
    label: 'Billing Address',
    placeholder: 'Street Address',
    value: null,
    required: true,
  },
  {
    name: 'address2',
    label: 'Suite/Appt',
    placeholder: 'Suite / Appt #',
    value: null,
  },
  {
    name: 'city',
    label: 'City',
    placeholder: 'City',
    value: null,
    required: true,
  },
  {
    name: 'state',
    label: 'State',
    placeholder: 'Select State',
    value: null,
    Component: FormControlDropdown,
    items: []
  },
  {
    name: 'zip',
    label: 'Zip/Postal',
    placeholder: 'Zip / Postal Code',
    value: null,
  },
  {
    name: 'country',
    label: 'Country',
    placeholder: 'Select Country',
    value: null,
    Component: FormControlCountryDropdown,
  },
  {
    name: 'phone',
    label: 'Phone',
    placeholder: 'Phone Number (Optional)',
    value: null,
  },
]

export default fields