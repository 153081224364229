import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../../theme/Icon'
import { MeetingTitle } from './ProjectMeetingTitle'
import ProjectMeetingRecurring from './ProjectMeetingRecurring'
import ProjectMeetingDate from './ProjectMeetingDate'

class ProjectMeetingItem extends React.Component {

  render() {
    const { meeting } = this.props
    return (
      <div className="meeting list-row row-flex">
        <div className="list-col icon">
          <Icon icon="meeting" />
        </div>
        <div className="list-col title">
          <MeetingTitle meeting={meeting} />
        </div>
        <div className="list-col">
          <ProjectMeetingRecurring meeting={meeting} />
        </div>
        <div className="list-col">
          <ProjectMeetingDate meeting={meeting} />
        </div>
      </div>
    )
  }
}

export default observer(ProjectMeetingItem)
