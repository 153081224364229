import React from 'react'
import { observer } from 'mobx-react'
import Nav from 'react-bootstrap/Nav'

class Tab extends React.Component {

  onClickTab = ({ event }) => {
    event.preventDefault()
  }

  render() {
    const { label, key } = this.props.tab
    return (
      <Nav.Item>
        <Nav.Link 
          eventKey={key}
        >
          <span>{label}</span>
        </Nav.Link>
      </Nav.Item>
    )
  }
}

export default observer(Tab)
