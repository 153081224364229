import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalMetrics.scss'
import { observable } from 'mobx'
import MetricList from 'Stores/Metric/MetricList'
import { getStore, models } from 'Stores'
import GoalMetricsResults from './Results';
import Scrollable from 'theme/Scrollable';
import { getAppNotifications } from 'AppNotifications'
import { uid } from 'Stores/utils';

const debug = require('debug')('treks:goal:metrics')

class GoalMetrics extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static defaultProps = {
    metrics: MetricList.create(),
    get account() {
      return getStore(models.Session).Account
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.setScrollDownBtnPos)
    this.setScrollDownBtnPos()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setScrollDownBtnPos)
  }

  setScrollDownBtnPos() {
    const btn = document.querySelector('.btn-scroll-down')
    if (btn) btn.style.marginBottom = 120 - window.pageYOffset
  }

  addMetric = ({ title, text }) => {
    if (!title || !text) {
      getAppNotifications().warn('The metric title and text are required')
      return false
    }
    const item = {
      user: this.props.account,
      id: uid(),
      title,
      metric: text
    }
    debug('adding metric', item)
    this.props.metrics.addItem(item)
    return true
  }

  render() {
    const { goal, metrics } = this.props
    debug('render', { goal, metrics })
    return (
      <div className="goal-metrics">
        <header className="row tab-header">
          <Icon icon="metric" size={15} />
          <div className="tab-title">Metrics</div>
        </header>
        <Scrollable
          container={document.querySelector('.container-scrollable')}
          className="goal-metrics-list"
          paneClassName=".metrics-graph-container"
        >
          <GoalMetricsResults />
        </Scrollable>
      </div>
    )
  }
}

export default observer(GoalMetrics)
