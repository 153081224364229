export const defMapKey = Symbol('context-map')

/**
 * Saves a map to a context
 * 
 * @example getMapFor(model) === getMapFor(model)
 */
export function getMapFor(context: object, mapKey: symbol = defMapKey): Map<any, any> {
  if (!context[mapKey]) {
    Object.defineProperty(context, mapKey, {
      value: new WeakMap<object, any>(),
      enumerable: false
    })
  }
  return context[mapKey]
}

/**
 * saves an entry to a context and key with an initializer
 * 
 * @example getEntryFor(model, 'event', () => new Event()) === getEntryFor(model, 'event')
 */
export function getEntryFor(context: any, name: any, initializer?: Function, mapKey?: symbol) {
  const map = getMapFor(context, mapKey)
  let value = map.get(name)
  if (!value && initializer) {
    value = initializer.apply(context, [name])
  }
  return value
}