/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from 'prop-types';
import Select from '../../theme/Form/Select';
import React from 'react';
import { observer } from 'mobx-react';
import Icon from 'theme/Icon';
import { importGridItem } from 'Stores/Dashboard/GridItem';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { importWidgetItem } from 'Stores/Dashboard/WidgetItem';
import Widget from './Widget';
import AddWidget from './AddWidget';
import { getStore, models } from 'Stores';
import DashboardDate from './DashboardDate';

const debug = require('debug')('treks:Components:DashboardItem')

const WIDGET_MARGIN = 2;

class DashboardItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmRemove: false,
            confirmChange: null,
            confirmAdd: null,
        };
    }

    static propTypes = {
        entry: PropTypes.objectOf(() => importGridItem()).isRequired,
    };

    static defaultProps = {
        get projectList() {
            return getStore(models.ProjectList);
        },
        get taskList() {
            return getStore(models.ActionPlanner);
        },
        get goalList() {
            return getStore(models.GoalList);
        },
        get gridList() {
            return getStore(models.GridList);
        },
        get pfaList() {
            return {items: [], fetched: debug};
        },
    };

    componentDidMount() {
        this.fetchDataSources()
    }

    fetchDataSources = async () =>{
        const { entry } = this.props;
        const { dataType } = entry;
        await this.props[dataType + 'List'].fetched()
    }

    onChange = async (option) => {
        const { entry } = this.props;
        const { dataType } = entry;
        const { value } = option || { value: null };
        const source = value && this.props[dataType + 'List'].getItemByIdString(value);
        debug('onChange GridItem',  option, source)
        entry.setDataSource(source);
    };

    removeMe = () => {
        debug('removing');
        const { entry, gridList } = this.props;
        entry.deletedDate = new Date();
        entry.save();

        gridList.removeItem(entry);
        this.props.updatedWidgets();
    };

    async changedWidgetLayout(layout, uid) {
        const { entry, updatedWidgets } = this.props;
        const { widgets, h, w } = entry;

        debug('commitWidgets', uid, layout, w, h);
        // let gridItem = entry;
        let widgetArr = [];
        let update = true;
        layout.forEach((item) => {
            if (item.y + item.h > h) {
                debug('item doesnt fit y', item);
                update = false;
            }

            if (item.x + item.w > w * entry.wScale) {
                debug('item doesnt fit x', item);
                update = false;
            }
        });
        if (!update) {
            console.error('NOT UPDATING - revert to ', [...widgets]);
            let map = {};
            widgets.forEach((w) => (map[w.i] = w));
            //revert to positions on original layout
            layout.forEach((i) => {
                let orig = map[i.i];
                if (!orig) return;
                i.x = orig.x;
                i.y = orig.y;
                i.h = orig.h;
                i.w = orig.w;
            });
        }
        layout.forEach((item, idx) => {
            let modelItem = importWidgetItem().fromUid(item.i);

            // update positions on models
            modelItem.i = item.i;
            modelItem.y = item.y;
            modelItem.x = item.x;
            modelItem.h = item.h;
            modelItem.w = item.w;
            widgetArr.push(modelItem);
        });

        entry.setWidgets([...widgetArr]);
        entry.save();
        if (entry.widgets.length > 0) updatedWidgets();

        return [...widgetArr];
    }

    changeDataType(type) {
        const { entry } = this.props;
        const { confirmChange } = this.state;

        if (confirmChange) {
            entry.changeDataType(confirmChange);

            entry.widgets = [];
            entry.save();
            return;
        }

        let hasWidgets = false;
        entry.widgets.forEach((w) => {
            if (w.widgetType) hasWidgets = true;
        });

        if (hasWidgets) this.setState({ confirmChange: type });
        else {
            entry.changeDataType(type);
            entry.save();
        }


        this.fetchDataSources()
    }

    render() {
        const { id, entry, mode } = this.props;
        const { dataType, dataSource, widgets, uid, h } = entry;
        const options = this.props[dataType + 'List'].items
            ? this.props[dataType + 'List'].items.map(({ title, id }) => ({
                    label: title + ' ' + id.toString(),
                    value: id.toString(),
              }))
            : [];

        debug('logging dataSource', dataSource);
        return (
            <>
                <header className={'grid-item-header grid-item-header-' + mode}>
                    {mode === 'edit' ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Modal
                                    show={this.state.confirmAdd !== null}
                                    size="lg"
                                    centered
                                    dialogClassName="modal-size-80"
                                    onHide={() => {
                                        this.setState({ confirmAdd: null });
                                    }}
                                >
                                    <AddWidget
                                        entry={entry}
                                        type={this.state.confirmAdd}
                                        availableWidgets={this.props.availableWidgets}
                                        add={(w) => {
                                            entry.addWidget(w);
                                            this.setState({
                                                confirmAdd: null,
                                            });
                                            this.props.updatedWidgets();
                                        }}
                                        close={() => this.setState({ confirmAdd: null })}
                                    />
                                </Modal>
                                <Modal
                                    show={this.state.confirmChange !== null}
                                    size="lg"
                                    centered
                                    dialogClassName="modal-size-80"
                                    onHide={() => {
                                        this.setState({ confirmChange: null });
                                    }}
                                >
                                    <Modal.Header>
                                        <Modal.Title>
                                            Change the data type to {this.state.confirmChange}?
                                        </Modal.Title>{' '}
                                        <Button
                                            variant="outline"
                                            onMouseDown={() => {
                                                this.setState({ confirmChange: null });
                                            }}
                                            className="modal-close-button"
                                        >
                                            <Icon
                                                set="fa"
                                                icon="times"
                                                size="xs"
                                                color={'#808284'}
                                                className="modal-close-icon"
                                            />
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>This will remove all widgets in the container.</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => {
                                                this.changeDataType();
                                                this.setState({ confirmChange: null });
                                            }}
                                        >
                                            Change
                                        </Button>
                                        <Button
                                            variant="outline"
                                            onClick={() => {
                                                this.setState({ confirmChange: null });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={this.state.confirmRemove}
                                    size="lg"
                                    centered
                                    dialogClassName="modal-size-80"
                                    onHide={() => {
                                        this.setState({ confirmRemove: false });
                                    }}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Remove this dashboard container?</Modal.Title>{' '}
                                        <Button
                                            variant="outline"
                                            onMouseDown={() => {
                                                this.setState({ confirmRemove: false });
                                            }}
                                            className="modal-close-button"
                                        >
                                            <Icon
                                                set="fa"
                                                icon="times"
                                                size="xs"
                                                color={'#808284'}
                                                className="modal-close-icon"
                                            />
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>This will remove all widgets in the container.</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => {
                                                this.removeMe();
                                                this.setState({ confirmRemove: false });
                                            }}
                                        >
                                            Remove
                                        </Button>
                                        <Button
                                            variant="outline"
                                            onClick={() => {
                                                this.setState({ confirmRemove: false });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <div className="grid-edit-drag">
                                    <Icon set="fa" icon="grip-vertical" size="xs" color="grey" />
                                </div>
                                <div className="selector-container">
                                    <Select
                                        className="type-selector"
                                        onChange={this.onChange}
                                        value={dataSource ? dataSource.id : null}
                                        options={[...options]}
                                        isDisabled={!dataType}
                                    />
                                </div>
                                {dataSource ? dataSource.id : null}

                                <div className="type-group">
                                    <div
                                        className={
                                            'icon-project group-icon' + (dataType === 'project' ? ' selected' : '')
                                        }
                                        onClick={() => {
                                            this.changeDataType('project');
                                        }}
                                    ></div>
                                    <div
                                        className={'icon-goal group-icon' + (dataType === 'goal' ? ' selected' : '')}
                                        onClick={() => {
                                            this.changeDataType('goal');
                                        }}
                                    ></div>
                                    <div
                                        className={'icon-task group-icon' + (dataType === 'task' ? ' selected' : '')}
                                        onClick={() => {
                                            this.changeDataType('task');
                                        }}
                                    ></div>
                                    <div
                                        className={
                                            'icon-chart-bar group-icon' + (dataType === 'pfa' ? ' selected' : '')
                                        }
                                        onClick={() => {
                                            this.changeDataType('pfa');
                                        }}
                                    ></div>
                                </div>

                                <div className="remove" onClick={() => this.setState({ confirmRemove: true })}>
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="times"
                                        class="svg-inline--fa fa-times fa-w-11 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 352 512"
                                        set="fa"
                                        name="times"
                                        color="#808284"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div >
                                    <div
                                        className="grid-edit-add"
                                        onClick={() => this.setState({ confirmAdd: dataType })}
                                    >
                                        <Icon style={{marginLeft: 5}} set="fa" icon="plus" size="xs" color="grey" />
                                        <div style={{marginLeft: 5}}>Add Widget</div>
                                    </div>
                                </div>
                                <div className="datepicker">
                                    <DashboardDate entry={entry} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {' '}
                            <div className="title">
                                {dataSource && dataSource.title ? (
                                    <>
                                        {' '}
                                        <div className={'icon-' + dataType + ' group-icon'}></div>{' '}
                                        {dataSource.title}{' '}
                                    </>
                                ) : (
                                    id
                                )}
                            </div>
                            <div className="datepicker" style={{ flexGrow: 1 }}>
                                <DashboardDate entry={entry} />
                            </div>
                        </div>
                    )}
                </header>
                <div className={'grid-item-body grid-item-body-' + mode}>
                    <SizeMe monitorHeight>
                        {({ size }) => (
                            <Responsive
                                className={'widget-grid widget-grid-' + mode}
                                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{
                                    lg: entry.wScale * entry.w,
                                    md: entry.wScale * entry.w,
                                    sm: entry.wScale * entry.w,
                                    xs: entry.wScale * entry.w,
                                    xxs: entry.wScale * entry.w,
                                }}
                                compactType={'horizontal'}
                                onDragStop={(layout) => this.changedWidgetLayout(layout, uid)}
                                onResizeStop={(layout) => this.changedWidgetLayout(layout, uid)}
                                layouts={{
                                    lg: widgets.map((item) => ({
                                        i: item.i,
                                        x: item.x,
                                        y: item.y,
                                        h: item.h,
                                        w: item.w,
                                        maxH: item.maxH,
                                    })),
                                }}
                                isDraggable={mode === 'edit'}
                                isResizable={mode === 'edit'}
                                margin={[WIDGET_MARGIN, WIDGET_MARGIN]}
                                width={size.width}
                                rowHeight={size.height / h - WIDGET_MARGIN * 2}
                            >
                                {widgets &&
                                    widgets.map &&
                                    widgets.map((i, idx) => {
                                        let item = i;
                                        return (
                                            <div
                                                key={i.uid}
                                                className={'widget-item widget-item-' + mode}
                                                data-grid={{
                                                    i: item.i,
                                                    x: item.x,
                                                    y: item.y,
                                                    h: item.h,
                                                    w: item.w,
                                                    maxH: item.maxH,
                                                }}
                                            >
                                                <Widget
                                                    mode={mode}
                                                    removeWidget={() => {
                                                        entry.removeWidget(idx);
                                                    }}
                                                    id={i.uid}
                                                    widget={item}
                                                    dataSource={dataSource}
                                                    data={item.data}
                                                    entry={entry}
                                                />
                                            </div>
                                        );
                                    })}
                            </Responsive>
                        )}
                    </SizeMe>
                </div>
            </>
        );
    }
}

export default observer(DashboardItem)
