import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:component:account')


class Account extends React.Component {

  render() {
    debug('render', this.props)
    return (
      <div>Account</div>
    )
  }
}

export default observer(Account)