import { factory } from './utils'
import Model from './Model/Model'
import List from './Lists/List'
import OrderedList from './Lists/OrderedList'
import ActionPlanner from './ActionPlanner'
import { ActionPlannerList } from './ActionPlanner/ActionPlannerList'
import Fetch from './Fetch'
import { importSession } from './Session'
import { importAccount, AccountRegister, BillingInfo, DisplaySettings } from './Account'
import { importUser, UserList } from './User'
import { importMainMenu } from './MainMenu'
import Modal from './Screen/Modal'
import { Form, Field } from './Form'
import { NotificationList, NotificationItem } from './Notification'
import { ApiRequest, LocalStorageRequest } from './Service'
import { 
  DayList, TimeSpanList, TimeSpanItem, TimeSpanBucketItem, TimeSpanBucketList
} from './ActionPlanner/TimeSpan'
import { TimeSpanListDaySettings } from './ActionPlanner/TimeSpan/Settings'
import { ActivityTypes, ActivityType, ActivityList, ActivityItem } from './Activity'
import { ProjectList, ProjectItem, ProjectSettings } from './Project'
import { GoalSubGoalList, GoalList, GoalItem, GoalSettings } from './Goal'
import { CalendarAccount, CalendarAccountList, Calendar, CalendarEvent } from './Calendar'
import { InboxList, importInboxItem } from './Inbox'
import {importDashboard, DashboardList, importGridItem, GridList, importWidgetItem} from './Dashboard'
import { TaskItem, TaskList, TaskListOrphaned } from './Task'
import { CategoryList, CategoryItem } from './Category'
import ModelStore from './Model/ModelStore'
import CommentItem from './Comment/CommentItem'
import CommentList from './Comment/CommentList'
import { TaskCommentItem, TaskCommentList } from './Task/Comment'
import { SubTaskListList, SubTask, SubTaskList } from './Task/SubTask'
import { importMainPaneGroup } from './MainPaneGroup'
import {HabitList, HabitItem, InstanceItem, InstanceList, importPlanItem, PlanList} from './Habit'
import ProjectTaskList from './Project/PojectTaskList'
import { ModelPropsI } from './Model/Type/Model'
import { TaskLabel } from './Task/Label/TaskLabel'
import { LabelTaskList } from './Task/Label/LabelTaskList'
import { TaskLabelList } from './Task/Label/TaskLabelList'
import { LabelGroupList } from './Task/Label/LabelGroupList'
import { LabelGroup } from './Task/Label/LabelGroup'
import { TaskSubTasksList } from './Task/TaskSubTasksList'
import FocusedItem from './ActionPlanner/FocusedItem'
import { TaskActivityItem, TaskActivityList, TaskActivityTypes, activityTypeList } from './Task/Activity'

export const models = {
  Model, List, OrderedList,
  ApiRequest,
  LocalStorageRequest,
  ActionPlanner, ActionPlannerList, TaskItem, TaskList, TaskListOrphaned,
  Fetch,
  get Session() {
    return importSession()
  },
  get User() {
    return importUser()
  },
  UserList,
  get MainMenu() {
    return importMainMenu()
  },
  Modal,
  get Account() {
    return importAccount()
  }, 
  AccountRegister, BillingInfo, DisplaySettings,
  Form, Field,
  NotificationList, 
  NotificationItem,
  TimeSpanList, TimeSpanItem, TimeSpanBucketItem, TimeSpanBucketList,
  TimeSpanListDaySettings,
  DayList,
  ProjectList, ProjectItem, ProjectSettings,
  GoalSubGoalList, GoalList, GoalItem, GoalSettings,
  CalendarAccount, CalendarAccountList, Calendar, CalendarEvent,
  CategoryList,
  CategoryItem,
  get CommentItem() {
    return CommentItem
  },
  CommentList,
  get TaskCommentItem() {
    return TaskCommentItem
  },
  TaskCommentList,
  ProjectTaskList,
  SubTaskListList, 
  get SubTask() {
    return SubTask()
  }, 
  get SubTaskList() {
    return SubTaskList()
  },
  InboxList,
  importInboxItem, // compat
  get InboxItem() {
    return importInboxItem()
  },
  get MainPaneGroup() {
    return importMainPaneGroup()
  },
	get Dashboard() {
		return importDashboard()
	}, DashboardList, GridList, 
	get GridItem() {
		return importGridItem()
	},
	get WidgetItem() {
		return importWidgetItem()
	},
	HabitItem, HabitList, InstanceItem, InstanceList, importPlanItem, PlanList,
  TaskLabel, LabelTaskList, TaskLabelList,
  LabelGroupList, LabelGroup,
  TaskSubTasksList,
  FocusedItem,
  ActivityTypes, ActivityType, ActivityList, ActivityItem,
  TaskActivityList, TaskActivityItem, TaskActivityTypes, activityTypeList
}

const app = {
  get actionPlanner() {
    return getStore(() => models.ActionPlanner)
  },
  get actionPlannerList() {
    return getStore(() => models.ActionPlannerList)
  },
  get focusedTask() {
    return getStore(() => models.ActionPlanner).focusedItem.item
  },
  get focusedTimespan() {
    return getStore(() => models.ActionPlanner).focusedTimespan
  },
  get events() {
    return getStore(() => models.ActionPlanner).events
  },
  get tasks() {
    return getStore(() => models.ActionPlanner).items
  },
  get apiRequest() {
    return getStore(() => models.ApiRequest)
  },
  get session() {
    return getStore(importSession()) 
  },
  get mainPaneGroup() {
    return getStore(importMainPaneGroup())
  },
  get projectList() {
    return getStore(ProjectList)
  }
}

export const stores = {
  models,
  app,
  ...models // @todo remove
} as any

export default stores

export function getStore<M extends typeof Model>(typeFn: M | (() => M), props?: ModelPropsI<InstanceType<M>>): InstanceType<M> {
  const ModelType = (typeFn.prototype instanceof Model ? typeFn : (typeFn as () => M)()) as M
  const storeKey = '__global.store__'
  if (props) return getModel(ModelType, props)
  if (!ModelType.hasOwnProperty(storeKey)) {
    ModelType[storeKey] = ModelType.create()
  }
  return ModelType[storeKey]
}

export function getModel(type, props?) {
  return factory(ModelStore).fromProps(type, props)
}

export function getModels(type) {
  return factory(ModelStore).em.getList(type)
}

export function findModel(type, props) {
  return factory(ModelStore).findModel(type, props)
}

export function findModelByUid(uid) {
  return factory(ModelStore).findModelByUid(uid)
}

export { ModelStore }

// debugging & testing
Object.assign(global, {
  app,
  stores,
  models,
  getStore,
  getModel,
  getModels,
  factory,
  ModelStore,
  ApiRequest
})

Object.keys(app).forEach(key => Object.defineProperty(global, key, {
  get() {
    return app[key]
  }
}))