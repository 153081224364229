import React from 'react'
import PropTypes from 'prop-types';
import { observer } from 'mobx-react'
import { importGridItem } from 'Stores/Dashboard/GridItem';
import { importWidgetItem } from 'Stores/Dashboard/WidgetItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Icon from 'theme/FontAwesomeIcon/FontAwesomeIcon';
import loadable from '@loadable/component';

class Widget extends React.Component {
  static propTypes = {
    entry: PropTypes.objectOf(() => importGridItem()).isRequired,
    widget: PropTypes.objectOf(() => importWidgetItem()).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmRemove: false,
    };

    this.RealComponent = () => {
      return <></>;
    };
  }

  componentDidMount() {
    const { widget } = this.props;
    console.log('dynamically importing', widget.widgetType);
    try {
      this.RealComponent = loadable(() => import(`./Widgets/${widget.widgetType}.js`));
      this.forceUpdate();
    } catch (err) {
      console.log('couldnt mount widget', err);
    }
  }

  render() {
    const { mode } = this.props;
    const { RealComponent } = this;

    return (
      <>
        <div className="widget">
          <Modal
            show={this.state.confirmRemove}
            size="lg"
            centered
            dialogClassName="modal-size-80"
            onHide={() => {
              this.setState({ confirmRemove: false });
            }}
          >
            <Modal.Header>
              <Modal.Title>Remove this widget?</Modal.Title>{' '}
              <Button
                variant="outline"
                onMouseDown={() => {
                  this.setState({ confirmRemove: false });
                }}
                className="modal-close-button"
              >
                <Icon
                  set="fa"
                  icon="times"
                  size="xs"
                  color={'#808284'}
                  className="modal-close-icon"
                />
              </Button>
            </Modal.Header>
            <Modal.Body>You can add it back later.</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.removeWidget();
                  this.setState({ confirmRemove: false });
                }}
              >
                Remove
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  this.setState({ confirmRemove: false });
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {mode === 'edit' ? (
            <div className="remove" onClick={() => this.setState({ confirmRemove: true })}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-11 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                set="fa"
                name="times"
                color="#808284"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </div>
          ) : (
            <></>
          )}
          <RealComponent {...this.props} />
          {/* {this.widgetDisplay()} */}
        </div>
      </>
    );
  }
}

export default observer(Widget)
