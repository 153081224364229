import React from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'

const debug = require('debug')('treks:form:control:input')

class FormControlInput extends React.Component {

  componentDidMount() {
    const domRef = ReactDOM.findDOMNode(this.ref)
    debug('mount', this.ref, domRef)
    if (domRef && this.props.focus) {
      domRef.focus()
    }
  }

  render() {
    let { onChange, name, value, autoComplete, type, placeholder, disabled, onKeyPress } = this.props
    return (
      <>
        {
          (['off', 'new-password']).includes(autoComplete) && (
            <input 
              type={type}
              style={{ display: 'none' }}
            />
          )
        }
        <Form.Control 
          ref={ref => this.ref = ref}
          {...{ name, value, autoComplete, type, placeholder, disabled, onKeyPress }}
          value={value || ''}
          onChange={event => onChange && onChange(event.target.value)}
        />
    </>
    )
  }
}

export default observer(FormControlInput)