import { Item } from "Stores/Lists";
import { action, computed, observable } from "mobx";

export const reminderMethods = ['email', 'popup']
export type ReminderMethod =  'email' | 'popup'

export type ReminderPeriod = 'minutes' | 'hours' | 'days' | 'weeks'
export const reminderPeriods = ['minutes', 'hours', 'days', 'weeks']

export class CalendarEventReminder extends Item {

  /**
   * The method used by this reminder. Possible values are:
      "email" - Reminders are sent via email.
      "popup" - Reminders are sent via a UI popup.
      Required when adding a reminder.
   */
  @observable method: ReminderMethod = 'popup'

  /**
   * Number of minutes before the start of the event when the reminder should trigger. 
   * Valid values are between 0 and 40320 (4 weeks in minutes).
      Required when adding a reminder.
   */
  @computed get minutes(): number {
    return this.value * this.getPeriodMultiplier(this.period)
  }

  @observable value: number

  @observable period: ReminderPeriod = 'minutes'

  getPeriodMultiplier(period: ReminderPeriod): number {
    return {
      'minutes': 1,
      'hours': 60,
      'days': 60 * 24,
      'weeks': 60 * 24 * 7, 
    }[period]
  }

  @action setMinutesByValueAndPeriod(value: number, period: ReminderPeriod) {
    const minutes = value * this.getPeriodMultiplier(period)
    if (minutes < 0 ||minutes > 40320) {
      throw new TypeError('Valid values are between 0 and 40320 (4 weeks in minutes).')
    }
    this.value = value
    this.period = period
  }

  @action setMethod(method: ReminderMethod) {
    if (!reminderMethods.includes(method)) {
      throw new TypeError(`Valid values are ${reminderMethods.join(' | ')}`)
    }
    this.method = method
  }

  @action setPeriod(period: ReminderPeriod) {
    if (!reminderPeriods.includes(period)) {
      throw new TypeError(`Valid values are ${reminderPeriods.join(' | ')}`)
    }
    this.period = period
  }

}