import React from 'react'
import { observer } from 'mobx-react'
import './WizardTabs.scss'

const debug = require('debug')('treks:planExecuteAnalyze')

class WizardTabs extends React.Component {

  static defaultProps = {
    tabs: [
      {
        name: 'plan',
        label: 'Plan'
      },
      {
        name: 'execute',
        label: 'Execute'
      },
      {
        name: 'analyze',
        label: 'Analyze'
      }
    ],
    focusedTabName: 'plan'
  }

  onChange = event => {
    debug('title changed', event)
    this.props.project.setTitle(event.target.value) 
    this.props.project.save()
  }

  render() {
    const { tabs, focusedTabName, onChange } = this.props
    debug('render', { tabs, focusedTabName })
    return (
      <div className='wizard-tabs'>
        {
          tabs.map(tab => {
            return (
              <Tab
                key={tab.name}
                tab={tab}
                isFocused={focusedTabName === tab.name}
                onChange={() => onChange(tab)}
              />
            )
          })
        }
      </div>
    )
  }
}

const Tab = ({ tab, onChange, isFocused }) => {
  const onClick = (event) => {
    event.preventDefault()
    onChange(tab)
  }
  return (
    <a
      href={'#' + tab.name}
      className={'wizard-tab' + (isFocused ? ' focused' : '')}
      onClick={onClick}
    >
        {
          tab.label
        }
    </a>
  )
}

export default observer(WizardTabs)