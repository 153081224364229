import React from 'react'
import WithTooltip from "theme/Tooltip/WithTooltip"
import Pressable from "theme/Pressable"

export const AddItemBtn = ({ className, onClick, label, tooltipTitle, tooltipPlacement = 'top' }) => {
  return (
    <WithTooltip placement={tooltipPlacement} title={tooltipTitle}>
      <Pressable
        className={'btn-add-item ' + className}
        onClick={onClick}
      >
        {label}
      </Pressable>
    </WithTooltip>
  )
}