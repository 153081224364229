import React from 'react'
import { observer } from 'mobx-react'
import TaskList from './TaskList'
import TaskDetailRow from '../../TaskDetail/TaskDetailRow'
import PropTypes from 'prop-types'
import SubTaskListList from 'Stores/Task/SubTask/SubTaskListList'
import { AddItemBtn } from 'Components/ui/AddItemBtn'
import './SubTasks.scss'
import { IconPlusCircle } from 'theme/Icons/IconPlusCircle'

const debug = require('debug')('treks:task:subtasks')

class SubTasks extends React.Component {

  static propTypes = {
    subtasks: PropTypes.objectOf(() => SubTaskListList)
  }

  componentDidMount() {
    this.props.item.subTasksList.fetch()
  }

  componentDidUpdate() {
    this.props.item.subTasksList.fetched()
  }

  onAddSubTask = () => {
    const { item } = this.props
    const subTask = item.subTasksList.addItem(
      this.createEmptyItemProps()
    )
    subTask.setFocusOnTitle()
    subTask.save()
  }

  createEmptyItemProps() {
    const { item } = this.props
    const { focusedTimespan } = item.list
    return {
      title: '',
      duration: 15,
      timespan: focusedTimespan || item.timespan,
      onPlanner: false,
    }
  }

  render() {
    const { item, view, order } = this.props
    const { subTasksList } = item
    const hasSubTasks = subTasksList.items.length
    debug('render', { item, view, order })
    return (
      <div className="container subtasks-container">
        {
          hasSubTasks ? (
            <TaskDetailRow icon="subtasks" hasValue={hasSubTasks}>
              <div className="subtasks-list">
                <TaskList 
                  showTitle={false}
                  item={item}
                  list={subTasksList}
                  parent={item} 
                  view={view}
                  order={order}
                />
              </div>
            </TaskDetailRow>
          ) : null
        }
        <TaskDetailRow icon={!hasSubTasks ? "subtasks" : null} >
          <div className='task-add-subtask'>
            <AddItemBtn
              label={
                <>
                  <IconPlusCircle size={18} />
                  <span className='task-add-subtask-label'>
                    {' Add SubTask'}
                  </span>
                </>
              }
              onClick={this.onAddSubTask}
              className={'btn-add-task'}
              tooltipTitle={'Enter'}
            />
          </div>
        </TaskDetailRow>
      </div>
    )
  }
  
}

export default observer(SubTasks)