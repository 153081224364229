import ActionPlannerContainer from '../../Containers/ActionPlanner/ActionPlannerContainer'
import PlannerSettingsModal from '../../Containers/ActionPlanner/PlannerSettingsModal'
import { Route, Switch } from 'react-router-dom'

export const PlannerRoutes = ({ view }) => (
  <Switch>
    <Route path="/planner/settings" component={PlannerSettingsModal} />
    <Route path="/planner/:focusedDate" component={() => <ActionPlannerContainer view={view} />} />
    <Route path="/planner" component={() => <ActionPlannerContainer view={view} />} />
    <Route path="/" component={() => <ActionPlannerContainer view={view} />} />
  </Switch>
)
export default PlannerRoutes