import React from 'react'
import { observer } from 'mobx-react'
import './Tabs.scss'
import Tab from './Tab'
import Nav from 'react-bootstrap/Nav'
import PropTypes from 'prop-types'

class Tabs extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    defaultActiveKey: PropTypes.string,
    onSelect: PropTypes.func,
    tabs: PropTypes.array
  }

  static defaultProps = {
    className: '',
    variant: 'pills',
    defaultActiveKey: null,
    activeKey: null,
    onSelect: () => {},
    tabs: []
  }

  render() {
    const { variant, className, defaultActiveKey, activeKey, tabs, onSelect } = this.props
    const firstTab = tabs && tabs[0]
    return (
      <Nav
        className={'tabs ' + className}
        variant={variant}
        defaultActiveKey={defaultActiveKey || (firstTab && firstTab.key)}
        activeKey={activeKey}
        onSelect={onSelect}
      >
        {
          tabs.map(tab => {
            return (<Tab key={tab.key} tab={tab} />)
          })
        }
      </Nav>
    )
  }
}

export default observer(Tabs)
