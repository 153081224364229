import { action, computed, observable, override } from "mobx";
import { hasMany } from "Relationships/RelationshipDecorators";
import { CategoryItem, CategoryList } from "Stores/Category";
import { Item } from "Stores/Lists";
import { OrganizationItem, OrganizationList } from "Stores/Organization";
import { ProjectItem, ProjectList } from "Stores/Project";
import TaskItem, { TaskList } from "Stores/Task";
import { TeamItem, TeamList } from "Stores/Team";
import { once } from "Stores/utils";

const debug = require("debug")("treks:store:user");

export default class User extends Item {

  @observable email: string
  @observable name: string
  @observable password: string
  @observable profileImage: string
  @observable country: string
  @observable phone: string
  @observable phoneType: string  
  @observable workEmail: string
  @observable companySize: string
  @observable companyRole: string
  @observable industry: string
  @observable questions:string[] = []
  @observable activity:string
  @observable plan: string
  @observable type: string
  @observable status: string
  @observable domain: string
  @observable event: string
  @observable subscription: string
  @observable createDate: Date = new Date()
  @observable isDeleted: boolean
  @observable authorizeNetAccountId: string
  @observable agreedToTOS: boolean
  @observable recaptcha: string

  @hasMany(() => OrganizationList, (organization: OrganizationItem) => organization.memberList)
  organizationList: OrganizationList

  @hasMany(() => OrganizationList, (organization: OrganizationItem) => organization.createdBy)
  createdOrganizationList: OrganizationList

  @hasMany(() => CategoryList, (category: CategoryItem) => category.createdBy)
  createdCategoryList: CategoryList

  @hasMany(() => TeamList, (team: TeamItem) => team.createdBy)
  createdTeamList: TeamList

  @hasMany(() => ProjectList, (project: ProjectItem) => project.memberList)
  projectList: ProjectList

  @hasMany(() => TaskList, (task: TaskItem) => task.assignedUser)
  assignedTaskList: TaskList

  @computed get emailList(): string[] {
    const emailList = this.getAttribute('emailList', () => [])
    debug('getting email list', emailList)
    return emailList
  }

  set emailList(emailList: string[]) {
    debug('setting email list', emailList)
    this.setAttribute('emailList', emailList)
  }

  @override async fetch() {
    debug("fetch user");
    const { id } = this;
    return this.fetchState.get("user/detail/" + id).then((resp) => {
      const { user } = resp.data;
      debug("resp", { user });
      Object.assign(this, user);
      return resp;
    });
  }

  toJSON() {
      const props = this.getProps()
      const json = { ...props, emailList: JSON.stringify(this.emailList) }
      return json
  }

  @action async edit() {
    debug("update user", this);
    if (!this.id) throw new Error('User does not have an ID')
    const resp = await this.fetchState.post("user/save", this.toJSON());
    return User.fromJSON(resp.data)
  }

  @action async add() {
    debug("update user", this);
    let json = this.toJSON()
    if (json.id) throw new Error('User already has an ID')
    delete json.id
    const resp = await this.fetchState.post("user/save", json);
    return User.fromJSON(resp.data)
  }

  @override async save() {
    debug("save user", this);
    let json = this.toJSON()
    if (!json.id) delete json.id // remove if empty
    const resp = await this.fetchState.post("user/save", json);
    return User.fromJSON(resp.data)
  }
}

export const importUser = once(() => {
  return User;
});
