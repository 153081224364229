import { action } from 'mobx';
import List from '../Lists/List'
import { User } from 'Stores/User';
import { hasOne } from 'Relationships/RelationshipDecorators';
import { models } from 'Stores/Stores';
import TaskItem from '.';

export default class TaskMemberList extends List {

  get ModelType() {
    return User
  }

	@hasOne(() => models.TaskItem, (task: TaskItem) => task.memberList)
	task: TaskItem


  constructor(items = [], task = null) {
   super(items)
   task && this.setTask(task)
  }

  @action setTask(task: TaskItem) {
    this.task = task
  }

}