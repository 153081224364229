import React from 'react'
import { observer } from 'mobx-react'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import stores, { getStore } from 'Stores'
import qs from 'qs'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:register:verify')

const headerText = 'Thank-you for choosing TREKS!'
const InfoText = ({ email }) => {
  return email 
    ? <p>You have verified your email account {email}.</p>
    : <p>Verifying your email account.</p>
}
const btnContinueLabel = 'Continue'
const successVerifyingEmailMsg = 'Your email is verified'
const failedVerifyingEmailMsg = 'An error occurred with verification of your email'

export default withRouter(observer(class AccountVerityToken extends React.Component {

  accountRegisterStore = getStore(stores.AccountRegister)
  get query() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  componentDidMount() {
    debug('props', this.props, this.query)
    const token = this.query.token
    this.verifyEmailToken(token)
  }

  onClickContinue = () => {
    this.props.history.push('/account/register/profile')
  }

  verifyEmailToken(token) {
    return this.accountRegisterStore.verifyEmailToken(token)
      .then(({ data: { email } }) => {
        this.accountRegisterStore.setProps({ email })
        getAppNotifications().success(successVerifyingEmailMsg)
      })
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedVerifyingEmailMsg)
      })
  }

  render() {
    const { accountRegisterStore } = this
    const email = accountRegisterStore.email
    debug('render', { accountRegisterStore, props: this.props })
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              <InfoText email={email} />
            </div>
            {
              email && (
                <Button 
                  className="btn-resend-email"
                  onClick={this.onClickContinue} 
                  variant="outline"
                >
                  {btnContinueLabel}
                </Button>
              )
            }
          </Col>
        </Row>
      </Container>
    )
  }
}))