import React from 'react';
import './Dashboard.scss';
import { observer } from 'mobx-react';
import { getStore, models } from 'Stores';
import { Responsive } from 'react-grid-layout';
import './modules/react-grid-layout.css';
import './modules/react-resizable.css';
import AppLoadingIndicator from 'Screens/App/AppLoadingIndicator';
import Button from 'react-bootstrap/Button';
import GridItem from './GridItem';
import { SizeMe } from 'react-sizeme';
import DashboardMenu from './DashboardMenu';
import DashboardTitle from './DashboardTitle';
import { importGridItem } from 'Stores/Dashboard';

// const ResponsiveGridLayout = WidthProvider(Responsive);
const debug = require('debug')('treks:inbox:list');

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
        };
    }

    static defaultProps = {
        get gridList() {
            return getStore(models.GridList);
        },
        get dashboardList() {
            return getStore(models.DashboardList);
        },
        get mainPaneGroup() {
            return getStore(models.MainPaneGroup);
        },
    };

    async componentDidMount() {
        const { dashboardList } = this.props;
        // gridList.fetch();
        let result = await dashboardList.fetch();
        debug('got result from fetch', result);
        this.selected();
    }

    async selected() {
        const { gridList, dashboardList, mainPaneGroup } = this.props;
        debug('gridlist fetching');
        gridList.fetch(dashboardList.focusedItem.id);
        if (dashboardList.focusedItem.workingDashboard) {
            // if (!init) {
            // 	init = true
            // 	return
            // }
            // return
            // const dashPane = getBucketList().findItem({ slug: 'dashboard' });
            // mainPaneGroup.setItems([dashPane]);
            mainPaneGroup.items[0].width = 1200;
            const pane = mainPaneGroup.getBucketItemPropsBySlug('day-planner')
            mainPaneGroup.addOrUpdateItem(pane, {});
        } else {
            let pane = mainPaneGroup.items[0];
            pane.width = '100vw';
            mainPaneGroup.setItems([pane]);
        }
    }

    async addGridItem() {
        const { gridList, dashboardList } = this.props;
        gridList.newItem(dashboardList.focusedItem.id);
        this.setState({ mode: 'edit' });
    }

    async commitLayout(layout) {
        // let grid = entry.grid
        let save = true;
        layout.forEach((item) => {
            let modelItem = importGridItem().fromUid(item.i);

            debug('modelItem', modelItem, 'item', item);
            debug('got grid item', modelItem, modelItem.grid);
            let numRows = 0;
            modelItem.grid.forEach((r) => {
                let max = modelItem.wScale * item.w;
                let columnsInRow = 0;
                r.forEach((c) => {
                    if (c) columnsInRow++;
                });

                if (columnsInRow > max) {
                    save = false;
                }
                if (columnsInRow > 0) {
                    numRows++;
                }
            });
            if (item.h < numRows) save = false; //trying to shrink height where there are widgets

            if (save) {
                modelItem.y = item.y;
                modelItem.x = item.x;
                modelItem.h = item.h;
                modelItem.w = item.w;
                modelItem.save();
            } else {
                alert('cant resize container ' + modelItem.uid);

                item.y = modelItem.y;
                item.x = modelItem.x;
                item.h = modelItem.h;
                item.w = modelItem.w;
            }
        });

        this.forceUpdate();
    }

    changeDashboardType() {
        const { mainPaneGroup, dashboardList } = this.props;

        dashboardList.focusedItem.workingDashboard = dashboardList.focusedItem.workingDashboard ? false : true;

        dashboardList.focusedItem.save();
        debug('mainPaneGroup', mainPaneGroup);
        this.selected();
    }

    render() {
        debug('render', this.props.gridList.items);
        // const { groups, entries } = this.state
        const { gridList, dashboardList } = this.props;
        const { mode } = this.state;

        if (!gridList.fetchState.isFetched && gridList.items.length === 0) {
            return (
                <>
                    <AppLoadingIndicator
                        style={{
                            position: 'absolute',
                            top: '70px',
                            left: 'calc(50%)',
                            zIndex: 1000,
                        }}
                    />
                </>
            );
        }
        // const layout = ;
        return (
            <div
                className={
                    'dashboard-container ' +
                    (dashboardList.focusedItem.workingDashboard ? 'container-working' : 'container-viewing')
                }
            >
                <div className="toolbar">
                    <div className="selector">
                        <div
                            style={{
                                borderRadius: 5,
                                background: 'hsl(0,0%,100%)',
                                width: '100%',
                            }}
                        >
                            <div style={{ display: mode === 'view' ? 'none' : 'block' }}>
                                <span style={{ paddingLeft: 10 }}>Name:</span>
                                <DashboardTitle item={dashboardList.focusedItem} />
                            </div>
                            <div style={{ display: mode === 'edit' ? 'none' : 'block' }}>
                                <DashboardMenu selected={() => this.selected()} />
                            </div>
                        </div>
                    </div>
                    {mode === 'edit' ? (
                        <div className="toolbar">
                            {' '}
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div for="workingDashboard" style={{ marginRight: 10 }}>
                                    Dashboard Type:
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <label style={{marginBottom: 0}}>
                                        <input
                                            type="radio"
                                            checked={dashboardList.focusedItem.workingDashboard}
                                            onClick={() => this.changeDashboardType()}
                                            style={{ marginRight: 5 }}
                                        />
                                        Working
                                    </label>
                                    <label style={{ marginBottom: 0, paddingLeft: 10 }}>
                                        <input
                                            type="radio"
                                            checked={!dashboardList.focusedItem.workingDashboard}
                                            onClick={() => this.changeDashboardType()}
                                            style={{ marginRight: 5 }}
                                        />
                                        Viewing
                                    </label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <Button
                        style={{ padding: 10, paddingTop: 3, marginRight: 10 }}
                        variant={mode === 'view' ? 'outline' : 'primary'}
                        size="xs"
                        onClick={() => {
                            this.setState({ mode: mode === 'view' ? 'edit' : 'view' });
                        }}
                    >
                        {mode === 'view' ? 'Edit' : 'Done'}
                    </Button>
                    {/* <DashboardItem id="a" pos={{ x: 0, y: 0 }} scale={{ w: 1, h: 2 }} /> */}
                </div>

                <SizeMe>
                    {({ size }) => (
                        <Responsive
                            className="dashboard-grid"
                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                            cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
                            rowHeight={mode === 'edit' ? 120 : 100}
                            width={size.width}
                            draggableHandle=".grid-item-header"
                            onDragStop={(layout) => this.commitLayout(layout)}
                            onResizeStop={(layout) => this.commitLayout(layout)}
                            layouts={{
                                lg: gridList.items.map((item) => ({
                                    i: item.i,
                                    x: item.x,
                                    y: item.y,
                                    h: item.h,
                                    w: item.w,
                                    maxH: item.maxH,
                                })),
                            }}
                            isDraggable={mode === 'edit'}
                            isResizable={mode === 'edit'}
                        >
                            {gridList.items.map((i) => {
                                // debug('dashboard-item', i);
                                let item = i;
                                return (
                                    <div
                                        key={i.uid}
                                        className="dashboard-item"
                                        data-grid={{
                                            i: item.i,
                                            x: item.x,
                                            y: item.y,
                                            h: item.h,
                                            w: item.w,
                                            maxH: item.maxH,
                                        }}
                                    >
                                        <GridItem
                                            id={i.uid}
                                            entry={i}
                                            mode={mode}
                                            availableWidgets={dashboardList.availableWidgets}
                                            updatedWidgets={() => {
                                                debug('updated widgets should update', new Date(), i);
                                                i.setMinHeight();
                                                this.forceUpdate();
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </Responsive>
                    )}
                </SizeMe>

                {gridList.items.length === 0 ? (
                    <div
                        style={{
                            padding: 10,
                            width: '100%',
                            textAlign: 'center',
                        }}
                        onClick={() => {
                            this.addGridItem();
                        }}
                    >
                        Click here to add a group
                    </div>
                ) : (
                    <div style={{ width: '100%', display: mode === 'edit' ? 'block' : 'none' }}>
                        <Button
                            style={{
                                padding: 10,
                                textAlign: 'center',
                                margin: 'auto',
                                display: 'block',
                            }}
                            size="xs"
                            onClick={() => {
                                this.addGridItem();
                            }}
                        >
                            + Add Group
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default observer(Dashboard)
