import React from 'react'
import { observer } from 'mobx-react';
import ActionPlannerStickyTask from './ActionPlannerStickyTask';
import TaskItem from 'Stores/Task';
import { TaskPieceFlow } from 'Stores/ActionPlanner/PlannerFlow/TaskPieceFlow';
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import Draggable from 'Modules/Draggable';

const debug = require('debug')('treks:planner:task:sticky:draggable')

type ContextProps = {
  nativeEvent: React.MouseEvent,
  task: TaskItem
}

type Props = {
  task: TaskItem;
  onShowContextMenu?: (props: ContextProps) => void;
  taskPiece?: TaskPieceFlow;
  plannerFlow: PlannerFlow;
}

class DraggableStickyTask extends React.Component<Props> {

  elementRef = null
  elementClone = null

  prevTop = 0
  isDragging = false

  static defaultProps = {
    saveEventErrorMsg: 'Failed to save task update'
  }

  onElementRef = (ref: Draggable) => {
    if (ref) this.elementRef = ref
  }

  onDragStart = () => {
    document.body.style.cursor = 'grab'
    this.isDragging = true
  }

  onDrag = ({ top }: { top: number }) => {
    debug('drag', top)
    this.setTaskPosition(top)
    return false
  }

  onDragEnd = ({ top }: { top: number }) => {
    document.body.style.cursor = ''
    this.setTaskPosition(top)
    this.isDragging = false
  }

  getPosRelToPlanner(top: number) {
    const { task } = this.props
    const minsPerDay = 60 * 24
    const offsetMins = task.plannerUtils.getDurationFromHeight(top)
    const offsetMinsToEventEnd = offsetMins + task.duration
    const startDate = task.actionPlanner.getDateFromOffsetTop(top)
    const afterStartOfDay = startDate > task.startOfDayDate
    const beforeEndOfDay = offsetMinsToEventEnd < minsPerDay
    const timespans = task.actionPlanner.dayTimespans.items
    const afterWake = timespans[0].duration < offsetMins
    const beforeSleep = minsPerDay - timespans[timespans.length - 1].duration > offsetMinsToEventEnd
    return {
      afterStartOfDay, beforeEndOfDay, afterWake, beforeSleep
    }
  }

  setTaskPosition(top: number) {
    debug('setTaskPosition', { top })
    this.setStartDateFromTopOffset(top)
    return
    // @note logic is deprecated
    const pos = this.getPosRelToPlanner(top)
    const prevPos = this.getPosRelToPlanner(this.prevTop)
    // prevent drag outside of day
    if (!(pos.afterStartOfDay && pos.beforeEndOfDay)) return
    // within non-sleep
    if (pos.afterWake && pos.beforeSleep) {
      return this.setStartDateFromTopOffset(top)
    }
    // was in sleep, allow move around except outside of day
    if (!prevPos.afterWake || !prevPos.beforeSleep) {
      return this.setStartDateFromTopOffset(top)
    }
  }

  setStartDateFromTopOffset(top: number) {
    const { plannerFlow, task } = this.props
    const offsetMins = plannerFlow.actionPlanner.utils.getDurationFromHeight(top)
    const nextStartDate = plannerFlow.getDateAtPlannerDuration(offsetMins)
    task.setSticky(nextStartDate)
    this.prevTop = top
    this.scrollActionPlannerIfEventHidden(top)
  }

  scrollActionPlannerIfEventHidden(top: number) {
    const plannerBody = document.querySelector('.action-planner-body')
    if (plannerBody.scrollTop > top) {
      plannerBody.scrollTop = top
    }
  }
  
  onShowContextMenu(params: ContextProps) {
    const { onShowContextMenu } = this.props
    onShowContextMenu && onShowContextMenu(params)
  }

  render() {
    const { task, taskPiece, plannerFlow } = this.props
    const { utils } = plannerFlow.actionPlanner
    // const stackIndex = plannerFlow.getStackIndex(task) // @todo 
    global.stickyPlannerFlow = plannerFlow
    const onTask: TaskItem|null = null // mocked
    const stackIndex = 0  // mocked
    const startDuration = plannerFlow.getMinsSinceStartOfPlanner(task.startDate)
    const top = utils.getHeightFromDuration(startDuration)
    const titleOffset = 7
    const stackOffset = 20, maxStacks = 8, maxStackOffset = stackOffset * maxStacks
    const widthOffset = 120
    const isOverTaskTitle = onTask && (onTask.startDuration + titleOffset >= task.minutesSinceStartOfDay)
    const onHoveredTaskWidth = onTask && onTask.isHovered && isOverTaskTitle && !this.isDragging ? 200 : 0
    const stackWidth = stackIndex * stackOffset
    const left = Math.min(Math.max(stackWidth, onHoveredTaskWidth - 50), maxStackOffset) || 0
    const transform = `translate(${left}px, ${top}px)`
    const width = `calc(100% - ${widthOffset + left}px)`
    const zIndex = 1000 + stackIndex
    const onTaskClass = onTask ? ' onTask' : ''
    const onActiveTaskClass = onTask && onTask.isFocused ? ' onFocusedTask' : ''
    const onDoneTaskClass = onTask && onTask.done ? ' onDoneTask' : ''
    const onHoveredTaskClass = onTask && onTask.isHovered && isOverTaskTitle ? ' onHoveredTask' : ''
    const draggingClass = this.isDragging ? ' dragging' : ''

    debug('render', { left, top, task, taskPiece, stackIndex, onTask })
    return (
      <Draggable
        ref={this.onElementRef}
        scrollElSel={'.main-pane-component:has(.planner-container)'}
        className={'draggable-task' + draggingClass + onTaskClass + onActiveTaskClass + onDoneTaskClass + onHoveredTaskClass}
        dragDirection={'vertical'}
        onDrag={this.onDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        style={{ transform, width, zIndex }}
        onContextMenu={({ nativeEvent }) => this.onShowContextMenu({ nativeEvent, task })}
      >
        <ActionPlannerStickyTask
          plannerFlow={plannerFlow}
          task={task} 
          taskPiece={taskPiece}
        />
      </Draggable>
    )
  }
}

export default observer(DraggableStickyTask)