import React from 'react'
import { observer } from 'mobx-react'
import GoalMeetingItem from './GoalMeetingItem'
import PropTypes from 'prop-types'
import MeetingList from 'Stores/Meeting/MeetingList'
import List from 'theme/List';

const debug = require('debug')('treks:goal:meetings:list')

class GoalMeetingList extends React.Component {

  static propTypes = {
    meetings: PropTypes.objectOf(() => MeetingList).isRequired,
  }

  render() {
    const { meetings, view } = this.props
    const items = meetings.filterItemsByDoneView(view)

    debug('render', { meetings, view, items })

    return (
      <List className="meeting-list">
        {items.map(meeting => (
          <GoalMeetingItem key={meeting.id} meeting={meeting} />
        ))}
      </List>
    )
  }
}

export default observer(GoalMeetingList)
