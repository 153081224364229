import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { FormField } from 'theme/Form'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

const debug = require('debug')('treks:theme:UserForm')

export default withRouter(observer(class UserSearch extends React.Component {

  onSubmit = event => {
    const values = this.props.fieldsStore.getFieldValues()
    debug('form submission', values)
    event.preventDefault()
    if (this.props.onSubmit) {
      this.props.onSubmit(values)
    }
  }

  onFieldChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  onFieldKeyDown = event => {
    debug('field change', event)
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  }

  onCancel = () => {
    this.props.history.push('/admin/user')
  }

  render() {
    const submitLabel = this.props.submitLabel || 'Create User'
    return (
      <Form className="admin-user-form" autoComplete="new-password" onSubmit={this.onSubmit}>
        <Container>
          <Row>
            {
              this.props.fieldsStore.fields.map(field => {
                return (
                  <Col key={field.name}>
                    <FormField 
                      {...field} 
                      onChange={(value, event) => this.onFieldChange(field, value, event)} 
                      onKeyDown={this.onFieldKeyDown}
                    />
                  </Col>
                )
              })
            }
          </Row>
          <Row>
            <Col className="footer">
              <Button type="submit" className="btn-submit">{submitLabel}</Button>
              <Button type="submit" variant="outline" className="btn-reset" onClick={this.onCancel}>Cancel</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    )
  }
}))