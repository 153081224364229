import React from 'react'
import { observer } from 'mobx-react'
import './AccountSettings.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { FormControlDropdown, FormField } from 'theme/Form'
import stores, { getStore } from 'Stores'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:account:settings:display')

const displaySettingsStore = getStore(stores.DisplaySettings)

const SettingsDropdown = observer(props => <FormControlDropdown {...props} className="settings-dropdown" />)
const SettingsItem = observer(props => <FormField {...props} Component={SettingsDropdown} />)

class AccountSettingsDisplay extends React.Component {

  saveTimeout = null

  componentDidMount() {
    displaySettingsStore.fetch()
      .catch(error => {
        debug('error', error)
        getAppNotifications().error('failed to load settings')
      })
  }

  onChange = (field, value) => {
    debug('select', field, value)
    field.value = value
    clearTimeout(this.saveTimeout)
    this.saveTimeout = setTimeout(() => {
      displaySettingsStore.save()
        .then(() => {
          getAppNotifications().success('Settings updated')
        })
        .catch(error => {
          debug('error', error)
          getAppNotifications().error('failed to load settings')
        })
    }, 500)
  }

  render() {
    debug('render', { displaySettingsStore })
    return (
      <Container className="settings account-settings">
        <Row className="account-settings-row">
          <Col>
            <Form>
              {
                displaySettingsStore.fields.map(field => (
                  <SettingsItem 
                    key={field.name}
                    {...field}
                    onChange={item => this.onChange(field, item)} 
                  />
                ))
              }
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default observer(AccountSettingsDisplay)