import React from 'react'
import { observer } from 'mobx-react';
import MainPaneGroupItemHeader from './MainPaneGroupItemHeader';
import MainPaneTabsContainer from './Tabs/TabsContainer'
import EmptyComponent from './EmptyComponent';

const debug = require('debug')('treks:component:main-pane:item')

class MainPaneGroupItem extends React.Component {

  prevScrollTop = 0

  prevWinScrollTop = 0

  onClickClose = event => {
    const { mainPaneGroup, pane } = this.props
    mainPaneGroup.removeItem(pane)
    event.stopPropagation()
  }

  onPaneScroll = event => {
    const scrollTop = event.target.scrollTop
    if (this.prevScrollTop === scrollTop) return // no change
    const winScrollStop = this.prevScrollTop < scrollTop ? document.body.scrollHeight : 0
    if (this.prevWinScrollTop === winScrollStop) return // no window scroll change
    this.prevScrollTop = scrollTop <= 0 ? 0 : scrollTop // negative scroll
    this.prevWinScrollTop = winScrollStop
    window.scrollTo({ left: 0, top: winScrollStop, behavior: "smooth" })
  }

  render() {
    const { style, mainPaneGroup, pane } = this.props
    const { name, icon, component, props, tabs } = pane
    debug('render', { name, icon, component, props })
    const Component = component || EmptyComponent
    return (
      <div className="main-pane-item" style={style}>
        {
          tabs.length ? (
            <MainPaneTabsContainer
              mainPaneGroup={mainPaneGroup}
              pane={pane}
              onPaneScroll={this.onPaneScroll}
              EmptyComponent={EmptyComponent}
            />
          ) : (
            <>
              <MainPaneGroupItemHeader
                pane={pane}
                icon={icon}
                onClickClose={this.onClickClose}
              />
              <div
                className={"main-pane-component md-light-scrollbar"}
                onScroll={this.onPaneScroll}
              >
                <Component
                  {...props}
                  mainPaneGroup={mainPaneGroup}
                  pane={pane}
                />
              </div>
            </>
          )
        }
      </div>
    )
  }
}

export default observer(MainPaneGroupItem)
