import { observable, action } from 'mobx';
import Model from 'Stores/Model';

export default class PFAModalModel extends Model {

  @observable isVisible: boolean = false

  @observable title: string = 'Plan - Focus - Accomplish'

  @observable icon: string = 'pfa'

  @action show() {
    this.isVisible = true
  }

  @action hide() {
    this.isVisible = false
  }

}