import { observable } from "mobx";
import Model from "Stores/Model";

export class TimespanProps extends Model {

  @observable type = ''

  @observable color = ''

  @observable title = ''

  @observable canHaveItems = true

  @observable canMove = true

  @observable deleted: boolean = false

}