import React from 'react'
import { observer } from 'mobx-react'
import GoalActivityItem from './GoalActivityItem';

const debug = require('debug')('treks:goal:activitys:list')

class GoalActivityList extends React.Component {

  render() {
    const { activityList } = this.props.goal
    debug('render', { activityList })
    return (
      <div className="goal-activity-list">
        {
          activityList.items.map(activityItem => {
            return <GoalActivityItem
              key={activityItem.hash}
              activityItem={activityItem}
            />
          })
        }
      </div>
    )
  }
}

export default observer(GoalActivityList)
