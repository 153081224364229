import Model from "Stores/Model"

type Key = string | Symbol
type Value = any
type ModelType = Model | typeof Model

/**
 * Get a memoized Map specific to a model 
 */
export function getModelMapByKey(model: ModelType, mapKey: symbol | string): Map<Key, Value> {
  if (!model[mapKey]) {
    model[mapKey] = new Map()
  }
  return model[mapKey]
}