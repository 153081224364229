import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getHabitPointsStore } from "Containers/PFA/stores/PFAHabitPointsStore";
import { getHabitExtraPointsLimitOptions } from "./stores/HabitExtraPointsLimitOptions";

class HabitExtraPointsLimitSelect extends React.Component {

  static defaultProps = {
    options: getHabitExtraPointsLimitOptions(),
    habitPointsStore: getHabitPointsStore(),
    defaultValue: '10'
  };

  onChange = ({ value }) => {
    this.props.habitPointsStore.setProp('extraPointsLimit', parseInt(value, 10))
  }

  getValue() {
    return String(this.props.habitPointsStore.extraPointsLimit || this.props.defaultValue)
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
      />
    )
  }

}

export default observer(HabitExtraPointsLimitSelect)
