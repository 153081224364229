import { getStorable } from 'services/store/StoreService';
import { ModelContext } from 'Stores/Model/ModelContext';
import { ModelPropsI } from 'Stores/Model/Type/Model';
import Model from '../Model';
import List from './List';

/**
 * An Item composes a {List} and {Model} allowing a structure such as
 * 
 * List1 -> Item1 -> Model1
 * List1 -> Item2 -> Model1
 * List2 -> Item3 -> Model1
 * 
 * Notice that Model1 exists in 3 distinct Items in 2 distinct Lists
 * 
 * A model is an entity with only one distinct (by id) copy
 * The {Model} can be refereced from many Items and many Lists
 * 
 * Models are not aware of what lists or items they are composed in
 * Items are aware of their parent List and Model
 * Lists are aware of their Items and Models
 * 
 * Structure within List/Item is solely a concern of the List and Item
 * Model state is solely the concern of the Model
 * 
 */
export default class Item extends Model {

  static ignoredProps = [
    ...Model.ignoredProps,
    'model', 'list'
  ]

  get storable(): ModelContext {
    // @todo remove when done with refactor
    if (!this.ModelType) return null // has not implemented a ModelType
    return getStorable(this.ModelType, this, 'model')
  }

  get ModelType(): typeof Model {
    return null
  }

  /**
   * Parent List in {this.model} current structure
   */
  public list: List

  /**
   * The model encapsulated by this Item
   */
  get model(): Model {
    return this.storable?.fromProps()
  }
  set model(model: Model) {
    this.storable?.setModel(model)
  }

  // ---- refactor: remove when done ----

  constructor(props: ModelPropsI) {
    super()
    if (props) this.setProps(props)
    if (this.ModelType) {
      this.reflectModelProps()
    }
  }

  reflectModelProps() {
    const myKeys = this.getPropKeys()
    const modelKeys = this.model.getPropKeys()
    const modelOnlyKeys = modelKeys.filter(key => !myKeys.includes(key))
    modelOnlyKeys.forEach(key => this.reflectModelProp(key))
    super.addPropKeys(modelOnlyKeys)
  }

  reflectModelProp(key) {
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      return // key exists already
    }
    try {
      Object.defineProperty(this, key, {
        get: () => this.model.getProp(key),
        set: (value) => {
          this.model.setProp(key, value)
        },
        configurable: true, // required for model tests
        enumerable: true
      })
    } catch(e) {
      console.warn('error defining key', key, e)
      // ignore keys already on the Item
    }
  }

}