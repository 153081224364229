import OrderedList from 'Stores/Lists/OrderedList';
import GoalItem from './GoalItem'
import { override } from 'mobx'
import { ProjectItem } from 'Stores/Project'
import { hasOne } from 'Relationships/RelationshipDecorators';
import HabitItem from 'Stores/Habit/HabitItem';

export default class GoalList extends OrderedList {

  get ModelType() {
    return GoalItem
  }

	@hasOne(() => ProjectItem, (project: ProjectItem) => project.goals)
  project: ProjectItem


	@hasOne(() => HabitItem, (habit: HabitItem) => habit.goals)
  habit: HabitItem

  @override async fetch() {
    return this.fetchState.get('goal/list')
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}
