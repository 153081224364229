import './FieldInfo.scss'

export const FieldInfo = ({ info }) => {
  return (
    <div className='field-info'>
      {
        info.split("\n").map((sentence, i) => {
          return (
            <p key={i} className='field-info-sentence'>
              {sentence}
            </p>
          )
        })
      }
    </div>
  )
}