import React, { ReactNode } from 'react'

const debug = require('debug')('treks:planExecuteAnalyze')

export type ScrollTab = {
  label?: ReactNode;
  name: string;
}

export type OnChangeProps = {
  event: React.MouseEvent,
  tab: ScrollTab,
  el: HTMLElement,
  index: number 
}

export class ScrollTabs extends React.Component<{
  tabs: ScrollTab[];
  focusedIndex: number;
  onChange: (props: OnChangeProps) => void
}> {

  render() {
    const { tabs, focusedIndex, onChange } = this.props
    debug('render', { tabs, focusedIndex })
    return (
      <div className='scroll-tabs'>
        {
          tabs.map((tab, index) => {
            return (
              <Tab
                key={tab.name}
                tab={tab}
                index={index}
                isFocused={focusedIndex === index}
                onChange={onChange}
              />
            )
          })
        }
      </div>
    )
  }
}

const Tab = ({ tab, index, onChange, isFocused }) => {
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault()
    onChange({ event, tab, index, el: event.target })
  }
  return (
    <a
      href={'#' + tab.name}
      className={'scroll-tab' + (isFocused ? ' focused' : '')}
      onClick={onClick}
    >
        {
          tab.label || tab.name
        }
    </a>
  )
}

export default ScrollTabs