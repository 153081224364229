import React from 'react'
import { components } from 'react-select'
import AddOption from './AddOption'
import { observer } from 'mobx-react';

const debug = require('debug')('treks:select:MenuList')

class MenuList extends React.Component {

  render() {
    const { props } = this
    const { children } = props
    const { hasAddOption, inputValue, addOptionIsActive } = props.selectProps
    debug('render', { props, children })
    return (
      <components.MenuList {...props}>
        {
          hasAddOption && !inputValue && !addOptionIsActive && <AddOption {...props.selectProps} />
        }
        {children}
      </components.MenuList>
    );
  }
}

export default observer(MenuList)