import React from 'react'
import { observer } from 'mobx-react'
import stores, { getStore } from 'Stores'

const debug = require('debug')('treks:container:account')

class AccountLogout extends React.Component {

  Session = getStore(stores.Session)

  componentDidMount() {
    this.Session.logout()
  }

  render() {
    debug('render', this.Session)
    return (
      <p>You have been logged out</p>
    )
  }
}

export default observer(AccountLogout)