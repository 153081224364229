import { override } from 'mobx'
import GoalMemberItem from './GoalMemberItem'
import { MemberList } from 'Stores/Member'
import { GoalItem } from '.'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class GoalFollowerList extends MemberList {

  get ModelType() {
    return GoalMemberItem
  }

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.followerList)
  goal:GoalItem

  createItemProps(props:any) {
    return { goal:this.goal, ...props }
  }

  @override async fetch() {
    return this.fetchState.get('goal/member/list/' + this.goal.id)
  }

}
