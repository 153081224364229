import { override } from 'mobx';
import { SubTaskListList } from 'Stores/Task/SubTask'
import { hasOne } from 'Relationships/RelationshipDecorators';
import ProjectItem from './ProjectItem';
/**
 * List of SubTask Lists for a specific project
 */
export default class ProjectSubTaskListList extends SubTaskListList {

  /**
   * @property {Date}
   * @note We don't have a due date for projects
   */
  @override get durationShouldBeDone() {
    return 0
  }

	@hasOne(() => ProjectItem, (project: ProjectItem) => project.subTasksList)
  project: ProjectItem

  @override async save() {
    return this.saveState.postJson('project/subtasks/save', await this.toJSON())
      .then(({ data }) => this.fromJSON(data.after))
  }

  @override async fetch() {
    return this.fetchState.get('project/subtasks/list/' + this.project.id)
      .then(json => this.fromJSON(json.data))
  }

}