import { reaction } from "mobx"
import { getAppNotifications, Position } from "Stores/App/AppToaster/AppNotifications"
import TaskItem from "."
import lang from './language/task'

type TaskAction = {
  name:string,
  action:Function
}

export default class TaskActions {

  task:TaskItem

  actions:Array<TaskAction> = [
    {
      name: 'onPlanner',
      action: () => {
        if (!this.task.onPlanner) {
          this.onRemoveFromPlanner()
        } else {
          this.onAddToPlanner()
        }
      }
    },
    {
      name: 'trashed',
      action: () => {
        if (this.task.trashed) {
          this.onDelete()
        } else {
          this.onUnDelete()
        }
      }
    }
  ]

  constructor(task:TaskItem) {
    this.task = task
  }

  addAction(action:TaskAction) {
    this.actions.push(action)
  }

  removeAction(action:TaskAction) {
    const index = this.actions.indexOf(action)
    if (index) {
      return this.actions.splice(index, 1)
    }
  }

  watchActions():Array<any> {
    return this.actions.map(({ name, action }:TaskAction) => {
        return reaction(() => this.task[name], () => action.call(this))
      })
  }

  onRemoveFromPlanner() {
    getAppNotifications().warn({
      message: lang.taskRemovedFromPlanner,
      position: Position.BOTTOM_LEFT,
      icon: "ban-circle",
      action: {
        onClick: () => this.task.onPlanner = true,
        text: "Undo",
      }
    })
  }

  onAddToPlanner() {
    getAppNotifications().success({
      icon: "add",
      message: lang.taskAddedToPlanner,
      position: Position.BOTTOM_LEFT,
      action: {
        onClick: () => this.task.onPlanner = false,
        text: "Undo",
      }
    })
  }

  onDelete() {
    getAppNotifications().error({
      message: lang.taskDeleted,
      position: Position.BOTTOM_LEFT,
      icon: "ban-circle",
      action: {
        onClick: () => this.task.setTrashed(false),
        text: "Undo",
      }
    })
  }

  onUnDelete() {
    getAppNotifications().success({
      icon: "add",
      message: lang.taskRestored,
      position: Position.BOTTOM_LEFT,
    })
  }
}