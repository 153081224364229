import { autorun, observable } from "mobx"
import { Entity } from "./Entity"
import { EntityModel } from "./EntityModel"

export type EntityId = string|number
export type EntityMapOptions = {
  isObservable: boolean
}

export class EntityMap {

  ModelType: typeof EntityModel
  _observableEntities = observable(new Map())
  _optimizedEntities = new Map()

  isObservable = false

  get entities() {
    return this.isObservable 
      ? this._observableEntities : this._optimizedEntities
  }

  constructor(ModelType, opts?:EntityMapOptions) {
    this.ModelType = ModelType
    if (opts) {
      Object.assign(this, opts)
    }
  }

  errorOnIdChanges() {
    this.isObservable = true
    autorun(() => {
      [ ...this.entities.entries() as any ].forEach(([key, entity]) => {
        if (key !== entity.id) {
          throw new Error('Entity id was changed!')
        }
      })
    })
  }
  
  set(id: EntityId, entity: Entity) {
    if (typeof id === 'undefined') {
      throw new TypeError('First param `id` must be defined')
    }
    if (!(entity instanceof Entity)) {
      throw new TypeError('Second param must be an Entity')
    }
    if (this.has(id)) {
      throw new TypeError('An entity with this id already exists')
    }
    if (id !== entity.get('id')) {
      throw new TypeError('The id and entity.id must match')
    }
    this.entities.set(id, entity)
  }

  get(id: EntityId): Entity {
    return this.entities.get(id)
  }

  has(id: EntityId): boolean {
    return this.entities.has(id)
  }

  hasEntity(entity: Entity): boolean {
    return this.entities.get(entity.id) === entity
  }

  clearAll() {
    this.entities.clear()
  }

  getAll() {
    return [ ...this.entities.values() as any ]
  }

  size() {
    return this.entities.size
  }
}