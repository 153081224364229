import React from 'react'
import './Avatar.scss'
import { lightenColor } from '../../lib/utils'

export default class Avatar extends React.PureComponent {

  static defaultProps = {
    size: 30,
    name: '',
    initialsLen: 2,
    initialsSep: '',
    initialsEnd: ''
  }

  /**
   * @static getColor
   * @param {String} str
   * @return {String}
   */
  getColor(str) {
    return '#' + this.intToRGB(this.hashCode(str || ''));
  }

  /**
   * Thanks to Cristian Sanchez
   * @url http://stackoverflow.com/a/3426956/4185200
   *
   * @param {String} str
   * @return {String}
   */
  hashCode(str) {
    let hash = 0, i = 0;
    for(i; i < str.length; i++) hash = str.charCodeAt(i) + ((hash << 5) - hash);
      return hash;
  }

  /**
   * Thanks to Cristian Sanchez
   * @url http://stackoverflow.com/a/3426956/4185200
   *
   * @param {Integer} int
   * @return {String}
   */
  intToRGB(i) {
    const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  getInitials() {
    const { name, initialsLen, initialsSep, initialsEnd } = this.props
    return typeof name === 'string' && name.split(' ')
      .map(word => word.substr(0, 1))
      .join(initialsSep)
      .substr(0, initialsLen)
      .concat(initialsEnd)
      .toUpperCase()
  }

  render() {
    const { src, size, name, className, containerStyle, avatarStyle, initialsLen } = this.props
    const color = this.getColor(name)
    const fontSize = size / initialsLen
    const sizeCss = {
      width: size,
      height: size,
      lineHeight: (size-2) + 'px',
      fontSize,
      background: color
    }
    const initials = this.getInitials(name)
    const classString = 'user-avatar ' + (className || '')
    return (
      <div className={classString} style={{
        ...styles.avatar,
        ...sizeCss,
        ...containerStyle,
        border: src ? '1px solid #ccc' : '1px solid ' + lightenColor(color, 5)
      }}>
        {
          src ? (
            <img 
              src={src} 
              style={{
                ...sizeCss,
                ...avatarStyle,
              }} 
              alt={name}
            />
          ) : (
            initials
          )
        }
      </div>
    )
  }
}

const styles = {
  avatar: {
    borderRadius: '100%',
    border: '1px solid #dddddf',
    overflow: 'hidden',
  }
}