import React from 'react'
import './SubMenu.scss'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from '../../../theme/Button'
import { GoalListPane, HabitListPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { observer } from 'mobx-react'
import { getStore, models } from 'Stores'

export default observer(() => {

  const onClickGoals = () => {
    getStore(models.MainPaneGroup).addOrUpdateItem(GoalListPane)
  }
  const onClickHabits = () => {
    getStore(models.MainPaneGroup).addOrUpdateItem(HabitListPane)
  }
  const hasPane = pane => {
    return getStore(models.MainPaneGroup).getItemByPaneType(pane)
  }
  return (
    <ButtonGroup aria-label="Goals &amp; Habits" toggle="true">
      <Button
        variant={hasPane(GoalListPane) ? 'secondary' : 'outline-dark'}
        size="xs"
        onClick={onClickGoals}
      >
        Goals
      </Button>
      <Button
        variant={hasPane(HabitListPane) ? 'secondary' : 'outline-dark'}
        size="xs"
        onClick={onClickHabits}
      >
        Habits
      </Button>
    </ButtonGroup>
  )
})