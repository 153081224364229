import { observable } from "mobx";
import { Item } from "Stores/Lists";
import { TimespanListFlow } from "../TimespanListFlow";

export class TimespanFlow extends Item {

list: TimespanListFlow 

  @observable type = ''

  @observable duration: number = null

}