import { action, computed, observable } from "mobx";
import { dateAddMins, minsBetweenDates } from "Stores/ActionPlanner/ActionPlannerUtils";
import { CalendarEvent } from "Stores/Calendar";
import { PlannerFlow } from "../PlannerFlow";
import { TaskPieceFlow } from "../TaskPieceFlow";
import { Item } from "Stores/Lists";
import TaskItem from "Stores/Task";
import ActionPlanner from "Stores/ActionPlanner/ActionPlanner";
import { PlannerEventsFlow } from "../PlannerEventsFlow";

export type DatedItemI = (CalendarEvent | TaskItem) & {
  startDate: Date;
  endDate: Date;
}

export class DatedItemFlow extends Item {

  @observable datedItem: DatedItemI

  @observable actionPlanner: ActionPlanner = null

  @observable plannerFlow: PlannerFlow = null

  @observable eventsFlow: PlannerEventsFlow = null

  @observable leftDragOffset: number = 0

  @computed get startDate() {
    return this.datedItem.startDate
  }

  @computed get endDate() {
    return this.datedItem.endDate
  }

  @computed get startDuration(): number {
    return (this.datedItem.startDate.getTime() - this.plannerFlow.startDate.getTime()) / (1000*60)
  }

  /**
   * Move the event to the new startDate keeping it's duration
   */
  @action moveStartDate(startDate: Date) {
    const startDuration = this.plannerFlow.getMinsSinceStartOfPlanner(startDate)
    this.moveStartDuration(startDuration)
  }

  /**
   * Move the event to the new startDuration keeping it's duration
   */
  @action moveStartDuration(startDuration: number) {
    const duration = this.duration
    this.datedItem.startDate = dateAddMins(this.plannerFlow.startDate, startDuration)
    if (this.datedItem instanceof CalendarEvent) {
      this.datedItem.endDate = dateAddMins(this.datedItem.startDate, duration)
    }
  }

  /**
   * @deprecated
   */
  @action setStartDuration(startDuration: number) {
    throw new Error('Deprecated')
  }

  /**
   * Move the end duration only
   */
  @action setDuration(duration: number) {
    if (this.datedItem instanceof CalendarEvent) {
      this.datedItem.endDate = dateAddMins(this.datedItem.startDate, duration)
    } else {
      this.datedItem.duration = duration
    }
  }

  @computed get endDuration(): number {
    return (this.datedItem.endDate.getTime() - this.plannerFlow.startDate.getTime()) / (1000*60)
  }

  @computed get duration() {
    return minsBetweenDates(
      this.datedItem.startDate, 
      this.datedItem.endDate
    )
  }

  @computed get stackIndex(): number {
    return this.eventsFlow.getStackIndex(this)
  }

  @computed get onTasks(): TaskPieceFlow[] {
    return this.eventsFlow.getTaskPiecesOnDatedItem(this)
  }

  @computed get onPlanner() {
    return this.datedItem.onPlanner
  }

  @computed get deleted() {
    return this.datedItem.deleted
  }

  @action setOnPlanner(onPlanner: boolean) {
    return this.datedItem.onPlanner = onPlanner
  }
  
}