import React from 'react'
import { observer } from 'mobx-react'
import SubTaskTaskItem from './SubTaskTaskItem'
import ListTitle from './ListTitle'
import Sortable from 'Modules/ReactSortable/ReactSortable';
import { action } from 'mobx'

const debug = require('debug')('treks:task:list')

class SubTaskTaskList extends React.Component {

  static defaultProps = {
    itemHeight: 25,
    showTitle: true
  }

  findSubTaskByUid(uid) {
    return this.props.list.ModelType.fromProps({ uid })
  }

  findSubTasksByUids(uids) {
    return uids.map(uid => this.findSubTaskByUid(uid)).filter(subtask => subtask)
  }

  filterItems(items, view) {
    return items.filter(item => {
      if (!item.title) return true
      if (view === 'done') return item.done
      if (view === 'not-done') return !item.done
      return true
    })
  }

  @action onChange = order => {
    const { list } = this.props
    const items = this.findSubTasksByUids(order)
    debug('onChange', { order, items })
    list.setItems(items)
    if (list.visibleItems.length === 0 && list.isFlatList) {
      list.trash()
      list.list && list.list.removeItem(list)
    }
    list.setOrder(order)
    list.saveOrder()
  }

  onPut = (_, __, el) => {
    const { list } = this.props
    const uid = el.getAttribute('data-id')
    const isParentTask = list.task && list.task.uid === uid
    const hasTask = list.getItemByUid(uid)
    debug('onPut', { uid, isParentTask, hasTask, list })
    return isParentTask && !hasTask
  }

  render() {
    const { list, parent, view, order, itemHeight, showTitle, store } = this.props
    if (!list?.items) return null // loading
    // @todo use orderedItems and set order explicitly?
    const items = list?.visibleItems
    const viewItems = this.filterItems(items, view)
    const containerClass = 'subtask-list-container'
    const flatClass = list.isFlatList ? 'flat-list' : 'hierachical-list'
    const trashedClass = list.trashed ? 'trashed-list' : ''
    const emptyClass = viewItems.length === 0 ? 'empty-list' : ''
    const className = [containerClass, flatClass, trashedClass, emptyClass]
      .map(className => className)
      .join(' ')
    debug('render', { parent, list, items, viewItems, view, order, store })
    return (
      <div className={className} data-id={list.uid}>
        <div className="subtask-list">
          {
            showTitle && (
              <header>
                <ListTitle item={list} list={list} parent={parent} />
              </header>
            )
          }
          <Sortable 
            options={{
              animation: 100,
              fallbackOnBody: true,
              swapThreshold: 0.65,
              group: {
                name: 'subtasks',
                put: () => this.onPut
              }
            }}
            onChange={this.onChange}
          >
            {
              viewItems
                .map(item => (
                  <div 
                    key={item.uid}
                    data-id={item.uid}
                    style={{ width: '100%', height: itemHeight }}
                  >
                    <SubTaskTaskItem 
                      store={store}
                      list={list}
                      item={item}
                      parent={parent}
                    />
                  </div>
                ))
            }
          </Sortable>
        </div>
      </div>
     )
  }
}

export default observer(SubTaskTaskList)