import { PFAScoreSnap } from "Containers/PFA/models/PFAScore/PFAScoreSnapshot/PFAScoreSnap"
import { PFAScoreSnapList } from "Containers/PFA/models/PFAScore/PFAScoreSnapshot/PFAScoreSnapList"
import { getStore } from "Stores"

const transformItemsToData = (items: PFAScoreSnap[], name) => {
  return items.reduce((list, scoreSnap, index) => {
    return [
      ...list,
      {
        x: index,
        y: Math.floor(scoreSnap[name] * 100/3)
      }
    ]
  }, [])
}

export const getPfaGraphData = async (): any[] => {
  const pfaScoreSnapListStore = getStore(PFAScoreSnapList) as PFAScoreSnapList
  await pfaScoreSnapListStore.fetched()

  const dataLists = ['plan', 'focus', 'accomplish'].map(name => {
    return transformItemsToData(pfaScoreSnapListStore.items, name)
  })

  return [
    {
      "id": "Plan",
      "color": "#3881C3",
      "data": dataLists[0]
    },
    {
      "id": "Focus",
      "color": "#E7E7E8",
      "data": dataLists[1]
    },
    {
      "id": "Accomplish",
      "color": "#DCDDDE",
      "data": dataLists[2]
    }
  ]
}

export const pfaGraphDataMock = [
  {
    "id": "Plan",
    "color": "#3881C3",
    "data": [
      {
        "x": 1,
        "y": 20
      },
      {
        "x": 2,
        "y": 30
      },
      {
        "x": 3,
        "y": 20
      },
      {
        "x": 4,
        "y": 10
      },
      {
        "x": 5,
        "y": 20
      },
      {
        "x": 6,
        "y": 10
      },
      {
        "x": 7,
        "y": 20
      }
    ]
  },
  {
    "id": "Focus",
    "color": "#E7E7E8",
    "data": [
      {
        "x": 1,
        "y": 20
      },
      {
        "x": 2,
        "y": 10
      },
      {
        "x": 3,
        "y": 20
      },
      {
        "x": 4,
        "y": 30
      },
      {
        "x": 5,
        "y": 20
      },
      {
        "x": 6,
        "y": 30
      },
      {
        "x": 7,
        "y": 35
      }
    ]
  },
  {
    "id": "Accomplish",
    "color": "#DCDDDE",
    "data": [
      {
        "x": 1,
        "y": 20
      },
      {
        "x": 2,
        "y": 10
      },
      {
        "x": 3,
        "y": 30
      },
      {
        "x": 4,
        "y": 40
      },
      {
        "x": 5,
        "y": 30
      },
      {
        "x": 6,
        "y": 50
      },
      {
        "x": 7,
        "y": 40
      }
    ]
  }
]