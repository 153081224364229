import React from 'react'
import PropTypes from 'prop-types'
import Select from 'theme/Form/Select'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'

class FilterFieldCompletion extends React.Component {

  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: 'completion',
    label: 'Completion',
    value: '',
    options: [
      {
        label: 'All Tasks',
        value: 'all'
      },
      {
        label: 'Incomplete Tasks',
        value: 'incomplete'
      },
      {
        label: 'Completed Tasks',
        value: 'complete'
      },
      {
        label: 'Completed Tasks - within last 24 hours',
        value: '24hrs'
      },
      {
        label: 'Completed Tasks - within last 3 days',
        value: '3days'
      },
      {
        label: 'Completed Tasks - within last week',
        value: '1week'
      },
      {
        label: 'Completed Tasks - within last month',
        value: '1month'
      }
    ]
  }

  @observable value = ''

  constructor() {
    super()
    makeObservable(this)
  }

  componentDidMount() {
    this.value = this.props.value
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.value = this.props.value
    }
  }

  onChange = ({ label, value }) => {
    this.value = value
    const { name, onChange } = this.props
    onChange({
      name,
      label: this.props.label,
      value: { label, value }
    })
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        onChange={this.onChange}
        value={this.value}
      />
     )
  }
}

export default observer(FilterFieldCompletion)