import { observable } from "mobx";
import { once } from "Stores/utils";
// import { ListI } from 'Stores/Lists/Type/List';
import { Item } from "../Lists";
import DashboardList from "./DashboardList";

export const importDashboard = once(() => {
  class Dashboard extends Item {
    get ListType() {
      return DashboardList;
    }

    @observable name: string;
    @observable workingDashboard: boolean = true;

    async save() {
      console.log("saving dashboard");
      let result = await this.saveState.postJson(
        "dashboard/save",
        this.toJSON()
      );
      console.log("setting id from result", result);
      if (result && result.data && result.data.id) this.id = result.data.id;
    }
  }

  return Dashboard;
});
