import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import ActionPlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils'
import '../../../Components/ActionPlanner/ActionPlannerTimes/ActionPlannerTimes.scss'
import { TimespanPieceFlow } from 'Stores/ActionPlanner/PlannerFlow/TimespanPieceFlow'

const debug = require('debug')('treks:planner:times')

type PropTypes = {
  plannerUtils: ActionPlannerUtils;
  timespanPieces: TimespanPieceFlow[];
  showTimespans?: boolean;
  minutesPerRow?: number;
  showTimeLabels?: boolean;
  timeLabelInterval?: number;
  daysLength?: number;
}

class PlannerFlowTimes extends React.Component<PropTypes> {

  static defaultProps = {
    minutesPerRow: 5,
    showTimeLabels: false,
    showTimespans: false,
    timeLabelInterval: 15,
    daysLength: 1
  }

  createTimeItem = (time: number, index: number, height: number, timespan: TimespanPieceFlow) => {
    time = time % (60*24)
    const hour = Math.floor(time/60%12) || 12
    const min = time%60
    const ampm = time < 12*60 ? 'am' : 'pm'
    return { 
      id: index, hour, min, ampm, height, timespan
    }
  }

  getTimeItems() {
    const { plannerUtils, minutesPerRow, daysLength } = this.props
    const height = plannerUtils.getHeightFromDuration(minutesPerRow)

    let time = 0;
    let times = [time]

    while (time < 24*60*daysLength) {
      times.push(time += minutesPerRow)
    }

    const items = times.map((time, index) => {
      const timespan = this.getTimespanPieceAtDuration(time)
      return this.createTimeItem(time, index, height, timespan)
    })
    return items
  }

  renderTime = ({ id, hour, min, ampm, height, timespan }) => {
    const { showTimeLabels, timeLabelInterval } = this.props
    const label = hour + ':' + (min  || '00') + ' ' + ampm
    const hourClass = min === 0 ? 'on-hour' : ''
    const halfHourClass = min === 30 ? 'on-half-hour' : ''
    const timespanClass = timespan ? 'timespan-' + timespan.type : 'timespan-empty'
    return (
      <li className={`label-item ${hourClass} ${halfHourClass} ${timespanClass}`} key={id} style={{
        height,
      }}>
        { showTimeLabels && min%timeLabelInterval === 0 ? <span>{label}</span> : null }
      </li>
    )
  }

  getTimespanPieceAtDuration(duration: number) {
    let sumDuration = 0
    return this.props.timespanPieces.find(item => {
      sumDuration += item.duration
      return (sumDuration > duration)
    })
  }

  get gradientList() {
    let prevDuration = 0
    return this.props.timespanPieces.reduce((gradients, { duration, color }) => {
      const gradient = {
        prevDuration,
        currDuration: prevDuration += duration,
        color,
      }
      return [...gradients, gradient]
    }, [])
  }

  gradientCSS() {
    const { plannerUtils } = this.props
    const gradientStr = this.gradientList.map(({ color, prevDuration, currDuration }) => {
      const prevHeight = plannerUtils.getHeightFromDuration(prevDuration)
      const currHeight = plannerUtils.getHeightFromDuration(currDuration)
      return `${color} ${prevHeight}px ${currHeight}px`
    }).join(',')
    return `linear-gradient( ${gradientStr} )`
  }

  render() {
    const { showTimespans } = this.props
    const items = this.getTimeItems()
    debug('render plannerFlowTimes', this.props)
    const background = showTimespans ? this.gradientCSS() : 'transparent'
    return (
      <div className="action-planner-times" style={{
        background
      }}>
        <ul>
          {
            items.map(this.renderTime)
          }
        </ul>
      </div>
    )
  }
}

export default observer(PlannerFlowTimes)
