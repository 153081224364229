import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getHabitPointsEarnedAfterOptions } from "./stores/HabitPointsEarnedAfterOptions";
import { getHabitPointsStore } from "Containers/PFA/stores/PFAHabitPointsStore";

class HabitPointsEarnedAfterSelect extends React.Component {

  static defaultProps = {
    options: getHabitPointsEarnedAfterOptions(),
    habitPointsStore: getHabitPointsStore(),
    defaultValue: 'each'
  };

  onChange = ({ value }) => {
    this.props.habitPointsStore.setProp('earnedAfter', parseInt(value))
  }

  getValue() {
    return String(this.props.habitPointsStore.earnedAfter || this.props.defaultValue)
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
      />
    )
  }

}

export default observer(HabitPointsEarnedAfterSelect)
