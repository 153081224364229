import React from 'react'
import ColorPicker from 'theme/ColorPicker/ColorPicker'
import { observer } from 'mobx-react'
import { action } from 'mobx'

export default observer(function GoalDetailFieldColor(props) {
  const onChangeComplete = action(value => {
    const { field, goal } = props
    field.value = value.hex
    goal.save()
  })
  return (
    <ColorPicker
      className="field-color list-col"
      onChangeComplete={onChangeComplete}
      {...props}
    />
  )
})