import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalData.scss'
import { observable } from 'mobx';
import GoalDataList from './GoalDataList'
import { stores, models, getStore } from 'Stores'
import Pressable from '../../../../theme/Pressable';
import GoalDataCollect from './GoalDataCollect';
import { GoalDataSource } from 'Stores/Goal/GoalData'

const debug = require('debug')('treks:goal:data')

const BtnAddData = ({ onAddData }) => (
  <Pressable
    className="btn-add"
    onClick={onAddData}
  >
    + Add Data
  </Pressable>
)

class GoalData extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static propTypes = {
    goal: PropTypes.objectOf(() => stores.Goal).isRequired,
    account: PropTypes.objectOf(() => stores.Account),
  }

  static defaultProps = {
    get account() {
      return getStore(models.Session).Account
    }
  }

  onAddData = () => {
    const { account, goal } = this.props
    goal.data.addItem({
      owner: account,
      createdBy: account,
      value: '',
      units: goal.units,
      createdTS: new Date()
    })
    goal.save()
  }

  onDataSourceChange = ({ dataSource, dataSourceId}) => {
    const { goal } = this.props
    goal.dataSource = dataSource
    goal.dataSourceId = dataSourceId
  }

  render() {
    const { goal } = this.props
    const { dataSource, dataSourceId, data } = goal
    debug('render', { goal, dataSource, dataSourceId, data })
    return (
      <div className="goal-data">
        <header className="row tab-header">
          <Icon set="fa" icon="search-plus" />
          <div className="tab-title">Data</div>
        </header>
        <div className="goal-data-list">
          <GoalDataCollect
            goal={goal}
            dataSource={dataSource}
            dataSourceId={dataSourceId}
            onChange={this.onDataSourceChange}
          />
          {
            dataSource === GoalDataSource.goal && (
              <>
                <GoalDataList goal={goal} data={data} />
                <BtnAddData onAddData={this.onAddData} />
              </>
            )
          }
        </div>
      </div>
    )
  }
}

export default observer(GoalData)
