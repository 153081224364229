import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalPeople.scss'
import Pressable from 'theme/Pressable'
import GoalMemberItem from 'Stores/Goal/GoalMemberItem';
import DetailPeopleList from 'Components/ui/Detail/DetailPeople/DetailPeopleList';
import stores, { getStore } from 'Stores';

const debug = require('debug')('treks:goal:people')

class GoalPeople extends React.Component {

  static defaultProps = {
    get userList() {
      return getStore(stores.UserList)
    }
  }

  onAddMember = () => {
    const { goal } = this.props
    const member = GoalMemberItem.fromProps({ isCreating: true, goal })
    this.props.goal.memberList.addItem(member)
  }

  render() {
    const { goal, userList } = this.props
    debug('render', { goal })
    return (
      <div className="goal-people">
        <header className="row tab-header">
          <Icon icon="assign" size={15} />
          <div className="tab-title">PEOPLE</div>
        </header>
        <div className="goal-people-list">
          <DetailPeopleList
            item={goal}
            userList={userList}
          />
          <Pressable
            className="btn-add-member"
            onClick={this.onAddMember}
          >
            + Add Member
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(GoalPeople)
