import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:goal:activity:item')

class ActivityItem extends React.Component {

  render() {
    const { activityItem } = this.props
    const { user, name, since, titleFormatted } = activityItem
    debug('render', { activityItem })
    return (
      <div className={'activity item row-flex activity-' + name}>
        <div className="item-col item-body">
          <div className="item-attributes row-flex">
            <div className="name">{user.name}</div>
            <div className="item-text">{titleFormatted}</div>
            <div className="since">{since}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default observer(ActivityItem)
