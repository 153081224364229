import { getEntryFor, getMapFor } from "../Stores/utils/ContextMap";
import { Event, ListenerDisposer } from "../events/Event";
import { Entity } from "./Entity";
import { EntityMap } from "./EntityMap";
import { getPrivateProps, getStaticPrivateProps } from "./PrivateProps";
import { action, observable } from "mobx";
import ModelApiMiddlewareStack from "services/api/middleware/ModelApiMiddlewareStack";
import { ApiRequest, LocalStorageRequest } from "Stores/Service";

const IS_PROD = process.env.NODE_ENV === 'production'

export type PropKey = string
export type PropValue = any
export type EntityModelProps = {
  [key: string]: PropValue
}

export class EntityModel {

  get privates() {
    return getPrivateProps(this)
  }
  
  static get privates() {
    return getStaticPrivateProps(this)
  }

  static isSameModel(model1: EntityModel, model2: EntityModel) {
    return model1.getSelf() === model2.getSelf()
  }

  static create(): EntityModel {
    return new this()
  }

  static fromProps(props): EntityModel {
    return new this(props)
  }

  uid: string = String(Math.random()).slice(2, 10)

  get id() {
    return this.getEntity().id
  }

  /**
   * Settting the ID will set the entity ID or
   * Swap the entity with matching Entity in EntityMap or 
   * Create a new Entity
   */
  set id(id) {
    const currEntity = this.getEntity()
    if (id === currEntity.id) return // no-op
    const knownEntity = this.getEntityMap().get(id)
    if (knownEntity) {
      this.setEntity(knownEntity)
    } else {
      if (typeof currEntity.id !== undefined) {
        const newEntity = this.createEntity({ ...this.getProps(), id })
        this.setEntity(newEntity)
        this.getEntityMap().set(id, newEntity)
      } else {
        currEntity.id = id
        this.getEntityMap().set(id, currEntity)
      }
    }
  }

  getSelf = (): EntityModel => this

  constructor(props?: any) {
    if (props) {
      this.setProps(props)
    }
  }

  getEntityEvent(): Event {
    return this.privates.get('entityEvent', () => new Event({ name: 'entityEvent' }))
  }

  onEntityChange(fn: Function): ListenerDisposer {
    return this.getEntityEvent().add(fn)
  }

  getEntity() {
    return this.privates.get('entity', () => this.createEntity())
  }

  createEntity(props?: EntityModelProps) {
    return new Entity(props)
  }

  setEntity(nextEntity) {
    const currEntity = this.privates.get('entity')
    this.privates.set('entity', nextEntity)
    // sync newer currEntity state to nextEntity
    if (currEntity) {
      const keys = currEntity.keys()
      for (let key of keys) {
        if (key === 'id' || key === 'uid') continue 
        const currMeta = currEntity.getMeta(key)
        const nextMeta = nextEntity.getMeta(key)
        if (!nextMeta || currMeta.uid > nextMeta.uid) {
          nextEntity.set(key, currEntity.get(key))
        }
      }
    }
    this.getEntityEvent().fire(nextEntity)
  }

  static getEntityMap() {
    return this.privates.get('entityMap', () => new EntityMap(this, { isObservable: !IS_PROD }))
  }

  static getModelMap() {
    return this.privates.get('modelMap', () => new EntityMap(this, { isObservable: !IS_PROD }))
  }

  getEntityMap() {
    return (this.constructor as any).getEntityMap()
  }

  setProp(key: PropKey, value: PropValue) {
    if (!this.getPropKeys().includes(key)) {
      throw TypeError(
        `Prop "${key}" does not exist on "${this.constructor.name}"`
      )
    }
    const baseKeys = this.getBasePropKeys()
    if (baseKeys.includes(key)) {
        this[key] = value
    } else {
      this.getEntity().set(key, value)
    }
  }

  setProps(props: EntityModelProps) {
    // ensure entity is updated first
    if (props?.id) {
      this.id = props.id
    }
    // set other props
    Object.entries(props).forEach(([key, value]) => {
      this.setProp(key, value)
    })
  }

  getProps(): EntityModelProps {
    return this.getPropKeys().reduce((next, key) => {
      return { 
        ...next, 
        [key]: this.getProp(key) 
      }
    }, {})
  }

  getProp(key: string) {
    return Reflect.get(this, key, this)
  }

  static basePropKeys = ['id', 'uid']

  static getBasePropKeys() {
    return this.basePropKeys
  }

  getBasePropKeys() {
    return (this.constructor as typeof EntityModel).basePropKeys
  }

  static getPropKeys() {
    return this.privates.get('propKeys', () => [ ...this.getBasePropKeys() ])
  }

  static addPropKey(key) {
    const keys = this.getPropKeys()
    if (!keys.includes(key)) {
      keys.push(key)
    }
  }

  getPropKeys() {
    return (this.constructor as typeof EntityModel).getPropKeys()
  }

  toJSON() {
    return this.getProps()
  }

  fromJSON(json) {
    return this.setProps(json) // todo: allow non props
  }

  setJSON(json) {
    return this.fromJSON(json)
  }

  toString() {
    return JSON.stringify(this.toJSON())
  }

  getAttribute = (name: string, initFn?: Function) => {
    return getEntryFor(this.getSelf(), name, initFn)
  }

  setAttribute = (name, value) => {
    getMapFor(this.getSelf()).set(name, value)
  }

  static findModel(props: any) {
    return this.getModelMap().get(props?.id)
  }

  // ---- reactions ---- 

  intercept(key: string, fn: Function) {
    // todo
  }

  reaction(expression: Function, effect: Function) {
    // todo
  }

  // ---- API Storable ----

  get modelName() {
    return this.constructor.name
  }

  get apiMiddleware() {
    return this.getAttribute('apiMiddleware', () => new ModelApiMiddlewareStack(this))
  }
  set apiMiddleware(middleware) {
    this.setAttribute('apiMiddleware', middleware)
  }

  useApiMiddleware(fn:Function) {
    this.apiMiddleware = this.apiMiddleware.use(fn)
  }

  @observable localState:LocalStorageRequest = new LocalStorageRequest(this.modelName)

  @observable fetchState:ApiRequest = new ApiRequest()

  @action async fetch(path = null) {
    const { id } = this
    return this.fetchState.get(path || this.modelName.toLowerCase() + '/fetch/' + id)
  }

  @observable saveState:ApiRequest = new ApiRequest()

  @action async save(json = null, path = null) {
    if (!json) json = await this.toJSON()
    const payload = await this.apiMiddleware.call('save', json)
    return this.saveState.post(path || this.modelName.toLowerCase() + '/save', payload)
  }

  async saveJson(json = null, path = null) {
    if (!json) json = await this.toJSON()
    const payload = await this.apiMiddleware.call('save', json)
    return this.saveState.postJson(path || this.modelName.toLowerCase() + '/save', payload)
  }

  // ---- End API Storable ----
}