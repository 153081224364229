import React from 'react'
import { intercept, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import TaskItem from 'Stores/Task/TaskItem';
import { uid } from 'Stores/utils';
import stores, { getStore } from 'Stores';
import Tooltip from '../../theme/Tooltip';

const debug = require('debug')('treks:task:title')

class TaskTitle extends React.Component {

  static propTypes = {
    item: PropTypes.objectOf(() => TaskItem),
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false,
    get sessionStore() {
      return getStore(stores.Session)
    },
    busyWaitMs: 5000,
    busyMsg: 'Another user is typing...'
  }

  @observable isBusy = null
  isBusyTimer = null

  inputRef = null

  constructor(props) {
    super()
    makeObservable(this)
    const { item, sessionStore, busyWaitMs } = props
    debug('component created', item)
    this.disposeFocus = intercept(item, 'focusOnTitle', change => {
      if (change.newValue === true) {
        debug('focus on title', item, this.inputRef)
        ;[1, 2, 3].forEach(i => setTimeout(() => {
          if (this.inputRef) {
            this.inputRef.focus()
            if (this.inputRef.scrollIntoView) {
              this.inputRef.scrollIntoView({ block: 'center' })
            }
          }
        }, i * 100))
      }
      return null
    })
  }

  componentWillUnmount() {
    this.disposeFocus()
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    const { item, sessionStore } = this.props
    debug('title changed', event)
    item.setUpdateTokenUid(sessionStore.tokenUid)
    item.setTitle(event.target.value)
  }

  /**
   * @todo move to TaskItem
   */
  onKeyDown = event => {
    const { item, parent } = this.props
    const { value } = event.target
    const isFlatList = !(event.metaKey || event.ctrlKey)
    debug('keyDown', item, event.nativeTask)
    if (event.key === 'Enter') {
      if (value) {
        if (!isFlatList) {
          this.addEmptyList({ isFlatList })
        } else {
          this.addEmptyItem()
        }
      }
    }
    if (event.key === 'Backspace' && !item.title && !this.props.isPlannerTask) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
    // backspace + (CMD key or CTRL key)
    if (event.key === 'Backspace' && (event.metaKey || event.ctrlKey)) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
    if (parent&& event.key === ':' && this.isCaretAtEndOfInput(event.target)) {
      event.preventDefault()
      const { title } = item
      item.setTitle('')
      this.addEmptyList({ title })
    }
  }

  isCaretAtEndOfInput(input) {
    return this.getCaretPosition(input) === input.value.length
  }

  getCaretPosition(input) {
    if (document.selection) {
      input.focus()
      var oSel = document.selection.createRange()
      oSel.moveStart('character', -input.value.length)
      return oSel.text.length;
    }
    else if (input.selectionStart || input.selectionStart === '0') {
      return input.selectionStart
    }
      
  }

  addEmptyList(props) {
    const { parent } = this.props
    const emptyList = parent.addItem(this.createEmptyList(props))
    if (!props.isFlatList) {
      emptyList.setFocusOnTitle()
    } else {
      const item = emptyList.addItem(this.createEmptyItem())
      item.setFocusOnTitle()
    }
  }

  createEmptyList(props) {
    const { item } = this.props
    const list = {
      id: uid(),
      title: '',
      items: [],
      createdFromTask: item,
      ...props
    }
    debug('createEmptyList', list)
    return list
  }

  addEmptyItem() {
    const { list } = this.props
    const { focusedItem } = list
    const { item:focusedTask } = focusedItem || {}
    let task
    if (focusedTask) {
      task = list.addEmptyItemAfterTask(focusedTask)
    } else {
      task = list.addEmptyItem()
    }
    const index = list.getItemIndex(task)
    debug('addEmptyItem', { task, index })
    task.setFocusOnTitle()
  }

  removeItem() {
    this.props.item.trash()
  }

  focusOnPrevOrNextItemTitle() {
    const { list, item } = this.props
    const targetItem = list.getPrevItem(item) || list.getNextItem(item)
    targetItem ? targetItem.setFocusOnTitle() : list.setFocusOnTitle()
  }

  onFocus = event => {
    const { item, onFocus } = this.props
    onFocus && onFocus(event, item)
  }

  onBlur = event => {
    const { item, onBlur } = this.props
    onBlur && onBlur(event, item)
  }

  render() {
    const { item, disabled, busyMsg } = this.props
    debug('render', item)
    const tooltipStyle = { 
      left: 'calc( 50% - 100px )',
      bottom: -20
    }
    return (
      <>
        <input 
          ref={this.onInputRef}
          className={"title title-input input-borderless " + (item.done ? 'done' : '')} 
          value={ item.title } 
          onChange={this.onChange} 
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled || this.isBusy ? 'disabled' : ''}
        />
        {
          this.isBusy
            ? (
              <Tooltip placement="bottom" tooltipStyle={tooltipStyle}>
                {busyMsg}
              </Tooltip>
            ) : null
        }
      </>
    )
  }
}

export default observer(TaskTitle)