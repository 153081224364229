import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { importGridItem } from 'Stores/Dashboard/GridItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Icon from 'theme/FontAwesomeIcon/FontAwesomeIcon';
import loadable from '@loadable/component';

const WidgetPreview = ({ w, k, widgetData, idx, selected, select }) => {
	const [RealComponent, setRealComponent] = React.useState(null);
	const [init, setInit] = React.useState(false);
	const { data } = widgetData;
	useEffect(() => {
		if (init) return;

		setRealComponent(loadable(() => import(`./Widgets/${k}.js`)));
		setInit(true);
	}, [init, k]);

	return (
		<div
			className={'widget-thumbnail ' + (selected === idx ? 'widget-preview-selected' : '')}
			key={'widgetoption' + idx}
			onClick={() => {
				select(idx);
			}}
		>
			<div className={'widget-preview '}>{RealComponent ? <RealComponent data={data} /> : <></>}</div>
			<div className="widget-name">{w.info.name}</div>
		</div>
	);
};

class AddWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            PreviewComponent: null,
        };
    }
    static propTypes = {
        entry: PropTypes.objectOf(() => importGridItem()).isRequired,
    };

    componentDidMount() {
        const { availableWidgets } = this.props;
        let defaultIndex = 0;
        this.setState({
            selected: defaultIndex,
            PreviewComponent: loadable(() => import(`./Widgets/${Object.keys(availableWidgets)[defaultIndex]}.js`)),
        });
    }

    render() {
        const { selected, PreviewComponent } = this.state;
        const { type, add, close, availableWidgets } = this.props;

        

        let filtered = Object.values(availableWidgets).filter((w) => type === w.dataType || !w.dataType)
        let s = filtered[selected]

        return (
            <>
                <Modal.Header>
                    <Modal.Title>Add a {type} widget</Modal.Title>{' '}
                    <Button
                        variant="outline"
                        onMouseDown={() => {
                            close();
                        }}
                        className="modal-close-button"
                    >
                        <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {' '}
                    <div className="widget-browser">
                        {filtered.map((k, idx) => {
                            return (
                                <WidgetPreview
                                    w={k}
                                    k={k.info.widgetType}
                                    widgetData={k}
                                    key={'widgetOption' + idx}
                                    idx={idx}
                                    selected={selected}
                                    select={(idx) => {
                                        this.setState({
                                            selected: idx,
                                            PreviewComponent: loadable(() =>
                                                import(`./Widgets/${k.info.widgetType}.js`)
                                            ),
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="widget-detail">
                        <div className="preview">
                            <div className="widget-display">
                                {PreviewComponent ? <PreviewComponent data={s && s.data} /> : <></>}
                            </div>
                        </div>
                        <div className="description">
                            {s && s.info ? (
                                <>
                                    {s.info.name} {s.info.w}x{s.info.h}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            add(s);
                        }}
                    >
                        Add
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </>
        );
    }
}

export default observer(AddWidget)
