import React from "react";
import { observer } from "mobx-react";
import ThrashGraph from "./ThrashGraph";
import data from "./data";
import { ProjectItem } from "Stores/Project";

const debug = require("debug")("treks:project:metrics:thrash");

class ProjectMetricThrash extends React.Component<{
  project: ProjectItem;
  data: any;
}, {
  data: any
}> {

  static defaultProps = {
    data,
  };

  componentDidMount() {
    this.setThrashData();
  }

  async setThrashData() {
    const { thrash } = this.props.project;
    await thrash.fetch();
    const data = this.getActivityData(thrash);
    this.setState({
      data: {
        ...this.props.data[0],
        data: data,
      },
    });
  }

  getActivityData(activityList) {
    const { weeksThrash } = activityList
    const data = weeksThrash.map((week, index) => {
      return {
        x: index + 1,
        y: week.thrash,
      };
    });
    return data;
  }

  render() {
    const data = this.state?.data ? [this.state.data] : this.props.data;
    debug("render", data);
    return (
      <div className="metrics-graph-container metrics-graph-thrash">
        <div className="metrics-graph">
          <ThrashGraph data={data} />
        </div>
      </div>
    );
  }
}

export default observer(ProjectMetricThrash);
