import React from 'react'
import Icon from 'theme/Icon'
import Pressable from 'theme/Pressable'

export const CollapsableIcon = ({ isCollapsed, onPress }) => {
  return (
    <Pressable onClick={onPress} className="btn-collapse">
      <Icon
        set="fa"
        icon={isCollapsed ? 'chevron-down' : 'chevron-up'}
        className="icon-collapse"
      />
    </Pressable>
  )
}