import { action, computed, observable, override } from "mobx";
import Model from "Stores/Model";
import { dayTimespans } from "./data/dayTimespans";
import { TimespanListFlow } from "./TimespanListFlow";
import { ApiRequest } from "Stores/Service";
import { TimespanPieceFlow } from "./TimespanPieceFlow";
import { PlannerFlow } from "./PlannerFlow";
import { AccountTimespanList } from "./Timespan/AccountTimespanList";

const debug = require('debug')('treks:model:DayFlow')

export class DayFlow extends Model {

  @observable locale: string = 'en'

  @observable plannerFlow: PlannerFlow = null

  @computed get accountTimespans(): AccountTimespanList {
    return this.plannerFlow.accountTimespans
  }

  @observable dayDate: Date = null

  @computed get dayTimestamp() {
    return this.dayDate?.getTime()
  }

  @computed get dayId(): number {
    return this.dayDate?.getDay()
  }

  @computed get dayIndex(): number {
    return this.plannerFlow.dayList.items.findIndex(day => day.dayDate === this.dayDate)
  }

  @computed get title(): string {
    return this.dayDate?.toLocaleDateString(this.locale, { weekday: 'long' })
  }

  @observable timespanList: TimespanListFlow = TimespanListFlow.fromProps({
    day: this,
    items: dayTimespans
  }) as TimespanListFlow

  @computed get dayTimespanPiecesFlow(): TimespanPieceFlow[] {
    const dayMins = 24 * 60
    const startDuration = this.dayIndex * dayMins
    const endDuration = startDuration + dayMins
    let currStartDuration = 0, currendDuration = 0
    const timespanPieces = this.plannerFlow.timespanFlowSplitAtDay.filter((piece: TimespanPieceFlow) => {
      currStartDuration = currendDuration
      currendDuration += piece.duration
      const isInDay = 
        ((currStartDuration >= startDuration  && currStartDuration < endDuration) ||
        (currendDuration > startDuration  && currendDuration <= endDuration))
        && piece.duration > 0
      return isInDay
    })
    return timespanPieces
  }

  /**
   * Fetches date settings or dayId settings
   * @note same as calling fetch() || fetchDaySettings() but server side
   */
  @override async fetch() {
    const timestamp = this.dayTimestamp
    await this.plannerFlow.accountTimespans.fetch()
    return this.fetchState.get('account/settings/day/' + timestamp)
      .then(resp => {
        if (resp.data.timespans) {
          this.timespanList.setItems(resp.data.timespans)
        }
        if (resp.data.order) {
          this.timespanList.setOrder(resp.data.order)
        }
        return resp
      })
  }

  async save() {
    debug('save day by date', this)
    const timestamp = this.dayTimestamp
    const timespans = this.dayTimespanPiecesFlow.map(piece => ({
      type: piece.type,
      duration: piece.duration
    }))
    const order = []
    return this.saveState.postJson(`account/settings/day/${timestamp}/save`, {
      timespans,
      order
    })
  }

  @observable daySettingsFetchState = new ApiRequest()
  @action async fetchDaySettings() {
    await this.plannerFlow.accountTimespans.fetched()
    return this.daySettingsFetchState.get('account/settings/timespans/day/' + this.dayId)
      .then(resp => {
        if (resp.data.timespans) {
          this.timespanList.setItems(resp.data.timespans)
        }
        if (resp.data.order) {
          this.timespanList.setOrder(resp.data.order)
        }
        return resp
      })
  }

  @observable daySettingsSaveState = new ApiRequest()
  async saveDaySettings() {
    debug('save day settings', this)
    const timespans = this.dayTimespanPiecesFlow.map(piece => ({
      type: piece.type,
      duration: piece.duration
    }))
    const order = []
    return this.daySettingsSaveState.postJson(`account/settings/timespans/day/${this.dayId}/save`, {
      timespans,
      order
    })
  }
  

}