import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import PopOver from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import { getAppNotifications } from '../../AppNotifications'
import './DatePicker.scss'
import Pressable from 'theme/Pressable'
import Icon from 'theme/Icon'
import stores, { getStore } from 'Stores'
import DatePicker from 'Modules/DatePicker'

const debug = require('debug')('treks:theme:date:picker')

export default observer(class DatePickerCom extends React.Component {

  inputRef = null
  containerRef = null

  static defaultProps = {
    iconClearDate: 'times',
    iconClearDateColor: '#808285',
    dateLabel: 'Set Date',
    timeLabel: 'Time',
    addTimeLabel: 'Add Time',
    get planner() {
      return getStore(stores.ActionPlanner)
    }
  }

  state = {
    showTooltip: false,
    inputValue: null,
    showDueTime: false
  }

  hideTooltip() {
    this.setState({ showTooltip: false })
  }

  setInputValue(inputValue) {
    this.setState({ inputValue })
  }

  componentDidUpdate() {
    if (this.state.showTooltip) {
      document.addEventListener('click', this.onClickApp)
    } else {
      document.removeEventListener('click', this.onClickApp)
    }
  }

  onClickApp = event => {
    if (!event.path.includes(this.containerRef) 
      && !event.path.includes(document.querySelector('.bs-popover-bottom'))) {
      debug('path', event.path)
      this.hideTooltip()
    }
  }

  onInputRef = ref => this.inputRef = ref

  onInputChange = event => {
    this.setInputValue(event.target.value)
  }

  onInputFocus = () => {
    this.setState({ showTooltip: true })
  }

  onInputBlur = () => {
    const date = moment(this.state.inputValue)
    if (this.state.inputValue && !date.isValid()) {
      return getAppNotifications().warn({
        message: 'Please enter a valid date',
      })
    }
    this.props.onChange(date.toDate())
  }

  onChangeDate = newDate => {
    const { date } = this.props
    const time = moment(date).isValid() 
      ? this.getTimeInMinutesFromDate(date) : 0
    debug('due date changed', newDate, time, date)
    newDate = new Date(newDate.setHours(0, time))
    this.onChange(newDate)
  }

  onChangeTime = date => this.onChange(date)

  onChange = date => {
    this.syncdateAndInputValue(date)
    debug('due date or time changed', date)
    this.hideTooltip()
  }

  syncdateAndInputValue(date) {
    this.props.onChange(date)
    this.setInputValue(this.formatDateTime(date))
  }

  // @todo implement in UI
  cleardateAndInputValue() {
    this.props.onChange(null)
    this.setInputValue('')
  }

  onClickContainer = event => {
    debug('clicked', event)
    event.stopPropagation()
    event.preventDefault()
  }

  onContainerRef = ref => {
    this.containerRef = ref
  }

  onShowDueTimePicker = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showDueTime : true })
  }

  onHideDueTimePicker = event => {
    const { date } = this.props.item
    event.preventDefault()
    event.stopPropagation()
    if (this.isDateValid(date)) {
      this.syncdateAndInputValue(this.removeTimeFromDate(date))
    }
    this.setState({ showDueTime : false })
  }

  removeTimeFromDate(date) {
    return new Date(date.setHours(0, 0, 0, 0))
  }

  isDateValid(date) {
    return moment(date).isValid()
  }

  onClickClearDate = event => {
    event.preventDefault()
    event.stopPropagation()
    this.cleardateAndInputValue()
  }

  formatDateTime(date) {
    return this.props.planner.formatDateTime(date)
  }

  getTimeInMinutesFromDate(date) {
    return this.props.planner.getTimeInMinutesFromDate(date) || 0
  }

  render() {
    const { date, iconClearDate, iconClearDateColor, dateLabel, timeLabel, addTimeLabel } = this.props
    const dateIsValid = this.isDateValid(date)
    const { showDueTime } = this.state
    const dateFormatted = dateIsValid ? this.formatDateTime(date) : ''
    debug('render', { date })
    return (
      <div className="date-picker" onClick={this.onClickContainer} ref={this.onContainerRef}>
        <input
          ref={this.onInputRef}
          className="date-input input-borderless"
          value={this.state.inputValue !== null ? this.state.inputValue : dateFormatted}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          placeholder={dateLabel}
        />
        {
          date && (
            <Pressable onClick={this.onClickClearDate} className="row-icon clear-date-icon">
              <Icon set="fa" name={iconClearDate} color={iconClearDateColor} />
            </Pressable>
          )
        }
        <Overlay target={this.inputRef} show={!!this.state.showTooltip} placement="bottom">
          {props => (
            <PopOver {...props} show="true">
              <DatePicker
                selected={dateIsValid ? date : new Date()}
                onChange={this.onChangeDate}
                inline
              />
              {
                showDueTime ?
                <div className="time-picker">
                  <h6 className="time-label">{timeLabel}</h6>
                  <DatePicker
                    selected={moment(date).isValid() ? date : new Date()}
                    onChange={this.onChangeTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                  <Pressable onClick={this.onHideDueTimePicker} className="btn-close">
                    x
                  </Pressable>
                </div> : 
                <Pressable onClick={this.onShowDueTimePicker}>{addTimeLabel}</Pressable>
              }
            </PopOver>
          )}
        </Overlay>
      </div>
    )
  }
})