import { observable, action, computed } from 'mobx';
import TaskItem from './TaskItem'
import FocusedItem from 'Stores/ActionPlanner/FocusedItem'
import { ItemPropsI } from 'Stores/Lists/Type/Item';
import { hasGlobal, hasMany, hasOne } from 'Relationships/RelationshipDecorators';
import { models } from 'Stores';
import { ProjectItem } from 'Stores/Project';
import { GoalItem } from 'Stores/Goal';
import { TaskLabel } from './Label/TaskLabel';
import { CategoryItem } from 'Stores/Category';
import { PagedOrdredList } from 'Stores/Lists/PagedOrderedList';

const debug = require('debug')('treks:store:task:list')

/**
 * Task List
 * @note shared between ActionPlanner and Task SubTasks
 */
export default class TaskList extends PagedOrdredList {

  get ModelType() {
    return models.TaskItem
  }

  @observable isFlatList: boolean = false

  @observable title: string = ''

  @observable focusOnTitle: boolean = false

  // task: TaskItem
  category: CategoryItem
  project: ProjectItem
  goal: GoalItem
  label: TaskLabel

  noProject: boolean = false
  noGoal: boolean = false
  noCategory: boolean = false

  // @todo blocking rel should be here?
  // @hasMany(() => TaskList, (task: TaskItem) => task.blockedByList)
  // blockingList: TaskList

  // @hasMany(() => TaskList, (task: TaskItem) => task.blockingList)
  // blockedByList: TaskList

  get noProjectItems() {
    return this.visibleIncompleteItems.filter(task => !task.project?.id)
  }

  get noCategoryItems() {
    return this.visibleIncompleteItems.filter(task => !task.category?.id)
  }

  get visibleIncompleteItems() {
    return this.visibleItems.filter(task => {
      return !task.done && !['planning', 'complete'].includes(task.project?.status)
    })
  }

  /**
   * Task that created initiated creation of list through keypress etc.
   */
	@hasOne(() => TaskItem)
  createdFromTask: TaskItem

  @observable changed: boolean = false

  @computed get isDeletable(): boolean {
    return !this.items.length
  }

  @observable trashedItems: Array<TaskItem> = []

  // @todo save() remove
  @observable isAutoSaved: boolean = false

  @observable trashed: boolean = false

  @action trash() {
    this.trashed = true
  }

	@hasGlobal(() => FocusedItem)
  focusedItem: FocusedItem

  @action setFocusedItem(item: TaskItem) {
    this.focusedItem.setFocusedItem(item)
  }

  @computed get duration(): number {
    return this.visibleItems
      .map(({ duration }) => duration)
      .reduce((total, duration) => total + duration, 0)
  }

  @computed get durationDone(): number {
    return this.items
      .filter(({ done }) => done)
      .map(({ duration }) => duration)
      .reduce((total, duration) => total + duration, 0)
  }

  @computed get durationNotDone(): number {
    return this.duration - this.durationDone
  }

  @computed get done(): boolean {
    return this.items.length && !this.items.find(item => !item.done)
  }

  @action findFirstItemWithEmptyTitle() {
    return this.items.find(item => !item.title && item.onPlanner && !item.done && !item.trashed)
  }

  @action setFocusOnTitle() {
    window.setTimeout(() => this.focusOnTitle = true)
  }

  @action setTitle(title: string) {
    this.title = title
  }

  @action setChanged(status: boolean = true) {
    this.changed = status
  }

  @action addEmptyItem(props?: any, index?: number): TaskItem {
    const item = this.addItem(this.createEmptyItemProps(props), index)
    debug('addEmptyItem', { item, index })
    return item as TaskItem
  }

  @action addEmptyItemAfterTask(task: TaskItem, props?: any): TaskItem {
    const index = this.getItemIndex(task)
    const itemProps = this.createEmptyItemProps(props)
    const item = this.addItem({
      ...itemProps,
      timespanType: task.timespanType
    }, index + 1)
    debug('addEmptyItemAfterTask', { item, index })
    return item as TaskItem
  }

  /**
   * Add an empty item after focused task or at last position
   */
  @action addEmptyItemAfterFocusedTask(props?: any): TaskItem {
    const focusedTask = this.focusedItem?.item
    if (focusedTask) {
      return this.addEmptyItemAfterTask(focusedTask, props)
    } else {
      return this.addEmptyItem(props)
    }
  }

  // @note overriden by ActionPlanner
  createEmptyItemProps(props?: any): ItemPropsI<this> {
    debug('createEmptyItemProps', props)
    const item = {
      title: '',
      duration: 15,
      onPlanner: true,
      ...props
    }
    debug('createEmptyItemProps', { item, props })
    return item
  }

  @action async toJSON() {
    const items = await Promise.all(this.items.map(item => item.toJSON()))
    const { title, isFlatList, order } = this
    return { title, isFlatList, items, order }
  }

  @computed get parent(): ProjectItem | GoalItem | CategoryItem | TaskLabel {
    return this.project || this.goal || this.category || this.label
  }

  @computed get orderUrl() {
    const url = 'task/list/' + this.parent?.modelName + '/' + this.parent?.id
    debug('orderUrl', url, { model: this, parent: this.parent, parendId: this.parent?.id })
    return url
  }

  async getSavedOrder(): Promise<string[]> {
    const order = await this.localState.get(this.orderUrl)
    return order as string[] || []
  }

  async fetchOrder(): Promise<this> {
    throw new Error('fetchOrder is deprecated. Use fetch() which returns order')
  }

  async saveOrder() {
    const order = this.getOrder()
    debug('save order', this, { order })
    await this.saveState.postJson('task/order/save', {
      order,
      isPlanner: false,
      projectId: this.category?.project?.id,
      goalId: this.goal?.id,
      categoryId: this.category?.id,
      labelId: this.label?.id
    })
    return this
  }

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('task/list/paged', {
      start,
      count,
    })
  }

  async fetch() {
    const json = await this.fetchState.get('task/list')
    this.fromJSON(json.data)
    return this
  }

  async save() {
    await this.saveOrder()
    return this
  }

}