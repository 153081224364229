import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Sortable from 'Modules/ReactSortable/ReactSortable';
import { action, computed, makeObservable, observable } from 'mobx'
import { AddItemBtn } from 'Components/ui/AddItemBtn'
import { ListFilter } from '../Model/ListFilter'
import './ListViewFlatList.scss'
import ListViewFlatTaskItem from './ListViewFlatTaskItem';
import { stores } from 'Stores';

const debug = require('debug')('treks:list:task:list')

class ListViewTaskList extends React.Component {

  static propTypes = {
    tasks: PropTypes.array.isRequired,
    // default List must be a parent of tasks
    taskList: PropTypes.objectOf(() => stores.TaskList).isRequired
  }

  @action onChange = order => {
    debug('onChange', order)
    return false
  }

  @observable isCollapsed = false
  onPressCollapsable = () => {
    this.isCollapsed = !this.isCollapsed
  }

  listFilter = new ListFilter()
  
  @computed get filteredItems() {
    const { tasks, filter } = this.props
    return this.listFilter.getFilteredItems(tasks, filter)
  }

  constructor() {
    super()
    makeObservable(this)
  }

  onPut = () => {
    return false
  }

  onAddTask = () => {
    this.props.taskList.addItem({ duration: 15 }).setFocusOnTitle()
  }

  render() {
    const { tasks, filter, fields, order, className } = this.props
    const { filteredItems } = this
    const collapseClass = this.isCollapsed ? ' collapsed' : ''
    debug('render', { tasks, filteredItems, filter, order })
    return (
      <div className={'task-list-container ' + className}>
        <div className={'task-list task-list-flat'}>
          <Sortable 
            className={'task-list-body' + collapseClass}
            options={{
              animation: 100,
              fallbackOnBody: true,
              swapThreshold: 0.65,
              group: {
                name: 'subtasks',
                put: () => this.onPut
              }
            }}
            onChange={this.onChange}
          >
            {
              filteredItems.map(item => (
                <div 
                  key={item.uid}
                  data-id={item.uid}
                >
                  <ListViewFlatTaskItem 
                    list={item.list}
                    item={item}
                    parent={item.parent}
                    category={item.category}
                    fields={fields}
                  />
                </div>
              ))
            }
          </Sortable>
        </div>
        <footer className="add-item-btns">
          <AddItemBtn
            label={'+ Add Task'}
            onClick={this.onAddTask}
            className={'btn-add-task'}
            tooltipTitle={'Enter'}
          />
        </footer>
      </div>
     )
  }
}

export default observer(ListViewTaskList)