import { action } from 'mobx'
import List from '../Lists/List'
import NotificationItem from './NotificationItem'
import Position from 'Stores/App/AppToaster/Position'
import Intent from 'Stores/App/AppToaster/Intent'

/**
 * UI Notification List
 */
export default class NotificationList extends List {

  types = Intent
  positions = Position

  get ModelType() {
    return NotificationItem
  }
  
  /**
   * Get list of visible notifications
   */
  get visibleItems() {
    return this.items.map(item => item.visible)
  }

  /**
   * Show a notification
   * @param {Notification} item 
   */
  @action show(item) {
    const notification = this.addItem(item)
    notification.show()
    return notification
  }

}
