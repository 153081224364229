import React from 'react'
import { observer } from 'mobx-react'
import Select from '../../theme/Form/Select'
import { getStore } from 'Stores'
import { getAppNotifications } from 'AppNotifications'
import { LabelGroupList } from 'Stores/Task/Label/LabelGroupList'

const debug = require('debug')('treks:task:category-select')

class TaskLabelSelect extends React.Component {

  static defaultProps = {
    labelGroupList: getStore(LabelGroupList), // options is the global labelGroupList
    selectPlaceholder: 'Select Tags',
    saveErrMsg: 'Failed to save task tags',
    fetchErrMsg: 'Failed to fetch tags'
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { fetchErrMsg, labelGroupList } = this.props
    labelGroupList.fetched()
      .catch(error => {
        getAppNotifications().error(fetchErrMsg)
        console.error(error)
      })
  }

  onChange = options => {
    const { item } = this.props
    const ids = options.map(option => String(option.value))
    const labels = this.getLabels().filter(label => ids.includes(String(label.id)))
    item.taskLabelList.setItems(labels)
    item.save()
    debug('set item tags', { item, options, labels })
  }

  onCreateOption = async (title) => {
    const { item, labelGroupList, saveErrMsg } = this.props
    const group = labelGroupList.items[1] // custom labels
    console.log('onCreateOption', { item, title, labelGroupList, group })
    const taskLabel = item.taskLabelList.addItem({
      title, 
      group
    })
    try {
      await taskLabel.save() // get id
      await item.save() // tasks saves tags by id
    } catch(err) {
      console.error('Save error', err)
      getAppNotifications().error(saveErrMsg)
    }
    debug('onCreateOption', { item, taskLabel })
  }

  getLabels() {
    const { labelGroupList } = this.props
    const labels = labelGroupList.items.map(item => item.labelList.items).flat()
    return labels
  }

  getOptions() {
    const labels = this.getLabels()
    const options = labels.map(({ title, id }) => ({ 
      label: title, 
      value: id 
    }))
    return options
  }

  getVisibleOptions() {
    const options = this.getOptions()
    const values = this.getValues()
    const valuesIds = values.map(value => String(value.value))
    const visibleOptions = options.filter(option => !valuesIds.includes(String(option.value)))
    return visibleOptions
  }

  getValues() {
    const taskLabels = this.props.item.taskLabelList.items
    const values = taskLabels.map(({ title, id }) => ({ 
      label: title, 
      value: id 
    }))
    return values
  }
  
  render() {
    const { item, selectPlaceholder } = this.props
    const { category } = item
    const options = this.getVisibleOptions()
    const value = this.getValues()
    debug('render', { item, category, options, value })
    return (
      <Select 
        isClearable
        isMulti
        hasAddOption
        createOptionPosition={'first'}
        placeholder={selectPlaceholder}
        onChange={this.onChange}
        onCreateOption={this.onCreateOption}
        options={options} 
        value={value}
      /> 
    )
  }
}

export default observer(TaskLabelSelect)