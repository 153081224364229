import React from 'react'
import { observer } from 'mobx-react'
import ProjectMeetingItem from './ProjectMeetingItem'
import PropTypes from 'prop-types'
import MeetingList from 'Stores/Meeting/MeetingList'

class ProjectMeetingList extends React.Component {

  static propTypes = {
    meetings: PropTypes.objectOf(() => MeetingList).isRequired,
  }

  render() {
    const { meetings } = this.props
    return (
      <div className="list-view meeting-list">
        {meetings.items.map(meeting => (
          <ProjectMeetingItem key={meeting.id} meeting={meeting} />
        ))}
      </div>
    )
  }
}

export default observer(ProjectMeetingList)
