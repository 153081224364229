import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectSprints.scss'
import { observable } from 'mobx';
import ProjectSprintList from './ProjectSprintList'
import SprintList from 'Stores/Sprint/SprintList'
import DoneFilter from '../../../../theme/List/DoneFilter'
import Pressable from '../../../../theme/Pressable';
import { getStore, models } from 'Stores';

const debug = require('debug')('treks:project:sprints')

class ProjectSprints extends React.Component {

  store = observable({
    view: localStorage.getItem('project.sprints.view') || 'all',
    order: null
  })

  onViewChange = view => {
    this.store.view = view
    localStorage.setItem('project.sprints.view', view)
  }

  static defaultProps = {
    sprints: SprintList.create(),
    get account() {
      return getStore(models.Session).Account
    }
  }

  async componentDidMount() {
    this.fetchSprints()
  }

  async componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.fetchSprints()
    }
  }

  async fetchSprints() {
    const { project, sprints } = this.props
    sprints.setProps({ project })
    return sprints.fetch()
  }

  addSprint = async item => {
    debug('adding sprint', item)
    const { sprints } = this.props
    const newSprint = sprints.addItem(item)
    return newSprint.save()
  }

  onAddSprint = () => {
    this.addSprint(this.createNewSprintProps())
  }

  createNewSprintProps() {
    const { project, account } = this.props
    return {
      project,
      sprintStartDate: new Date(),
      sprintEndDate: new Date(),
      taskCount: 0,
      taskDoneCount: 0,
      taskTargetPercent: 0,
      createdBy: account,
      recurring: false
    }
  }

  render() {
    const { project, sprints } = this.props
    const { view, order } = this.store
    debug('render', { project, sprints, view, order })
    return (
      <div className="project-sprints">
        <header className="row tab-header">
          <Icon icon="time_zone" size={15} />
          <div className="tab-title">Sprints</div>
          <DoneFilter view={view} onChange={this.onViewChange} />
        </header>
        <div className="project-sprints-list">
          <ProjectSprintList project={project} sprints={sprints} />
          <Pressable
            className="btn-add-sprint"
            onClick={this.onAddSprint}
          >
            + Add Sprint
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(ProjectSprints)
