
import utils from './'
import { toJS } from 'mobx'
import List from 'Stores/Lists'
import Model from 'Stores/Model'

const debug = require('debug')('treks:debug')

const toShape = obj => {
  let shape
  if (obj instanceof List) {
    shape = { ...obj, items: obj.items.map(item => toShape(item)) }
  } else if (obj instanceof Model) {
    shape = toJS(obj)
  } else if (Array.isArray(obj)) {
    shape = obj.map(item => toShape(item))
  } else {
    shape = toJS(obj)
  }
  return shape
}

const toJSON = obj => {
  let json
  if (obj instanceof Model) {
    json = obj.toJSON()
    json = Object.entries(json).reduce((obj, [key, val]) => ({ ...obj, [key]: toJS(val) }), {})
  } else if (Array.isArray(obj)) {
    json = obj.map(item => toJSON(item))
  } else {
    json = toJS(obj)
  }
  return json
}

export async function registerGlobalDebugUtils() {
  global.assert = await import('assert')
  global.utils = utils
  global.toJS = toJS
  global.mobx = await import('mobx')
  global.mobxReact = await import('mobx-react')
  global.debug = debug
  global.toShape = toShape
  global.toJSON = toJSON
  global.env = process.env
}

export const debugTimer = require('debug')('treks:timer')
