import OrderedList from "Stores/Lists/OrderedList"
import { LabelGroup } from "./LabelGroup"
import { override } from "mobx"

const debug = require('debug')('treks:model:LabelGroupList')

export class LabelGroupList extends OrderedList {

  get ModelType() {
    return LabelGroup
  }

  @override async fetch() {
    const resp = await this.fetchState.get('task/label/group/list')
    debug('LabelGroupList fetch', { resp })
    const items = resp.data.items?.map(item => {
      item.labelList = {
        title: item.title,
        uid: item.uid,
        items: item.items.map(label => {
          return {
            ...label,
            taskList: {
              total: label._count.TaskLabel
            }
          }
        })
      }
      delete item.items
      return item
    })
    return this.fromJSON({ items })
  }

}