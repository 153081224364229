import React from 'react'
import { observer } from 'mobx-react'
import { getStore, stores } from 'Stores'
import './TaskDetailFooter.scss'
import PressableIcon from '../../theme/Pressable/PressableIcon';
import { ProjectItem } from 'Stores/Project';
import Clipboard from 'clipboard'
import AppConfig from '../../Config/AppConfig'
import { getAppNotifications, Position } from '../../AppNotifications';
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import TaskToEvent from 'Containers/ActionPlanner/TaskToEvent';
import ActionPlanner from 'Stores/ActionPlanner';
import { getDayPlanner } from 'Stores/ActionPlanner/Planner/DayPlanner';

const debug = require('debug')('treks:task:footer')

class TaskDetailFooter extends React.Component {

  static defaultProps = {
    actionPlannerStore: getStore(models.ActionPlanner),
    plannerFlow: getDayPlanner().plannerFlow,
    size: { 
      width: '100%'
    },
    get task() {
      return getStore(stores.ActionPlanner).focusedItem?.item
    },
    trashTaskTitle: 'Delete task',
    printTaskTitle: 'Print task',
    onConvertToEventTitle: 'Convert to event',
    convertToProjectTitle: 'Convert to project',
    copyUrlTitle: 'Copy URL'
  }

  uiState = ArbitraryModel.fromProps({
    redirectToCalendarSettings: false,
    calendarEvent: null,
    showCalendarModal: false,
    selectedTask: null,
    selectedCaledar: null
  })

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
    this.clipboard = new Clipboard('.task-detail-btn-copy-url', {
      text: () => AppConfig.appBaseUrl + 'planner/task/' + this.props.task.uid
    });
    this.clipboard.on('success', () => getAppNotifications().success({ position: Position.LEFT_BOTTOM, message: 'Copied task url to clipboard!' }))
    this.clipboard.on('error', () => getAppNotifications().error({ position: Position.LEFT_BOTTOM, message: 'Failed to copy task url to clipboard!' }))
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
    this.clipboard.destroy()
  }

  showTaskTrashNote = () => {
    const { task } = this.props
    const taskIndex = task.list.items.findIndex(({ uid }) => task.uid === uid)
    getAppNotifications().show({
      message: 'Deleted task ' + task.title,
      icon: "ban-circle",
      action: {
        onClick: () => {
          task.unTrash()
          task.list.moveItem(task, taskIndex)
          task.list.saveOrder()
          task.save()
          getAppNotifications().success({
            icon: "add",
            message: 'Restored Task ' + task.title,
          })
        },
        text: 'Undo'
      } 
    })
  }

  onClickTrash = event => {
    const { task } = this.props
    debug('delete task', task)
    event.preventDefault()
    task.trash()
    this.showTaskTrashNote()
  }

  onClickPrint = event => {
    event.preventDefault()
    window.print()
  }

  onConvertToProject = async event => {
    const { task } = this.props
    event.preventDefault()
    const { title, description, memberList } = await task.toJSON()
    const project = ProjectItem.fromJSON({ title, description, memberList })
    debug('onConvertToProject', await task.toJSON(), project.toJSON())
    await project.save()
    await task.trash()
  }

  onConvertToEvent = async event => {
    event.preventDefault()
    this.uiState.showCalendarModal = true
  }

  render() {
    const { showCalendarModal } = this.uiState
    const { plannerFlow, actionPlannerStore, size, pos, task, trashTaskTitle, printTaskTitle, convertToProjectTitle, onConvertToEventTitle, copyUrlTitle } = this.props
    debug('render', { size, pos, task })

    return (
      <footer
        id="task-detail-footer"
        className="main-pane-footer"
        style={{
          ...size,
          ...pos
        }}
      >
        <PressableIcon icon="trash" size="xs" style={{marginLeft: -10 }} onClick={this.onClickTrash} title={trashTaskTitle} />
        <PressableIcon icon="print" size="xs" onClick={this.onClickPrint} title={printTaskTitle} />
        <PressableIcon icon="convert_to_project" size={20} onClick={this.onConvertToProject}  title={convertToProjectTitle} />
        <PressableIcon icon="convert_to_event" size={20} onClick={this.onConvertToEvent} title={onConvertToEventTitle} />
        <PressableIcon className="task-detail-btn-copy-url" icon="url" size="xs" title={copyUrlTitle} />

        {
          showCalendarModal && (
            <TaskToEvent
              actionPlannerStore={actionPlannerStore}
              plannerFlow={plannerFlow}
              selectedTask={task}
              onHideCalendarModal={this.onHideCalendarModal}
            />
          )
        }
      </footer>
    )
  }
}

export default observer(TaskDetailFooter)
