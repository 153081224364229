import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import 'filepond/dist/filepond.min.css'
import Icon from '../../../theme/Icon'
import TaskFileItem from './TaskFileItem'
import TaskFileUploadModal from './TaskFileUploadModal'

const debug = require('debug')('treks:task:files')

class TaskFiles extends React.Component {

  state = observable({
    showModal: false
  })

  setState(state) {
    Object.assign(this.state, state)
  }

  handleShow = event => {
    event.preventDefault()
    if (this.props.item.done) return
    this.setState({ showModal: true })
  }

  onModalClose = files => {
    debug('files', files)
    this.setState({ showModal: false })
    this.props.item.fileList.addItems(files)
  }

  render() {
    const { fileList } = this.props.item
    debug('render', { fileList })
    return (
      <div className="task-file-list">
        {
          fileList.items.map(fileItem => {
            return <TaskFileItem key={fileItem.uid} fileItem={fileItem} />
          })
        }
        <a 
          onClick={this.handleShow} 
          className="file-add" 
          href="/file/add"
        >
          {
            fileList.items.length > 0 ? <Icon 
              set="fa" 
              icon={'file-upload'} 
              size="xs" 
              color={'#808284'} 
            /> : null
          }
          <span className="file-add-label">
            Attach Files
          </span>
        </a>
        <TaskFileUploadModal show={this.state.showModal} onClose={this.onModalClose} />
      </div>
    )
  }
}

export default observer(TaskFiles)