import React from 'react'
import { observer } from 'mobx-react'
import Select from '../../theme/Form/Select'
import { CategoryList } from 'Stores/Category'
import { getStore } from 'Stores'
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:task:category-select')

class TaskCategorySelect extends React.Component {

  static defaultProps = {
    categoryList: getStore(CategoryList),
    taskSelectPlaceholder: 'Select Category',
    categorySaveErrMsg: 'Failed to save new category',
    fetchFailErrMsg: 'Failed to fetch  your categories'
  }

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    this.fetch()
  }

  fetch() {
    const { fetchFailErrMsg } = this.props
    this.props.categoryList.fetched()
      .catch(error => {
        getAppNotifications().error(fetchFailErrMsg)
        console.error(error)
      })
  }

  onChange = option => {
    const { item, categoryList } = this.props
    item.setCategory(option && categoryList.getItemById(option.value))
    item.save()
    debug('set item category', { item, option })
  }

  onCreateOption = label => {
    const { item, categoryList, categorySaveErrMsg } = this.props
    const newCategory = categoryList.addItem({ title: label, project: item.project })
    newCategory.save()
      .then(() => {
        item.setCategory(newCategory)
        item.save()
      }) // has id now
      .catch(err => {
        console.error('Category save error', err)
        getAppNotifications().error(categorySaveErrMsg)
      })
    debug('onCreateOption', { item, label, newCategory })
  }

  render() {
    const { item, categoryList, taskSelectPlaceholder } = this.props
    const { category } = item
    const options = categoryList.items
      .map(({ title, id }) => ({ 
        label: title, 
        value: id 
      }))
    const value = category 
      ? options.find(({value}) => value === category.id) 
      : null
    debug('render', { item, category, options, value })
    return (
      <Select 
        isClearable
        hasAddOption
        isDisabled={!item.project?.id}
        createOptionPosition={'first'}
        placeholder={taskSelectPlaceholder}
        onChange={this.onChange}
        onCreateOption={this.onCreateOption}
        options={options} 
        value={value}
      /> 
    )
  }
}

export default observer(TaskCategorySelect)