import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

export class AppLoadingIndicator extends React.Component {

  static defaultProps = {
    animation: 'border'
  }

  render() {
    const { animation, style } = this.props
    const defaultStyle = {
      position: 'absolute',
      top: 'calc(50% - 50px)',
      left: 'calc(50% - 50px)',
      zIndex: 1000 
    }
    return (
      <div style={{ ...defaultStyle, ...style }}>
        <Spinner animation={animation} />
      </div>
    )
  }

}

export default AppLoadingIndicator