import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import MainPaneGroup from '../Components/MainPaneGroup'
import { bucket, MultiDaylannerPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { mainPaneComponents } from './MainPane/MainPaneComponents'
import { models } from 'Stores'

const debug = require('debug')('treks:route:main-pane')

export default withRouter(observer(class MainPaneRoute extends React.Component {

  static defaultProps = {
    store: getStore(models.MainPaneGroup)
  }

  get params() {
    return this.props.match.params
  }

  async componentDidMount() {
    const { store } = this.props

    this.setBucketComponents(bucket)
    store.setBucketItems(bucket)
    await store.fetchLocal(this.params.page)
    this.addPaneFromURL()
    if (!store.items.length) {
      this.addDefaultPane()
    }
    window.addEventListener('beforeunload', () => store.saveLocal(this.params.page))
  }

  setBucketComponents(bucket) {
    bucket.forEach(item => {
      const { component, footer } = mainPaneComponents[item.slug] || {}
      Object.assign(item, { component, footer })
    })
  }

  addDefaultPane(comp = MultiDaylannerPane) {
    this.props.store.addOrUpdateItem(comp)
  }

  addPaneFromURL() {
    const { page, id } = this.params
    const { store } = this.props
    let pane = store.getBucketItemBySlug(page)
    if (pane) {
      pane.setModelId(id)
      store.addOrUpdateItem(pane)
    }
    debug('setPaneFromURL', { page, id, pane, store })
    return pane
  }

  render() {
    const { store } = this.props
    debug('render', { store })
    return (
			<MainPaneGroup store={store} />
    )
  }
}))
