import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './AccountSettingsMenu.css'
import AccountSettingsMenuItem from './AccountSettingsMenuItem'
import Nav from 'react-bootstrap/Nav'

const items = [
  {
    label: 'PROFILE',
    id: 'profile'
  },
  {
    label: 'DISPLAY',
    id: 'display'
  },
  {
    label: 'BILLING',
    id: 'billing'
  },
]


export default withRouter(observer(class AccountSettingsMenu extends React.Component {

  onSelect = eventKey => {
    this.props.history.push(eventKey)
  }

  render() {
    const defaultActiveKey = this.props.history.location.pathname || '/account/settings/profile'
    return (
      <Nav
        className="account-settings-menu"
        variant="pills"
        defaultActiveKey={defaultActiveKey}
        onSelect={this.onSelect}
      >
        {
          items.map(item => <AccountSettingsMenuItem key={item.id} item={item} />)
        }
      </Nav>
    )
  }
}))