
import { override } from 'mobx'
import Form from '../Form'
import { displaySettingsFields } from './fields/displaySettingsFields'

const debug = require('debug')('treks:store:account:settings')

export default class DisplaySettings extends Form {
  constructor() {
    super(displaySettingsFields)
  }

  /**
   * Retrieve settings from field values
   */
  getSettings() {
    return this.getFieldValues()
  }

  @override async fetch() {
    debug('fetch account settings')
    return this.fetchState.get('account/settings/detail')
      .then(resp => {
        const { settings } = resp.data
        debug('setting api settings', settings)
        this.setFieldValues(settings)
      })
  }

  @override async save() {
    const settings = this.getSettings()
    debug('save account settings', { settings })
    return this.saveState.post('account/settings/save', settings)
      .then(resp => {
        debug('resp', resp)
        this.setFieldValues(resp.data.settings)
      })
  }
}