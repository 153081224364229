import React from 'react'
import ProjectDetailRow from '../ProjectDetailRow'
import { observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './ProjectAddComment.scss'

const debug = require('debug')('treks:project:comment:add')

class ProjectAddComment extends React.Component {

  textRef = null

  store = observable({
    focused: null,
    title: '',
    text: ''
  })

  static propTypes = {
    onAddComment: PropTypes.func.isRequired
  }

  resetStore() {
    Object.assign(this.store, {
      focused: null,
      title: '',
      text: '',
    })
  }

  onTitleKeyUp = event => {
    if (event.key === 'Enter') {
      this.textRef && this.textRef.focus()
    }
  }

  onTitleFocus = () => {
    this.store.focused = true
    debug('focused', toJS(this.store))
  }

  onTitleChange = event => {
    this.store.title = event.target.value
  }

  onTextChange = event => {
    this.store.text = event.target.value
  }

  onSubmit = async () => {
    this.props.onAddComment({
      title: this.store.title,
      text: this.store.text
    })
    this.resetStore()
  }

  render() {
    const { focused } = this.store
    return (
      <div className={'container comment-add' + (focused ? ' focused' : '')}>
        <ProjectDetailRow icon="comment">
          <div className="comment-box">
            <Form.Control 
              value={this.store.title}
              placeholder={focused ? 'Title' : 'Comment'}
              className="comment-input" 
              onChange={this.onTitleChange} 
              onFocus={this.onTitleFocus}
              onKeyUp={this.onTitleKeyUp}
            />
            {
              focused && (
                <>
                  <Form.Control
                    ref={ref => this.textRef = ref}
                    as="textarea"
                    rows="3"
                    value={this.store.text}
                    onChange={this.onTextChange}
                    className="comment-input comment-text"
                    placeholder={'Comment'}
                  />
                  <Form.Group className="form-group-btn" controlId="comment-add-btn">
                    <Button onClick={this.onSubmit}>Comment</Button>
                  </Form.Group>
                </>
              )
            }
          </div>
        </ProjectDetailRow>
      </div>
    )
  }
}

export default observer(ProjectAddComment)