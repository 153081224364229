import React from 'react'
import { observer } from 'mobx-react'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import { 
  AdminUserList,
  AdminUserDetail,
  AdminUserAdd,
  AdminUserEdit,
} from '../../Components/Admin/User'
import { Switch, Route } from 'react-router-dom'

const modalStore = ModalStore.create()
modalStore.setProps({
  show: true,
})

// do not allow closing
const canClose = false

class AdminUserModal extends React.Component {

  handleClose = () => {
    modalStore.setState({ show: false })
  }

  render() {
    const { show} = modalStore
    return (
      <Modal 
          show={canClose ? show : true} 
          onHide={this.handleClose}
          size="lg"
          dialogClassName="settings-modal admin-user-modal"
        >
          <Modal.Body>
            <Switch>
              <Route path="/admin/user/add" component={AdminUserAdd} />
              <Route path="/admin/user/edit/:userId" component={AdminUserEdit} />
              <Route path="/admin/user/:userId" component={AdminUserDetail} />
              <Route path="/admin/user" component={AdminUserList} />
            </Switch>
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(AdminUserModal)