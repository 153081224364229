import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './AdminUser.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import stores from 'Stores'
import userAddFields from './Fields/UserAdd'
import UserForm from './UserForm'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:admin:user:edit')

const headerText = 'Edit User'
const defaultSaveError = 'An error occurred while trying to save the user.'
const defaultLoadError = 'An error prevented loading the user data. Please ensure you are online.'

export default withRouter(observer(class AdminUserEdit extends React.Component {

  userAddFields = stores.Form.fromFields(userAddFields)
  userStore = stores.User.create()

  async componentDidMount() {
    try {
      this.userAddFields.getFieldByName('password').required = false
      this.userStore.setProps({ id: this.props.match.params.userId })
      await this.userStore.fetch()
      this.setUserToFormFields()
    } catch(error) {
      getAppNotifications().error(error.message || defaultLoadError)
    }
  }

  setUserToFormFields() {
    this.userAddFields.setFieldValues(this.userStore)
  }

  onSubmit = params => {
    debug('editing user', params, this.userStore)
    this.userStore.setProps(params)
    this.userStore.edit()
      .then(() => {
        getAppNotifications().success('The user has been updated!')
      })
      .catch(error => {
        if (error.fields) {
          debug('Server sent errors', error)
          this.userAddFields.setFormValidationErrors(error)
          debug('errors set', this.userAddFields)
        }
        getAppNotifications().error(error.message || defaultSaveError)
      })
  }

  render() {
    debug('render', { userAddFields: this.userAddFields })

    return (
      <Container className="settings admin-user admin-user-list">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <UserForm 
              fieldsStore={this.userAddFields}
              onSubmit={this.onSubmit}
              submitLabel={'Update User'}
            />

          </Col>
        </Row>
      </Container>
    )
  }
}))