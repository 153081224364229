import { override } from 'mobx'
import CommentItem from './CommentItem'
import { List } from '../Lists'
import { ProjectItem } from 'Stores/Project'
import TaskItem from 'Stores/Task'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class CommentList extends List {

  get ModelType() {
    return CommentItem
  }

	@hasOne(() => ProjectItem)
  project:ProjectItem

	@hasOne(() => TaskItem)
  task:TaskItem

	@hasOne(() => CommentItem)
	comment:CommentItem

  createItemProps(props:any = {}) {
    const { project, task, comment } = this
    return { project, task, replyTo:comment, ...props }
  }

  @override async fetch() {
    const projectId = this.project && this.project.id
    const commentId = this.comment && this.comment.id
    const taskId = this.task && this.task.id
    return this.fetchState.get('comment/list', { projectId, commentId, taskId })
      .then(json => this.fromJSON(json.data))
  }

  @override fromJSON(data:any) {
    if (data.items) {
      const replies = data.items.filter(item => item.replyToId)
      const comments = data.items
        .filter(item => !item.replyToId)
        .map(comment => {
          return {
            ...comment,
            liked: comment.CommentLikes[0]?.published,
            likeCount: comment._count.CommentLikes
          }
        })
      console.log('comments', comments)
      super.fromJSON({ items: comments })
      replies.forEach((props:any) => {
        const comment = this.items.find(comment => comment.id === props.replyToId)
        const reply = this.createItem().fromJSON(props)
        comment.addReply(reply)
      })
    }
    return data
  }

}