export const data = [
  {
    "id": "Goal",
    "color": "#58b948",
    "data": [
      {
        "x": 1,
        "y": 175
      },
      {
        "x": 2,
        "y": 172
      },
      {
        "x": 3,
        "y": 169
      },
      {
        "x": 4,
        "y": 166
      },
      {
        "x": 5,
        "y": 162
      },
      {
        "x": 6,
        "y": 158
      },
      {
        "x": 7,
        "y": 155
      }
    ]
  },
  {
    "id": "Calculated",
    "color": "#d1807c",
    "data": [
      {
        "x": 1,
        "y": 175
      },
      {
        "x": 2,
        "y": 174
      },
      {
        "x": 3,
        "y": 172
      },
      {
        "x": 4,
        "y": 169
      },
      {
        "x": 5,
        "y": 165
      },
      {
        "x": 6,
        "y": 161
      },
      {
        "x": 7,
        "y": 159
      }
    ]
  },
  {
    "id": "Actual",
    "color": "#808285",
    "data": [
      {
        "x": 1,
        "y": 175
      },
      {
        "x": 2,
        "y": 170
      },
      {
        "x": 3,
        "y": 172
      },
      {
        "x": 4,
        "y": 165
      },
      {
        "x": 5,
        "y": 156
      },
      {
        "x": 6,
        "y": 159
      },
      {
        "x": 7,
        "y": 156
      }
    ]
  }
]

export default data
