import React from 'react'
import { observer } from 'mobx-react';
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import WeekDraggableEvent from 'Components/Calendar/WeekCalendar/WeekDraggableEvent';
import EventContextMenu from 'Containers/ActionPlanner/ContextMenu/EventContextMenu'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import ActionPlanner from 'Stores/ActionPlanner';

const debug = require('debug')('treks:planner:events')

type WeekPlannerEventsFlowProps = {
  actionPlannerStore: ActionPlanner;
  plannerFlow: PlannerFlow;
  plannerUtils: number;
  stackOffset: number;
  maxStacks: number;
  maxWidth: number;
  widthOffset: number;
  onTaskOffset: number;
}

export default observer(class WeekPlannerEventsFlow extends React.Component<WeekPlannerEventsFlowProps> {

  uiStore = ArbitraryModel.fromProps({
    showContextMenu: false,
    contextMenuPos: {left: null, top: null }
  })

  static defaultProps = {
    stackOffset: 50, 
    maxStacks: 8,
    maxWidth: '100%',
    widthOffset: 120,
    onTaskOffset: 50,
  }

  onShowContextMenu = ({ event, nativeEvent }) => {
    if (localStorage.noEventContextMenu) return // disable in dev
    nativeEvent.preventDefault()
    this.setFocusedEvent(event)
    const { clientX, clientY } = nativeEvent
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.addEventListener('scroll', this.onHideContextMenu)
    Object.assign(this.uiStore, { 
      showContextMenu: true, 
      contextMenuPos: { left: clientX, top: clientY }
    })
    debug('showing context menu on event', { event, nativeEvent })
  }

  onHideContextMenu = () => {
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.removeEventListener('scroll', this.onHideContextMenu)
    Object.assign(this.uiStore, {
      showContextMenu: false,
      contextMenuPos: { left: null, top: null }
    })
  }

  setFocusedEvent(event) {
    this.props.actionPlannerStore.setFocusedEvent(event.datedItem)
  }

  render() {
    const { actionPlannerStore, plannerFlow, plannerUtils, stackOffset, maxStacks, maxWidth, widthOffset, onTaskOffset } = this.props
    const { showContextMenu, contextMenuPos } = this.uiStore
    const { startOfWeekDate, endOfWeekDate } = actionPlannerStore
    debug('render', { plannerFlow })
    return (
      <div className="action-planner-events action-planner-items week-planner-events">
        {
          plannerFlow.allEvents.map(event => {
            if (event.endDate < startOfWeekDate || event.startDate > endOfWeekDate) return
            const dayCount = 7 // focused week only
            const startDay = Math.round(event.startDuration / (60*24)) % dayCount // 0 indexed
            const leftOffset = (startDay ? ((startDay/dayCount) * 100) : 0) + '%'
            debug('leftOffset', event.datedItem.title, { leftOffset, dayCount, startDay })
            return (
              <WeekDraggableEvent
                key={event.uid}
                plannerFlow={plannerFlow}
                plannerUtils={plannerUtils}
                leftOffset={leftOffset}
                stackOffset={stackOffset}
                maxStacks={maxStacks}
                maxWidth={maxWidth}
                widthOffset={widthOffset}
                onTaskOffset={onTaskOffset}
                event={event}
                onShowContextMenu={this.onShowContextMenu}
              />
            )
          })
        }
        { 
          showContextMenu ? 
          <EventContextMenu
            event={actionPlannerStore.focusedEvent}
            contextMenuPos={contextMenuPos}
            onHideContextMenu={this.onHideContextMenu}
          /> : null 
        }
      </div>
    )
  }
})
