import List from '../Lists/List'
import CalendarEvent from './CalendarEvent';
import { computed, override } from 'mobx';

export default class CalendarEventList extends List {

  @override get items(): CalendarEvent[] {
    return super.items 
  }

  get ModelType() {
    return CalendarEvent
  }

  @computed get visibleEvents() {
    return this.items.filter(item => {
      return !item.deleted && item.status !== 'cancelled' && item.onPlanner !== false
    })
  }

}
