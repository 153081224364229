const getElOffsetFromParent = (el, parent) => {
  let offset = 0
  let nextParent = el
  while(nextParent && nextParent !== parent) {
    offset += nextParent.contains(parent) 
      ? (nextParent.offsetTop - parent.offsetTop) : nextParent.offsetTop
    nextParent = nextParent.offsetParent
  }
  return offset
}

const headerHeight = 100 // planner header

let transformQueueEvent = null
const handleScrollQueued = event => {
  const scrollEl = event.target
  const { scrollTop } = scrollEl
  const timespanElList = scrollEl.querySelectorAll('.action-planner-items')

  timespanElList.forEach(timespanEl => {
    const titleEl = timespanEl.querySelector('.timespan-title')
    if (!titleEl) return
    const timespanOffsetTop = getElOffsetFromParent(timespanEl, scrollEl)
    const timespanHeight = timespanEl.offsetHeight
    const timespanOffsetBottom = timespanOffsetTop + timespanHeight
    const titleHeight = titleEl.offsetWidth // titleEl is rotated 90deg
    const maxOffset = timespanHeight - titleHeight
    const currentOffset = scrollTop - timespanOffsetTop + headerHeight
    const titleOffsetMax =  Math.max(Math.min(currentOffset, maxOffset), 0)

    if (scrollTop > timespanOffsetTop - headerHeight && scrollTop < timespanOffsetBottom) {

      if (!titleEl.getAttribute('data-transform')) {
        titleEl.setAttribute('data-transform', getComputedStyle(titleEl).transform)
      }
      const origTransform = titleEl.getAttribute('data-transform')
      titleEl.style.transform = `translateY(${titleOffsetMax}px) ${origTransform}`

    } else {
      if (titleEl.getAttribute('data-transform')) {
        titleEl.style.transform = titleEl.getAttribute('data-transform')
      }
    }

  })
}

let isWatchRAF = false
const startWatchRAF = () => {
  isWatchRAF = true
  const waitNextRAF = () => {
    requestAnimationFrame(() => {
      if (transformQueueEvent) {
        handleScrollQueued(transformQueueEvent)
        transformQueueEvent = null
      }
      if (isWatchRAF) waitNextRAF()
    })
  }
  waitNextRAF()
}

const endWatchRAF = () => isWatchRAF = false 

const handleScrollAni = event => transformQueueEvent = event

export function create() {
  startWatchRAF()
  const scrollEl = document.querySelector('.main-pane-component')
  scrollEl && scrollEl.addEventListener('scroll', handleScrollAni)
}

export function destroy() {
  endWatchRAF()
  const scrollEl = document.querySelector('.main-pane-component')
  scrollEl && scrollEl.removeEventListener('scroll', handleScrollAni)
}

export const StickyTimespanTitles = {
  create, 
  destroy
}
export default StickyTimespanTitles