import { computed } from "mobx"
import TaskItem from "../TaskItem"
import { once } from '../../utils'

export const importSubTask = once(() => {
  class SubTask extends TaskItem {

    get ModelType() {
      return TaskItem
    }
  
    @computed get task() {
      return this.list?.task
    }
  
    isSubTask = true
  
    async save() {
      // @todo remove delegation to parent when subtask api completed
      return this.task?.save()
    }
  
  }
  return SubTask
})

export default importSubTask // @todo remove