import { action, computed, override } from 'mobx'
import TaskItem from 'Stores/Task';
import { TaskFocusedItemI } from 'Stores/Task/Type';
import Model from '../Model';
import { models } from 'Stores';

const debug = require('debug')('treks:store:task:focused')

/**
 * ActionPlanner UI Focused Task
 */
export default class FocusedItem extends Model implements TaskFocusedItemI {

  /**
   * @property {string} modelName for production
   */
  get modelName() {
    return 'FocusedItem'
  }

  @computed get item() {
    return this.getAttribute('item')
  }

  @action async setFocusedItem(item: TaskItem) {
    if (this.item !== item) {
      this.setAttribute('item', item)
    }
  }

  @override async fromJSON(json: any) {
    if (json?.uid) {
      let model = models.TaskItem.findByUid(json.uid)
      if (!model && json.id) {
        model = models.TaskItem.fromJSON(json)
        model.fetch()
      }
      this.setFocusedItem(model)
    }
    return this
  }

  async save() {
    const { uid, id } = this.item
    debug('save focused item', this.modelName, { uid, id })
    return this.localState.set(this.modelName, { uid, id })
  }

  @override async fetch() {
    const item = await this.localState.get(this.modelName)
    debug('fetch focused item', this.modelName, item)
    this.fromJSON(item)
  }

}
