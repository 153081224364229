import React from 'react'
import { components } from 'react-select'
import PlaceholderColor from './PlaceholderColor';
import { observer } from 'mobx-react';

const debug = require('debug')('treks:select:placeholder')

class Placeholder extends React.Component {

  render() {
    const { props } = this
    const { children } = props
    const { menuIsOpen, menuOpenPlaceholder, hasAddOption, addOptionIsActive, addOptionPlaceholder } = props.selectProps
    debug('render', { props, children })
    return (
      <components.Placeholder {...props}>
        {
          hasAddOption && addOptionIsActive && menuIsOpen ? <PlaceholderColor>{addOptionPlaceholder}</PlaceholderColor> :
          menuOpenPlaceholder && menuIsOpen 
            ? <PlaceholderColor>{menuOpenPlaceholder}</PlaceholderColor> : children
        }
      </components.Placeholder>
    );
  }
}

export default observer(Placeholder)