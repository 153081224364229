import React from 'react'
import { observer } from 'mobx-react'
import ProjectDetail from './ProjectDetail'
import PropTypes from 'prop-types'
import { models } from 'Stores'

const debug = require('debug')('treks:component:project')

class Project extends React.Component {

  static propTypes = {
    project: PropTypes.instanceOf(models.ProjectItem).isRequired,
    projectList: PropTypes.instanceOf(models.ProjectList).isRequired
  }

  render() {
    const { project, projectList } = this.props
    debug('render', { project, projectList })
    return (
      <ProjectDetail projectList={projectList} project={project} />
    )
  }
}

export default observer(Project)