import { observable, override } from "mobx"
import { PlannerFlow } from "../PlannerFlow"
import List from "Stores/Lists"
import { TimespanProps } from "./TimespanProps"
import { once } from "Stores/utils"
import { defaultTimespanList } from "../data/defaultTimespanList"


const debug = require('debug')('treks:store:AccountTimespanList')

export class AccountTimespanList extends List {

  get ModelType(): typeof TimespanProps {
    return TimespanProps
  }

  @override get items(): TimespanProps[] {
    return super.items
  }

  @override get visibleItems(): TimespanProps[] {
    return super.items.filter(item => !item.deleted)
  }

  @observable plannerFlow: PlannerFlow = null

  constructor() {
    super()
    this.setItems(defaultTimespanList)
  }

  @override async fetch(): Promise<this> {
    this.fetchState.get('account/settings/timespans/list')
      .then(resp => {
        if (resp.data?.timespans?.length) {
          this.setItems(resp.data.timespans)
        }
      })
    return this
  }

  async save(): Promise<this> {
    this.saveState.postJson('account/settings/timespans/save', {
      timespans: this.toJSON().items
    })
    return this
  }

}

export const getAccountTimespanList = once<AccountTimespanList>(() => AccountTimespanList.create())

global.AccountTimespanList = AccountTimespanList
global.getAccountTimespanList = getAccountTimespanList