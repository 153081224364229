import React from 'react'
import './Table.scss'
import Table from 'react-bootstrap/Table'

export default class TableView extends React.Component {
  render() {
    const { className, children } = this.props
    return (
      <Table striped hover className={className}>
        {children}
      </Table>
    )
  }
}