import React from 'react'

export default class MainHeader extends React.PureComponent {
  render() {
    return (
      <header className="main-header">
        {this.props.children}
      </header>
    )
  }
}