import React from "react";
import { observer } from "mobx-react";
import Icon from "theme/Icon";
import { getPFASettingsStore } from "../../stores/PFASettingsStore";
import pfaEnglishLang from "./lang/english";
import { RangeSlider, DoubleRangeSlider } from "../../widgets/RangeSlider";
import FieldInfo from "./components/FieldInfo";
import "./styles/PFASettings.scss";
import DaysToTrackSelect from "./components/DaysToTrackSelect";
import { HabitPointsEarnedAfterSelect, HabitExtraPointsSelect, HabitExtraPointsLimitSelect } from "./components/HabitPointsSelect";
import { KindDeedsExtraPointsSelect, KindDeedsExtraPointsLimitSelect } from './components/KindDeedsSelect';
import TimespansToTrack from "./components/TimespansToTrack";
import { ScoreWeightsSelect } from "./components/ScoreWeights";

class PFASettingsView extends React.Component {
  static defaultProps = {
    title: "SETTINGS",
    pfaSettingsStore: getPFASettingsStore(),
    lang: pfaEnglishLang,
    isTargetProductivityEnabled: false,
    SettingsIcon: () => <Icon icon="settings" />,
  };

  get habitPoints() {
    return this.props.pfaSettingsStore.habitPoints;
  }

  handleClose = () => {
    this.props.modelStore.hide();
  };

  onSelectTab = (tabKey) => {
    this.props.tabsStore.setActiveTabKey(tabKey);
  };

  setValue = (name, value) => {
    this.props.pfaSettingsStore.setProp(name, value);
  };

  setScoreWeight = (name, value) => {
    const { scoreWeights } = this.props.pfaSettingsStore;
    scoreWeights.setProp(name, value);
    const totalWeights = scoreWeights.getTotalWeights();
    const nextKey = scoreWeights.getNextKey(name);
    const nextValue = scoreWeights.getProp(nextKey);
    scoreWeights.setProp(nextKey, nextValue + 100 - totalWeights);
  };

  setThrashAcceptableRange(range) {
    this.props.pfaSettingsStore.thrashAcceptableRange.setRange(range);
  }

  setHabitPointsEarnedAfter(value) {
    this.habitPoints.setProp("earnedAfter", value);
  }

  render() {
    const { pfaSettingsStore, title, lang, isTargetProductivityEnabled, SettingsIcon } = this.props;
    const { thrashAcceptableRange } = pfaSettingsStore;

    return (
      <div className="pfa-settings">
        <header>
          <SettingsIcon />
          <span className="settings-title">{title}</span>
        </header>
        <div className="settings-content">
          <div className="settings-row">
            <label className="settings-label">{lang.timespansToTrack}</label>
            <div className="settings-fields">
              <TimespansToTrack />
            </div>
          </div>

          <div className="settings-row">
            <label className="settings-label">{lang.daysToTrack}</label>
            <div className="settings-fields" style={{ flex: 'none' }}>
              <div className="settings-field">
                <DaysToTrackSelect />
              </div>
            </div>
          </div>

          <div className="settings-row">
            <label className="settings-label">{lang.pfaGoal}</label>
            <div className="settings-fields">
              <div className="settings-field field-rows">
                <div className="field-row" style={{ width: '50%', minWidth: 200, maxWidth: 800 }}>
                  <RangeSlider
                    value={pfaSettingsStore.scoreGoal}
                    onChange={(value) =>
                      this.setValue("scoreGoal", Number(value))
                    }
                  />
                </div>
                <div className="field-row">
                  <label className="settings-field-value">
                    {pfaSettingsStore.scoreGoal}%
                  </label>
                </div>
              </div>
            </div>
          </div>

          {
            isTargetProductivityEnabled ? (
              <div className="settings-row">
                <label className="settings-label">
                  {lang.targetProductivity}
                </label>
                <div className="settings-fields">
                  <div className="settings-field field-rows">
                    <div className="field-row">
                      <RangeSlider
                        value={pfaSettingsStore.targetProductivity}
                        onChange={(value) =>
                          this.setValue("targetProductivity", Number(value))
                        }
                      />
                    </div>
                    <div className="field-row">
                      <label className="settings-field-value">
                        {pfaSettingsStore.targetProductivity}%
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          <div className="settings-row">
            <label className="settings-label">{lang.scoreWeighting}</label>
            <div className="settings-fields">
              <div className="settings-field field-cols">
                <div className="field-col">
                  <ScoreWeightsSelect />
                </div>
              </div>
            </div>
          </div>

          <div className="settings-row">
            <label className="settings-label">
              {lang.thrashAcceptableRange}
            </label>
            <div className="settings-fields">
              <div className="settings-field field-cols">
                <div className="field-col">
                  <DoubleRangeSlider
                    range={thrashAcceptableRange.range}
                    onChange={(value) => this.setThrashAcceptableRange(value)}
                  />
                </div>
                <FieldInfo info={lang.thrashAcceptableRangeInfo} />
              </div>
            </div>
          </div>

          <div className="settings-row" id="settings-row-habits">
            <label className="settings-label">{lang.habits}</label>
            <div className="settings-fields">
              
              <div className="settings-field field-rows">
                <div className="field-row field-label">
                  <label className="settings-field-label">
                    {lang.pointsEarnedAfter}
                  </label>
                </div>
                <div className="field-row">
                  <HabitPointsEarnedAfterSelect />
                </div>
              </div>

              <div className="settings-field field-rows">
                <div className="field-row field-label">
                  <label className="settings-field-label">
                    {lang.extraPoints}
                  </label>
                </div>
                <div className="field-row">
                  <HabitExtraPointsSelect />
                </div>
              </div>

              <div className="settings-field field-rows">
                <div className="field-row field-label">
                  <label className="settings-field-label">
                    {lang.extraPointsLimit}
                  </label>
                </div>
                <div className="field-row">
                  <HabitExtraPointsLimitSelect />
                </div>
                <div className="field-row full-width">
                  <FieldInfo info={lang.habitsInfo} />
                </div>
              </div>

            </div>
          </div>

          <div className="settings-row" id="settings-row-kind-deeds">
            <label className="settings-label">{lang.kindDeeds}</label>
            <div className="settings-fields">

              <div className="settings-field field-rows">
                <div className="field-row field-label">
                  <label className="settings-field-label">
                    {lang.extraPoints}
                  </label>
                </div>
                <div className="field-row">
                  <KindDeedsExtraPointsSelect />
                </div>
              </div>

              <div className="settings-field field-rows">
                <div className="field-row field-label">
                  <label className="settings-field-label">
                    {lang.extraPointsLimit}
                  </label>
                </div>
                <div className="field-row">
                  <KindDeedsExtraPointsLimitSelect />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default observer(PFASettingsView)
