import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import './AdminUser.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Pagination from 'theme/Pagination'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import stores from 'Stores'
import userFields from './Fields/User'
import UserSearch from './UserSearch'
import UserListTableStore from './Stores/UserListTable'
import UserText from './Form/FormControlUserText'

const debug = require('debug')('treks:admin:user:list')

const headerText = 'Users List'

export default withRouter(observer(class AdminUserList extends React.Component {

  userListStore = stores.UserList.create()
  uiStore = UserListTableStore.fromProps({ userListStore: this.userListStore })

  get query() {
    return qs.parse(this.props.location.search, { 
      ignoreQueryPrefix: true 
    })
  }

  set query(params) {
    const query = this.query
    Object.assign(query, params)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: qs.stringify(query)
    })
  }

  componentDidMount() {
    this.userListStore.fetch()
  }

  onPageChange = page => {
    this.query = { page }
  }

  onSearch = params => {
    Object.keys(params).forEach(key => {
      if (!params[key]) {
        delete params[key]
      }
    })
    this.userListStore.fetch(params)
  }

  onSelectAll = () => {
    this.uiStore.toggleAllSelected()
    debug('Selected ', this.uiStore.allSelected ? 'all' : 'none' )
  }

  render() {
    debug('render', { query: this.query })
    const page = this.query.page || 1
    const perPage = 10
    const start = (page - 1) * perPage
    const total = this.userListStore.items.length
    const users = [...this.userListStore.items].splice(start, perPage)

    return (
      <Container className="settings admin-user admin-user-list">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>

            <UserSearch 
              total={total}
              onSearch={this.onSearch}
            />

            <Table striped hover>
              <thead>
                <tr>
                  {
                    userFields.map(({ name, label }) => (
                      <th key={name}>{label}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  users.map(user => {
                    return (
                      <tr key={user.id}>
                        {
                          userFields.map(field => {
                            const Component = field.Component || UserText
                            return (
                              <td key={field.name}>
                                { 
                                  <Component user={user} field={field} uiStore={this.uiStore} />
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>

            <footer>
              <Form.Group className="footer-select-all">
                <Form.Check 
                  name="selectAll" 
                  type="checkbox" 
                  onChange={this.onSelectAll} 
                  checked={this.uiStore.allSelected}
                />
                <Form.Label htmlFor="selectAll" onClick={this.onSelectAll}>Select All</Form.Label>
              </Form.Group>
              <div className="footer-btns">
                <Button>Export</Button>
                <Button>Update</Button>
                <Button>Archive</Button>
                <Button>Message</Button>
              </div>
              <Pagination 
                perPage={perPage}
                page={page}
                total={total}
                onPageChange={this.onPageChange}
              />
            </footer>
          </Col>
        </Row>
      </Container>
    )
  }
}))