import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Sortable from './Sortable'
import SortableEvents from './SortableEvents'
import SortableReact from './SortableReact'


export const SortableRoutes = () => {
  return (
    <Switch>
      <Route path="/docs/sortable/events" component={SortableEvents} />
      <Route path="/docs/sortable/react" component={SortableReact} />
      <Route path="/docs/sortable" component={Sortable} />
    </Switch>
  )
}
export default SortableRoutes