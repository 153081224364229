import Model from "Stores/Model";
import { observable, override } from "mobx";
import { once } from "Stores/utils";

export const importUserSettings = once(() => {
  class UserSettings extends Model {
    @observable userId: string;

    @observable language: string;

    @observable plannerPane: string;

    @observable clock: string;

    @observable duration: string;

    @observable workHours: string;

    @observable firstWeekDay: string;

    @observable timespanTypes: any = null;

    @override async fetch(): Promise<any> {
      const json = await this.fetchState.get("account/settings/detail");
      this.fromJSON(json.data.settings);
      return json.data.settings;
    }

    @override async save(): Promise<any> {
      return this.fetchState.postJson("account/settings/save", this.toJSON());
    }
  }

  return UserSettings;
});
