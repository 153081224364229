import React from 'react'
import { observer } from 'mobx-react'
import 'theme/style/settings.css'
import './AccountPasswordReset.css'
import '../Register/AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import Form from 'react-bootstrap/Form'
import { withRouter } from 'react-router-dom'
import stores from 'Stores'
import AccountRegisterFooter from '../Register/AccountRegisterFooter'
import { getAppNotifications } from 'AppNotifications'
import { FormField } from 'theme/Form'
import qs from 'qs'

const minPasswordLen = 6

const ResetHeader = 'Reset your Treks password'
const ResetButtonLabel = 'Reset Password'
const passwordLabel = 'Enter your new wassword'
const passwordConfirmLabel = 'Re-enter your new password'
const failedSendingEmailMsg = 'An error occurred resetting your password'
const errorPasswordMismatch = 'The re-entered password does not match'
const errorPasswordInvalid = `Please enter a password of ${minPasswordLen} or more characters`

const formFields = [
  {
    name: 'password',
    value: ''
  },
  {
    name: 'passwordConfirm',
    value: ''
  }
]
const { AccountRegister, Form:FormStore } = stores

const debug = require('debug')('treks:component:account:password:reset')

export default withRouter(observer(class AccountPasswordReset extends React.Component {

  accountRegisterStore = AccountRegister.create()
  formStore = FormStore.fromFields(formFields)

  get query() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  onPasswordKeyUp = event => {
    const passwordField = this.formStore.getFieldByName('password')
    debug('keyUp', { passwordField, key: event.key, valid: this.validatePassword() })
    if (event.key === 'Enter') {
      this.resetAccountPassword()
    } else {
      if (passwordField.hasErrors && this.validatePassword()) {
        passwordField.clearErrors()
      }
    }
  }

  onPasswordConfirmKeyUp = event => {
    const passwordConfirmField = this.formStore.getFieldByName('passwordConfirm')
    debug('keyUp', { passwordConfirmField, key: event.key, valid: this.validatePasswordsMatch() })
    if (event.key === 'Enter') {
      this.resetAccountPassword()
    } else {
      if (passwordConfirmField.hasErrors && this.validatePasswordsMatch()) {
        passwordConfirmField.clearErrors()
      }
    }
  }

  validatePassword() {
    const password = this.formStore.getFieldByName('password')
    return password.value && (password.value.toString().length >= minPasswordLen)
  }

  validatePasswordsMatch() {
    const password = this.formStore.getFieldByName('password')
    const passwordConfirm = this.formStore.getFieldByName('passwordConfirm')
    return password.value === passwordConfirm.value
  }

  onClick = event => {
    event.preventDefault()
    this.resetAccountPassword()
  }

  resetAccountPassword() {
    const passwordField = this.formStore.getFieldByName('password')
    if (!this.validatePassword()) {
      passwordField.setErrors([errorPasswordInvalid])
      return getAppNotifications().error(errorPasswordInvalid)
    }
    if (!this.validatePasswordsMatch()) {
      this.formStore.getFieldByName('passwordConfirm')
        .setErrors([errorPasswordMismatch])
      return getAppNotifications().error(errorPasswordMismatch)
    }
    this.accountRegisterStore.setProps({
      password: passwordField.value,
      token: this.query.token
    })
    return this.accountRegisterStore.resetAccountPassword()
      .then(() => {
        this.props.history.push('/account/password/reset/complete')
      })
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedSendingEmailMsg)
      })
  }

  render() {
    const { registerAccountState, sendEmailState } = this.accountRegisterStore
    const busy = registerAccountState.state === 'pending' || sendEmailState.state === 'pending'
    const passwordField = this.formStore.getFieldByName('password')
    const passwordConfirmField = this.formStore.getFieldByName('passwordConfirm')
    return (
      <Container className="account-register account-register-password settings">
        <Row>
          <Col>
            <header className="header">
              {ResetHeader}
            </header>
            <Form.Group>
              <FormField 
                label={passwordLabel} 
                name={'password'}
                autoFocus
                className="input input-password"
                onKeyUp={this.onPasswordKeyUp}
                type="password"
                disabled={busy}
                value={passwordField.value}
                errors={passwordField.errors}
                onChange={value => passwordField.setValue(value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{passwordConfirmLabel}</Form.Label>
              <FormField
                className="input input-password"
                onKeyUp={this.onPasswordConfirmKeyUp}
                type="password"
                disabled={busy}
                value={passwordConfirmField.value}
                errors={passwordConfirmField.errors}
                onChange={value => passwordConfirmField.setValue(value)}
              />
            </Form.Group>
            <Button 
              onClick={this.onClick} 
              busy={busy}
            >
              {ResetButtonLabel}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountRegisterFooter />
          </Col>
        </Row>
      </Container>
    )
  }
}))