import { ApiRequest } from "Stores/Service"
import Model from "."
import { ModelJsonI } from "./Type/Model"

export type RequestAdapterType = 'FETCH' | 'SAVE' | 'LIST' | 'UID'

/**
 * Base Model Request Adapter
 */
export default class ModelRequestAdapter {

  model: Model

  type: RequestAdapterType

  request: ApiRequest

  data: ModelJsonI<Model>

  constructor(model: Model, type: RequestAdapterType, request: ApiRequest) {
    if (model) this.model = model
    if (type) this.type = type.toUpperCase() as RequestAdapterType
    if (request) this.request = request
  }

  send = (data = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }
  
  post = (data = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }

  postJson = (data = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }
  
  get = (data = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }

  download = (data = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }

  fetch = (data = {}, options = {}) => {
    throw new Error('Method not available on this request adapter implementation')
    return null
  }

}
