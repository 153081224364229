import React from 'react'
import { observer } from 'mobx-react'
import Avatar from 'theme/Avatar'
import Pressable from 'theme/Pressable'
import DetailPeopleTitle from './DetailPeopleTitle'
import DetailPeopleRole from './DetailPeopleRole'

const debug = require('debug')('treks:project:people:item')

class DetailPeopleItem extends React.Component {

  onChangeNotificationConversations = event => {
    const checked = event.target.checked
    this.props.member.notifyConversations = checked
    this.modified()
  }

  onChangeNotificationTasks = event => {
    const checked = event.target.checked
    this.props.member.notifyTasks = checked
    this.modified()
  }

  onChangeNotificationStatus = event => {
    const checked = event.target.checked
    this.props.member.notifyStatus = checked
    this.modified()
  }

  modified = () => {
    this.props.member.save()
  }

  render() {
    const { member, memberList, userList } = this.props
    const { user, notifyConversations, notifyTasks, notifyStatus  } = member
    const { id, name, profileImage } = user
    debug('render', { member })
    return (
      <tr>
        <td className="member-meta">
          <Pressable className="member-avatar" onClick={this.onClick}>
            <Avatar 
              key={id} 
              name={name}
              src={profileImage} 
              size={24} 
              className="member-avatar"
            />
          </Pressable>
          <DetailPeopleTitle
            className="member-name"
            member={member}
            memberList={memberList}
            userList={userList}
          />
        </td>
        <td>
          <div className="notification-checkboxes">
            <div className="notification-checkbox">
              <input
                type="checkbox"
                onChange={this.onChangeNotificationConversations}
                checked={notifyConversations || 0}
              />
            </div>
            <div className="notification-checkbox">
              <input
                type="checkbox"
                onChange={this.onChangeNotificationTasks}
                checked={notifyTasks || 0}
              />
            </div>
            <div className="notification-checkbox">
              <input
                type="checkbox"
                onChange={this.onChangeNotificationStatus}
                checked={notifyStatus || 0}
              />
            </div>
          </div>
        </td>
        <td>
          <DetailPeopleRole member={member} />
        </td>
      </tr>
    )
  }
}

export default observer(DetailPeopleItem)
