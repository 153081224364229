import { action, makeObservable } from 'mobx';
import { JsonAny } from 'Stores/Model/Type/Json';
import { LocalDeviceStorage } from './LocalDeviceStorage';
import { RequestState } from './Request';

const debug = require('debug')('treks:stores:service:LocalStorageRequest')

/**
 * Wraps LocalStorage request with state
 */
export default class LocalStorageRequest extends RequestState {

  namespace:string = ''

  private storageAdapter:LocalDeviceStorage

  setStorageAdapter(adapter: LocalDeviceStorage) {
    this.storageAdapter = adapter
  }

  getStorageAdapter(): LocalDeviceStorage {
    if (!this.storageAdapter) {
      this.storageAdapter = new LocalDeviceStorage()
    }
    return this.storageAdapter
  }

  constructor(namespace) {
    super()
    if (namespace) this.namespace = namespace
    makeObservable(this)
  }

  serializer = {
    unserialize(data) {
      try {
        return JSON.parse(data)
      } catch(error) {
        return data
      }
    },
    serialize(data) {
      try {
        return JSON.stringify(data)
      } catch(error) {
        return data
      }
    }
  }

  /**
   * Get item from local storage
   */
  @action get = async (path: string): Promise<JsonAny> => {
    return this.setRequest(this.getStorageAdapter().getItem(this.namespace + path))
      .then(serialized => {
        const data = this.serializer.unserialize(serialized)
        debug('get', path, data)
        return data
      })
  }

  /**
   * Save item to local storage
   */
  @action set = async (path: string, data: JsonAny = {}): Promise<void> => {
    debug('set', { path, data })
    const serialized = this.serializer.serialize(data)
    this.getStorageAdapter().setItem(this.namespace + path, serialized)
    this.setRequest(serialized)
  }

}
