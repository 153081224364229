import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { hasGlobal } from "Relationships/RelationshipDecorators";
import stores from "Stores";
import ActionPlanner from "Stores/ActionPlanner";
import { TimeSpanItem } from "Stores/ActionPlanner/TimeSpan";
import { TimeSpanListDaySettings } from "Stores/ActionPlanner/TimeSpan/Settings";
import Model from "Stores/Model";
import { getPFASettingsStore } from "../../stores/PFASettingsStore";
import PFASettingsModel from "../PFASettings/PFASettingsModel";

export class PFAPlanScoreModel extends Model {

  @computed get pfaSettings(): PFASettingsModel {
    return getPFASettingsStore()
  }

  @hasGlobal(() => stores.ActionPlanner) actionPlanner: ActionPlanner

  @computed get dayTimespans(): TimeSpanListDaySettings {
    return this.actionPlanner.dayTimespans
  }

  @computed get executionRange(): number[] {
    return this.pfaSettings.thrashAcceptableRange.range
  }

  @computed get trackedTimespans(): TimeSpanItem[] {
    const { timespansToTrack } = this.pfaSettings
    return timespansToTrack.trackedTimespanTypes
      .map(type => this.dayTimespans.items.filter(timespan => type === timespan.type))
      .flat()
      .filter(timespan => timespan)
  }

  @computed get plannedTimespans(): TimeSpanItem[] {
    return this.trackedTimespans
      .filter(timespan => timespan.durationDone > 0)
  }

  // ---- plan planning ----

  @computed get totalPlannedTimespansDuration(): number {
    return this.plannedTimespans.reduce((total, timespan) => total + timespan.duration, 0)
  }

  @computed get totalPlannedTimespansTasksDuration(): number {
    return this.plannedTimespans.reduce((total, timespan) => total + timespan.tasksDuration, 0)
  }

  @computed get totalTrackedTimespansDurationDone(): number {
    return this.plannedTimespans.reduce((total, timespan) => total + timespan.durationDone, 0)
  }

  timespanPlanScore = computedFn((timespan: TimeSpanItem) => {
    return( timespan.tasksDuration / timespan.duration) || 0
  }, true)

  timespanPlanScorePercent = computedFn((timespan: TimeSpanItem) => {
    return Math.floor(this.timespanPlanScore(timespan) * 100)
  }, true)

  @computed get planScore(): number {
    return (this.totalPlannedTimespansTasksDuration / this.totalPlannedTimespansDuration) || 0 // 0/0 = NaN
  }

  @computed get planScorePercent(): number {
    return Math.floor(this.planScore * 100)
  }

  // ---- plan execution ----

  @computed get startOfTodayDate(): Date {
    return this.actionPlanner.startOfTodayDate
  }

  @computed get minsSinceStartOfDay(): number {
    return this.actionPlanner.minsSinceStartOfDay
  }

  // for testing only
  mockCurrentDate(date: Date) {
    this.actionPlanner.mockCurrentDate(date)
  }

  originalTasks = computedFn((timespan: TimeSpanItem) => {
    return timespan.tasks
  }, true)

  deletedTasks = computedFn((timespan: TimeSpanItem) => {
    return timespan.tasks.filter(task => task.trashed)
  }, true)

  reorderedTasks = computedFn((timespan: TimeSpanItem) => {
    return timespan.tasks.filter(task => {
      return task.reOrderDate
        && task.reOrderDate.getTime() >= this.startOfTodayDate.getTime()
    })
  }, true)

  @computed get totalPlannedTimespansPastDuration(): number {
    return this.plannedTimespans.reduce((total, timespan) => total + timespan.pastDuration, 0)
  }

  getTimespanRealtimeWeight = computedFn((timespan: TimeSpanItem) => {
    if (this.totalPlannedTimespansPastDuration === 0) return 0
    return timespan.pastDuration / this.totalPlannedTimespansPastDuration
  }, true)

  timespanDeletedScore = computedFn((timespan: TimeSpanItem): number => {
    const timespanWeight = this.getTimespanRealtimeWeight(timespan)
    const originalTasks = this.originalTasks(timespan)
    const deletedTasks = this.deletedTasks(timespan)
    const originalTasksDuration = originalTasks.reduce((sum, task) => sum + task.duration, 0)
    const deletedTasksDuration = deletedTasks.reduce((sum, task) => sum + task.duration, 0)
    const timespanScore = (deletedTasksDuration / originalTasksDuration) * timespanWeight
    return timespanScore || 0 // NaN when divide by 0
  }, true)

  @computed get deletedScore(): number {
    const deletedScore = this.plannedTimespans.reduce((score, timespan) => {
      return score + this.timespanDeletedScore(timespan)
    }, 0)
    return deletedScore
  }

  @observable orderScoreWeight: number = 0.5

  timespanOrderScore = computedFn((timespan: TimeSpanItem): number => {
    const timespanWeight = this.getTimespanRealtimeWeight(timespan)
      const originalTaskCount = this.originalTasks(timespan).length
      const reorderedTaskCount = this.reorderedTasks(timespan).length
      const timespanScore = (reorderedTaskCount / originalTaskCount) * timespanWeight
      return timespanScore || 0 // NaN when divide by 0
  }, true)

  @computed get orderScore(): number {
    const orderScore = this.plannedTimespans.reduce((score, timespan) => {
      return score + this.timespanOrderScore(timespan)
    }, 0)
    return orderScore * this.orderScoreWeight
  }

  timespanThrashScore = computedFn((timespan: TimeSpanItem): number => {
    return this.timespanDeletedScore(timespan) + this.timespanOrderScore(timespan)
  }, true)

  @computed get thrashScore(): number {
    const { deletedScore, orderScore } = this
    return deletedScore + orderScore
  }

  @computed get thrashScorePercent(): number {
    return Math.floor(this.thrashScore * 100)
  }

  @computed get executionScore(): number {
    const executionRange = this.pfaSettings.thrashAcceptableRange.range
    const rawExecutionPercent = (1 - this.thrashScore) * 100
    let executionScore = 100
    if (rawExecutionPercent < executionRange[0]) {
      executionScore = rawExecutionPercent / executionRange[0]
    } else if (rawExecutionPercent < executionRange[1]) {
      executionScore = executionRange[1] / rawExecutionPercent
    }
    return executionScore/100
  }

  @computed get executionScorePercent(): number {
    return Math.floor(this.executionScore * 100)
  }

  @computed get total(): number {
    const scores = [this.planScore, this.executionScore]
    const avgScore = scores.reduce((avg, score) => avg + score/scores.length, 0)
    return Math.min(1, avgScore)
  }

}