import React from 'react'
import Form from 'react-bootstrap/Form'
import { observer } from 'mobx-react'
import { getAppNotifications, Position } from '../../../../AppNotifications'

const debug = require('debug')('treks:form:control:user-editable-text')

let updateTimer = null

const UserEditableText = observer(({ user, field }) => {
  debug('render', { user, field })
  const value = user[field.name]

  const updateUser = async () => {
    debug('updating user', user)
    try {
      await user.edit()
      getAppNotifications().success({
        message: 'User updates saved!',
        position: Position.BOTTOM_LEFT
      })
    } catch(error) {
      getAppNotifications().error({
        message: 'User update failed to sync. You may be offline.',
        position: Position.BOTTOM_LEFT
      })
    }
    
  }

  const onChange = ({ target: { value }}) => {
    debug('onchange', value, field)
    user[field.name] = value
    clearTimeout(updateTimer)
    updateTimer = setTimeout(() => updateUser(), 1000)
  }

  return (
    <Form.Control 
      {...field}
      className="input-borderless"
      value={value}
      style={{
        width: value ? ( (value.length + 1) + 'ch') : '20px'
      }}
      onChange={onChange}
    />
  )
})

export default UserEditableText