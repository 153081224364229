import React from 'react'

const InfoTextFormControl = ({ info }) => {
  return (
    <div className="form-control-info-text">
      <div className="form-info-text">{info}</div>
    </div>
  )
}

export default InfoTextFormControl