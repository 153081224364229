import { observable, action, override } from 'mobx'
import { Item } from '../Lists'
import Invitation from '../Invitation'
import { importUser } from '../User'
import { UserI } from 'Stores/User/Type/User'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class MemberItem extends Item {

  @observable isCreating:boolean = false

  @observable role:string = ''

  @observable user: UserI = importUser().create()

  @action setUser(user: UserI) {
    this.user = user
  }

	@hasOne(() => Invitation)
  invitation:Invitation

  @override fromJSON(json) {
    const { userId } = json
    this.user = importUser().fromProps({ id: userId })
    this.user.fetched()
    return super.fromJSON(json)
  }

}