import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Button from '../theme/Button'
import Icon from '../theme/Icon'

class ModalContainer extends React.Component<{
  show: boolean;
  title: string;
  icon?: React.ReactNode;
  component?: React.ReactNode;
  children?: React.ReactNode;
  onHide: () => void;
  style?: React.CSSProperties;
}> {

  render() {
    const { show, title, icon, component, children, onHide, style } = this.props
    return (
      <Modal 
          show={show} 
          onHide={onHide}
          size="lg"
          centered
          style={style}
        >
          <Modal.Header>
            <Modal.Title>
              {
                typeof icon === 'string' ? (
                  <Icon set="fa" icon={icon} size="xs" color={'#808284'} className="modal-title-icon" />
                ) : (
                  icon
                )
              }
              <span>{title}</span>
            </Modal.Title>
            <Button variant="outline" onClick={onHide} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {component || children}
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(ModalContainer)