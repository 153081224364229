import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import 'theme/style/settings.css'
import './AdminUser.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import stores, { getStore } from 'Stores'
const debug = require('debug')('treks:admin:user:list')

const headerText = 'User Detail'

export default withRouter(observer(class AdminUserDetail extends React.Component {

  formStore = getStore(stores.Form)

  get query() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  render() {
    debug('render', { query: this.query })
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <Button />
          </Col>
        </Row>
      </Container>
    )
  }
}))