import { observable, action, override } from 'mobx'
import { importAccount } from './Account'
import ApiRequest from '../Service/ApiRequest'
import BillingApiRequest from '../Service/BillingApiRequest';

const debug = require('debug')('treks:store:account:register')

export default class AccountRegister extends importAccount() {

  /**
   * @property {string}
   */
  @override name = null

  /**
   * @property {string}
   */
  @override email = null

  /**
   * @property {string}
   */
  @override password = null

  /**
   * @property {string}
   */
  @override plan = null

  /**
   * @property {string}
   */
  @override domain = null

  /**
   * @property {string}
   */
  @override type = null

  /**
   * @property {boolean}
   */
  @override agreedToTOS = false

  /**
   * @property {string}
   */
  @observable token = null

  /**
   * @property {string}
   */
  @observable recaptchaToken = null

  /**
   * @property {ApiRequest}
   */
  @observable registerAccountState = new ApiRequest()

  /**
   * @property {ApiRequest}
   */
  @observable sendEmailState = new ApiRequest()

  /**
   * @property {ApiRequest}
   */
  @observable sendPasswordResetState = new ApiRequest()

  /**
   * @property {BillingApiRequest}
   */
  @observable createBillingAccountState = new BillingApiRequest()

  /**
   * @property {BillingApiRequest}
   */
  @observable createSubscriptionState = new BillingApiRequest()

  /**
   * @property {BillingApiRequest}
   */
  @observable subscriptionListState = new BillingApiRequest()

  /**
   * @property {BillingApiRequest}
   */
  @observable planListState = new BillingApiRequest()

  @action setQuestions(questions) {
    this.questions = questions
  }

  @action registerAccount() {
    const { name, email, plan, agreedToTOS, recaptchaToken } = this
    debug('create a user account', email)
    return this.registerAccountState.post('user/register', {
      name, email, plan, agreedToTOS, recaptchaToken
    })
  }

  @action sendVerificationEmail() {
    const { email } = this
    debug('send verification email', email)
    return this.sendEmailState.post('user/verification/send', {
      email
    })
  }

  @action sendPasswordResetEmail() {
    const { email } = this
    debug('send password reset email', email)
    return this.sendPasswordResetState.post('user/password/reset/send', {
      email
    })
  }

  @action resetAccountPassword() {
    const { password, token } = this
    debug('reset password', password)
    return this.sendPasswordResetState.post('user/password/reset/save', {
      password,
      token
    })
  }

  @action verifyEmailToken(token) {
    debug('verify email token', token)
    return this.sendEmailState.get('user/verification/receive', {
      token
    })
  }

  @action getFirstAndLastName() {
    const nameParts = this.name ? this.name.split(' ') : []
    const [ firstName, lastName ] = [ nameParts.shift(), ...nameParts]
    return { firstName, lastName }
  }

  @action createBillingAccount() {
    const { firstName, lastName } = this.getFirstAndLastName()
    debug('create user subscription', { firstName, lastName })
    Object.assign(this, { firstName, lastName })
    return this.createBillingAccountState.post('account/create', { 
      email: this.email,
      account_code: this.email,
      first_name: firstName, 
      last_name: lastName,
    })
      .then(({ data }) => {
        this.authorizeNetAccountId = data.authorizeNetAccountId
        return data
      })
  }

  @action createSubscription({ plan_code }) {
    const { firstName, lastName } = this.getFirstAndLastName()
    debug('create user subscription', { firstName, lastName })
    Object.assign(this, { firstName, lastName })
    return this.createSubscriptionState.post('subscription/add', { 
      email: this.email,
      account_code: this.email,
      first_name: firstName, 
      last_name: lastName,
      plan_code,
    })
  }

  getSubscriptionList() {
    debug('list subscriptions', this.email)
    return this.subscriptionListState.get('account/subscriptions')
  }

  getPlanList() {
    debug('list available plans')
    return this.planListState.get('plan/list')
  }

}