import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { action, observable, computed } from 'mobx';
import { getAppNotifications } from 'AppNotifications';
import TaskResizeHandle from '../Task/TaskResizeHandle'
import ActionPlannerStickyTaskHeader from './ActionPlannerStickyTaskHeader';
import { getBucketList } from 'Stores/MainPaneGroup';
import TaskItem from 'Stores/Task';
import ActionPlanner from 'Stores/ActionPlanner';
import { getStore, models } from 'Stores';
import { ResizableWithScroll } from 'Modules/Resizable/ResizableWithScroll';

const debug = require('debug')('treks:planner:task')

class ActionPlannerStickyTask extends React.Component {

  updateTimer = null

  isHovered = observable(false)
  get isFocused() {
    return computed(() => {
      const { actionPlanner, task } = this.props
      return actionPlanner.focusedEvent === task
    })
  }

  static defaultProps = {
    errorSaveEventMsg: 'Failed to save task updates',
    get actionPlanner() {
      return getStore(models.ActionPlanner)
    },
    get mainPaneGroup() {
      return getStore(models.MainPaneGroup)
    }
  }

  static propTypes = {
    task: PropTypes.instanceOf(TaskItem).isRequired,
    actionPlanner: PropTypes.instanceOf(ActionPlanner)
  }

  queueUpdate(fn, ms = 50) {
    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => fn(), ms)
  }

  onPress = () => {
    const { task, actionPlanner, mainPaneGroup } = this.props
    debug('press', task)
    actionPlanner.setFocusedEvent(task)
    const pane = getBucketList().findItem({ slug: 'task-detail' })
    mainPaneGroup.addOrUpdateItem(pane, { task })
  }

  onMouseOver = () => this.queueUpdate(() => this.isHovered.set(true))

  onMouseOut = () => this.queueUpdate(() => this.isHovered.set(false))

  @action onResizeStart = ({ height }) => {
    document.body.style.cursor = 'row-resize'
    debug('resize start', { height })
  }

  @action onResizeEnd = ({ height }) => {
    const { task, errorSaveEventMsg } = this.props
    debug('resize end', { height })
    document.body.style.cursor = ''
    task.save()
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(errorSaveEventMsg)
      })
  }

  @action onResize = ({ height }) => {
    debug('resize', { height })
    this.props.task.setHeight(height)
  }

  render() {
    const { task, plannerFlow, taskPiece } = this.props
    const { id, height } = task
    const size = { height }
    const { isHovered, isFocused } = this
    const className = "planner-task planner-sticky-task" 
      + (isFocused.get() ? ' focused' : '') 
      + (isHovered.get() ? ' hovered' : '')
    debug('render', { task })
    return (
      <ResizableWithScroll
        key={id}
        style={{ ...size, minHeight: 15*5 }} 
        className={className}
        onMouseDown={this.onPress}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        resizeHandleClassName={'action-planner-task-resize-handle'}
        // Handle={props => (
        //   <TaskResizeHandle
        //     plannerFlow={plannerFlow}
        //     taskPiece={taskPiece}
        //     {...props}
        //   />
        // )}
        resize={'vertical'}
        onResize={this.onResize}
        onResizeStart={this.onResizeStart}
        onResizeEnd={this.onResizeEnd}
      >
        <div className="task-container">
          <ActionPlannerStickyTaskHeader
            task={task}
            isHovered={isHovered.get()}
            isFocused={isFocused.get()}
          />
        </div>
      </ResizableWithScroll>
     )
  }
}

export default observer(ActionPlannerStickyTask)