import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import TaskDetailRow from '../TaskDetailRow'
import Avatar from '../../../theme/Avatar'

const Comment = observer(({ item }) => {
  const { name, profileImage } = item.user
  useEffect(() => {
    item.user.fetched()
  }, [item.user])
  return (
    <div className="item row-flex">
      <div className="item-col avatar">
        <Avatar name={name} src={profileImage} size={24} />
      </div>
      <div className="item-col item-body">
        <div className="item-attributes row-flex">
          <div className="name">{name}</div>
          <div className="since">{item.since}</div>
        </div>
        <div className="item-text">
          {item.text}
        </div>
      </div>
    </div>
  )
})

class TaskComments extends React.Component {

  render() {
    const { items } = this.props.commentList
    if (items.length === 0) return null
    return (
      <div className="container task-comment-list">
        <TaskDetailRow icon="comment">
          <div className="comment-list">
            {items.map(item => (
              <Comment key={item.uid} item={item} />
            ))}
          </div>
        </TaskDetailRow>
      </div>
    )
  }
}

export default observer(TaskComments)