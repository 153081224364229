import { EventEmitter2 as EventEmitter } from 'eventemitter2'
import { MqttClient } from 'mqtt';
import MQTTChannel from './channel'

const debug = require("debug")("treks:mqtt:mqtt");

export default class MqttTransport extends EventEmitter {

  id: string
  client: MqttClient
  channels: {}

  constructor({ id, client }) {
    super({
      wildcard: true,
      delimiter: '/',
      maxListeners: 20,
      newListener: false,
      verboseMemoryLeak: true
    });
    this.id = id;
    this.client = client;
    this.channels = {};
  
    this.client.on("message", (topic, message) => {
      var unserialized = this.unserialize(message);
      debug(
        "Received message",
        topic,
        "len: " + message.length,
        this.stringifyDebugMessage(unserialized)
      );
      this.emit(topic, unserialized);
    });
  
    this.client.on("connect", message => this.emit("connect", message));
  }

  channel(id: string) {
    const channel = this.channels[id] || new MQTTChannel({ client: this, id });
    this.channels[id] = channel;
    return channel;
  }
  
  /**
   * Serialize Object to JSON String Buffer
   */
  serialize(message: {}) {
    try {
      return Buffer.from(JSON.stringify(message));
    } catch (e) {
      console.error("Failed to serialize message", message);
      return null;
    }
  }
  
  /**
   * Unserialize JSON String Buffer to JSON Object
   */
  unserialize(message: any) {
    const jsonStr = message.toString();
    if (!jsonStr) {
      return jsonStr; // empty message
    }
    try {
      return JSON.parse(jsonStr);
    } catch (e) {
      debug("Failed to unserialize message", message, jsonStr);
      return null;
    }
  }
  
  subscribe(topic: string, fn: (message: any) => void) {
    debug("Subscribing to topic", topic);
    this.client.subscribe(topic);
    if (fn) {
      const event = topic.replace('+', '*') // event wildcards
      this.on(event, message => {
        debug("Receive subscribed message", topic);
        fn(message);
      });
      debug(">> Subscribed to event", event);
    }
  }
  
  publish(topic: string, message: any) {
    const serialized = this.serialize(message);
    debug("pulish message", topic, this.stringifyDebugMessage(message));
    this.client.publish(topic, serialized);
  }
  
  stringifyDebugMessage(message = "", length = 100) {
    let str: string;
    try {
      str = JSON.stringify(message);
    } catch (e) {
      str = "";
    }
    return typeof str === 'string' 
      ? str.substring(0, length) + (str.length > length ? "..." : "") 
      : str;
  }
}
