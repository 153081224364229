import React from 'react'
import { observer } from 'mobx-react'
import AvatarEdit from 'react-avatar-edit'
import Icon from '../../../theme/Icon'
import { getAppNotifications } from '../../../AppNotifications'

class App extends React.Component {

  avatarEditRef = null

  state = {
    preview: null,
    src: this.props.src
  }
  
  onClose = () => {
    this.setState({ preview: null })
    this.props.onCrop && this.props.onCrop(null)
  }
  
  onCrop = preview  => {
    this.setState({preview})
    this.props.onCrop && this.props.onCrop(preview)
  }

  onSave = event  => {
    event.preventDefault()
    this.props.onSave && this.props.onSave(this.state.preview)
  }

  onBeforeFileLoad = (elem) => {
    if(elem.target.files[0].size > 716800) {
      getAppNotifications().error("File is too big!");
      elem.target.value = "";
    }
  }
  
  render () {
    return (
      <div style={styles.container}>
        <a href="#save" onClick={this.onSave} style={styles.save}>
          <Icon icon={'task'} color={'#fff'} size={20} />
        </a>
        <AvatarEdit
          width={250}
          height={250}
          onCrop={this.onCrop}
          onClose={this.onClose}
          onBeforeFileLoad={this.onBeforeFileLoad}
          src={this.state.src}
        />
      </div>
    )
  }
}

export default observer(App)

const styles = {
  container: {
    overflow: 'hidden',
    position: 'relative',
  },
  save: {
    position: 'absolute',
    right: 5,
    top: 5,
    cursor: 'pointer',
    zIndex: 999
  }
}