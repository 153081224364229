import { observable, computed, action, runInAction } from 'mobx';

const debug = require('debug')('trek:stores:fetch')

/**
 * Requests map
 */
const Requests = new WeakMap()
/**
 * Responses map
 */
const Responses = new WeakMap()

/**
 * Wraps a WhatWG fetch (or any Promise) with observable state
 */
export default class Fetch {

  /**
   * @property {object} Response
   */
  @computed get Response() {
    return Responses.get(this)
  }
  set Response(Response) {
    Responses.set(this, Response)
  }

  /**
   * @property {Promise} Inflight fetch request
   */
  @computed get Request() {
    return Requests.get(this)
  }
  set Request(Request) {
    Requests.set(this, Request)
  }
  
  /**
   * Observable Fetch States
   */
  @observable fetched = false
  @observable fetching = false
  @observable lastFetch = null /** @deprecated */
  @observable startTime = null
  @observable endTime = null
  @observable error = null
  @observable busy = false

  /**
   * @property {int} Fetch duration in millisecs
   */
  @computed get duration() {
    const now = new Date()
    const start = this.startTime || now
    const end = this.endTime || now
    return end.getTime() - start.getTime()
  }

  /**
   * @property {int} Fetching state Timer
   */
  @observable fetchingBusyTimer = null

  /**
   * @property {int} Fetching busy state update delay in ms
   */
  @observable fetchingBusyDelay = 200

  /**
   * Fetch
   * @param {Promise} Fetch Request
   */
  constructor(Request) {
    if (Request) {
      this.fetch(Request)
    }
  }

  /**
   * Track a Request/Promise resolution states
   * @param {Promise} Request 
   * @return {Promise} Resolves to Response
   */ 
  @action async fetch(Request) {
    this.startFetch(Request)
    return Request
      .then(Response => {
        debug('then', Response)
        runInAction(() => this.Response = Response)
        return Response
      })
      .catch(error => {
        debug('error', error)
        this.setError(error)
        throw error
      })
      .then(Response => {
        debug('finally', Response)
        this.endFetch()
        return Response
      })
  }

  /**
   * Fetching busy state is delayed to prevent unnecessary UI busy states
   */
  @action startFetch(Request) {
    this.fetching = true
    this.Request = Request
    this.startTime = new Date()
    this.fetchingBusyTimer = setTimeout(
      () => runInAction(() => this.busy = true),
      this.fetchingBusyDelay
    )
    return Request
  }

  @action endFetch() {
    clearTimeout(this.fetchingBusyTimer)
    this.fetched = true
    this.fetching = false
    this.busy = false
    this.lastFetch = this.endTime = new Date()
  }

  @action setFetched(status) {
    this.fetched = status
  }

  @action setError(error) {
    this.error = error
  }

}
