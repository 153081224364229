import React from 'react'
import { observer } from 'mobx-react'
import ListViewTasksContainer from '../ListViewTasks'
import GoalList from 'Stores/Goal/GoalList'
import ListViewGoalDetail from './ListViewGoalDetail'
import './ListViewGoals.scss'
import { ListViewList } from '../Shared/ListViewContainer/models/ListViewList'
import { getStore } from 'Stores'

class ListViewGoalsContainer extends React.Component {

  static defaultProps = {
    parentLists: ListViewList.create(),
    headers: [ // order matters
      {
        name: 'parent',
        label: 'PROJECT:Category',
        required: true
      },
      {
        name: 'item_name',
        label: 'Goal Name',
        required: true
      },
      {
        name: 'due_date',
        label: 'Due Date'
      },
      {
        name: 'overdue_tasks',
        label: 'Overdue Tasks'
      },
      {
        name: 'duration',
        label: 'Duration'
      }
    ]
  }

  componentDidMount() {
    this.props.parentLists.setItems([
      getStore(GoalList)
    ])
  }

  render() {
    return (
      <ListViewTasksContainer
        {...this.props}
        className="list-view-goals"
        allowFlatList={false}
        ListViewTaskCategoryList={ListViewGoalDetail}
      />
    )
  }
  
}

export default observer(ListViewGoalsContainer)