import HabitItem from './HabitItem'
import List from 'Stores/Lists/List';
import { override } from 'mobx';
import { GoalItem } from 'Stores/Goal';
import { TeamItem } from 'Stores/Team';
import { OrganizationItem } from 'Stores/Organization';
import { ProjectItem } from 'Stores/Project';
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class HabitList extends List {

  get ModelType() {
    return HabitItem
  }

	@hasOne(() => GoalItem)
  goal:GoalItem

	@hasOne(() => TeamItem)
  team:TeamItem

	@hasOne(() => OrganizationItem)
  organization:OrganizationItem

	@hasOne(() => ProjectItem, (project: ProjectItem) => project.habits)
  project: ProjectItem

  @override async fetch() {
    const goalId = this.goal && this.goal.id
    const teamId = this.team && this.team.id
    const organizationId = this.organization && this.organization.id
    return this.fetchState.get('habit/list', { goalId, teamId, organizationId })
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}
