import { observable, computed } from 'mobx'
import { Item } from '../Lists'
import { User, UserList } from '../User'
import { TeamItem } from '../Team';
import { ProjectItem, ProjectList } from 'Stores/Project';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';
import { CategoryItem, CategoryList } from 'Stores/Category';

export default class OrganizationItem extends Item {

  @observable name:string = ''

  @observable description:string = ''

  @observable type:string = ''

  @observable createdTS:Date = new Date()

  @observable memberByInvitation:boolean = false

  @observable memberByEmailDomain:boolean = false

  @observable memberByRequest:boolean = false

  @observable memberOpen:boolean = false

  @observable billable:boolean = false

  @hasMany(() => ProjectList, (project: ProjectItem) => project.organization)
  projectList: ProjectList

  @hasMany(() => CategoryList, (category: CategoryItem) => category.organization)
  categoryList: CategoryList

  @hasMany(() => UserList, (user: User) => user.organizationList)
  memberList: UserList

  @hasOne(() => User, (user: User) => user.createdOrganizationList)
  createdBy: User

  @hasOne(() => TeamItem, (team: TeamItem) => team.organization)
  team: TeamItem

  @computed get paymentMethod() {
    // @todo: return this.getAttribute('paymentMethod', () => PaymentMethod.create())
    return this.getAttribute('paymentMethod', () => Item.create())
  }

}
