import { ModelPropsI } from "Stores/Model/Type/Model"
import { TimespanFlow } from "./Timespan/TimespanFlow"
import { TimespanPieceFlow } from "./TimespanPieceFlow"
import { TimespanPieceSplit } from "./Timespan/TimespanPieceSplit"
import { PlannerFlow } from "./PlannerFlow"

export const getTimespanFlowMerged = (
  timespans: TimespanFlow[], 
  plannerFlow: PlannerFlow
) => {
  let nextPiece: ModelPropsI<TimespanPieceFlow>
  let currentDuration = 0
  let prevType: string
  return  timespans
    .reduce((pieces, timespan) => {
      if (timespan.duration === 0) {
        return pieces
      }
      if (prevType === timespan.type) {
        const prevPiece = [ ...pieces ].pop()
        currentDuration += timespan.duration
        prevPiece.duration += timespan.duration
        prevPiece.timespans.push(timespan)
        return pieces
      } else {
        nextPiece = {
          uid: `merged-${timespan.uid}`,
          startDuration: currentDuration,
          duration: timespan.duration,
          type: timespan.type,
          plannerFlow,
          timespans: [timespan],
          flowType: 'timespanFlowMerged'
        }
        currentDuration += timespan.duration
        prevType = timespan.type
        return [ ...pieces, nextPiece ]
      }
    }, [])
    .map(piece => {
      return TimespanPieceFlow.fromProps(piece) as TimespanPieceFlow
    })
}

export const getTimespanFlowSplitAtDay = (
  timespanFlowMerged: TimespanPieceFlow[], 
  plannerFlow: PlannerFlow
): TimespanPieceFlow[] => {
  let endDuration = 0, startDuration = 0, currentDuration = 0
  const dayDuration = 24*60
  const splitPieces: TimespanPieceSplit[] =  timespanFlowMerged
    .reduce((pieces, piece) => {
      let nextPieces: TimespanPieceSplit[] = []
      let splitIndex = 0
      let pieceDurationLeft = piece.duration
      startDuration = endDuration % dayDuration
      endDuration = startDuration + pieceDurationLeft
      while(startDuration + pieceDurationLeft > dayDuration) {
        const duration = dayDuration - startDuration
        startDuration = (startDuration + duration) % dayDuration
        nextPieces.push({
          uid: `${piece.uid}-${splitIndex}`,
          type: piece.type,
          duration,
          piece,
          splitIndex,
          timespans: piece.timespans
        })
        splitIndex++
        pieceDurationLeft -= duration
      }
      if (pieceDurationLeft > 0) {
        nextPieces.push({
          uid: `${piece.uid}-${splitIndex}`,
          type: piece.type,
          duration: pieceDurationLeft,
          timespans: piece.timespans,
          piece,
          splitIndex,
        })
        splitIndex++
      }
      return [ ...pieces, ...nextPieces ]
    }, [])
  return splitPieces.map(splitPiece => {
    const piece = TimespanPieceFlow.fromProps({
      uid: `split-${splitPiece.uid}`,
      plannerFlow,
      group: splitPiece.piece,
      timespans: splitPiece.timespans,
      type: splitPiece.piece.type,
      flowType: 'timespanFlowSplitAtDay',
      duration: splitPiece.duration,
      startDuration: currentDuration
    })
    currentDuration += splitPiece.duration
    return piece
  }) as TimespanPieceFlow[]
}