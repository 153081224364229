import { observable, override } from 'mobx';
import ProjectItem from './ProjectItem'
import MemberItem from 'Stores/Member/MemberItem'
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class ProjectMemberItem extends MemberItem {

  @observable notifyConversations:boolean = false

  @observable notifyTasks:boolean = false

  @observable notifyStatus:boolean = false

	@hasOne(() => ProjectItem, (p: ProjectItem) => p.memberList)
  project:ProjectItem

  static create() {
    const model = super.create()
    // @todo save()
    // each model:ProjectMemberItem must use save()
    return model
  }

  @override async save() {
    return this.saveState.postJson('project/member/save/' + this.project.id, this.toJSON())
  }

  @override fromJSON(json) {
    const { projectId } = json
    this.project = ProjectItem.fromProps({ id: projectId })
    return super.fromJSON(json)
  }

}