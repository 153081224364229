import React from 'react'
import { observer } from 'mobx-react'
import ProjectSettingItem from './ProjectSettingItem'
import PropTypes from 'prop-types'
import Form from 'Stores/Form'
import { ProjectItem } from 'Stores/Project'

class ProjectSettingList extends React.Component {

  static propTypes = {
    project: PropTypes.objectOf(() => ProjectItem).isRequired,
    form: PropTypes.objectOf(() => Form).isRequired
  }

  render() {
    const { project, form } = this.props
    return (
      <div className="list-view setting-list">
        {
          form.fields.map(field => {
            return (
              <ProjectSettingItem
                key={field.name}
                field={field}
                project={project}
              />
            )
          })
        }
      </div>
    )
  }
}

export default observer(ProjectSettingList)
