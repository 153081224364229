import React from 'react'
import { observer } from 'mobx-react'

class GoalDataItem extends React.Component {

  onChange = event => {
    this.props.item.value = event.target.value
    this.props.item.save()
  }

  render() {
    const { item } = this.props
    return (
      <input 
        onChange={this.onChange}
        value={item.value}
        className={'title title-input input-borderless'}
        placeholder={'Enter Value'}
      />
    )
  }
}

export default observer(GoalDataItem)
