import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectPeople.scss'
import Pressable from 'theme/Pressable'
import ProjectMemberItem from 'Stores/Project/ProjectMemberItem';
import DetailPeopleList from 'Components/ui/Detail/DetailPeople/DetailPeopleList';
import stores, { getStore } from 'Stores';

const debug = require('debug')('treks:project:people')

class ProjectPeople extends React.Component {

  static defaultProps = {
    get userList() {
      return getStore(stores.UserList)
    }
  }

  onAddMember = () => {
    const { project } = this.props
    const member = ProjectMemberItem.fromProps({ isCreating: true })
    project.memberList.addItem(member)
  }

  render() {
    const { project, userList } = this.props
    debug('render', { project, userList })
    return (
      <div className="project-people">
        <header className="row tab-header">
          <Icon icon="assign" size={15} />
          <div className="tab-title">PEOPLE</div>
        </header>
        <div className="project-people-list">
          <DetailPeopleList
            item={project}
            userList={userList}
          />
          <Pressable
            className="btn-add-member"
            onClick={this.onAddMember}
          >
            + Add Member
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(ProjectPeople)
