/**
 * Cross browser and device event
 */
export default class NormalizedEvent {

  event = null

  constructor(event) {
    this.event = event.nativeEvent || event
  }

  preventDefault() {
    this.event.cancelable && this.event.preventDefault()
  }

  stopPropagation() {
    this.event.stopPropagation()
  }

  get target() {
    return this.event.target
  }

  composedPath() {
    return this.event.composedPath() || this.event.path || [this.target]
  }

  get cancellable() {
    return this.event.cancellable
  }

  get clientX() {
    return this.event.changedTouches ? this.event.changedTouches[0].clientX : this.event.clientX
  }

  get clientY() {
    return this.event.changedTouches ? this.event.changedTouches[0].clientY : this.event.clientY
  }

  get button() {
    return typeof this.event.button !== undefined ? this.event.button : this.event.which - 1
  }

}