import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import PopOver from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import Pressable from 'theme/Pressable'
import { getAppNotifications } from '../../../../AppNotifications'
import './DetailDateTime.scss'
import DatePicker from 'Modules/DatePicker'

const debug = require('debug')('treks:task:due-date')

class DetailDateTime extends React.Component {

  inputRef = null
  containerRef = null

  static defaultProps = {
    dateTime: null,
    list: null,
    item: null,
    onChange: null,
    disabled: false
  }

  state = {
    showTooltip: false,
    inputValue: null,
    showTime: false
  }

  hideTooltip() {
    this.setState({ showTooltip: false })
  }

  setInputValue(inputValue) {
    this.setState({ inputValue })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { item, list, dateTime } = nextProps
    // reset state when new item
    if (item !== this.props.item) {
      this.setState({
        inputValue: '',
        showTime: false,
        showTooltip: false
      });
    } else {
      // set inputValue from props
      this.setState({
        inputValue: list.formatDateTime(dateTime)
      })
    }
  }

  componentDidUpdate() {
    if (this.state.showTooltip) {
      document.addEventListener('click', this.onClickApp)
    } else {
      document.removeEventListener('click', this.onClickApp)
    }
  }

  onClickApp = event => {
    if (!event.path.includes(this.containerRef) 
      && !event.path.includes(document.querySelector('.bs-popover-bottom'))) {
      debug('path', event.path)
      this.hideTooltip()
    }
  }

  onInputRef = ref => this.inputRef = ref

  onInputChange = event => {
    this.setInputValue(event.target.value)
  }

  onInputFocus = () => {
    this.setState({ showTooltip: true })
  }

  onInputBlur = () => {
    const { onChange } = this.props
    const dateTime = moment(this.state.inputValue)
    if (this.state.inputValue && !dateTime.isValid()) {
      return getAppNotifications().warn({
        message: 'Please enter a valid date',
      })
    }
    onChange && onChange(dateTime.toDate())
  }

  onChangeDate = dateTime => {
    const { item, list } = this.props
    const time = moment(dateTime).isValid() 
      ? list.getTimeInMinutesFromDate(dateTime) : 0
    debug('due date changed', dateTime, time, item)
    dateTime = new Date(dateTime.setHours(0, time))
    this.onChange(dateTime)
  }

  onChangeTime = dateTime => this.onChange(dateTime)

  onChange = dateTime => {
    this.syncDueDateAndInputValue(dateTime)
    debug('due date or time changed', dateTime)
    this.hideTooltip()
  }

  syncDueDateAndInputValue(dateTime) {
    const { list, onChange } = this.props
    const inputValue = list.formatDateTime(dateTime)
    onChange && onChange(dateTime)
    this.setInputValue(inputValue)
  }

  onClickContainer = event => {
    debug('clicked', event)
    event.stopPropagation()
    event.preventDefault()
  }

  onContainerRef = ref => {
    this.containerRef = ref
  }

  onShowDueTimePicker = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showTime : true })
  }

  onHideDueTimePicker = event => {
    const { dateTime } = this.props
    event.preventDefault()
    event.stopPropagation()
    if (this.isDateValid(dateTime)) {
      this.syncDueDateAndInputValue(this.removeTimeFromDate(dateTime))
    }
    this.setState({ showTime : false })
  }

  removeTimeFromDate(date) {
    return new Date(date.setHours(0, 0, 0, 0))
  }

  isDateValid(date) {
    return moment(date).isValid()
  }

  render() {
    const { dateTime, item, list, disabled } = this.props
    const dateTimeIsValid = this.isDateValid(dateTime)
    const { showTime } = this.state
    const dateTimeFormatted = dateTimeIsValid ? list.formatDateTime(dateTime) : ''
    debug('render', { item, dateTime })
    return (
      <div className="detail-date-time" onClick={this.onClickContainer} ref={this.onContainerRef}>
        <input
          ref={this.onInputRef}
          className="date-time input-borderless"
          value={this.state.inputValue !== null ? this.state.inputValue : dateTimeFormatted}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          disabled={disabled}
        />
        <Overlay target={this.inputRef} show={!!this.state.showTooltip} placement="bottom">
          {props => (
            <PopOver {...props} show="true">
              <DatePicker
                selected={dateTimeIsValid ? dateTime : new Date()}
                onChange={this.onChangeDate}
                inline
              />
              {
                showTime ?
                <div className="dueTimePicker">
                  <h6 className="dueTimeLabel">Due Time</h6>
                  <DatePicker
                    selected={moment(dateTime).isValid() ? dateTime : new Date()}
                    onChange={this.onChangeTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                  <Pressable onClick={this.onHideDueTimePicker} className="btn-close">
                    x
                  </Pressable>
                </div> : 
                <Pressable onClick={this.onShowDueTimePicker}>Add Due Time</Pressable>
              }
            </PopOver>
          )}
        </Overlay>
      </div>
    )
  }
}

export default observer(DetailDateTime)