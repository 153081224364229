import { observable, action, computed, when, makeObservable, intercept, reaction, IReactionPublic, IReactionOptions, IReactionDisposer } from 'mobx';
import { uid, factory } from '../utils';
import ModelStore from './ModelStore'
import ModelApiMiddlewareStack from 'services/api/middleware/ModelApiMiddlewareStack';
import { ApiRequest, LocalStorageRequest } from 'Stores/Service';
import { ModelI, ModelJsonI, ModelPropKeyI, ModelPropsI } from './Type/Model';
import { DateModel } from './DateModel';
import { ModelContext } from './ModelContext';
import { getModel, findModel, getDefinedObjectPropertyNames, findMany  } from './ModelUtils'
import { initializeRelationships } from 'Relationships/RelationshipInitializer';
import { EntityModel } from 'Entity/EntityModel';

const debug = require('debug')('treks:model')
const warn = require('debug')('treks:model:warn')

// keys are model.constructor which is ok since they are unlikely to be Proxy()ed. 
// Proxy(model).constructor === model.constructor
const __propKeysMap = new WeakMap()
const __dynamicPropKeysMap = new WeakMap()

// keys are directly on Model instance and must be symbols as model !== Proxy(model)
const __attributesKey = Symbol('attributes')
const __memoizedAttributesKey = Symbol('memoized attributes')


/**
 * Generic Base Model
 * Separated out to List extends from here instead of Model
 */
export default class Model {

  static instanceOfModel(model: Model|object) {
    return model instanceof Model || model instanceof EntityModel
  }

  isModel = true

  __this__: this

  static strictEntities = false;

  get ModelType(): typeof Model {
    return this.constructor as typeof Model
  }

  isObservable:boolean = false
  @action onObservable() {}

  makeObservable() {
    if (!this.isObservable) {
      makeObservable(this)
      this.isObservable = true
      if (this.onObservable) {
        this.onObservable()
      }
    }
  }

  /**
   * Assert two models are the same regardless of Proxy (ItemContext)
   */
   static isSameModel(model1:Model, model2:Model) {
    return (model1 && model2) 
      && (model1.__this__ === model2.__this__)
  }

  /**
   * Only point where model creation is allowed
   * @link https://www.typescriptlang.org/docs/handbook/utility-types.html#instancetypetype 
   * @link https://stackoverflow.com/questions/34098023/typescript-self-referencing-return-type-for-static-methods-in-inheriting-classe 
   */
  static create<T extends typeof Model>(this: T, observable: boolean = true, realtionships: boolean = true, entity: boolean = true): InstanceType<T> {
    const model = new this()
    if (entity) model.addToEntityManager() // before relationships
    if (observable) model.makeObservable()
    if (realtionships) initializeRelationships(model)
    return model as InstanceType<T>
  }

  /**
   * Create an instance from props
   * @param {Props} props 
   */
  static fromProps<T extends typeof Model>(this: T, props?: ModelPropsI<InstanceType<T>>): InstanceType<T> {
    let model = props && (props.id || props.uid) && findModel(this, props)
    if (!model) model = this.create()
    if (props) model.setProps(props)
    return model as InstanceType<T>
  }
  
  /**
   * Find an instance by Id and set JSON 
   * or create a new instance from JSON
   */
  static fromJSON<T extends typeof Model>(json: ModelJsonI<InstanceType<T>>): InstanceType<T> {
    let model = json && findModel(this, json)
    if (!model) model = this.create()
    if (json) model.setJSON(json)
    return model as InstanceType<T>
  }

  /**
   * Find a model by { id } or create one with this id
   */
  static fromId<T extends typeof Model>(id: string): InstanceType<T> {
    let model = findModel(this, { id })
    if (!model) {
      model = this.create()
      model.setProps({ id })
    }
    return model as InstanceType<T>
  }

  /**
   * Find a model by uid or create one with this uid
   */
  static fromUid<T extends typeof Model>(uid: string): InstanceType<T> {
    let model = findModel(this, { uid })
    if (!model) {
      model = this.create()
      model.setProps({ uid })
    }
    return model as InstanceType<T>
  }

  constructor() {
    this.__this__ = this
    if (arguments.length > 0) {
      throw new Error(
        `Models must be constructed without props or use Model.fromProps(props)`
      )
    }
  }

  /**
   * @property {array} List of props to ignore
   */
  static ignoredProps = [
    'ignoredProps',
    'modelName',
    'attributes',
    'memoizedAttributes',
    'store',
    'fetchState',
    'saveState',
    'localState',
    'isNew',
    'itemsWasSet',
    'saveTimeout',
    'useModelMap',
    'apiMiddleware',
    'isObservable',
    'currentDate',
    'onSetUidHandlers',
    'onSetIdHandlers',
    'willSaveIntervalMs',
    'willSaveTimmer',
    'contexts',
    'isModel'
  ]

  /**
   * @property {Store} Handles persistent storage of model
   */
 get store() {
    return this.getAttribute('store', () => factory(ModelStore))
  }
  set store(store) {
    this.setAttribute('store', store)
  }

  get apiMiddleware() {
    return this.getAttribute('apiMiddleware', () => new ModelApiMiddlewareStack(this))
  }
  set apiMiddleware(middleware) {
    this.setAttribute('apiMiddleware', middleware)
  }

  useApiMiddleware(fn:Function) {
    this.apiMiddleware = this.apiMiddleware.use(fn)
  }

  /**
   * @property {string} Model Namespace
   */
  get modelName():string {
    return this.constructor.name
  }

  _uid = uid()

  /**
   * @property {string} Memoized UID
   */
  @computed get uid():string {
    return this.getAttribute('uid') || this._uid
  }
  set uid(uid:string) {
    const existing = this.getAttribute('uid')
    if (existing && existing !== uid) {
      throw new Error('Cannot overwrite an existing model UID')
    }
    const model = this.findModel({
      uid
    })
    if (model && !Model.isSameModel(this, model)) {
      throw new Error('A model with this uid already exists in EntityManager')
    }
    this.fireSetUid(uid)
    this.setAttribute('uid', uid)
  }

  /**
   * @property {string} Id is always a string
   */
  @computed get id():string {
    return this.getAttribute('id')
  }
  set id(id: string) {
    const existing = this.getAttribute('id')
    if (existing && existing !== id) {
      throw new Error('Cannot overwrite an existing model ID')
    }
    const model = this.findModel({
      id
    })
    if (model && !Model.isSameModel(this, model)) {
      throw new Error('A model with this id already exists in EntityManager')
    }
    this.fireSetId(id)
    this.setAttribute('id', id)
  }

  onSetIdHandlers = []
  onSetId(handler: (change: any) => any) {
    this.onSetIdHandlers.push(handler)
  }

  fireSetId(id: string) {
    const change = {
      get oldValue() {
        return this.id
      },
      newValue: id,
      type: 'update'
    }
    this.onSetIdHandlers.forEach(handler => handler(change))
  }

  onSetUidHandlers = []
  onSetUid(handler: (change: any) => any) {
    this.onSetUidHandlers.push(handler)
  }

  fireSetUid(uid: string) {
    const change = {
      get oldValue() {
        return this.uid
      },
      newValue: uid,
      type: 'update'
    }
    this.onSetUidHandlers.forEach(handler => handler(change))
  }

  

  /**
   * @property {map} Dynamic map of attributes
   */
  ;[__attributesKey]:any = observable(new Map());

  /**
   * @property {map} Dynamic map of memoized attributes
   * @note Not observed
   */
  [__memoizedAttributesKey]:any = new Map();

  /**
   * @property {number} Prevent infinite recusion in cyclic referencing props
   */
  static maxPropDepth:number = 5

  /**
   * @property {boolean} has not been persisted
   */
  @computed get isNew():boolean {
    return !this.id && parseInt(this.id, 10) !== 0
  }

  @computed get localState(): LocalStorageRequest {
    return this.getAttribute('localState', () => new LocalStorageRequest(this.modelName))
  }

  @computed get fetchState(): ApiRequest {
    return this.getAttribute('fetchState', () => new ApiRequest())
  }

  @computed get saveState(): ApiRequest {
    return this.getAttribute('saveState', () => new ApiRequest())
  }

  @action async fetch(path?: string, data?: ModelJsonI<this>): Promise<this> {
    const { id } = this
    debug('fetch', { id })
    const resp = await this.fetchState.get(path || this.modelName.toLowerCase() + '/fetch/' + id, data)
    return (this.constructor as typeof Model).fromJSON({ id, ...resp }) as this
  }

  /**
   * Ensures model was fetched, or fetches and waits for fetch to complete
   */
  async fetched(): Promise<Model> {
    if (!this.fetchState.wasFetched) {
      return this.fetch()
    } else {
      await when(() => this.fetchState.isFetched)
    }
    return this
  }

  async save(): Promise<Model>;
  @action async save(json?: any, path?:string): Promise<Model> {
    if (!json) json = await this.toJSON()
    const payload = await this.apiMiddleware.call('save', json)
    console.log('save', { json, payload })
    const resp = await this.saveState.post(path || this.modelName.toLowerCase() + '/save', payload)
    return this.setJSON(resp) // set id directly as this is a new model
  }

  async saveJson(json = null, path = null): Promise<Model> {
    if (!json) json = await this.toJSON()
    const payload = await this.apiMiddleware.call('save', json)
    debug('save', { json, payload })
    const resp = await this.saveState.postJson(path || this.modelName.toLowerCase() + '/save', json)
    return this.setJSON(resp) // set id directly as this is a new model
  }

  willSaveTimmer = null
  willSaveIntervalMs = 500
  async willSave(willSaveIntervalMs = this.willSaveIntervalMs): Promise<this> {
    if (this.willSaveTimmer) clearTimeout(this.willSaveTimmer)
    return await new Promise(resolve => {
      this.willSaveTimmer = setTimeout(async () => {
        await this.save()
        resolve(this)
      }, willSaveIntervalMs)
    })
  }

  async saveLocal() {
    const json = this.toJSON()
    await this.localState.set(this.modelName, json)
  }

  @action async fetchLocal() {
    const json = await this.localState.get(this.modelName)
    this.setJSON({ ...json })
  }

  /**
   * Set the model props 
   *  Recursively calls setProps() for properties of type Model
   * 
   * @example
   * 
   *  if we have a model: 
   *    Member { title:string, project:Project, user:User }
   *  then 
   *    member.setProps({ title, project, user }) 
   *  will 
   *    member.title = title
   *    member.project.setProps(project)
   *    member.user.setProps(user)
   * 
   * @todo Test infinite recursion
   *  setProps(User) -> setProps(project) -> setProps(user) ...
   * 
   * @todo Optimize
   *  Any model prop should be computed
   *  setProps() should set private.props
   *  get model() should call setProps() on model and return instance
   * 
   */
  @action 
  setProps(props: ModelPropsI<this>, depth:number = Model.maxPropDepth) {
    if (depth === 0) {
      warn('Maximum prop depth reached', { model: this, props, depth })
      return
    }
    if (!props) {
      throw new TypeError("Props must not be empty")
    }
    // serialize models first
    if (props instanceof Model) {
      throw new TypeError('props must not be a Model')
    }
    this._setProps(props, depth)
  }

  private _setProps<T extends Model, K extends keyof Model>(props: ModelPropsI<this>, depth:number) {
    Object.keys(props).forEach(key => {
      const value = props[key]
      this.setProp(key as K, value, depth)
    })
  }

  @action
  setProp(key: keyof this, value: any, depth = Model.maxPropDepth) {
    if (this[key] === value) return // noop
    try {
      // prevent setting non-props
      if (this.hasProp(key)) {
        const currentProp = this.getProp(key)
        // if prop is model and value is model, overwrite
        // if prop is model and value is object, setProps
        // otherwise overwrite value
        if (currentProp instanceof Model && !(value instanceof Model) && value instanceof Object) {
          currentProp.setProps(value, depth)
        } else {
          this[key] = value
        }
      } else {
        const props = this.getPropKeys().join(', ')
        throw new TypeError(`Trying to set property "${String(key)}" on model ${this.constructor.name}.
          This property is not defined in the class properties. 
          The defined properties are: ${props}`)
      }
    } catch(error) {
      warn('Error setting prop', { key, value, depth })
      throw error
    }
  }

  hasAttribute(key: keyof this) {
    return this[__attributesKey].has(key)
  }

  setAttribute(key: keyof this, value: any) {
    this[__attributesKey].set(key, value)
  }

  /**
   * Retrieve an attribute 
   * @param {string} key 
   * @param {any|function} defaultValue returned attribute is falsy
   *  if defaultValue is a {function} it is called once and memoized
   */
  getAttribute(key: keyof this, defaultValue?: any) {
    if (this[__attributesKey].has(key)) {
      return this[__attributesKey].get(key)
    }
    if (!this[__memoizedAttributesKey].has(key)) {
      this[__memoizedAttributesKey].set(key, typeof defaultValue === 'function' ? defaultValue() : defaultValue)
    }
    return this[__memoizedAttributesKey].get(key)
  }

  /**
   * Visual display of model value
   * @important Do not rename to toString() 
   */
  toJsonString() {
    return JSON.stringify(this.toJSON())
  }

  /**
   * Visual display of model value
   * @note toString() is used internally by MobX
   */
   toString() {
    return this.modelName
  }

  /**
   * Model label for form fields and other visual display
   */
  toLabel() {
    return this.constructor.name.replace(/(?!^)([A-Z])/g, match => ' ' + match);
  }

  /**
   * Storable JSON serialized representation of model data
   * @todo create true JSON structure. 
   * @todo atm we leave it be to prevent cycling through all props tree, 
   * @todo which is inefficient and may have recursive cycles
   */
  toJSON(): ModelJsonI<this> {
    const props = this.getProps()
    const json = Object.entries(props)
      .filter(([key]) => !key.startsWith('_')) // skip private keys
      .reduce((json, [key, value]) => {
        return ({
          ...json, 
          [key]: value instanceof Model 
            ? { id: value.id, uid: value.uid } 
            : (Array.isArray(value) ? value.map(item => item) : value)
        })
      }, {})
    debug('toJSON', { model: this, props, json })
    return { ...json }
  }

  /**
   * JSON serialization handling Promises
   * @note handles promises only 1 level deep
   */
  async asyncToJSON(): Promise<ModelJsonI<this>> {
    const props = this.getProps()
    const json = (await Promise.all(
        Object.entries(props)
          .filter(([key]) => !key.startsWith('_')) // skip private keys
          .map(async ([key, value]) => ([
            key, 
            (await value) instanceof Model ? { uid: (value as Model).uid } : value
          ]))
      ))
      .reduce((entries, [key, value]) => ({ ...entries, [key as string]: value }), {})
    debug('asyncToJSON', { model: this, props, json })
    return json
  }

  /**
   * A flat JSON ref to the model
   */
  toJSONRef(): ModelJsonI<this> {
    const { uid, id } = this
    const json = { uid, id }
    debug('toJSONRef', { model: this, json })
    return json as ModelJsonI<this>
  }

  /**
   * Supercedes this.fromJSON()
   * @note may return a new model if JSON maps to existing model id|uid
   */
  setJSON(json: ModelJsonI<this>): Model {
    return this.fromJSON(json as ModelJsonI<this>)
  }

  setPropJSON(key: string, json: any) {
    if (this.hasProp(key)) {
      const currentProp = this.getProp(key)
      // same behaviour as setProps but ignores non-keys
      if (currentProp instanceof Model && !(json instanceof Model) && json instanceof Object) {
        currentProp.setJSON(json)
      } else {
        this[key] = json
      }
    }
  }

  /**
   * Deserialize json into model
   * @note may return a new model if JSON maps to existing model id|uid
   * @note Warns on invalid keys as opposed to setProps() which errors
   * @param {JSON} json 
   * @return {Model}
   * @deprecated Use static method Model.fromJSON(json) and prefer this.setJSON(json) 
   */
  @action fromJSON(json: ModelPropsI<this>): this {
    if (!json) return this
    const model = this
    const emModel = this.findModel(json)
    if (emModel && !Model.isSameModel(emModel, model)) {
      warn('fromJSON() duplicate model in EnityManager', model, this)
      throw new Error('fromJSON() duplicate model in EnityManager')
    }
    const keys = model.getPropKeys()
    const validPropKeys = Object.keys(json).filter(key => keys.includes(key as ModelPropKeyI<this>))
    const invalidPropKeys = Object.keys(json).filter(key => !keys.includes(key as ModelPropKeyI<this>))
    if (invalidPropKeys.length) {
      warn('JSON data has invalid props not defined in this model', 
        { model, json, invalidPropKeys })
    }
    const jsonProps = validPropKeys.reduce((next, key) => {
      return key in json ? { ...next, [key]: json[key] } : next
    }, {})
    Object.entries(jsonProps).forEach(([key, value]) => {
      this.setPropJSON(key, value)
    })
    return model
  }

  @action async asyncFromJSON(json: ModelPropsI<this>): Promise<this> {
    return this.constructor.prototype.fromJSON(json)
  }

  hasProp(key) {
    return this.getPropKeys().includes(key)
  }

  getProp(key) {
    return this[key]
  }

  getPropType(key:string) {
    return this.constructor.prototype[key]
  }

  getProps(keys?: ModelPropKeyI<this>[]): ModelPropsI<this> {
    if (!keys) keys = this.getPropKeys()
    const props = keys.reduce((props, key) => {
      props[key as string] = this[key]
      return props
    }, {})
    return props
  }

  private _getDynamicPropKeys(): ModelPropKeyI<this>[] {
    const constructor = (this.constructor as typeof Model)
    if (!__dynamicPropKeysMap.get(constructor)) {
      __dynamicPropKeysMap.set(constructor, [])
    }
    return __dynamicPropKeysMap.get(constructor)
  }

  private _getPropKeys(): ModelPropKeyI<this>[] {
    const constructor = (this.constructor as typeof Model)
    // caching means properties created dynamically after this call are not included
    // this is desired for models as dynamic props are not allowed
    // only props explicitly defined on the model definition are allowed
    if (!__propKeysMap.get(constructor)) {
      const keys = getDefinedObjectPropertyNames(this)
        .filter(key => !constructor.ignoredProps.includes(key))
      __propKeysMap.set(constructor, keys)
    }
    return __propKeysMap.get(constructor)
  }

  getPropKeys(): ModelPropKeyI<this>[] {
    const propKeys = this._getPropKeys()
    const dynamicPropKeys = this._getDynamicPropKeys()
    return [ 
      ...propKeys, 
      ...dynamicPropKeys
    ]
  }

  /**
   * allow setting property keys dynamically
   */
  addPropKey(key: string) {
    this._getDynamicPropKeys().push(key as keyof this)
  }

  addPropKeys(keys: string[]) {
    this._getDynamicPropKeys().push(...keys as ModelPropKeyI<this>[])
  }

  static findByUid<M extends typeof Model>(uid): InstanceType<M> {
    return findModel(this, { uid }) as InstanceType<M>
  }

  static findById(id) {
    return findModel(this, { id })
  }

  findModel(props) {
    return this.store.em.find(this.constructor, props)
  }

  static findModel(props) {
    return findModel(this, props)
  }

  static findMany(props) {
    return findMany(this, props)
  }

  /**
   * Get the given model from Registry
   * @note Overriable per model instance
   * @param {any} props
   */
  getModel(props) {
    const model = this.store.fromProps(this.constructor, props)
    debug('getModel', this.constructor.name, props, model)
    return model
  }

  static getModel(props) {
    const model = getModel(this, props)
    debug('static getModel', this.name, props, model)
    return model
  }

  /**
   * Save model to Registry (Will replace model with matching uid)
   * @note Overriable per model instance
   * @param {Model} [model]
   */
  setModel(model) {
    if (!(model instanceof Model)) {
      throw new TypeError('Parameter model must be an instance of Model')
    }
    return this.store.setModel(model)
  }

  /**
   * Add model to Registry (Will not replace any models)
   * @note Overriable per model instance
   * @param {Model} [model]
   */
  addModel(model: Model) {
    if (!(model instanceof Model)) {
      throw new TypeError('Parameter model must be an instance of Model')
    }
    return this.store.addModel(model)
  }

  addToEntityManager() {
    return this.addModel(this)
  }

  /**
   * @property {Date} Date updated every second 
   */
  @computed get currentDate() {
    return DateModel.currentDate
  }
  set currentDate(date: Date) {
    throw new Error('Use mockCurrentDate()')
  }

  @action mockCurrentDate(date: Date) {
    DateModel.currentDate = date
  }

  // contexts
  contexts: ModelContext<Model>[] = []

  addContext(context) {
    this.contexts.push(context)
  }

  // observers

  intercept(key: string, fn: any) {
    debug('model.intercept', { i: this, key, fn })
    this.makeObservable()
    return intercept(this as any, key, fn)
  }

  reaction<T extends this>(
    expression: (r: IReactionPublic) => T, 
    effect: (arg: T, prev: T, r: IReactionPublic) => void, 
    opts?: IReactionOptions<T, boolean>
  ): IReactionDisposer {
    return reaction(expression, effect, opts)
  }

}

// debugging
Object.defineProperty(global, 'Model', {
  get: () => Model
})
