import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import 'filepond/dist/filepond.min.css'
import Icon from 'theme/Icon'
import DetailFileItem from './DetailFileItem'
import DetailFileUploadModal from './DetailFileUploadModal'

const debug = require('debug')('treks:detail:files')

class DetailFiles extends React.Component {

  static defaultProps = {
    fileList: []
  }

  state = observable({
    showModal: false
  })

  setState(state) {
    Object.assign(this.state, state)
  }

  handleShow = event => {
    event.preventDefault()
    if (this.props.item.done) return
    this.setState({ showModal: true })
  }

  onModalClose = files => {
    console.log('onModalClose files', files, this.props.item.fileList)
    this.setState({ showModal: false })
    this.props.item.fileList.addItemsFromFiles(files)
    files.forEach(file => file.save())
  }

  render() {
    const { fileList } = this.props.item
    console.log('render', { fileList })
    return (
      <div className="event-file-list">
        {
          fileList.items.map(fileItem => {
            return <DetailFileItem key={fileItem.id} fileItem={fileItem} />
          })
        }
        <a 
          onClick={this.handleShow} 
          className="file-add" 
          href="/file/add"
        >
          {
            fileList.items.length > 0 ? <Icon 
              set="fa" 
              icon={'file-upload'} 
              size="xs" 
              color={'#808284'} 
            /> : null
          }
          <span className="file-add-label">
            Attach Files
          </span>
        </a>
        <DetailFileUploadModal show={this.state.showModal} onClose={this.onModalClose} />
      </div>
    )
  }
}

export default observer(DetailFiles)