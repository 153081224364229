import { intercept, makeAutoObservable } from "mobx"

const debug = require('debug')('treks:DateModel')

const state = { timer: null }

/**
 * A timer for all models that emits every second
 * To use create a computed which will observe Model.currentDate 
 * 
 * Example: 
 * 
  @computed get currentDate() {
    return DateModel.currentDate
  }
 */
export const DateModel = makeAutoObservable({

  currentDate: new Date(),

  setDateUpdateInterval: (ms: number) => {
    if (state.timer) clearInterval(state.timer)
    state.timer = setInterval(() => {
      DateModel.currentDate = new Date()
    }, ms)
  },

})


intercept(DateModel, (change) => {
  debug('DateModel.currentDate', change)
  if (change.type === 'update' && !(change.newValue instanceof Date)) {
    console.error('DateModel.currentDate must be a Date', change.newValue)
    return false
  }
  return change
})

// update every 10s
DateModel.setDateUpdateInterval(1000*10)

// debugging
Object.defineProperty(global, 'DateModel', {
  get: () => DateModel
})

// eg: debugging
// DateModel.currentDate = new Date(Date.now() - 10*60*60*1000+60*10000+10)
// DateModel.setDateUpdateInterval(1000*1000)