import React from 'react'
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:ActionPlanner:EventContextMenu')

export default class EventContextMenu extends React.Component {

  static defaultProps = {
    removeEventLabel: 'Remove Event from ActionPlanner',
    removeEventSuccessMsg: 'The Event has been removed from your action planner',
    removeEventFromPlannerFailMsg: 'Failed to remove event from planner',
    deleteEventLabel: 'Delete Event',
    deleteEventFailMsg: 'Failed to delete event',
    deleteEventSuccessMsg: 'The Event has been deleted'
  }

  handleClick = () => {
    this.props.onHideContextMenu && this.props.onHideContextMenu()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
  }

  componentDidUnMount() {
    document.removeEventListener('click', this.handleClick)
  }

  onDeleteEvent = (nativeEvent) => {
    const { event, deleteEventFailMsg, deleteEventSuccessMsg } = this.props
    debug('deleting event', event)
    nativeEvent.preventDefault()
    event.delete()
      .then(() => getAppNotifications().warn(deleteEventSuccessMsg))
      .catch(() => getAppNotifications().error(deleteEventFailMsg))
  }

  onRemoveEvent = (nativeEvent) => {
    const { event, removeEventSuccessMsg, removeEventFromPlannerFailMsg } = this.props
    debug('removing event', event)
    nativeEvent.preventDefault()
    event.removeFromPlanner()
      .then(() => getAppNotifications().warn(removeEventSuccessMsg))
      .catch(() => getAppNotifications().error(removeEventFromPlannerFailMsg))
  }

  render() {
    const { contextMenuPos, removeEventLabel, deleteEventLabel } = this.props
    const { top, left } = contextMenuPos
    return (
      <div 
        style={{
          position: 'fixed',
          top,
          left,
          zIndex: 10000
        }}
        x-placement="bottom-start"
        aria-labelledby="dropdown-basic"
        className="dropdown-menu show"
      >
        <a
          href="#/remove"
          className="dropdown-item"
          onClick={this.onRemoveEvent}
        >
          {removeEventLabel}
        </a>
        <a
          href="#/delete"
          className="dropdown-item"
          onClick={this.onDeleteEvent}
        >
          {deleteEventLabel}
        </a>
      </div>
    )
  }
}