import { FormControlDropdown, FormControlCountryDropdown } from 'theme/Form'

const userFields = [
  {
    name: 'name',
    label: 'Name',
    value: null,
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    value: null,
    required: true,
  },
  {
    name: 'password',
    label: 'Password',
    value: null,
    required: true,
  },
  {
    name: 'plan',
    label: 'Plan',
    value: null,
    Component: FormControlDropdown,
    items: [],
  },
  {
    name: 'type',
    label: 'Type',
    value: null,
  },
  {
    name: 'seats',
    label: 'Seats',
    value: null,
  },
  {
    name: 'status',
    label: 'Status',
    value: null,
    Component: FormControlDropdown,
    items: [
      'Active',
      'Inactive',
    ],
  },
  {
    name: 'act',
    label: 'Act',
    value: null,
  },
  {
    name: 'domain',
    label: 'Domain',
    value: null,
  },
  {
    name: 'state',
    label: 'State',
    value: null,
    Component: FormControlDropdown,
    items: []
  },
  {
    name: 'country',
    label: 'Country',
    value: null,
    Component: FormControlCountryDropdown,
  },
]

export default userFields