
import { override, reaction } from "mobx";
import { importSession } from "Stores/Session";
import { getAppStore } from "Stores/App/AppStore";
import { TimeSpanBucketItem, TimeSpanList } from "Stores/ActionPlanner/TimeSpan";

export default class TimeSpanBucketList extends TimeSpanList {

  get ModelType() {
    return TimeSpanBucketItem
  }

  timespanTypesDisposer = null

  @override async fetch() {
    const { userSettings } = getAppStore(importSession())
    const { dayList } = this
    await userSettings.fetched()
    if (userSettings.timespanTypes) {
      const { items } = userSettings.timespanTypes
      dayList.setBucketItems(items)
    }
    if (this.timespanTypesDisposer) this.timespanTypesDisposer()
    this.timespanTypesDisposer = reaction(() => dayList.bucket.toJSON(), () => {
      userSettings.timespanTypes = dayList.bucket.toJSON()
      userSettings.save()
    })
  }

}