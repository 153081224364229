import React from 'react'
import { observer } from 'mobx-react'
import Dropdown from 'react-bootstrap/Dropdown'
import './FormControlDropdown.css'

const debug = require('debug')('treks:theme:settings:dropdown')

export default observer(function FormControlDropdown(field) {
  let { placeholder, value, onChange, items = [], className, disabled } = field
  debug('render dropdown', field)
  items = items.map(item => {
    return typeof item === 'string' 
      ? { label: item, value: item }
      : { label: (item.label || item.value), value: (typeof item.value !== 'undefined' ? item.value : item.label)}
  })
  const selected = value && items.find(item => item.value === value)
  return (
    <Dropdown className={'form-control-dropdown ' + (className || '')}>
      <Dropdown.Toggle variant="outline" className={(disabled ? ' disabled' : '')} disabled={disabled}>
       {selected ? selected.label : (placeholder || (items[0] && items[0].label))}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          disabled || items.map((item) => {
            const { label, value } = item
            return (
              <Dropdown.Item 
                key={value} 
                onClick={() => onChange && onChange(value)}
              >
                {label}
              </Dropdown.Item>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
})