import { getStore } from "Stores";
import Model from "Stores/Model";
import { computed, observable } from "mobx";
import { getKeyPressState } from "uiState/KeypressState";
import { multiTasksMenuItems, singleTaskMenuItems } from "./ListViewContextMenuItems";
import TaskItem from "Stores/Task";

const keyPresState = getKeyPressState()

export class ListViewUiModel extends Model {

  @computed get isCtrlKeyPressed() {
    return keyPresState.isCtrlKeyPressed
  }

  @computed get isShiftKeyPressed() {
    return keyPresState.isShiftKeyPressed
  }

  selectedTaskListState = observable({
    list: null,
    items: []
  })

  contextMenuState = observable({
    top: 0,
    show: false,
    selectedTasks: []
  })

  @computed get contextMenuItems() {
    const { selectedTasks } = this.contextMenuState
    const menuItems = selectedTasks.length > 1
      ? multiTasksMenuItems
      : (selectedTasks.length === 1 ? singleTaskMenuItems : [])
    return menuItems
  }

  isTaskSelected(task: TaskItem) {
    return this.contextMenuState.selectedTasks.find(item => item?.uid === task.uid)
  }

}

export const getListViewUiStore = (): ListViewUiModel => getStore(ListViewUiModel as any)