import { observable } from "mobx"
import { RelationshipJoinEntry } from "./RelationshipJoinEntry"
import { ColKey, ColType, ColValue } from "./__types__"

export class RelationshipJoinTable {

  uid: string = Math.random().toString(36).slice(2) // debug

  ns: string = ''

  entries: RelationshipJoinEntry[] = observable([])

  hasEntry(Type: ColType, key: ColKey, value: ColValue, RefType: ColType, refKey: ColKey, refValue: ColValue): boolean {
    const hasEntry = !!this.getEntry(Type, key, value, RefType, refKey, refValue)
    return hasEntry
  }

  getEntry(Type: ColType, key: ColKey, value: ColValue, RefType: ColType, refKey: ColKey, refValue: ColValue): RelationshipJoinEntry {
    return this.entries.find(entry => {
      return entry.hasColEntry(Type, key, value) && entry.hasColEntry(RefType, refKey, refValue)
    })
  }

  addEntry(Type: ColType, key: ColKey, value: ColValue, RefType: ColType, refKey: ColKey, refValue: ColValue): RelationshipJoinEntry {
    const entry = new RelationshipJoinEntry(Type, key, value, RefType, refKey, refValue)
    this.entries.push(entry)
    return entry
  }

  removeEntry(Type: ColType, key: ColKey, value: ColValue, RefType: ColType, refKey: ColKey, refValue: ColValue): RelationshipJoinEntry[] {
    const entry = this.getEntry(Type, key, value, RefType, refKey, refValue)
    const index = this.entries.indexOf(entry)
    return index !== -1 ? this.entries.splice(index, 1) : undefined
  }

  findEntries(Type: ColType, key: ColKey, value: ColValue): RelationshipJoinEntry[] {
    return this.entries.filter(entry => {
      return entry.hasColEntry(Type, key, value)
    })
  }

  removeEntries(Type: ColType, key: ColKey, value: ColValue): RelationshipJoinEntry[] {
    const entries = this.findEntries(Type, key, value)
    entries.forEach(entry => {
      const index = this.entries.indexOf(entry)
      this.entries.splice(index, 1)
    })
    return entries
  }

  findOneEntry(Type: ColType, key: ColKey, value: ColValue): RelationshipJoinEntry {
    return this.entries.find(entry => {
      return entry.hasColEntry(Type, key, value)
    })
  }

}