import React from 'react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'

const debug = require('debug')('treks:theme:PressableDoneIcon')

export default class PressableDoneIcon extends React.Component {

  static defaultProps = {
    colorNotDone: '#808285',
    colorDone: '#dcdddf',
    iconDone: 'task_complete',
    iconNotDone: 'task',
    size: 24,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {}
  }

  onClick = event => {
    const { done, item, onChange } = this.props
    debug('toggle done', { item, done })
    event.preventDefault()
    onChange(!done)
  }

  render() {
    const { 
      done, size, 
      colorNotDone, colorDone, iconNotDone, iconDone, 
      onClick, onFocus, onBlur 
    } = this.props
    debug('render', { done, size })
    const color = done ? colorDone : colorNotDone
    const icon = done ? iconDone : iconNotDone
    return (
      <Pressable onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
          <Icon name={icon} color={color} size={size} />
      </Pressable>
    )
  }
}