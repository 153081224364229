import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import './GoalDetail.scss'
import GoalTitle from './GoalTitle'
import GoalStats from './GoalStats'
import GoalTabs from './GoalTabs'
import WizardTabs from 'Components/Shared/Wizard/WizardTabs'

const debug = require('debug')('treks:goal:detail')

class GoalDetail extends React.Component {

  state = {
    focusedTabName: 'plan'
  }

  componentDidMount() {
    this.props.goal.memberList.fetched()
    const focusedTabName = localStorage.getItem('goal.focusedTabName')
    if (focusedTabName) {
      this.setState({ focusedTabName })
    }
  }

  componentDidUpdate(prevProps) {
    const { goal } = this.props
    if (goal !== prevProps.goal) {
      goal.memberList.fetched()
    }
  }

  onChangeWizardTab = (tab) => {
    this.setState({ focusedTabName: tab.name })
    localStorage.setItem('goal.focusedTabName', tab.name)
  }

  render() {
    const { goal, goalList } = this.props
    const { focusedTabName } = this.state
    debug('render', { goal, goalList })
    return (
      <div className="goal-detail">
        <div className="container">
          <header className="row header">
            <Icon icon="goal" size={20} />
            <GoalTitle goal={goal} />
          </header>
        </div>
        <div className="container">
          <WizardTabs
            focusedTabName={focusedTabName}
            onChange={this.onChangeWizardTab}
          />
        </div>
        <GoalStats goal={goal} />
        <GoalTabs
          focusedWizardTabName={focusedTabName}
          goal={goal}
          onSelect={this.onSelectTab}
        />
      </div>
    )
  }
}

export default observer(GoalDetail)
