import { ValueTextFormControl } from 'theme/Form'

const fields = [
  {
    name: 'subscription_amount',
    label: 'Due in 30 days',
    placeholder: '$0.00',
    value: null,
    Component: ValueTextFormControl,
  },
  {
    name: 'paymentDue',
    label: 'Due today',
    placeholder: '$0.00',
    value: null,
    Component: ValueTextFormControl,
  },
]

export default fields