import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from './utils'
import { getStore, models } from 'Stores'
import { TaskDetailPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import Icon from 'theme/Icon'
import Pressable from 'theme/Pressable'

export const TaskDetailChevron = observer(({ item, list, fields }) => {
  const openTaskDetail = (event) => {
    event.preventDefault()
    getStore(models.ActionPlanner).setFocusedItem(item)
    getStore(models.MainPaneGroup).addOrUpdateItem(TaskDetailPane)
  }
  return (
    fieldIsEnabled(fields, 'detail') || true ? (
      <div className="attribute attribute-detail">
        <Pressable onClick={event => openTaskDetail(event)}>
          <Icon set="fa" icon="chevron-right" size="xs" color={'#808284'} />
        </Pressable>
      </div>
    ) : null
  )
})