import React from 'react'
import './DueToday.scss'
import { observer } from 'mobx-react'
import stores, { getStore } from 'Stores'
import DueTodayGroup from './DueTodayGroup'

const debug = require('debug')('treks:planner:DueToday')

class DueToday extends React.Component {

  containerRef = null
  scrollTimeout = null
  isAutoScrolling = false
  lastScrollTop = 0

  static defaultProps = {
    get items() {
      return getStore(stores.ActionPlanner).itemsDue
    },
    groupLabels: {
      TaskItem: 'Due Today',
      CalendarEvent: 'Day Events'
    },
    itemHeight: 24,
    maxItemsVisible: 6,
    isCssScroll: false
  }

  componentDidMount() {
    this.lastScrollTop = this.containerRef ? this.containerRef.scrollTop : 0
  }

  renderGroup = ({ modelName, items }) => {
    const { groupLabels } = this.props
    return (
      <DueTodayGroup
        key={modelName}
        items={items}
        modelName={modelName}
        groupLabel={groupLabels[modelName]}
      />
    )
  }

  onScrollEnd = () => {
    const { items } = this.props
    const { lastScrollTop } = this
    this.isAutoScrolling = true
    const { offsetHeight, scrollTop, scrollHeight } = this.containerRef
    const direction = lastScrollTop < scrollTop
    const itemHeight = scrollHeight/items.length
    if (Math.round(scrollTop/itemHeight) !== scrollTop/itemHeight) {
      this.containerRef.scrollTop = (direction ? Math.ceil(scrollTop/itemHeight) : Math.floor(scrollTop/itemHeight)) * itemHeight
    }
    this.lastScrollTop = this.containerRef.scrollTop
    debug('scrollEnd', { lastScrollTop, itemHeight, offsetHeight, scrollTop, scrollHeight }, items.length, direction)
    setTimeout(() => this.isAutoScrolling = false, 50)
  }

  onScroll = () => {
    clearTimeout(this.scrollTimeout)
    if (this.isAutoScrolling) return
    this.scrollTimeout = setTimeout(() => {
      this.onScrollEnd()
    }, 100)
  }

  render() {
    debug('render', this.props)
    const { items, itemHeight, maxItemsVisible, isCssScroll } = this.props
    const modelNames = items.map(item => item.modelName)
    const groups = modelNames.filter((modelName, index) => modelNames.indexOf(modelName) === index)
    const groupedItems = groups.map(modelName => {
      return {
        modelName,
        items: items.filter(item => item.modelName === modelName)
      }
    })
    const height = Math.min(items.length, maxItemsVisible) * itemHeight
    const overflowY = items.length > maxItemsVisible ? 'scroll' : 'hidden'
    return (
      <div
        className="due-today md-light-scrollbar"
        style={isCssScroll ? {} : { height, overflowY }}
        onScroll={this.onScroll}
        ref={ref => this.containerRef = ref}
      >
        {
          groupedItems.map(group => this.renderGroup(group))
        }
      </div>
    )
  }
}

export default observer(DueToday)