import React from 'react'
import { observer } from 'mobx-react'
import Project from '../../Components/Project'
import { getStore, models } from 'Stores'
import MainPaneItem from 'Stores/MainPaneGroup/MainPaneItem'

const debug = require('debug')('treks:container:project')

class ProjectContainer extends React.Component {

  static defaultProps = {
    get projectList() {
      return getStore(models.ProjectList)
    },
    get mainPaneGroup() {
      return getStore(models.MainPaneGroup)
    }
  }

  static propTypes = {
    pane: (props, name, componentName) => {
      const prop = props[name]
      const isPane = prop instanceof MainPaneItem
      if (!isPane) {
        return new Error(`${componentName} prop ${name} must be a MainPaneItem`)
      }
      if (!prop.slug === 'project') {
        return new Error(`${componentName} prop ${name} must be a MainPaneItem with slug "project"`)
      }
    }
  }

  getProject() {
    const { projectList, pane } = this.props
    return pane?.modelId && projectList.getItemById(pane.modelId)
  }

  setProject(project) {
    this.props.pane.setModelId(project.id)
  }
    
  async componentDidMount() {
    const { projectList } = this.props
    await projectList.fetched()
  }

  render() {
    const { projectList } = this.props
    const project = this.getProject()
    debug('render', this.props, { project })
    return (
      projectList.fetchState.isFetched 
        ? (
          project ? 
            <Project projectList={projectList} project={project} /> : 
            <div style={{ padding: 10 }}>Please choose a project</div>
        ) 
        : <div>Loading...</div>
    )
  }
}

export default observer(ProjectContainer)