import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

const debug = require('debug')('treks:theme:pagination')

export default class ListPagination extends React.Component {

  onSelect = page => {
    this.props.onPageChange && this.props.onPageChange(page)
  }

  render() {
    let { perPage, page, total, size = 'sm' } = this.props
    page = parseInt(page, 10)

    const pageIndex = Math.max(0, page - 1)
    const pageCount = Math.ceil(total / perPage)
    const pageList = [...(new Array(pageCount)).keys()]

    debug('render', { perPage, page, total, size, pageIndex, pageCount })

    return (
      <Pagination size={size}>
        <Pagination.First onClick={() => this.onSelect(1)} />
        <Pagination.Prev onClick={() => this.onSelect(Math.max(1, page - 1))} />
        {
          pageList.map(i => (
            <Pagination.Item 
              key={i}
              active={pageIndex === i}
              onClick={() => this.onSelect(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))
        }
        <Pagination.Next onClick={() => this.onSelect(Math.min(pageCount, page + 1))} />
        <Pagination.Last onClick={() => this.onSelect(pageCount)} />
      </Pagination>
    )
  }
}