import React from 'react'
import { observer } from 'mobx-react'
import './List.scss'

class ListHeader extends React.Component {
  render() {
    return (
      <div className="list-header row-flex">
        {this.props.children}
      </div>
    )
  }
}

export default observer(ListHeader)