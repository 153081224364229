import React from 'react'
import { observer } from 'mobx-react'
import './List.scss'

class ListBody extends React.Component {
  render() {
    return (
      <div className="list-body">
        {this.props.children}
      </div>
    )
  }
}

export default observer(ListBody)