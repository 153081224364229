import React from 'react'
import { observer } from 'mobx-react'
import GoalDetailItem from './GoalDetailItem'
import PropTypes from 'prop-types'
import Form from 'Stores/Form'
import { GoalItem } from 'Stores/Goal'
import GoalDetailGroup from './GoalDetailGroup';

class GoalDetailList extends React.Component {

  static propTypes = {
    goal: PropTypes.instanceOf(GoalItem).isRequired,
    form: PropTypes.objectOf(() => Form).isRequired
  }

  render() {
    const { goal, form } = this.props
    return (
      <div className="list-view detail-list">
        {
          form.fields.map(field => {
            return (
              field.type === 'group' 
                ? <GoalDetailGroup
                    key={field.name}
                    field={field}
                    goal={goal}
                  />
                : <GoalDetailItem
                    key={field.name}
                    field={field}
                    goal={goal}
                  />
            )
          })
        }
      </div>
    )
  }
}

export default observer(GoalDetailList)
