/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import './SubMenu.scss';
import { observer } from 'mobx-react';
import Select from '../../../theme/Form/Select';
import { action } from 'mobx';

const dictionary = ['calories', 'repetitions', 'miles'];

class HabitUnits extends React.Component {

    static defaultProps = {
        selectPlaceholder: 'Units',
    };

    @action onChange = (option) => {

        const { habit } = this.props;
        if (habit) {
            habit.units = option.value;
            habit.save()
        }
    };

    render() {
        const { habit } = this.props;
        const options = dictionary.map((d) => ({label: d, value: d}));

        const value = habit ? habit.units : null;
        return (
            <div style={{ padding: 2, borderRadius: 5, minWidth: 150, paddingTop: 5 }}>
                <Select onChange={this.onChange} options={options} value={value} />
            </div>
        );
    }
}

export default observer(HabitUnits)
