import React from 'react'
import { observer } from 'mobx-react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import './TaskCheckBox.scss'

const debug = require('debug')('treks:task:checkbox')

class TaskCheckbox extends React.Component {

  static defaultProps = {
    colorDone: '#dcdddf',
    colorNotDone: '#97ABB0',
    iconDone: 'task_complete',
    iconNotDone: 'task',
    IconBusy: props => <Icon set="fa" name={'circle-notch'} {...props} />,
    size: 24
  }

  onClick = async event => {
    const { item } = this.props
    const { isBusy } = item.saveState
    debug('toggle check', { item, isBusy })
    if (isBusy) return
    event.preventDefault()
    event.stopPropagation()
    item.toggleDone()
    item.list.moveItem(item, 0)
    item.list.saveOrder()
    await item.save()
  }

  onFocus = () => {
    const { item, onFocus } = this.props
    debug('focused on', item)
    onFocus && onFocus(item)
  }

  onBlur = () => {
    const { item, onBlur } = this.props
    debug('removed focus on', item)
    onBlur && onBlur(item)
  }

  render() {
    const { item, size, colorDone, colorNotDone, iconDone, iconNotDone, IconBusy, iconBusySize } = this.props
    debug('render', item)
    const color = item.done ? colorDone : colorNotDone
    const icon = item.done ? iconDone : iconNotDone
    const doneClass = item.done ? ' done' : ''
    return (
      <Pressable
        onClick={this.onClick}
        onFocus={this.onFocus}
        onBlur={this.props.onBlur}
        className={'checkbox-icon' + doneClass }
        style={{
          width: (size * 1.5), // size if font-size
          height: (size * 1.5)
        }}
      >
        <Icon name={icon} color={color} size={size} />
      </Pressable>
    )
  }
}

export default observer(TaskCheckbox)