import React from 'react'
import { observer } from 'mobx-react'
import TaskActivityText from './TaskActivityText'

const debug = require('debug')('treks:task:activity')

const TaskActivityRow = observer(({ item }) => {
  debug('render item', item)
  return (
    <div className="item row-flex">
      <div className="item-col item-body">
        <div className="item-attributes row-flex">
          <div className="name">{item.user.name}</div>
          <div className="item-text">
            <TaskActivityText item={item} />
          </div>
          <div className="since">{item.since}</div>
        </div>
      </div>
    </div>
  )
})

export default TaskActivityRow