import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:form:control:user-text')

const UserEditableText = observer(({ user, field }) => {
  debug('render', { user, field })
  const value = user[field.name]
  return (
    <span className={'field-' + field.name}>{value}</span>
  )
})

export default UserEditableText