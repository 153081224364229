import React from 'react'
import { observer } from 'mobx-react'
import GoalSubGoalItem from './GoalSubGoalItem'
import PropTypes from 'prop-types'
import GoalList from 'Stores/Goal/GoalList'
import Table from 'theme/Table';

class GoalSubGoalList extends React.Component {

  headers = [
    '',
    'SubGoal',
    'Roll Up Data',
    'Achieved vs.',
    'Plan',
  ]

  static propTypes = {
    subgoals: PropTypes.objectOf(() => GoalList).isRequired,
  }

  async componentDidMount() {
    this.props.subgoals.fetch()
  }

  async componentDidMUpdate(prevProps) {
    if (this.props.subgoals !== prevProps.subgoals) {
      this.props.subgoals.fetch()
    }
  }

  render() {
    const { subgoals, goal } = this.props
    return (
      <Table className="subgoal-list striped">
        <thead>
          <tr>
            {
              this.headers.map(header => (
                <td key={header}>{header}</td>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {subgoals.items.map(subgoal => (
            <GoalSubGoalItem key={subgoal.id} subgoal={subgoal} goal={goal} />
          ))}
        </tbody>
      </Table>
    )
  }
}

export default observer(GoalSubGoalList)
