import React from 'react'
import { observer } from 'mobx-react'
import './App.css'
import MainMenuContainer from '../../Containers/MainMenu'
import Logo from '../../Components/Logo'
import MainHeader from '../../Components/MainHeader'
import Modal from '../../Containers/Modal'
import AppRoutes from '../../Routes/App'
import AppLoadingIndicator from './AppLoadingIndicator'
import { getAppNotifications } from '../../AppNotifications'
import { getStore, models } from 'Stores'
import { IS_DEV } from '../../env'
import { UserAvatarContainer } from 'theme/Avatar/UserAvatarContainer'

const debug = require('debug')('treks:screens:app')

export default observer(class AppScreen extends React.Component {

  static defaultProps = {
    defaultLoginErrorMsg: 'Your authenticated session has expired'
  }

  state = {
    throwError: false
  }

  sessionStore = getStore(models.Session)

  componentDidMount() {
    // app requires authentication
    this.authenticate()

    // offline/online notifications
    window.addEventListener('offline', () => {
      getAppNotifications().error('You are offline, your changes will be saved when you are back online')
    })
    window.addEventListener('online', () => {
      getAppNotifications().success('You are back online')
    })

    global.TreksAppScreen = this // debugging
  }

  authenticate() {
    this.sessionStore.auth()
      .then(resp => {
        debug('Auth resp', resp)
        if (!resp) throw Error('Auth failed')
      })
      .catch(error => {
        if (IS_DEV) {
          throw error
        }
        console.error('App auth error', error)
        if (this.isPublicRoute()) return
        this.goToLogin()
      })
  }

  goToLogin() {
    const { history, defaultLoginErrorMsg } = this.props
    const { pathname } = history.location
    debug(defaultLoginErrorMsg)
    if (!pathname.match('/account/login')) {
      history.push('/account/login')
    }
  }

  isPublicRoute() {
    return this.props.history.location.pathname
      .match(/\/account\/(login|register|password)/)
  }

  throwError(msg) {
    throw new Error(msg || 'Throwing an error')
  }

  renderWithError() {
    this.setState({ throwError: true })
  }

  isAuthReqComplete() {
    return this.sessionStore.isAuthReqComplete
  }

  render() {
    debug('render', this)
    if (this.state.throwError) {
      throw new Error('Throwing render Error in App')
    }
    if (!this.isPublicRoute() && !this.isAuthReqComplete()) {
      return (
        <AppLoadingIndicator />
      )
    }
    return (
      <div className="main">
        <MainHeader>
          <Logo />
          <MainMenuContainer />
          <UserAvatarContainer />
        </MainHeader>
        <AppRoutes />
        <Modal />
      </div>
    )
  }
})