import { observable, computed, action, reaction, override } from 'mobx';
import Item from '../Lists/Item'
import CalendarEventList from './CalendarEventList';
import { importUser } from '../User';
import { minsToMillisecs, millisecsToMins } from './CalendarUtils'
import ActionPlanner from '../ActionPlanner';
import TaskItem from '../Task';
import EventMemberList from './EventMemberList';
import FileList from '../File/FileList';
import { getAppNotifications, Position } from 'Stores/App/AppToaster/AppNotifications';
import { UserI } from 'Stores/User/Type/User';
import { CalendarAccountList } from 'Stores/Calendar';
import { getStore } from 'Stores/Stores';
import { CalendarEventReminderList } from './CalendarEventReminderList';


const debug = require('debug')('treks:store:CalendarEvent')

/**
 * Implementation for any ICal Event
 * 
 * Currently supported:
 * Google Calendar Event
 * https://developers.google.com/calendar/api/v3/reference/events/update
 */
export default class CalendarEvent extends Item {

  get modelName(): string {
    return 'CalendarEvent' // prod mangles class names
}

  get actionPlanner():ActionPlanner {
    return this.getAttribute('actionPlanner', () => getStore(ActionPlanner))
  }
  set actionPlanner(actionPlanner:ActionPlanner) {
    this.setAttribute('actionPlanner', actionPlanner)
  }
  
  onObservable() {
    //this.interceptSave()
  }

  interceptSave() {
    this.useApiMiddleware((action, json, next) => {
      if (action === 'save') {
        return new Promise(resolve => {
          setTimeout(() => resolve(next(action, json)), 5000)
          getAppNotifications().warn({
            message: 'Send notifications to event members?',
            position: Position.TOP,
            icon: "ban-circle",
            action: {
              onClick: () => {
                json.sendUpdates = 'all'
                resolve(next(action, json))
              },
              text: "All Members",
            }
          })
        })
      }
    })
  }

  @observable updateDeviceUid: string = ''

  @observable calenderEventList:CalendarEventList

  @observable title: string = ''

  @observable description: string = ''

  @observable timezone: string = ''

  @action setTimezone(timezone: string) {
    this.setProps({ timezone })
  }

  @action setTitle(title:string):void {
    this.setProps({ title })
  }

  @computed get user(): UserI {
    return this.getAttribute('user', () => importUser().create())
  }
  set user(user: UserI) {
    this.setAttribute('user', user)
  }

  @computed get userId():string {
    return this.user.id
  }
  set userId(id) {
    this.user = importUser().fromProps({ id })
  }

  /**
   * Google event ID
   */
  @observable eventId:string = ''

  /**
   * Google pubsub channel that created event
   */
  @observable channelId:string = ''

  @observable service:string = ''

  @observable done:boolean = false

  @action toggleDone() {
    this.done = !this.done
  }

  @observable createDate:Date = null

  @observable updateUserId:number = null

  @observable updateDate:Date = null

  @observable updateTokenUid:string = null

  @action setUpdateTokenUid(updateTokenUid:string):void {
    this.updateTokenUid = updateTokenUid
  }

  @observable focusOnTitle:boolean = false

  @observable memberList:EventMemberList = EventMemberList.create()

  @observable fileList:FileList = FileList.create()

  @observable allDayEvent:boolean

  /**
   * "Reminders" on google calendar
   */
  @observable notifications = CalendarEventReminderList.create() as CalendarEventReminderList

  @observable guestCanModifyEvent:boolean = false
  
  @observable guestsCanInviteOthers:boolean = false

  @observable guestsCanSeeOtherGuests:boolean = false

  @observable calendarId:string = ''

  @observable calendarAccountId:string = ''

  @observable calendarAccountList: CalendarAccountList = getStore(CalendarAccountList) as CalendarAccountList

  @computed get calendarAccount() {
    return this.calendarAccountList.items.find(account => account.id === this.calendarAccountId)
  }

  @computed get calendar() {
    const { calendarAccount, calendarId } = this
    const calendar = calendarAccount?.items.find((calendar) => calendar.calendarId === calendarId)
    return calendar
  }

  @computed get startDate():Date {
    const date = this.getAttribute('startDate', () => new Date())
    if (this.allDayEvent) {
      return new Date(new Date(date).getTime() + this.timezoneOffset) // convert UTC to our timezone
    }
    return date

  }
  set startDate(date:Date) {
    this.setAttribute('startDate', new Date(date))
  }

  @action setStartDate(date:any) {
    this.startDate = new Date(date)
  }

  get timezoneOffset():number {
    return (new Date()).getTimezoneOffset() * 60 * 1000
  }

  /**
   * Moves startDate and endDate keeping duration
   */
  @action moveStartDate(startDate:Date) {
    const { duration } = this // @todo fix duration should be Int not Float
    const endDate = new Date(startDate.getTime() + minsToMillisecs(duration));
    this.setProps({ startDate, endDate })
  }

  /**
   * Moves startDate and endDate keeping duration
   */
  @action moveEndDate(endDate:Date) {
    const { duration } = this
    const startDate = new Date(endDate.getTime() - minsToMillisecs(duration));
    this.setProps({ endDate, startDate })
  }

  @computed get endDate():Date {
    const date = this.getAttribute('endDate', () => new Date())
    if (this.allDayEvent) {
      return new Date(new Date(date).getTime() + this.timezoneOffset) // convert UTC to our timezone
    }
    return date
  }
  set endDate(date:Date) {
    this.setAttribute('endDate', new Date(date))
  }

  @action setEndDate(date:any) {
    this.endDate = new Date(date)
  }

  @computed get startOfDayDate():Date {
    return new Date(new Date(this.startDate).setHours(0, 0, 0, 0))
  }

  @computed get endOfDayDate():Date {
    const { startOfDayDate } = this
    return new Date(startOfDayDate.getTime() + 86400000)
  }

  @computed get msSinceStartOfDay():number {
    const { startOfDayDate, startDate } = this
    return startDate.getTime() - startOfDayDate.getTime()
  }

  @computed get msSinceStartOfDayToEnd():number {
    const { startOfDayDate, endDate } = this
    return endDate.getTime() - startOfDayDate.getTime()
  }

  @computed get minutesSinceStartOfDay():number {
    return millisecsToMins(this.msSinceStartOfDay)
  }

  @computed get minutesSinceStartOfDayToEnd():number {
    return millisecsToMins(this.msSinceStartOfDayToEnd)
  }
  
  @action setMinutesSinceStartOfDay(minsSinceStartOfDay:number) {
    const msSinceStartOfDay = minsToMillisecs(minsSinceStartOfDay)
    this.moveStartDate(new Date(this.startOfDayDate.getTime() + msSinceStartOfDay))
  }

  @observable minDuration = 5

  @computed get duration() {
    return this.minutesSinceStartOfDayToEnd - this.minutesSinceStartOfDay
  }

  @action setDuration(duration:number) {
    const { startDate } = this
    this.endDate = new Date(startDate.getTime() + minsToMillisecs(duration))
  }

  @observable startTimeZone:string = ''

  @observable endTimezone:string = ''

  @observable status:string = ''

  @observable syncToken:string = ''

  @observable onPlanner:boolean|null = null

  @action toggleOnPlanner() {
    if (!this.onPlanner) {
      return this.addToPlanner()
    } else {
      return this.removeFromPlanner()
    }
  }

  @observable recurrence:Array<string> = []

  @action setRecurrence(recurrence: string[]) {
    console.log('recurrence', recurrence)
    this.recurrence.splice(0, this.recurrence.length, ...recurrence)
  }

  @observable reminders:object = {}

  @observable location:string|null = null

  @action async addToPlanner() {
    if (this.onPlanner) return false
    const { eventId, calendarId, calendarAccount  } = this
    const { email } = calendarAccount
    debug('addToPlanner', { email, eventId, calendarId, calendarAccount })
    this.onPlanner = true
    return this.saveState.post('calendar/event/onPlanner', { eventId, calendarId, email, onPlanner: 1  })
      .catch(err => {
        this.onPlanner = false
        throw err
      })
  }

  @action async removeFromPlanner() {
    if (this.onPlanner === false) return false
    const { eventId, calendarId, calendarAccount  } = this
    const { email } = calendarAccount
    debug('removeFromPlanner', { email, eventId, calendarId, calendarAccount })
    this.onPlanner = false
    return this.saveState.post('calendar/event/onPlanner', { eventId, calendarId, email, onPlanner: 0  })
      .catch(err => {
        this.onPlanner = true
        throw err
      })
  }

  @observable deleted:boolean = false

  @computed get onTask():TaskItem {
    return this.actionPlanner.getEventTask(this)
  }

  /**
   * @note inclusive of this event
   */
  @computed get overlappingEvents():Array<CalendarEvent> {
    const { minutesSinceStartOfDay, minutesSinceStartOfDayToEnd } = this
    const overlappingEvents = this.actionPlanner.events
      .filter((plannerEvent:CalendarEvent) => {
        return minutesSinceStartOfDay <= plannerEvent.minutesSinceStartOfDayToEnd 
          && minutesSinceStartOfDayToEnd >= plannerEvent.minutesSinceStartOfDay
      })
    debug('overlappingEvents', { event: this.title, overlappingEvents: overlappingEvents.map(event => event.title) })
    return overlappingEvents
  }

  @computed get stackIndex():number {
    const { events } = this.actionPlanner
    let prevEvent:CalendarEvent|undefined = this
    let firstEvent:CalendarEvent|null = null
    while(prevEvent) {
      prevEvent = [ ...prevEvent.overlappingEvents.slice(0, prevEvent.overlappingEvents.indexOf(prevEvent))].pop()
      debug('getStackIndex: found prevEvent', prevEvent && prevEvent.title)
      if (prevEvent) firstEvent = prevEvent
    }
    const stackIndex = firstEvent ? events.indexOf(this) - events.indexOf(firstEvent) : 0
    debug('getStackIndex', this.title, { stackIndex, firstEvent:(firstEvent && firstEvent.title) })
    return stackIndex
  }

  @override fromJSON(json:any) {
    super.fromJSON(json)
    this.onPlanner = !!json.onPlanner
    if (json.startDate) this.setStartDate(json.startDate)
    if (json.endDate) this.setEndDate(json.endDate)
    if (json.recurrence) this.setRecurrence(json.recurrence)
    return this
  }

  /**
   * Google calendar uses YYYY-MM-DD for allDayEvent
   * @param {Date} date
   */
  formatDate(date:Date):string {
    const isoDate = new Date(date).toISOString()
    if (this.allDayEvent) return isoDate.split('T').shift()
    return isoDate
  }

  toJSON():any {
    const json:any = this.getProps([
      'id', 'calendarId', 'calendarAccountId', 'eventId', 'title', 'startDate', 'endDate', 'allDayEvent',
      'status', 'recurrence', 'reminders', 'createDate', 'updateDate', 'onPlanner', 'deleted'
    ])

    if (json.startDate) json.startDate = this.formatDate(new Date(json.startDate))
    if (json.endDate) json.endDate = this.formatDate(new Date(json.endDate))
    return json
  }

  toUpdatesJSON():any {
    const json = this.toJSON()
    const updated = Object.keys(json).reduce((next:object, key:string) => {
      const value = json[key]
      return (value !== null && value !== undefined) 
        ? { ...next, [key]: value } : next
    }, {})
    return updated
  }

  /**
   * Save Event and send notifications
   * @see https://developers.google.com/calendar/v3/reference/events/update#sendUpdates
   * @param sendUdates {string} (all|externalOnly|none)
   * @note backend will save or update based on eventId existence
   * @note requires the email to auth with gcal
   */
  async save() {
    const { 
      eventId, calendarId, calendarAccount, 
      title, startDate, endDate, reminders, 
      recurrence, onPlanner  
    } = this
    if (!this.calendarAccount) {
      console.warn('Calendar accounts not found. Call event.calendarAccountList.fetch() first.')
      throw new Error('No Calendar Account found')
    }
    const email = calendarAccount?.email
    console.log('save', { email, eventId, calendarId, calendarAccount })
    if (!email) {
      console.warn('Missing email on calendar Account', this.calendarAccount)
      throw new Error('No Calendar Account email found')
    }
    return this.saveJson({
      // eventId,
      calendarId,
      title,
      reminders,
      recurrence,
      onPlanner,
      startDate: startDate.toString(),
      endDate: endDate.toString()
    }, 'calendar/event/save?email=' + email)
  }

  async delete() {
    const { eventId, calendarId, calendarAccount  } = this
    if (!this.calendarAccount) {
      console.warn('Calendar accounts not found. Call event.calendarAccountList.fetch() first.')
      throw new Error('No Calendar Account found')
    }
    const email = this.calendarAccount?.email
    debug('delete', { email, eventId, calendarId, calendarAccount })
    if (!email) {
      console.warn('Missing email on calendar Account', this.calendarAccount)
      throw new Error('No Calendar Account email found')
    }
    this.deleted = true
    return this.saveState.post('calendar/event/delete?email=' + email, { eventId, calendarId  })
      .catch((error) => {
        this.deleted = false
        throw error
      })
  }
  

  /**
   * Fire a save once an update is made
   * @note only fires once, disposed after
   * @note batches updates within `timeout`
   */
  saveOnUpdateTimer = null
  saveOnUpdate(opts = { timeout: 500 }) {
    return new Promise(resolve => {
      const dispose = reaction(() => this.toJSON(), () => {
        clearTimeout(this.saveOnUpdateTimer)
        this.saveOnUpdateTimer = setTimeout(() => {
          dispose()
          resolve(this.save())
        }, opts.timeout)
      })
    })
  }

}