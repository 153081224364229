import React from 'react'
import './ListViewContextMenu.scss'
import { observer } from 'mobx-react'
import Button from 'theme/Button'
import PressableIcon from 'theme/Pressable/PressableIcon'
import { getListViewUiStore } from '../ListViewContainer/models/ListViewUiModel'

const debug = require('debug')('treks:ListViewContextMenu')

class ListViewContextMenu extends React.Component {

  static defaultProps = {
    show: false,
    menuItems: [],
    top: null,
    left: null,
    selectedTasks: [],
    onHide: () => {}
  }

  handleDocumentClick = () => {
    const { onHide, show } = this.props
    if (show && onHide)  onHide()
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentDidUnMount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  onClick = ({ event, menuItem }) => {
    event.preventDefault()
    event.stopPropagation()
    const { selectedTasks, onHide } = this.props
    menuItem.onClick({ menuItem, selectedTasks, onHide })
  }

  renderSubMenu({ menuItem }) {
    return (
      <div className='sub-menu'>
        {
          menuItem.menuItems.map(menuItem => (
            <React.Fragment key={'sub-' + menuItem.label}>
              <a
                href="#"
                className="dropdown-item"
                onClick={(event ) => this.onClick({ event, menuItem })}
              >
                {
                  menuItem.label
                }
              </a>
              {
                menuItem.showMenu && this.renderSubMenu({ menuItem })
              }
            </React.Fragment>
          ))
        }
      </div>
    )
  }

  renderContextMenu() {
    const { 
      show, selectedTasks, top, left,
    } = this.props
    const uiStore = getListViewUiStore()
    const { contextMenuItems } = uiStore
    debug('render ListViewContextMenu', { contextMenuItems, show, top, left, selectedTasks })
    return (
      <div 
        style={{ top, left }}
        className={"list-view-context-menu dropdown-menu " + (show ? 'show' : 'hide')}
      >
        {
          contextMenuItems.map(menuItem => {
            return (
              <React.Fragment key={menuItem.label} >
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={(event) => this.onClick({ event, menuItem })}
                >
                  <menuItem.icon />
                  <span>
                    {
                      menuItem.label
                    }
                  </span>
                </a>
                {
                  menuItem.showMenu && this.renderSubMenu({ menuItem })
                }
              </React.Fragment>
            )
          })
        }
      </div>
    )
  }

  renderFooterMenu() {
    const { size, pos } = this.props
    const uiStore = getListViewUiStore()
    const { contextMenuItems } = uiStore
    console.log('render ListViewContextMenu:footer', { contextMenuItems })
    return (
      <footer
        className={"main-pane-footer "}
        style={{
          ...size,
          ...pos
        }}
      >

        {
          contextMenuItems.map(menuItem => {
            return (
              <React.Fragment key={menuItem.label} >
                <Button
                  variant="outline-dark"
                  size="xs"
                  onClick={(event) => this.onClick({ event, menuItem })}
                >
                  <menuItem.icon />
                  <span>
                    {
                      menuItem.label
                    }
                  </span>
                  {
                    menuItem.showMenu && this.renderSubMenu({ menuItem })
                  }
                </Button>
              </React.Fragment>
            )
          })
        }
        
        <div className="footer-btns">
          <PressableIcon
            icon="settings"
            size="xs"
            style={{ marginLeft: 'auto' }}
            onClick={this.onClickSettings}
          />
        </div>
      </footer>
    )
  }

  render() {
    const { menuType } = this.props
    if (menuType === 'footer') {
      return this.renderFooterMenu()
    } else {
      return this.renderContextMenu()
    }
  }
}

export default observer(ListViewContextMenu)