import React from "react";
import { ResponsiveLine } from "@nivo/line";

const debug = require("debug")("treks:project:metrics:blockers:graph");

class UnassignedTasksGraph extends React.Component {
  getYMaxCeil(scale = 10) {
    const yMax = this.props.data[0].data.reduce(
      (max, { y }) => Math.max(y, max),
      0,
    );
    return Math.ceil(yMax / scale) * scale;
  }

  render() {
    const { data } = this.props;
    const yMax = this.getYMaxCeil();
    debug("render", data);
    return (
      <ResponsiveLine
        className="metrics-graph-line"
        data={data}
        margin={{
          top: 50,
          right: 50,
          bottom: 50,
          left: 50,
        }}
        xScale={{
          type: "point",
        }}
        yScale={{
          type: "linear",
          stacked: true,
          min: 0,
          max: yMax,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Week",
          legendOffset: 45,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "% Unassigned",
          legendOffset: -45,
          legendPosition: "middle",
          format: (label) => label + "%",
        }}
        colorBy={(e) => e.color}
        enableGridX={false}
        enableDots={false}
        enableArea={true}
        areaOpacity={1}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    );
  }
}

export default UnassignedTasksGraph;
