import React from 'react'
import { observer } from 'mobx-react'
import Select from 'theme/Form/Select'
import FollowerList from './FollowerList'
import './FollowerList.scss'

const debug = require('debug')('treks:theme:followers')

const addText = 'Add Followers'

class Followers extends React.Component {

  onChange = ({ label, value }) => {
    const { followers, users, onAddFollower } = this.props
    debug('onChange', label, value)
    const user = users.getItemById(value)
    if (onAddFollower) {
      return onAddFollower(user)
    }
    followers.addItem({ user })
  }

  render() {
    const { followers, users, onRemoveFollower } = this.props
    const followerIds = followers.items.map(follower => follower.user.id.toString())
    const options = users.items
      .filter(({ id }) => !followerIds.includes(id.toString()))
      .map(({ name, id }) => ({ 
        label: name, 
        value: id 
      }))
    debug('render', { followers, users, followerIds, options })
    return (
      <div className="follower-list row-flex">
        <FollowerList
          followers={followers}
          options={options}
          onRemoveFollower={onRemoveFollower}
        />
        { 
          options.length ? (
            <Select 
              classNamePrefix="react-select"
              placeholder={addText}
              onChange={this.onChange}
              options={options}
              value={null}
              styles={selectStyles}
            />
          ) : null 
        }
      </div>
    )
  }
}

export default observer(Followers)

const selectStyles = {
  control: styles => ({
    ...styles,
    minHeight: 30,
    height: 30,
    marginTop: 0,
    marginLeft: 5,
    backgroundColor: 'transparent',
    alignItems: 'flex-start'
  })
}
