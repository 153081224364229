import React from 'react'
import { observer } from 'mobx-react'
import GoalDetailItem from './GoalDetailItem'
import PropTypes from 'prop-types'
import { GoalItem } from 'Stores/Goal'
import { Field } from 'Stores/Form';

const debug = require('debug')('treks:goal:details:group')

class GoalDetailGroup extends React.Component {

  static propTypes = {
    goal: PropTypes.objectOf(() => GoalItem).isRequired,
    field: PropTypes.objectOf(() => Field).isRequired
  }

  render() {
    const { field, goal } = this.props
    debug('render', { field, goal })
    return (
      <div className="list-group list-row row-flex">
        {
          field.items.map(field => (
            <GoalDetailItem
              key={field.name}
              field={field}
              goal={goal}
            />
          ))
        }
      </div>
    )
  }
}

export default observer(GoalDetailGroup)
