const debug = require('debug')('treks:sortable:utils')

export const generateUid = () => Math.random().toString(16).slice(2)

export const on = (el, events, listener, capture) => {
  debug('adding events', { el, events, listener, capture })
  return [events].flat().map(eventName => el.addEventListener(eventName, listener, capture))
}

export const getEventLoggingOptions = () => {
  return [
    'onChoose',
    'onStart',
    'onEnd',
    'onAdd',
    'onUpdate',
    'onSort',
    'onRemove',
    'onChange',
    'onUnchoose'
  ].reduce((options, name) => {
    options[name] = (evt) => {
      debug({
        'event': name,
        'item': evt.item,
        'from': evt.from,
        'to': evt.to,
        'oldIndex': evt.oldIndex,
        'newIndex': evt.newIndex
      });
      return false
    };
    return options
  }, {})
}