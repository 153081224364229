const requestApiSchema = {
  FETCH: {
    method: 'get',
    path: model => model.modelName.toLowerCase() + '/fetch/' + model.id
  },
  SAVE: {
    method: 'post',
    path: model => model.modelName.toLowerCase() + '/save'
  },
  LIST: {
    method: 'get',
    path: model => model.modelName.toLowerCase() + '/list'
  },
  UID: {
    method: 'get',
    path: model => model.modelName.toLowerCase() + '/uid/' + model.id
  }
}

export default requestApiSchema