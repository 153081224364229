import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { stores, getStore } from 'Stores'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import DetailMemberAvatar from './DetailMemberAvatar'

class MemberItem extends React.Component {

  static propTypes = {
    member: PropTypes.objectOf(() => getStore(stores.User))
  }

  state = {
    avatarRef: null,
    showTooltip: false
  }

  onAvatarRef = ref => this.setState({ avatarRef: ref })

  onAvatarFocus = () => this.setState({ showTooltip: true })

  onAvatarBlur = () => this.setState({ showTooltip: false })

  onAvatarClick = () => {
    this.props.item.removeMember(this.props.member)
  }

  render() {
    const { member, avatarStyles } = this.props
    return (
      <div className="avatar-item">
        <div
          ref={this.onAvatarRef}
          onMouseOver={this.onAvatarFocus}
          onMouseOut={this.onAvatarBlur}
          onClick={this.onAvatarClick}
        >
        <DetailMemberAvatar 
          key={member.id} 
          member={member}
          avatarStyles={avatarStyles}
        />
        </div>
        <Overlay target={this.state.avatarRef} show={this.state.showTooltip} placement="top">
          {props => <Tooltip {...props} show="true">Remove Member</Tooltip>}
        </Overlay>
      </div>
    )
  }
}

export default observer(MemberItem)