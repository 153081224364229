import React from "react";
import { observer } from "mobx-react";
import { getScoreWeightsStore } from "./stores";
import "./styles/ScoreWeightsSelect.scss"
import ScoreWeightsRangeSlider from "./ScoreWeightsRangeSlider";

class ScoreWeightsSelect extends React.Component {

  static defaultProps = {
    scoreWeights: getScoreWeightsStore(),
  };

  setScoreWeights = values => {
    this.props.scoreWeights.setRangeValues(values)
  }

  render() {
    const { scoreWeights, containerStyle } = this.props
    return (
      <ScoreWeightsRangeSlider
        className='score-weights-range-slider'
        range={scoreWeights.rangeValues}
        onChange={this.setScoreWeights}
        containerStyle={containerStyle}
      />
    );
  }

}

export default observer(ScoreWeightsSelect)
