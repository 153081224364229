import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../../theme/Icon'
import { ListRow, ListCol } from '../../../../theme/List';
import PressableDoneIcon from '../../../../theme/PressableDoneIcon';

class GoalMeetingItem extends React.Component {

  render() {
    const { done, title, recurring, meetingDateFormatted } = this.props.meeting
    return (
      <ListRow className={'list-row-meeting ' + (done ? 'done' : '')}>
        <ListCol className="col-icon-done">
          <PressableDoneIcon done={done} size={16} />
        </ListCol>
        <ListCol className="col-icon-meeting">
          <Icon icon="meeting" color={done && '#dcdddf'} />
        </ListCol>
        <ListCol className="col-title">
          {title}
        </ListCol>
        <ListCol className="col-recurring">
          {
            recurring && (
              <Icon icon="recurring" color={done && '#dcdddf'} />
            )
          }
        </ListCol>
        <ListCol className="col-date">
          {meetingDateFormatted}
        </ListCol>
      </ListRow>
    )
  }
}

export default observer(GoalMeetingItem)
