import { observer } from "mobx-react";
import React from "react";
import "./PlannerFlowDoc.scss"
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";
import { mocks } from "./mocks";
import ActionPlannerUtils, { dateAddDays } from "Stores/ActionPlanner/ActionPlannerUtils";
import PlannerFlowTimes from "./PlannerFlowTimes";

const plannerUtils = new ActionPlannerUtils({
  dateFormat: 'MMMM D, YYYY',
  timeFormat: 'h:mm A',
  durationStep: 1,
  durationInterval: 15, 
  durationHeightPerInterval: 15, 
  taskMargin: 0,
  minDuration: 5,
})

const { startDate } = mocks
mocks.plannerFlow = PlannerFlow.fromProps({
  startDate,
  endDate: dateAddDays(new Date(), 6)
})
//mocks.plannerFlow.taskList.setItems(mocks.tasks)
const longTasks = mocks.tasks
      .map(task => ({ ...task, duration: 120 + 60 * Math.random() * 10 }))
    mocks.plannerFlow.taskList.setItems(longTasks)

global.mocks = mocks


const heightRatio = 1

class PlannerFlowDoc extends React.Component {

  getTaskPiecesForTimespanPiece(ts) {
    const typeIndex = mocks.plannerFlow.timespanTypes
      .indexOf(ts.type)
    return mocks.plannerFlow.taskPiecesFlow[typeIndex]?.taskFlowTimespansMerged
      .filter(piece => piece.timespanPiece === ts) || []
  }

  render() {
    return (
      <div className="plannerFlowDoc">
        <div className="plannerBox">
          {
            this.renderTimes()
          }
          {
            this.renderPlanner()
          }
        </div>
        {
          this.renderFooter()
        }
      </div>
    )
  }

  renderPlanner() {
    const { timespanFlowMerged } = mocks.plannerFlow
    return (
        <div className="planner">
        {
          timespanFlowMerged.map((ts, tsIndex) => {
            return (
              <div
                key={ts.type + tsIndex}
                className={"timespanType " + ts.type}
                style={{ height: ts.duration * heightRatio }}
              >
                {
                  this.getTaskPiecesForTimespanPiece(ts).map((taskPiece, index) => {
                    const height = taskPiece.duration * heightRatio
                    const key = taskPiece.task.uid + index
                    const taskPieceClass = [
                      (taskPiece.overflow ? 'overflow' : ''),
                      (taskPiece.underflow ? 'underflow' : '')
                    ].join(' ')
                    return (
                      <div
                        key={key}
                        className={"taskPieceBox " + key}
                        style={{ height }}
                      >
                        <div className={"taskPiece " + taskPieceClass}>
                          {
                            index + ': ' + taskPiece.task.title
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        )
      }
      </div>
    )
  }

  renderTimes() {
    return (
      <PlannerFlowTimes
        timespanPieces={mocks.plannerFlow.timespanFlowMerged}
        daysLength={mocks.plannerFlow.daysLength}
        plannerUtils={plannerUtils}
        showTimeLabels={true}
        timeLabelInterval={30}
      />
    )
  }

  renderFooter() {
    return (
      <footer>
        <pre>
          
          {
            `
            task0 = mocks.plannerFlow.taskList.items[0]
            task0.duration = 50
    
            resizeTask = () => {
                window.requestAnimationFrame(() => {
                    task0.duration += 10
                    if (task0.duration < 1000) resizeTask()
                })
            }
    
            resizeTask()
            `
          }
        
        </pre>
      </footer>
    )
  }
}

export default observer(PlannerFlowDoc)