export const data = [
  {
    "id": "Unassigned Tasks",
    "color": "#ce7475",
    "data": [
      {
        "x": 1,
        "y": 2
      },
      {
        "x": 2,
        "y": 4
      },
      {
        "x": 3,
        "y": 12
      },
      {
        "x": 4,
        "y": 15
      },
      {
        "x": 5,
        "y": 17
      },
      {
        "x": 6,
        "y":16
      },
      {
        "x": 7,
        "y": 15
      }
    ]
  }
]

export default data