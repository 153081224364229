import React from 'react'
import { observer } from 'mobx-react';
import { action } from 'mobx';

const debug = require('debug')('treks:select:AddOption')

class AddOption extends React.Component {

  static defaultProps = {
    addOptionContainerStyle: () => ({
      padding: '8px 12px',
      background: '#fff',
      color: '#000',
      borderBottom: '1px solid #dee0e1',
      display: 'block'
    })
  }

  componentDidMount() {
    this.props.addSelectEventListener('onCreateOption', this.onCreateOption)
  }

  componentWillUnmount() {
    setTimeout(
      () => this.props.removeSelectEventListener('onCreateOption', this.onCreateOption), 
      50
    )
  }

  @action onClick = event => {
    event.preventDefault()
    debug('onClick', { event })
    this.props.setSelectProps({ addOptionIsActive: true })
    this.props.addSelectEventListener('onMenuClose', this.onMenuClose)
  }

  @action onMenuClose = (...props) => {
    debug('onMenuClose', { props })
    this.props.setSelectProps({ addOptionIsActive: false })
    this.props.removeSelectEventListener('onMenuClose', this.onMenuClose)
  }

  @action onCreateOption = label => {
    debug('onCreateOption', { label })
    this.props.setSelectProps({ addOptionIsActive: false })
  }

  render() {
    const { props, store }  = this
    const { addOptionLabel, addOptionContainerStyle } = props
    const propsAndStore = ({ ...props, ...store })
    const containerStyle = addOptionContainerStyle(propsAndStore)
    debug('render', props, store)
    return (
      <div>
        <a href="#add-option" style={containerStyle} onClick={this.onClick}>
          {addOptionLabel}
        </a>
      </div>
    )
  }

}

export default observer(AddOption)
