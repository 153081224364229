import React from 'react'
import Sortable from 'sortablejs'
import './Sortable.scss'
import { getEventLoggingOptions } from './SortableUtils'

const debug = require('debug')('treks:docs:sortable')

export default class SortableJsDocs extends React.Component {

  state = {
    lists: [
      {
        name: 'Event',
        items: [],
        group: 'event'
      },
      {
        name: 'Personal',
        items: [],
        group: {
          name: 'timespan',
          put: ['timespan', 'event', 'nested']
        }
      },
      {
        name: 'Work',
        items: [],
        group: 'timespan'
      },
      {
        name: 'Afternoon Personal',
        items: [],
        group: 'timespan'
      }
    ]
  }

  componentDidMount() {
    setTimeout(() => this.addToLists(), 100)
    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
    };

    this.state.lists.forEach(list => Sortable.create(document.getElementById(list.name), {
      ...options,
      ...getEventLoggingOptions(),
      group: list.group
    }))
  }

  addToLists() {
    const listLen = Math.floor(Math.random() * 5)
    const tempList = new Array(listLen).fill()
    const lists = [ ...this.state.lists ]
    lists.forEach(list => {
      let len = list.items.length
      tempList.map(() => ({
        name: list.name + ' ' + ++len,
        duration: Math.max(Math.floor(Math.random() * 200), 30),
        subtasks: Math.random() > 0.6 ? (
          new Array(Math.floor(Math.random() * 5)).fill().map((val, index) => ({
            name: 'Sub task ' + index,
            duration: Math.floor(Math.random()*50) + 20
          }))
        ) : []
      })).forEach(item => list.items.push(item))
    })
    this.setState({ lists })
    window.setTimeout(() => this.makeNestedSortable())
  }

  addEvent() {
    const lists = [ ...this.state.lists ]
    const events = lists[0].items
    events.push({
      name: 'Event ' + (events.length + 1),
      duration: Math.max(Math.floor(Math.random() * 200), 30)
    })
    this.setState({
      lists
    })
  }

  makeNestedSortable() {
    const options = {
      animation: 100,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: {
        name: 'nested',
        put: ['event', 'nested']
      }
    }
    const nestedEls = document.querySelectorAll('.nested')
    debug('nested els', nestedEls)
    Array.from(nestedEls).forEach(el => Sortable.create(el, {
      ...options,
      ...getEventLoggingOptions(),
    }))
  }

  render() {
    return (
      <div className="container sortable-list-container">
        <div className="row">
        <div className="col">

          <h2>Sortable</h2>
          <p>
            Sortable multi list with flow around a fixed item
          </p>
          <p>
            <button onClick={() => this.addToLists()}>Add to Lists</button>
            <button onClick={() => this.addEvent()}>Add Event</button>
          </p>

          {
            this.state.lists.map((list, index) => {
              return (
                <div key={index} className="list-container">
                  <h3 className="list-name">{list.name}</h3>
                  <div  className="sortable-list" id={list.name}>
                  {
                    list.items.map(item => (
                      <div key={item.name} id={item.name} style={{ minHeight: item.duration }} className={list.name + '-item item'}>
                        {item.name}
                        {
                          item.type !== 'event' && (
                            <div className="nested" style={{ minHeight: item.duration - 10 }}>
                              {
                                item.subtasks && item.subtasks.map(({ name, duration }) => (
                                  <div key={name} className="item" style={{ height: duration }}>{name}</div>
                                ))
                              }
                              {
                                (new Array(Math.floor(item.duration/5)).fill()).map((value, index) => (
                                  <div key={index} className="item" style={{ height: 5, opacity: 0 }}></div>
                                ))
                              }
                            </div>
                          )
                        }
                      </div>
                    ))
                  }
                </div>
                </div>
              )
            })
          }

        </div>
        </div>
      </div>
    )
  }
}