import { override } from 'mobx'
import ProjectItem from './ProjectItem'
import OrderedList from 'Stores/Lists/OrderedList'
import { Item } from 'Stores/Lists'

/**
 * Project List
 */
export default class ProjectList extends OrderedList {

  get ModelType() {
    return ProjectItem
  }

  @override get visibleItems(): ProjectItem[] {
      return this.items.filter(project => !['planning', 'complete'].includes(project.status) && !project.deleted)
  }

  @override async fetch() {
    const res = await this.fetchState.get('project/list')
    if (res) {
      const items = res.data?.map(project => {
        project.categoryList = {
          items: project.Categories.map(category => {
            return {
              ...category,
              taskList: {
                totalItems:  category._count.Tasks
              }
            }
          })
        }
        project.taskList = {
          totalItems:  project._count.Tasks
        }
        return project
      })
      this.fromJSON({ items })
    }
    return this
  }

}