import Model from "Stores/Model";
import { LabelTaskList } from "./LabelTaskList";
import { hasMany, hasOne } from "Relationships/RelationshipDecorators";
import TaskItem from "../TaskItem";
import { observable } from "mobx";
import { LabelGroup } from "./LabelGroup";

export class TaskLabel extends Model {

  @observable title: string

  @observable color: string

  @observable focusOnTitle: boolean 

  @hasMany(() => LabelTaskList, (task: TaskItem) => task.taskLabelList)
  taskList: LabelTaskList

  @hasOne(() => LabelGroup, (group: LabelGroup) => group.labelList)
  group: LabelGroup

  async save() {
    const res = await this.saveState.postJson('task/label/create', {
      title: this.title,
      color: this.color,
      taskIds: this.taskList.items.map(task => task.id)
    })
    const id = res.data.label.id
    if (id && Number.isInteger(id)) {
      this.setProp('id', id)
    }
    return this
  }
  
}