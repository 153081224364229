import React from 'react'
import { observer } from 'mobx-react'
import data from './data'
import BurnDownGraph from './BurnDownGraph'
import './BurnDown.scss'
import { ProjectItem } from 'Stores/Project'
import ProjectTaskList from 'Stores/Project/PojectTaskList'
import TaskItem from 'Stores/Task'

const debug = require('debug')('treks:project:metrics:burndown')

type DayBurndown = {
  totalCompleted: number;
  date: Date;
  added?: number;
  remaining?: number;
  completed?: number;
  removed?: number;
  forecastCompleted?: number;
  forecastRemaining?: number;
}

type DayBurndownData = {
  day: string;
  "Work Added"?: number;
  "Work AddedColor"?: string;
  "Work Removed"?: string;
  "Work RemovedColor"?: string;
  "Work Remaining"?: number;
  "Work RemainingColor"?: string;
  "Work Completed"?: number;
  "Work CompletedColor"?: string;
  "Total Completed"?: number;
  "Total CompletedColor"?: string;
  "Work Forecast Completed"?: number;
  "Work Forecast CompletedColor"?: string;
  "Work Forecast Remaining"?: number;
  "Work Forecast RemainingColor"?: string;
}

class ProjectMetricsBurnDown extends React.Component<{
  project: ProjectItem,
  data: any
}, {
  data: any
}> {

  static defaultProps = {
    data,
  };

  componentDidMount() {
    this.setBurndownData();
  }

  formatDate(date: Date) {
    return date.toLocaleString(
      'en-us', 
      { month: 'short', day: 'numeric' }
    )
  }

  async setBurndownData() {
    const { taskList } = this.props.project;
    await taskList.fetchBurndown();
    const days = this.getActivityData(taskList);
    const projectedDays = this.getProjectedData(days)

    const lastDay = days[days.length - 1]
    const totalDuration = lastDay 
      ? lastDay.completed 
        + lastDay.totalCompleted 
        + lastDay.remaining 
        + lastDay.added
        - lastDay.removed
      : 0

    const data = this.getBurndownData(days, totalDuration)
    const projected = this.getBurndownData(projectedDays, totalDuration)

    debug('data', { data, projected })
    this.setState({
      data: [
        ...data,
        ...projected
      ]
    });
  }

  getAverage5DayDone(data: DayBurndown[]): number {
    const days = data.slice(-5)
    let worked = 0
    return days.reduce((sum, day) => {
      if (day.completed) {
        worked++
      }
      return sum + day.completed
    }, 0) / worked
  }

  getPercent(sumTasks: number, sumTotal: number): number {
    return sumTasks && sumTotal 
      ? parseFloat(Number(sumTasks / sumTotal * 100).toFixed(2)) : 0
  }

  getSumDuration(tasks: TaskItem[]): number {
    return tasks.reduce((sum, task) => sum + task.duration, 0)
  }

  getBurndownData(days: DayBurndown[], totalDuration: number): Partial<DayBurndownData>[] {
    debug('days', days)

    if (!days.length) return []

    let prevForecastRemaining = 0
    const data = days.map(day => {
      const dayDate = this.formatDate(day.date)
      const addedPercent = day.added ? this.getPercent(day.added, totalDuration) : 0
      const donePercent = day.completed ? this.getPercent(day.completed, totalDuration) : 0
      const remainingPercent = day.remaining ? this.getPercent(day.remaining, totalDuration) : 0
      const totalCompletedPercent = day.totalCompleted ? this.getPercent(day.totalCompleted, totalDuration) : 0
      const forecastCompletedPercent = day.forecastCompleted  ? this.getPercent(day.forecastCompleted, totalDuration)  : 0
      const forecastRemainingPercent = day.forecastRemaining ? this.getPercent(day.forecastRemaining, totalDuration) : 0
        
      const forecastRemaining = Number(
        Math.max(
          0, 
          forecastRemainingPercent - forecastCompletedPercent
        ).toFixed(2)
      )
      prevForecastRemaining = forecastRemainingPercent
      
      const dayData = {
        "day": dayDate,
        "Work Added": addedPercent,
        "Work AddedColor": "#1f5e99",
        "Work Completed": donePercent,
        "Work CompletedColor": "#cbeab6",
        "Work Remaining": remainingPercent,
        "Work RemainingColor": "#4592c2",
        "Total Completed": totalCompletedPercent,
        "Total CompletedColor": "rgba(255, 255, 255, 0)",
        "Work Forecast Completed": Math.min(prevForecastRemaining, forecastCompletedPercent),
        "Work Forecast CompletedColor": "#D3D5D6",
        "Work Forecast Remaining": forecastRemaining,
        "Work Forecast RemainingColor": "#AEB0B3",
      };
      return dayData
    })

    return data
  }

  getActivityData(taskList: ProjectTaskList): DayBurndown[] {
    const { dayTasks } = taskList
    debug('dayTasks', dayTasks)
    let totalCompleted = 0
    const data = dayTasks.map((day, index) => {
      const date = day.startDate
      const added = this.getSumDuration(day.addedTasks)
      const remaining = this.getSumDuration(day.remainingTasks)
      const completed = this.getSumDuration(day.doneTasks)
      const removed = this.getSumDuration(day.deletedTasks)

      const dayData = {
        date,
        added,
        remaining,
        completed,
        totalCompleted,
        removed,
      };

      totalCompleted += (completed + removed)

      return dayData
    });
    return data;
  }

  getProjectedData(days: DayBurndown[]): DayBurndown[] {
    const avgDone = Math.ceil(this.getAverage5DayDone(days))
    const currDay = days[days.length -1]
    const { totalCompleted } = currDay
    const remaining = currDay.remaining
    const added = currDay.added
    const work = remaining + added
    const projectedDayCount = work && avgDone 
      ? Math.ceil(work / avgDone) : 0
    let now = Date.now()
    const dayMs = 1000 * 60 * 60 * 24
    const maxDayCount = 10
    const projectedDays = new Array(
        Math.min(projectedDayCount, maxDayCount)
      )
      .fill(null)
      .map((_, i) => {
        const date = new Date(now  + dayMs * i)
        const completed = avgDone * i
        const dayData = {
          date,
          forecastCompleted: avgDone,
          forecastRemaining: work - completed,
          totalCompleted: totalCompleted + completed,
        };
        return dayData
      })
      debug('avgDone', { remaining, avgDone, projectedDays})
    return projectedDays
  }

  render() {
    const data = this.state?.data || this.props.data
    debug('render', data)
    return (
      <div className="metrics-graph-container metrics-graph-burndown">
        <div className="metrics-graph">
          <BurnDownGraph data={data} />
        </div>
      </div>
    )
  }
}

export default observer(ProjectMetricsBurnDown)
