import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectFiles.css'
import { observable } from 'mobx';
import ProjectFileList from './ProjectFileList'
import FileList from 'Stores/File/FileList'
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:project:files')

class ProjectFiles extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  componentDidMount() {
    this.props.project.fileList = FileList.create()
    this.props.project.fileList.fetch()
      .catch(error => {
        debug('file fetch error', error)
        getAppNotifications().error('Error fetching files')
      })
  }

  render() {
    const { project } = this.props
    debug('render', { project })
    return (
      <div className="project-files">
        <header className="row tab-header">
          <Icon icon="attach_file" size={15} />
          <div className="tab-title">FILES</div>
        </header>
        <ProjectFileList project={project} fileList={project.fileList} />
      </div>
    )
  }
}

export default observer(ProjectFiles)