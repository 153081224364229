import Model from "Stores/Model"

export default class AppToasterIntent extends Model {

  static INFO = 'info'
  static SUCCESS = 'success'
  static DANGER = 'danger'
  static WARNING = 'info'

}
