import React from 'react'
import logo from '../assets/images/logo.png';

export default class Logo extends React.PureComponent {
  render() {
    const { height, src } = this.props
    return (
      <img 
        className="main-logo"
        src={src || logo} 
        alt="logo" 
        style={{
          ...styles.image,
          height: { height }
        }} 
      />
    )
  }
}

const styles = {
  image: {
    display: 'inline-block',
  }
}