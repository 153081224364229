import { action, computed, observable, override } from 'mobx'
import { computedFn } from 'mobx-utils'
import { hasGlobal, hasOne } from 'Relationships/RelationshipDecorators'
import ActionPlanner from 'Stores/ActionPlanner'
import { TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan'
import Model from 'Stores/Model'
import { ModelJsonI } from 'Stores/Model/Type/Model'
import { stores } from 'Stores/Stores'
import TaskItem from 'Stores/Task'

type PFAPlanTaskSnap = {
  id: number|string,
  uid: string,
  startDate: Date,
  endDate: Date
}

export default class PFAPlan extends Model {

  @hasGlobal(() => stores.ActionPlanner) actionPlanner: ActionPlanner

  @action setActionPlanner(actionPlanner: ActionPlanner) {
    return this.setProp('actionPlanner', actionPlanner)
  }

  @hasOne(() => stores.TimeSpanItem) timespan: TimeSpanItem

  @action setTimespan(timespan: TimeSpanItem) {
    return this.setProp('timespan', timespan)
  }

  @computed get planDate(): Date {
    return this.actionPlanner.startOfTodayDate
  }

  @computed get timespanType(): string {
    return this.timespan.type
  }

  // changes by startDate, endDate
  @computedFn getTaskSnapshot(task: TaskItem): PFAPlanTaskSnap {
    return {
      id: task.id,
      uid: task.uid,
      startDate: task.startDate,
      endDate: task.endDate
    }
  }

  @observable planSnapshot: PFAPlanTaskSnap[] = []

  // changes if any task.startDate|endDate changes
  @computedFn getPlanSnapshot(): PFAPlanTaskSnap[] {
    if (!this.timespan.list) return [] // timespan not attached to planner
    return this.timespan.tasks.map(task => {
      return this.getTaskSnapshot(task)
    })
  }

  @action takePlanSnapshot() {
    this.planSnapshot = this.getPlanSnapshot()
  }

  // memoized by UID
  @computedFn getTaskSnapshotByUid(uid: string): PFAPlanTaskSnap {
    return this.planSnapshot.find(snap => snap.uid === uid)
  }

  @computed get tasksCompleted(): TaskItem[] {
    return this.timespan.tasks.filter(task => task.done && task.doneDate)
  }

  @observable taskCompleteTimeMargin: number = 0.1 // eg: 0.1 = 10% over

  @computed get tasksCompletedOnTime(): TaskItem[] {
    const onTimeTasks = this.tasksCompleted.filter(task => {
      const taskSnap = this.planSnapshot.find(snap => snap.uid === task.uid)
      if (!taskSnap) return false
      const addMarginMs = (this.taskCompleteTimeMargin * task.duration) * 1000 // ms
      const taskCompleteTimeMax = taskSnap.endDate.getTime() + addMarginMs
      return task.doneDate.getTime() <= taskCompleteTimeMax
    })
    return onTimeTasks
  }

  @computed get tasksCompletedNotOnTime(): TaskItem[] {
    return this.tasksCompleted.filter(task => {
      return !this.tasksCompletedOnTime.includes(task)
    })
  }

  toJSON() {
    return {
      planDate: this.planDate,
      timespanType: this.timespanType,
      planSnapshot: this.planSnapshot
    }
  }

  fromJSON(json: ModelJsonI): PFAPlan {
      const { planSnapshot } = json as any
      this.setProps({
        planSnapshot: typeof planSnapshot === 'string' ? JSON.parse(planSnapshot) : planSnapshot
      })
      return this
  }

  @override async save() {
    const resp = await this.saveState.postJson('pfa/plan/save', this.toJSON())
    return this.fromJSON(resp.data as any)
  }

  @override async fetch() {
    const { data } = await this.fetchState.get('pfa/plan/fetch', {
      timespanType: this.timespanType,
      planDate: this.planDate.getTime()
    })
    return this.fromJSON(data as any)
  }

}