import { override } from 'mobx'
import List from '../Lists/List'
import { importUser } from './User'

const debug = require('debug')('treks:store:user:list')

/**
 * User List
 */
export default class UserList extends List {

  get ModelType() {
    return importUser()
  }

  /**
   * Fetch the user list
   */
  @override async fetch(search) {
    debug('fetch user list')
    return this.fetchState.get('user/list', search)
      .then(resp => {
        const { users } = resp.data
        debug('resp', { users })
        this.setItems(users)
        return resp
      })
  }

}
