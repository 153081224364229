import React from 'react'
import { observer } from 'mobx-react'
import { when, toJS } from 'mobx'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import stores, { getStore} from 'Stores'
import { FormField } from 'theme/Form'
import paymentFields from './PaymentFields'
import planFields from './PlanFields'
import checkoutFields from './CheckoutFields'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:account:register:profile')

const headerText = 'Start your free trial'
const infoText = 'Free for 30 days, cancel at any time'
const SubmitBtnLabel = 'Start Free Trial'
const LimitedBtnLabel = 'Continue with our limited version'
const plan_code = 'pro-trial'
const checkoutInfo = `If you decide not to continue with a paid plan, 
cancelling is easy. After your 30 day free trial is 
over, you’ll be charged based on your selected plan.`
const checkoutTerms = `By continuing, you are agreeing to our subscriber 
terms. You will be automatically charged at the end 
of the trial period.`

export default withRouter(observer(class AccountStartFreeTrial extends React.Component {

  formRefs = {}

  accountRegisterStore = getStore(stores.AccountRegister)
  sessionStore = getStore(stores.Session)
  accountStore = getStore(stores.Session).Account
  billingInfoStore = getStore(stores.BillingInfo)
  paymentFormStore = stores.Form.fromFields(paymentFields)
  planFormStore = stores.Form.fromFields(planFields)
  checkoutStore = stores.Form.fromFields(checkoutFields)

  subscriptions = []
  plans = []

  componentDidMount() {
    global.AccountStartFreeTrial = this // debugging
    when(
      () => this.accountStore.email,
      () => {
        const paymentValues = this.accountStoreToPaymentFormFieldValues(this.accountStore)
        debug('autorun setting auth account to form', toJS(this.accountStore), paymentValues)
        this.paymentFormStore.setFieldValues(paymentValues)
        this.accountRegisterStore.setProps(this.accountStore)
        this.accountRegisterStore.getSubscriptionList()
          .then(({ data }) => {
            this.subscriptions = data.subscriptions
            debug('subscription list', this.subscriptions)
          })
          .catch(error => {
            debug('error getting subscriptions', error)
            getAppNotifications().error('Error getting subscriptions')
          })
        this.accountRegisterStore.getPlanList()
          .then(({ data }) => {
            this.plans = data.plans
            const planItems = this.plansToPlanItems(data.plans)
            this.planFormStore.setFieldItems('plan_code', planItems)
            debug('plan list', this.plans)
          })
          .catch(error => {
            debug('error getting plans', error)
            getAppNotifications().error('Error getting plans')
          })
      }
    )
  }

  /**
   * Normalize accountStore to recurly schema (first_name, last_name instead of name)
   * @param {Store} accountStore 
   */
  accountStoreToPaymentFormFieldValues(accountStore) {
    const values = {}
    Object.entries(accountStore).forEach(([key, value]) => {
      if (key === 'name') {
        const [first_name, last_name] = typeof value === 'string' && value.split(' ')
        Object.assign(values, { first_name, last_name })
      } else {
        values[key] = value
      }
    })
    return values
  }

  plansToPlanItems(plans) {
    return plans.map(plan => {
      return {
        label: plan.name,
        value: plan.plan_code,
      }
    })
  }

  getSelectedPlanCode() {
    debug('selected plan', this.planFormStore, this.planFormStore.getFieldValues().plan_code)
    return this.planFormStore.getFieldValues().plan_code
  }

  getTrialSubscription() {
    const plan_code = this.getSelectedPlanCode()
    return this.subscriptions
      .find(subscription => subscription.plan.plan_code === plan_code)
  }

  onClickSubmitBtn = async () => {
    try {
      const paymentValues = this.paymentFormStore.getFieldValues()
      this.billingInfoStore.setFromPaymentFormFieldValues(paymentValues)
      await this.billingInfoStore.create()
      if (!this.getTrialSubscription()) {
        await this.accountRegisterStore.createSubscription({
          plan_code
        })
      }
      this.props.history.push('/account/register/complete')
    } catch(error) {
      debug('subscription create error', error)
      getAppNotifications().error('An error occurred while trying to create your subscription')
    }
  }

  onClickLimitedBtn = async () => {
    this.props.history.push('/account/register/complete')
  }

  hasPaymentValuesFilled() {
    const paymentValues = this.paymentFormStore.getFieldValues()
    return Object.values(paymentValues).find(value => value)
  }

  onFieldValueChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  render() {

    debug('render', { paymentFormStore: this.paymentFormStore })

    const busy = this.accountRegisterStore.createSubscriptionState.state === 'pending' 
      || this.sessionStore.isAuthReqPending
      || this.accountRegisterStore.subscriptionListState.state === 'pending'

    return (
      <Container className="account-register account-start-free-trial settings">
        <Row className="centered-header">
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              {infoText}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form className="account-register-form form-bordered">
            <header className="header">Payment Details</header>

              {
                this.paymentFormStore.fields.map(field => {
                  return (
                    <FormField
                      key={field.name}
                      {...field}
                      disabled={field.disabled || busy} 
                      onChange={value => this.onFieldValueChange(field, value)} 
                    />
                  )
                })
              }

            </Form>
          </Col>
          <Col className="col-5">
            <Form className="account-register-form form-bordered account-register-form-plan">
              <header className="header">Trial Plan</header>

              {
                this.planFormStore.fields.map(field => {
                  return (
                    <FormField
                      key={field.name}
                      {...field}
                      disabled={field.disabled || busy} 
                      onChange={value => this.onFieldValueChange(field, value)} 
                    />
                  )
                })
              }

            </Form>

            <Form className="account-register-form form-bordered account-register-form-checkout">
              <header className="header">Billing</header>

              {
                this.checkoutStore.fields.map(field => {
                  return (
                    <FormField
                      key={field.name}
                      {...field}
                      disabled={field.disabled || busy} 
                      onChange={value => this.onFieldValueChange(field, value)} 
                    />
                  )
                })
              }

              <p className="info-text">
                {checkoutInfo}
              </p>

              <Button 
                onClick={this.onClickSubmitBtn} 
                variant="primary"
                disabled={busy}
              >
                {SubmitBtnLabel}
              </Button>

              <Button 
                className="btn-limited-version"
                onClick={this.onClickLimitedBtn} 
                variant="outline"
                disabled={busy}
              >
                {LimitedBtnLabel}
              </Button>

              <p className="info-text">
                {checkoutTerms}
              </p>

            </Form>
            
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>
    )
  }
}))