import { FormControlDropdown, FormControlCountryDropdown } from 'theme/Form'

const userSearchFields = [
  {
    name: 'name',
    label: 'Name',
    value: null,
  },
  {
    name: 'email',
    label: 'Email',
    value: null,
  },
  {
    name: 'phone',
    label: 'Phone',
    value: null,
  },
  {
    name: 'activity',
    label: 'Activity',
    value: null,
  },
  {
    name: 'created',
    label: 'Reg Date',
    value: null,
  },
  {
    name: 'country',
    label: 'Country',
    value: null,
    Component: FormControlCountryDropdown,
  },
  {
    name: 'plan',
    label: 'Plan',
    value: null,
    Component: FormControlDropdown,
    items: []
  },
  {
    name: 'type',
    label: 'Type',
    value: null,
    Component: FormControlDropdown,
    items: [
      'Beta',
      'Normal',
    ]
  },
  {
    name: 'status',
    label: 'Status',
    value: null,
    Component: FormControlDropdown,
    items: [
      'Active',
      'Inactive',
    ]
  },
  {
    name: 'domain',
    label: 'Domain',
    value: null,
  },
  {
    name: 'event',
    label: 'Event',
    value: null,
  },
  {
    name: 'subscription',
    label: 'Subscription',
    value: null,
    Component: FormControlDropdown,
    items: [
      'Developer',
      'User'
    ]
  }
]

export default userSearchFields