import React from 'react'
import { observer } from 'mobx-react'
import DatePicker from 'theme/DatePicker'

class ProjectMeetingDate extends React.Component {

  onChangeDate = date => {
    const { meeting } = this.props
    meeting.meetingDate = date
    meeting.save()
  }

  render() {
    const { meeting, className } = this.props
    return (
      <div className={'meeting-date ' + className}>
        <DatePicker
          date={meeting.meetingDate}
          onChange={this.onChangeDate}
          inline
        />
      </div>
    )
  }
}

export default observer(ProjectMeetingDate)