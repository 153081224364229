import { observable, override } from 'mobx'
import OrderedList from 'Stores/Lists/OrderedList'
import { DayFlow } from './DayFlow'
import { TimespanFlow } from './Timespan/TimespanFlow'

export class TimespanListFlow extends OrderedList {

  @observable day: DayFlow = null

  get ModelType(): typeof TimespanFlow {
    return TimespanFlow
  }

  @override get items(): TimespanFlow[] {
    return super.items
  }

  @override get orderedItems(): TimespanFlow[] {
    return super.orderedItems
  }

}