import { action, computed, IObservableValue, observable } from "mobx"
import { createLogger } from "../../Relationships/RelationshipLogger"
import Model from "."
import { Event, ListenerDisposer, EventListener } from "../../events/Event"

const globalState = { uid: 1 }

export class ModelContext<M extends Model> {

  uid:number

  @computed get model(): M {
    return this.getModel()
  }
  
  name?: string

  context?: any

  box: IObservableValue<M> = observable.box()

  _setModelEvent = new Event({ context: this, name: 'ΘΔ⊧' })

  constructor(model: M, context?: any, name?: string) {
    this.uid = globalState.uid++
    this.context = context
    this.name = name
    this.debug('create', { name, model, context })
    this.setModel(model)
  }

  @action setModel(nextModel: M){
    const prevModel = this.getModel()
    if (nextModel === prevModel) {
      this.debug('*** no-op set model is same', { nextModel, prevModel })
      return // no-op
    }
    
    this.debug('__set model to context', {
      context: this.context?.constructor?.name,
      key: this.name,
      next: nextModel?.constructor?.name,
      prev: prevModel?.constructor?.name })

    if (prevModel && nextModel && prevModel.constructor !== nextModel.constructor) {
      throw new TypeError('Next model type is not the same as previous')
    }
    this._trackNextModel(nextModel)
    this.box.set(nextModel)
    this._setModelEvent.fire(nextModel)
  }

  onSetModel = (listener: EventListener): ListenerDisposer => {
    return this._setModelEvent.add(listener)
  }

  getModel(): M {
    return this.box.get() as M
  }

  setContext(context: any) {
    this.context = context
  }

  getContext = (): any => this.context

  static _trackingEvents: WeakMap<Model, Event> = new WeakMap()

  private _trackingDisposer: ListenerDisposer

  private _trackNextModel(nextModel: M) {
    if (!nextModel) return; // don't track if empty
    let trackingEvent = ModelContext._trackingEvents.get(nextModel)
    // global intercept for this model
    if (!trackingEvent) {
      this.debug('### set/track model', { prevModel: this.model, nextModel })
      trackingEvent = new Event({ context: this, model: nextModel, name: '⋵→⋵' })
      nextModel.onSetId((change) => {
        this.debug('>>> trigger update', { nextModel })
        if (change.type === 'update') {
          const id = change.newValue
          const entity = nextModel.findModel({ id })
          if (entity === nextModel) {
            return change
          } else if (entity && entity !== nextModel) {
            trackingEvent.fire(entity)
            return null
          } else {
            trackingEvent.fire(nextModel)
          }
        }
        return change
      })
      ModelContext._trackingEvents.set(nextModel, trackingEvent)
    }
    // specific tracker for prev model
    if (typeof this._trackingDisposer === 'function') {
      this._trackingDisposer()
    }
    // specific tracker for this model
    this._trackingDisposer = trackingEvent.add(entity => {
      this._triggerEntityChange(entity)
      this.setModel(entity)
    })
  }

  private _changeEntityEvent = new Event({ context: this, name: 'ΘΔ⋵' })

  private _triggerEntityChange(nextModel: M) {
    this._changeEntityEvent.fire(nextModel)
  }

  onModelEntityChange = (cb: EventListener): ListenerDisposer => {
    return this._changeEntityEvent.add(cb)
  }

  loggers = []
  debug(...args) {
    //if (!(this.context && this.context.id === 'user1')) return;
    if (!this.loggers[this.uid]) {
      this.loggers[this.uid] = createLogger(require('debug')('treks:model:ModelContext'))
    }
    const _args = [this.uid, this.context?.modelName + '.' + this.context?.id, this.name].filter(arg => arg)
    this.loggers[this.uid](_args, ...args)
  }

}