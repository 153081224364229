import { override } from 'mobx'
import { User } from 'Stores/User';

const debug = require('debug')('treks:store:account')

export class Account extends User {

  @override async fetch() {
    debug('fetch account')
    await this.fetchState.get('account/detail')
      .then((resp) => {
        const { account } = resp.data
        debug('resp', { account })
        Object.assign(this, account)
        return resp
      })
    return this
  }

  @override async edit() {
    debug('update account', this)
    const { 
      email, name, password, profileImage, emailList, 
      country, phone, phoneType, 
      workEmail, companySize, companyRole, industry, authorizeNetAccountId } = this
    return this.fetchState.post('account/edit', {
      email,
      name,
      password,
      profileImage,
      country,
      phone,
      phoneType,
      workEmail,
      companySize,
      companyRole,
      industry,
      authorizeNetAccountId,
      emailList: JSON.stringify(emailList),
    })
  }

}

export const importAccount = () => Account
