import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import './ProjectDetail.scss'
import ProjectTitle from './ProjectTitle'
import ProjectStats from './ProjectStats'
import ProjectTabs from './ProjectTabs'
import { models } from 'Stores'
import WizardTabs from 'Components/Shared/Wizard/WizardTabs'

const debug = require('debug')('treks:project:detail')

class ProjectDetail extends React.Component {

  static propTypes = {
    project: PropTypes.instanceOf(models.ProjectItem).isRequired,
    projectList: PropTypes.instanceOf(models.ProjectList)
  }

  componentDidMount() {
    this.props.project.memberList.fetched()
    const focusedTabName = localStorage.getItem('project.focusedTabName')
    if (focusedTabName) {
      this.setState({ focusedTabName })
    }
  }

  componentDidUpdate(prevProps) {
    const { project } = this.props
    if (project !== prevProps.project) {
      project.memberList.fetched()
    }
  }

  onChangeWizardTab = (tab) => {
    this.setState({ focusedTabName: tab.name })
    localStorage.setItem('project.focusedTabName', tab.name)
  }

  render() {
    const { project, projectList } = this.props
    const { focusedTabName } = { ...this.state }
    debug('render', { project, projectList })
    return (
      <div className="project-detail">
        <div className="container">
          <header className="row project-header" style={{ backgroundColor: project.color }}>
            <Icon icon="project" size={20} />
            <ProjectTitle project={project} />
          </header>
        </div>
        <div className="container">
          <WizardTabs
            focusedTabName={focusedTabName}
            onChange={this.onChangeWizardTab}
          />
        </div>
        <ProjectStats project={project} />
        <ProjectTabs
          focusedWizardTabName={focusedTabName}
          project={project}
          onClickTab={this.onClickTab}
        />
      </div>
    )
  }
}

export default observer(ProjectDetail)