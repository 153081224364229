import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import Icon from 'theme/Icon'

class ListViewSearch extends React.Component {

  static propTypes = {
    onSearch: PropTypes.func.isRequired
  }

  static defaultProps = {
    label: 'Search'
  }

  @observable search = { value: '' }

  constructor() {
    super()
    makeObservable(this)
  }

  onCancel = () => {
    this.search = { value: '' }
    this.props.onSearch({ value: '' })
  }

  onChange = event => {
    const { label } = this.props
    const { value } = event.target
    this.search = { value }
    this.props.onSearch({ label, value })
  }

  render() {
    const { value } = this.search
    return (
      <div className="list-view-search-container">
        <Icon set="fa" icon="search" />
        <input
          className="filter-search-input"
          onChange={this.onChange}
          value={value}
        />
        {
          value
            ? <Icon set="fa" icon="times" onClick={this.onCancel} />
            : null
        }
      </div>
     )
  }
}

export default observer(ListViewSearch)