import { ActivityItem } from "Stores/Activity";
import { computed } from "mobx";

export class ProjectBlockerItem extends ActivityItem {

  @computed get blocker() {
    return !!this.afterState?.blocker
  }

  @computed get unBlocker() {
    return !!this.beforeState?.blocker
  }

}
