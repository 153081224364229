import React from 'react'
import { observer } from 'mobx-react'
import FollowerItem from './FollowerItem'

const debug = require('debug')('treks:theme:followers:list')

class FollowersList extends React.Component {

  onChange = option => {
    const { followers } = this.props
    const follower = followers.getItemById(option.value)
    followers.addItem(follower)
    debug('set follower', follower)
  }

  render() {
    const { options, followers, onRemoveFollower } = this.props
    const { items } = followers
    debug('render', { options, followers })
    return (
      <div className="avatar-list">
        {items.map(follower => (
          <FollowerItem
            key={follower.uid}
            followers={followers}
            follower={follower}
            onRemoveFollower={onRemoveFollower}
          />
        ))}
      </div>
    )
  }
}

export default observer(FollowersList)


