import { observable } from "mobx";
import { once } from "Stores/utils";

export const getDaysToTrackOptions = once(() => observable([
  {
    label: "Everyday",
    value: "everyday",
  },
  {
    label: "Weekdays",
    value: "weekdays",
  },
  {
    label: 'Custom',
    options: [
      {
        label: "Monday",
        value: "monday",
      },
      {
        label: "Tuesday",
        value: "tuesday",
      },
      {
        label: "Wednesday",
        value: "wednesday",
      },
      {
        label: "Thursday",
        value: "thursday",
      },
      {
        label: "Friday",
        value: "friday",
      },
      {
        label: "Saturday",
        value: "saturday",
      },
      {
        label: "Sunday",
        value: "sunday",
      }
    ]
  }
]));
