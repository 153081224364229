import React from 'react'
import { getAppNotifications } from '../../../../AppNotifications'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import './DeleteTimespanMenu.scss'

const debug = require('debug')('treks:ActionPlanner:PlannerContextMenu')

class DeleteTimespanMenu extends React.Component {

  ref = null

  static defaultProps = {
    TasksExistOnTimespanLabel: ({ count }) => count === 1 
      ? `A task exists on your planner for this timespan` 
      : `${count} tasks exist on your planner for this timespan`,
    cancelDeleteTasksLabel: 'cancel',
    MoveTasksToTimespanLabel: ({ Dropdown }) => <><span>move these tasks to </span><Dropdown /> <span>timespan</span></>,
    ConfirmMoveTasksToTimespanLabel: timespanName => `OK to move tasks to ${timespanName} timespan?`,
    removeTasksFromPlannerLabel: 'remove these tasks from planner',
    confirmRemoveTasksFromPlannerLabel: 'OK to remove tasks from action planner?'
  }

  uiState = ArbitraryModel.create({
    currentStep: null,
    timespanType: null
  })

  onDocumentInteraction = event => {
    if (this.ref && !this.ref.contains(event.target)) {
      this.props.onHideContextMenu(event, null)
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentInteraction)
  }

  componentDidUnMount() {
    document.removeEventListener('mousedown', this.onDocumentInteraction)
  }

  onMenuItemClick = (event, menuItem) => {
    const { timespan } = this.props
    event.preventDefault()
    menuItem.onClick({ timespan, event, menuItem, self: this })
  }

  @action removeTimespan() {
    const { timespanPiece, plannerFlow, minTimespanCount, minTimespanCountWarning } = this.props
    if (plannerFlow.timespanFlow.length <= minTimespanCount) {
      return getAppNotifications().warn(minTimespanCountWarning)
    }
    plannerFlow.resizeTimespanPiece(timespanPiece, -timespanPiece.duration)
    this.props.onHideContextMenu()
  }

  onRef = ref => this.ref = ref

  get movableToTimespans() {
    return this.props.plannerFlow.accountTimespans.visibleItems
      .filter(timespan => timespan.type !== 'sleep' && timespan.type !== this.props.timespan.type)
  }

  onChooseTimespanType = (event) => {
    this.uiState.setState({ timespanType: event.target.value })
  }

  onClickTimespanTypeSelect = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  renderChooseTimespanType() {
    const { movableToTimespans } = this
    return (
      <select onChange={this.onChooseTimespanType} onClick={this.onClickTimespanTypeSelect}>
        {
          movableToTimespans.map(timespan => {
            return (
              <option
                key={timespan.type}
                value={timespan.type}
              >
                {timespan.title}
              </option>
            )
          })
        }
      </select>
    )
  }

  onMoveTasksToTimespan = () => {
    const { timespanTasks, ConfirmMoveTasksToTimespanLabel } = this.props
    const type = this.uiState.timespanType || this.movableToTimespans[0]?.type
    
    if (confirm(ConfirmMoveTasksToTimespanLabel(type))) {
      timespanTasks.forEach(task => {
        task.timespanType = type
        task.save()
      })
      this.props.onDeleteTimespan()
      this.props.onHideContextMenu()
    }
  }

  onRemoveTasksFromPlanner = () => {
    const { timespanTasks, confirmRemoveTasksFromPlannerLabel } = this.props
    
    if (confirm(confirmRemoveTasksFromPlannerLabel)) {
      timespanTasks.forEach(task => {
        task.onPlanner = false
        task.save()
      })
      this.props.onDeleteTimespan()
      this.props.onHideContextMenu()
    }
  }

  renderMenu() {
    const {
      TasksExistOnTimespanLabel,
      cancelDeleteTasksLabel,
      MoveTasksToTimespanLabel,
      removeTasksFromPlannerLabel,
      taskCount
    } = this.props
    const menuItems = [
      {
        key: 'cancel',
        title: cancelDeleteTasksLabel,
        onClick: () => this.props.onHideContextMenu()
      },
      {
        key: 'move',
        title: () => <MoveTasksToTimespanLabel Dropdown={() => this.renderChooseTimespanType()} />,
        onClick: this.onMoveTasksToTimespan
      },
      {
        key: 'delete',
        title: removeTasksFromPlannerLabel,
        onClick: this.onRemoveTasksFromPlanner
      }
    ]
    return (
      <div 
        x-placement="bottom-start"
        aria-labelledby="dropdown-basic"
        className="delete-timespan-menu dropdown-menu show"
      >
        <h3 className="dropdown-header dropdown-item"><TasksExistOnTimespanLabel count={taskCount} /></h3>
        {
          menuItems.map(menuItem => {
            return (
              <a
                key={menuItem.key}
                href={'#' + menuItem.key}
                className="dropdown-item"
                onClick={event => this.onMenuItemClick(event, menuItem)}
              >
                {typeof menuItem.title === 'string' ? menuItem.title : <menuItem.title />}
              </a>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { currentStep } = this.uiState

    const steps = {
      'default': () => this.renderMenu(),
      'confirmDeleteTasks': () => {},
      'confirmMoveTasks': () => {}
    }

    const CurrentMenu = steps[currentStep] || steps.default

    return (
      <div 
        ref={this.onRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10000
        }}
        className="delete-timespan-context-menu"
      >
        <CurrentMenu />
      </div>
    )
  }
}

export default observer(DeleteTimespanMenu)