import Model from "Stores/Model";
import { hasMany } from "Relationships/RelationshipDecorators";
import { computed, observable, override } from "mobx";
import { LabelList } from "./LabelList";
import { TaskLabel } from "./TaskLabel";
import TaskList from "../TaskList";
import TaskItem from "../TaskItem";
import { LabelTaskList } from "./LabelTaskList";

export class LabelGroup extends Model {

  @observable title: string

  @observable color: string

  @hasMany(() => LabelList, (label: TaskLabel) => label.group)
  labelList: LabelList

  @hasMany(() => LabelTaskList, (task: TaskItem) => task.taskLabelList)
  taskList: TaskList

  @computed get categoryList() {
    return this.labelList
  }

  @computed get items() {
    return this.labelList
  }

  @override async fetch() {
    console.log('Todo: fetch label group')
    return this
  }
  
}