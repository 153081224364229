import React from 'react'
import { observer } from 'mobx-react'
import TaskCheckBox from 'Components/TaskDetail/TaskCheckbox'
import TaskTitle from 'Components/TaskDetail/TaskTitle'
import OnPlannerIcon from 'Components/ui/OnPlannerIcon'
import { DueDateField } from '../ListViewFields/DueDateField'
import { AssignedUserField } from '../ListViewFields/AssignedUserField'
import { TaskDurationField } from '../ListViewFields/TaskDurationField'
import { TaskParentField } from '../ListViewFields/TaskParentField'
import { TaskCategoryField } from '../ListViewFields/TaskCategoryField'

class ListViewFlatTaskItem extends React.Component {

  static defaultProps = {
    dateFormatOpts: {
      dateFormat: 'MMM D, YYYY',
      timeFormat: ''
    }
  }

  render() {
    const { item, list, parent, category, fields, dateFormatOpts } = this.props
    return (
      <div className="task-item">
        <OnPlannerIcon
          color={item.onPlanner ? '#97aaae' : '#dcdddf'}
          item={item}
          iconSize={15}
        />
        <TaskParentField item={item} fields={fields} />
        <TaskCategoryField item={item} fields={fields} />
        <TaskCheckBox item={item} size={14} />
        <TaskTitle item={item} list={list} parent={parent}  />
        <div className="attributes">
          <div className="actions">
            <DueDateField
              item={item}
              list={list}
              fields={fields}
              dateFormatOpts={dateFormatOpts}
            />
            <AssignedUserField item={item} list={list} fields={fields} />
          </div>
          <TaskDurationField item={item} list={list} fields={fields} />
        </div>
      </div>
     )
  }
}

export default observer(ListViewFlatTaskItem)