import React from 'react'
import Icon from '../../theme/Icon'
import { observer } from 'mobx-react';

type TaskDetailRowProps = {
  className?: string;
  icon?: string;
  label?: React.ReactNode;
  children: React.ReactNode;
  hasValue?: boolean;
  disabled?: boolean;
}

const TaskDetailRow = ({ className = '', icon = '', label = '', children, hasValue = false, disabled = false }: TaskDetailRowProps) => {
  return (
    <div className={'row' + (className ? ' ' + className : '') + (disabled ? ' disabled' : '')}>
      {
        <div className={'row-icon' + (hasValue ? ' has-value' : '') }>
          {
            icon 
              ? <Icon icon={icon} size={18} />
              : label
          }
        </div>
      }
      <div className='row-content'>
        {children}
      </div>
    </div>
  )
}

export default observer(TaskDetailRow)