import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import Icon from 'theme/Icon'
import Tooltip from 'theme/Tooltip'

export default observer(class TaskResizeHandle extends React.Component {
  render() {
    const { plannerFlow, taskPiece, ...props } = this.props
    const endDate = plannerFlow.getTaskPieceEndDate(taskPiece)
    return (
      <div className="action-planner-task-resize-handle" {...props}>
        <Icon name="resize" color={'#97ABB0'} size={'auto'} />
        <Tooltip placement="top">
          {
            moment(endDate).format('h:mm A')
          }
        </Tooltip>
      </div>
    )
  }
})