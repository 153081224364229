import React from 'react';
import { observer } from 'mobx-react';
import Icon from '../../../theme/Icon';
import './HabitDetail.scss';
import HabitTitle from './HabitTitle';
import HabitFrequency from './HabitFrequency';
import HabitCustomFrequency from './HabitCustomFrequency';
import HabitBudget from './HabitBudget';
import HabitUnits from './HabitUnits';
import moment from 'Modules/moment';
import HabitInstances from './HabitInstances';
import HabitPlanner from './HabitPlanner';

const debug = require('debug')('treks:habit:detail');

class HabitDetail extends React.Component {
    render() {
        const { habit, habitList } = this.props;
        debug('render', { habit, habitList });
        return (
            <div className="habit-detail">
                <div className="container">
                    <header className="row header">
                        <Icon className="icon" icon="recurring" size={20} />
                        <HabitTitle habit={habit} />
                        <div>
                            {moment(habit.startDate).format('ll')} - {moment(habit.endDate).format('ll')}
                        </div>
                    </header>

                    <div className="row details">
                        <HabitPlanner habit={habit} />
                    </div>

                    <div className="row details">
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <div className="label row-label">Budget:</div>
                            <HabitBudget habit={habit} />
                            <HabitUnits habit={habit} />
                        </div>
                    </div>


                    <div className="row details">
                        <div className="label row-label">Frequency:</div>
                        <div className="frequency">
                            <div className="selector">
                                <HabitFrequency habit={habit} />
                            </div>
                        </div>

                        <div
                            className="days-of-week"
                            style={{
                                display: habit && habit.frequencyUnits === 'cron' ? 'flex' : 'none',
                            }}
                        >
                            <HabitCustomFrequency habit={habit} />
                        </div>
                    </div>

                    <div className="row details">
                        <div className="label row-label">Instance:</div>
                        <HabitInstances habit={habit} />
                    </div>
                </div>
                {/* <GoalStats goal={goal} />
    <GoalTabs goal={goal} onSelect={this.onSelectTab} /> */}
            </div>
        );
    }
}

export default observer(HabitDetail)
