import { override } from 'mobx'
import GoalItem from './GoalItem'
import { MemberItem } from 'Stores/Member'
// import { models } from 'Stores/Stores'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class GoalMemberItem extends MemberItem {

	@hasOne(() => GoalItem)
  goal:GoalItem

  @override async save() {
    return this.saveState.get('goal/member/save', this.toJSON())
  }

}
