import { observable, override } from 'mobx'
import api, { createGetParams } from '../../services/api'
import ApiRequest from './ApiRequest'
import LocalStorageRequest from './LocalStorageRequest'

const debug = require('debug')('treks:stores:service:api:offline')

/**
 * Wraps API request with local state and remote state
 * LocalState does not throw errors
 * To see localState errors use localState.error 
 * 
 * The localState is a cache of
 *  get() - the last api request state
 *  post() - the last post payload attempt  
 */
export default class OfflineFirstApiRequest extends ApiRequest {

  @observable localState = new LocalStorageRequest()

  async setLocalState(path, data) {
    debug('setting local state', path, data)
    return this.localState.set(path, data)
      .catch(error => debug('Failed to set local state', error))
  }

  async getLocalState(path) {
    debug('getting local state', path)
    return this.localState.get(path)
      .then(data => {
        debug('got local state', path, data)
        return data
      })
      .catch(error => debug('Failed to get local state', error))
  }

  /**
   * Make an API post while cache locally and track request state
   */
  @override post = async (path, data = {}) => {
    debug('post', { path, data })
    this.setLocalState(path + '/' + data.id, data)
    return this.setRequest(api.post(path, data))
  }

  /**
   * Make an API post and track request state
   */
  @override postJson = async (path, data = {}) => {
    debug('post', { path, data })
    this.setLocalState(path, data)
    return this.setRequest(api(path, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data)
    }))
  }
  
  /**
   * Make an API get and track request state
   */
  @override get = async (path, data = {}) => {
    const uri = path + '?' + createGetParams(data)
    debug('get', { path, data, uri })
    this.getLocalState(uri)
    return this.setRequest(api.get(path, data))
      .then(resp => {
        this.setLocalState(uri, resp.data)
        return resp
      })
  }

}
