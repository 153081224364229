import React from 'react'
import { observer } from 'mobx-react'
import ListViewTasksContainer from '../ListViewTasks'
import ProjectList from 'Stores/Project/ProjectList'
import ListViewProjectDetail from './ListViewProjectDetail'
import './ListViewProjects.scss'
import { getStore, models } from 'Stores'
import { ListViewList } from '../Shared/ListViewContainer/models/ListViewList'
import Button from 'theme/Button'
import { LucidePlus } from 'lucide-react'
import Modal from 'Containers/Modal'
import { observable } from 'mobx'

class ListViewProjectsContainer extends React.Component {

  static defaultProps = {
    parentLists: ListViewList.create(),
    headers: [ // order matters
      {
        name: 'parent',
        label: 'PROJECT:Category',
        required: true
      },
      {
        name: 'item_name',
        label: 'Project Name',
        required: true
      },
      {
        name: 'due_date',
        label: 'Due Date'
      },
      {
        name: 'overdue_tasks',
        label: 'Overdue Tasks'
      },
      {
        name: 'duration',
        label: 'Duration'
      }
    ]
  }

  async componentDidMount() {
    const { parentLists } = this.props
    const projectList = getStore(ProjectList) as ProjectList
    parentLists.setItems([
      projectList
    ])
    projectList.fetchOrder()
    await projectList.fetched()
    projectList.visibleItems.forEach(project => {
      project.taskList.fetchNextPage()
      project.categoryList.items.forEach(category => {
        category.taskList.fetchNextPage()
      })
    })
  }

  onProjectTitleRef = (ref: HTMLInputElement) => {
    if (ref) ref.focus()
  }

  onCreateProject = () => {
    this.projectModalState.show = false
    const projectList = getStore(models.ProjectList)
    const project = projectList.addItem({
      ...this.createProjectState
    })
    project.save()
  }

  createProjectState = observable({
    title: '',
    // color: '#ccc'
  })

  projectModalState = observable({
    show: false
  })

  onClickCreateProject = () => {
    this.projectModalState.show = true
  }

  onHideCreateProjectModal = () => {
    this.projectModalState.show = false
  }

  onChangeProjectTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.createProjectState.title = event.target.value
  }

  onProjectTitleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log('event', event.key)
    if (event.key === 'Enter') {
      this.onCreateProject()
    }
  }

  render() {
    return (
      <div className='projects-container'>
        <div style={{
          width: '100%',
          padding: '5px 40px'
        }}>
          <Button
            onClick={this.onClickCreateProject}
            style={'float:right'}
            className='btn-small'
            variant="primary"
          >
            <span>{'Create Project'}</span>
            <LucidePlus />
          </Button>
        </div>
        <ListViewTasksContainer
          {...this.props}
          className="list-view-projects"
          allowFlatList={false}
          ListViewTaskCategoryList={ListViewProjectDetail}
        />
        <Modal
          title='Create Project'
          onHide={this.onHideCreateProjectModal}
          show={this.projectModalState.show}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: 5 }}>
              <label style={{ padding: 5, fontWeight: 'bold' }}>Project title</label>
              <input
                ref={this.onProjectTitleRef}
                onChange={this.onChangeProjectTitle}
                onKeyDown={this.onProjectTitleKeyDown} 
              />
            </div>
            <div style={{ padding: 5 }}>
              <Button onClick={this.onCreateProject}>
                {'Save Project'}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
  
}

export default observer(ListViewProjectsContainer)