import React from "react";
import { createRoot } from "react-dom/client";
import { configure } from "mobx";
configure({
  enforceActions: "never",
  useProxies: global.Proxy ? "always" : "never",
  isolateGlobalState: true,
});
import "bootstrap/dist/css/bootstrap.css"; // eslint-disable-line
import "./theme/style/base.css"; // eslint-disable-line
// deterministic dependencies load order
import "Stores"; // eslint-disable-line
import App from "./App"; // eslint-disable-line
import * as serviceWorker from "./serviceWorker"; // eslint-disable-line
import { IS_DEV } from "env";

if (IS_DEV) {
  global.dev = import("./dev");
}

createRoot(document.getElementById("root")).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
