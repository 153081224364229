import { LocalDeviceStorage } from 'Stores/Service/LocalDeviceStorage';
import { StorageServiceI } from 'Stores/Service/Type/StorageService'

const debug = require('debug')('treks:api:request');

type DataParams = {
  [key: string]: any
}

const timeoutDefault = 20000; // default abort timeout is 60s

let storage: StorageServiceI = new LocalDeviceStorage()

export const setStorage = (nextStorage: StorageServiceI) => {
  storage = nextStorage
}

export const createGetParams = (data: DataParams) => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, value || '');
  });
  return params;
};

export const createPostParams = (data: DataParams) => {
  const params = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    params.append(key, value || '');
  });
  return params;
};

export const getAuthToken = async (): Promise<string> => {
  const token = await storage.getItem('token') as string
  debug('get token', token);
  return token
};

let devAuthToken = ''
export const createDevAuthToken = async (): Promise<string> => {
  if (devAuthToken) return devAuthToken
  const { REACT_APP_DEV_API_URL, DEV_KEY } = process.env
  const api_url = REACT_APP_DEV_API_URL || 'http://localhost:5001/'
  const query = `?dev_key=${DEV_KEY}&userId=1&email=gabe@fijiwebdesign.com`
  try {
    const json = await request(`${api_url}dev/token/create` + query, { json: true })
    devAuthToken = json.token
  return devAuthToken
  } catch(err) {
    console.error('Failed to createDevAuthToken')
    throw err
  }
};

export const saveAuthToken = (token: string) => {
  debug('saving token', token);
  storage.setItem('token', token)
};

export const getTokenHeader = headers => {
  return headers.token || headers['x-access-token'] || headers.Authorization || headers.authorization;
};

const AbortController = global.AbortController;

export class ApiError extends Error {
  constructor(error?: { message: string }) {
    super(error && error.message);
    this.name = this.constructor.name;
    Object.assign(this, error);
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(error && error.message)).stack;
    }
  }
}

export class EmptyResponseError extends ApiError {
  message = 'Empty response from server'
}

export class TimeoutError extends ApiError {
  message = 'Request timed out on client'
}

type RequestOptions = {
  timeout?: number;
  headers?: { [key: string]: string };
  json?: boolean;
}

export const request = async (url: string, options: RequestOptions = {}) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const timeout = options.timeout || timeoutDefault;
  if (!options.headers) {options.headers = {};}
  if (!getTokenHeader(options.headers)) {
    const token = await getAuthToken();
    if (token) {options.headers.Authorization = 'Bearer ' + token;}
  }
  debug('api request', url, options);
  let promise;
  if (options.json) {
    promise = fetch(url, { ...options, signal })
      .then(response => response.json())
      .then(async response => {
        const authToken = response.token || (response.data && response.data.token);
        if (authToken) {
          await saveAuthToken(authToken);
        }
        if (response.error) {
          debug('api error', response);
          return Promise.reject(response.error);
        }
        if (!('data' in response)) {
          debug('api error: empty response', response);
          return Promise.reject(new EmptyResponseError());
        }
        debug('api response', response);
        return response;
      })
      .catch(error => {
        if (error && error.name === 'AbortError' && promise.isTimeout) {
          debug('fetch aborted', error);
          return Promise.reject(new TimeoutError());
        } else {
          debug('Unknown error', error);
          return Promise.reject(new ApiError(error));
        }
      });
  } else {
    promise = fetch(url, { ...options, signal });
  }

  promise.isTimeout = false;
  promise.timeout = () => {
    promise.isTimeout = true;
    controller.abort();
  };
  promise.abort = () => controller.abort();
  promise.retry = () => request(url, options);
  setTimeout(() => promise.timeout(), timeout);
  return promise;
};

export default request;
