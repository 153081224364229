import React from 'react'
import ColorPicker from 'theme/ColorPicker/ColorPicker'
import { observer } from 'mobx-react'

export default observer(function FieldColor(props) {
  const onChangeComplete = value => {
    const { field, onChange } = props
    onChange(value.hex, field)
  }
  return (
    <ColorPicker
      className="field-color list-col"
      onChangeComplete={onChangeComplete}
      {...props}
    />
  )
})