import React from 'react'
import Icon from 'theme/Icon'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'

const ViewUserDetail = withRouter(({ user, history }) => {
  const onClick = () => {
    history.push('/admin/user/edit/' + user.id)
  }
  return (
    <Button variant="outline" onClick={onClick} className="btn-user-detail">
      <Icon set="fa" icon="arrow-circle-right" color={'#3582b9'} />
    </Button>
  )
})

export default ViewUserDetail