import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from './utils'

export const TaskField = observer(({ fields, fieldName, item, style, noGroupLabel }) => {
  const field = item[fieldName]
  return (
    fieldIsEnabled(fields, fieldName) ? (
      <div
        className="attribute attribute-parent text-overflow-ellipses"
        style={style}
      >
        {field?.id ? field.title : (noGroupLabel || 'UnGrouped')}
      </div>
    ) : null
  )
})