import React from 'react'
import PropTypes from 'prop-types'
import Model from 'Stores/Model';
import { observer } from 'mobx-react';

export const ParentItemTitleInput = observer(({ item, style, inputProps = {} }) => {
  const onChange = (event) => { 
    item.title = event.target.value
    item.save()
  }
  return (
    <input
      className={"parent-title-input title-input input-borderless"} 
      value={item.title} 
      onChange={onChange} 
      style={style}
      {...inputProps}
    />
  )
})

ParentItemTitleInput.propTypes = {
  item: PropTypes.objectOf(() => Model)
}