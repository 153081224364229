import { observable, action } from 'mobx';
import Model from 'Stores/Model';

function getIds(list) {
  return list.map(item => item.id)
}

function getUnique(arr) {
  return arr.filter((value, index, self) => self.indexOf(value) === index)
}

/**
 * User List Table UI State
 */
export default class UserListTable extends Model {

  userListStore = null

  @observable allSelected = false
  @observable selectedUserIdsList = []

  constructor(userListStore) {
    super()
    this.userListStore = userListStore
  }

  @action toggleAllSelected() {
    this.allSelected = !this.allSelected
    this.setSelectedUsers(this.allSelected ? [ ...this.userListStore.items ] : [])
  }

  @action setSelectedUsers(users) {
    this.selectedUserIdsList = getIds(users)
  }

  @action selectUsers(users) {
    this.selectedUserIdsList = getUnique([ ...this.selectedUserIdsList, ...getIds(users) ])
  }

  @action unSelectUsers(users) {
    const ids = getIds(users)
    this.selectedUserIdsList = this.selectedUserIdsList.filter(id => !ids.includes(id))
  }

  isSelected(user) {
    return !!this.selectedUserIdsList.find(id => id === user.id)
  }

}
