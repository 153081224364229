export const habitTypeList = [
  {
    name: 'create',
    title: '{owner} created Habit',
  },
  {
    name: 'deleted',
    title: '{owner} deleted Habit',
  },
  {
    name: 'add_user',
    title: '{owner} added user {user} to Habit {goal}',
  },
  {
    name: 'remove_user',
    title: '{owner} removed user {user} from the Habit {goal}',
  },
  {
    name: 'add_user',
    title: '{owner} added user {user} to the Habit {goal}',
  },
  {
    name: 'add_task',
    title: '{user} added task {task} to the Habit {goal}',
  },
  {
    name: 'add_task',
    title: '{user} added task {task} to the Habit {goal}',
  },
]

export default habitTypeList