import React from 'react';
import './SubMenu.scss';
import { observer } from 'mobx-react';
import Select from '../../../theme/Form/Select';
import { action } from 'mobx';

const debug = require('debug')('treks:components:HabitFrequency')

const dictionary = [
  { name: 'Daily', frequency: 1, frequencyUnits: 'day' },
  { name: 'Weekly', frequency: 1, frequencyUnits: 'week' },
  { name: 'Custom', frequency: 0, frequencyUnits: 'cron' },

];

const getOption = (name = null, frequency = null, frequencyUnits = null) => {
  for (let i = 0; i < dictionary.length; i++) {
    let d = dictionary[i];
    debug('loop', name, d.name);
    if (name)
      if (d.name === name) {
        debug('returning', d);
        return d;
      }

    if (frequency > -1) if (d.frequency === frequency && d.frequencyUnits === frequencyUnits) return d;
  }
  debug('not found', name, frequency, frequencyUnits);
  return null;
};

class HabitFrequency extends React.Component {

  static defaultProps = {
    selectPlaceholder: 'Frequency',
  };

  @action onChange = (option) => {
    // const { value } = JSON.parse(option) || { value: null };

    const value = getOption(option.value);
    const { habit } = this.props;
    debug('onChange', { option, value });
    if (habit) {
      habit.frequencyUnits = value.frequencyUnits;
      habit.frequency = value.frequency;
    }
  };

  render() {
    const { habit } =
      this.props;
    const options = dictionary.map((d) => ({ label: d.name, value: d.name }));

    const value = habit && habit.frequency > -1 ? getOption(null, habit.frequency, habit.frequencyUnits).name : null;
    debug('render', { options, value });
    return (
      <div style={{ padding: 2, borderRadius: 5, minWidth: 150, paddingTop: 5 }}>
        <Select
          onChange={this.onChange}
          options={options}
          value={value}
        />
      </div>
    );
  }
}

export default observer(HabitFrequency)
