import { InfoTextFormControl } from 'theme/Form'

const caption = {
  name: 'info',
  info: 'Add teammates to work with you on TREKS',
  placeholder: 'Teammate First & Last Name',
  value: null,
  Component: InfoTextFormControl,
}

const fields = [
  {
    name: 'teammateName',
    label: 'Teammate Name',
    placeholder: 'Teammate First & Last Name',
    value: null,
  },
  {
    name: 'teammateEmail',
    label: 'Teammate Email',
    placeholder: 'name@company.com',
    value: null,
  },
]

export default fields
export {
  caption
}