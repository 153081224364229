import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalSubGoals.scss'
import { observable } from 'mobx';
import GoalSubGoalList from './GoalSubGoalList'
import Pressable from '../../../../theme/Pressable';
import { GoalItem } from 'Stores/Goal'
import { getStore, models, stores } from 'Stores'

const debug = require('debug')('treks:goal:subgoals')

class GoalSubGoals extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static propTypes = {
    goal: PropTypes.objectOf(() => GoalItem).isRequired,
    account: PropTypes.objectOf(() => stores.Account),
  }

  static defaultProps = {
    get account() {
      return getStore(models.Session).Account
    }
  }

  addSubGoal = props => {
    const item = {
      createdBy: this.props.account,
      ...props
    }
    debug('adding subgoal', item)
    this.props.goal.subgoals.addItem(item)
    this.props.goal.save()
    // @todo save()
    // in component that updates goal
    // goal.save()
    return true
  }

  onAddSubGoal = () => {
    this.addSubGoal({
      createdTS: new Date()
    })
  }

  render() {
    const { goal } = this.props
    debug('render', { goal })
    return (
      <div className="goal-subgoals">
        <header className="row tab-header">
          <Icon icon="subtasks" size={15} />
          <div className="tab-title">SubGoals</div>
        </header>
        <div className="goal-subgoals-list">
          <GoalSubGoalList goal={goal} subgoals={goal.subgoals} />
          <Pressable
            className="btn-add"
            onClick={this.onAddSubGoal}
          >
            + Add Subgoal
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(GoalSubGoals)
