import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { getStore, models } from 'Stores';
import { Select, ColoredSelect } from '../../theme/Form/Select'
import { getAppNotifications } from '../../AppNotifications';
import PressableSinglevalue from '../../theme/Form/Select/PressableSingleValue'
import { ProjectPane } from 'Stores/MainPaneGroup/data/MainPaneItems'

const debug = require('debug')('treks:task:project:select')

class TaskProjectSelect extends React.Component {

  static defaultProps = {
    get projectList() {
      return getStore(models.ProjectList)
    },
    get mainPaneGroup() {
      return getStore(models.MainPaneGroup)
    },
    projectSaveError: 'Failed to save your project',
    fetchFailErrMsg: 'Failed to fetch your projects',
    selectPlaceholder: 'Select Project',
    noOptionsMessage: () => 'No projects matching search...',
    menuOpenValue: 'Search Projects...',
    menuOpenPlaceholder: 'Search Projects...',
    addOptionLabel: 'Create New Project...',
    defaultColor: '#eee'
  }

  componentDidMount() {
    const { projectList, fetchFailErrMsg } = this.props
    projectList.fetch()
      .catch(error => {
        getAppNotifications().error(fetchFailErrMsg)
        console.error(error)
      })
  }

  componentDidUpdate() {
    const { projectList, item } = this.props
    // @todo fix with registry.
    // set categoryList in projectList.items[project === item.project] since it isn't loaded
    if (item.project && item.project.id) {
      const project = projectList.getItemByIdString(item.project.id)
      if (project) item.project.categoryList.setItems(project.categoryList.items)
    }
  }

  @action setProject(project) {
    const { item } = this.props
    item.setProject(project)
    item.projectId = project?.id
    item.save()
    debug('setProject', { item, project, id: project?.id })
  }

  @action onChange = action(option => {
    const { item, projectList } = this.props
    const id = option && option.value
    const project = id && projectList.getItemByIdString(id)
    this.setProject(project)
    debug('onChange', { item, id, option, project })
  })

  @action onCreateOption = async label => {
    const { projectList, defaultColor, projectSaveError } = this.props
    try {
      const newProject = await projectList.addItem({ title: label, color: defaultColor }).save()
      this.setProject(newProject)
      debug('onCreateOption', { label, newProject })
    } catch(err) {
      getAppNotifications().error(projectSaveError)
      console.error('Project save error', err)
    }
  }

  @action onMouseDownValue = event => {
    const { item, mainPaneGroup } = this.props
    event.preventDefault()
    event.stopPropagation()
    const projectPane = mainPaneGroup.addOrUpdateItem(ProjectPane)
    projectPane.setModelId(item.project.id)
  }

  render() {
    const { 
      projectList,
      item,
      selectPlaceholder,
      noOptionsMessage,
      menuOpenValue,
      menuOpenPlaceholder,
      addOptionLabel
    } = this.props
    const { project } = item
    const { id, color } = project || {}
    const options = projectList.items
      .map(({ title, id, color }) => ({ 
        label: title, 
        value: id,
        color
      }))
    const option = options.find(option => option.value === id)
    const value = id
      ? option
      : null
    const SelectCom = color ? ColoredSelect : Select
    debug('render', { item, project, options, value, id, color })
    return (
      <SelectCom 
        isClearable
        hasAddOption
        createOptionPosition={'first'}
        addOptionLabel={addOptionLabel}
        onAddOption={this.onAddOption}
        menuOpenPlaceholder={menuOpenPlaceholder}
        menuOpenValue={menuOpenValue}
        noOptionsMessage={noOptionsMessage}
        placeholder={selectPlaceholder}
        onChange={this.onChange}
        options={options} 
        value={value}
        onCreateOption={this.onCreateOption}
        components={{ 
          SingleValue: props => 
            <PressableSinglevalue
              className="project-select-value-pressable"
              onMouseDown={this.onMouseDownValue}
              childProps={props}
            />
        }}
      /> 
    )
  }
}

export default observer(TaskProjectSelect)