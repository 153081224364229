import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import moment from 'moment'
import Pressable from 'theme/Pressable'
import ActionPlanner from 'Stores/ActionPlanner'
import { PFAScoreHeader } from 'Components/ActionPlanner/Header/PFAHeader'
import { dateAddDays, numDaysBetweenDates, toStartOfDayDate } from 'Stores/ActionPlanner/ActionPlannerUtils'

const debug = require('debug')('treks:planner:header')

export default withRouter(observer(class DayPlannerFlowHeader extends React.Component {

  whenLoginDisposer = null

  static propTypes = {
    actionPlannerStore: PropTypes.objectOf(() => ActionPlanner),
    showPFA: PropTypes.bool,
    updateUrl: PropTypes.bool
  }

  static defaultProps = {
    updateUrl: false
  }

  get paneScrollEl() {
    return document.querySelector('.main-pane-component:has(.action-planner-body)')
  }

  get scrollDuration() {
    return this.props.plannerUtils.getDurationFromHeight(this.paneScrollEl.scrollTop)
  }

  get scrollDate() {
    const scrollDays = this.scrollDuration / (24 * 60)
    return dateAddDays(this.props.plannerFlow.startDate, scrollDays)
  }

  _attemptScrollToHeight(height, timeout = 100, interval = 50) {
    height = Math.max(height, 1) // do not trigger infinite scroll
    if (timeout <= 0) return
    debug('attemptscroll', { height, timeout, interval, scrollTop: this.paneScrollEl.scrollTop })
    this.paneScrollEl.scrollTop = height
    setTimeout(() => {
      if (this.paneScrollEl.scrollTop !== height) {
        this._attemptScrollToHeight(height, timeout - interval, interval)
      }
    }, interval)
  }

  scrollAddDuration(duration) {
    const height = this.props.plannerUtils.getHeightFromDuration(duration)
    const nextHeight = this.paneScrollEl.scrollTop + height
    this._attemptScrollToHeight(nextHeight)
  }

  onClickPrevDay = () => {
    const { plannerFlow, actionPlannerStore } = this.props
    debug('plannerFlow', plannerFlow)
    const prevDayDate = (dateAddDays(this.scrollDate, -1))
    if (plannerFlow.startDate > prevDayDate) {
      plannerFlow.setProps({
        startDate: dateAddDays(plannerFlow.startDate, -1)
      })
      actionPlannerStore.setFocusedDate(plannerFlow.startDate)
    }
    this.scrollAddDuration(-1 * 24 * 60)
    global.prev = { prevDayDate, startDate: plannerFlow.startDate  }
    debug('prev day', { prevDayDate, startDate: plannerFlow.startDate  })
  }

  onClickNextDay = () => {
    const { plannerFlow, actionPlannerStore } = this.props
    const nextDayDate = (dateAddDays(this.scrollDate, 1))
    if (plannerFlow.endDate < nextDayDate) {
      plannerFlow.setProps({
        endDate: dateAddDays(plannerFlow.endDate, 1)
      })
      actionPlannerStore.setFocusedDate(plannerFlow.endDate)
    }
    this.scrollAddDuration(24 * 60)
    debug('next day', { nextDayDate, endDate: plannerFlow.endDate })
  }

  render() {
    const { actionPlannerStore, isFloating, showPFA } = this.props
    const { focusedDate, opts } = actionPlannerStore
    const title = moment(focusedDate).format(opts.dateFormat)
    debug('render', { title, focusedDate })
    return (
      <header className={'action-planner-header ' + (isFloating ? 'float' : 'inline')}>
        <div className='header-item'>
          { 
            showPFA ? <PFAScoreHeader /> : null
          }
        </div>
        <div className='header-item'>
          <nav className={'action-planner-header-nav'}>
            <Pressable onClick={this.onClickPrevDay}>
              <Icon set="fa" icon="chevron-left" size="xs" color={'#808284'} />
            </Pressable>
            <h2 className="action-planner-title">{title}</h2>
            <Pressable onClick={this.onClickNextDay}>
              <Icon set="fa" icon="chevron-right" size="xs" color={'#808284'} />
            </Pressable>
          </nav>
        </div>
        <div className='header-item'></div>
      </header>
    )
  }

}))
