import { observer } from "mobx-react";
import React from "react";
import "./WeekPlannerFlowDoc.scss"
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";
import { mocks } from "./mocks";
import ActionPlannerUtils, { dateAddDays } from "Stores/ActionPlanner/ActionPlannerUtils";
import PlannerFlowTimes from "./PlannerFlowTimes";
import { WeekPlannerFlowModel } from "./models/WeekPlannerFlowModel";

const plannerUtils = new ActionPlannerUtils({
  dateFormat: 'MMMM D, YYYY',
  timeFormat: 'h:mm A',
  durationStep: 1,
  durationInterval: 15, 
  durationHeightPerInterval: 15, 
  taskMargin: 0,
  minDuration: 5,
})

const { startDate } = mocks
mocks.plannerFlow = PlannerFlow.fromProps({
  startDate,
  endDate: dateAddDays(new Date(), 6)
})
//mocks.plannerFlow.taskList.setItems(mocks.tasks)
const longTasks = mocks.tasks
      .map(task => ({ ...task, duration: 120 + 60 * Math.random() * 10 }))    
const personalTasks = longTasks.map(task => ({
  ...task,
  timespanType: 'personal'
}))
mocks.plannerFlow.taskList.setItems([...personalTasks, ...longTasks])

global.mocks = mocks

const heightRatio = 1

const weekFlowModel = WeekPlannerFlowModel.fromProps({
  plannerFlow: mocks.plannerFlow
})

class WeekPlannerFlowDoc extends React.Component {

  render() {
    return (
      <div className="weekPlannerFlowDoc">
        <div className="plannerBox">
          {
            <PlannerFlowTimes
              timespanPieces={weekFlowModel.firstDayTimespans}
              plannerUtils={plannerUtils}
              showTimeLabels={true}
              timeLabelInterval={30}
            />
          }
          {
            this.renderPlanner()
          }
        </div>
        {
          this.renderFooter()
        }
      </div>
    )
  }

  renderPlanner() {
    const dayGroupedTimespans = weekFlowModel.dayGroupedTimespans
    return (
        <div className="week-planner">
        {
          dayGroupedTimespans.map((dayTimespans, index) => {
            return (
              <div key={index} className="day">
                {
                  this.renderTimes(dayTimespans)
                }
                {
                  dayTimespans.map((ts, tsIndex) => {
                    return (
                      <div
                        key={ts.type + tsIndex}
                        className={"timespanType " + ts.type}
                        style={{ height: ts.duration * heightRatio }}
                      >
                        {
                          weekFlowModel.getTaskPiecesForTimespan(ts).map((taskPiece, index) => {
                            const height = taskPiece.duration * heightRatio
                            const key = taskPiece.task.uid + index
                            const taskPieceClass = [
                              (taskPiece.overflow ? 'overflow' : ''),
                              (taskPiece.underflow ? 'underflow' : '')
                            ].join(' ')
                            return (
                              <div
                                key={key}
                                className={"taskPieceBox " + key}
                                style={{ height }}
                              >
                                <div className={"taskPiece " + taskPieceClass}>
                                  {
                                    index + ': ' + taskPiece.task.title
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
      }
      </div>
    )
  }

  renderTimes(timespanPieces) {
    return (
      <PlannerFlowTimes
        timespanPieces={timespanPieces}
        plannerUtils={plannerUtils}
        showTimeLabels={false}
      />
    )
  }

  renderFooter() {
    return (
      <footer>
        <pre>
          
          {
            `
            task0 = mocks.plannerFlow.taskList.items[0]
            task0.duration = 50
    
            resizeTask = () => {
                window.requestAnimationFrame(() => {
                    task0.duration += 10
                    if (task0.duration < 1000) resizeTask()
                })
            }
    
            resizeTask()
            `
          }
        
        </pre>
      </footer>
    )
  }
}

export default observer(WeekPlannerFlowDoc)