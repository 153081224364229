import React from 'react'
import { observer } from 'mobx-react'
import CalendarAccountRow from './CalendarAccountRow'
import { getAppNotifications } from '../../../AppNotifications'
import { stores, getStore } from 'Stores'
import { CalendarAccountList } from 'Stores/Calendar'
import { TimeSpanListDaySettings } from 'Stores/ActionPlanner/TimeSpan/Settings'

const debug = require('debug')('treks:component:calendar:accounts')

type CalendarAccountsProps = {
  getCalendarAccountList: () => CalendarAccountList;
  getTimeSpanList: () => TimeSpanListDaySettings;
}

class CalendarAccounts extends React.Component<CalendarAccountsProps> {

  static defaultProps = {
    getCalendarAccountList: () => getStore(stores.CalendarAccountList),
    getTimeSpanList: () => getStore(stores.ActionPlanner).dayTimespans
  }

  componentDidMount() {
    this.props.getCalendarAccountList().fetch()
      .catch(error => getAppNotifications().error(error.message))
  }

  render() {
    const { getCalendarAccountList, getTimeSpanList } = this.props
    const calendarAccountList = getCalendarAccountList()
    const timeSpanList = getTimeSpanList()
    debug('render', { calendarAccountList, timeSpanList })
    return (
      <div className="calendar-account-list">
        {
          calendarAccountList.items.map(calendarAccount => (
            <CalendarAccountRow 
              key={calendarAccount.id} 
              list={calendarAccountList} 
              account={calendarAccount}
              timeSpanList={timeSpanList}
            />
          ))
        }
      </div>
    )
  }
}

export default observer(CalendarAccounts)