import { JsonAny } from 'Stores/Model/Type/Json'
import { LocalStorageAdapter } from './Storage/Adapter/LocalStorageAdapter'
import { StorageSerializerJson } from './Storage/Serializer/StorageSerializerJson'
import { StorageSerializerI } from './Type/StorageSerializer'
import { StorageServiceI } from './Type/StorageService'

/**
 * Consistent interface for storage
 */
export class LocalDeviceStorage implements StorageServiceI {

  namespace: string = ''
  adapter: any = new LocalStorageAdapter()
  timeoutMs: number = 500

  serializer: StorageSerializerI = new StorageSerializerJson()

  constructor(namespace?: string, adapter?) {
    if (namespace) this.namespace = namespace
    if (adapter) this.adapter = adapter
  }

  getAdapter() {
    return this.adapter
  }

  setAdapter(adapter) {
    this.adapter = adapter
  }

  async timeout(promise: Promise<any>, ms: number = this.timeoutMs, reason = 'Storage operation timeout') {
    let resolved = false
    promise.then(() => resolved = true)
    const timer = new Promise((_, reject) => setTimeout(() => {
      !resolved && reject(reason)
    }, ms))
    return Promise.race([promise, timer])
  }

  /**
   * Get item from local storage w/ timeout
   */
  async getItem(path: string) {
    const raw = await this.timeout(this.getAdapter().getItem(path))
    return this.serializer.unserialize(raw)
  }

  /**
   * Save item to local storage w/ timeout
   */
  async setItem(path: string, data: JsonAny = {}) {
    return this.timeout(this.getAdapter().setItem(path, this.serializer.serialize(data)))
  }

}

// debug
window['LocalDeviceStorage'] = LocalDeviceStorage
