import React from 'react'
import { observer } from 'mobx-react'
import '../ActionPlanner.scss'
import '../../TaskDetail/TaskDetail.scss'
import DueToday from '../DueToday'
import RealtimeIndicator from '../RealtimeIndicator'
import ActionPlannerTimespan from '../ActionPlannerTimespan/ActionPlannerTimespan'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import Resizable from 'Modules/Resizable';
import TimespanResizeHandle from '../TimespanResizeHandle'
import { autorun } from 'mobx'
import TimespanTitle from '../TimespanTitle'
import ActionPlannerUtils, { dateAddDays } from 'Stores/ActionPlanner/ActionPlannerUtils'
import ActionPlannerStickyTasks from '../StickyTasks/ActionPlannerStickyTasks'
import PlannerFlowTimes from 'Screens/Docs/PlannerFlow/PlannerFlowTimes'
import DayPlannerFlowHeader from './Header/DayPlannerFlowHeader'
import DayPlannerEventsFlow from './DayPlannerEventsFlow'
import DayPlannerDaySplitLabel from './DayPlannerDaySplitLabel'
import { round } from 'Stores/utils'
import { getKeyPressState } from 'uiState/KeypressState'
import ActionPlanner from 'Stores/ActionPlanner'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow'
import TaskItem from 'Stores/Task'
import { TimespanPieceFlow } from 'Stores/ActionPlanner/PlannerFlow/TimespanPieceFlow'

const debug = require('debug')('treks:planner')

type ResizeProps = {
  height?: number;
  width?: number;
}

type Props = {
  plannerFlow: PlannerFlow;
  actionPlannerStore: ActionPlanner;
  plannerUtils: ActionPlannerUtils;
  minutesPerRow: number;
  numDaysToBuffer: number; // days to load beyond viewed day
  items: TaskItem[];
  itemsDue: TaskItem[];
  onOrderChange: (order: string[]) => void;
  onTaskDurationChange: (task: TaskItem, duration: number) => void;
  onTaskDurationUpdate: (task: TaskItem, duration: number) => void;
  onTaskPress: (task: TaskItem) => void;
  onContextMenu: (event: React.MouseEvent, task: TaskItem) => void;
  showHeader: boolean;
  showTimeLabels: boolean;
  showTimespanTitle: boolean;
  showDueToday: boolean;
  showRealtimeIndicator: boolean;
  dueTodayLabel: string;
}



class DayPlannerFlow extends React.Component<Props> {

  static defaultProps = {
    resizeNextTimespan: true,
    syncTimespansErrorMsg: 'Unable to sync your timespan settings',
    minutesPerRow: 5,
    numDaysToBuffer: 1,
  }

  uiState = ArbitraryModel.fromProps({
    isResizing: false,
    focusedTimespan: null,
  })

  resizeState = {
    lastDuration: null,
    startDuration: null
  }

  componentDidMount() {
    const { actionPlannerStore } = this.props
    this.createInfinitePlannerScroll()
    this.syncTimespanSettings()
    global.plannerFlow = this.plannerFlow // debug
    global.plannerUtils = this.props.plannerUtils
  }

  syncTimespanSettings() {
    const { plannerFlow } = this.props
    autorun(() => {
      const { startDate, endDate } = plannerFlow
      debug('planner start/end change', { startDate, endDate })
      plannerFlow.dayList.items.map(async day => {
        const dayJSON = await day.fetched()
        debug('updated day json', dayJSON)
      })
    })
  }

  setFocusedDayFromScroll(scrollTop: number) {
    const { actionPlannerStore, plannerFlow, plannerUtils } = this.props
    const { startDate } = plannerFlow
    const scrollDuration = plannerUtils.getDurationFromHeight(scrollTop)
    const scrollDays = Math.max(0, scrollDuration / (60*24)) // 0 if < 1
    const scrollDate = dateAddDays(startDate, scrollDays)
    actionPlannerStore.setFocusedDate(scrollDate)
    debug('setFocusedDayFromscroll', { scrollDate, startDate, scrollDays, scrollDuration })
  }

  async scrollToHeight(scrollEl: Element, height: number, cb: () => void, timeout = 100) {
    const startTime = new Date().getTime()
    // setTimeout gives time for react render to complete
    setTimeout(() =>  requestAnimationFrame(() => {
      scrollEl.scrollTop = height
      if (scrollEl.scrollTop !== height && timeout > 0) {
        this.scrollToHeight(scrollEl, height, undefined, timeout - (new Date().getTime() - startTime))
      } else {
        if (typeof cb === 'function') {
          cb()
        }
      }
    }))
  }

  createInfinitePlannerScroll() {
    const { numDaysToBuffer, plannerUtils } = this.props
    const bufferHeight = 100
    const hourHeight = plannerUtils.getHeightFromDuration(60)
    const dayHeight = hourHeight * 24
    const paneScrollEl = document.querySelector('.main-pane-component:has(.action-planner-body)')
    let prevScrollTop = 0
    let lastDayBufferTime = Date.now()
    paneScrollEl.addEventListener('scroll', () => {
      const { scrollTop, clientHeight, scrollHeight } = paneScrollEl
      const scrollDelta = scrollTop - prevScrollTop
      prevScrollTop = scrollTop
      this.setFocusedDayFromScroll(scrollTop)
      if (Date.now() - lastDayBufferTime < 200) {
        debug('rendering...')
        return 
      }
      const nextDayBufferHeight = numDaysToBuffer === 0 ? bufferHeight : dayHeight
      if (scrollDelta > 0 && ((scrollTop + clientHeight + nextDayBufferHeight) >= scrollHeight)) {
        this.addNextDaysToPlanner(1)
        lastDayBufferTime = Date.now()
      }
      const reachedTop = scrollTop <= 0
      const reachedFirstDay = (scrollTop - nextDayBufferHeight) <= 0
      if (scrollDelta < 0 && (numDaysToBuffer === 0 ? reachedTop : reachedFirstDay)) {
        this.addPrevDaysToPlanner(1)
        lastDayBufferTime = Date.now()
        // scroll to before prepend day
        setTimeout(() => {
          const prevDayEnd = (dayHeight * numDaysToBuffer) + dayHeight - hourHeight
          debug('prevDaEnd', prevDayEnd)
          this.scrollToHeight(paneScrollEl, prevDayEnd, () => {
            debug('settting focused day from scroll', )
            this.setFocusedDayFromScroll(paneScrollEl.scrollTop)
          })
        }, 100)
        
      }
      debug('scroll', { 
        scrollDelta, scrollTop, scrollHeight, clientHeight, 
        numDaysToBuffer, nextDayBufferHeight, dayHeight, reachedTop, reachedFirstDay
      })
    })
  }

  addNextDaysToPlanner(dayCount) {
    const { startDate, endDate }  = this.plannerFlow
    debug('adding next day to planner', { startDate, endDate })
    this.plannerFlow.setProps({
      endDate: dateAddDays(endDate, dayCount)
    })
    debug('added next day to planner', { startDate, endDate: this.plannerFlow.endDate })
  }

  addPrevDaysToPlanner(dayCount) {
    const { startDate, endDate }  = this.plannerFlow
    debug('adding prev day to planner', { startDate, endDate })
    this.plannerFlow.setProps({
      startDate: dateAddDays(startDate, -dayCount)
    })
    debug('added prev day to planner', { startDate: this.plannerFlow.startDate, endDate })
  }

  get isAnyCtrlKeyPressed() {
    return getKeyPressState().isAnyCtrlKeyPressed
  }

  onResize = (timespanPiece, { height }) => {
    const sticky = this.isAnyCtrlKeyPressed
    const { plannerUtils, plannerFlow } = this.props
    const { lastDuration } = this.resizeState
    const heightDuration = plannerUtils.getDurationFromHeight(height)
    const deltaDuration = heightDuration - lastDuration
    const lastPiece = plannerFlow.getLastTimespanPiece(timespanPiece)
    const lastPieceEndDuration = lastPiece.endDuration
    const nextLastPieceEndDuration = lastPieceEndDuration + deltaDuration
    const nextDeltaDuration = round(nextLastPieceEndDuration, sticky ? 5 : 1) - lastPieceEndDuration
    const appliedDelta = plannerFlow.resizeTimespanPiece(timespanPiece, nextDeltaDuration, true)
    const nextDuration = lastDuration + appliedDelta
    this.resizeState.lastDuration = nextDuration
    debug('resize', { heightDuration, lastDuration, deltaDuration, nextDeltaDuration, appliedDelta, nextDuration })
  }

  onResizeStart = (timespanPiece: TimespanPieceFlow, props?: ResizeProps) => {
    debug('resize start', { timespanPiece })
    document.body.style.cursor = 'row-resize'
    this.uiState.setState({
      isResizing: true,
      focusedTimespan: timespanPiece
    })
    this.resizeState.startDuration = timespanPiece.duration
    this.resizeState.lastDuration = timespanPiece.duration
  }

  onResizeStop = (timespanPiece: TimespanPieceFlow, props?: ResizeProps) => {
    debug('resize stop', { timespanPiece })
    document.body.style.cursor = ''
    const { actionPlannerStore } = this.props

    this.uiState.setState({
      isResizing: false,
      focusedTimespan: null
    })
    this.props.plannerFlow.roundTimespans()
    this.removeZeroDurationTimespans()

    this.saveDaysWithTimespanPiece(timespanPiece)
  }

  getDaysAroundTimespanPiece(timespanPiece: TimespanPieceFlow) {
    const day = timespanPiece.lastTimespan.list.day
    const prevDay = timespanPiece.prevTimespanPiece?.lastTimespan.list.day
    const nextDay = timespanPiece.nextTimespanPiece?.firstTimespan.list.day
    const days = [prevDay, day, nextDay].filter(day => day)
    return days
  }

  saveDaysWithTimespanPiece(timespanPiece) {
    // @todo optimize will require 
    // this.resizeState.startDuration to get resize direction 
    // and figure out days affected
    const days = this.getDaysAroundTimespanPiece(timespanPiece)
    days.map(day => day.save())
  }

  removeZeroDurationTimespans() {
    this.plannerFlow.timespans
        .filter(ts => ts.duration === 0)
        .forEach(ts => {
          this.plannerFlow.removeTimespan(ts)
        })
  }

  getHeightByRef(ref) {
    return parseFloat(ref.style.height.replace('px', ''))
  }

  onClickTimespan = ({ timespanPiece }) => {
    const { actionPlannerStore } = this.props
    actionPlannerStore.setFocusedTimespan(timespanPiece)
  }

  get plannerFlow() {
    return this.props.plannerFlow
  }
  
  render() {
    const { 
      items, itemsDue, actionPlannerStore, plannerUtils, 
      onOrderChange, onTaskDurationChange, onTaskDurationUpdate, onTaskPress, onContextMenu, 
      showHeader, showTimeLabels, showTimespanTitle, showDueToday, showRealtimeIndicator 
    } = this.props
    const { dayTimespans } = actionPlannerStore
    const { isResizing, focusedTimespan } = this.uiState
    const { plannerFlow } = this
    debug('render', { items, dayTimespans, isResizing, focusedTimespan })
    return (
      <div className="action-planner-body">
        {
          showDueToday ? (
            <DueToday items={itemsDue} label={this.props.dueTodayLabel} />
          ) : null
        }
        {
          showHeader ? (
            <DayPlannerFlowHeader
              actionPlannerStore={actionPlannerStore}
              plannerFlow={plannerFlow}
              isFloating={true}
            />
          ) : null
        }
        {
          showRealtimeIndicator ? (
            <RealtimeIndicator 
              scrollIntoView={true}
              plannerFlow={plannerFlow}
              plannerUtils={plannerUtils}
              actionPlanner={actionPlannerStore}
              dayTimespans={dayTimespans}
            />
          ) : null
        }

        <div className="planner-items-container">
          <PlannerFlowTimes
            timespanPieces={plannerFlow.timespanFlow}
            daysLength={plannerFlow.daysLength}
            plannerUtils={plannerUtils}
            showTimeLabels={showTimeLabels}
            timeLabelInterval={30}
            showTimespans={false}
          />
          <DayPlannerEventsFlow
            dragDirection={'vertical'}
            plannerFlow={plannerFlow}
            actionPlannerStore={actionPlannerStore}
            plannerUtils={plannerUtils}
            maxWidth={'100%'}
            onTaskOffset={0}
            widthOffset={20}
            stackOffset={20}
            leftOffset={30}
          />
          <DayPlannerDaySplitLabel
            plannerFlow={plannerFlow}
            plannerUtils={plannerUtils}
            dateFormat={actionPlannerStore.opts.dateFormat}
          />
          {
            plannerFlow.timespanFlow.map(timespanPiece => {
              const height = plannerUtils.getHeightFromDuration(timespanPiece.duration)
              const timespanStyle = {
                backgroundColor: timespanPiece.color,
                overflow: 'visible',
                height,
                position: 'relative'
              }
              const timespanIsResizing = isResizing && focusedTimespan === timespanPiece
              // compare same point on planner
              const timespanIsFocused = timespanPiece.startDuration === actionPlannerStore.focusedTimespan?.startDuration
              return (
                <Resizable
                  key={timespanPiece.uid}
                  data-id={timespanPiece.uid}
                  className={
                    "action-planner-items" 
                    + (timespanIsResizing ? ' resizing' : '') 
                    + (timespanIsFocused ? ' focused' : '')
                    + (timespanPiece.isTasksOverflow ? ' overflow' : '')
                  }
                  resizeHandleClassName={'timespan-resize-handle'}
                  Handle={props => <TimespanResizeHandle {...props} planner={actionPlannerStore} timespan={timespanPiece} />}
                  style={timespanStyle}
                  resize={'vertical'}
                  onResize={({ height }) => this.onResize(timespanPiece, { height })}
                  onResizeStart={({ height }) => this.onResizeStart(timespanPiece, { height })}
                  onResizeEnd={({ height }) => this.onResizeStop(timespanPiece, { height })}
                  onClick={() => this.onClickTimespan({ timespanPiece })}
                >
                  {
                    showTimespanTitle ? <TimespanTitle timespan={timespanPiece} /> : null
                  }
                  <ActionPlannerTimespan
                    plannerFlow={plannerFlow}
                    actionPlannerStore={actionPlannerStore}
                    plannerUtils={plannerUtils}
                    timespan={timespanPiece.timespanProps}
                    timespanPiece={timespanPiece}
                    isResizing={isResizing}
                    focusedTimespan={focusedTimespan}
                    onOrderChange={onOrderChange}
                    onTaskDurationChange={onTaskDurationChange}
                    onTaskDurationUpdate={onTaskDurationUpdate}
                    onTaskPress={onTaskPress}
                    onContextMenu={onContextMenu}
                  />
                </Resizable>
              )
            })
          }
        </div>

      </div>
    )
  }
}

export default observer(DayPlannerFlow)
