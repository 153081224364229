import React from 'react'

const SettingsGroup = ({ label, value }) => {
  return (
    <div className="settings-group">
      <label className="label">{label}</label>
      <div className="value">{value}</div>
    </div>
  )
}

export default SettingsGroup