import React from 'react'
import './Scrollable.scss'
import PropTypes from 'prop-types'
import ScrollButton from './ScrollButton';
import ScrollableJS from './ScrollableJS';

const debug = require('debug')('treks:theme:Scrollable')

export default class Scrollable extends React.Component {

  scrollable = null
  scrollableRef = null

  static propTypes = {
    onScroll: PropTypes.func,
    panesSelector: PropTypes.string,
    panes: PropTypes.oneOfType([
      PropTypes.instanceOf(HTMLCollection),
      PropTypes.arrayOf(PropTypes.instanceOf(HTMLElement))
    ]),
    scrollMaxTime: PropTypes.number
  }

  onScrollableRef = ref => {
    const { container, panes, panesSelector } = this.props
    debug('onScrollableRef', ref)
    this.scrollableRef = ref
    if (ref) {
      this.scrollable = new ScrollableJS(container || ref, panes || panesSelector)
    }
  }

  componentWillUnmount() {
    debug('componentWillUnmount', this.scrollable)
    if (this.scrollable) {
      this.scrollable.destroy()
      this.scrollable = null
    }
  }

  scrollUp = () => {
    this.scrollable.scrollToDirection(-1)
  }

  scrollDown = () => {
    this.scrollable.scrollToDirection(1)
  }

  render() {
    const { children, className } = this.props
    return (
      <div
        ref={this.onScrollableRef}
        className={'scrollable ' + (className ? className : '')}
      >
        <ScrollButton onClick={this.scrollUp} direction={'up'} />
        {children}
        <ScrollButton onClick={this.scrollDown} direction={'down'} />
      </div>
    )
  }
}
