import React, { ReactNode } from 'react'
import ScrollButton from './ScrollButton';
import './ScrollHorizontal.scss'

const debug = require('debug')('treks:ScrollHorizontal')

export default class ScrollHorizontal extends React.Component<{
  children: ReactNode[];
  className?: string;
  paneIndex?: number;
  onChange: (props: { ref: HTMLElement, nextIndex: number, nextPane: HTMLElement }) => void;
}> {

  scrollableRef = null
  panes = []

  onScrollableRef = (ref: HTMLElement) => {
    if (!ref) return
    this.scrollableRef = ref
    this.panes = Array.from(ref.querySelector('.scroll-panes').children)
  }

  scrollBack = () => {
    const { paneIndex = 0, onChange } = this.props
    const nextIndex = Math.max(0, paneIndex - 1)
    const nextPane = this.panes[nextIndex]
    console.log('onChange',{ nextIndex, nextPane })
    onChange({ ref: this.scrollableRef, nextIndex, nextPane })
  }

  scrollForward = () => {
    const { paneIndex = 0, onChange } = this.props
    const nextIndex = Math.min(this.panes.length - 1, paneIndex + 1)
    const nextPane = this.panes[nextIndex]
    console.log('onChange',{ nextIndex, nextPane })
    onChange({ ref: this.scrollableRef, nextIndex, nextPane })
  }

  render() {
    const { children, className, paneIndex = 0 } = this.props
    return (
      <div
        ref={this.onScrollableRef}
        className={'scroll-horizontal ' + (className ? className : '')}
      >
        <ScrollButton onClick={this.scrollBack} direction={'left'} />
        <ScrollButton onClick={this.scrollForward} direction={'right'} />

        <div className='scroll-box'>
          <div className='scroll-panes'>
            {
              children.map((child, index) => (
                <div key={index} className={'pane' + (paneIndex === index ? ' active' : '')}>
                  { child }
                </div>
              ))
            }
          </div>
        </div>
        
      </div>
    )
  }
}
