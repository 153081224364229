import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import { AccountLogin, AccountLoginComplete } from '../../Components/Account/Login'
import { Switch, Route } from 'react-router-dom'

const modalStore = ModalStore.fromProps({ show: true })

export default withRouter(observer(class AccountLoginModal extends React.Component {

  handleClose = () => {
    modalStore.setProps({ show: false })
  }

  render() {
    return (
      <Modal 
          show={true} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="settings-modal modal-size-80"
        >
          <Modal.Body>
            <Switch>
              <Route path="/account/login/complete" component={AccountLoginComplete} />
              <Route path="/" component={AccountLogin} />
            </Switch>
          </Modal.Body>
        </Modal>
    )
  }
}))