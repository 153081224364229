import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from 'theme/Icon'
import './GoalTasks.scss'
import SubTasks from 'Components/ui/SubTasks/SubTasks'
import GoalTasksSort from './GoalTasksSort'
import DoneFilter from 'theme/List/DoneFilter'

const debug = require('debug')('treks:goal:tasks')

class GoalTasks extends React.Component {

  store = observable({
    view: null,
    order: null,
  })

  onSetView = view => {
    this.store.view = view
  }

  onSetOrder = order => {
    this.store.order = order
  }

  render() {
    const { goal, list } = this.props
    const { view, order } = this.store
    debug('render', { goal, list, view, order })
    return (
      <div className="goal-tasks">
        <header className="row tab-header">
          <Icon icon="task" size={15} />
          <div className="tab-title">TASKS</div>
          <DoneFilter view={view} onChange={this.onSetView} />
          <GoalTasksSort onSetOrder={this.onSetOrder} />
        </header>
        <div className="container">
          <SubTasks
            item={goal}
            subtasks={goal.subTasksList}
            view={view}
            order={order}
          />
        </div>
      </div>
    )
  }
}

export default observer(GoalTasks)
