import { observable } from "mobx"

/**
 * An Entity is a dynamic Model with a fixed ID
 * The EntityManager ensures only one entity with an ID exists per EM
 */
export class Entity {

  /**
   * Counter for each update to sync latest update
   */
  static updateId = 0

  state = observable(new Map())

  constructor(props) {
    this.setState(props)
  }
  
  get id() {
    return this.get('id')
  }
  set id(id) {
    if (id === this.id) return // no-op
    if (this.id) {
      throw new TypeError('Entity id cannot be modified once set')
    }
    this.set('id', id)
  }

  has(key: string) {
    return this.state.has(key)
  }

  get(key: string) {
    return this.state.get(key)?.value
  }

  set(key: string, value: any) {
    return this.state.set(key, {
      uid: ++Entity.updateId,
      value,
    })
  }

  delete(key) {
    this.state.delete(key)
  }

  keys() {
    return this.state.keys()
  }

  setState(state) {
    if (state) {
      Object.entries(state).forEach(([key, value]) => {
        this.set(key, value)
      })
    }
  }

  getState() {
    const state = {}
    this.state.forEach(({ value }, key) => {
      state[key] = value
    })
    return state
  }

  clearState() {
    this.state.clear()
  }

  getMeta(key) {
    return this.state.get(key)
  }

}