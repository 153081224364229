import React from 'react'
import { observer } from 'mobx-react'
import Select from '../../../theme/Form/Select';
import { getAppNotifications } from '../../../AppNotifications';
import { action } from 'mobx';
import Calendar, { CalendarAccount } from 'Stores/Calendar';
import { TimeSpanListDaySettings } from 'Stores/ActionPlanner/TimeSpan/Settings';

const debug = require('debug')('treks:calendar:settings:row')

type CalendarRowProps = {
  calendar: Calendar;
  account: CalendarAccount;
  timeSpanList: TimeSpanListDaySettings;
  categoryPlaceholder?: string;
  actionPlannerPlaceholder?: string;
}

class CalendarRow extends React.Component<CalendarRowProps> {

  static defaultProps = {
    categoryPlaceholder: 'Select Category',
    actionPlannerPlaceholder: 'Not Used'
  }

  get categoryOptions() {
    const { items } = this.props.timeSpanList
    const options = items
      .filter(({ type }, index) => items.findIndex(item => item.type === type) === index) // unique types
      .filter(({ canHaveItems }) => canHaveItems) // timespans with tasks
      .map(({ title, id }) => ({ value: id, label: title }))
    debug('get categoryOptions', options)
    return options
  }

  get actionPlannerOptions() {
    return [
      { value: 'automatic', label: 'Automatic' },
      { value: 'manual', label: 'Manual' }
    ]
  }

  componentDidMount() {
    const { timeSpanList } = this.props
    debug('mount', { timeSpanList })
    timeSpanList
      .fetch()
      .catch(() => getAppNotifications().error('Unable to get categories'))
  }

  @action onChangeCategory = option => {
    const { calendar } = this.props
    debug('onChangeCategory', { option })
    calendar.category = option && option.value
    calendar.save()
  }

  @action onChangeOnActionPlanner = option => {
    const { calendar } = this.props
    debug('onChangeActionPlanner', { option })
    calendar.actionPlanner = option && option.value
    calendar.save()
  }

  render() {
    const { calendar, categoryPlaceholder, actionPlannerPlaceholder } = this.props
    const { categoryOptions, actionPlannerOptions } = this
    const { title, category, actionPlanner } = calendar
    debug('render', { calendar, categoryOptions })
    return (
      <tr className="calendar-account-row">
        <td>{title}</td>
        <td>
          <Select 
            isClearable
            placeholder={categoryPlaceholder}
            onChange={this.onChangeCategory}
            options={categoryOptions} 
            value={categoryOptions.find(({ value }) => value === category)}
          />
        </td>
        <td>
          <Select 
            isClearable
            placeholder={actionPlannerPlaceholder}
            onChange={this.onChangeOnActionPlanner}
            options={actionPlannerOptions} 
            value={actionPlannerOptions.find(({ value }) => value === actionPlanner)}
          />
        </td>
      </tr>
    )
  }
}

export default observer(CalendarRow)