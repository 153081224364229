import { getStorable } from "services/store/StoreService"
import List from "Stores/Lists"
import Model from "Stores/Model"
import { BaseType, ListType, ModelType, PropsResolver, RelationRef, RelationshipConfig } from "./__types__"
import { RelationshipUtils as utils } from "./RelationshipUtils"
import { observable, observe } from "mobx"
import { getRelationMap } from "./RelationshipInitializer"

const debug = require('debug')('treks:rel:deco')

const h = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(h)
  } else if (obj instanceof Model) {
    return obj.title || obj.name
  } else {
    try {
      return Object.entries(obj).reduce((prev, [key, value]) => {
        return { ...prev, [key]: (value instanceof Model ? h(value) : value) }
      }, {})
    } catch(e) {
      return obj
    }
  }
}

/**
 * Decorates Models with relationships.
 * @example `RelationshipDecorators.hasOne(() => User, user => user.parent)`
 * @link src/SubModules/treks-ui-models/docs/Relationships.md
 * 
 * Decoraters are not initialized until `initlializeRelationships()` is called
 * @see ../initialize.ts
 */
export class RelationshipDecorators {

  static relationType = {
    one: Model,
    many: List
  }

  static config: RelationshipConfig = {
    ensureReferentialIntegrity: false
  }

  static setConfig(config: RelationshipConfig) {
    this.config = { ...this.config, ...config }
  }

  static isHasOne(RelType: BaseType): boolean {
    return RelType === this.relationType.one
  }

  static isHasMany(RelType: BaseType): boolean {
    return RelType === this.relationType.many
  }

  static _createItemsGetter(refList: List, thisModel: Model, ThisType: typeof Model, RefItemType: typeof Model, key: string, refKey: string) {

    const self = this
    const hasGetterKey = '__hasItemsGetter__' as keyof List
    const hasGetter = refList.hasAttribute(hasGetterKey)
    if (hasGetter) {
      return
    } else {
      refList.setAttribute(hasGetterKey, true)
    }

    const items = observable([])

    observe(items, (change) => {
      // console.group('items change')
      if (change['removedCount']) {
        debug('>>> items removed ', h({ thisModel, refKey, key }),  h(change['removed']))
        change['removed'].forEach((item) => {
          if (item[refKey]) {
            if (item[refKey] instanceof List) {
              if (item[refKey].hasItem(thisModel)) {
                item[refKey].removeItem(thisModel)
                debug('>>> inv:list item remove', h({ item, refKey, thisModel }),  h(item.items))
              } else {
                debug('>>> inv:list item not exist ', h({ item, refKey, thisModel }),  h(item.items))
              }
            } else {
              if (item[refKey]?.uid === thisModel.uid) {
                debug('>>> inv:model set refKey null', h({ item, refKey, thisModel }))
                item.setProp(refKey, null)
              } else {
                debug('inv:model set null <end>')
              }
            }
          }
          // @todo fix List.addItems, updateItems, addItemsFromJSON will remove and re-add. 
          // @note this causes ref to be null for one iteration triggering react components update to wrong values
        })
      }
      // add must be after remove
      if (change['addedCount']) {
        debug('>>> items added ', h({ thisModel, refKey, key }),  h(change['added']))
        change['added'].forEach(item => {
          if (item[refKey]) {
            if (item[refKey] instanceof List) {
              if (!item[refKey].hasItem(thisModel)) {
                debug('>>> inv:list item add', h({ item, refKey, thisModel }),  h(item.items))
                item[refKey].addItem(thisModel)
              } else {
                debug('>>> inv:list item exist', h({ item, refKey, thisModel }),  h(item.items))
              }
            } else {
              if (item[refKey]?.uid !== thisModel.uid) {
                debug('>>> inv:model set refKey thisModel', h({ item, refKey, thisModel }))
                item.setProp(refKey, thisModel) 
              } else {
                debug('inv:model set thisModel <end>')
              }
            }
          }
        })
      }

      // console.groupEnd()

      return change
    })

    Object.defineProperty(refList, '_items', {
      configurable: true,
      get() {
        debug('get items', `${thisModel.id}<${ThisType.name}>.${key}`, { refKey, RefItemType })
        return  items
      },
      set() {
        throw new Error('Overwritting List._items is not allowed')
      }
    })
  }

  static getRelation(model: Model, key: string) {
    const relations = getRelationMap(model)
    return relations.get(key)
  }

  static getInverseRelation(model: Model, key: keyof Model, refModel?: Model) {
    if (!refModel) refModel = model.getAttribute(key)
    const relations = getRelationMap(refModel)
    const relation = Array.from(relations.values()).find(rel => {
      // RelType, RefTypeFn, ref, target, key, descriptor
      const relRefKey = rel.ref && utils._getRefKey(rel.ref)
      return relRefKey && relRefKey === key
    })
    return relation
  }

  static warnedInvRel = []
  static warnedRefRel = []
  static warnedSetLoop = []

  static _createDescriptor(RelType: BaseType, RefTypeFn: ModelType, ref: RelationRef, target: Model, key: string & keyof Model, descriptor: any = {}) {
    const self = this
    const { configurable, enumerable } = descriptor;
    return  {
      configurable, 
      enumerable,
      get() {
        // access property directly on the prototype
        if (this === target) {
          return;
        }

        const thisModel = this as Model
        const ThisType = target.constructor as typeof Model
        const RefType = utils._normalizeModelType(RefTypeFn, RelType)
        const refKey = ref ? utils._getRefKey(ref) : ''

        let refModel = thisModel.getAttribute(key)
        if (refModel) return refModel
        
        refModel = RefType.create()

        // console.group('Get model')
        debug('get relation model', `${thisModel.id}<${ThisType.name}>.${key}`, this.uid, { ThisType, key, RefType, refKey })

        if (self.isHasMany(RelType)) {
          const RefItemType = (refModel as List).ModelType as typeof Model

          const refRel = self.getRelation(refModel, refKey)
          const invRel = self.getInverseRelation(thisModel, key, refModel)

          debug('is hasMany', { refRel, invRel })

          if (refRel !== invRel) {
            if (!self.warnedRefRel.includes(refRel)) {
              console.warn('Inverse relation not same as reference relation', { refRel, invRel, thisModel, key, refModel })
              self.warnedRefRel.push(refRel)
            }
          }

          if (invRel) {
            if (self.isHasOne(invRel.RelType)) {
              debug('set reflect', { refModel, key: invRel.key, thisModel, invRel })
              refModel.setAttribute(invRel.key, thisModel)
            }
          } else {
            const rel = self.getRelation(thisModel, key)
            if (!self.warnedInvRel.includes(rel)) {
              console.warn('No inverse relation for', { rel, thisModel, key, refModel })
              self.warnedInvRel.push(rel)
            }
          }

          if (!(refModel instanceof List)) {
            throw new Error(`A many relation must use a List as ModelType. 
              eg: hasMany(List, (item: Item) => item.refKey)`)
          }

          if (refKey) {
            refModel.setAttribute(refKey, thisModel) // lists always point back to parent with prop refKey
            self._createItemsGetter(refModel, thisModel, ThisType, RefItemType, key, refKey)
          }
        }

        // console.groupEnd()
        
        thisModel.setAttribute(key, refModel)
        return refModel
      },
      set(nextRefModel: Model) {
        // access property directly on the prototype
        if (this === target) {
          return;
        }

        const thisModel = this as Model
        const ThisType = target.constructor as typeof Model
        const RefType = utils._normalizeModelType(RefTypeFn, RelType)
        const refKey = ref ? utils._getRefKey(ref) : ''

        // console.group('set model')
        debug('set relation model', `${thisModel.id}<${ThisType.name}>.${key}`, { nextRefModel })

        const refModel = thisModel.getAttribute(key)
        thisModel.setAttribute(key, nextRefModel)

        // same model no-op
        if (Model.isSameModel(nextRefModel, refModel)) {
          const loopKey = thisModel.modelName + ':' + key
          if (!self.warnedSetLoop.includes(loopKey)) {
            console.warn('Loop detected', { parent: thisModel, key, nextRefModel })
            self.warnedSetLoop.push(loopKey)
          }
          debug('setting same model <end>', h({ thisModel, key, refModel, nextRefModel }))
        } else if (refKey) {
          // console.group('set model inv')
          debug('set', h({ thisModel, key, refModel, nextRefModel, refKey }))

          // fix use relations

          if (refModel && refModel[refKey] instanceof List) {
            if (refModel[refKey].hasItem(thisModel)) {
              debug('set inv:list remove', h({ refModel, refKey, thisModel }), h(refModel[refKey].items))
              refModel[refKey].removeItem(thisModel)
              debug('set inv:list removed', h({ refModel, refKey, thisModel }), h(refModel[refKey].items))
            } else {
              debug('set inv:list not exist <end>', h({ refModel, refKey, thisModel }), h(refModel[refKey].items))
            }
             
          }
          else if (refModel && refModel[refKey] instanceof Model) {
            debug('set inv:model set', h({ refModel, refKey, thisModel }))
            refModel?.setAttribute(refKey, thisModel)
          }

          if (nextRefModel && nextRefModel[refKey] instanceof List) {
            if (!nextRefModel[refKey].hasItem(thisModel)) {
              debug('set inv:list add', h({ nextRefModel, refKey, thisModel }), h(nextRefModel[refKey].items))
              nextRefModel[refKey].addItem(thisModel)
              debug('set inv:list added', h({ nextRefModel, refKey, thisModel }), h(nextRefModel[refKey].items))
            } else {
              debug('set inv:list item not exists <end>', h({ nextRefModel, refKey, thisModel }), h(nextRefModel[refKey].items))
            }
          } 
          else if (nextRefModel && nextRefModel[refKey] instanceof Model) {
            debug('set inv:model set', h({ nextRefModel, refKey, thisModel }))
            nextRefModel?.setAttribute(refKey, thisModel)
          }

          // console.groupEnd()
        }

        // console.groupEnd()

      }
    }
  }

  static _createRelationship(RelType: BaseType, RefTypeFn: ModelType|ListType, ref?: RelationRef, propsFn?: PropsResolver, config?: RelationshipConfig): Function {
    /**
     * create relationship descriptor
     * @note target is the Model instance prototype
     * @note the Model instance is `this` in the get(), set() descriptors
     * @note ie: (Object.getPrototypeOf(this) === target)  is true
     */
    return (target: Model, key: string & keyof Model, desc = { configurable: true,  enumerable: true }) => {

      target.addPropKey(key)

      const descriptor = this._createDescriptor(RelType, RefTypeFn, ref, target, key, desc)
      utils.noteRelation(target, key, { RelType, RefTypeFn, ref, target, key, descriptor })
    }
  }

  static hasOne(ModelType: ModelType, ref?: RelationRef, props?: PropsResolver, config?: RelationshipConfig): Function {
    return this._createRelationship(Model, ModelType, ref, props, config)
  }
  
  static hasMany(ListType: ListType, ref?: RelationRef, props?: PropsResolver, config?: RelationshipConfig): Function {
    return this._createRelationship(List, ListType, ref, props, config)
  }

  static hasGlobal(ModelType: ModelType, propsFn?: PropsResolver): Function {
    const state = { init: false }
    return (target: Model, key: string, desc = {}) => {
      
      target.addPropKey(key)

      const descriptor = {
        configurable: true, 
        enumerable: true,
        ...desc,
        get() {
          if (this === target) {
            return; // access property directly on the prototype
          }
          ModelType = utils._normalizeModelType(ModelType, Model)
          const model = getStorable(ModelType as typeof Model).getModel()
          if (!state.init) {
            if (propsFn) {
              const props = utils._normalizeProps(propsFn, this)
              model.setProps(props)
            }
            state.init = true
          }
          return model
        },
        set(nextModel: Model) {
          if (this === target) {
            return; // access property directly on the prototype
          }
          ModelType = utils._normalizeModelType(ModelType, Model)
          getStorable(ModelType as typeof Model).setModel(nextModel)
        }
      }
      utils.noteRelation(target, key, { descriptor })
    }
  }
}

let { hasOne, hasMany, hasGlobal } = RelationshipDecorators
hasOne = hasOne.bind(RelationshipDecorators)
hasMany = hasMany.bind(RelationshipDecorators)
hasGlobal = hasGlobal.bind(RelationshipDecorators)
export {
  hasOne,
  hasMany,
  hasGlobal
}

global['RelationshipDecorators'] = RelationshipDecorators