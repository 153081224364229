import React from 'react'
import { observer } from 'mobx-react'
import '../../ListViewTasks/ListViewTasks.scss'
import './ListViewTasksContainer.scss'
import ListViewTaskCategoryList from '../../ListViewTasks/ListViewTaskCategoryList'
import ListViewFilter from '../ListViewFilter'
import Pressable from 'theme/Pressable'
import { computed, makeObservable, observable } from 'mobx'
import ListViewSearch from '../ListViewSearch'
import ListViewSort from '../ListViewSort'
import ListViewFields from '../ListViewFields'
import { ListViewHeaders } from '../ListViewHeader/ListViewHeaders'
import { ListViewFilterRows } from '../ListViewFilter/ListViewFilterRows'
import LocalJSONStorage from 'services/storage/LocalJSONStorage'
import ListViewFlatList from '../ListViewFlatList/ListViewFlatList'
import stores, { getStore, models } from 'Stores'
import Sortable from 'Modules/ReactSortable/ReactSortable'
import { ListViewList } from './models/ListViewList'
import ListViewContextMenu from '../ContextMenu/ListViewContextMenu'
import { getListViewUiStore } from './models/ListViewUiModel'

const debug = require('debug')('treks:list:task:container')

const labelGroupList = getStore(stores.LabelGroupList)
const projectList = getStore(stores.ProjectList)
const goalList = getStore(stores.GoalList)

const taskList = getStore(stores.TaskList)
const taskListOrphaned =  getStore(stores.TaskListOrphaned)
const parentLists = ListViewList.create()
const noParentCategory = getStore(stores.CategoryItem, { uid: 'no-category-parent', title: 'Stray Tasks', taskList: taskListOrphaned })
const noParentCategoryList = getStore(stores.CategoryList, { uid: 'no-project-category-list', items: [noParentCategory]})
const noProject = getStore(stores.ProjectItem, { uid: 'no-project', title: 'Stray Tasks', color: '#eee', categoryList: noParentCategoryList })

const uiStore = getListViewUiStore()


class ListViewTasksContainer extends React.Component {

  static defaultProps = {
    taskList,
    taskListOrphaned,
    parentLists,
    noParentCategory,
    noParentCategoryList,
    noProject,
    
    headers: [ // order matters
      {
        name: 'parent',
        label: 'PROJECT/GOAL:Category',
        required: true
      },
      {
        name: 'item_name',
        label: 'Task Name',
        required: true
      },
      {
        name: 'due_date',
        label: 'Due Date'
      },
      {
        name: 'assigned_user',
        label: 'Assigned User'
      },
      {
        name: 'duration',
        label: 'Duration'
      }
    ],
    storage: new LocalJSONStorage({ namespace: 'list-view'}),
    ListViewFlatList,
    ListViewTaskCategoryList,
    allowFlatList: true,
    className: ''
  }

  constructor(...props) {
    super(...props)
    makeObservable(this)
  }

  componentDidMount() {
    const { taskList, parentLists } = this.props
    taskList.fetchNextPage()
    parentLists.setItems([labelGroupList, projectList, goalList ])
    parentLists.items.forEach(parent => {
      parent.fetchOrder()
      parent.fetch()
    })
  }

  @observable filter = {}

  @observable fields = ['parent', 'item_name', 'due_date', 'assigned_user', 'overdue_tasks', 'duration']

  @computed get isFlatList() {
    return this.filter.sort?.value?.withinParent === false
  }

  @computed get isFlatCategory() {
    return this.filter.sort?.value?.withinCategory === false
  }

  @observable filterVisible =  this.getFilterVisible()

  getFilterVisible() {
    return this.props.storage.getItem('filterVisible')
  }

  saveFilterVisible(filterVisible) {
    return this.props.storage.setItem('filterVisible', filterVisible)
  }

  @computed get filterLabel() {
    if (!this.filter) return 'All tasks'
    return Object.keys(this.filter)
      .map(name => {
        if (name === 'sort') return null
        let filterLabel = ''
        const filter = this.filter[name]
        const nameLabel = filter.label || name
        if (filter &&  filter.value) {
          if (Array.isArray(filter.value)) {
            const labels = filter.value.map(({ label }) => label)
            filterLabel = nameLabel + ': ' + labels.join(', ')
          } else if (filter.value && filter.value.label) {
            const { label } = filter.value
            filterLabel = nameLabel + ': ' + label
          } else {
            filterLabel = nameLabel + ': ' + filter.value
          }
        }
        return filterLabel
      })
      .filter(label => label)
      .join(', ')
  }

  @computed get allParents() {
    return this.props.parentLists.items
      .map(list => list.visibleItems)
      .flat()
  }

  @computed get allTasksFlat() {
    return this.props.taskList.visibleIncompleteItems
  }

  findFilterRow(name) {
    return ListViewFilterRows.find(row => row.name === name)
  }

  validateFilter = ({ name, ...filter }) => {
    if (!name) throw new Error('Filter must have a name')
    const row = this.findFilterRow(name)
    if (!row) throw new Error('Filter name does not match any filters')
    if (filter.value) {
      if (row.isMulti) {
        if (!Array.isArray(filter.value)) {
          throw new Error('Filter multi value must be Array<{ value, label }>')
        }
      } else {
        if (!('value' in filter.value) || !('label' in filter.value)) {
          throw new Error('Filter value be of type { value, label }')
        }
      }
    }
  }

  onFilter = ({ name, ...filter }) => {
    this.filter[name] = filter
    if (process.env.NODE_ENV === 'development') {
      this.validateFilter({ name, ...filter })
    }
  }

  onFilterClear = () => {
    const { sort } = this.filter
    this.filter = { sort }
  }

  toggleFilterView = () => {
    this.filterVisible = !this.filterVisible
    this.saveFilterVisible(this.filterVisible)
  }

  onSearch = search => {
    this.filter.search = search
  }

  onSortListView = sort => {
    this.filter.sort = sort
  }

  onChangeFields = fields => {
    this.fields = fields
  }

  renderFlatListView() {
    const { taskList, order } = this.props
    const { allTasksFlat,  filter, fields } = this
    return (
      <ListViewFlatList
        taskList={taskList}
        tasks={allTasksFlat}
        filter={filter}
        fields={[ ...fields, 'category']}
        order={order}
      />
    )
  }

  onPut = (sortableTo, sortableFrom) => {
    const { allParents } = this
    debug('onPut', { sortableTo, sortableFrom })
    const parentToUid = sortableTo.el.getAttribute('data-parent-uid')
    const parentTo = allParents.find(parent => parent.uid === parentToUid)
    const parentFromUid = sortableFrom.el.getAttribute('data-parent-uid')
    const parentFrom = allParents.find(parent => parent.uid === parentFromUid)
    debug('parentTo', { parentToUid, parentTo, parentFrom, allParents })
    return parentFrom.constructor === parentTo.constructor
  }

  onSort = (uids, sortable, ...args) => {
    const parentListUid = sortable.el.getAttribute('data-id')
    const parentList = this.props.parentLists.items.find(list => list.uid === parentListUid)
    debug('onSort', { uids, parentListUid, parentList }, args)
    parentList.setOrder(uids)
    parentList.saveOrder()
  }

  renderHierarchicalView() {
    const { filter, fields, isFlatCategory } = this
    const { ListViewTaskCategoryList, order } = this.props
    return (
      <>
        {
          this.props.parentLists.items.map(parentList => {
            const parentType = 
                parentList instanceof stores.LabelGroupList ? 'label-group-list'
              : parentList instanceof stores.GoalList ? 'goal-list' 
              : parentList instanceof stores.ProjectList ? 'project-list' : ''
            const groupName = 'group-' + parentType
            const isLabelGroup = parentList instanceof stores.LabelGroupList
            return (
              <div className='list-view-parent-list' key={parentList.uid}>
                <Sortable
                  data-id={parentList.uid}
                  data-group={groupName}
                  className={'parent-list-sort'}
                  options={{
                    animation: 100,
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    group: {
                      name: groupName,
                      put: [groupName]
                    }
                  }}
                  onChange={this.onSort}
                >
                  {
                    parentList.visibleItems.map(parent => (
                      <div key={parent.uid} data-id={parent.uid} className='list-view-parent'>
                        <ListViewTaskCategoryList
                          flat={isFlatCategory}
                          item={parent}
                          categoryList={parent.categoryList}
                          filter={filter}
                          fields={fields}
                          order={order}
                          showUncategorized={isLabelGroup ? false : true}
                        />
                      </div>
                    ))
                  }
                </Sortable>
              </div>
            )
          })
        }
        {
          this.renderOrphanedTasks()
        }
      </>
    )
  }

  renderOrphanedTasks() {
    const { filter, fields, isFlatCategory } = this
    const { order } = this.props
    return (
      <div>
        <ListViewTaskCategoryList
          icon={'✔'}
          showCategoryHeader={false}
          showFooter={false}
          flat={isFlatCategory}
          item={noProject}
          categoryList={noProject.categoryList}
          filter={filter}
          fields={fields}
          order={order}
          showUncategorized={false}
          noCategory={true}
          noProject={true}
        />
      </div>
    )
  }

  hideContextMenu = () => {
    const { contextMenuState } = uiStore
    Object.assign(contextMenuState, {
      show: false,
      selectedTasks: []
    })
    const paneEl = this.containerEl.closest('.main-pane-component')
    paneEl.removeEventListener('scroll', this.hideContextMenu)
  }

  renderContextMenu() {
    const { show, top, left, menuItems, selectedTasks } = uiStore.contextMenuState
    return (
      <ListViewContextMenu
        selectedTasks={selectedTasks}
        top={top}
        left={left}
        show={show}
        menuItems={menuItems}
        onHide={this.hideContextMenu}
      />
    )
  }

  onTaskContextMenu = ({ event, taskEl, task }) => {
    event.preventDefault()
    const { contextMenuState } = uiStore

    const paneEl = this.containerEl.closest('.main-pane-component')
    paneEl.addEventListener('scroll', this.hideContextMenu)

    const taskTop = taskEl.getBoundingClientRect().top
    const containerTop = this.containerEl.getBoundingClientRect().top
    const scrollTop = paneEl.scrollTop

    const offsetTop =  taskTop - scrollTop - containerTop

    Object.assign(contextMenuState, {
      show: true,
      top: offsetTop + 10
    })

    console.log('onTaskContextMenu', {
      task, event, offsetTop, taskTop, containerTop, scrollTop, contextMenuState 
    })
  }

  onContextMenu = (event) => {
    const taskEl = event.target.closest('.task-container')
    if (taskEl) {
      const taskUid = taskEl.getAttribute('data-id')
      const task = models.TaskItem.findByUid(taskUid)
      if (task) {
        const { selectedTasks } = uiStore.contextMenuState
        const hasTask = selectedTasks.find(({ uid }) => task.uid)
        if (!hasTask) {
          selectedTasks.push(task)
        }
        debug('onContextMenu', { event, taskEl, taskUid, task })
        this.onTaskContextMenu({ task, event, taskEl })
      } else {
        console.error('Could not find task', { taskUid, taskEl })
      }
    }
  }

  onClick = (event) => {
    const taskEl = event.target.closest('.task-container')
    if (taskEl) {
      const taskUid = taskEl.getAttribute('data-id')
      const task = models.TaskItem.findByUid(taskUid)
      if (task && task instanceof models.TaskItem) {
        event.preventDefault()
        if (event.ctrlKey || event.metaKey) {
          this.onSelectWithCtrl(task)
        } else if (event.shiftKey) {
          this.onSelectWithShift(task)
        } else {
          this.onSelectNoKeypress(task)
        }
      } else {
        console.error('could not find task', { taskUid, taskEl, task })
      }
    }
  }

  onSelectNoKeypress = (task) => {
    const { selectedTasks } = uiStore.contextMenuState
    selectedTasks.splice(0, selectedTasks.length, task)
    console.log('onClick noKeyPress', { task, selectedTasks })
  }

  onSelectWithCtrl = (task) => {
    const { selectedTasks } = uiStore.contextMenuState
    const selectIndex = selectedTasks.findIndex(item => item.uid === task.uid)
    if (selectIndex > -1) {
      selectedTasks.splice(selectIndex, 1)
    } else {
      selectedTasks.push(task)
    }
    console.log('onClick meta/ctrl', { task, selectedTasks })
  }

  onSelectWithShift = (task) => {
    const { items } = uiStore.selectedTaskListState
    const { selectedTasks } = uiStore.contextMenuState
    const selectedTasksUids = selectedTasks.map(item => item.uid)
    const itemsUids = items.map(item => item.uid)

    // no tasks selected in current list
    if (selectedTasks.length === 0 || !selectedTasksUids.find(uid => itemsUids.includes(uid))) {
      selectedTasks.push(task)
      return
    }

    // selects all tasks between taskIndex and any existing selected tasks in current list
    const taskIndex = items.findIndex(item => item.uid === task.uid)
    if (taskIndex > -1) {
      const upUid = itemsUids
        .slice(0, taskIndex)
        .reverse()
        .find(uid => selectedTasksUids.includes(uid))
      const downUid = itemsUids
        .slice(taskIndex)
        .find(uid => selectedTasksUids.includes(uid))
      const upIndex = itemsUids.indexOf(upUid)
      const downIndex = itemsUids.indexOf(downUid)
      if (upIndex > -1) {
        const upSelection = items.slice(upIndex, taskIndex+1)
        selectedTasks.push(...upSelection)
      }
      if (downIndex > -1) {
        const downSelection = items.slice(taskIndex, downIndex)
        selectedTasks.push(...downSelection)
      }
    }

    console.log('onClick shift', { task, selectedTasks })
  }

  onRef = ref => this.containerEl = ref

  render() {
    const { order, headers, className, allowFlatList } = this.props
    const { isFlatList, allParents, allTasksFlat, filter, fields } = this
    const { filterLabel } = this
    const visibleHeaders = (allowFlatList && isFlatList) ? headers : headers.slice(1)
    debug('render', { allParents, filter, order, isFlatList, allTasksFlat, fields  })
    return (
      <div
        ref={this.onRef}
        className={"container list-view-tasks-container" + (className ? ' ' + className : '')}
        onContextMenu={this.onContextMenu}
        onClick={this.onClick}
      >
        {
          this.renderContextMenu()
        }
        <nav className="list-view-filters">
          <div className="filter-item filter-search">
            <ListViewSearch onSearch={this.onSearch} /> 
          </div>
          <Pressable
            onClick={this.toggleFilterView}
            className="filter-item filter-label text-overflow-ellipses">
            Filter: {filterLabel}
          </Pressable>
          <div className="filter-item">
            <ListViewSort
              onSort={this.onSortListView}
            />
          </div>
          <div className="filter-item">
            <ListViewFields
              onChange={this.onChangeFields}
            />
          </div>
        </nav>
        { 
          this.filterVisible && (
            <ListViewFilter
              filter={this.filter}
              onFilter={this.onFilter}
              onClear={this.onFilterClear}
              onClose={this.toggleFilterView}
            />
          )
        }
        <ListViewHeaders
          headers={visibleHeaders}
          fields={fields}
        />
        {
          (isFlatList && allowFlatList) ? (
            this.renderFlatListView()
          ) : (
            this.renderHierarchicalView()
          )
        }
      </div>
    )
  }
  
}

export default observer(ListViewTasksContainer)