import React from 'react'
import { darkenColor } from 'lib/utils';
import Tooltip from 'theme/Tooltip'
import moment from 'moment';
import { observer } from 'mobx-react';

export default observer(class TimespanResizeHandle extends React.Component {
  render() {
    const { planner, timespan, ...handleProps } = this.props
    return (
      <div {...handleProps} style={{
        zIndex: 10,
        borderColor: darkenColor(timespan.color, 10) 
      }}>
      {
        planner.resizingTask ? null : (
          <Tooltip placement="top">
            {
              moment(timespan.endDate).format('h:mm A')
            }
          </Tooltip>
        )
      }
      </div>
    )
  }
})