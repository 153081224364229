import { observable, action, override } from 'mobx'
import List from '../Lists/List'
import FileItem from './FileItem'
import { hasOne } from 'Relationships/RelationshipDecorators';
import { ProjectItem } from 'Stores/Project';
import { GoalItem } from 'Stores/Goal';
import { models } from 'Stores/Stores';

const debug = require('debug')('treks:store:file:list')

export class FileList extends List {

  get ModelType() {
    return FileItem
  }

  /**
   * @property {TaskItem} Task file is attached to
   */
	@hasOne(() => models.TaskItem, (task: models.TaskItem) => task.fileList)
	task: models.TaskItem
  /**
   * @property {ProjectItem} Project file is attached to
   */
	@hasOne(() => ProjectItem, (project: ProjectItem) => project.fileList)
	project: ProjectItem

  /**
   * @property {CommentItem} Comment file is attached to
   */
  @observable comment = null

  /**
   * @property {GoalItem} Goal file is attached to
   */

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.fileList)
  goal: GoalItem

  @action setTask(task) {
    this.task = task
  }

  @action setProject(project) {
    this.project = project
  }

  @action setComment(comment) {
    this.comment = comment
  }

  @action addItemsFromFiles(files: File[]) {
    const items = files.map(file => this.createItem({ file }))
    this.addItems(items)
    console.log('addItemsFromFiles', { files, items })
  }

  @action setItemsFromFiles(files: File[]) {
    const items = files.map(file => this.createItem({ file }))
    this.setItems(items)
    console.log('setItemsFromFiles', { files, items })
  }

  createItemProps(file) {
    const { project, task, comment } = this
    return { file, task, project, comment }
  }

  @action async createItemFromJSON(item = {}) {
    const file = FileItem.create()
    const { project, task, comment } = this
    file.setProps({ project, task, comment })
    await file.fromJSON(item)
    return file
  }

  async toJSON() {
    const items = await Promise.all(this.items.map(item => item.toJSON()))
    return {
      items
    }
  }

  @override async fromJSON(data = { items: [] }) {
    try {
      const items = data.items ? await Promise.all(data.items.map(async item => {
        const file = this.createItemFromJSON(item)
        return file
      })) : []
      this.setItems(items)
      debug('fromJSON', { data, self: this })
    } catch(error) {
      debug('fromJSON error', error)
      throw error
    }
    return this
  }

  async save() {
    const save = this.items.map(item => item.save())
    return Promise.all(save)
  }

  @override fetch() {
    return this.fetchState.get('file/list', {
      taskId: this.task && this.task.id,
      projectId: this.project && this.project.id,
      commentId: this.comment && this.comment.id
    })
      .then(({ data }) => {
        if (data) {
          this.fromJSON(data)
        }
      })
  }

}

export default FileList
