import { observable, computed, override } from 'mobx';
import SubTaskList from './SubTaskList'
import OrderedList from 'Stores/Lists/OrderedList'
import moment from 'moment'
import { hasOne } from 'Relationships/RelationshipDecorators';
import TaskItem from '..';
import { GoalItem } from 'Stores/Goal';
import { DateModel } from 'Stores/Model/DateModel';
import stores, { getStore } from 'Stores/Stores';

// const debug = require('debug')('treks:store:SubTaskListList')

/**
 * List of SubTask Lists
 */
export default class SubTaskListList extends OrderedList {

  get ModelType() {
    return SubTaskList()
  }

  /**
   * @property {Date} Start of Today (Different from focused day)
   */
  @computed get startOfDay(): Date {
    return new Date(new Date(DateModel.currentDate).setHours(0, 0, 0, 0))
  }

  /**
   * @property {Number} Minutes passed from 12am today
   */
  @computed get minsSinceStartOfDay(): number {
    return moment().diff(new Date(this.startOfDay), 'minutes')
  }

  // @todo relationship not working
	//@hasOne(() => stores.TaskItem, (task: TaskItem) => task.subTasksList)
	@observable task: TaskItem = null
  
  @observable goal: GoalItem = null

  @override get items(): TaskItem[] {
    return super.items
  }

  @override get visibleItems(): TaskItem[] {
    return this.items
      .filter(({ trashed }) => !trashed)
  }

  @computed get visibleTasks(): TaskItem[] {
    return this.visibleItems
      .map(({ visibleItems }) => visibleItems)
      .flat()
      .filter(task => task) // @fix null tasks
  }

  @computed get duration(): number {
    return this.visibleItems
      .map(({ duration }) => duration)
      .reduce((total, duration) => total + duration, 0)
  }

  @computed get durationDone(): number {
    return this.items
      .map(({ durationDone }) => durationDone)
      .reduce((total, duration) => total + duration, 0)
  }

  @computed get durationNotDone(): number {
    return this.duration - this.durationDone
  }

  @computed get durationShouldBeDone(): number {
    if (!this.task || !this.task.startTimespan || !this.task.onPlanner) return 0
    return Math.min(this.minsSinceStartOfDay, this.task.endDuration) - this.task.startDuration
  }

  findFirstEmptyList(props = {}) {
    return this.items.find(item => !item.title && JSON.stringify({ ...item }) === JSON.stringify({ ...item, ...props }))
  }

  /**
   * @note Do not include props in JSON. (Infinite recursion)
   */
  async toJSON() {
    return this.asyncToJSON()
  }

  async asyncToJSON() {
    const items = await Promise.all(this.items.map(item => item.asyncToJSON()))
    return { items }
  }

  async save() {
    // @todo remove delegation to parent when subtask api completed
    return this.task?.saveThrottled()
  }

}