import React from 'react'
import Icon from 'theme/Icon'
import PlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils'
import Pressable from '../../../theme/Pressable'
import { observer } from 'mobx-react'
import stores, { getStore } from 'Stores'
import { EventDetailPane } from 'Stores/MainPaneGroup/data/MainPaneItems'

const plannerUtils = new PlannerUtils()

const debug = require('debug')('treks:planner:DueTodayItem')

class DueTodayItem extends React.Component {

  static defaultProps = {
    get actionPlannerStore() {
      return getStore(stores.ActionPlanner)
    },
    get mainPaneGroupStore() {
      return getStore(stores.MainPaneGroup)
    },
    get formatDuration() {
      return duration => plannerUtils.formatDuration(duration)
    }
  }

  onClick = () => {
    const { item, onClickTask, onClickEvent } = this.props
    if (item.modelName === 'TaskItem') {
      (onClickTask || this.onClickTask)(item)
    }
    if (item.modelName === 'CalendarEvent') {
      (onClickEvent || this.onClickEvent)(item)
    }
    item.onPlanner = true
  }

  onClickTask = (task) => {
    const { list } = task
    const { item } = list.focusedItem
    const focusedItemIndex = item ? list.getItemIndex(item) : 0
    const moveToIndex = focusedItemIndex ? focusedItemIndex + 1 : 0
    list.moveItem(task, moveToIndex)
    debug('moveItem', { task, moveToIndex, focusedItemIndex })
  }

  onClickEvent = (event) => {
    const { actionPlannerStore, mainPaneGroupStore } = this.props
    actionPlannerStore.setFocusedEvent(event)
    mainPaneGroupStore.addOrUpdateItem(EventDetailPane, { event })
  }

  render() {
    const { uid, title, duration, children } = this.props.item
    const durationFormatted = this.props.formatDuration(duration)
    return (
      <div
        className="due-today-item"
        data-id={uid}
      >
        <Pressable onClick={this.onClick}>
          <Icon name={'drag'} color={'#fff'} size={10} />
          <Icon set="fa" icon={'chevron-down'} color={'#fff'} />
          <span className="title">{ title }</span>
          <span className="duration">{ durationFormatted }</span>
        </Pressable>
        {children && (
          <div className="due-today-sub">
            {children.map(child => <DueTodayItem  key={child.id} item={child} />)}
          </div>
        )}
      </div>
    )
  }
}

export default observer(DueTodayItem)
