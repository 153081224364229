import React from 'react'
import { components } from 'react-select'
import { observer } from 'mobx-react';
import PlaceholderColor from './PlaceholderColor';

const debug = require('debug')('treks:select:SingleValue')

class Singlevalue extends React.Component {

  render() {
    const { props } = this
    const { children } = props
    const { menuIsOpen, menuOpenValue, hasAddOption, addOptionIsActive, addOptionLabel } = props.selectProps
    debug('render', { props, children })
    return (
      <components.SingleValue {...props}>
        {
          hasAddOption && addOptionIsActive && menuIsOpen ? <PlaceholderColor>{addOptionLabel}</PlaceholderColor> : 
          menuOpenValue && menuIsOpen 
            ? <PlaceholderColor>{menuOpenValue}</PlaceholderColor> : children
        }
      </components.SingleValue>
    );
  }
}

export default observer(Singlevalue)