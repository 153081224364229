import { observable, action, override, computed } from 'mobx'
import List from '../Lists/List'
import ActivityItem from './ActivityItem'
import { importUser } from '../User'
// import  ProjectItem  from 'Stores/Project/ProjectItem'
import { hasOne } from 'Relationships/RelationshipDecorators'
import { models }  from 'Stores/Stores'
import { ProjectItem } from 'Stores/Project'


const debug = require('debug')('treks:store:activity:list')

/**
 * Global ActivityList
 * Each list has a type which maps to a set of items
 * eg: type:task will map to the activities avaialble for tasks
 */
export default class ActivityList extends List {

  get ModelType() {
    return ActivityItem
  }

  @observable weekCount = 7;

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.thrash)
  project: ProjectItem;

  @computed get startDate(): Date {
    const now = Date.now();
    const weekMs = 1000 * 60 * 60 * 24 * 7;
    const startDate = new Date(now - this.weekCount * weekMs);
    return startDate;
  }

  // compute from startdate so they are dependent
  @computed get endDate(): Date {
    const weekMs = 1000 * 60 * 60 * 24 * 7;
    const endDate = new Date(
      this.startDate.getTime() + this.weekCount * weekMs,
    );
    return endDate;
  }

  @computed get activityWeeks() {
    const activityItems = this.items as ActivityItem[]
    const now = Date.now();
    const weekMs = 1000 * 60 * 60 * 24 * 7;
    const activityWeeks = [7, 6, 5, 4, 3, 2, 1].map((weekIndex) => {
      const startDate = new Date(now - weekIndex * weekMs);
      const endDate = new Date(now - (weekIndex - 1) * weekMs);
      const items = activityItems.filter((activity) => {
        const createDate = activity.createDate;
        return createDate <= endDate && createDate >= startDate;
      });
      return {
        startDate,
        endDate,
        items
      };
    });
    return activityWeeks;
  }

  /**
   * @property {string}
   */
  @observable type = null

  /**
   * @property {User}
   */
  @observable user = importUser().create()

  @action setUser(user) {
    this.user = user
  }

	@hasOne(() => models.ProjectItem, (project: ProjectItem) => project.activityList)
  project: ProjectItem

  toJSON() {
    return {
      items: this.items.map(item => item.toJSON()),
      type: this.type
    }
  }

  @override fromJSON(json) {
    debug('fromJSON', json)
    this.items.splice(0)
    this.type = json.type
    this.setItemsFromJSON(json.items)
    return this
  }

  @override async fetch() {
    return this.fetchState.get('activity/list', { 
      type: this.type, 
      projectId: this.project.id 
    })
      .then(({ data }) => this.fromJSON(data))
  }

  save() {
    const json = this.toJSON()
    debug('save', { json })
    return this.saveState.post('activity/list/save', json)
  }

}