import React from 'react'
import { observer } from 'mobx-react'
import './List.scss'

class ListRow extends React.Component {

  static defaultProps = {
    className: ''
  }

  render() {
    const { className } = this.props
    return (
      <div className={'list-row row-flex ' + className}>
        {this.props.children}
      </div>
    )
  }
}

export default observer(ListRow)