import React from 'react'
import { observer } from 'mobx-react'
import DetailRow from '../ui/DetailRow'
import DetailAddMembers from '../ui/Detail/DetailAddMembers'
import FormControlCheckBox from 'theme/Form/FormControlCheckBox'

const debug = require('debug')('treks:event:members')

class EventMembers extends React.Component {

  static defaultProps = {
    addMemberPlaceholder: 'Add Guests',
  }

  onAddMember = () => {
    const { item } = this.props
    const { name, email } = this.store
    const newItem = item.memberList.addItem({ name, email })
    item.addMember(item.memberList.getItemByIdString(newItem.id))
    this.store.setState({ name: null, email: null, isAddingUser: false })
    debug('onCreateOption', { item, newItem })
  }

  onChange = option => {
    const { item } = this.props
    const member = item.memberList.getItemByIdString(option.value)
    item.addMember(member)
    debug('set item member', { item, option })
  }

  // : 'guestCanModifyEvent' | 'guestsCanInviteOthers' | 'guestsCanSeeOtherGuests'
  onToggle = (prop) => {
    const { item } = this.props
    item[prop] = !item[prop]
    item.willSave()
  }

  render() {
    const { item, addMemberPlaceholder } = this.props
    const members = item.memberList.items
    const memberIds = members.map(member => member.id.toString())
    const options = item.memberList.items
      .filter(({ id }) => !memberIds.includes(id.toString()))
      .map((member) => ({ 
        label: member.name, 
        value: member.id 
      }))
    debug('render', { item, options, store: this.store })
    return (
      <div className="container event-member-list">
        <DetailRow icon="add_guest" className="row-flex" hasValue={members.length}>
          <DetailAddMembers
            item={item}
            addMemberPlaceholder={addMemberPlaceholder}
            options={options}
            onChange={this.onChange}
            onAddMember={this.onAddMember}
            avatarStyles={avatarStyles}
            selectStyles={selectStyles}
            />
        </DetailRow>
        <DetailRow className='event-perms' hasValue={item.memberList.items.length > 0} disabled={!item.memberList.items.length}>
          <div className='event-perms-header'>{'Guests can'}</div>
          <div className='event-guest-perms'>
            <div className='event-guest-perm'>
              <FormControlCheckBox onChange={() => this.onToggle('guestCanModifyEvent')} checked={item.guestCanModifyEvent} />
              <span>{'Modify event'}</span>
            </div>
            <div className='event-guest-perm'>
              <FormControlCheckBox onChange={() => this.onToggle('guestsCanInviteOthers')} checked={item.guestsCanInviteOthers} />
              <span>{'Invite others'}</span>
            </div>
            <div className='event-guest-perm'>
              <FormControlCheckBox onChange={() => this.onToggle('guestsCanSeeOtherGuests')} checked={item.guestsCanSeeOtherGuests} />
              <span>{'See guests list'}</span>
            </div>
          </div>
        </DetailRow>
      </div>
    )
  }
}

export default observer(EventMembers)

const avatarStyles = {
  marginRight: 5,
}

const selectStyles = {
  control: styles => {
    return {
      ...styles,
      minHeight: 30,
      height: 30,
      marginTop: 0,
      marginLeft: 5,
    }
  },
}
