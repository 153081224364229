import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectComments.scss'
import { observable } from 'mobx';
import ProjectCommentList from './ProjectCommentList'
import ProjectAddComment from './ProjectAddComment'
import { getAppNotifications } from 'AppNotifications'
import { getStore, models } from 'Stores';
import PropTypes from 'prop-types'

const debug = require('debug')('treks:project:comments')

class ProjectComments extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static defaultProps = {
    comments: models.CommentList.create(),
    get account() {
      return getStore(models.Session).Account
    }
  }

  static propTypes = {
    comments: PropTypes.instanceOf(models.CommentList),
    account: PropTypes.instanceOf(models.Account)
  }

  componentDidMount() {
    const { comments, project } = this.props
    comments.setProps({ project })
    comments.fetch()
  }

  componentDidUpdate(prevProps) {
    const { comments, project } = this.props
    if (prevProps.project !== project) {
      comments.setProps({ project })
      comments.fetch()
    }
  }

  addComment = ({ title, text }) => {
    const { account, comments, project } = this.props
    if (!title || !text) {
      return getAppNotifications().warn('The comment title and text are required')
    }
    const props = {
      user: account,
      title,
      text,
      commentDate: new Date(),
      project
    }
    debug('adding comment', props)
    const comment = comments.addItem(props)
    comment.save()
  }

  render() {
    const { project, comments, account } = this.props
    debug('render', { project, comments })
    return (
      <div className="project-comments">
        <header className="row tab-header">
          <Icon icon="comment" size={15} />
          <div className="tab-title">Comments</div>
        </header>
        <div className="project-comments-container">
          <ProjectAddComment onAddComment={this.addComment} />
          <ProjectCommentList project={project} comments={comments} account={account} />
        </div>
      </div>
    )
  }
}

export default observer(ProjectComments)
