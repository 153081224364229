import React from 'react'
import './DraggableDemo.scss'
import { observer } from 'mobx-react'
import DraggableItem from './DraggableItem'
import { addRandomItemsToList } from './utils';
import DraggableLists from './models/DraggableLists'

const debug = require('debug')('treks:docs:draggable')

export default observer(class DraggableDemo extends React.Component {

  store = {
    lists: new DraggableLists()
  }

  componentDidMount() {
    this.addToLists()
  }

  addToLists() {
    addRandomItemsToList(this.store.lists.items)
  }

  addEvent() {
    this.store.lists.addEvent()
  }
 
  onChange = (list, order, Draggable, event) => {
    debug('onChange', { order, list, Draggable, event })
    this.store.lists.setListOrder(list, order)
  }

  onDraggableRef = ref => {
    this.ref = ref
  }

  render() {
    const { store, props } = this
    debug('render', { store, props })

    return (
      <div className="container draggable-list-container">
        <div className="row">
        <div className="col">

          <h2>Draggable React</h2>
          <p>
            Draggable multi list with flow around a fixed item
          </p>
          <p>
            <button onClick={() => this.addToLists()}>Add to Lists</button>
            <button onClick={() => this.addEvent()}>Add Event</button>
          </p>

          {
            this.store.lists.items.map((list, index) => {
              return (
                <div key={index} className="list-container">
                  <h3 className="list-name">{list.name}</h3>
                  <div
                    className="draggable-list"
                  >
                  {
                    list.items.map(item => (
                      <DraggableItem
                        key={item.id}
                        list={list}
                        item={item}
                        onChange={this.onChange}
                      />
                    ))
                  }
                  </div>
                </div>
              )
            })
          }

        </div>
        </div>
      </div>
    )
  }
})