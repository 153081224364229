import React from 'react'
import { observer } from 'mobx-react'
import PlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils'
import PlannerSettingsDay from './PlannerSettingsDay'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import PlannerFlowTimes from 'Screens/Docs/PlannerFlow/PlannerFlowTimes'

const debug = require('debug')('treks:container:planner:settings')

const plannerUtils = new PlannerUtils({ 
  durationInterval: 60, 
  durationHeightPerInterval: 25, 
  taskMargin: 0,
  minDuration: 30,
})

class PlannerSettingsDays extends React.Component {

  render() {
    const { plannerFlow, plannerSettings, dayList } = this.props
    debug('render', dayList)

    return (
      <div className="action-planner">

        <div className="action-planner-body md-light-scrollbar">
          <PlannerFlowTimes
            timespanPieces={plannerFlow.timespanFlow}
            daysLength={1}
            plannerUtils={plannerUtils}
            showTimeLabels={true}
            timeLabelInterval={60}
            minutesPerRow={60}
          />

          <Container className="action-planner-items">
            <Row>
            {
              dayList.items.map(day => {
                return (
                  <Col key={day.uid}>
                    <h4 className="settings-header">{day.title}</h4>
                    <PlannerSettingsDay 
                      plannerFlow={plannerFlow}
                      plannerSettings={plannerSettings}
                      plannerUtils={plannerUtils}
                      dayList={dayList}
                      day={day}
                    />
                  </Col>
                )
              })
            }
            </Row>
          </Container>

        </div>
      </div>
    )
  }
}

export default observer(PlannerSettingsDays)