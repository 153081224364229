import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import stores from 'Stores'

const debug = require('debug')('treks:task:description')

class TaskDescription extends React.Component {

  static defaultProps = {
    placeholder: 'Description'
  }

  static propTypes = {
    item: PropTypes.instanceOf(stores.TaskItem).isRequired,
    description: PropTypes.string
  }

  componentDidMount() {
    this.setHeightToContent()
  }

  componentDidUpdate() {
    this.setHeightToContent()
  }

  onRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    debug('description changed', event)
    this.props.item.setProps({
      description: event.target.value
    })
    this.props.item.willSave()
  }

  setHeightToContent() {
    const { inputRef } = this
    if (inputRef) {
      inputRef.style.height = inputRef.scrollTop 
        ? inputRef.scrollHeight + 'px' : 'auto'
      // required to ensure we detect scroll decreasing height
      inputRef.style.height = inputRef.scrollHeight + 'px'
    }
  }

  render() {
    const { item, placeholder } = this.props
    debug('render', item)
    return (
      <textarea
        placeholder={placeholder}
        rows="1"
        ref={this.onRef}
        className="description input-borderless"
        value={item.description || ''}
        onChange={this.onChange}
      />
    )
  }
}

export default observer(TaskDescription)