import { computed } from 'mobx'
import TimeSpanItem from './TimeSpanItem'

const debug = require('debug')('treks:store:TimespanBucketItem')

function round(value, step = 1.0) {
  let inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

export default class TimeSpanBucketItem extends TimeSpanItem {

  @computed get totalDuration(): number {
    const { title, type, dayList } = this
    const dayTotals = dayList.items.map(day => 
      day.items
        .filter(item => item.type === type)
        .reduce((total, item) => total + item.duration, 0))
    const totalMins = dayTotals.reduce((total, duration) => total + duration, 0)
    debug('total duration', { title, type, totalMins, dayList })
    const totalHours = totalMins / 60
    return round(totalHours, 0.5)
  }

}
