import { hasOne } from "Relationships/RelationshipDecorators";
import TaskItem from "./TaskItem";
import TaskList from "./TaskList";
import { models } from "Stores";
import { type ModelPropsI } from "Stores/Model/Type/Model";
import { computed, override } from "mobx";

const debug = require('debug')('treks:store:TaskSubTasksList')

export class TaskSubTasksList extends TaskList {

  @hasOne(() => models.TaskItem, (task: TaskItem) => task.subTasksList)
  parentTask: TaskItem

  @override get duration(): number {
    return this.visibleItems
      .filter(item => item.onPlanner !== true)
      .map(({ duration }) => duration)
      .reduce((total, duration) => total + duration, 0)
  }

  addItem(props: ModelPropsI<TaskItem>, index: number = this.items.length) {
    debug('TaskSubTasksList:additem', { props, list: this })
    const task = this.createItem<TaskItem>(props)
    TaskItem.canBeSubTask(this.parentTask, task)
    return super.addItem(task, index)
  }

  fromJSON(data) {
    // ensure we only consume subtasks of parentTask
    if (data?.items) {
      const { items } = data
      const subtasks = items.filter(item => item.parentTaskId === this.parentTask.id)
      debug('subtasks', { subtasks, items })
      if (subtasks.length !== items.length) {
        console.warn('Invalid list of subtasks')
      }
      data.items = subtasks
      super.fromJSON(data)
    }
    return this
  }

  async saveOrder() {
    const order = this.getOrder()
    debug('save order', this, { order })
    await this.saveState.postJson('task/order/save', {
      order,
      isPlanner: false,
      parentTaskId: this.parentTask?.id,
    })
    return this
  }

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('task/list/paged', {
      parentTaskId: this.parentTask?.id,
      start,
      count,
    })
  }

  async fetch() {
    const json = await this.fetchState.get('task/list', {
      parentTaskId: this.parentTask?.id
    })
    this.fromJSON(json.data)
    return this
  }

  async save() {
    await this.saveOrder()
    return this
  }


}