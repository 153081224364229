import Model from "Stores/Model";
import { TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan';
import stores, { getStore } from 'Stores';
import { action, computed, observable } from "mobx";
import ActionPlanner from "Stores/ActionPlanner";

// @todo timespan.type should use timespan.uid for tracking instead
// @todo add timespan.uid to timeSpanBucket items
export class TimespansToTrack extends Model {

  @observable plannerStore: ActionPlanner = getStore(stores.ActionPlanner)

  @computed get allTimespans(): TimeSpanItem[] {
    return this.plannerStore.dayTimespans.items
      .filter(timespans => timespans.type !== 'sleep')
  }

  @computed get allTimespanTypes(): string[] {
    const timespanTypes = this.allTimespans.map(item => item.type)
    return timespanTypes
      .filter((type: string, index: number) => timespanTypes.indexOf(type) === index)
  }

  @observable trackedTimespanTypes: string[] = [ ...this.allTimespanTypes ]

  @action addTimespanType(name: string) {
    if (!name) throw new Error('Parameter {name} must not be empty')
    this.trackedTimespanTypes.push(name)
  }

  @action removeTimespanType(name: string) {
    if (!name) throw new Error('Parameter {name} must not be empty')
    this.trackedTimespanTypes.splice(this.trackedTimespanTypes.indexOf(name), 1)
  }

  @action setTimespanTypes(timespanTypes: string[]) {
    this.trackedTimespanTypes.splice(0, this.trackedTimespanTypes.length, ...timespanTypes)
  }

  hasTimespanType(name: string): boolean {
    return this.trackedTimespanTypes.includes(name)
  }
}