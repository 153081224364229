import React from 'react'

function toDayName(date) {
  return date.toLocaleDateString('en-gb', { weekday: 'short' })
}

export function TaskEarmarkedDayLabel({ task }) {
  const dueClassName = task.isEarmarkedOverdue ? ' overdue' : ''
  return <>
    {
      task.isEarmarked ? (
        <span className={'task-earmarked-day' + dueClassName}>
          { 
            toDayName(task.earmarkedDate)
          }
        </span>
      ) : null
    }
  </>
}