import React from 'react'
import { observer } from 'mobx-react'
import { when, toJS } from 'mobx'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import stores, { getStore} from 'Stores'
import { FormField } from 'theme/Form'
import fields from './TeamFields'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:account:register:team')

const headerText = 'Welcome to TREKS!'
const infoText = 'Tell us about your team'
const ContinueLabel = 'Continue'

export default withRouter(observer(class AccountRegisterTeam extends React.Component {

  formControlRefs = {}

  accountRegisterStore = getStore(stores.AccountRegister)
  sessionStore = getStore(stores.Session)
  accountStore = getStore(stores.Session).Account
  formStore = stores.Form.fromFields(fields)

  componentDidMount() {
    when(
      () => this.accountStore.id,
      () => {
        debug('autorun setting auth account to form', toJS(this.accountStore))
        this.formStore.setFieldValues(this.accountStore)
        this.accountRegisterStore.setProps(this.accountStore)
      }
    )
  }

  hasFilledInForm() {
    return Object.values(this.formStore.getFieldValues())
      .find(value => value)
  }

  onClickContinue = async () => {
    try {
      const accountProps = this.formStore.getFieldValues()
      this.accountStore.setProps(accountProps)
      await this.accountStore.edit()
      if (this.hasFilledInForm()) {
        this.props.history.push('/account/register/add-teammates')
      } else {
        this.props.history.push('/account/register/start-free-trial')
      }
    } catch(error) {
      debug('team details error', error)
      getAppNotifications().error('An error occurred while trying to add your team details')
    }
  }

  onFieldValueChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  render() {

    debug('render', { formStore: this.formStore })

    const busy = this.sessionStore.isAuthReqPending

    return (
      <Container className="account-register account-register-team settings">
        <Row className="centered-header">
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              {infoText}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form className="account-register-form">

            {
              this.formStore.fields.map(field => {
                return (
                  <FormField
                    key={field.name}
                    {...field}
                    disabled={field.disabled || busy} 
                    onChange={value => this.onFieldValueChange(field, value)} 
                  />
                )
              })
            }
            
            <Form.Group controlId="formGroupName">
              <div className="form-btn-container">
                <Button 
                  onClick={this.onClickContinue} 
                  variant="primary"
                  disabled={busy}
                >
                  {ContinueLabel}
                </Button>
              </div>
            </Form.Group>

          </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>
    )
  }
}))