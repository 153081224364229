import { StorageServiceI } from 'Stores/Service/Type/StorageService'

export class LocalStorageAdapter implements StorageServiceI {

  async getItem(path: string) {
    return localStorage.getItem(path)
  }

  async setItem(path: string, data: string) {
    localStorage.setItem(path, data)
  }

}
