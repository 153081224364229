import React from 'react'
import './AccountSettings.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default class AccountSettings extends React.Component {

  render() {
    return (
      <Container className="settings account-settings">
        <Row className="account-settings-row">
          <Col>
            Todo
          </Col>
        </Row>
      </Container>
    )
  }
}