import React from 'react'

const ValueTextFormControl = ({ value, placeholder }) => {
  return (
    <div className="form-control-value-text">
      <div className="form-value-text">{value || placeholder}</div>
    </div>
  )
}

export default ValueTextFormControl