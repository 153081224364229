import React from 'react'
import { observer } from 'mobx-react'
import { ResponsiveLine } from '@nivo/line'
import { observable } from 'mobx'

const debug = require('debug')('treks:goal:metrics:results:graph')

const clone = obj => JSON.parse(JSON.stringify(obj))

class ResultsGraph extends React.Component {

  store = observable({
    data: []
  })

  componentDidMount() {
    const data = clone(this.props.data)
    data[0].data = data[0].data.map(({ x, }) => ({ x, y: 0 }))
    this.store.data = data
    window.setTimeout(() => this.store.data = this.props.data)
    global.store = this.store
  }

  getYMaxCeil(scale = 10) {
    const yMax = this.props.data[0].data
      .reduce((max, { y }) => Math.max(y, max), 0)
    return Math.ceil(yMax/scale) * scale
  }

  getYMinCeil(scale = 10) {
    debug('yMin', { data: this.props.data[0].data })
    const points = this.props.data[0].data
    const yMin = points
      .reduce((min, { y }) => Math.min(y, min), points[0].y)
    return Math.floor(yMin/scale) * scale
  }

  render() {
    const { data } = this.store
    const yMax = this.getYMaxCeil()
    const yMin = this.getYMinCeil()
    debug('render', { data, yMin, yMax })
    return (
      <ResponsiveLine
        className="metrics-graph-line"
        data={clone(data)}
        margin={{
          "top": 50,
          "right": 150,
          "bottom": 50,
          "left": 50
        }}
        xScale={{
          "type": "point"
        }}
        yScale={{
          "type": "linear",
          "stacked": false,
          "min": yMin,
          "max": yMax
        }}
        axisTop={null}
        axisBottom={{
          "orient": "bottom",
          "tickSize": 5,
          "tickPadding": 5,
          "tickRotation": 0,
          "legend": "Week",
          "legendOffset": 45,
          "legendPosition": "middle"
        }}
        axisLeft={{
          "orient": "left",
          "tickSize": 5,
          "tickPadding": 5,
          "tickRotation": 0,
          "legend": "lbs",
          "legendOffset": -45,
          "legendPosition": "middle"
        }}
        colorBy={e => e.color}
        enableGridX={false}
        enableDots={false}
        enableArea={false}
        areaOpacity={1}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
            {
                "anchor": "center-right",
                "direction": "column",
                "justify": true,
                "translateX": 200,
                "translateY": 80,
                "itemsSpacing": 0,
                "itemDirection": "left-to-right",
                "itemWidth": 80,
                "itemHeight": 20,
                "itemOpacity": 0.75,
                "symbolSize": 12,
                "symbolShape": "square",
                "symbolBorderColor": "rgba(0, 0, 0, .5)",
                "effects": [
                    {
                        "on": "hover",
                        "style": {
                            "itemBackground": "rgba(0, 0, 0, .2)",
                            "itemOpacity": 1
                        }
                    }
                ]
            }
        ]}
      />
    )
  }
}

export default observer(ResultsGraph)
