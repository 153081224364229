import Model from "Stores/Model"
import { ColKey, ColType, ColValue } from "./__types__"
// import { observable } from "mobx"

/**
 * For performance we never mutate a column entry once created
 * So we don't have to watch mutations. 
 * Instead we remove the entry and insert an updated one
 */
export class RelationshipJoinEntry {

  entries: Map<ColKey, ColValue> = (new Map()) // observable(new Map())

  constructor(
    Type: ColType,
    key: ColKey,
    value: ColValue,
    RefType: ColType,
    refKey: ColKey,
    refValue: ColValue
  ) {
    this.setColEntry(Type, key, value)
    this.setColEntry(RefType, refKey, refValue)
  }

  getTableColKey(Type: ColType, key: ColKey) {
    return `${Type.name}.${key}`
  }

  setColEntry(Type: ColType, key: ColKey, value: ColValue) {
    const colKey = this.getTableColKey(Type, key)
    this.entries.set(colKey, value)
  }

  hasColEntry(Type: ColType, key: ColKey, value: ColValue) {
    const colValue = this.getColEntry(Type, key)
    return colValue === value || (value instanceof Model && Model.isSameModel(colValue as Model, value))
  }

  getColEntry(Type: ColType, key: ColKey) {
    const colKey = this.getTableColKey(Type, key)
    return this.entries.get(colKey)
  }

  toJSON() {
    const json = {}
    this.entries.forEach((value, key) => {
      json[key] = value
    })
    return json
  }


}