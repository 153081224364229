import { override } from 'mobx'
import { ActivityTypes } from '../../Activity'

/**
 * Avaialble Task Activity Types
 */
export default class TaskActivityTypes extends ActivityTypes {

  /**
   * @property {string}
   */
  @override type = 'task'

}
