import React from 'react'
import { observer } from 'mobx-react'
import './SprintLabel.scss'
import { FormControlDropdown } from 'theme/Form'

const debug = require('debug')('treks:project:settings:label:sprint')

class SprintLabel extends React.Component {

  onSprintLenChange = event => {
    this.props.settings.sprintLen = event.target.value
  }

  onSprintLenUnitChange = value => {
    debug('changed sprintLenUnit', { settings: this.props.settings, value })
    this.props.settings.sprintLenUnit = value
  }

  render() {
    const { field, settings } = this.props
    const sprintLenUnitItems = [
      {
        label: 'Day(s)',
        value: 'day',
      },
      {
        label: 'Week(s)',
        value: 'week',
      },
      {
        label: 'Month(s)',
        value: 'month',
      }
    ]
    debug('render', { field, settings })
    return (
      <div className="project-settings-label-sprint">
        <div className="project-settings-label">Sprint Based Project</div>
        <div className="list-row row-flex">
          <div>Sprint Length</div>
          <input 
            className="field-input-sprint-len"
            value={settings.sprintLen}
            onChange={this.onSprintLenChange}
          />
          <FormControlDropdown
            className="field-select-sprint-len-unit"
            items={sprintLenUnitItems}
            value={settings.sprintLenUnit}
            onChange={this.onSprintLenUnitChange}
          />
        </div>
      </div>
    )
  }
}

export default observer(SprintLabel)
