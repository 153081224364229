import { observable, override } from "mobx";
import OrderedList from "Stores/Lists/OrderedList";
import { ListJsonI } from "Stores/Lists/Type/List";
import { getBucketList } from ".";
import MainPaneItem from "./MainPaneItem";

const debug = require('debug')('treks:model:MainPaneTabList')

export class MainPaneTabList extends OrderedList {

  get ModelType() {
    return MainPaneItem
  }

  @observable colSpan: number = 1

  @observable maxTabs: number = 5

  canAddTab(pane: MainPaneItem): boolean {
    return pane.colSpan <= this.colSpan && this.items.length < this.maxTabs
  }

  addTab(pane: MainPaneItem): MainPaneItem {
    return this.addItem(pane)
  }

  removeTab(pane: MainPaneItem): MainPaneItem {
    return this.removeItem(pane)
  }

  toJSON(): ListJsonI {
    return {
      colSpan: this.colSpan,
      maxTabs: this.maxTabs,
      items: this.items.map(item => ({
        uid: item.uid,
        x: item.x,
        w: item.w,
        type: item.type,
        name: item.name,
        title: item.title,
        slug: item.slug,
        icon: item.icon,
        width: item.width,
        modelId: item.modelId,
        activeTab: item.activeTab,
        focusedTabUid: item.focusedTabUid,
      }))
    }
  }

  getBucketItemPropsBySlug(slug: string): Partial<MainPaneItem> {
    const bucket = getBucketList()
    const item = bucket.getItemBySlug(slug)
    if (item) {
      const { component, name } = item
      return { component, name }
    }
  }

  getItemFromJSON(props: Partial<MainPaneItem>) {
    const bucketProps = this.getBucketItemPropsBySlug(props?.slug)
    const pane = MainPaneItem.fromJSON(props)
    bucketProps && pane.setProps(bucketProps)
    debug('Tablist.bucket', bucketProps, props, pane.toJSON())
    return pane
  }

  @override fromJSON(json?: ListJsonI): this {
    if (json) {
      this.setProps({
        colSpan: json.colSpan,
        maxTabs: json.maxTabs,
      } as any)
      const panes = json.items?.map(item => {
        return this.getItemFromJSON(item)
      })
      this.setItemsFromJSON(panes)
    }
    return this
  }

}
