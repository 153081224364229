import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { FilePond, File } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from '../../../theme/Button'

const debug = require('debug')('treks:task:files')
const maxFiles = 20

class TaskFileUploadModal extends React.Component {

  state = observable({
    files: []
  })

  setState(state) {
    Object.assign(this.state, state)
  }

  handleInit() {
    debug('FilePond instance has initialised', this.pond)
  }

  handleClose = () => {
    this.props.onClose(this.state.files)
    this.setState({
      files: []
    })
  }

  handleCancel = () => {
    this.props.onClose([])
    this.setState({
      files: []
    })
  }

  onUpdateFiles = list => {
    const files = list.map(item => {
      const file = item.file
      file.id = item.id
      return file
    })
    debug('files', { list, files })
    this.setState({ files })
  }

  render() {
    const { files } = this.state
    debug('render', { files })
    return (
      <Modal 
        show={this.props.show} 
        onHide={this.handleCancel}
        size="lg"
        centered
      >
        <Modal.Body>
          <FilePond 
            style={styles.filepond}
            ref={ref => this.pond = ref}
            allowMultiple={true}
            maxFiles={maxFiles}
            oninit={() => this.handleInit()}
            onupdatefiles={this.onUpdateFiles}
          >
            {files.map(file => (
              <File key={file.id} src={file} origin="local" />
            ))}
          </FilePond>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button variant="outline-dark" onClick={this.handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default observer(TaskFileUploadModal)

const styles = {
  filepond: {
    width: '100%'
  },
}