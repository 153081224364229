import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import Input from './ProjectStatusInput'

const debug = require('debug')('treks:project:assignments:item')

class ProjectTaskStatusAssignmentItem extends React.Component {

  render() {
    const { assignedTo, duration, durationFormatted } = this.props.assignment
    debug('render', { assignedTo, duration, durationFormatted })
    return (
      <div className="assignment-item list-row row-flex">
        <div className="list-col col-drag">
          <Icon name="drag" color={'#808284'} size={10} />
        </div>
        <div className="list-col col-status">
          <Input
            assignment={this.props.assignment}
            onChangeStatus={this.props.onChangeStatus}
          />
        </div>
        <div className="list-col col-status">
          {assignedTo && assignedTo.name}
        </div>
        <div className="list-col col-status">
          {duration ? durationFormatted : null}
        </div>
      </div>
    )
  }
}

export default observer(ProjectTaskStatusAssignmentItem)
