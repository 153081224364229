import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { UserList } from 'Stores/User';
import { MemberList, MemberItem } from 'Stores/Member';
import DetailSelectMember from 'Components/ui/Detail/DetailSelectMember/DetailSelectMember';

const debug = require('debug')('treks:project:people:title')

class ProjectPeopleTitle extends React.Component {

  static propTypes = {
    userList: PropTypes.objectOf(() => UserList).isRequired,
    memberList: PropTypes.objectOf(() => MemberList).isRequired,
    member: PropTypes.objectOf(() => MemberItem).isRequired,
  }

  onChangeUser = user => {
    const { member } = this.props
    member.setUser(user)
    member.save()
  }

  render() {
    const { userList, memberList, member, className } = this.props
    const { user } = member
    const { options } = this
    debug('render', { user, options })
    if (user.id) return (
      <div className="member-name">
        {user.name}
      </div>
    )
    return (
      <div className={'member-assign ' + className}>
        <DetailSelectMember
          userList={userList}
          memberList={memberList}
          member={member}
          onChangeUser={this.onChangeUser}
        />
      </div>
    )
  }
}

export default observer(ProjectPeopleTitle)