import React from 'react'
import 'theme/style/settings.css'
import '../Register/AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import AccountRegisterFooter from '../Register/AccountRegisterFooter'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'

const headerText = 'Password reset  successful!'
const infoText = 'Your password has been reset and you can now login.'
const continueToLoginText = 'Login to Treks'

export default withRouter(class AccountPasswordResetComplete extends React.Component {

  onClickContinue = () => {
    this.props.history.push('/account/login')
  }

  render() {
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <p className="info">
              {infoText}
            </p>
            <Button 
              onClick={this.onClickContinue} 
              variant="primary"
            >
              {continueToLoginText}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountRegisterFooter />
          </Col>
        </Row>
      </Container>
    )
  }
})