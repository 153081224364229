import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectMetrics.scss'
import { observable } from 'mobx'
import MetricList from 'Stores/Metric/MetricList'
import ProjectMetricsThrash from './Thrash';
import ProjectMetricsBlockers from './Blockers';
import ProjectMetricsUnassignedTasks from './UnassignedTasks';
import BurnDown from './BurnDown';
import Scrollable from 'theme/Scrollable';
import { getAppNotifications } from 'AppNotifications';
import { uid } from 'Stores/utils';
import { getStore, models } from 'Stores'
import ScrollHorizontal from 'Components/Shared/ScrollHorizontal/ScrollHorizontal'
import ScrollTabs from 'Components/Shared/ScrollHorizontal/ScrollTabs'

const debug = require('debug')('treks:project:metrics')

const scrollTabs = [
  {
    name: 'thrash',
    label: 'Thrash'
  },
  {
    name: 'blockers',
    label: 'Blockers'
  },
  {
    name: 'unassigned',
    label: 'Unassigned Tasks'
  },
  {
    name: 'finish',
    label: 'Projected Finish'
  },
  {
    name: 'burndown',
    label: 'Burndown'
  }
]

class ProjectMetrics extends React.Component {

  store = observable({
    view: null,
    order: null,
    paneIndex: 0
  })

  static defaultProps = {
    metrics: MetricList.create(),
    get account() {
      return getStore(models.Session).Account
    }
  }

  componentDidMount() {
    const paneIndex = Number(localStorage.getItem('project.metrics.paneIndex'))
    this.store.paneIndex = paneIndex
    this.scrollToTabIndex(paneIndex, false)
    console.log('paneIndex', paneIndex)
    window.addEventListener('scroll', this.setScrollDownBtnPos)
    this.setScrollDownBtnPos()
  }

  componentWillUnmount() {
    localStorage.setItem('project.metrics.paneIndex', this.store.paneIndex)
    window.removeEventListener('scroll', this.setScrollDownBtnPos)
  }

  setScrollDownBtnPos() {
    const btn = document.querySelector('.btn-scroll-down')
    if (btn) btn.style.marginBottom = 120 - window.pageYOffset
  }

  addMetric = ({ title, text }) => {
    if (!title || !text) {
      getAppNotifications().warn('The metric title and text are required')
      return false
    }
    const item = {
      user: this.props.account,
      id: uid(),
      title,
      metric: text
    }
    debug('adding metric', item)
    this.props.metrics.addItem(item)
    return true
  }

  onScrollChange = ({ nextPane, nextIndex }, useScroll = true) => {
    console.log('onScrollChange', { nextPane, nextIndex })
    
    const parent = nextPane.parentNode
    const scrollLeft = nextIndex * nextPane.getBoundingClientRect().width
    // parent.scrollLeft = scrollLeft
    parent.scrollTo({
      behavior: useScroll ? 'smooth' : 'instant', 
      left: scrollLeft
    })

    console.log('scrollTo', {
      parent,
      nextPane,
      nextIndex,
      scrollLeft
    })

    this.store.paneIndex = nextIndex
    localStorage.setItem('project.metrics.paneIndex', this.store.paneIndex)
  }

  onTabChange = ({ event, index }) => {
    console.log('onTabChange', { index })
    event.preventDefault()
    this.scrollToTabIndex(index)
  }

  scrollToTabIndex(index, useScroll = true) {
    const nextPane = document.querySelector('.scroll-panes').children[index]
    this.onScrollChange({ nextPane, nextIndex: index }, useScroll)
  }

  render() {
    const { project, metrics } = this.props
    const { paneIndex } = this.store
    debug('render', { project, metrics })
    return (
      <div className="project-metrics">
        <header className="row tab-header">
          <Icon icon="metric" size={15} />
          <div className="tab-title">Metrics</div>
        </header>
        <div className='tabs-container'>
          <ScrollTabs
            tabs={scrollTabs}
            focusedIndex={paneIndex} 
            onChange={this.onTabChange}
          />
        </div>
        <ScrollHorizontal
          className="project-metrics-list"
          onChange={this.onScrollChange}
          paneIndex={paneIndex}
        >
          <ProjectMetricsThrash project={project} />
          <ProjectMetricsBlockers project={project} />
          <ProjectMetricsUnassignedTasks project={project} />
          <BurnDown project={project} />
        </ScrollHorizontal>
      </div>
    )
  }
}

export default observer(ProjectMetrics)
