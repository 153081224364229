import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Icon from 'theme/Icon'
import './GoalActivity.scss'
import { observable } from 'mobx';
import GoalActivityList from './GoalActivityList'
import { GoalItem } from 'Stores/Goal';
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:goal:activity')

class GoalActivity extends React.Component {

  static propTypes = {
    goal: PropTypes.objectOf(() => GoalItem).isRequired,
  }

  store = observable({
    view: null,
    order: null
  })

  componentDidMount() {
    this.props.goal.activityList.fetch()
      .catch(error => {
        debug('activity fetch error', error)
        getAppNotifications().error('Error fetching activity')
      })
  }

  render() {
    const { goal } = this.props
    debug('render', { goal })
    return (
      <div className="goal-activity">
        <header className="row tab-header">
          <Icon icon="attach_activity" size={15} />
          <div className="tab-title">HISTORY</div>
        </header>
        <GoalActivityList
          goal={goal}
        />
      </div>
    )
  }
}

export default observer(GoalActivity)
