import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan'
import { TimespanTypeTitle } from './TimespanTypeTitle'
import ColorPicker from 'theme/ColorPicker/ColorPicker'
import Pressable from 'theme/Pressable'
import Icon from 'theme/Icon'
import { autorun, makeAutoObservable } from 'mobx'
import DeleteTimespanMenu from './modals/DeleteTimespanMenu'

const debug = require('debug')('treks:planner:settings:detail')

class TimeSpansOverviewItem extends React.Component {

  static propTypes = {
    timespan: PropTypes.objectOf(() => TimeSpanItem)
  }

  static defaultProps = {
    colorBtnStyle: { border: 0, width: 20, height: '100%' },
    colorContainerStyle: { height: '100%' },
    deleteTimespanUsedInPlannerWarning: 'Cannot delete a timespan currently used in your planner.',
    deletTimespanWithTasksWarning: (count) =>  count > 1
      ? `${count} tasks exist on your planner for this timespan`
      : `${count} task exists on your planner for this timespan`
  }

  get field() {
    return {
      name: 'color',
      value: this.props.timespan.color
    }
  }

  get totalDuration() {
    const { timespan } = this.props
    return this.props.plannerFlow.timespanFlow
      .filter(piece => piece.type === timespan.type)
      .map(piece => piece.duration)
      .reduce((sum, duration) => sum += duration, 0)
  }

  get timespanTasks() {
    const { plannerFlow, timespan } = this.props
    return plannerFlow.tasks
      .filter(task => task.timespanType === timespan.type)
  }

  get taskCount() {
    return this.timespanTasks.length
  }

  uiState = makeAutoObservable({
    isHover: false,
    showConfirmDelete: false,
    totalDuration: this.totalDuration
  })

  componentDidMount() {
    autorun(() => {
      this.uiState.totalDuration = this.totalDuration
    })
  }

  onColorChange = value => {
    const { timespan, onSaveSettings } = this.props
    timespan.color = value.hex
    onSaveSettings()
  }

  onTitleChange = title => {
    const { timespan, onSaveSettings } = this.props
    timespan.title = title
    onSaveSettings()
  }

  onClickDelete = () => {
    const { deleteTimespanUsedInPlannerWarning } = this.props
    if (this.totalDuration > 0) {
      return alert(deleteTimespanUsedInPlannerWarning)
    }
    if (this.taskCount > 0) {
      this.uiState.showConfirmDelete = true
      return
    } else {
      this.onDeleteTimespan()
    }
  }

  onDeleteTimespan = () => {
    const { timespan } = this.props
    timespan.deleted = true
    timespan.list.save()
  }

  onHideContextMenu = () => {
    this.uiState.showConfirmDelete = false
  }

  // @todo optimize ColorPicker is delaying renders
  render() {
    const {
      plannerFlow,
      timespan,
      isCreating,
      onAddTimespan,
      colorBtnStyle,
      colorContainerStyle,

    } = this.props
    debug('render', timespan)

    // @important use uiState to prevent side-effects
    const totalDuration = this.uiState.totalDuration
    const totalHours = (totalDuration / 60).toFixed(2)

    const color = totalDuration > 0 ? '#dcdddf' : '#97ABB0'

    const { showConfirmDelete } = this.uiState

    return (
      <tr className="planner-settings-overview-item">
        <td style={{ textAlign: 'right' }}>
          <TimespanTypeTitle
            timespan={timespan}
            focusOnTitle={isCreating}
            onAddTimespan={onAddTimespan}
            onTitleChange={this.onTitleChange}
          />
        </td>
        <td>
          <ColorPicker
            field={this.field}
            btnStyle={colorBtnStyle}
            containerStyle={colorContainerStyle}
            onChangeComplete={this.onColorChange}
          />
        </td>
        <td className="timespan-total-duration">
          <span className="duration-value">{totalHours}</span>
          <span className="duration-unit"> hours</span>
        </td>
        <td className="timespan-delete">
          <Pressable
            onClick={this.onClickDelete}
            className="timespan-delete-icon"
            tabIndex="-1"
          >
            <Icon set="fa" name={'times'} color={color} size={'1x'} />
          </Pressable>
        </td>
        <td style={{
          position: 'relative'
        }}>
          {
            showConfirmDelete
              ? <DeleteTimespanMenu
                  plannerFlow={plannerFlow}
                  timespan={timespan}
                  onCreateTimespan={this.onCreateTimespan}
                  taskCount={this.taskCount}
                  timespanTasks={this.timespanTasks}
                  onHideContextMenu={this.onHideContextMenu}
                  onDeleteTimespan={this.onDeleteTimespan}
                />
              : null 
            }
        </td>
      </tr>
    )
  }
}

export default observer(TimeSpansOverviewItem)