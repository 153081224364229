import React from 'react'
import PropTypes from 'prop-types'
import stores from 'Stores'
import Tooltip from 'theme/Tooltip'
import { observer } from 'mobx-react'
import { debugTimer } from 'lib/utils/debugging'

const debug = require('debug')('treks:planner:RealtimeIndicator')

class RealtimeIndicator extends React.Component {

  static propTypes = {
    dayTimespans: PropTypes.objectOf(() => stores.TimeSpanList).isRequired,
    actionPlanner: PropTypes.objectOf(() => stores.ActionPlanner).isRequired,
    plannerUtils: PropTypes.object.isRequired,
    scrollIntoView: PropTypes.bool,
  }

  state = {
    tooltipVisible: true
  }

  tooltipRef = null
  indicatorRef = null

  componentDidMount() {
    if (this.props.scrollIntoView) this.scrollIntoView()
    debugTimer('RealtimeIndicator')
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  get currentTimespanPiece() {
    const { plannerFlow } = this.props
    return plannerFlow.timespanFlow
      .find(t =>{
        return t.startDuration <= plannerFlow.minsSinceStartOfPlanner 
          && t.endDuration > plannerFlow.minsSinceStartOfPlanner
      })
  }

  get currentTaskPieceIndex() {
    const { plannerFlow } = this.props
    return this.currentTimespanPiece.taskPiecesFlow
    .findIndex(t => {
      return t.startDuration <= plannerFlow.minsSinceStartOfPlanner 
          && t.endDuration > plannerFlow.minsSinceStartOfPlanner
    })
  }

  get currentTaskPiece() {
    return this.currentTimespanPiece.taskPiecesFlow[this.currentTaskPieceIndex]
  }

  get prevTaskPiece() {
    return this.currentTimespanPiece.taskPiecesFlow[this.currentTaskPieceIndex - 1]
  }

  scrollIntoView() {
    clearTimeout(this.scrollTimer)
    if (this.indicatorRef && this.indicatorRef.scrollIntoView) {
      this.scrollTimer = setTimeout(() => {
        const mainPane = document.querySelector('.main-pane-component:has(.action-planner)')
        debugTimer('scroll', mainPane)
        mainPane?.scrollIntoView()
        mainPane?.scrollTo({
          top: (this.indicatorRef?.offsetTop || mainPane.offsetHeight) - (mainPane.offsetHeight/2),
          behavior: 'smooth'
        })
      }, 500)
    }
  }

  get firstNotDoneTaskPiece() {
    return this.currentTimespanPiece?.taskPiecesFlow.find(taskPiece => {
      return !taskPiece.task.done
    })
  }

  get topCssPx() {
    const { plannerUtils, plannerFlow } = this.props
    return plannerUtils.getHeightFromDuration(plannerFlow.minsSinceStartOfPlanner)
  }

  get onScheduleStatus() {
    const status = this.timeLeft > 0 ? 'isLate' : 'isOnTime'
    return status
  }

  get currentTimeFormatted() {
    return this.props.actionPlanner.currentTimeFormatted
  }

  get timeLeft() {
    const { plannerFlow } = this.props
    const { firstNotDoneTaskPiece } = this
    const timeLeft = firstNotDoneTaskPiece 
      ? Math.floor(plannerFlow.minsSinceStartOfPlanner - firstNotDoneTaskPiece.endDuration)
      : 0
    return timeLeft
  }

  get timeLeftFormatted() {
    const { plannerUtils } = this.props
    const { timeLeft } = this
    const sign = timeLeft > 0 ? '+' : '-'
    const absTimeLeft = plannerUtils.formatDuration(Math.abs(timeLeft))
    return `${sign}${absTimeLeft}`
  }

  handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const { tooltipVisible } = this.state;
    if (!tooltipVisible) {
      // Calculate distance between mouse and tooltip
      const indicatorRect = this.indicatorRef?.getBoundingClientRect();
      if (indicatorRect) {
        const distance = Math.abs(indicatorRect.top - clientY) 
        if (distance > 50 || clientX > 200) {
          this.setState({ tooltipVisible: true });
        }
      }
    }
  };

  handleTooltipMouseEnter = () => {
    this.setState({ tooltipVisible: false });
  };

  render() {
    const {
      topCssPx,
      onScheduleStatus,
      currentTimeFormatted,
      timeLeft,
      timeLeftFormatted
    } = this
    debug('render', { topCssPx, onScheduleStatus, currentTimeFormatted })
    
    return (
      <div 
        ref={ref => this.indicatorRef = ref}
        className={'planner-realtime-indicator ' + onScheduleStatus}
        style={{
          top: topCssPx
        }}>
        <Tooltip
          tooltipStyle={{
            opacity: this.state.tooltipVisible ? '1' : '0.2',
            pointerEvents: this.state.tooltipVisible ? '' : 'none',
            width: 140
          }}
          onRef={ref => this.tooltipRef = ref}
          placement="top"
          onMouseEnter={this.handleTooltipMouseEnter}
        >
          {
            currentTimeFormatted + (
              timeLeft 
                ? ` [${timeLeftFormatted}]` 
                : ''
            )
          }
        </Tooltip>
      </div>
    )
  }
}

export default observer(RealtimeIndicator)