import UserAvatar from "./UserAvatar"
import "./AvatarContainer.scss"

const user =  getStore(stores.Session).Account

export const UserAvatarContainer = () => {
  return (
    <div className="user-avatar-container">
      <a href="/account/settings" className="user-avatar-border">
        <UserAvatar user={user} />
      </a>
    </div>
  )
}