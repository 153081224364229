import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getKindDeedsExtraPointsLimitOptions } from "./stores/KindDeedsExtraPointsLimitOptions";
import { getKindDeedsPointsStore } from "Containers/PFA/stores/PFAKindDeedsStore";

class KindDeedsExtraPointsLimitSelect extends React.Component {

  static defaultProps = {
    options: getKindDeedsExtraPointsLimitOptions(),
    habitPointsStore: getKindDeedsPointsStore(),
    defaultValue: '10'
  };

  onChange = ({ value }) => {
    this.props.habitPointsStore.setProp('extraPointsLimit', parseInt(value, 10))
  }

  getValue() {
    return String(this.props.habitPointsStore.extraPointsLimit || this.props.defaultValue)
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
      />
    )
  }

}

export default observer(KindDeedsExtraPointsLimitSelect)
