import List from "Stores/Lists";
import { once } from "Stores/utils";
import { MenuItem } from "./MenuItem";

/**
 *  Main Top Menu
 */
export const importMainMenu = once(() => {
  class MainMenu extends List {
    get ModelType() {
      return MenuItem;
    }
  }

  return MainMenu;
});
