import React from 'react'
import { observer } from 'mobx-react'
import ProjectSprintItem from './ProjectSprintItem'
import PropTypes from 'prop-types'
import SprintList from 'Stores/Sprint/SprintList'
import ProjectSprintHeader from './ProjectSprintHeader';

class ProjectSprintList extends React.Component {

  static propTypes = {
    sprints: PropTypes.objectOf(() => SprintList).isRequired,
  }

  render() {
    const { sprints } = this.props
    return (
      <div className="list-view sprint-list">
        <ProjectSprintHeader />
        {sprints.items.map(sprint => (
          <ProjectSprintItem key={sprint.id} sprint={sprint} />
        ))}
      </div>
    )
  }
}

export default observer(ProjectSprintList)
