import React, { createContext, ReactNode, useContext } from "react";
import { createSubTasksStore, SubTasksStore } from "./SubtasksStore";

const SubTasksContext = createContext(null);

export const useSubTasksStore = () => {
  const store = useContext(SubTasksContext);
  if (!store) throw new Error("useStore must be used within a StoreProvider");
  return store;
};

type SubTasksProviderProps = {
  children: ReactNode;
  store?: SubTasksStore;
}

export const SubTasksProvider = ({ children, store }: SubTasksProviderProps) => {
  return (
    <SubTasksContext.Provider value={store || createSubTasksStore()}>
      {children}
    </SubTasksContext.Provider>
  )
};
