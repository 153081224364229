import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalDetails.scss'
import Form from 'Stores/Form'
import GoalDetailList from './GoalDetailList'
import PropTypes from 'prop-types'
import { GoalItem } from 'Stores/Goal'
import GoalFiles from '../GoalFiles'
import GoalActivity from '../GoalActivity';
import GoalDetailFieldDropdown from './Fields/GoalDetailFieldDropdown';
import GoalDetailFieldColor from './Fields/GoalDetailFieldColor';
import { stores, getStore } from 'Stores';

const debug = require('debug')('treks:goal:details')

const DropDown = ({ field, goal }) => <GoalDetailFieldDropdown field={field} goal={goal} />
const ColorPicker = ({ field, goal }) => <GoalDetailFieldColor field={field} goal={goal} />

class GoalDetailsCom extends React.Component {

  static defaultProps = {
    goal: PropTypes.instanceOf(GoalItem).isRequired,
    form: Form.create()
  }

  componentDidMount() {
    const { form } = this.props
    const UserList = getStore(stores.UserList)
    UserList.fetch()
    
    const fields = [
      {
        name: 'status',
        label: 'Status',
        Component: DropDown,
        items: [
          'Inactive',
          'Active',
          'Complete - Achieved',
          'Complete - Not Achieved'
        ]
      },
      {
        name: 'units',
        label: 'Units',
        items: [
          'lbs',
          'kg'
        ],
        Component: DropDown
      },
      {
        type: 'group',
        name: 'dates',
        items: [
          {
            name: 'startDate',
            label: 'Start Date',
          },
          {
            name: 'endDate',
            label: 'End Date',
          }
        ]
      },
      {
        type: 'group',
        name: 'values',
        items: [
          {
            name: 'fromValue',
            label: 'From Value'
          },
          {
            name: 'toValue',
            label: 'To Value'
          }
        ]
      },
      {
        name: 'master',
        label: 'Master Goal',
        Component: DropDown,
        items: []
      },
      {
        type: 'group',
        name: 'owner',
        items: [
          {
            name: 'level',
            label: 'Goal Level',
            Component: DropDown,
            items: []
          },
          {
            name: 'owner',
            Component: DropDown,
            items: UserList.items
          }
        ]
      },
      {
        name: 'template',
        label: 'Template'
      },
      {
        name: 'highlightColor',
        label: 'Highlight Color',
        Component: ColorPicker
      }
    ]

    form.setFields(fields)
  }

  render() {
    const { goal, form } = this.props
    debug('render', { goal, form })
    return (
      <div className="goal-details">
        <header className="row tab-header">
          <Icon icon="details" size={15} />
          <div className="tab-title">DETAILS</div>
        </header>
        <div className="goal-details-list">
          <GoalDetailList
            goal={goal}
            form={form}
          />
          <GoalFiles goal={goal} />
          <GoalActivity goal={goal} />
        </div>
      </div>
    )
  }
}

export default observer(GoalDetailsCom)
