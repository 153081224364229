import { observable, computed, action } from 'mobx'
import Item from '../Lists/Item'

export default class Field extends Item {

  /**
   * @property {string}
   */
  @observable name = null

  /**
   * @property {string}
   */
  @observable label = null

  /**
   * @property {string}
   */
  @observable value = null

  /**
   * @property {boolean}
   */
  @observable disabled = false

  /**
   * @property {boolean}
   */
  @observable required = false

  /**
   * @property {array} items
   * @todo set Model items
   */
  @observable items = []

  /**
   * @property {array<Error>}
   */
  @observable errors = []

  /**
   * @property {React.Component}
   */
  @observable Component = null

  /**
   * @property {string}
   */
  @observable type = null

  /**
   * @property {string}
   */
  @observable placeholder = null

  /**
   * @property {string}
   */
  @observable info = null

  /**
   * @property {string}
   */
  @observable link = null

  /**
   * @property {boolean}
   */
  @observable enumerable = null

  /**
   * @property {function}
   */
  @observable validate = () => {
    this.required && !this.value && new Error('Field is required')
  }

  /**
   * @property {bool}
   */
  @computed get hasErrors() {
    return this.errors.length > 0
  }

  /**
   * @property {boolean}
   */
  @observable focus = null

  /**
   * @property {boolean}
   */
  @observable submitOnEnter = null

  /**
   * @param {string} value 
   */
  @action setValue(value) {
    this.value = value
  }

  /**
   * @param {array} errors 
   */
  @action setErrors(errors = []) {
    this.errors = errors
  }

  @action clearErrors() {
    this.errors = []
  }

  /**
   * @param {Error} error 
   */
  @action addError(error) {
    this.errors.push(error)
  }

} 
