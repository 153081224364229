import { observable } from 'mobx'
import Model from '../Model'

export default class GoalSettings extends Model {

  /**
   * Highlight Color
   */
  @observable highlightColor:string = ''

  /**
   * Sprint Based Goal
   */
  @observable sprintBasedGoal:boolean = true

  /**
   * Sprint Length
   */
  @observable sprintLen:number = 2

  /**
   * Sprint Length Unit (day|week|month)
   */
  @observable sprintLenUnit:string = 'week'

  /**
   * Requires invitation to join
   */
  @observable requiresInvitation:boolean = true

  /**
   * Add to Favorites
   */
  @observable addToFav:boolean = true

  /**
   * Enable time tracking
   */
  @observable timeTracking:boolean = true

  /**
   * Include in Goal Planner
   */
  @observable includeInGoalPlanner:boolean = true

  /**
   * Tasks are Status based by default
   */
  @observable tasksStatusBased:boolean = true

  /**
   * Assign tasks to a default team or individual based on status
   */
  @observable assignTasksToTeam:boolean = true

  /**
   * Assign a default duration to each status
   */
  @observable assignDurationToStatus:boolean = true

}
