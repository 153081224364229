import { action, computed, override } from 'mobx';
import List from 'Stores/Lists';
import Field from './Field'

const debug = require('debug')('treks:store:form')

export default class Form extends List {

  get ModelType() {
    return Field
  }

  @computed get fields(): Field[] {
    return this.items
  }
  set fields(fields) {
    throw new Error('Use setFields() instead of directly setting fields.')
  }


  @computed get namedFields() {
    return this.fields.reduce((fields, field) => {
      fields[field.name] = field
      return fields
    }, {})
  }

  constructor(fields = []) {
    super()
    this.setFields(fields)
  }

  @override setProps(props) {
    if (props && props.fields) {
      this.setFields(props.fields)
      delete props.fields
    }
    super.setProps(props)
  }

  static fromFields(fields) {
    const form = this.create()
    form.setFields(fields)
    return form
  }

  @action reset() {
    this.fields.forEach(field => {
      field.setProps({
        value: null,
        error: null
      })
      return field
    })
  }

  getFieldByName(name) {
    return this.namedFields[name]
  }

  @action setFields(fields) {
    debug('set fields', { fields })
    if (fields && !Array.isArray(fields)) {
      throw new TypeError('fields must be an array')
    }
    super.setItems(fields)
  }

  /**
   * Set the fields from object key/value eg: {  name: 'joe', email: 'joe@joe.com' }
   * @param {object} fields key/value object map
   */
  @action setFieldValues(fields) {
    Object.entries(fields).forEach(([ name, value ]) => {
      this.setFieldValue(name, value)
    })
  }

  @action setFieldValue(name, value) {
    const field = this.getFieldByName(name)
    if (field) field.value = value
  }

  @action setFieldItems(name, items) {
    debug('setting field items', { name, items })
    const field = this.getFieldByName(name)
    if (field) {
      field.items = items
      debug('set field items', { field, items })
    }
  }

  /**
   * Retrieve key/value object of fields
   * @return {object{[key: string]: any}}
   */
  getFieldValues() {
    return this.fields
      .filter(({ enumerable }) => enumerable !== false)
      .reduce((curr, { name, value }) => {
        curr[name] = value
        return curr
      }, {})
  }

  /**
   * Retrieve key/value object of field errors
   * @return {object{[key: string]: any}}
   */
  getFieldErrors() {
    return this.fields
      .filter(({ enumerable }) => enumerable !== false)
      .filter(field => field.hasErrors)
      .map(field => field.errors)
      .reduce((errorMap, { name, errors }) => {
        errorMap[name] = errors
        return errorMap
      }, {})
  }

  /**
   * @return {boolean}
   */
  hasErrors() {
    return this.fields.find(field => field.hasErrors)
  }
  
  /**
   * Field errors are arrays showing multiple errors
   * @param {string} name 
   * @param {array} errors
   */
  @action setFieldErrors(name, errors) {
    this.getFieldByName(name).setErrors(errors)
  }

  /**
   * Errors must be in the form { fields: { name: [error, error], ... }}
   * @param {object} error 
   */
  @action setFormValidationErrors(error = {}) {
    Object.entries(error.fields).forEach(([name, errors]) => {
      this.setFieldErrors(name, errors)
    })
  }

  /**
   * Validate fields using the validate() on each field
   * @return {Array< Array<Error> >}
   */
  @action validateFields() {
    return this.fields.forEach(field => {
      if (field.validate) {
        debug('validating field', field)
        const validation = field.validate(field.value)
        const errors = Array.isArray(validation) ? validation : ( validation ? [ validation ] : [] )
        field.setErrors(errors)
      }
    })
  }

}

/**
   * Generate a validation function. 
   * @param {function} validator returns bool false on fail
   * @param {Error} error the error to return when validation fails
   */
  export function createValidator(validator, error) {
    return (...args) => validator(...args) ? false : error
  }
