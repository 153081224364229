export const data = [
  {
    "day": "",
    "Work Added": 0,
    "Work AddedColor": "#1f5e99",
    "Work Remaining": 0,
    "Work RemainingColor": "#4592c2",
    "Work Completed": 0,
    "Work CompletedColor": "#cbeab6",
    "Total Completed": 0, 
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
    "Work RemovedColor": "#40742E"
  },
  {
    "day": "",
    "Work Added": 0,
    "Work AddedColor": "#1f5e99",
    "Work Remaining": 0,
    "Work RemainingColor": "#4592c2",
    "Work Completed": 0,
    "Work CompletedColor": "#cbeab6",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Added": 0,
    "Work AddedColor": "#1f5e99",
    "Work Remaining": 0,
    "Work RemainingColor": "#4592c2",
    "Work Completed": 0,
    "Work CompletedColor": "#cbeab6",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Added": 0,
    "Work AddedColor": "#1f5e99",
    "Work Remaining": 0,
    "Work RemainingColor": "#4592c2",
    "Work Completed": 0,
    "Work CompletedColor": "#cbeab6",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Added": 0,
    "Work AddedColor": "#1f5e99",
    "Work Remaining": 0,
    "Work RemainingColor": "#4592c2",
    "Work Completed": 0,
    "Work CompletedColor": "#cbeab6",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  {
    "day": "",
    "Work Forecast Completed": 0,
    "Work Forecast CompletedColor": "#D3D5D6",
    "Work Forecast Remaining": 0,
    "Work Forecast RemainingColor": "#AEB0B3",
    "Total Completed": 0,
    "Total CompletedColor": "rgba(255, 255, 255, 0)",
  },
  
  
]

export default data