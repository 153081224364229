import { Component } from "react";
import { aryIanaTimeZones } from "./timezones";
import Select from "theme/Form/Select";
import { CalendarEvent } from "Stores/Calendar";
import { observer } from "mobx-react";

type EventTimezoneProps = {
  event: CalendarEvent;
  placeholder?: string;
};

const timezoneOptions = aryIanaTimeZones.map((tz) => ({
  value: tz,
  label: tz,
}));

class EventTimezone extends Component<EventTimezoneProps> {
  static defaultProps = {
    placeholder: "Choose Timezone",
  };

  findTimezoneOption = (timezone: string) => {
    return timezoneOptions.find((option) => option.value === timezone);
  };

  onChange = (option: { name: string; value: string }) => {
    const { event } = this.props;
    console.log("change", option);
    event.setTimezone(option.value);
    event.willSave();
  };

  render() {
    const { event, placeholder } = this.props;
    return (
      <Select
        placeholder={placeholder}
        value={this.findTimezoneOption(event.timezone) || ""}
        onChange={this.onChange}
        options={timezoneOptions}
      />
    );
  }
}

export default observer(EventTimezone);
