import { CalendarEvent } from 'Stores/Calendar';
import CalendarEventList from '../Calendar/CalendarEventList';
import { action, override } from 'mobx';

export default class ActionPlannerEventList extends CalendarEventList {

  setItems(events: CalendarEvent[]) {
    super.setItems(events)
  }

  @override fetch(date: Date) {
    return this.fetchState.get('calendar/planner/events?startDate=' + new Date(date).getTime() + '&numDays=1')
      .then((resp:any) => this.setItems(resp.data.events))
  }

  @action fetchWeek(date: Date) {
    return this.fetchState.get('calendar/planner/events?dateStart=' + new Date(date).getTime() + '&numDays=7')
      .then((resp:any) => this.setItems(resp.data.events))
  }

}
