import GoalList from './GoalList'
import GoalItem from './GoalItem'
import { observable, override } from 'mobx'
import { TeamItem } from 'Stores/Team'
import { OrganizationItem } from 'Stores/Organization'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class GoalSubGoalList extends GoalList {

  get ModelType() {
    return GoalItem
  }

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.subgoals)
  goal:GoalItem

  @observable team:TeamItem = TeamItem.create()

  @observable organization:OrganizationItem = OrganizationItem.create()

  @override async fetch() {
    const goalId = this.goal && this.goal.id
    const teamId = this.team && this.team.id
    const organizationId = this.organization && this.organization.id
    return this.fetchState.get('goal/list', { goalId, teamId, organizationId })
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}
