import React from 'react'
import FilterFieldAssignedMembers from './Fields/FilterFieldAssignedMembers'
import FilterFieldCompletion from './Fields/FilterFieldCompletion'
import FilterFieldDueDate from './Fields/FilterFieldDueDate'
import FilterFieldDuration from './Fields/FilterFieldDuration'
import FilterFieldOnPlanner from './Fields/FilterFieldOnPlanner'
import FilterFieldParent from './Fields/FilterFieldParent'

export const ListViewFilterRows = [
  {
    label: 'Completion',
    name: 'completion',
    Component: props => <FilterFieldCompletion {...props} />
  },
  {
    label: 'Assigned To',
    name: 'assigned',
    isMulti: true,
    Component: props => <FilterFieldAssignedMembers {...props} />
  },
  {
    label: 'Action Planner',
    name: 'onPlanner',
    Component: props => <FilterFieldOnPlanner {...props} />
  },
  {
    label: 'Due',
    name: 'dueDate',
    Component: props => <FilterFieldDueDate {...props} />
  },
  {
    label: 'Parent',
    name: 'parent',
    isMulti: true,
    Component: props => <FilterFieldParent {...props} />
  },
  {
    label: 'Duration',
    name: 'duration',
    Component: props => <FilterFieldDuration {...props} />
  }
]