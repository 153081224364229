import React from "react";
import { observer } from "mobx-react";

export class AccomplishTab extends React.Component {
  static defaultProps = {};

  render() {

    return (
      <div className="pfa-day-tab">
      </div>
    );
  }
}

export default observer(AccomplishTab)
