const timeSpanBucket = [
  {
    type: 'sleep',
    title: 'Sleep',
    duration: 60,
    color: '#999999',
    canHaveItems: false,
    canMove: false,
  },
  {
    type: 'personal',
    title: 'Personal AM',
    duration: 60,
    color: '#e8f7fc',
  },
  {
    type: 'work',
    title: 'Work',
    duration: 60,
    color: '#f5f5f5',
  },
  {
    type: 'personal',
    title: 'Personal PM',
    duration: 60,
    color: '#e8f7fc',
  },
]

const emptyTimeSpan = {
  id: 'empty',
  type: 'empty',
  title: 'Empty',
  duration: 1*60,
  color: '#fff',
}

const timeSpanItems = [
  { ...timeSpanBucket[0], duration: 5.5*60 },
  { ...timeSpanBucket[1], duration: 2.5*60 },
  { ...timeSpanBucket[2], duration: 10*60 },
  { ...timeSpanBucket[3], duration: 4*60, canMove: false  },
  { ...timeSpanBucket[0], duration: 2*60, canMove: false },
]

const days = [
  {
    dayIndex: 0,
    dayId: 'Sun',
    title: 'Sunday',
  },
  {
    dayIndex: 1,
    dayId: 'Mon',
    title: 'Monday',
  },
  {
    dayIndex: 2,
    dayId: 'Tue',
    title: 'Tuesday',
  },
  {
    dayIndex: 3,
    dayId: 'Wed',
    title: 'Wednesday',
  },
  {
    dayIndex: 4,
    dayId: 'Thu',
    title: 'Thursday',
  },
  {
    dayIndex: 5,
    dayId: 'Fri',
    title: 'Friday',
  },
  {
    dayIndex: 6,
    dayId: 'Sat',
    title: 'Saturday',
  }
]

days.forEach(day => {
  day.id = day.dayId
  day.items = timeSpanItems.map(item => ({ ...item, dayId: day.dayId }))
})

// debugging
global.timeSpanItems = timeSpanItems

export {
  timeSpanItems,
  emptyTimeSpan,
  timeSpanBucket,
  days,
}
export default days