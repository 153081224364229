import React from 'react'
import Icon from 'theme/Icon'

export const RemoveIcon = () => {
  return (
    <Icon
      set="fa"
      icon={'times'}
      size={'1x'}
      className="icon-remove"
    />
  )
}