import Model from "Stores/Model";
import { observable } from "mobx";
import { getStore, models } from "Stores";
import MqttLiveSyncClient from "Stores/Model/MqttLiveSync/Client";

export class MQTTState extends Model {

  @observable mqttSyncClient: MqttLiveSyncClient

  constructor() {
    super()
    const session = getStore(models.Session) as any
    const { token } = session
    const { id, email } = session.Account
    this.mqttSyncClient = new MqttLiveSyncClient()
    this.mqttSyncClient.connect({ id, email, token })
  }

  getClient() {
    return this.mqttSyncClient
  }

}

export const getMQTTState = (): MQTTState => getStore<MQTTState>(MQTTState)