import { TreksApi } from "SubModules/treks-ui-models/services/api/TreksApi";
import { getAuthToken, saveAuthToken, getTokenHeader, createGetParams, createPostParams } from "SubModules/treks-ui-models/services/api/request";

export const treksApi = new TreksApi();

export const createUrl = treksApi.createUrl.bind(treksApi);
export const getBaseUrl = treksApi.getBaseUrl.bind(treksApi);
export const fetch = treksApi.fetch.bind(treksApi);
export const post = treksApi.post.bind(treksApi);
export const get = treksApi.get.bind(treksApi);
export const download = treksApi.download.bind(treksApi);

export { getAuthToken, saveAuthToken, getTokenHeader, createGetParams, createPostParams };

export default treksApi;

global['treksApi'] = treksApi; // debugging