import { Api } from '../../services/api'
import ApiRequest from './ApiRequest'

const billingApiBaseUrl = process.env.REACT_APP_BILLING_API_URL 
  || `http://localhost:8001/authorize.net/`;

/**
 * Wraps API configured for Billing API Url
 */
export default class BillingApiRequest extends ApiRequest {

  /**
   * @property {api}
   */
  api = new Api({ baseUrl: billingApiBaseUrl })

  constructor(api?:Api) {
    super()
    if (api) {
      this.api = api
    }
  }

}
