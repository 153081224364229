import React from 'react'
import { observer } from 'mobx-react'
import ProjectTaskStatusAssignmentItem from './ProjectTaskStatusAssignmentItem'
import PropTypes from 'prop-types'
import ProjectSettings from 'Stores/Project/ProjectSettings'
import AssignmentList from 'Stores/Project/ProjectTaskStatusAssignmentList'
import { ProjectItem } from 'Stores/Project'
import { SortablePane, Pane } from 'react-sortable-pane'

const debug = require('debug')('treks:project:assignments:list')

class ProjectTaskStatusAssignmentList extends React.Component {

  panesRef = null

  static propTypes = {
    settings: PropTypes.objectOf(() => ProjectSettings).isRequired,
    assignments: PropTypes.objectOf(() => AssignmentList).isRequired,
    project: PropTypes.objectOf(() => ProjectItem).isRequired
  }

  componentDidUpdate() {
    this.fixDynamicPanesPosition()
  }

  /**
   * className __resizable_base__ is removed to fix issue
   * @see https://github.com/bokuweb/react-sortable-pane/issues/226
   */
  fixDynamicPanesPosition() {
    const { panesWrapper } = this.panesRef
    const els = panesWrapper.querySelectorAll(':scope > .__resizable_base__')
    Array.from(els).forEach(rzBase => panesWrapper.removeChild(rzBase))
  }

  onChangeStatus = (assignment, status) => {
    assignment.status = status
  }

  render() {
    const { assignments, settings } = this.props
    debug('render', { assignments })
    return (
      <div className="list-view assignment-list" style={{ height: assignments.items.length * 30 }}>
        <SortablePane
            ref={ref => this.panesRef = ref}
            disableEffect={true}
            className="task-assign-panes"
            dragHandleClassName="icon-drag"
            direction="vertical"
          >
          {
            assignments.items.map(assignment => {
              return (
                <Pane 
                  resizable={false}
                  key={assignment.status}
                >
                  <ProjectTaskStatusAssignmentItem
                    assignment={assignment}
                    settings={settings}
                    onChangeStatus={this.onChangeStatus}
                  />
                </Pane>
              )
            })
          }
        </SortablePane>
      </div>
    )
  }
}

export default observer(ProjectTaskStatusAssignmentList)
