import React from 'react'
import { observer } from 'mobx-react';
import Select from './Select'
import colorStyles from './ColorStyles';
import { composeStyles } from './SelectStyles';

class ColoredSelect extends React.Component {

  render() {
    const { props } = this
    const styles = composeStyles(props.styles, colorStyles)
    return (
      <Select
        {...props}
        styles={styles}
      /> 
    )
  }
}

export default observer(ColoredSelect)