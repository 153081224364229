import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import './styles/RangeSlider.scss'
import './styles/DoubleRangeSlider.scss'

class DoubleRangeSlider extends React.Component {

  static defaultProps = {
    range: [0, 100]
  }

  static propTypes = {
    range: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func.isRequired,
    containerStyle: PropTypes.object,
    className: PropTypes.string
  }

  rangeElLow = null
  rangeElHigh = null

  onChange = () => {
    const low = Number(this.rangeElLow.value)
    const high = Number(this.rangeElHigh.value)
    this.props.onChange([ low, high ])
  }

  render() {
    const { range, containerStyle, className } = this.props
    const low = Math.min(...range)
    const high = Math.max(...range)

    return (
      <div
        className={`range-slider-container range-slider-double ${className ? className : ''}`}
        style={containerStyle}
      >
        <div
          className='range-start'
          style={{
            width: low + '%',
           }}
        ></div>
        <div
          className='range-fill'
          style={{
            width: (high - low) + '%',
            marginLeft: low + '%'
           }}
        ></div>
        <div
          className='range-end'
          style={{
            width: high + '%',
            marginLeft: high + '%'
           }}
        ></div>
        <div
          className='range-value'
          style={{
            left: range[0] + '%'
          }}
        >
          {range[0]}%
        </div>
        <div
          className='range-value'
          style={{
            left: range[1] + '%'
          }}
        >
          {range[1]}%
        </div>
        <input
          type='range'
          ref={ref => this.rangeElLow = ref}
          value={range[0]}
          onChange={this.onChange}
        />
        <input
          type='range'
          ref={ref => this.rangeElHigh = ref}
          value={range[1]}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default observer(DoubleRangeSlider)