import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Icon from 'theme/Icon'
import './ProjectTasks.scss'
import ProjectTasksSort from './ProjectTasksSort'
import DoneFilter from 'theme/List/DoneFilter'
import ListViewTaskCategoryList from 'Components/ListView/ListViewTasks/ListViewTaskCategoryList'

const debug = require('debug')('treks:project:tasks')

class ProjectTasks extends React.Component {

  store = observable({
    view: localStorage.getItem('project.tasks.view') || 'all',
    order: localStorage.getItem('project.tasks.order'),
  })

  onSetView = view => {
    this.store.view = view
    localStorage.setItem('project.tasks.view', view)
  }

  onSetOrder = order => {
    this.store.order = order
    localStorage.setItem('project.tasks.order', order)
  }

  componentDidMount() {
    this.loadTasks()
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.loadTasks()
    }
  }

  loadTasks() {
    const { subTasksList } = this.props.project
    subTasksList.fetch()
  }

  render() {
    const { project, list } = this.props
    const { view, order } = this.store
    console.log('render', { project, list, view, order })
    global.focusedProject = project
    return (
      <div className="project-tasks">
        <header className="row tab-header">
          <Icon icon="task" size={15} />
          <div className="tab-title">TASKS</div>
          <DoneFilter view={view} onChange={this.onSetView} />
          <ProjectTasksSort order={order} onSetOrder={this.onSetOrder} />
        </header>
        <div className="container">
          <ListViewTaskCategoryList
            showHeader={false}
            item={project}
            categoryList={project.categoryList}
            view={view}
            order={order}
            filter={{}}
            fields={[]}
          />
        </div>
      </div>
    )
  }
}

export default observer(ProjectTasks)