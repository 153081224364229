import React from 'react'
import { observer } from 'mobx-react'
import { reaction } from 'mobx';
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import { uid } from 'Stores/utils';

const debug = require('debug')('treks:task:duration')

class DetailDuration extends React.Component {

  inputRef = null
  reaction = null

  static defaultProps = {
    keyDownEnabled: true,
    isEditable: true,
    disabled: false
  }

  store = ArbitraryModel.fromProps({
    isFocused: false,
    inputValue: 0
  })

  componentDidMount() {
    this.store.setState({
      inputValue: this.props.item.duration
    })
    this.reaction = reaction(
      () => this.props.item.duration, 
      duration => this.store.setState({
        inputValue: duration
      })
    )
  }

  componentWillUnmount() {
    if (this.reaction && this.reaction.dispose) {
      this.reaction.dispose()
    }
  }

  onChange = event => {
    debug('duration changed', event)
    if (!this.props.isEditable) return
    this.store.setState({
      inputValue: event.target.value
    })
  }

  onBlur = event => {
    const { item, onBlur, isEditable } = this.props
    if (!isEditable) return
    const inputDuration = parseFloat(this.store.inputValue)
    const minDuration = Math.max(inputDuration, item.minDuration)
    item.setDuration(minDuration)
    this.store.setState({ isFocused: false, inputValue: item.duration })
    onBlur && onBlur(event, item)
    debug('focus off', { item, inputDuration, minDuration })
  }

  onFocus = event => {
    const { item, onFocus } = this.props
    const { nativeEvent } = event
    debug('focus on', item)
    onFocus && onFocus(event, item)
    setTimeout(() => nativeEvent.target.select(), 50)
    this.store.setState({ isFocused: true })
  }

  /**
   * @todo move to DetailItem
   */
  onKeyDown = event => {
    const { item, keyDownEnabled, isEditable } = this.props
    debug('keyDown', item, event.key)
    if (!keyDownEnabled || !isEditable) return
    if (event.key === 'Enter') {
      event.stopPropagation()
      this.editNextEmptyItemOrAddOne()
    }
    if (event.key === 'Backspace' && !item.title) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
  }

  editNextEmptyItemOrAddOne() {
    debug('add item')
    const { list } = this.props
    if (!list) return
    const item = list.findFirstItemWithEmptyTitle() || list.addItem(this.createEmptyItem())
    item && item.setFocusOnTitle()
  }

  createEmptyItem() {
    const { list } = this.props
    const { focusedTimespan } = list
    return {
      id: uid(),
      title: '',
      duration: 15,
      onPlanner: true,
      list,
      timespan: focusedTimespan
    }
  }

  removeItem() {
    this.props.list.removeItem(this.props.item)
  }

  focusOnPrevOrNextItemTitle() {
    const prevItem = this.props.list.getPrevItem(this.props.item)
    const nextItem = this.props.list.getNextItem(this.props.item)
    debug('focus on prev or next ', { prevItem, nextItem })
    if (prevItem) {
      prevItem.setFocusOnTitle()
    } else if (nextItem) {
      nextItem.setFocusOnTitle()
    }
  }

  render() {
    const { item, disabled, className } = this.props
    const { done, durationFormatted } = item
    const { isFocused, inputValue } = this.store
    debug('render', item)
    const value = isFocused ? inputValue : durationFormatted
    const classNames = [ done && 'done',  className ]
      .filter(val => val)
      .join(' ')
    return (
      <input
        ref={ref => this.inputRef = ref}
        className={"duration duration-input input-borderless " + classNames}
        value={ value } 
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        disabled={disabled ? 'disabled' : ''}
      />
    )
  }
}

export default observer(DetailDuration)