import React from 'react'
import stores, { getStore } from 'Stores'

function timeSpanBucket() {
  return getStore(stores.ActionPlanner).dayList.bucket
}

function setTimespanType(timespan, type) {
  timespan.type = type
}

export default class CreateTimespanMenu extends React.Component {

  static defaultProps = {
    menuItems: timeSpanBucket().items.map(timespan => {
      const props = timespan.toJSON()
      return {
        ...props,
        onClick: ({ timespan }) => setTimespanType(timespan, props.type)
      }
    })
  }

  onDocumentClick = () => {
    this.props.onHide && this.props.onHide()
  }

  async componentDidMount() {
    const { dayList } = this.props
    document.addEventListener('click', this.onDocumentClick)
    await dayList.bucket.fetched()
  }

  componentDidUnMount() {
    document.removeEventListener('click', this.onDocumentClick)
  }

  onMenuItemClick = (event, menuItem) => {
    const { timespan, onHide } = this.props
    event.preventDefault()
    menuItem.onClick({ timespan, event, menuItem, self: this })
    onHide && onHide()
  }

  render() {
    const { menuItems } = this.props
    return (
      <div 
        style={{
          position: 'absolute',
          top: 'calc(50% - 90px)',
          left: 'calc(50% - 100px)',
          zIndex: 10000
        }}
        x-placement="bottom-start"
        aria-labelledby="dropdown-basic"
        className="dropdown-menu show"
      >
        <header style={{ padding: 10, fontWeight: 'bold' }}>
          {'Choose timespan type'}
        </header>
        {
          menuItems.map(menuItem => {
            return (
              <a
                key={menuItem.type}
                href={'#' + menuItem.type}
                className="dropdown-item"
                onClick={event => this.onMenuItemClick(event, menuItem)}
              >
                {menuItem.title}
              </a>
            )
          })
        }
      </div>
    )
  }
}