import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Pressable from 'theme/Pressable'
import { PFAModalContainer } from 'Containers/PFA'
import PFAModalModel from 'Containers/PFA/models/PFAModalModel'
import { getPFAModalStore } from 'Containers/PFA/stores/PFAModalStore'
import PFAScoreCard from './PFAScoreCard'
import { PFAScoreModel } from 'Containers/PFA/models/PFAScore/PFAScoreModel'
import { getPFAScoreStore } from 'Containers/PFA/stores/PFAScoreStore'

const debug = require('debug')('treks:planner:header:pfa')

export default observer(class PFAScoreWidget extends React.Component {

  static propTypes = {
    pfaScoreStore: PropTypes.instanceOf(PFAScoreModel),
    pfaScoreGoal: PropTypes.number,
    pfaModalStore: PropTypes.instanceOf(PFAModalModel)
  }

  static defaultProps = {
    pfaScoreStore: getPFAScoreStore(),
    pfaScoreGoal: 75,
    styles: {
      container: {}
    },
    pfaModalStore: getPFAModalStore(),
  }

  onClick = () => {
    this.props.pfaModalStore.show()
  }

  render() {
    const { pfaScoreStore, pfaScoreGoal, styles } = this.props
    const { pfaScore } = pfaScoreStore
    debug('render', this.props)
    const pfaColor = pfaScore >= pfaScoreGoal ? '#58bb66' : '#eb2a2e'
    return (
      <Pressable
        className={'action-planner-header-pfa'}
        onClick={this.onClick}
        style={styles?.container}
      >
        <div
          className='pfa-scale'
          style={{
            position: 'relative',
            height: 15,
            width: 150,
            maxWidth: '90%', 
            backgroundColor: '#ccc',
            borderRadius: 5,
          }}
        >
          <div
            className='pfa-slider-container'
            style={{
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 5,
            }}
          >
            <div 
              className='pfa-scale-slider'
              style={{
                height: 15,
                width: `${pfaScore}%`,
                background: pfaColor,
                transition: 'all 0.5s',
                borderRadius: '5px 0 0 5px',
              }}
            ></div>
          </div>
          <div
            className='pfa-goal-indicator'
            style={{
              position: 'absolute',
              top: -10,
              left: `calc(${pfaScoreGoal}% - 8px)`,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '10px 8px 0 8px',
              borderColor: '#ccc transparent transparent transparent',
              transition: 'all 0.5s',
            }}
          ></div>
        </div>
        <PFAScoreCard
          pfaColor={pfaColor}
          pfaScore={pfaScore}
        />
        <PFAModalContainer />
      </Pressable>
    )
  }

})
