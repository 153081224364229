import React from 'react'
import './App.css'
import './AppError.scss'
import * as Sentry from '@sentry/browser'

export default class AppErrorScreen extends React.Component {

  render() {
    const { eventId } = this.props

    return (
      <div className="main error-screen">
          <div className="container">
            <div className="col">
              <h3>An error occurred loading the Application</h3>
              <p>
                We have logged the error and will look into it shortly.
              </p>
              <div>
                { 
                  eventId && (
                    <button
                      className="btn btn-primary"
                      onClick={() => Sentry.showReportDialog({ eventId })}
                    >
                      Report feedback
                    </button>
                  )
                }
              </div>
            </div>
          </div>
      </div>
    )
  }
}