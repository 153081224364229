import React from 'react'
import PropTypes from 'prop-types'
import CheckBoxList from './FieldCheckBoxList'

export default class FilterFieldOnPlanner extends React.Component {

  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: 'onPlanner',
    label: 'Action Planner',
    options: [
      {
        id: 'on_planner',
        label: 'On Planner',
        value: 'true'
      },
      {
        id: 'not_on_planner',
        label: 'Not On Planner',
        value: 'false'
      },
      {
        id: 'all',
        label: 'All',
        value: 'all'
      }
    ]
  }

  onChange = value => {
    const { name, label, onChange } = this.props
    onChange({ name, label, value })
  }

  render() {
    const { options, name, value } = this.props
    return (
      <CheckBoxList
        name={name}
        options={options}
        onChange={this.onChange}
        value={value}
      />
     )
  }
}