import React from 'react'
import { observer } from 'mobx-react'
import GoalDataItem from './GoalDataItem'
import PropTypes from 'prop-types'
import Table from 'theme/Table';
import { GoalDataList } from 'Stores/Goal/GoalData'

class GoalDataListCom extends React.Component {

  headers = [
    'Date',
    'Value',
    'Units',
    ''
  ]

  static propTypes = {
    data: PropTypes.objectOf(() => GoalDataList).isRequired,
  }

  render() {
    const { data } = this.props
    return (
      <Table className="data-list striped">
        <thead>
          <tr>
            {
              this.headers.map(header => (
                <td key={header}>{header}</td>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {data.visibleItems.map(item => (
            <GoalDataItem key={item.uid} item={item} />
          ))}
        </tbody>
      </Table>
    )
  }
}

export default observer(GoalDataListCom)
