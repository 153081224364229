import { observable, action, computed, override } from 'mobx'
import List from '../Lists/List'
import { ItemI, ItemPropsI } from './Type/Item'
import { ListItemsI, OrderedListI } from './Type/List'
import { Item } from '.'

const debug = require('debug')('treks:store:list:ordered')

export default class OrderedList extends List implements OrderedListI {

  static ignoredProps = [ 
    'orderedItems',
    ...List.ignoredProps,
    ...Model.ignoredProps  
  ]

  @override get visibleItems(): Item[] {
    return this.orderedItems.filter(item => !item.trashed && !item.deleted)
  }

  @computed get orderedItems(): ListItemsI {
    const orderedItems = this.order
      .filter(uid => uid) // remove undef
      .map(uid => this.getItemByUid(uid))
    // this.order may be incomplete, append all items to the end
    const allItems = [ ...orderedItems, ...this.items ]
    // filter unique so we only append missed items
    const uniqueOrdered = allItems
      .filter((item, index) => index === allItems.indexOf(item))
      .filter(item => item) // remove empty
    return uniqueOrdered
  }

  /**
   * @note Use getOrder() for the actual order
   */
  @computed get order(): string[] {
    const order = this.getAttribute('order', () => {
      const uids = this.items.map(item => item.uid)
      return observable([ ...uids ])
    })
    return order
  }
  set order(_) {
    throw new Error('Use setOrder() to prevent deleting this.order reference')
  }

  /**
   * @param {array} Ordered Array of item ids
   * @important Do not overwrite this.order reference
   */
  @action setOrder(order: string[] = []) {
    const uniqueOrder = order.filter((item, index) => order.indexOf(item) === index)
    this.order.splice(0, this.order.length, ...uniqueOrder)
  }

  /**
   * Order snapshot
   * @note Use getOrder instead of this.order directly
   * @note this.order is internal
   */
  getOrder(): string[] {
    const order = this.orderedItems
      .map(item => item && item.uid)
      .filter(uid => uid)
    return order
  }

  getItemByIndex(index: number): ItemI {
    if (index < 0) {
      throw new Error('Index must be larger than zero')
    }
    const order = this.getOrder()
    const uid = order[index]
    if (uid) return this.getItemByUid(uid)
  }

  getItemIndex(item: ItemI): number {
    return this.getOrder().indexOf(item.uid)
  }

  getPrevItem(item: ItemI): ItemI {
    const prevIndex = this.getItemIndex(item) - 1
    if (prevIndex > 0 && prevIndex < this.orderedItems.length) {
      return this.getItemByIndex(prevIndex)
    }
  }

  getNextItem(item: ItemI): ItemI {
    const nextIndex = this.getItemIndex(item) + 1
    if (nextIndex > 0 && nextIndex < this.orderedItems.length) {
      return this.getItemByIndex(nextIndex)
    }
  }

  @action moveItem(props: ItemI, newIndex: number) {
    const list = this
    const item = this.getItemByUid(props.uid as string)
    if (!item) {
      throw new Error('This list does not contain item defined by props.uid')
    }
    if (list !== item.list) {
      console.warn('OrderedList list mismatch', { item, list })
    }
    const { uid } = item
    const order = this.getOrder()
    const currIndex = order.indexOf(uid)
    debug('moveItem', { uid, currIndex, newIndex, order })
    order.splice(currIndex, 1)
    order.splice(newIndex, 0, uid)
    this.setOrder(order)
    debug('moveItem done', { uid, currIndex, newIndex, order: list.order, getOrder: list.getOrder(),  })
  }

  @override addItem(props: ItemPropsI, index: number = this.getOrder().length): ItemI {
    const order = this.getOrder() // get order first before add
    const item = super.addItem(props, index) // add at index to support list.items indexed order
    const { uid } = item
    debug('order before splice', order, { index })
    order.splice(index, 0, uid)
    debug('new order', order)
    this.setOrder(order)
    debug('addItem', { props, index, uid, order })
    return item
  }

  @override removeItem(item: ItemI): ItemI {
    const order = this.getOrder()
    const orderIndex = order.indexOf(item.uid)
    // @note do not use this.getItemIndex() which returns order index
    const itemIndex = this.items.findIndex(model => item.uid === model.uid)
    if (orderIndex === -1 || itemIndex === -1) return
    order.splice(orderIndex, 1)
    this.setOrder(order)
    return this.items.splice(itemIndex, 1)[0]
  }

  toJSON() {
    return {
      ...super.toJSON(),
      items: this.orderedItems.map(item => item.toJSON())
    }
  }

  @computed get saveOrderKey(): string {
    const url = 'list/order/' + this.modelName + '/' + this.id
    return url
  }

  async getSavedOrder(): Promise<string[]> {
    const order = await this.localState.get(this.saveOrderKey)
    return order as string[] || []
  }

  async fetchOrder(): Promise<this> {
    const order = await this.getSavedOrder()
    if (order) this.setOrder(order as string[])
    debug('fetchOrder', { model: this, orderKey: this.saveOrderKey, order })
    return this
  }

  async saveOrder(): Promise<this> {
    const order = this.getOrder()
    debug('saveOrder', { model: this, orderKey: this.saveOrderKey, order })
    await this.localState.set(this.saveOrderKey, order)
    return this
  }

}