import { observable, computed } from 'mobx'
import OrderedList from 'Stores/Lists/OrderedList'
import { dateAddDays, numDaysBetweenDates } from '../ActionPlannerUtils'
import { DayFlow } from './DayFlow'
import { PlannerFlow } from './PlannerFlow'

const debug = require('debug')('treks:store:DayListFlow')

export class DayListFlow extends OrderedList {

  get ModelType(): typeof DayFlow {
    return DayFlow
  }

  @observable plannerFlow: PlannerFlow = null

  get items(): DayFlow[] {
    const { plannerFlow } = this
    return [...Array(this.daysLength)].map((_, index) => {
      const dayDate = dateAddDays(this.startDate, index)
      const dayKey = dayDate.getTime()
      const day = DayFlow.fromProps({ uid: dayKey, dayDate, plannerFlow }) as DayFlow
      return day
    })
  }

  get presentDays(): DayFlow[] {
    return this.items.filter(day => {
      return day.dayDate >= this.plannerFlow.todayDate
    })
  }

  @computed get startDate(): Date {
    return this.plannerFlow.startDate
  }
  
  @computed get endDate(): Date {
    return this.plannerFlow.endDate
  }

  @computed get daysLength(): number {
    return Math.floor(numDaysBetweenDates(this.startDate, this.endDate))
  }

}