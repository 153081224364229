import Model from "Stores/Model";
import { getKeyPressState } from "./KeypressState";
import { observable } from "mobx";
import { getStore } from "Stores";
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";

export class AppUiState extends Model {

  constructor(...args: any[]) {
    super(...args)
  }

  @observable keyPressState = getKeyPressState()

  /**
   * Which plannerFlow is being used
   */
  @observable plannerFlow: PlannerFlow = null

}

export const getAppUiState = (): AppUiState => getStore(AppUiState)