import React from 'react'
import { observer } from 'mobx-react'
import MainPaneGroup from '../../Components/MainPaneGroup'
import { AccountPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { getStore, models } from 'Stores'

const debug = require('debug')('treks:container:account')

class AccountContainer extends React.Component {

  get store() {
    return getStore(models.MainPaneGroup)
  }

  componentDidMount() {
    //this.store.setItems([ AccountPane ])
  }

  render() {
    debug('render', AccountPane)
    return (
      <MainPaneGroup store={this.store} />
    )
  }
}

export default observer(AccountContainer)