import React from "react";
import PropTypes from "prop-types"
import { observer } from "mobx-react";
import Icon from "theme/Icon";
import { getPFASettingsStore } from "Containers/PFA/stores/PFASettingsStore";
import pfaEnglishLang from "./lang/english";
import DatePagination from "./widgets/DatePagination/DatePagination";
import PFAScoreCard from "Widgets/PFA/PFAScore/PFAScoreCard";
import Tabs from "theme/Tabs";
import { DayTabItems } from "./data/DayTabItems";
import { getPFADayTabsStore } from "./stores/DayTabsStore";
import { PFAGraph } from "./graph/pfa/PFAGraph";
import './PFADayView.scss'
import { getPFAScoreStore } from "Containers/PFA/stores/PFAScoreStore";
import { PFAScoreModel } from "Containers/PFA/models/PFAScore/PFAScoreModel";

export class PFADayView extends React.Component {

  static propTypes = {
    pfaScoreStore: PropTypes.instanceOf(PFAScoreModel),
    pfaScoreGoal: PropTypes.number,
  }

  static defaultProps = {
    pfaScoreStore: getPFAScoreStore(),
    pfaScoreGoal: 75,
    title: "DAY",
    pfaSettingsStore: getPFASettingsStore(),
    lang: pfaEnglishLang,
    isTargetProductivityEnabled: false,
    SettingsIcon: () => <Icon icon="settings" />,
    dayTabsStore: getPFADayTabsStore(),
    defaultTabKey: 'plan'
  };

  componentDidMount() {
    this.props.dayTabsStore.setTabs(DayTabItems)
    this.mountDefaultTab()
  }

  mountDefaultTab() {
    const { dayTabsStore } = this.props
    if (!dayTabsStore.activeTabKey) {
      dayTabsStore.setActiveTabKey(this.props.defaultTabKey)
    }
    this.onSelectTab(dayTabsStore.activeTabKey)
  }

  onSelectTab = (tabKey) => {
    this.props.dayTabsStore.setActiveTabKey(tabKey)
  }

  render() {
    const { dayTabsStore, pfaScoreGoal, pfaScoreStore } = this.props;
    const { pfaScore } = pfaScoreStore
    const pfaColor = pfaScore >= pfaScoreGoal ? '#58bb66' : '#eb2a2e'
    const ActiveComponent = dayTabsStore.activeComponent

    return (
      <div className="pfa-day-view pfa-content-view">
        <header>
          <DatePagination />
        </header>
        <div className="pfa-view-content">
          <div className="content-main">
            <div className="main-score-card">
                <PFAScoreCard 
                  pfaColor={pfaColor}
                  pfaScore={pfaScore}
                />
            </div>
            <div className="main-graph">
              <PFAGraph pfaScoreStore={pfaScoreStore} />
            </div>
          </div>
          <div className="content-footer">
            <Tabs
              className='pfa-content-tabs'
              tabs={[ ...dayTabsStore.tabs ]}
              onSelect={this.onSelectTab}
              activeKey={dayTabsStore.activeTabKey}
            />
            <ActiveComponent pfaScoreStore={pfaScoreStore} />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(PFADayView)
