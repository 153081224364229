import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalComments.scss'
import { observable } from 'mobx';
import GoalCommentList from './GoalCommentList'
import GoalAddComment from './GoalAddComment'
import CommentList from 'Stores/Comment/CommentList'
import { models, getStore } from 'Stores'
import { getAppNotifications } from 'AppNotifications'
import { uid } from 'Stores/utils'

const debug = require('debug')('treks:goal:comments')

class GoalComments extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static defaultProps = {
    comments: CommentList.fromItems([]),
    get account() {
      return getStore(models.Session).Account
    }
  }

  addComment = ({ title, text }) => {
    if (!title || !text) {
      getAppNotifications().warn('The comment title and text are required')
      return false
    }
    const item = {
      user: this.props.account,
      id: uid(),
      title,
      comment: text
    }
    debug('adding comment', item)
    this.props.comments.addItem(item)
    return true
  }

  render() {
    const { goal, comments } = this.props
    debug('render', { goal, comments })
    return (
      <div className="goal-comments">
        <header className="row tab-header">
          <Icon icon="comment" size={15} />
          <div className="tab-title">Comments</div>
        </header>
        <div className="goal-comments-container">
          <GoalAddComment onAddComment={this.addComment} />
          <GoalCommentList goal={goal} comments={comments} />
        </div>
      </div>
    )
  }
}

export default observer(GoalComments)
