import React from 'react'
import { observer } from 'mobx-react'
import Button from '../../theme/Button'
import PressableIcon from '../../theme/Pressable/PressableIcon';
import stores, { getStore, models } from 'Stores';
import { withRouter } from 'react-router-dom';
import { getDayPlanner } from 'Stores/ActionPlanner/Planner/DayPlanner';
import { getMouseClickState } from 'uiState/MouseClickState';

const debug = require('debug')('treks:planner:footer')

const btnTaskTitle = 'Task (enter)'

class ActionPlannerFooter extends React.Component {

  static defaultProps = {
    classNamePrefix: 'day-planner-footer',
    size: { 
      width: '100%'
    },
    get actionPlannerStore() {
      return getStore(stores.ActionPlanner)
    },
    get plannerUi() {
      return getDayPlanner()
    },
    getPaneEl() {
      return document.querySelector('.main-pane-component:has(.action-planner)')
    }
  }

  get plannerFlow() {
    return this.props.plannerUi.plannerFlow
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  onKeyPress = event => {
    const mouseClick = getMouseClickState()
    const target = mouseClick.mouseEvent?.target
    const clickedOnPane = () => this.props.getPaneEl().contains(target)
    if (event.key === 'Enter' && event.target === document.body && clickedOnPane()) {  
      this.addTask()
    }
  }

  addTask = () => {
    const { actionPlannerStore } = this.props
    const { focusedTimespan, focusedTask, timeFocusedTimespan } = actionPlannerStore

    let task
    // timespan priority over task so we can add to a task to a different timespan
    if (focusedTimespan && focusedTask?.timespanType !== focusedTimespan.type) {
      debug('adding task to timespan', { focusedTimespan })
      task = actionPlannerStore.addEmptyItem({
        timespanType: focusedTimespan.type
      })
    } else if (focusedTask) {
      debug('adding task after', { focusedTask })
      task = actionPlannerStore.addEmptyItemAfterTask(focusedTask)
    } else {
      debug('adding task at to time focused timespan', { timeFocusedTimespan })
      task = actionPlannerStore.addEmptyItem({
        timespanType: timeFocusedTimespan?.type
      })
    }
    debug('add task', { task, ts: task.timespanType})
    // we need set focused task 
    // we need store for planner
    // @todo fix for hidden tasks (not in autoscroll area)
    task.setFocusOnTitle()
    task.save()
    task.list.saveOrder()
    debug('addTask', { task, focusedTask })
  }

  onClickSettings = () => {
    // @todo fix full reload so we clear out timespans
    window.location = '/planner/settings'
  }

  get isCollapsed() {
    return this.props.plannerUi.isUiCollapsed
  }

  onToggleCollapse = () => {
    const { onToggleCollapse } = this.props
    if (onToggleCollapse) return onToggleCollapse()
  }

  render() {
    const { size, pos, item, classNamePrefix } = this.props
    debug('render planner footer', { size, pos, item })
    return (
      <footer
        className={"action-planner-footer main-pane-footer " + classNamePrefix}
        style={{
          ...size,
          ...pos
        }}
      >
        <Button 
          variant="outline-dark" 
          size="xs"
          onClick={this.addTask}
        >{btnTaskTitle}</Button>
        <Button variant="outline-dark" size="xs">Event (ctrl+e)</Button>
        <Button variant="outline-dark" size="xs">Break (ctrl+b)</Button>
        
        <div className="footer-btns">
          <PressableIcon
            icon={this.isCollapsed ? 'expand' : 'collapse'}
            size="xs"
            onClick={this.onToggleCollapse} 
          />
          <PressableIcon icon="print" size="xs" />
          <PressableIcon icon="no_alert" size="xs" />
          <PressableIcon
            icon="settings"
            size="xs"
            style={{ marginLeft: 'auto' }}
            onClick={this.onClickSettings}
          />
        </div>
      </footer>
    )
  }
}

export default withRouter(observer(ActionPlannerFooter))