import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import './AccountSettings.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Avatar from 'theme/Avatar'
import AccountAvatarEditor from './AccountAvatarEditor'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import ProfileForm from './Stores/ProfileForm'
import { FormField } from '../../../theme/Form'
import { getAppNotifications } from '../../../AppNotifications'
import { once } from 'Stores/utils'
import { getStore, models } from 'Stores'

const debug = require('debug')('treks:account:profile')

class AccountSettings extends React.Component {

  photoEditor = observable({
    previewImage: null,
    show: false,
  })

  get profileForm() {
    return once(() => ProfileForm.create())
  }

  get account() {
    return getStore(models.Account)
  }

  onChange = (field, value) => {
    debug('change', { field, value })
    this.account[field.name] = value
    debug('account', this.account)
  }

  onSubmit(event) {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.account.edit()
      .then(() => getAppNotifications().success('Account updated'))
      .catch(error => getAppNotifications().error(error))
  }

  onCropPhoto = image => {
    debug('crop image', { image })
    this.photoEditor.previewImage = image
  }

  onSavePhoto = image => {
    debug('save image', { image })
    this.popoverRef && this.popoverRef.hide()
    this.photoEditor.previewImage = image
    this.account.profileImage = image
    this.account.edit()
      .then(() => getAppNotifications().success('Profile image updated'))
      .catch(error => getAppNotifications().error(error))
  }

  onDeletePhoto = image => {
    debug('delete image', { image })
    this.popoverRef && this.popoverRef.hide()
    this.photoEditor.previewImage = null
    this.account.profileImage = null
    this.account.edit()
      .then(() => getAppNotifications().success('Profile image deleted'))
      .catch(error => getAppNotifications().error(error))
  }

  onShowPhotoEditor = () => {
    debug('enter photo editor', this.photoEditor)
    this.photoEditor.show = true
  }

  onHidePhotoEditor = () => {
    debug('exit photo editor', this.photoEditor)
    this.photoEditor.show = false
  }

  render() {
    const { account, profileForm } = this
    debug('render', { photoEditor: this.photoEditor, account, profileForm })

    const PhotoEditOverlay =  (
      <Popover 
        id="account-avatar-editor-popover"
      >
        <AccountAvatarEditor 
          onCrop={this.onCropPhoto} 
          onSave={this.onSavePhoto}
        />
      </Popover>
    )

    const avatarBtnsStyle = {
      opacity: (this.photoEditor.show ? 1 : '')
    }

    return (
      <Container className="settings account-settings">
        <Row className="account-settings-row">
          <Col>
            <Form onSubmit={this.onSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Profile Photo</Form.Label>
                <div className="form-avatar">
                  <Avatar
                    name={account.name}
                    src={this.photoEditor.previewImage || account.profileImage}
                    size={38}
                  />
                  <div className="form-avatar-btns" style={avatarBtnsStyle}>
                    <OverlayTrigger 
                      trigger="click"
                      placement="bottom"
                      overlay={PhotoEditOverlay}
                      rootClose={true}
                      ref={ref => this.popoverRef = ref}
                      onEnter={this.onShowPhotoEditor}
                      onExit={this.onHidePhotoEditor}
                    >
                      <Button variant="outline">Change Photo</Button>
                    </OverlayTrigger>
                    <div className="btn-sep"> </div>
                    <Button variant="outline" onClick={this.onDeletePhoto}>Remove</Button>
                  </div>
                </div>
              </Form.Group>
              
              {
                profileForm.fields.map(field => {
                  return (
                    <FormField 
                      key={field.name}
                      {...field} 
                      value={account[field.name]} 
                      onChange={value => this.onChange(field, value)}
                    />
                  )
                })
              }

              <Form.Group controlId="formOtherEmails">
                <Form.Label>Other Email</Form.Label>
                <div className="settings-table">
                  <div className="settings-list other-email-list">
                    {
                      account.emailList && account.emailList.map && account.emailList.map(email => (
                        <div key={email}>{email}</div>
                      ))
                    }
                  </div>
                  <div className="settings-list other-email-btns">
                    <Button variant="outline" onClick={this.changePlan}>Add Email</Button>
                    <Button variant="outline" onClick={this.changePlan}>Remove Email</Button>
                  </div>
                </div>
              </Form.Group>

              

              <Form.Group controlId="formOtherEmails">
                <Button variant="primary" type="submit">
                  Update Profile
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default observer(AccountSettings)