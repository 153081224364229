import React from 'react'
import { observer } from 'mobx-react'
import { RemoveBtn } from 'theme/RemoveBtn/RemoveBtn'
import { Position, getAppNotifications } from 'AppNotifications'

export const RemoveBtnField = observer(({ item, list, fields }) => {
  const onPress = () => {
    console.log('press', { item, list, fields })
    getAppNotifications().warn({
      message: `Do you want to delete the task "${item.title}"?`,
      position: Position.TOP,
      icon: "ban-circle",
      action: {
        onClick: () => {
          item.trash()
          item.save()
          getAppNotifications().error(`The task "${item.title}" was deleted`)
        },
        text: "DELETE",
      },
      undo: {
        text: 'Cancel'
      }
    })
  }
  return (
    <div className="attribute attribute-remove">
      <RemoveBtn onPress={onPress} />
    </div>
  )
})