import React from 'react'
import { observer } from 'mobx-react';
import FormControlCheckBox from '../../../theme/Form/FormControlCheckBox';
import { autorun } from 'mobx';
import TreksRecapture from '../../../Modules/TreksRecapture';
import recaptchaConfig from '../../../Config/RecaptchaConfig'
import { getStore, models } from 'Stores';

const debug = require('debug')('treks:account:register:footer')

class AccountRegisterFooter extends React.Component {

  accountRegisterStore = getStore(models.AccountRegister)
  sessionStore = getStore(models.Session)
  accountStore = this.sessionStore.Account


  static defaultProps = {
    hideIfPrevAgreed: true
  }

  async componentDidMount() {
    autorun(() => this.accountRegisterStore.agreedToTOS = !!this.accountStore.agreedToTOS)
    this.sessionStore.isAuthenticated()
      .catch(err => debug('Auth error', err))
    this.getRecaptchaToken('register')
      .then(token => {
        this.accountRegisterStore.recaptchaToken = token
      })
      .catch(err => debug('failed recaptcha token', err))
  }

  async getRecaptchaToken(action) {
    const recaptcha = new TreksRecapture(recaptchaConfig)
    const token = await recaptcha.getToken(action)
    debug('recaptcha token', token) 
    return token
  }

  onChangeCheckbox = value => {
    this.accountRegisterStore.agreedToTOS = value
    debug('checkbox', value)
  }

  alreadyAgreedToTOS() {
    return this.accountStore.agreedToTOS
  }

  isLoaded() {
    return this.sessionStore.isAuthReqComplete
  }

  render() {
    if (!this.isLoaded()) return null
    if (this.props.hideIfPrevAgreed && this.alreadyAgreedToTOS()) return null
    return (
      <footer className="footer">
        <FormControlCheckBox
          id="tos"
          onChange={this.onChangeCheckbox}
          checked={this.accountRegisterStore.agreedToTOS}
        />
        <label htmlFor="tos">
          By registering you agree to the&nbsp;
          <a href="#terms">Treks terms of service</a> and <a href="#privacy">privacy policy</a>  
        </label>.
      </footer>
    )
  }
}

export default observer(AccountRegisterFooter)