import React from 'react'
import { observer } from 'mobx-react'
import { TaskField } from './TaskField'

export const TaskCategoryField = observer(({ item, fields }) => {
  const backgroundColor = item.category?.color
  const style = backgroundColor ? { backgroundColor } : {}
  return (
    <TaskField
      item={item}
      fieldName={'category'}
      fields={fields}
      style={style}
      noGroupLabel={'Uncategorized'}
    />
  )
})