import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DraggableDemo from './DraggableDemo'

const debug = require('debug')('treks:docs:draggable:index')

export const Draggable = () => {
  debug('render route')
  return (
    <Switch>
      <Route path="/docs/draggable" component={DraggableDemo} />
    </Switch>
  )
}
export default Draggable