import Model from "Stores/Model";
import { getModelMapByKey } from "./RelationshipMap";

type RelationNote = {
  RelType: typeof Model,
  RefTypeFn: () => Model,
  ref: () => Model,
  key: string,
  descriptor: {}
}

const initKey = Symbol('relationships-initialized')
const descriptorKey = Symbol('relationship.descriptor')

function isRelationshipsInitialized(model: Model) {
  return model[initKey]
}

function setRelationshipInitialized(model: Model) {
  model[initKey] = true
}

export function getRelationMap(model: Model): Map<string, RelationNote> {
  return getModelMapByKey(model.constructor as typeof Model, descriptorKey) as Map<string, RelationNote>
}

export function initializeRelationships(model: Model) {
  if (isRelationshipsInitialized(model)) return
  getRelationMap(model).forEach((relation, key) => {
    Object.defineProperty(model, key as string, relation.descriptor)
  })
  setRelationshipInitialized(model)
}