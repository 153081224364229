import { action, computed, observable, override } from "mobx";
import Model from "Stores/Model";
import { Item } from "Stores/Lists";
import { once } from "Stores/utils";
import { MainPaneTabList } from "./MainPaneTabList";

const debug = require("debug")("treks:store:MainPaneItem");

type PaneOpenInOptions =
  | []
  | [
      | "pane" // prefer existing pane or new
      | "tab" // prefer existing tab or new
      | "browser-tab" // prefer existing browser tab or new
      | "new-pane" // always new pane - unless panes full
      | "new-tab" // always new tab - unless tabs full
      | "new-browser-tab" // always new browser tab
    ];

type percentage = number;

export default class MainPaneItem extends Item {
  
  public list: MainPaneTabList;

  @observable getInitialProps: () => object = () => ({})

  @observable ref: HTMLElement;

  @observable icon: string;

  @observable slug: string;

  @observable name: string;

  @observable title: string;

  @observable type: string;

  @observable x: number = 0;

  @observable y: number = 0;

  @observable w: number = 0;

  @observable width: percentage;

  @observable isResizing: boolean;

  @observable component: any;

  @observable footer: any;

  @observable colSpan: number = 1;

  @observable openIn: PaneOpenInOptions = [];

  @observable resizable: boolean = true;

  @observable modelId: string | number;

  @observable active: boolean;

  @observable activeTab: number = -1; // @todo deprecated in favor of focusedTab

  @computed get tabList(): MainPaneTabList {
    return this.getAttribute('tabList', () => MainPaneTabList.fromProps({ bucket: this.bucket })) as MainPaneTabList
  }

  @observable isPendingFocusOnTab: boolean;

  @observable focusDate: Date

  @computed get focusTimestamp(): number {
    return this.focusDate?.getTime() || 0
  }

  @computed get focusedTab(): MainPaneItem {
    return this.lastFocusedTab
  }

  @action setFocusedTab(props: Partial<MainPaneItem>) {
    const pane = this.tabList.getItemByUid(props?.uid) as MainPaneItem
    if (pane) {
      pane.focusDate = new Date()
      this.scrollToTab(pane)
    }
  }

  @computed get tabsOrderedByLastFocus() {
    return [ ...this.tabs ]
    .sort(
      (a, b) => a.focusTimestamp -  b.focusTimestamp
    )
    .reverse()
  }

  @computed get lastFocusedTab() {
    return this.tabsOrderedByLastFocus[0]
  }

  @computed get url(): string {
    return "/" + this.slug;
  }

  @action setWidth(width: number) {
    this.width = width;
    if (this.tabs.length) {
      this.scrollToTabImmediate(this.focusedTab);
    }
  }

  @computed get tabElementId(): string {
    return "main-pane-tab-" + this.uid;
  }

  getTabElement = (tab: MainPaneItem): HTMLElement => {
    return document.querySelector("#" + tab.tabElementId);
  };

  scrollToTabImmediate(tab: MainPaneItem) {
    const tabEl = this.getTabElement(tab);
    if (tabEl) tabEl.parentElement.scrollLeft = tabEl.offsetLeft;
  }

  /**
   * @todo fix bug scrolling to second element on mousemove
   */
  scrollToTab(tab: MainPaneItem) {
    this.isPendingFocusOnTab = true;
    setTimeout(() => {
      const tabEl = this.getTabElement(tab);
      global.tab = tabEl // @todo remove dev
      console.log('scrolling to tab', tabEl)
      tabEl?.parentElement.scrollTo({
        left: tabEl.offsetLeft,
        // behavior: "smooth", // causes bug where second element is scrolled to on mousemove
      });
      setTimeout(() => (this.isPendingFocusOnTab = false), 1000); // after scroll animation
    }, 200); // after tab dom render
  }

  @computed get tabs(): MainPaneItem[] {
    return [...this.tabList.items];
  }

  hasTab(tab: MainPaneItem): boolean {
    return !!this.getTab(tab);
  }

  getTab({ slug }: MainPaneItem): MainPaneItem {
    return this.tabs.find((tab) => tab.slug === slug);
  }

  canAddTab(pane: MainPaneItem): boolean {
    if (this.tabList.colSpan < this.colSpan) {
      return false // fix can't add tabs to planner colSpan=2
    }
    return this.tabList.canAddTab(pane);
  }

  @action addTab(pane: MainPaneItem) {
    if (pane === this) return; // prevent recursion
    if (this.tabs.length === 0) {
      this.tabList.addTab(this); // intial tab is self
    }
    this.tabList.addItem(pane);
    this.setFocusedTab(pane); // focus on added tab
    return pane;
  }

  @action removeTab(pane: MainPaneItem) {
    this.tabList.removeItem(pane);
    if (this.tabList.items.length === 0) {
      this.list?.removeItem(this); // since we added this as initial tab
    }
  }

  @computed get minWidth(): percentage {
    return this.colSpan === 3 || this.siblings.length === 1
      ? 100
      : (this.colSpan / 4) * 100;
  }

  @computed get maxWidth(): percentage {
    return this.colSpan === 3 || this.siblings.length === 1
      ? 100
      : ((this.colSpan + 1) / 4) * 100;
  }

  @computed get siblings(): Item[] {
    return this.list?.itemsOrdered || [];
  }

  @override setProps(props) {
    if (props instanceof Model) {
      props = props.toJSON();
    }
    if (props.focusedTabUid) {
      const focusedTab = this.tabList.getItemByUid(props.focusedTabUid)
      focusedTab && this.setFocusedTab(focusedTab)
      delete props.focusedTabUid
    }
    return super.setProps(props);
  }

  /**
   * Model within pane
   * @param {string} model id
   */
  setModelId(modelId: string | number) {
    this.modelId = modelId;
  }

  @override fromJSON(data: any): MainPaneItem {
    if (data.uid) {
      this.setProp('uid', data.uid)
    }
    this.setProps({
      x: data.x,
      w: data.w,
      type: data.type,
      name: data.name,
      title: data.title,
      slug: data.slug,
      icon: data.icon,
      width: data.width,
      modelId: data.modelId,
      activeTab: data.activeTab,
    });
    this.tabList.fromJSON(data.tabList);
    if (data.focusedTabUid) {
      const focusedTab = this.tabList.getItemByUid(data.focusedTabUid)
      focusedTab && this.setFocusedTab(focusedTab)
    }
    debug("fromJSON", this.name, this.uid, { data, self: this });
    return this;
  }

  toJSON(): any {
    const json = {
      uid: this.uid,
      x: this.x,
      w: this.w,
      type: this.type,
      name: this.name,
      title: this.title,
      slug: this.slug,
      icon: this.icon,
      width: this.width,
      modelId: this.modelId,
      tabList: this.tabList.toJSON(),
      activeTab: this.activeTab,
      focusedTabUid: this.focusedTab?.uid,
    };
    debug("toJSON", json);
    return json;
  }
}

// @deprecated
export const importMainPaneItem: MainPaneItem = once(() => {
  return MainPaneItem;
});
