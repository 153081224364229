import { property } from "Stores/Model/ModelDecorators";
import Calendar from "Stores/Calendar";
import Model from "Stores/Model";
import TaskItem from "Stores/Task";

export class TaskToEventState extends Model {

  // show select calendar for Task -> Event
  @property showCalendarModal = false

  @property selectedTask: TaskItem = null

  @property selectedCaledar: Calendar = null

  @property redirectToSettings = false

}


export const createTasktoEventState = (props?: Partial<TaskToEventState>) => TaskToEventState.fromProps(props) as TaskToEventState
