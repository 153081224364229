import List from '../Lists/List'
import MeetingItem from '../Meeting/MeetingItem';
import { override } from 'mobx';
import { GoalItem } from 'Stores/Goal';
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class MeetingList extends List {

  get ModelType() {
    return MeetingItem
  }

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.meetings)
  goal: GoalItem

  /**
   * Filter by done view string (falsy|all|done|not-done)
   * @param view {string}
   */
  filterItemsByDoneView(view:string = 'all') {
    return this.items.filter(item => {
      return !view || view === 'all' 
        || (item.done && view === 'done') 
        || (!item.done && view === 'not-done')
    })
  }

  @override async fetch() {
    return await this.fetchState.get('meeting/list')
  }

}