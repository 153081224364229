import { observable } from "mobx";
import { once } from "Stores/utils";
import { HabitItem, InstanceItem } from ".";
import { Item } from "../Lists";
import PlanList from "./PlanList";

export const importPlanItem = once(() => {
  class PlanItem extends Item {
    get ListType() {
      return PlanList;
    }

    @observable budget: Number;
    @observable date: Number;
    @observable habit: HabitItem;
    @observable instance: InstanceItem;

    async save() {
      return this.saveState.postJson(
        "habit/plan/save/" + this.habit.id,
        this.toJSON()
      );
    }
  }

  return PlanItem;
});
