import { observable } from 'mobx'
import { hasOne } from 'Relationships/RelationshipDecorators';
import { User } from 'Stores/User/Type/User';
import { Item } from '../Lists'
import { importUser} from '../User'
import CommentItem from './CommentItem';

export default class CommentFollowerItem extends Item {

  @observable user:User = importUser().create()

	@hasOne(() => CommentItem)
  comment:CommentItem

  @observable createDate:Date|undefined

}