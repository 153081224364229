import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import PopOver from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import { getAppNotifications } from '../../AppNotifications'
import './TaskDueDate/TaskDueDate.scss'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import DatePicker from 'Modules/DatePicker'

const debug = require('debug')('treks:task:due-date')

function dateHasTime(date) {
  return date  && date.getHours() !== 0 || date.getMinutes() !== 0 || date.getSeconds() !== 0 || date.getMilliseconds() !== 0;
}

class TaskDueDate extends React.Component {

  inputRef = null
  containerRef = null

  static defaultProps = {
    iconClearDate: 'times',
    iconClearDateColor: '#808285',
    dueDateLabel: 'Set due date'
  }

  state = {
    showTooltip: false,
    inputValue: null,
    showDueTime: false
  }

  hideTooltip() {
    this.setState({ showTooltip: false })
  }

  setInputValue(inputValue) {
    this.setState({ inputValue })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset state when new item
    if (nextProps.item !== this.props.item) {
      this.setState({
        inputValue: null,
        showDueTime: false,
        showTooltip: false
      });
    }
  }

  componentDidUpdate() {
    if (this.state.showTooltip) {
      document.addEventListener('click', this.onClickApp)
    } else {
      document.removeEventListener('click', this.onClickApp)
    }
  }

  onClickApp = event => {
    console.log('onClickApp', event)
    const path = event.path || event.composedPath()
    if (
      path 
      && !path.includes(this.containerRef) 
      && !path.includes(document.querySelector('.bs-popover-bottom'))
    ) {
      debug('path', path)
      this.hideTooltip()
    }
  }

  onInputRef = ref => this.inputRef = ref

  onInputChange = event => {
    this.setInputValue(event.target.value)
  }

  onInputFocus = () => {
    this.setState({ showTooltip: true })
  }

  onInputBlur = () => {
    const dueMoment = moment(this.state.inputValue)
    if (this.state.inputValue && !dueMoment.isValid()) {
      return getAppNotifications().warn({
        message: 'Please enter a valid date',
      })
    }
    const dueDate = this.state.inputValue ? dueMoment.toDate() : null
    debug('save task with due date', { dueDate, dueMoment })
    this.props.item.setProp('dueDate', dueDate)
    this.props.item.save()
  }

  onChangeDate = dueDate => {
    const { item, list } = this.props
    const time = moment(item.dueDate).isValid() 
      ? list.getTimeInMinutesFromDate(item.dueDate) : 0
    debug('due date changed', dueDate, time, item.dueDate)
    dueDate = new Date(dueDate.setHours(0, time))
    this.onChange(dueDate)
  }

  onChangeTime = dueDate => this.onChange(dueDate)

  onChange = dueDate => {
    this.syncDueDateAndInputValue(dueDate)
    debug('due date or time changed', dueDate)
    this.hideTooltip()
  }

  syncDueDateAndInputValue(dueDate) {
    const { list, item } = this.props
    const inputValue = list.formatDateTime(dueDate)
    item.setProp('dueDate', dueDate)
    item.save()
    this.setInputValue(inputValue)
  }

  // @todo implement in UI
  clearDueDateAndInputValue() {
    this.props.item.setProp('dueDate', null)
    this.props.item.save()
    this.setInputValue('')
  }

  onClickContainer = event => {
    debug('clicked', event)
    event.stopPropagation()
    event.preventDefault()
  }

  onContainerRef = ref => {
    this.containerRef = ref
  }

  onShowDueTimePicker = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showDueTime : true })
  }

  onHideDueTimePicker = event => {
    const { dueDate } = this.props.item
    event.preventDefault()
    event.stopPropagation()
    if (this.isDateValid(dueDate)) {
      this.syncDueDateAndInputValue(this.removeTimeFromDate(dueDate))
    }
    this.setState({ showDueTime : false })
  }

  removeTimeFromDate(date) {
    return new Date(date.setHours(0, 0, 0, 0))
  }

  isDateValid(date) {
    return moment(date).isValid()
  }

  onClickClearDueDate = event => {
    event.preventDefault()
    event.stopPropagation()
    this.clearDueDateAndInputValue()
  }

  render() {
    const { item, list, iconClearDate, iconClearDateColor, dueDateLabel } = this.props
    const { dueDate } = item
    const dueDateIsValid = this.isDateValid(dueDate)
    const { showDueTime } = this.state
    const dueDateFormatted = dueDateIsValid ? list.formatDateTime(dueDate) : ''
    debug('render', { item, dueDate })
    return (
      <div className="task-due-date" onClick={this.onClickContainer} ref={this.onContainerRef}>
        <input
          ref={this.onInputRef}
          className="due-date input-borderless"
          value={this.state.inputValue !== null ? this.state.inputValue : dueDateFormatted}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          placeholder={dueDateLabel}
        />
        {
          dueDate && (
            <Pressable onClick={this.onClickClearDueDate} className="row-icon task-clear-dueDate-icon">
              <Icon set="fa" name={iconClearDate} color={iconClearDateColor} />
            </Pressable>
          )
        }
        <Overlay target={this.inputRef} show={!!this.state.showTooltip} placement="bottom">
          {props => (
            <PopOver {...props} show="true" className='task-duedate-tooltip'>
              <DatePicker
                selected={dueDateIsValid ? dueDate : new Date()}
                onChange={this.onChangeDate}
                inline
              />
              {
                showDueTime || dateHasTime(new Date(dueDate)) ? (
                  <div className="dueTimePicker">
                    <h6 className="dueTimeLabel">Due Time</h6>
                    <div className='dueTimeRow'>
                      <DatePicker
                        selected={moment(dueDate).isValid() ? dueDate : new Date()}
                        onChange={this.onChangeTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                      <Pressable onClick={this.onHideDueTimePicker} className="row-icon btn-close-duetime">
                        <Icon set="fa" name={iconClearDate} color={iconClearDateColor} />
                      </Pressable>
                    </div>
                  </div>
                ) : (
                  <div className="dueTimePicker" style={{ justifyContent: 'center' }}>
                    <Pressable onClick={this.onShowDueTimePicker}>Add Due Time</Pressable>
                  </div>
                )
              }
            </PopOver>
          )}
        </Overlay>
      </div>
    )
  }
}

export default observer(TaskDueDate)