import React from 'react';
import { observer } from 'mobx-react';
import { HabitPane } from 'Stores/MainPaneGroup/data/MainPaneItems';
import { getStore, models } from 'Stores';
import Habit from 'Components/Habit';

const debug = require('debug')('treks:container:habit');

class HabitContainer extends React.Component {
    static defaultProps = {
        get habitList() {
            return getStore(models.HabitList);
        },
        get store() {
            return getStore(models.MainPaneGroup);
        },
    };

    getHabit() {
        const { habitList, store } = this.props;
        const pane = store.getItemBySlug(HabitPane.slug);
        const habit = pane && pane.modelId && habitList.getItemById(1);
        return habit;
    }

    setHabit(habit) {
        const paneItem = this.props.store.addOrUpdateItem(HabitPane);
        paneItem.setModelId(habit.id);
    }

    async componentDidMount() {
        const { habitList } = this.props;
        await habitList.fetched();
    }

    render() {
        const { habitList } = this.props;
        const habit = this.getHabit();
        debug('render', { habitList, habit });
        if (!habit) {
            return <div style={{ padding: 10 }}>Please choose a habit</div>;
        }
        return (
            <>
                <Habit habit={habit} habitList={habitList} />
            </>
        );
    }
}

export default observer(HabitContainer)
