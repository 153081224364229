import React from 'react'
import { observer } from 'mobx-react'
import GoalHabitItem from './GoalHabitItem'
import PropTypes from 'prop-types'
import HabitList from 'Stores/Habit/HabitList'
import Table from 'theme/Table';

class GoalHabitList extends React.Component {

  headers = [
    '',
    'Habit',
    'Roll Up Data',
    'Type',
    'Today',
    'Plan',
  ]

  static propTypes = {
    habits: PropTypes.objectOf(() => HabitList).isRequired,
  }

  async componentDidMount() {
    this.props.habits.fetch()
  }

  async componentDidMUpdate(prevProps) {
    if (this.props.habits !== prevProps.habits) {
      this.props.habits.fetch()
    }
  }

  render() {
    const { habits, goal } = this.props
    return (
      <Table className="habit-list striped">
        <thead>
          <tr>
            {
              this.headers.map(header => (
                <td key={header}>{header}</td>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {habits.items.map(habit => (
            <GoalHabitItem key={habit.id} habit={habit} goal={goal} />
          ))}
        </tbody>
      </Table>
    )
  }
}

export default observer(GoalHabitList)
