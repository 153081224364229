import { getPFAPlanScoreStore } from "Containers/PFA/stores/PFAPlanScoreStore";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import Icon from "theme/Icon";
import Pressable from "theme/Pressable";
import "./DatePagination.scss"

class DatePagination extends React.Component {

  static defaultProps = {
    pfaPlanScoreStore: getPFAPlanScoreStore() 
  }

  get actionPlannerStore() {
    return this.props.pfaPlanScoreStore.actionPlanner
  }

  get dateLabel() {
    return moment(this.actionPlannerStore.focusedDate)
      .format(this.actionPlannerStore.opts.dateFormat)
  }

  setFocusedDate(date) {
    this.actionPlannerStore.setFocusedDate(date)
    const time = this.actionPlannerStore.focusedDate.getTime()
    this.props.history.push('/day-planner/' + time + this.props.location.hash)
  }

  onClickPrevDay = () => {
    this.setFocusedDate(moment(this.actionPlannerStore.focusedDate).subtract(1, 'day'))
  }

  onClickNextDay = () => {
    this.setFocusedDate(moment(this.actionPlannerStore.focusedDate).add(1, 'day'))
  }

  render() {
    return (
      <nav className={'date-pagination-container'}>
        <Pressable onClick={this.onClickPrevDay}>
          <Icon set="fa" icon="chevron-left" size="xs" color={'#808284'} />
        </Pressable>
        <h2 className="label">{this.dateLabel}</h2>
        <Pressable onClick={this.onClickNextDay}>
          <Icon set="fa" icon="chevron-right" size="xs" color={'#808284'} />
        </Pressable>
      </nav>
    )
  }
}

export default withRouter(observer(DatePagination))