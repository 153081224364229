export const dayTimespans = [
  {
    type: 'sleep',
    duration: 5.5*60,
  },
  {
    type: 'personal',
    duration: 2.5*60,
  },
  {
    type: 'work',
    duration: 10*60,
  },
  {
    type: 'personal',
    duration: 4*60,
  },
  {
    type: 'sleep',
    duration: 2*60
  },
]