import React from 'react'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import stores, { getStore } from 'Stores'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:register:verify')

const headerText = 'Thank-you for choosing TREKS!'
const InfoText = ({ email }) => (
  <>
    <p>We sent you an activation email at {email}.</p>
    <p>Please check your inbox and verify
your email to complete your registration.</p>
  </>
)
const HelpText = () => (
  <>
    <p>If you have any questions, please contact us.</p>
    <p>If you don’t see the email in your inbox, try having us resend the email or check your spam filter.</p>
  </>
)
const btnResendLabel = 'Resend Email'
const failedSendingEmailMsg = 'An error occurred re-sending your verification email'
const successSendingEmailMsg = 'We have resent your verification email'

export default withRouter(class AccountVerityEmail extends React.Component {

  accountRegisterStore = getStore(stores.AccountRegister)

  onClickResendEmail = () => {
    const email = this.accountRegisterStore.email || this.props.match.params.email
    this.sendVerificationEmail(email)
  }

  sendVerificationEmail(email) {
    return this.accountRegisterStore.sendVerificationEmail(email)
      .then(() => {
        getAppNotifications().success(successSendingEmailMsg)
      })
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedSendingEmailMsg)
      })
  }

  render() {
    const { accountRegisterStore } = this
    const { params } = this.props.match
    const email = accountRegisterStore.email || params.email
    debug('render', { accountRegisterStore, props: this.props })
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              <InfoText email={email} />
            </div>
            <div className="help-text">
              <HelpText email={email} />
            </div>
            <Button 
              className="btn-resend-email"
              onClick={this.onClickResendEmail} 
              variant="outline"
            >
              {btnResendLabel}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
})