import React from 'react'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import { withRouter } from 'react-router-dom'
import './GoalTabs.css'
import Tabs from 'theme/Tabs'
import GoalTasks from './GoalTasks'
import GoalPeople from './GoalPeople'
import GoalComments from './GoalComments'
import GoalDetails from './GoalDetails'
import GoalMeetings from './GoalMeetings'
import GoalMetrics from './GoalMetrics'
import GoalHabits from './GoalHabits/GoalHabits';
import GoalSubGoals from './GoalSubGoals';
import GoalData from './GoalData';
import { LocalStorageRequest } from 'Stores/Service'

const debug = require('debug')('treks:goal:tabs')

class GoalTabs extends React.Component {

  @observable activeTab = null
  @observable localState = new LocalStorageRequest(this.constructor.name)

  constructor() {
    super()
    makeObservable(this)
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.onWindowUpdate)
    window.addEventListener('resize', this.onWindowUpdate)
    this.activeTab = this.getSelectedTabKeyFromUrl() || (await this.localState.get('activeTab')) || 'details'
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowUpdate)
    window.removeEventListener('resize', this.onWindowUpdate)
  }

  onWindowUpdate = () => this.forceUpdate()

  components = {
    details: ({ goal }) => <GoalDetails goal={goal} />,
    habits: ({ goal }) => <GoalHabits goal={goal} />,
    subgoals: ({ goal }) => <GoalSubGoals goal={goal} />,
    data: ({ goal }) => <GoalData goal={goal} />,
    tasks: ({ goal }) => <GoalTasks goal={goal} />,
    people: ({ goal }) => <GoalPeople goal={goal} />,
    meetings: ({ goal }) => <GoalMeetings goal={goal} />,
    comments: ({ goal }) => <GoalComments goal={goal} />,
    metrics: ({ goal }) => <GoalMetrics goal={goal} />
  }

  tabs = Object.keys(this.components).map(label => ({ 
    label: label.toUpperCase(), 
    key: label.toLowerCase(),
  }))

  wizardTabs = {
    plan: ['details', 'habits', 'subgoals', 'tasks', 'people', 'meetings'],
    execute: ['data', 'subgoals', 'tasks', 'comments', 'files'],
    analyze: ['metrics', 'history']
  }

  getTabComponent(tab) {
    return this.components[tab]
  }

  getSelectedTabKeyFromUrl() {
    const { history } = this.props
    const matches = history.location.pathname.match(/^\/goal\/tab\/([a-z]+)$/)
    return matches && matches[1]
  }

  onSelect = key => {
    this.activeTab = key
    this.localState.set('activeTab', key)
    // @todo compose routes into <MainPane> so we don't reload when url changes
    //this.props.history.push('/goal/tab/' + key)
    debug('selected tab', { key })
  }

  getTabHeight() {
    if (!this.tabRef) return 'calc( 100vh )'
    const box = this.tabRef.getBoundingClientRect()
    return window.innerHeight - box.top
  }

  render() {
    const { goal, focusedWizardTabName } = this.props

    const visibleTabNames = this.wizardTabs[focusedWizardTabName || 'plan']
    
    const visibleTabs = visibleTabNames
      .map(name => {
        return this.tabs.find(tab => tab.key === name)
      })
      .filter(tab => tab) // @todo all tabs

    const activeTab = visibleTabNames.includes(this.activeTab)
      ? this.activeTab
      : visibleTabNames[0]

    const tabStyles = {
      height: this.getTabHeight(),
      overflow: 'auto'
    }
    const Tab = this.getTabComponent(activeTab)
    debug('render', { activeTab, goal, Tab })
    return (
      <>
        <Tabs
          className="goal-tabs"
          variant="pills"
          activeKey={activeTab}
          onSelect={this.onSelect}
          tabs={visibleTabs}
        />
        <div
          className="tab container-scrollable md-light-scrollbar"
          style={tabStyles}
          ref={ref => this.tabRef = ref}
        >
          {
            Tab ? <Tab goal={goal} /> : null
          }
        </div>
      </>
    )
  }
}

export default withRouter(observer(GoalTabs))
