import React from 'react'
import { observer } from 'mobx-react'
import './StatusAssignLabel.scss'
import Form from 'react-bootstrap/Form'
import { observable } from 'mobx';
import ProjectTaskStatusAssignment from '../ProjectTaskStatusAssignment'

const debug = require('debug')('treks:project:settings:label:status-assign')

class StatusAssignLabel extends React.Component {

  store = observable({
    project: null
  })

  onFieldChangeAssignTasksToTeam = () => {
    this.props.settings.assignTasksToTeam = !this.props.settings.assignTasksToTeam
  }

  onFieldChangeAssignDurationToStatus = () => {
    this.props.settings.assignDurationToStatus = !this.props.settings.assignDurationToStatus
  }

  render() {
    const { field, settings, project } = this.props
    const { tasksStatusBased, assignTasksToTeam, assignDurationToStatus } = settings
    debug('render', { field, settings })
    return (
      <div className="project-status-assign-label">
        <div className="project-settings-label">Tasks are Status based by default</div>
        <div className="list-row row-flex">
          <Form.Check
            className="list-col"
            checked={assignTasksToTeam}
            onChange={this.onFieldChangeAssignTasksToTeam}
            disabled={!tasksStatusBased}
          />
          <div className="list-col">Assign tasks to a default team or individual based on status</div>
        </div>
        <div className="list-row row-flex">
          <Form.Check
            className="list-col"
            checked={assignDurationToStatus}
            onChange={this.onFieldChangeAssignDurationToStatus}
            disabled={!tasksStatusBased}
          />
          <div className="list-col">Assign a default duration to each status</div>
        </div>
        <div className="list-row row-flex">
          <div className="list-col project-assignments-col">
            <ProjectTaskStatusAssignment
              settings={settings}
              project={project}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default observer(StatusAssignLabel)
