import React from 'react'
import TaskDetailRow from '../TaskDetailRow'
import { observer } from 'mobx-react'
import { getAppNotifications } from '../../../AppNotifications'
import TaskActivityRow from './TaskActivityRow'
import { autorun, observable } from 'mobx'
import Icon from 'theme/Icon'

const debug = require('debug')('treks:task:activity')

class TaskActivity extends React.Component {

  static defaultProps = {
    fetchErrorMsg: 'Error fetching task history'
  }

  uiState = observable({
    task: null,
    showHistory: localStorage.getItem('task-detail-show-history') ? true : false
  })

  componentDidMount() {
    autorun(() => {
      if (this.uiState.showHistory) {
        this.fetchActivities()
      }
    })
  }

  componentDidUpdate() {
    if (this.uiState.task !== this.props.task && this.uiState.showHistory) {
      this.fetchActivities()
      this.uiState.task = this.props.task
    }
  }

  fetchActivities() {
    const { fetchErrorMsg } = this.props
    this.props.task.activityList.fetched()
      .then(activiyList => {
        debug('activityList', activiyList)
      })
      .catch(error => {
        console.error('activityList fetch error', error)
        getAppNotifications().error(fetchErrorMsg)
      })
  }

  toggleShowHistory = () => {
    this.uiState.showHistory = !this.uiState.showHistory
    localStorage.setItem(
      'task-detail-show-history', 
      this.uiState.showHistory ? '1' : ''
    )
  }

  render() {
    const { items } = this.props.task.activityList
    const { showHistory } = this.uiState
    debug('render', { items })
    return (
      <div className="container task-history-list">
        <TaskDetailRow icon="history">
          <div className='history-disclosure'>
            <button
              className="show-history-btn"
              onClick={this.toggleShowHistory}
            >
              {
                showHistory ? (
                  <>
                    <Icon set="fa" icon={'chevron-left'} style={{ marginRight: 5 }} size={18} />
                    {'Hide History'}
                  </>
                ) : (
                  <>
                  <Icon set="fa" icon={'chevron-right'} style={{ marginRight: 5 }} size={18} />
                    {'Show History'}
                  </>
                )
              }
            </button>
            {
              showHistory ? (
                <div className="history-list">
                  {items.map(item => (
                    <TaskActivityRow key={item.id} item={item} />
                  ))}
                </div>
              ) : null
            }
          </div>
        </TaskDetailRow>
      </div>
    )
  }
}

export default observer(TaskActivity)