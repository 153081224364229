import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { getPfaGraphData } from './pfaGraphData'

const debug = require('debug')('treks:pfa:graph')

export class PFAGraph extends React.Component {

  state = {
    data: []
  }

  async componentDidMount() {
    const { pfaScoreStore } = this.props
    const data = await getPfaGraphData(pfaScoreStore)
    this.setState({ data })
  }

  render() {
    const { data } = this.state
    debug('render', data)
    return (
      <ResponsiveLine
        colors={line => line.color}
        className="metrics-graph-line"
        data={data}
        xScale={{
          "type": "point"
        }}
        yScale={{
          "type": "linear",
          "stacked": true,
          "min": 0,
          "max": 100
        }}
        colorBy={e => e.color}
        enableGridX={false}
        enableArea={true}
        areaOpacity={1}
        animate={true}
        pointLabelYOffset={0} // nivo bug
      />
    )
  }
}
