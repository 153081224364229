import React from 'react'
import { getAppNotifications, Position, Intent } from 'Stores/App/AppToaster/AppNotifications';
import { observer } from 'mobx-react'
import { stores, getStore } from 'Stores'
import Alert from 'react-bootstrap/Alert'
import './AppNotifications.scss'
import Pressable from 'theme/Pressable';

const debug = require('debug')('treks:AppNotifications')

const AppNotification = observer(({ notification }) => {
  const { visible, transition, intent, message, undo, action } = notification

  const hide = () => notification.hide()
  const onUndo = () => {
    if (undo) {
      if (typeof undo === 'function') {
        undo()
      } else if (typeof undo.onClick === 'function') {
        undo.onClick()
      }
      hide()
    }
  }
  const onAction = () => {
    action.onClick()
    hide()
  }

  debug('render AppNofication', notification)
  return (
     <Alert
      variant={intent || 'info'}
      onClose={hide}
      dismissible
      className={visible && transition.shown === true ? 'shown' : (transition.hidden === true ? 'hide hidden' : 'hide')}
    >
      <div className='notification-message'>
        <span>{ message }</span>
      </div>
      <div className={'notification-actions'}>
        {
          undo 
            ? (
              <Pressable className={'btn-secondary btn-undo'} onClick={onUndo}>
                {undo.text || 'Undo'}
              </Pressable> 
            ) : null
        }
        {
          action 
            ? (
              <Pressable className={'btn-primary btn-action'} onClick={onAction}>
                {action.text || 'Confirm'}
              </Pressable> 
            ) : null
        }
      </div>
      
    </Alert>
  )
})

@observer
class AppNotificationPosition extends React.Component {

  static defaultProps = {
    margin: 60,
    width: 300,
    position: Position.BOTTOM_LEFT,
    notifications: []
  }

  onDismiss = toast => {
    toast.hide()
  }

  getPositionStyles = position => {
    const { margin, width } = this.props
    const cssOffset =( width/2) + 'px'
    const cssMargin = margin + 'px'
    const cssWidth = width + 'px'
    const positionStyles = {
      [Position.BOTTOM_LEFT]: { left: cssMargin, bottom: cssMargin },
      [Position.BOTTOM_RIGHT]: { right: cssMargin, bottom: cssMargin },
      [Position.TOP_RIGHT]: { right: cssMargin, top: cssMargin },
      [Position.TOP_LEFT]: { left: cssMargin, top: cssMargin },
      [Position.BOTTOM]: { right: `calc(50% - ${cssOffset})`, bottom: cssMargin },
      [Position.TOP]: { right: `calc(50% - ${cssOffset})`, top: cssMargin },
    }
    return { ...positionStyles[position], width: cssWidth }
  }

  render() {
    const { position, notifications } = this.props
    const postionStyles = this.getPositionStyles(position)
    debug('render', { postionStyles, notifications })
    return (
      <div style={postionStyles} className="app-notifications">
        {
          notifications.map(notification => (
            <AppNotification key={notification.uid} notification={notification} />
          ))
        }
      </div>
    )
  }
}

@observer
class AppNotificationContainer extends React.Component {

  positionProps = {
    [Position.BOTTOM_LEFT]: {},
    [Position.BOTTOM_RIGHT]: {},
    [Position.TOP_RIGHT]: {},
    [Position.TOP_LEFT]: {},
    [Position.BOTTOM]: {},
    [Position.TOP]: { width: 600 },
  }

  render() {
    const notificationList = getStore(stores.NotificationList)
    const { positionProps } = this
    const positions = Object.getOwnPropertySymbols(this.positionProps)
    const positionPropList = positions.map(position => {
      const props = this.positionProps[position]
      const notifications = notificationList.items.filter(item => {
        return item.position === position 
          || (!item.position && position === Position.BOTTOM_LEFT)
      })
      return {
        notifications,
        'position': position,
        ...props
      }
    })

    debug('positions', { positions, positionPropList, positionProps })

    return (
      <div className="app-notification-container">
        {
          positionPropList.map((props, i) => (
            <AppNotificationPosition key={i} {...props} />
          ))
        }
      </div>
    )
  }

}

// debugging
global.getAppNotifications = getAppNotifications

export {
  AppNotificationContainer,
  getAppNotifications,
  Intent,
  Position,
}