import { observable } from "mobx";
import Model from "Stores/Model";
import { getStore } from "Stores/Stores";
import TaskItem from "Stores/Task";
import { ActionPlannerList } from "./ActionPlannerList";

export class ActionPlannerApp extends Model {

  @observable tasks: TaskItem[] = []

  @observable order: string[] = []

  @observable dayPlannerList: ActionPlannerList

  @observable weekPlannerList: ActionPlannerList

}

export const getActionPlannerAppStore = (): ActionPlannerApp => getStore(ActionPlannerApp)

// debug
global.plannerApp = getActionPlannerAppStore()