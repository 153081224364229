import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import Button from '../../../theme/Button'
import Select from 'theme/Form/Select'
import { action } from 'mobx'
import NormalizedEvent from 'Modules/Draggable/NormalizedEvent'
import { minsToTimeFormatted } from 'lib/utils/DateUtils'

const debug = require('debug')('treks:planner:settings:detail')

class TimeSpanDetail extends React.Component {

  componentDidMount() {
    document.body.addEventListener('click', this.onUnFocus)
  }

  componentDidUnMount() {
    document.body.removeEventListener('click', this.onUnFocus)
  }

  static defaultProps = {
    selectPlaceholder: 'Select type...',
    timespanDetailLabel: 'Timespan Detail'
  }

  eventIsWithinDetailEl(event) {
    const el = document.querySelector('.timespan-detail')
    return event.composedPath().includes(el)
  }

  onUnFocus = event => {
    event = new NormalizedEvent(event)
    const { dayList } = this.props
    if (!this.eventIsWithinDetailEl(event)) {
      dayList.focusedItem = null
    }
  }

  onClickDelete = () => {
    const { plannerSettings } = this.props
    const { focusedTimespanPiece } = plannerSettings
    debug('remove ', plannerSettings.focusedTimespanPiece)
    if (!focusedTimespanPiece.nextTimespanPiece) return
    focusedTimespanPiece.setDuration(0, true, true)
    plannerSettings.setFocusedTimespanPiece(null)
  }

  @action onChange = option => {
    const { focusedTimespanPiece } = this.props.plannerSettings
    focusedTimespanPiece.setType(option.value)
    this.saveDaysWithTimespanPiece(focusedTimespanPiece)
    debug('set focusedItem type', { focusedTimespanPiece, option })
  }

  getDaysAroundTimespanPiece(timespanPiece) {
    const day = timespanPiece.lastTimespan.list.day
    const prevDay = timespanPiece.prevTimespanPiece?.lastTimespan.list.day
    const nextDay = timespanPiece.nextTimespanPiece?.firstTimespan.list.day
    const days = [prevDay, day, nextDay].filter(day => day)
    return days
  }

  saveDaysWithTimespanPiece(timespanPiece) {
    // @todo optimize will require 
    // this.resizeState.startDuration to get resize direction 
    // and figure out days affected
    const days = this.getDaysAroundTimespanPiece(timespanPiece)
    days.map(day => day.saveDaySettings())
  }

  getTotalDuration(timespan) {
    return this.props.plannerFlow.timespanFlow
      .filter(piece => piece.type === timespan.type)
      .map(piece => piece.duration)
      .reduce((sum, duration) => sum += duration, 0)
  }

  get availableTimespans() {
    return this.props.bucket.items.filter(ts => {
      return ts.deleted === false || this.getTotalDuration(ts) > 0
    })
  }

  render() {
    const { plannerSettings, bucket, selectPlaceholder, timespanDetailLabel } = this.props
    const { focusedTimespanPiece, focusedDay } = plannerSettings
    debug('render', { focusedTimespanPiece, plannerSettings })
    global.focusedTimespanPiece = focusedTimespanPiece // debug
    if (!focusedTimespanPiece) {
      return null
    }
    const options = this.availableTimespans.map(timespan => ({
      label: timespan.title,
      value: timespan.type
    }))
    const value = options.find(option => focusedTimespanPiece.type === option.value)

    const dayMins = 60 * 24
    const dayOffset = focusedDay.dayIndex * dayMins

    const startDuration = focusedTimespanPiece.startDuration - dayOffset
    const endDuration = focusedTimespanPiece.endDuration - dayOffset

    return (
      <div className="timespan-detail">
        <header className="header">
          {timespanDetailLabel}
          <Button variant="outline" className="timespan-close-icon" onClick={this.onClickDelete}>
            <Icon icon="trash" color={'#808284'} />
          </Button>
        </header>
        <table className="timespan-attributes">
          <tbody>
            <tr>
              <td className="label">Type:</td>
              <td>
                <Select
                  options={options}
                  value={value}
                  onChange={this.onChange}
                  placeholder={selectPlaceholder}
                  styles={{ container: provided => ({ ...provided, width: 120 })}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">Day:</td>
              <td>{focusedDay.title}</td>
            </tr>
            <tr>
              <td className="label">Start:</td>
              <td>{minsToTimeFormatted(startDuration)}</td>
            </tr>
            <tr>
              <td className="label">End:</td>
              <td>{minsToTimeFormatted(endDuration)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default observer(TimeSpanDetail)