import Model from "Stores/Model"
import Intent from "./Intent"
import { computed } from "mobx"
import { NotificationList } from "Stores/Notification"
import { getStore } from "Stores"
import Notification from "Stores/Notification/NotificationItem"

type AppNotification = Partial<Notification>

export default class AppToaster extends Model {

  @computed get toasters(): NotificationList {
    return getStore(NotificationList)
  }

  /**
   * Allow string or object notifications
   * @param {object|string} notification 
   */
  normalize(notification: AppNotification | string): AppNotification {
    return typeof notification === 'string' 
      ? { message: notification } 
      : notification
  }

  show(notification: AppNotification | string) {
    return this.toasters.show({
      duration: 10000,
      ...this.normalize(notification)
    })
  }

  success(notification: AppNotification | string) {
    return this.show({
      intent: Intent.SUCCESS,
      icon: "tick",
      ...this.normalize(notification),
    })
  }

  error(notification: AppNotification | string) {
    return this.show({
      intent: Intent.DANGER,
      icon: "warning-sign",
      ...this.normalize(notification),
    })
  }

  warn(notification: AppNotification | string) {
    return this.show({
      intent: Intent.WARNING,
      icon: "warning-sign",
      ...this.normalize(notification),
    })
  }

}