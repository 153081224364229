import React from 'react'
import { observer } from 'mobx-react';
import PropTypes from 'prop-types'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import DraggableStickyTask from './DraggableStickyTask';
import './ActionPlannerStickyTasks.scss'
import PlannerTaskContextMenu from 'Containers/ActionPlanner/ContextMenu/PlannerTaskContextMenu'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import ActionPlanner from 'Stores/ActionPlanner';
import TaskItem from 'Stores/Task';

const debug = require('debug')('treks:planner:events')

type PropTypes = {
  actionPlannerStore: ActionPlanner;
  plannerFlow: PlannerFlow
}

class ActionPlannerStickyTasks extends React.Component<PropTypes> {

  uiStore = ArbitraryModel.fromProps({
    showContextMenu: false,
    contextMenuPos: { left: null, top: null }
  })

  onShowContextMenu = ({ task, nativeEvent }) => {
    if (localStorage.noTaskContextMenu) return // disable in dev
    nativeEvent.preventDefault()
    this.setFocusedTask(task)
    const { clientX, clientY } = nativeEvent
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.addEventListener('scroll', this.onHideContextMenu)
    this.uiStore.setState({ 
      showContextMenu: true, 
      contextMenuPos: { left: clientX, top: clientY }
    })
    debug('showing context menu on task', { task, nativeEvent })
  }

  onHideContextMenu = () => {
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.removeEventListener('scroll', this.onHideContextMenu)
    this.uiStore.setState({
      showContextMenu: false,
      contextMenuPos: { left: null, top: null }
    })
  }

  setFocusedTask(task: TaskItem) {
    this.props.actionPlannerStore.setFocusedTask(task)
  }

  render() {
    const { actionPlannerStore, plannerFlow } = this.props
    const { showContextMenu, contextMenuPos } = this.uiStore
    debug('render', { actionPlannerStore })
    return (
      <div className="action-planner-sticky-tasks action-planner-items">
        {
          actionPlannerStore.stickyTasks.map(task => {
            return <DraggableStickyTask
              key={task.id}
              task={task}
              // taskPiece={taskPiece}
              plannerFlow={plannerFlow}
              onShowContextMenu={this.onShowContextMenu}
            />
          })
        }
        { 
          showContextMenu ? 
          <PlannerTaskContextMenu
            plannerFlow={plannerFlow}
            task={actionPlannerStore.focusedTask}
            contextMenuPos={contextMenuPos}
            onHideContextMenu={this.onHideContextMenu}
          /> : null 
        }
      </div>
    )
  }
}

export default observer(ActionPlannerStickyTasks)
