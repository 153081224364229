import reqSchema from './ModelRequestApiSchema'
import ModelRequestAdapter from './ModelRequestAdapter'

/**
 * Model Request Adapter for API
 */
export default class ModelRequestApi extends ModelRequestAdapter {

  reqSchema = reqSchema

  setReqSchema(schema) {
    this.reqSchema = schema
  }

  getReqSchema(type) {
    return this.reqSchema[type]
  }

  getMethod() {
    const requestType = this.getReqSchema(this.type)
    return requestType.method
  }

  getPath() {
    const requestType = this.getReqSchema(this.type)
    const path = requestType.path(this.model)
    return path
  }

  send = (data = {}) => {
    const method = this.getMethod()
    return this.request[method](this.getPath(), data)
  }
  
  post = (data = {}) => {
    return this.request.post(this.getPath(), data)
  }

  postJson = (data = {}) => {
    return this.request.postJson(this.getPath(), data)
  }
  
  get = (data = {}) => {
    return this.request.get(this.getPath(), data)
  }

  download = (data = {}) => {
    return this.request.download(this.getPath(), data)
  }

  fetch = (data = {}, options = {}) => {
    return this.request.fetch(this.getPath(), data)
  }

}
