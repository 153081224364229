import React from "react";
import PropTypes from "prop-types";
import { observable, observe, action } from "mobx";
import { observer } from "mobx-react";
import "theme/Pane/SplitPane.css";
import "./MainPaneGroup.scss";
import MainPaneGroupItem from "./MainPaneGroupItem";
import "element-qsa-scope"; // polyfill :scope css selector
import Resizable from "../../Modules/Resizable";
import { whenCondition } from "lib/utils/whenUtils";
import { withRouter } from "react-router-dom";
import { getStore, models } from "Stores";
import ReactSortable from '../../Modules/ReactSortable'

const debug = require("debug")("treks:component:MainPaneGroup");

Array.move = function (arr, from, to) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
};

class MainPaneGroup extends React.Component {
  static propTypes = {
    store: PropTypes.objectOf(() => models.MainPaneGroup).isRequired,
  };

  static defaultProps = {
    get store() {
      return getStore(models.MainPaneGroup);
    },
  };

  uiState = observable({
    isOnLine: navigator.onLine
  });

  onOffline = () => (this.uiState.isOnLine = false);

  onOnline = () => (this.uiState.isOnLine = true);

  onWindowResize = () => {
    const { focusedPane } = this.props.store
    if (focusedPane?.tabs?.length > 1) {
      focusedPane.scrollToTabImmediate(focusedPane.focusedTab)
    }
  }

  async componentDidMount() {
    this.whenMainPaneLoaded();
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("offline", this.onOffline);
    window.addEventListener("online", this.onOnline);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("offline", this.onOffline);
    window.removeEventListener("online", this.onOnline);
  }

  whenMainPaneLoaded = () => {
    whenCondition(
      () => {
        const panes = document.querySelector(".main-pane-group");
        return panes ? panes.getBoundingClientRect().height : 0;
      },
      () => {
        this.syncPanesToFitContainer();
      }
    );
  };

  syncPanesToFitContainer() {
    observe(this.props.store.items, (change) => {
      const { type, added, removed } = change;
      debug("observed", change);
      if (type === "splice" && (removed.length || added.length)) {
        debug("resizing: going to set default widths", { type, added, removed }); 
        this.setDefaultPaneWidths();
      }
    });
  }

  @action setDefaultPaneWidths() {
    debug("setDefaultPaneWidths");
    const { store } = this.props
    const { panes } = store;
    if (panes.length === 1) {
      panes[0].width = 100
      return
    }
    // ensure each pane has its default colSpan width
    panes.forEach((item, index) => {
      item.width = item.colSpan * (100 / store.colSpan);
      debug("setDefaultPaneWidth", { index, item, width: item.width });
    });
    // ensure we span 100% on screen if width left
    const panesWidth = panes.reduce((total, pane) => total += pane.width, 0)
    if (panesWidth < 99) {
      const widthLeft = 100 - panesWidth
      const addWidth = widthLeft / panes.length
      debug('Adding width to each pane', addWidth)
      panes.forEach(pane => pane.width += addWidth)
    }
  }

  @action fixNoPaneWidth() {
    debug("fixNoPaneWidth");
    const { panes } = this.props.store;
    panes
      .filter(item => !item.width)
      .forEach((item, index) => {
        debug("fixedNoPaneWidth", { item, index });
        item.width = 100 / panes.length;
      });
  }

  getNextItem(item) {
    const { panes } = this.props.store;
    const index = panes.indexOf(item);
    return panes.length - 1 > index && panes[index + 1];
  }

  /**
   * @todo calculate where item dropped.
   * @todo remove +-100
   */
  onPaneDragEnd = ({ pane, left }) => {
    const { order } = this.props.store;
    const index = order.indexOf(pane.slug);
    debug("onPaneDragEnd", { order, pane, left, index });
    if (left <= -100) {
      Array.move(order, index, index - 1);
    } else if (left >= 100) {
      Array.move(order, index, index + 1);
    }
    this.onOrderChange(order);
  };

  resizingStart = ({ width, idx }) => {
    debug("started resizing with", { width, idx });
    document.body.classList.add('pane-resizing')
  };

  @action resizingCurrent = ({ width, idx }) => {

    this.fixNoPaneWidth() // @todo why
    const { panes } = this.props.store;

    const percentWidth = this.resizeScreenPercentage(width);
    const item = panes[idx];
    const { minWidth, maxWidth } = item

    const nextItem = this.getNextItem(item);

    item.setWidth(Math.max(Math.min(percentWidth, maxWidth), minWidth))

    const totalResizedPanesWidth = panes.reduce((sum, item) => sum + parseFloat(item.width), 0);
    const remainingWidth = 100 - totalResizedPanesWidth
    if (nextItem) {
      const nextWidth = parseFloat(nextItem.width) + remainingWidth
      nextItem.setWidth(Math.max(Math.min(nextWidth, nextItem.maxWidth), nextItem.minWidth))
    }

    const totalFilledPanesWidth = panes.reduce((sum, item) => sum + parseFloat(item.width), 0);
    const nextRemainingWidth = 100 - totalFilledPanesWidth

    // re-resize item.width from remaining width from next sibling resize max/min constraints
    item.setWidth(parseFloat(item.width) + nextRemainingWidth)

    debug("resize", { idx, remainingWidth, width, percentWidth, itemWidth: item.width, nextItemWidth: nextItem.width, minWidth, maxWidth });
  };

  @action resizingEnd = ({ width, idx }) => {
    debug("end resizing with", { width, idx });
    document.body.classList.remove('pane-resizing')
    return this.props.store.saveLocal();
  };

  resizeScreenPercentage = (w) => {
    const screenWidth = window.innerWidth;
    return (w / screenWidth) * 100;
  };

  // @todo why
  getLayout() {
    const layouts = ['dashboard', 'inbox']
    return this.props.store.panes.find(item => {
        return layouts.includes(item.name.toLowerCase()) && item.name.toLowerCase()
    })
  }

  onDragStart = (...args) => {
    debug('onDragStart', args)
  }

  onDragEnd = (...args) => {
    debug('onDragEnd', args)
  }

  onRemove = (...args) => {
    debug('onRemove', args)
    return this.props.store.saveLocal();
  }

  onAdd = (...args) => {
    debug('onAdd', args)
    return this.props.store.saveLocal();
  }

  onChange = (order) => {
    debug('onChange', order)
    this.props.store.setOrder(order);
    return this.props.store.saveLocal();
  }

  render() {
    const { store } = this.props;
    const { panes } = store;

    const isOnLineClass = this.uiState.isOnLine ? "online" : "offline";
    debug("render panes", { panes });
    return (
      <div className={"main-pane-container " + isOnLineClass}>
        <ReactSortable
          options={{
            handle: '.main-pane-header',
            animation: 100,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            group: {
              name: 'main-pane',
              put: ['main-pane', 'pane-tab']
            },
            onStart: this.onDragStart,
            onEnd: this.onDragEnd,
            onRemove: this.onRemove,
            onAdd: this.onAdd
          }}
          onChange={this.onChange}
          className={'main-pane-group'}
        >
        {
          panes.map((pane, idx) => {
            const Footer = pane.footer;
            const footerComponent = Footer && <Footer key={pane.slug} />;
            const width =
              pane.width > 0 && pane.width < 100
                ? pane.width + "%"
                : 100 / panes.length + "%";
            debug("pane width", { idx, 'pane.width': pane.width, width });

            return (
                <Resizable
                  key={pane.uid}
                  data-id={pane.slug}
                  style={{ width }}
                  ref={(ref) => (pane.ref = ref)}
                  className={"main-pane-item-container"}
                  resize={"horizontal"}
                  onResize={({ width }) =>
                    this.resizingCurrent({ width, idx })
                  }
                  onResizeStart={({ width }) =>
                    this.resizingStart({ width, idx })
                  }
                  onResizeEnd={({ width }) =>
                    this.resizingEnd({ width, idx })
                  }
                  resizeHandleClassName={"main-pane-resize-handle"}
                >
                  <MainPaneGroupItem pane={pane} mainPaneGroup={store} />

                  <div className="main-pane-footer-container">
                    {footerComponent}
                  </div>
                </Resizable>
            );
          })
        }
        </ReactSortable>
      </div>
    );
  }
}

export default withRouter(observer(MainPaneGroup))
    
