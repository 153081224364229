import { observable } from "mobx"
import Icon from "theme/Icon"
import { getListViewUiStore } from "./ListViewUiModel"
import { getAppNotifications } from "AppNotifications"

const notifications = getAppNotifications()

export const multiTasksMenuItems = observable([
  {
    label: 'Delete Selected Tasks',
    onClick: async ({ onHide }) => {
      const uiStore = getListViewUiStore()
      const { selectedTasks } = uiStore.contextMenuState
      if (selectedTasks.length > 0) {
        const deleteOps = selectedTasks.map(async task => {
          task.trash()
          return task.save()
        })
        await Promise.all(deleteOps)
        notifications.error('Deleted Selected Tasks')
        onHide()
      }
    },
    icon: () => <Icon set={'fa'} icon={'times'} />
  },
  {
    label: 'Add to project',
    onClick: ({ menuItem }) => {
      console.log('add to project', { menuItem })
      menuItem.showMenu = true
    },
    icon: () => <Icon icon={'project'} />,
    showMenu: false,
    get menuItems() {
      return projectList.visibleItems.map(project => ({
        label: project.title,
        onClick: async ({ selectedTasks, onHide }) => {
          const taskOps = selectedTasks.map(async task => {
            task.setProject(project)
            if (task.category?.project?.uid !== task.project?.uid) {
              task.category = null // remove category if not in project
            }
            return task.save()
          })
          await Promise.all(taskOps)
          notifications.show(`Added selected tasks to project "${project.title}"`)
          multiTasksMenuItems.map(menuItem => menuItem.showMenu = false)
          onHide()
        }
      }))
    }
  },
  {
    label: 'Add to category',
    onClick: ({ menuItem }) => {
      menuItem.showMenu = true
    },
    icon: () => <Icon icon={'category'} />,
    showMenu: false,
    get menuItems() {
      return projectList.visibleItems.map(project => ({
        label: <span style={{ color: '#ccc' }}>{ project.title }</span>,
        onClick: () => {},
        showMenu: true,
        get menuItems() {
          return project.categoryList.visibleItems.map(category => ({
            label: category.title,
            onClick: async ({ selectedTasks, onHide }) => {
              const taskOps = selectedTasks.map(async task => {
                task.setProject(project)
                task.setCategory(category)
                return task.save()
              })
              await Promise.all(taskOps)
              notifications.show(`Added selected tasks to category "${category.title}"`)
              multiTasksMenuItems.map(menuItem => menuItem.showMenu = false)
              onHide()
            },
          }))
        }
      }))
    }
  }
])

export const singleTaskMenuItems = [
  {
    label: 'Add SubTask',
    onClick: async ({ selectedTasks, onHide }) => {
      const task = selectedTasks[0]
      const subtask = task.subTasksList.addItem({})
      subtask.setFocusOnTitle()
      await task.save()
      notifications.show(`SubTask added to task "${task.title}"`)
      onHide()
    },
    icon: () => <Icon icon={'subtasks'} />
  },
  ...multiTasksMenuItems
]