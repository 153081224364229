import ModelEntityManager from './ModelEntityManager'
import ModelRepository from './ModelRepository'
import { ApiRequest } from 'Stores/Service'
import Model from '.'
import { RequestAdapterType } from './ModelRequestAdapter'

/**
 * Stores Models in EntityManager
 * Handles relationships and requests
 */
export default class ModelStore {

  em = new ModelEntityManager()
  repo = new ModelRepository()
  
  get request() {
    return new ApiRequest()
  }

  constructor(em: ModelEntityManager, repo: ModelRepository) {
    if (em) this.em = em
    if (repo) this.repo = repo
  }

  /// Entity Management ///

  /**
   * Get a model from EntityMap or create one
   */
  getModel(ModelType: typeof Model, props: any) {
    return this.em.get(ModelType, props)
  }

  /**
   * Get a model from EntityMap matching uid|id
   */
  findModel(ModelType: typeof Model, props: any) {
    return this.em.find(ModelType, props)
  }

  /**
   * Get a model from EntityMap matching uid
   */
  findModelByUid(ModelType: typeof Model, uid: string) {
    return this.em.findByUid(ModelType, uid)
  }

  /**
   * Add a model to the entityMap
   */
  addModel(model: Model) {
    this.em.add(model)
  }

  /**
   * Store a model to the entityMap (replaces existing)
   */
  setModel(model: Model) {
    this.em.set(model)
  }

  /// Relationship resolution ///

  findMany(ModelType: typeof Model, props: any) {
    return this.em.findMany(ModelType, props)
  }

  findOne(ModelType: typeof Model, props: any) {
    return this.em.findOne(ModelType, props)
  }

  /// Relationship definition ///
  
  hasOne(ModelType: typeof Model, id: string) {
    const model = ModelType.fromId(id)
    if (id) model.fetched()
    return model
  }

  /// services ///

  /**
   * 
   */
  createRequest(model: Model, type: RequestAdapterType, request?: ApiRequest) {
    return this.repo.createRequest(model, type, request || this.request)
  }

}
