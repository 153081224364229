export const activityTypeList = [
  {
    action: 'create',
    type: 'task',
    title: 'created task',
  },
  {
    action: 'add_to_planner',
    type: 'task',
    title: 'put task on his Action Planner',
  },
]