import React from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import PlannerSettingsTimespan from './PlannerSettingsTimespan'
import Sortable from 'Modules/ReactSortable'

const debug = require('debug')('treks:planner:settings:day')

class PlannerSettingsDay extends React.Component {

  onDragStart = ({ item, from, to }) => {
    const { day, plannerSettings, plannerFlow } = this.props
    debug('onDragStart', { item, from, to })
    item.setAttribute('data-drag', 'drag')
    const timespan = plannerFlow.getTimespanPieceByUid(item.getAttribute('data-id'))
    plannerSettings.setFocusedTimespanPiece(timespan, day)
  }

  onDragStop = ({ item, from, to, }) => {
    const { day, plannerSettings } = this.props
    debug('onDragStop', { item, from, to })
    item.setAttribute('data-drag', '')
    const timespanPiece = plannerFlow.getTimespanPieceByUid(item.getAttribute('data-id'))
    plannerSettings.setFocusedTimespanPiece(timespanPiece, day)
  }

  @action onOrderChange = order => {
    const { day } = this.props
    const pieces = order.map(uid => plannerFlow.getTimespanPieceByUid(uid))
    const timespanUids = pieces.reduce((uids, piece) => [ ...uids, ...piece.group.timespans.map(t => t.uid) ], [])
    debug('order change', { timespanUids, order, day })
    day.timespanList.setOrder(timespanUids)
    day.saveDaySettings()
  }

  @action onTimespanPieceClick = ({ timespanPiece }) => {
    const { plannerSettings, day } = this.props
    debug('onTimespanPieceClick', { timespanPiece })
    plannerSettings.setFocusedTimespanPiece(timespanPiece, day)
  }

  @action onResizeStart = item => {
    const { plannerSettings, day } = this.props
    document.body.style.cursor = 'row-resize'
    debug('resize start', { item })
    plannerSettings.setFocusedTimespanPiece(item, day)
  }

  @action onResizeStop = item => {
    document.body.style.cursor = ''
    this.props.day.saveDaySettings()
  }

  renderItem = (timespanPiece) => {
    const { plannerFlow, plannerSettings, dayList, day, plannerUtils } = this.props
    return (
      <PlannerSettingsTimespan
        key={timespanPiece.uid}
        plannerFlow={plannerFlow}
        plannerSettings={plannerSettings}
        timespanPiece={timespanPiece}
        timespan={timespanPiece.timespanProps}
        dayList={dayList}
        day={day}
        plannerUtils={plannerUtils}
        onClick={this.onTimespanPieceClick}
        onResizeStart={this.onResizeStart}
        onResizeStop={this.onResizeStop}
      /> 
    )
  }

  render() {
    const { day } = this.props
    debug('render day', { day })
    return (
      <Sortable
        options={{
          animation: 100,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          group: {
            name: day.uid,
            put: [day.uid]
          },
          onStart: this.onDragStart,
          onEnd: this.onDragStop
        }}
        onChange={this.onOrderChange}
      >
        {
          day.dayTimespanPiecesFlow.map(this.renderItem)
        }
      </Sortable>
    )
  }
}

export default observer(PlannerSettingsDay)
