import React from 'react'
import Calendar from './Calendar'
import CalendarSettingsModal from './CalendarSettingsModal'
import { Route } from 'react-router-dom'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import { observer } from 'mobx-react'

const modalState = ArbitraryModel.fromProps({
  showSettings: true
})

const CalendarRoutes = ({ view }) => (
  <>
    <Route path="/calendar" component={() => <Calendar view={view || 'week'} />} />
    <Route path="/calendar-week" component={() => <Calendar view={"week"} />} />   
    <Route path="/calendar-month" component={() => <Calendar view={"month"} />} />   
    <Route path="/calendar/settings" component={observer(() => (
      <CalendarSettingsModal
        handleClose={() => {
          console.log('close modal')
          modalState.setState({ showSettings: false })
        }}
        show={modalState.showSettings}
      />
    ))} />
  </>
)

export default observer(CalendarRoutes)