import React from 'react'
import { observer } from 'mobx-react'
import FormControlSelect from 'theme/Form/FormControlSelect'

export default observer(function FieldProjectStatus(props) {
  const onChange = ({ value }) => {
    const { project } = props
    project.status = value
    project.save()
  }
  console.log('props', props)
  return (
    <FormControlSelect
      onChange={onChange}
      items={[
        {
          label: 'Planning',
          value: 'planning'
        },
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Complete',
          value: 'complete'
        }
      ]}
      value={props.project.status}
    />
  )
})