export const setIdleTimeout = (fn: Function, ms: number) => {
  let timer: number = 0

  const resetTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(fn, ms);  // time is in milliseconds
  }

  const eventNames = [
    'load',
    'mousemove',
    'mousedown',
    'touchstart',
    'touchmove',
    'click',
    'keydown',
    'scroll'
  ]

  eventNames.forEach(eventName => {
    window.addEventListener(eventName, resetTimer, true);
  })

  resetTimer()
}

// debug
global.setIdleTimeout = setIdleTimeout