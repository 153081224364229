import React from 'react'
import { observer } from 'mobx-react'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import Button from 'theme/Button'
import Icon from 'theme/Icon'
import renderMedia from 'render-media'
import from2 from 'from2'
import { getAuthToken, createUrl } from 'Services/TreksApiService'
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:task:file:modal')

const errorFileDownload = 'An error occurred downloading the file: '

class DetailFileModal extends React.Component {

  modalStore = ModalStore.create()

  static defaultProps = {
    fileDownloadIcon: 'file-download',
    fileUploadingIcon: 'spinner'
  }

  componentDidMount() {
    this.modalStore.setState({
      title: 'File Detail: ' + this.props.fileItem.name,
      icon: 'file'
    })
  }

  onFileDetailRef = (ref) => {
    const { fileItem } = this.props
    if (ref && fileItem && fileItem.file) {
      this.renderFileItemToElement(fileItem, ref)
    }
  }

  handleClose = event => {
    this.preventEvent(event)
    this.props.onClose()
  }

  async renderFileItemToElement(fileItem, domNode) {
    if (fileItem.name.match(/\.mp4$/)) {
      const path = await fileItem.toUrl()
      const token = await getAuthToken()
      const url = createUrl(path, { token })
      return this.renderVideoFileUrlToElement(url, domNode)
    }
    try {
      const file = await fileItem.file
      this.renderFileToElement(file, domNode)
    } catch(error) {
      getAppNotifications().error(errorFileDownload + fileItem.name)
    }
  }

  renderVideoFileUrlToElement(url, domNode) {
      const videoEl = document.createElement('video')
      videoEl.src = url
      videoEl.autoplay = true
      videoEl.controls = true
      domNode.appendChild(videoEl)
  }

  async renderFileToElement(file, domNode) {
    const bytes = Buffer.from(await this.readFileAsArrayBuffer(file))
    const streamableFile = {
      name: file.name,
      createReadStream: (opts = {}) => {
        return from2([ bytes.slice(opts.start || 0, opts.end || (bytes.length - 1)) ])
      }
    }
    debug('bytes', bytes, streamableFile)
    renderMedia.append(streamableFile, domNode, function (err, elem) {
      if (err) return console.error(err.message)
      debug('media element', elem)
    })
  }

  readFileAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onabort = () => reject()
      reader.onerror = error => reject(error)
      reader.readAsArrayBuffer(file)
    })
  }

  onClickDownloadFile = event => {
    this.preventEvent(event)
    this.downloadFile()
  }

  downloadFile() {
    const { file } = this.props.fileItem
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(file)
    link.download = file.name
    debug('download file', { file, link })
    link.click()
  }

  preventEvent = event => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  render() {
    const { fileItem, show, fileDownloadIcon, fileUploadingIcon } = this.props
    const { name, type } = fileItem
    const isUploaded = !fileItem.isRemote && !fileItem.isUploaded
    debug('render', this.props)
    return (
      <Modal 
          show={show} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="modal-size-80"
          onClick={this.preventEvent}
        >
        <Modal.Header>
            <Modal.Title>
              <Icon 
                set="file" 
                icon={type ? type : 'file'} 
                size={16}
                color={'#808284'} 
              />
              &nbsp;
              {name}
            </Modal.Title>
            <Button variant="outline" onMouseDown={this.onClickDownloadFile} className="modal-download-button">
              <Icon
                set="fa"
                icon={isUploaded ? fileUploadingIcon : fileDownloadIcon}
                size="xs"
                color={'#808284'}
                className={'modal-download-icon ' + (isUploaded ? 'file-uploaded' : 'file-uploading')}
              />
            </Button>
            <Button variant="outline" onMouseDown={this.handleClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="file-detail" ref={this.onFileDetailRef}></div>
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(DetailFileModal)