import { ActivityItem } from "Stores/Activity";
import { computed, override } from "mobx";

export class ProjectThrashItem extends ActivityItem {
  @override get thrash() {
    const before = this.beforeState?.duration || 0;
    const after = this.afterState?.duration || 0;
    return after - before;
  }

  @computed get thrashPercent() {
    const before = this.beforeState?.duration || 0;
    const after = this.afterState?.duration || 0;
    const percent = ((after - before) / after) * 100;
    // console.log("percent", { before, after, percent });
    return percent;
  }
}
