import { computed, observable, override } from "mobx";
import List from "Stores/Lists";
import ActionPlanner from "./ActionPlanner";
import { isSameDay } from "./ActionPlannerUtils";

const debug = require('debug')('treks:Models:ActionPlannerList')

/**
 * List of Action Planners used in multi-day and week planner views
 * Each ActionPlanner is synchronized by the list
 * Each Planner starts the day after the prev
 * The ActionPlannerList.focusedDate coincides with the ActionPlanner.focusedDate of the day in focus
 * @todo pagination appends/prepends ActionPlanners to the list and syncs these
 * @todo each planner set of tasks is sync'ed from the overflow of the previous day
 */
export class ActionPlannerList extends List {

  get ModelType() {
    return ActionPlanner
  }

  @override get items(): ActionPlanner[] {
    return super.items
  }

  /**
   * Specific to week planner UI (not tasks)
   * @property {object}
   */
   opts = {
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'h:mm A',
    durationStep: 1,
    durationInterval: 15, 
    durationHeightPerInterval: 15, 
    taskMargin: 0,
    minDuration: 5,
  }

  @computed get focusedDate() {
    const startOfTodayDate = new Date(new Date().setHours(0,0,0,0))
    return this.getAttribute('focusedDate', startOfTodayDate)
  }
  set focusedDate(_) {
    throw 'Direct access to focusedDate is not allowed'
  }

  setFocusedDate(focusedDate: Date) {
    if (!(focusedDate instanceof Date)) {
      throw new TypeError('setFocusedDate() must receive a Date as first parameter')
    }
    this.setAttribute('focusedDate', focusedDate)
  }

  @observable startOfWeekIndex: number = 0

  @computed get startOfWeekDate(): Date {
    const focusedDate = new Date(this.focusedDate) // copy
    const dayOfMonth = focusedDate.getDate()
    const dayOfWeek = focusedDate.getDay()
    const startDay =  new Date(focusedDate.setDate(dayOfMonth - dayOfWeek + this.startOfWeekIndex))
    const zeroHour = new Date(startDay.setHours(0, 0, 0))
    return zeroHour
  }

  @computed get endOfWeekDate(): Date {
    const startOfWeek = new Date(this.startOfWeekDate) // copy
    return new Date(startOfWeek.setDate(startOfWeek.getDate() + 7))
  }

  @computed get weekPlanners(): ActionPlanner[] {
    return this.items
  }

  @observable multiDayLen = 2

  @computed get dayPlanners(): ActionPlanner[] {
    const focusedDate = new Date(this.focusedDate) // copy
    const focusedPlanner = this.items.find(planner => isSameDay(planner.focusedDate, focusedDate))
    const focusedIndex = this.items.indexOf(focusedPlanner)
    const dayPlanners = this.items.slice(focusedIndex, Math.min(focusedIndex + this.multiDayLen, this.items.length))
    debug('dayPlanners', { focusedDate, focusedIndex, focusedPlanner, planners: this.items.map(planner => planner.focusedDate), dayPlanners })
    return dayPlanners
  }

}