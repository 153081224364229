import List from "Stores/Lists"
import Model from "Stores/Model"

export const toMeta = (model: Model) => {
  const { modelName: type, id, uid } = model
  if (model instanceof List) {
    return { 
      type, uid, 
      items: model.items.map(item => item.id ) 
    }
  }
  return { type, id, uid }
}
export const toLoggable = (object: any) => {
  if (!object) return object
  if (object instanceof Model) {
    return toMeta(object)
  }
  if (typeof object !== 'object' || Array.isArray(object)) {
    return object
  }
  return Object.entries(object)
    .reduce((next, [key, value]) => {
      return { 
        ...next, 
        [key]: value instanceof Model ? toMeta(value) : toLoggable(value) 
      }
    }, {})
}
export const logger = console.log.bind(console)
export const log = (...args) => {
  const meta = args.map(arg => toLoggable(arg))
  logger(...meta)
}
export const createLogger = (logger:Function) => {
  return (...args) => {
    const meta = args.map(arg => toLoggable(arg))
    logger(...meta)
  }
}