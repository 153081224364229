import DataItem from './DataItem'
import List from 'Stores/Lists/List'

export default class DataList extends List {

  get visibleItems():Array<DataItem> {
    return this.items.filter((item:DataItem) => !item.trashed)
  }
  
  get ModelType():any {
    return DataItem
  }
  
}
