import "Stores"
import { factory } from "Stores/utils"
import ModelStore from "./ModelStore"
import Model from "./Model"

const modelStore = factory(ModelStore) as ModelStore

export function getModel(type: typeof Model, props: any) {
  return modelStore.fromProps(type, props)
}

export function findModel(type: typeof Model, props: any) {
  return modelStore.findModel(type, props)
}

export function findMany(type: typeof Model, props: any) {
  return modelStore.findMany(type, props)
}

export function findModelByUid(type: typeof Model, uid: string) {
  return modelStore.findModelByUid(type, uid)
}

export function getObjectPrototypes(obj: Object) {
  let protos = []
  let proto = Object.getPrototypeOf(obj)
  while(proto && proto !== Object.prototype) {
      protos.push(proto)
      proto = Object.getPrototypeOf(proto)
  }
  return protos
}

export function getDefinedObjectPropertyNames(obj: Object): string[] {
  const keys = Object.getOwnPropertyNames(obj)
  return getObjectPrototypes(obj).reduce((next, proto) => {
    const descripters = Object.getOwnPropertyDescriptors(proto)
    const getters = Object.keys(descripters)
      .filter(key => typeof descripters[key].get === 'function')
    return [...next, ...getters]
  }, [...keys])
}