import { FC, Component } from 'react';
import { observable, action, computed } from 'mobx';
import Model from 'Stores/Model'

export class PFAModalTabsItem extends Model {
  @observable key: string
  @observable label: string
  @observable component: Component|FC
}

export default class PFAModalTabsModel extends Model {

  @observable activeTabKey: string

  @observable tabs: PFAModalTabsItem[]  = []

  @action setTabs(tabs: PFAModalTabsItem[]) {
    this.tabs.splice(0, this.tabs.length, ...tabs)
  }

  @action setActiveTabKey(tabKey: string) {
    this.activeTabKey = tabKey
  }

  @computed get activeTab(): PFAModalTabsItem|undefined {
    return this.tabs.find(tab => tab.key === this.activeTabKey)
  }

  @computed get activeComponent(): Component|FC {
    return this.activeTab?.component || (() => null)
  }


}