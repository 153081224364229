import React from "react";
import { observer } from "mobx-react";
import data from "./data";
import UnassignedTasksGraph from "./UnassignedTasksGraph";
import "./UnassignedTasks.scss";
import { ProjectItem } from "Stores/Project";
import ProjectTaskList from "Stores/Project/PojectTaskList";

const debug = require("debug")("treks:project:metrics:unassigned");

class ProjectMetricUnassignedTasks extends React.Component<{
  project: ProjectItem,
  data: any
}> {
  static defaultProps = {
    data,
  };

  componentDidMount() {
    this.setUnassignedTasksData();
  }

  async setUnassignedTasksData() {
    const { taskList } = this.props.project;
    await taskList.fetchNextPage(); // @todo fix
  }

  getActivityData(taskList: ProjectTaskList) {
    const { weeksUnassignedTasks } = taskList
    const data = weeksUnassignedTasks.map((week, index) => {
      const total = week.unAssigned.length + week.assigned.length
      const unAssigned = week.unAssigned.length
      return {
        x: index + 1,
        y: (total && unAssigned) ? (unAssigned / total) * 100 : 0,
      };
    });
    return data;
  }

  render() {
    const { taskList } = this.props.project
    const activity = this.getActivityData(taskList);
    const data = [
      {
        "id": "Unassigned Tasks",
        "color": "#ce7475",
        data: activity
      }
    ]

    const { items: tasks, unAssignedTasks } = this.props.project.taskList
    const totalCount = tasks.length
    const unassignedCount = unAssignedTasks.length
    const unassignedPercent = totalCount ? Number(unassignedCount/totalCount * 100).toFixed(2) : 0;
    return (
      <div className="metrics-graph-container metrics-graph-unassigned">
        <div className="metrics-graph">
          <div className="metrics-stats">
            <div className="metrics-stat row-flex">
              <div className="stat-value">{unassignedCount}</div>
              <label className="stat-label">Unassigned Tasks</label>
            </div>
            <div className="metrics-stat metrics-stat-total row-flex">
              <div className="stat-value">{totalCount}</div>
              <label className="stat-label">Total Project Tasks</label>
            </div>
            <div className="metrics-stat row-flex">
              <div className="stat-value">{unassignedPercent}%</div>
              <label className="stat-label">
                of project tasks are unassigned
              </label>
            </div>
          </div>
          <UnassignedTasksGraph data={data} />
        </div>
      </div>
    );
  }
}

export default observer(ProjectMetricUnassignedTasks);
