import { observable, computed, override } from 'mobx';
import { Item } from '../Lists';
import { GoalList } from '../Goal';
import { TeamItem } from '../Team';
import { OrganizationItem } from '../Organization'
import HabitMemberList from './HabitMemberList';
import HabitTemplate from './HabitTemplate';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';
import { SubTaskListList } from 'Stores/Task/SubTask';
import { ProjectItem } from 'Stores/Project';

const createRandomColorHex = () => {
  return "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0")
}

export default class HabitItem extends Item {

  static create(): HabitItem {
    const habit = super.create() as HabitItem
    habit.color = createRandomColorHex()
    return habit
  }
  
  @observable title:string

  @observable color: string

  @observable createdTS:string 
  
  @observable createdBy:string 
  
  @observable status:string 
  
  @observable units:string 
  
  @observable startDate:string 
  
  @observable endDate:string 
  
  @observable negativeTrend:string
  
  @hasOne(() => HabitTemplate, (template:HabitTemplate) => template.habit)
	template:HabitTemplate
  
  @observable frequency:string 
  
  @observable frequencyUnits:string 
  
  @observable periodUnits:string

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.habitList)
  project: ProjectItem

  @hasOne(() => TeamItem)
	team:TeamItem

	@hasOne(() => OrganizationItem)
  organization:OrganizationItem
	
	@hasMany(() => HabitMemberList, (members: HabitMemberList) => members.habit)
  members:HabitMemberList
  
	@hasMany(() => GoalList, (goals: GoalList) => goals.habit)
  goals:GoalList

  @observable data:any

  @computed get isPercentCompleteOnTime() {
    // @todo implement
    return true
  }

  // @todo inverse relationship
  @hasOne(() => SubTaskListList)
  subTasksList: SubTaskListList

  @override async save() {
    return this.saveState.postJson('habit/save', this.toJSON())
  }

}
