import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getKindDeedsPointsOptions } from "./stores/KindDeedsExtraPointsOptions";
import { getKindDeedsPointsStore } from "Containers/PFA/stores/PFAKindDeedsStore";

class KindDeedsExtraPointsSelect extends React.Component {

  static defaultProps = {
    options: getKindDeedsPointsOptions(),
    kindDeedsPointsStore: getKindDeedsPointsStore(),
    defaultValue: '3'
  };

  onChange = ({ value }) => {
    this.props.kindDeedsPointsStore.setProp('extraPoints', parseInt(value, 10))
  }

  getValue() {
    return String(this.props.kindDeedsPointsStore.extraPoints || this.props.defaultValue)
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
      />
    )
  }

}

export default observer(KindDeedsExtraPointsSelect)
