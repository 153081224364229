import React from 'react'
import { observer } from 'mobx-react'
import Nav from 'react-bootstrap/Nav'

class AccountSettingsMenuItem extends React.Component {
  render() {
    const { label, id } = this.props.item
    return (
      <Nav.Item>
        <Nav.Link eventKey={`/account/settings/${id}`}>{label}</Nav.Link>
      </Nav.Item>
    )
  }
}

export default observer(AccountSettingsMenuItem)