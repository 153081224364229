import Form from 'Stores/Form'

const debug = require('debug')('treks:store:account:settings')

export default class ProfileForm extends Form {

  constructor() {
    super()
    this.setFields([
      {
        name: 'name',
        label: 'Name',
        require: true,
      },
      {
        name: 'password',
        label: 'Password',
        type: 'password',
      },
      {
        name: 'email',
        label: 'Primary Email',
        type: 'email',
      },
    ])
  }

  /**
   * Retrieve settings from field values
   */
  getSettings() {
    return this.getFieldValues()
  }

  async fetch() {
    debug('fetch account settings')
    return this.fetchState.get('account/settings/detail')
      .then(resp => {
        const { settings } = resp.data
        debug('setting api settings', settings)
        this.setFieldValues(settings)
      })
  }

  async save() {
    const settings = this.getSettings()
    debug('save account settings', { settings })
    return this.saveState.post('account/settings/save', settings)
      .then(resp => {
        debug('resp', resp)
        this.setFieldValues(resp.data.settings)
      })
  }
}