import List from "Stores/Lists";
import { CalendarEventReminder } from "./CalendarEventReminder";
import { computed, observable } from "mobx";

/**
 * Implements the Google Calendar Events API Reminders
 * https://developers.google.com/calendar/api/v3/reference/events/update
 */
export class CalendarEventReminderList extends List {

  get ModelType() {
    return CalendarEventReminder
  }

  /**
   * If the event doesn't use the default reminders, this lists the reminders specific to the event, or, if not set, indicates that no reminders are set for this event. The maximum number of override reminders is 5.	
   */
  @computed get overrides(): CalendarEventReminder[] {
    return this.items
  }

  /**
   * Whether the default reminders of the calendar apply to the event
   */
  @observable useDefault = false

}