import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './ProjectMeetings.scss'
import { observable } from 'mobx';
import ProjectMeetingList from './ProjectMeetingList'
import DoneFilter from '../../../../theme/List/DoneFilter'
import Pressable from '../../../../theme/Pressable';
import MeetingList from 'Stores/Project/ProjectMeetingList'
import { getStore, models } from 'Stores';

const debug = require('debug')('treks:project:meetings')

class ProjectMeetings extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static defaultProps = {
    meetings: MeetingList.create(),
    get account() {
      return getStore(models.Session).Account
    }
  }

  componentDidMount() {
    const { meetings, project } = this.props
    meetings.setProp('project', project)
    meetings.fetch()
  }

  onAddMeeting = () => {
    const { project, account } = this.props
    const item = {
      project,
      createdBy: account,
      title: '',
      summary: '',
      meetingDate: new Date(),
    }
    debug('adding meeting', item)
    this.props.meetings.addItem(item)
  }

  render() {
    const { project, meetings } = this.props
    const { view, order } = this.store
    debug('render', { project, meetings, view, order })
    return (
      <div className="project-meetings">
        <header className="row tab-header">
          <Icon icon="meeting" size={15} />
          <div className="tab-title">Meetings</div>
          <DoneFilter  view={view} onChange={this.onViewChange} />
        </header>
        <div className="project-meetings-list">
          <ProjectMeetingList project={project} meetings={meetings} />
          <Pressable
            className="btn-add-meeting"
            onClick={this.onAddMeeting}
          >
            + Add Meeting
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(ProjectMeetings)
