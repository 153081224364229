import { override } from 'mobx'
import TaskItem from '../TaskItem'
import { ActivityItem } from '../../Activity'
import { hasOne } from 'Relationships/RelationshipDecorators'

const debug = require('debug')('treks:store:task:activity')

/**
 * Task Activity Item
 */
export default class TaskActivityItem extends ActivityItem {

	@hasOne(() => TaskItem, (task: TaskItem) => task.activityList)
  task: TaskItem

  @override fromJSON(json = {}) {
    const { taskId } = json
    const props = (({ taskId, ...props}) => props)(json)
    super.fromJSON(props)
    if (taskId) {
      this.task = TaskItem.fromProps({ id: taskId }) // don't update task
    }
    debug('fromJSON', json, this)
    return this
  }

  toJSON() {
    const json = super.toJSON.call(this)
    json.task = this.task.toJSON()
    debug('toJSON', json, this)
    return json
  }

}
