import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getDaysToTrackOptions } from "./stores/DaysToTrackOptions";
import { getPFASettingsStore } from "Containers/PFA/stores/PFASettingsStore";

class DaysToTrackSelect extends React.Component {

  static defaultProps = {
    options: getDaysToTrackOptions(),
    pfaSettingsStore: getPFASettingsStore(),
    defaultValue: 'everyday',
    nonMultiOpts: ['everyday', 'weekdays']
  };

  onChange = (value) => {
    const { pfaSettingsStore } = this.props
    if (Array.isArray(value) && this.hasNonMultiOpt(value)) {
      value = this.hasNonMultiOpt(value)
    }
    pfaSettingsStore.setProp('daysToTrack', value)
  }

  hasNonMultiOpt(value) {
    const { nonMultiOpts } = this.props
    return (!Array.isArray(value)) 
      ? nonMultiOpts.includes(value) 
      : value.find(opt => this.props.nonMultiOpts.includes(opt.value))
  }

  isMulti() {
    const { pfaSettingsStore, nonMultiOpts } = this.props
    const { daysToTrack } = pfaSettingsStore
    if (Array.isArray(daysToTrack)) {
      return daysToTrack.length && !this.hasNonMultiOpt(daysToTrack)
    }
    return daysToTrack && !nonMultiOpts.includes(daysToTrack?.value)
  }

  getValue() {
    const { pfaSettingsStore, defaultValue } = this.props
    if (Array.isArray(pfaSettingsStore.daysToTrack)) {
      return pfaSettingsStore.daysToTrack.length ? pfaSettingsStore.daysToTrack : defaultValue
    }
    return pfaSettingsStore.daysToTrack?.value ? pfaSettingsStore.daysToTrack : defaultValue
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
        isMulti={this.isMulti()}
      />
    )
  }

}

export default observer(DaysToTrackSelect)
