import { GoalItem } from "Stores/Goal";
import { Item } from "Stores/Lists";
import { ProjectItem } from "Stores/Project";
import TaskItem from "Stores/Task";

type FilterableItem = TaskItem
type FilterableItems = Array<FilterableItem>
type FilterItem = {
  value: string,
  label: string
}
type FilterItems= Array<FilterItem>
type SearchFilterValue = string
type SortFilterValue = FilterItem & {
  sortBy: FilterItem,
  sortOrder: FilterItem
}
type RangeFilterValue = FilterItem & {
  range: FilterItem,
  unit: FilterItem
}

export class ListFilter {

  filterFns = {
    search: (items:FilterableItems, value:SearchFilterValue) => {
      if (!value) return items
      return items.filter(item => item.title.includes(value))
    },
    sort: (items:FilterableItems, value:SortFilterValue) => {
      if (!value) return items
      const { sortBy, sortOrder = 'ASC' } = value
      if (!sortBy) return items
      if (['set_order', 'category'].includes(sortBy.value)) return items
      const itemSortFns = {
        'due_date': (a, b) => {
          const sortNum = a.dueDate > b.dueDate 
            ? 1
            : (a.dueDate < b.dueDate ? -1 : 0)
          return sortOrder === 'ASC' ? sortNum : sortNum * -1
        },
        'assigned_to': (a, b) => {
          const sortNum = a.assignedUser.name > b.assignedUser.name 
            ? 1
            : (a.assignedUser.name < b.assignedUser.name ? -1 : 0)
          return sortOrder === 'ASC' ? sortNum : sortNum * -1
        },
        'alphabetical': (a, b) => {
          const sortNum = a.title > b.title 
            ? 1
            : (a.title < b.title ? -1 : 0)
          return sortOrder === 'ASC' ? sortNum : sortNum * -1
        },
        'duration': (a, b) => {
          const sortNum = a.duration > b.duration 
            ? 1
            : (a.duration < b.duration ? -1 : 0)
          return sortOrder === 'ASC' ? sortNum : sortNum * -1
        }
      }
      const sortFn = itemSortFns[sortBy.value]
      if (!sortFn) return items // @todo remove
      return items.sort((a, b) => sortFn(a, b))
    },
    completion: (items:FilterableItems, { value }:FilterItem) => {
      if (!value) return items
      const msecsIn24Hrs = 24 * 60 * 60 * 1000
      const date24hrsAgo = new Date(new Date().getTime() - msecsIn24Hrs)
      const date3daysAgo = new Date(new Date().getTime() - msecsIn24Hrs * 3)
      const date1weekAgo = new Date(new Date().getTime() - msecsIn24Hrs * 7)
      const date1monthAgo = new Date(new Date().getTime() - msecsIn24Hrs * 30)
      const itemFilterFns = {
        'all': item => item,
        'incomplete': item => !item.done,
        'complete': item => item.done,
        '24hrs': item => item.done && item.doneDate >= date24hrsAgo,
        '3days': item => item.done && item.doneDate >= date3daysAgo,
        '1week': item => item.done && item.doneDate >= date1weekAgo,
        '1month': item => item.done && item.doneDate >= date1monthAgo,
      }
      const itemFilter = itemFilterFns[value]
      return items.filter(item => {
        return itemFilter(item)
      })
    },
    assigned: (items:FilterableItems, value:FilterItems) => {
      if (!value) return items
      const userIds = value.map(({ value }) => value)
      return items.filter(item => userIds.includes(item.assignedUser.id))
    },
    onPlanner: (items:FilterableItems, { value }:FilterItem) => {
      if (!value || value === 'all') return items
      return items.filter(item => {
        return value === 'true' ? item.onPlanner : !item.onPlanner
      })
    },
    dueDate: (items:FilterableItems, value:RangeFilterValue) => {
      if (!value || !value.range || !value.value || !value.unit) return items
      const msecsInDay = 24 * 60 * 60 * 1000
      const msecsInWeek = 7 * msecsInDay
      const msecsInMonth = 30 * msecsInDay
      const msecsPerUnit = {
        'days': msecsInDay,
        'weeks': msecsInWeek,
        'months': msecsInMonth
      }
      const secondsDuration = msecsPerUnit[value.unit.value] * Number(value.value)
      const dateNow = new Date()
      const dateWithinNext = new Date(new Date().getTime() + secondsDuration)
      const dateWithinLast = new Date(new Date().getTime() - secondsDuration)
      const dateWithinNextPlusDay = new Date(new Date().getTime() + secondsDuration + msecsInDay)
      const rangeFilterFns = {
        'within_next': item => item.dueDate >= dateNow && item.dueDate <= dateWithinNext,
        'within_last': item => item.dueDate <= dateNow && item.dueDate >= dateWithinLast,
        'before': item => item.dueDate <= dateWithinNext,
        'between': item => item.dueDate >= dateWithinLast && item.dueDate <= dateWithinNext,
        'on': item => item.dueDate >= dateWithinNext && item.dueDate <= dateWithinNextPlusDay,
      }
      const rangeFilter = rangeFilterFns[value.range.value]
      return items.filter(item => {
        return rangeFilter(item)
      })
    },
    parent: (items:FilterableItems, options:FilterItems) => {
      if (!options || !options.length) return items
      const parentTypes = {
        project: ProjectItem,
        goal: GoalItem
      }
      const valueTypes = options.map(({ value }) => parentTypes[value])
      const filteredItems = items.filter(item => {
        return -1 !== valueTypes.findIndex(type => {
          const match = type 
            ? item.parent && item.parent instanceof type
            : (!item.parent || !item.parent.id)
          return match
        })
      })
      return filteredItems
    },
    duration: (items:FilterableItems, value:RangeFilterValue) => {
      if (!value || !value.range || !value.value || !value.unit) return items
      const minsInMin = 1
      const minsInHour = 60 * minsInMin
      const minsInDay = 24 * minsInHour
      const minsPerUnit = {
        'minutes':  minsInMin,
        'hours': minsInHour,
        'days': minsInDay
      }
      const minsDuration = minsPerUnit[value.unit.value] * Number(value.value)
      const rangeFilterFns = {
        '<': item => item.duration <= minsDuration,
        '=': item => item.duration === minsDuration,
        '>': item => item.duration >= minsDuration,
      }
      const rangeFilter = rangeFilterFns[value.range.value]
      return items.filter(item => {
        return rangeFilter(item)
      })
    },
  }

  getFilteredItems(items: Item[], filter:any): Item[] {
    if (!filter) return items || []
    const filteredItems = Object.keys(filter)
      .reduce((prevItems, filterName) => {
        const { value } = filter[filterName]
        const filterFn = this.filterFns[filterName]
        return filterFn(prevItems, value)
      }, items)
    return filteredItems || []
  }
}