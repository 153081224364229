import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import { TaskItem } from 'Stores/Task';

const debug = require('debug')('treks:task:checkbox')

class TaskOnPlanner extends React.Component {

  static propTypes = {
    item: PropTypes.objectOf(() => TaskItem),
  }

  static defaultProps = {
    icon: 'action_planner',
    iconSize: 20
  }

  onClick = event => {
    const { item } = this.props
    debug('toggle check', item)
    event.preventDefault()
    event.stopPropagation()
    item.toggleOnPlanner()
    item.save()
  }

  render() {
    const { item, iconSize, icon } = this.props
    debug('render', item)
    const color = !item.onPlanner ? '#dcdddf' : '#808285'
    const onPlannerClassName = item.onPlanner ? ' is-on-planner' : ''
    return (
      <Pressable onClick={this.onClick} className={'row-icon task-on-planner-icon' + onPlannerClassName}>
        <Icon name={icon} size={iconSize} color={color} />
      </Pressable>
    )
  }
}

export default observer(TaskOnPlanner)