import React from 'react'
import { observer } from 'mobx-react';
import { dateAddDays } from 'Stores/ActionPlanner/ActionPlannerUtils';
import { getAppNotifications } from 'AppNotifications';
import ActionPlannerEvent from '../ActionPlannerEvents/ActionPlannerEvent';
import { DraggableWithScroll } from 'Modules/Draggable/DraggaleWithScroll';

const debug = console.log //  require('debug')('treks:planner:DraggableEvent')

class DayDraggableEvent extends React.Component {

  elementRef = null

  prevTop = 0
  isDragging = false

  static defaultProps = {
    stackOffset: 50,
    maxStacks: 8,
    maxWidth: '100%',
    widthOffset: 120,
    onTaskOffset: 50,
    leftOffset: 0,
    saveEventErrorMsg: 'Failed to save event update',
    scrollElSel: '.main-pane-component:has(.action-planner)'
  }

  getPlannerEl() {
    return document.querySelector('.action-planner-body')
  }

  onElementRef = ref => {
    if (ref) this.elementRef = ref
  }

  onDragStart = () => {
    const { event } = this.props
    debug('onDragStart', { event })
    document.body.style.cursor = 'grab'
    this.isDragging = true
  }

  onDrag = ({ top, left }) => {
    debug('onDrag', { top, left })
    this.setEventPosition({ top, left })
    return false
  }

  onDragEnd = ({ top, left }) => {
    const { event } = this.props
    debug('onDragEnd', { top, left })
    document.body.style.cursor = ''
    this.setEventPosition({ top, left })
    event.leftDragOffset = 0
    this.isDragging = false

    // set day if changed (week planner?)
    const plannerEl = this.getPlannerEl()
    const dayColWidth = plannerEl.offsetWidth / 7
    if (Math.abs(left) > dayColWidth/2) {
      const dayDelta = Math.round(left/dayColWidth)
      event.startDate = dateAddDays(event.startDate, dayDelta)
      event.endDate = dateAddDays(event.endDate, dayDelta)
    }

    event.datedItem.save()
  }

  setEventPosition({ top }) {
    debug('setEventPosition', { top })
    this.setStartDateFromTopOffset(top)
  }

  setStartDateFromTopOffset(top) {
    const { plannerFlow, event } = this.props
    const offsetMins = plannerFlow.actionPlanner.utils.getDurationFromHeight(top)
    const nextStartDate = plannerFlow.getDateAtPlannerDuration(offsetMins)
    event.moveStartDate(nextStartDate)
    this.prevTop = top
    // this.scrollActionPlannerIfEventHidden(event, top)
  }

  /**
   * @todo fix scrolling planner when drag sticky task
   */
  scrollActionPlannerIfEventHidden(event, top) {
    const { plannerUtils } = this.props
    const pane = document.querySelector('.main-pane-component:has(.action-planner-body)')
    if (!pane) return
    if (pane.scrollTop > top) {
      pane.scrollTop = top
    }
    const eventEndHeight = plannerUtils.getHeightFromDuration(event.endDuration)
    if ((pane.scrollTop + pane.offsetHeight) < eventEndHeight) {
      pane.scrollTop = eventEndHeight - pane.offsetHeight
    }
  }
  
  onShowContextMenu(params) {
    const { onShowContextMenu } = this.props
    onShowContextMenu && onShowContextMenu(params)
  }

  render() {
    const { 
      plannerFlow, plannerUtils, event, scrollElSel,
      dragDirection, stackOffset, maxStacks, maxWidth, widthOffset, 
      onTaskOffset, leftOffset, topOffset 
    } = this.props

    const onTasks = plannerFlow.getTaskPiecesOnDatedItem(event)
    const startDuration = plannerFlow.getMinsSinceStartOfPlanner(event.startDate)
    const height = plannerUtils.getHeightFromDuration(event.duration)

    const maxStackOffset = stackOffset * maxStacks
    const stackIndex = event.stackIndex
    const stackWidth = stackIndex * stackOffset

    const onTask = onTasks && onTasks[0]
    const startBuffer = 5
    const onTaskStart = onTask && (
      onTask.startDuration - startBuffer <= event.startDuration 
      && onTask.startDuration + startBuffer >=  event.startDuration
    )
    const titleOffset = 7
    const isOverTaskTitle = onTask && (onTask.startDuration + titleOffset >= event.startDuration)
    const onHoveredTaskWidth = onTask && onTask.isHovered && isOverTaskTitle && !this.isDragging ? 200 : 0
    const zIndex = 10 + stackIndex
    const onTaskClass = onTask ? ' onTask' : ''
    const onActiveTaskClass = onTask && onTask.isFocused ? ' onFocusedTask' : ''
    const onDoneTaskClass = onTask && onTask.done ? ' onDoneTask' : ''
    const onHoveredTaskClass = onTask && onTask.isHovered && isOverTaskTitle ? ' onHoveredTask' : ''
    const draggingClass = this.isDragging ? ' dragging' : ''
    const stackIndexClass = ' stack-' + stackIndex
    const className = 'draggable-event' + draggingClass + onTaskClass 
      + onActiveTaskClass + onDoneTaskClass + onHoveredTaskClass + stackIndexClass

    const top = plannerUtils.getHeightFromDuration(startDuration)
    const left = Math.min(Math.max(stackWidth, onHoveredTaskWidth), maxStackOffset)
    const transform = `translate(${left}px, ${top}px)`

    const leftCalc = `calc(${typeof leftOffset === 'number' ? leftOffset + 'px' : leftOffset}`
      + ` + ${event.leftDragOffset}px)`
    const topCalc = topOffset
    const innerWidth = `calc(${maxWidth} - ${widthOffset + stackWidth + (onTask ? onTaskOffset: 0)}px)`
    const innerHeight = height - 10

    const overlapEndDuration = plannerFlow.getEventsOverlappingEndDuration(event)
    const cutTopMargin = overlapEndDuration !== event.endDuration ? 5 : 0
    const cutoutHeight = plannerUtils.getHeightFromDuration(overlapEndDuration - event.startDuration) - cutTopMargin

    debug('render', { event, left, top, stackIndex, stackOffset, stackWidth, onTask, transform, topCalc })
    return (
      <DraggableWithScroll
        ref={this.onElementRef}
        scrollElSel={scrollElSel}
        className={className}
        handleCssSelector='.icon-drag'
        dragDirection={dragDirection}
        onDrag={this.onDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        style={{ transform, height, zIndex, left: leftCalc, top: topCalc  }}
        onContextMenu={({ nativeEvent }) => this.onShowContextMenu({ nativeEvent, event })}
      >
        <div
          className={(onTask && !onTaskStart) ? 'draggable-event-cutout' : ''}
          style={{ height: cutoutHeight }}
        >
          <ActionPlannerEvent
            plannerUtils={plannerUtils}
            width={innerWidth}
            height={innerHeight}
            event={event} 
          />
        </div>
      </DraggableWithScroll>
    )
  }
}

export default observer(DayDraggableEvent)