import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import MainPaneGroup from '../Components/MainPaneGroup'
import { bucket, MultiDaylannerPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { mainPaneComponents } from './MainPane/MainPaneComponents'
import { models } from 'Stores'
import { autorun } from 'mobx'

const debug = require('debug')('treks:route:main-pane')

export default withRouter(observer(class MainPaneRoute extends React.Component {

  static defaultProps = {
    store: getStore(models.MainPaneGroup)
  }

  get params() {
    return this.props.match.params
  }

  componentDidUpdate(prevProps) {
    const { store, location } = this.props
    console.log('updated panes', store.items.length)
    // reset panes if route changes
    if (location.pathname !== prevProps.location.pathname) {
      store.setItems([])
      this.setPanes()
    }
  }

  async componentDidMount() {
    const { store } = this.props
    this.setBucketComponents(bucket)
    store.setBucketItems(bucket)
    this.setPanes()
    window.addEventListener('beforeunload', () => store.saveLocal(this.params.page))
    // always show the URL default pane
    // @todo dicuss with Paul (Should we change URL instead)
    autorun(() => {
      if (store.items.length === 0) {
        this.addPaneFromURL()
      }
    })
  }

  async setPanes() {
    const { store } = this.props
    await store.fetchLocal(this.params.page)
    this.addPaneFromURL()
    if (store.items.length === 0) {
      this.addDefaultPane()
    }
  }

  setBucketComponents(bucket) {
    bucket.forEach(item => {
      const { component, footer } = mainPaneComponents[item.slug] || {}
      Object.assign(item, { component, footer })
    })
  }

  addDefaultPane(comp = MultiDaylannerPane) {
    this.props.store.addOrUpdateItem(comp)
  }

  addPaneFromURL() {
    const { page, id } = this.params
    const { store } = this.props
    let pane = store.getBucketItemBySlug(page)
    if (pane) {
      pane.setModelId(id)
      store.addOrUpdateItem(pane)
    }
    debug('setPaneFromURL', { page, id, pane, store })
    return pane
  }

  render() {
    const { store } = this.props
    debug('render', { store })
    return (
			<MainPaneGroup store={store} />
    )
  }
}))
