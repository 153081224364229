import { action } from "mobx";
import Model from "Stores/Model";
import { property } from "Stores/Model/ModelDecorators";
import TaskItem, { TaskList } from "Stores/Task";

export class SubTasksStore extends Model {

  @property focusedSubTask: TaskItem = null

  @property focusedList: TaskList = null

  @action setFocusedSubTask(focusedSubTask: TaskItem) {
    this.focusedSubTask = focusedSubTask
  }

  isFocusedSubTask(task: TaskItem) {
    return this.focusedSubTask && Model.isSameModel(this.focusedSubTask, task)
  }

  @action setFocusedSubTaskList(list: TaskList) {
    this.focusedList = list
  }

  isFocusedSubTaskList(list: TaskList) {
    return this.focusedList && Model.isSameModel(this.focusedList, list)
  }
}

export const createSubTasksStore = () => new SubTasksStore();
