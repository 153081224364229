import { load } from 'recaptcha-v3'

export default class TreksRecapture {

  siteKey:string = '6LcV9LgUAAAAAK_1kCJ_Y0H4R8nzJvAk4r6xMTw7'
  apiUrl:string = 'https://api.treks.com/'

  constructor(options:Object) {
    if (options) {
      Object.assign(this, options)
    }
  }

  async getToken(action:string) {
    const recaptcha = await load(this.siteKey)
    return await recaptcha.execute(action)
  }
  
  async verify(action:string) {
    const token = await this.getToken(action)
    const resp = await this.verifyResponseToken(token)
    return resp
  }

  async verifyResponseToken(token:string) {
    return await fetch(this.apiUrl + 'recapcha/verify?response=' + token)
        .then(res => res.json())
  }

}