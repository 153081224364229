import { observable, computed, override } from 'mobx'
import { importUser } from '../User'
import Item from '../Lists/Item'
import { ProjectItem } from '../Project'
import { User } from 'Stores/User/Type/User'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class SprintItem extends Item {

  @observable sprintNumber:number = 0

  @observable taskCount:number = 0

  @observable taskDoneCount:number = 0

  @observable taskTargetPercent:number = 0

  @observable scrumMaster:User

  @observable productOwner:User

  @observable sprintStartDate:Date|undefined

  @observable sprintEndDate:Date|undefined

	@hasOne(() => ProjectItem)
  project:ProjectItem

  @observable createdBy:User

  @observable recurring:boolean = false

  @computed get taskCompletePercent() {
    return Math.floor(this.taskDoneCount/this.taskCount * 100) || 0
  }

  @override async save() {
    return await this.saveState.postJson('sprint/save', this.toJSON())
  }

  @override fromJSON(json:any) {
    const { projectId, createdBy, sprintStartDate, sprintEndDate } = json
    const item = super.fromJSON(json)
    this.project = projectId && ProjectItem.getModel({ id: projectId })
    this.createdBy = createdBy && importUser().getModel({ id: createdBy })
    this.sprintStartDate = sprintStartDate && new Date(sprintStartDate)
    this.sprintEndDate = sprintEndDate && new Date(sprintEndDate)
    return item
  }

}