import React from 'react'
import { observer } from 'mobx-react'
import TaskDetailRow from './TaskDetailRow'
import Avatar from '../../theme/Avatar'
import Select from '../../theme/Form/Select'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import AddUserDropdown from '../ui/AddUserDropdown'

const debug = require('debug')('treks:task:members')

class MemberAvatar extends React.Component {
  render() {
    const { member } = this.props
    return (
      <Avatar 
        key={member.id} 
        name={member.name}
        src={member.profileImage} 
        size={24} 
        className="avatar" 
        containerStyle={avatarStyles}
      />
    )
  }
}

/**
 * @todo allow components as labels to implement avatar in dropdown
 */
export class MemberLabel extends React.Component {
  render() {
    const { member } = this.props
    return (
      <div>
        <MemberAvatar member={member} />
        {member.name}
      </div>
    )
  }
}

class MemberItem extends React.Component {

  state = {
    avatarRef: null,
    showTooltip: false
  }

  onAvatarRef = ref => this.setState({ avatarRef: ref })

  onAvatarFocus = () => this.setState({ showTooltip: true })

  onAvatarBlur = () => this.setState({ showTooltip: false })

  onAvatarClick = () => {
    this.props.item.removeMember(this.props.member)
    this.props.item.save()
  }

  render() {
    const { member } = this.props
    return (
      <div className="avatar-item">
        <div
          ref={this.onAvatarRef}
          onMouseOver={this.onAvatarFocus}
          onMouseOut={this.onAvatarBlur}
          onClick={this.onAvatarClick}
        >
        <MemberAvatar 
          key={member.id} 
          member={member}
        />
        </div>
        <Overlay target={this.state.avatarRef} show={this.state.showTooltip} placement="top">
          {props => <Tooltip {...props} show="true">Remove Member</Tooltip>}
        </Overlay>
      </div>
    )
  }
}

class TaskMembers extends React.Component {

  static defaultProps = {
    addMemberPlaceholder: 'Add Members',
  }

  store = ArbitraryModel.fromProps({
    isAddingUser: false,
    name: null,
    email: null
  })

  onCreateOption = name => {
    this.store.setState({ name, isAddingUser: true })
    debug('onCreateOption', { isAddingUser: this.store.isAddingUser })
  }

  onAddUser = async () => {
    const { item, userList } = this.props
    const { name, email } = this.store
    const newUser = await userList.addItem({ name, email }).save()
    item.addMember(newUser)
    item.save()
    this.store.setState({ name: null, email: null, isAddingUser: false })
    debug('onCreateOption', { item, newUser })
  }

  onChange = option => {
    const { item, userList } = this.props
    const member = userList.getItemByIdString(option.value)
    item.addMember(member)
    item.save()
    debug('set item member', { item, option, userList })
  }

  onHideAddUser = () => {
    this.store.setState({ isAddingUser: false })
  }

  render() {
    const { item, userList, addMemberPlaceholder } = this.props
    const { isAddingUser } = this.store
    const members = item.memberList.items
    const memberIds = members.map(member => member.id.toString())
    const options = userList.items
      .filter(({ id }) => !memberIds.includes(id.toString()))
      .map((member) => ({ 
        label: member.name, 
        value: member.id 
      }))
    debug('render', { item, options, store: this.store })
    return (
      <div className="container task-member-list">
        <TaskDetailRow icon="add_guest" className="row-flex" hasValue={members.length}>
          {
            members.length ? (
              <div className="avatar-list">
                {members.map(member => (
                  <MemberItem key={member.id} member={member} item={item} />
                ))}
              </div>
            ) : null
          }
          <div className="task-member-select">
            <Select 
              isClearable
              hasAddOption
              createOptionPosition={'first'}
              placeholder={addMemberPlaceholder}
              onChange={this.onChange}
              onCreateOption={this.onCreateOption}
              options={options}
              value={null}
              styles={selectStyles}
            />
            {
              isAddingUser && (
                <AddUserDropdown
                  store={this.store}
                  onHide={this.onHideAddUser}
                  onAddUser={this.onAddUser}
                />
              )
            }
          </div>
        </TaskDetailRow>
      </div>
    )
  }
}

export default observer(TaskMembers)

const avatarStyles = {
  marginRight: 5,
}

const selectStyles = {
  control: styles => {
    return {
      ...styles,
      minHeight: 30,
      height: 30,
      marginTop: 0,
      marginLeft: 5,
    }
  },
}
