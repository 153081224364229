import React from 'react'
import { observer } from 'mobx-react'
import Select from 'theme/Form/Select'
import { getStore, models } from 'Stores'
import { CalendarAccountList } from 'Stores/Calendar'

type CalendarGlobalSettingsProps = {
  getCalendarAccountList: () => CalendarAccountList;
}

class CalendarGlobalSettings extends React.Component<CalendarGlobalSettingsProps> {

  static defaultProps = {
    getCalendarAccountList: () => getStore(models.CalendarAccountList) as CalendarAccountList
  }

  render() {
    const { getCalendarAccountList } = this.props
    const calendarAccounts = getCalendarAccountList()
    const calendars = calendarAccounts.items.map(account => account.items).flat()
    const calendarOptions = calendars.map(calendar => ({
      label: calendar.name,
      value: calendar.id
    }))
    const weekStartOptions = ['Sunday', 'Monday'].map(day => ({
      value: day.toLowerCase(),
      label: day
    }))
    const alertOptions = [5, 15, 10, 60].map(mins => ({
      value: mins,
      label: `${mins} minutes`
    }))

    return (
      <div className="calendar-global-settings">
        <table>
            <tbody>
              <tr>
                <td>Default Personal Calendar</td>
                <td>
                  <Select
                    value={null}
                    options={calendarOptions}
                  />
                </td>
              </tr>
              <tr>
                <td>Default Work Calendar</td>
                <td>
                  <Select
                    value={null}
                    options={calendarOptions}
                  />
                </td>
              </tr>
              <tr>
                <td>Week Start On</td>
                <td>
                  <Select
                    value={'sunday'}
                    options={weekStartOptions}
                  />
                </td>
              </tr>
              <tr>
                <td>Default Alert Time</td>
                <td>
                  <Select
                    value={5}
                    options={alertOptions}
                  />
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    )
  }
}

export default observer(CalendarGlobalSettings)