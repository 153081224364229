import { action, observable } from "mobx"
import Model from "Stores/Model"
import { getStore } from "Stores/Stores"
import ActionPlannerUtils, { dateAddDays, toDayIndex, toStartOfDayDate } from "../ActionPlannerUtils"
import { WeekPlannerConfig } from "../config/WeekPlannerConfig"
import { PlannerFlowSplitAtDay } from "../PlannerFlow/PlannerFlowSplitAtDay"
import { getTodayStartDate } from "./utils"

const weekStartDate = toStartOfDayDate(
  dateAddDays(
    getTodayStartDate(), 
    -toDayIndex(getTodayStartDate())
  )
)

export class WeekPlanner extends Model {

  plannerFlow = PlannerFlowSplitAtDay.fromProps({
    startDate: weekStartDate,
    endDate: dateAddDays(weekStartDate, 7)
  }) as PlannerFlowSplitAtDay

  utils = new ActionPlannerUtils(WeekPlannerConfig.default)

  @observable isUiCollapsed: boolean = false

  @action toggleUiExpandCollapse() {
    this.utils.setOpts(this.isUiCollapsed ? WeekPlannerConfig.default: WeekPlannerConfig.collapsed)
    this.isUiCollapsed = !this.isUiCollapsed
  }

}

export const getWeekPlanner = (): WeekPlanner => getStore(WeekPlanner)

global.weekPlanner = getStore(WeekPlanner)