import { ResponsivePie } from '@nivo/pie'
import { getExecutionPieGraphData } from './ExecutionPieGraphData'

const defaultProps = {
  width: 400,
  height: 400,
  margin: { top: 80, right: 120, bottom: 80, left: 120 },
  animate: true,
  activeOuterRadiusOffset: 8,
  innerRadius: 0.5
}

export const ExecutionPieGraph = (props) => {
  const allProps = { ...defaultProps, ...props }
  const { width, height, pfaPlanScoreStore } = allProps
  const getColor = bar => bar.data.color
  const data = getExecutionPieGraphData(pfaPlanScoreStore)
  return (
    <div style={{ width, height }}>
      <ResponsivePie
        {...allProps}
        colors={getColor}
        data={data}
      />
    </div>
  )
}