import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from '../ListViewFields'

export const ListViewHeaders = observer(({ headers, fields }) => {
  return (
    <header className="list-view-headers">
      {
        headers.map(header => {
          return fieldIsEnabled(fields, header.name) || header.required ? (
            <h3 key={header.name} className={header.name}>
              {header.label}
            </h3>
          ) : null
        })
      }
    </header>
  )
})