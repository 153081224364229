import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

export const TimespanTypeTitle = observer(({ timespan, focusOnTitle, onAddTimespan, onTitleChange }) => {
  const [ref, setRef] = useState()
  useEffect(() => {
    if (focusOnTitle && ref) ref.focus()
  }, [focusOnTitle, ref])
  const [focused, setFocused] = useState(false)
  const onChange = event => {
    onTitleChange(event.target.value)
  }
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)
  const onKeyDown = event => {
    if (event.key === 'Backspace' && (event.metaKey || event.ctrlKey || !timespan.title)) {
      focusOnPrevOrNextItemTitle()
      removeItem()
    }
    if (event.key === 'Enter') {
      onAddTimespan()
    }
  }
  const focusOnPrevOrNextItemTitle = () => {
    const { list } = timespan
    const targetItem = list.getPrevItem(timespan) || list.getNextItem(timespan)
    if (targetItem) targetItem.setFocusOnTitle()
  }
  const removeItem = () => {
    timespan.list.removeItem(timespan)
  }
  const style = { 
    border: focused ? '1px solid #ccc' : '1px solid #fff',
    borderRadius: 3,
    padding: 3,
    textAlign: 'right'
  }
  return (
    <input
      ref={setRef}
      className={"title title-input"} 
      value={timespan.title} 
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      onKeyDown={onKeyDown}
    />
  )
})