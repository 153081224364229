import React from 'react'
import PropTypes from 'prop-types'
import './ListViewFilter.scss'

const debug = require('debug')('treks:list:task:filter')

export default class ListViewFilterRow extends React.Component {

  static propTypes = {
    label: PropTypes.string.isRequired
  }

  render() {
    const { label, children, onChange } = this.props
    debug('render', { label, onChange })
    return (
      <div className="filter-row">
        <label className="filter-row-label">{label}</label>
        <div className="filter-row-body">
          {children}
        </div>
      </div>
     )
  }
}