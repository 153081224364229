import { computed } from "mobx"
import { dateAddMins } from "../ActionPlannerUtils"
import { DatedItemFlow } from "./Event/DatedItemFlow"
import { DatedItemListFlow } from "./Event/DatedItemListFlow"
import { TaskPieceFlow } from "./TaskPieceFlow"
import { TimespanPieceFlow } from "./TimespanPieceFlow"
import Model from "Stores/Model"
import { PlannerFlow } from "./PlannerFlow"

export class PlannerEventsFlow extends Model {

  plannerFlow: PlannerFlow

  startDuration = 0

  @computed get endDuration() {
    return this.plannerFlow.endDuration
  }

  @computed get startDate() {
    return this.plannerFlow.startDate
  }

  @computed get endDate() {
    return this.plannerFlow.endDate
  }

  @computed get taskPiecesFlow() {
    return this.plannerFlow.taskPiecesFlow
  }

  @computed get eventList(): DatedItemListFlow {
    return DatedItemListFlow.fromProps({
      plannerFlow: this.plannerFlow,
      actionPlanner: this.plannerFlow.actionPlanner,
      eventsFlow: this
    }) as DatedItemListFlow
  }

  @computed get orderedEvents(): DatedItemFlow[] {
    return this.eventList.eventsOrderedByStartDuration
  }

  @computed get events(): DatedItemFlow[] {
    return this.orderedEvents
  }

  @computed get visibleEvents(): DatedItemFlow[] {
    return this.orderedEvents
      .filter(event => event.deleted !== true && event.onPlanner !== false)
      // @todo fix filter
      // @note no need to filter for multi-day planner.
      // .filter(event => {
      //   return (event.startDuration >= this.startDuration && event.startDuration < this.endDuration)
      //     || (event.endDuration >= this.startDuration && event.endDuration < this.endDuration)
      // })
  }

  @computed get eventStackIndexMap(): Map<DatedItemFlow, number> {
    const stackIndexMap = new Map()
    const orderedEvents = this.orderedEvents
    let prevEventsEndDuration = 0, prevStackIndex = 0
    orderedEvents.forEach(event => {
      if (prevEventsEndDuration) {
        if (prevEventsEndDuration >= event.startDuration) {
          stackIndexMap.set(event, ++prevStackIndex)
        }
      }
      if (prevEventsEndDuration < event.endDuration) {
        prevEventsEndDuration = event.endDuration
      }
    })
    return stackIndexMap
  }

  getStackIndex(event: DatedItemFlow): number {
    return this.eventStackIndexMap.get(event) || 0
  }

  getTaskPiecesOnDatedItem(event: DatedItemFlow): TaskPieceFlow[] {
    return this.taskPiecesFlow.filter(piece => {
      const pieceEndDuration = piece.startDuration + piece.duration
      const endDurationMin = Math.min(event.endDuration, pieceEndDuration)
      const startDurationMax = Math.max(event.startDuration, piece.startDuration)
      const overlapDuration = endDurationMin - startDurationMax
      return overlapDuration > 0
    })
  }

  getEventsOverlapping(event: DatedItemFlow): DatedItemFlow[] {
    let lastEndDuration = event.endDuration
    return this.visibleEvents.filter(piece => {
      if (piece.startDuration < lastEndDuration && piece.endDuration > event.startDuration) {
        lastEndDuration = Math.max(lastEndDuration, piece.endDuration)
        return true
      }
      return false
    })
  }

  getEventsOverlappingEndDuration(event: DatedItemFlow): number {
    let lastEndDuration = event.endDuration
    this.visibleEvents.forEach(piece => {
      if (piece.startDuration < lastEndDuration && piece.endDuration > event.startDuration) {
        lastEndDuration = Math.max(lastEndDuration, piece.endDuration)
      }
    })
    return lastEndDuration
  }

  getFirstEventStartingWithTaskPiece(taskPiece: TaskPieceFlow, marginInclusive = 0) {
    return this.visibleEvents.find(event => {
      return event.startDuration >= taskPiece.startDuration - marginInclusive
        && event.startDuration <= taskPiece.startDuration + marginInclusive
    })
  }

  // events on a static piece
  getEventsOnPiece(events: DatedItemFlow[], pieceStartDuration: number, pieceEndDuration: number) {
    return events.filter(event => {
      const endDurationMin = Math.min(pieceEndDuration, event.endDuration)
      const startDurationMax = Math.max(pieceStartDuration, event.startDuration)
      const overlapDuration = endDurationMin - startDurationMax
      return overlapDuration > 0
    })
  }

  // events duration on a static piece
  getEventsDurationOnPiece(events: DatedItemFlow[], pieceStartDuration: number, pieceEndDuration: number) {
    const eventsOnPiece = this.getEventsOnPiece(events, pieceStartDuration, pieceEndDuration)
    let duration = 0, lastEndDuration = pieceStartDuration
    if (eventsOnPiece.length) {
      duration = eventsOnPiece.reduce((duration, event) => {
        const endDurationMin = Math.min(pieceEndDuration, event.endDuration)
        const startDurationMax = Math.max(lastEndDuration, event.startDuration)
        const eventDuration = Math.max(endDurationMin - startDurationMax, 0)
        lastEndDuration = endDurationMin
        return duration + eventDuration
      }, 0)
    }
    return duration
  }

  // recursive event duration on growing piece
  getEventsDurationOnPieceFlow(
    events: DatedItemFlow[], 
    currTimespan: TimespanPieceFlow, 
    timespanDurationLeft: number, 
    taskDurationLeft: number
  ): number {

    let eventsDuration = 0

    while(1) {
      const pieceStartDuration = currTimespan.endDuration - timespanDurationLeft
      const taskAndEventsDurationLeft = taskDurationLeft + eventsDuration
      const pieceDuration = taskAndEventsDurationLeft >= timespanDurationLeft 
        ? timespanDurationLeft : taskAndEventsDurationLeft
      const pieceEndDuration = pieceStartDuration + pieceDuration

      const nextEventsDuration = this.getEventsDurationOnPiece(events, pieceStartDuration, pieceEndDuration)

      if (eventsDuration === nextEventsDuration) break
      eventsDuration = nextEventsDuration
      
    }
    return eventsDuration
  }

}