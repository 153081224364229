import { getStore } from "Stores";
import Model from "Stores/Model";
import { property } from "Stores/Model/ModelDecorators";
import TaskItem from "Stores/Task";

export class ActionPlannerUiState extends Model {

  @property isAuthenticated = false

  @property isFetched = false

  @property shiftKey = false

  @property showContextMenu = false

  @property contextMenuPos = null

  @property isScrollingNextDay = false

  // show select calendar for Task -> Event
  @property showCalendarModal = false

  @property selectedTask: TaskItem = null

}

export const getActionPlannerUiState = (): ActionPlannerUiState => getStore(ActionPlannerUiState as any)
