import { override } from 'mobx'
import HabitItem from './HabitItem'
import { MemberItem } from 'Stores/Member'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class HabitMemberItem extends MemberItem {

	@hasOne(() => HabitItem)
  habit:HabitItem

  @override async save() {
    return this.saveState.get('habit/member/save', this.toJSON())
  }

}
