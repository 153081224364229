import React from 'react'
import Icon from 'theme/Icon'
import './ScrollButton.scss'
import PropTypes from 'prop-types'

export default class ScrollButton extends React.Component {

  static defaultProps = {
    size: 'lg'
  }

  static propTypes = {
    onClick: PropTypes.func.isRequired
  }

  render() {
    const { direction, size } = this.props
    return (
      <Icon
        set="fa"
        icon={'chevron-' + direction}
        className={'btn-scroll btn-scroll-' + direction}
        size={size}
        onClick={this.props.onClick}
      />
    )
  }
}
