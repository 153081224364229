import { observable, override } from "mobx"
import TaskItem from "Stores/Task"
import ActionPlanner from "../ActionPlanner"
import { TaskListFlow } from "./TaskListFlow"

const debug = require('debug')('treks:store:task:list')

export class PlannerTaskListFlow extends TaskListFlow {

  @observable actionPlanner: ActionPlanner = null

  @override get items(): TaskItem[] {
    return this.actionPlanner.itemsOrdered
  }

  @override get visibleItems(): TaskItem[] {
    return this.actionPlanner.visibleItems
  }

  setItems(items) {
    this.actionPlanner.setItems(items)
  }

  addItem(props: any, index?: number) {
    return this.actionPlanner.addItem(props, index)
  }

  removeItem(item) {
    return this.actionPlanner.removeItem(item)
  }

}