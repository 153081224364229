import { observable, action } from 'mobx';
import { Item } from 'Stores/Lists'

/**
 * UI Notification
 */
export default class Notification extends Item {

  @observable message: string

  @observable icon: string

  @observable visible: boolean

  @observable intent: string

  @observable duration: number

  @observable transition: Record<'shown' | 'hidden', boolean> = {
    shown: false,
    hidden: false
  }

  @observable position: string

  @observable action: {
    text: string;
    onClick: () => void
  }

  @observable undo: () => void

  constructor(props) {
   super(props)
   if (props && props.visible) this.show()
   if (props && props.duration) this.hideAfterDuration(props.duration)
  }

  @action show() {
    this.visible = true
    this.createTransition({ shown: true, hidden: false }, 0)
    if (this.duration) {
      setTimeout(() => this.hide(), this.duration)
    }
  }

  @action hide() {
    this.visible = false
    this.createTransition({ shown: false, hidden: true })
  }

  @action createTransition(transition, duration = 500) {
    setTimeout(() => {
      Object.assign(this.transition, transition)
    }, duration)
  }

  @action hideAfterDuration(duration) {
    setTimeout(() => this.hide(), duration)
  }

}
