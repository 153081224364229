export const categories = [
  {
    id: 1,
    title: 'Initial Research',
  },
  {
    id: 2,
    title: 'Planning',
  },
  {
    id: 3,
    title: 'UX Mockup',
  }
]
export default categories
