import { observable } from 'mobx';
import Model from '.';

const debug = require('debug')('treks:Entity:property')

interface DecoPropDesc extends PropertyDescriptor {
  initializer: Function
}

export const modelProperty = (...args): any => {
  args[0].addPropKey(args[1])
  debug('modelProperty', args)
  const ret = observable.apply(modelProperty, args)
  return ret
}

export const attribute = (target: Model, key: string, descriptor: DecoPropDesc): PropertyDescriptor => {
  const { configurable, enumerable, get, set, value, initializer } = descriptor;
  target.addPropKey(key)
  debug('creating modelProperty', { target, key, descriptor })
  return {
    configurable, 
    enumerable,
    get() {
      if (this === target) {
        throw Error('Access property on prototype')
      }
      if (value) return value
      if (get) return get.call(this)
      return this.getAttribute(key, initializer)
    },
    set(value: any) {
      if (this === target) {
        throw Error('Access property on prototype')
      }
      if (set) set.call(this, value)
      return this.setAttribute(key, value)
    }
  }
}

export const property = modelProperty
