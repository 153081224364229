import React from 'react'
import { observer } from 'mobx-react'
import DatePicker from 'theme/DatePicker'

class SprintEndDate extends React.Component {

  onChangeDate = date => {
    const { sprint } = this.props
    sprint.sprintEndDate = date
  }

  render() {
    const { sprint, className } = this.props
    return (
      <div className={'sprint-end-date ' + className}>
        <DatePicker
          date={sprint.sprintEndDate}
          onChange={this.onChangeDate}
          inline
        />
      </div>
    )
  }
}

export default observer(SprintEndDate)