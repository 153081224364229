import { observable } from 'mobx'
import UserList from '../User/UserList'
import List from '../Lists/List'

export default class MetricList extends List {

  @observable title:string = ''

  @observable summary:string = ''

  @observable users:UserList = UserList.create()

}