import React from 'react'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import Avatar from '../../theme/Avatar'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'

const debug = require('debug')('treks:theme:followers:item')

@observer 
class FollowerTooltip extends React.Component {
  render() {
    const { store } = this.props
    debug('render tooltip', store)
    return (
      <Overlay target={store.avatarRef} show={store.showTooltip} placement="top">
        {props => <Tooltip {...props} show="true">Remove Member</Tooltip>}
      </Overlay>
    )
  }
}

@observer 
class FollowerItem extends React.Component {

  store = observable({
    avatarRef: null,
    showTooltip: false
  })

  @action 
  setShowTooltip(status) {
    clearInterval(this.showTooltipTimer)
    this.showTooltipTimer = setTimeout(() => {
      this.store.showTooltip = status
    }, 50)
  }

  onAvatarRef = ref => this.store.avatarRef = ref

  onAvatarFocus = () => this.setShowTooltip(true)

  onAvatarBlur = () => this.setShowTooltip(false)

  onAvatarClick = () => {
    const { onRemoveFollower, followers, follower } = this.props
    if (onRemoveFollower) {
      return onRemoveFollower(follower)
    }
    followers.removeItem(follower)
  }

  render() {
    const { follower } = this.props
    const { user } = follower
    debug('render', { follower, user })
    return (
      <div className="avatar-item">
        <div
          ref={this.onAvatarRef}
          onMouseOver={this.onAvatarFocus}
          onMouseOut={this.onAvatarBlur}
          onClick={this.onAvatarClick}
        >
        <Avatar 
          key={user.id} 
          name={user.name}
          src={user.profileImage} 
          size={24} 
          className="avatar" 
          containerStyle={avatarStyles}
        />
        </div>
        <FollowerTooltip store={this.store} />
      </div>
    )
  }
}

export default FollowerItem

const avatarStyles = {
  marginRight: 5,
}