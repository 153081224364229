import React from 'react'
import './style/trek-icons.css'
import FontAwesomeIcon from '../FontAwesomeIcon'

const fileTypes = [
  'avi',
  'css',
  'dll',
  'doc',
  'eps',
  'htm',
  'jpg',
  'mov',
  'mp3',
  'pdf',
  'png',
  'ppt',
  'psd',
  'txt',
  'wav',
  'xl',
  'zip'
]

export default class Icon extends React.PureComponent {

  static defaultProps = {
    set: 'treks',
    style: {}
  }

  render() {
    const { name, size, color, set, icon, className, style } = this.props

    if (set === 'fa') {
      return (
        <FontAwesomeIcon 
          {...this.props} 
          icon={name || icon} 
        />
      )
    }

    if (set === 'file') {
      const fileType = (name || icon).toLowerCase()
      if (fileTypes.includes(fileType)) {
        return (
          <img 
            width={size || 24}
            src={require('./FileTypes/' + fileType.toUpperCase() + '.svg')} 
            alt={fileType}
            title={fileType}
            style={style}
          />
        )
      } else {
        return (
          <FontAwesomeIcon 
            {...this.props} 
            icon={fileType && fileType !== 'file' ? 'file-' + fileType : 'file'} 
          />
        )
      }
    }

    return (
      <i 
        className={(className || '') + ' icon-' + (name || icon)}
        style={{
          fontSize: size && (typeof size === 'number' ? size + 'px' : size),
          color,
          ...style
        }}
      ></i>
    )
  }
}