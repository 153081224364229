import ProjectItem from "../ProjectItem";
import { hasOne } from "Relationships/RelationshipDecorators";
import { computed } from "mobx";
import { ProjectThrashItem } from "./ProjectTrashItem";
import { ActivityList } from "Stores/Activity";

export class ProjectThrash extends ActivityList {

  get ModelType() {
    return ProjectThrashItem;
  }

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.thrash)
  project: ProjectItem;

  @computed get weeksThrash() {
    const activityWeeks = this.activityWeeks.map((week) => {
      const sum = week.items.reduce((sum, item) => sum + item.thrash, 0);
      const thrash = sum / 7;
      return {
        thrash
      };
    });
    return activityWeeks;
  }

  async fetch() {
    const res = await this.fetchState.get("activity/project/thrash", {
      projectId: this.project.id,
      sinceTimestamp: this.startDate.getTime(),
    });
    return this.fromJSON(res.data);
  }
}
