import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Icon from 'theme/Icon'
import './GoalMeetings.scss'
import { observable } from 'mobx';
import GoalMeetingList from './GoalMeetingList'
import { getStore, models } from 'Stores'
import DoneFilter from '../../../../theme/List/DoneFilter'
import { getAppNotifications } from '../../../../AppNotifications'
import Pressable from '../../../../theme/Pressable';
import { GoalItem } from 'Stores/Goal';
import { uid } from 'Stores/utils';

const debug = require('debug')('treks:goal:meetings')

class GoalMeetings extends React.Component {

  store = observable({
    view: localStorage.getItem('goal.meetings.view'),
    order: null
  })

  static propTypes = {
    goal: PropTypes.objectOf(() => GoalItem).isRequired
  }

  static defaultProps = {
    get account() {
      return getStore(models.Session).Account
    }
  }

  addMeeting = ({ title, meetingDate, recurring }) => {
    if (!title || !meetingDate) {
      getAppNotifications().error('The meeting title and date are required')
      return false
    }
    const item = {
      id: uid(),
      createdBy: this.props.account,
      title,
      meetingDate,
      recurring
    }
    debug('adding meeting', item)
    this.props.goal.meetings.addItem(item)
    return true
  }

  onAddMeeting = () => {
    this.addMeeting({
      title: 'Example Meeting',
      meetingDate: new Date(),
      recurring: Math.random() > 0.5
    })
  }

  onViewChange = view => {
    debug('view changed', view)
    this.store.view = view
    localStorage.setItem('goal.meetings.view', view)
  }

  render() {
    const { goal } = this.props
    const { view, order } = this.store
    const { meetings } = goal
    debug('render', { goal, meetings, view, order })
    return (
      <div className="goal-meetings">
        <header className="row tab-header">
          <Icon icon="meeting" size={15} />
          <div className="tab-title">Meetings</div>
          <DoneFilter view={view} onChange={this.onViewChange} />
        </header>
        <div className="goal-meetings-list">
          <GoalMeetingList goal={goal} meetings={meetings} view={view} />
          <Pressable
            className="btn-add btn-add-meeting"
            onClick={this.onAddMeeting}
          >
            + Add Meeting
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(GoalMeetings)
