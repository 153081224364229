import React from 'react'
import { observer } from 'mobx-react'
import ProjectSettingFieldCheckbox from './Fields/ProjectSettingFieldCheckbox'
import { action } from 'mobx'

const debug = require('debug')('treks:project:settings:item')

class ProjectSettingItem extends React.Component {

  @action onFieldChange = value => {
    const { field, project } = this.props
    project[field.name] = field.value = value
    project.save()
  }

  render() {
    const { field, project } = this.props
    debug('render', { field, project })
    const Field = field.Component 
      ? (
        <field.Component
          field={field}
          project={project}
          onChange={this.onFieldChange}
        /> 
      )
      : (
        <div className="list-col setting-col">
          <ProjectSettingFieldCheckbox
            field={field}
            onChange={this.onFieldChange}
          />
        </div>
      )
    const Label = typeof field.label === 'string'
      ? <div className="project-settings-label">{field.label}</div> 
      : <field.label field={field} project={project} /> 
    return (
      <div className="setting list-row row-flex">
        {Field}
        <div className="list-col setting-col">
          {Label}
        </div>
      </div>
    )
  }
}

export default observer(ProjectSettingItem)
