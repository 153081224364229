/**
 * Cached types->arg1->arg2 ... arg (n) chains
 */
const typesMap = new WeakMap()

/**
 * Caches Instances by arguments references
 * Arguments are compared by reference
 * 
 * @param {object} Class/Type to instantiate
 * @param {any} Arguments to class
 * @example 
 * const user1 = { name: 'joe' }
 * const user2 = { name: 'joe' }
 * factory(UserStore, user1) === factory(UserStore, user1)
 * factory(UserStore, user1) !== factory(UserStore, user2)
 */
export default function factory(type, ...args) {
  if (!type) return null
  if (!typesMap.get(type)) {
    typesMap.set(type, new Map())
  }
  const instanceMap = typesMap.get(type)
  const argsChain = args.reduce((map, arg) => {
    if (!map.get(arg)) {
      map.set(arg, new Map())
    }
    return map.get(arg)
  }, instanceMap)

  if (!argsChain.get(type)) {
    argsChain.set(type, new type(...args))
  }
  return argsChain.get(type)
}

export const factoryRefential = factory

/**
 * @const cached instances 
 */
const instances = {}

/**
 * Generates a singleton instance for each unique set of args
 */
export function factorySerialized(type, ...args) {
  const name = type.toString()
  let id = args.toString()
  try {
    id = JSON.stringify(args)
  } catch(error) {
    id = ''
  }
  
  if (!instances[name]) {
    instances[name] = {}
  }
  if (!instances[name][id]) {
    instances[name][id] = new type(...args)
  }
  return instances[name][id]
}

export { factory }