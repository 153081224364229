import React from "react";
import { observer } from "mobx-react";
import { getTimespansToTrackStore } from "Containers/PFA/stores/PFATimespansToTrackStore";
import { getTimespansToTrackOptionsStore } from "./stores";
import TimespansToTrackCheckBox from "./TimespansToTrackCheckBox";

class TimespansToTrack extends React.Component {

  static defaultProps = {
    options: getTimespansToTrackOptionsStore(),
    timespansToTrackStore: getTimespansToTrackStore(),
  };

  toggleTrackTimespan = (value) => {
    const { timespansToTrackStore } = this.props
    if (this.isTrackingTimespan(value)) {
      timespansToTrackStore.removeTimespanType(value)
    } else {
      timespansToTrackStore.addTimespanType(value)
    }
  }

  getValue() {
    return this.props.timespansToTrackStore.trackedTimespanTypes
  }

  isTrackingTimespan(type) {
    return this.props.timespansToTrackStore.hasTimespanType(type)
  }

  render() {
    return (
      <>
        {
          this.props.options.map(({ label, value }) => {
            const isTracking = this.isTrackingTimespan(value)
            return (
              <div key={value} className="settings-field">
                <TimespansToTrackCheckBox
                  value={value}
                  checked={isTracking}
                  onChange={this.toggleTrackTimespan}
                />
                <label htmlFor={value}>{label}</label>
              </div>
            )
          })
        }
      </>
    )
  }

}

export default observer(TimespansToTrack)
