import { observable, computed, override } from 'mobx';
import UserList from '../User/UserList'
import Item from '../Lists/Item'
import { importUser } from '../User'
import moment from 'moment'
import { OrganizationItem } from '../Organization';
import { TeamItem } from '../Team';
import { ProjectItem } from '../Project';
import { UserI } from 'Stores/User/Type/User';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';

export default class MeetingItem extends Item {

  @observable title:string = ''

  @observable type:string = ''

  @observable summary:string = ''

	@hasMany(() => UserList)
  users:UserList

  @observable meetingDate:Date = new Date()

  @observable meetingTime:number = 0

  @observable dateFormat:string = 'MMM D, YYYY'

  @observable recurring:boolean = false

  @observable createdBy:UserI

  @observable createdTS:number = Date.now()

  @observable complete:boolean = false

  @hasOne(() => OrganizationItem)
	organization:OrganizationItem

  @hasOne(() => TeamItem)
	team:TeamItem

	@hasOne(() => ProjectItem)
  project:ProjectItem

  @computed get done() {
    return this.meetingDate && this.meetingDate.getTime() < Date.now()
  }

  @computed get meetingDateFormatted() {
    return this.meetingDate ? moment(this.meetingDate).format(this.dateFormat) : ''
  }

  @override async save() {
    return await this.saveState.postJson('meeting/save', this.toJSON())
  }

  @override fromJSON(json:any) {
    const { projectId, createdBy, meetingDate } = json
    const item = super.fromJSON(json)
    this.project = projectId && ProjectItem.fromProps({ id: projectId })
    this.createdBy = createdBy && importUser().fromProps({ id: createdBy })
    this.meetingDate = meetingDate && new Date(meetingDate)
    return item
  }

}