import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { MemberItem } from 'Stores/Member';
import Select from 'theme/Form/Select';

const mockRoles = [
  { value: 'member', label: 'Member' },
  { value: 'editor', label: 'Editor' },
  { value: 'admin', label: 'Admin' },
  { value: 'superadmin', label: 'Super Admin' }
]

class DetailPeopleRole extends React.Component {

  static propTypes = {
    member: PropTypes.objectOf(() => MemberItem).isRequired,
  }

  static defaultProps = {
    selectRolePlaceholder: 'Member Role',
  }

  onChangeRole = role => {
    const { member } = this.props
    member.role = role.value
    member.save()
  }

  onCreateOption = () => {
    // todo
  }

  get options() {
    return mockRoles
  }

  get value() {
    const { role } = this.props.member
    return this.options.find(option => role === option.value)
  }

  render() {
    const { className, selectRolePlaceholder } = this.props
    const { options, value } = this
    return (
      <div className={'member-role ' + className}>
        <Select
          isClearable
          hasAddOption
          createOptionPosition={'first'}
          placeholder={selectRolePlaceholder}
          onChange={this.onChangeRole}
          onCreateOption={this.onCreateOption}
          options={options} 
          value={value}
        />
      </div>
    )
  }
}

export default observer(DetailPeopleRole)