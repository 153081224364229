import React from 'react';
import './SubMenu.scss';
import { observer } from 'mobx-react';
import { models, getStore } from 'Stores';
import moment from 'Modules/moment';

const defaultWeekdays = Array.apply(null, Array(7)).map(function (_, i) {
  return moment(i, 'e')
    .endOf('week')
    .isoWeekday(i + 1);
});

class HabitPlanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
    };
  }

  async componentDidMount(props) {
    const { habit, instanceList, planList } = this.props;

    instanceList.fetch(habit.id);
    planList.fetch(habit.id);
  }

  selectDate = (d) => {
    this.setState({ selectedDate: d });
  };

  static defaultProps = {
    get instanceList() {
      return getStore(models.InstanceList);
    },
    get planList() {
      return getStore(models.PlanList);
    },
  };

  render() {
    const { habit, instanceList, planList } = this.props;
    const { selectedDate } = this.state;
    console.log('render', { habit, instanceList });

    let planItems = planList.items.filter((e) => moment(e.date).isSame(selectedDate, 'day'));
    let planDict = {};
    planItems.forEach((p) => {
      if (p.instance && p.instance.id) planDict[p.instance.id] = p;
    });
    const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.budget);

    let total = planItems.reduce(reducer, 0);

    return (
      <>
        <div className="planner-header">
          <div className="title">{selectedDate.format('ll')}</div>
          <div className="subtitle">{selectedDate.format('dddd')}</div>
          <hr />
        </div>
        <div className="planner-container">
          {defaultWeekdays.map((d) => {
            return (
              <div className="planner-column" onClick={() => this.selectDate(d)}>
                <div className="bar" style={{
                  height: selectedDate.isSame(d, 'day') && total > 0 ? ((total/habit.periodUnits) * 100)+'%' : '100%'
                }}>
                  <div className="empty">
                    {selectedDate.isSame(d, 'day') && total > 0 ? total : habit.periodUnits}
                  </div>
                </div>
                <div
                  className={
                    'date-label ' + (selectedDate.isSame(d, 'day') ? 'date-label-selected' : '')
                  }
                >
                  {d.format('l')}
                </div>
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: 'block',
            width: '100%',
            padding: 12,
            borderRadius: 5,
            minWidth: 150,
            paddingTop: 5,
          }}
        >
          {JSON.stringify(planDict)}
          {instanceList.items.map((instance, idx) => {
            return (
              <PlanSlot
                planList={planList}
                plan={planDict[instance.id]}
                instance={instance}
                habit={habit}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default observer(HabitPlanner)

function PlanSlot({ plan, instance, habit, planList }) {
  const onBudgetChange = (event) => {
    // debug('duration changed', event);
    plan.budget = event.target.value;
    // this.props.instance.save();
  };
  return (
    <div className="plan-slot">
      <div>Plan</div>
      <div>{instance.title}</div>
      {plan ? (
        <>
          {' '}
          <input
            style={{ textAlign: 'right', marginRight: 3 }}
            className={'input-borderless'}
            value={plan.budget}
            onChange={onBudgetChange}
          />
        </>
      ) : (
        <>
          {' '}
          <a
            href="#newplan"
            onClick={() => {
              planList.newItem(habit, instance);
            }}
          >
            + Add a Plan
          </a>
        </>
      )}
    </div>
  );
}
