import React from 'react'
import ProjectSubMenu from 'Components/MainMenu/MainMenuItem/ProjectSubMenu'
import TaskSubMenu from 'Components/MainMenu/MainMenuItem/TaskSubMenu';
import ActionPlannerSubMenu from 'Components/MainMenu/MainMenuItem/ActionPlannerSubMenu';
import GoalsSubMenu from 'Components/MainMenu/MainMenuItem/GoalsSubMenu';
import CalendarSubMenu from 'Components/MainMenu/MainMenuItem/CalendarSubMenu';
import MetricsSubMenu from 'Components/MainMenu/MainMenuItem/MetricsSubMenu';

export const MainMenuComponents = [
  {
    slug: 'planner',
    SubMenu: () => <ActionPlannerSubMenu />
  },
  {
    slug: 'tasks',
    SubMenu: () => <TaskSubMenu />
  },
  {
    slug: 'projects',
    SubMenu: () => <ProjectSubMenu />
  },
  {
    slug: 'goals',
    SubMenu: () => <GoalsSubMenu />
  },
  {
    slug: 'calendar',
    SubMenu: () => <CalendarSubMenu />
  },
  {
    slug: 'metrics',
    SubMenu: () => <MetricsSubMenu />
  }
]