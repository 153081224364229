import { action, override } from 'mobx'
import List from '../Lists/List'
import CalendarAccount from './CalendarAccount'

const debug = require('debug')('treks:store:calendar:account:list')

export default class CalendarAccountList extends List {

  get ModelType() {
    return CalendarAccount
  }

  @override get items(): CalendarAccount[] {
    return super.items
  }

  /**
   * Fetch all calendar accounts and associated calendars
   * @return {Promise} Fullfilled when all calendars for users accounts is fetched
   */
  @override fetch() {
    debug('fetching', this)
    return this.fetchState.get('calendar/account/list')
      .then(resp => this.setItemsFromApi(resp.data.accounts))
      .then(fetches => Promise.all(fetches))
  }

  /**
   * Fetches each account for calendars
   * @param {object} accounts 
   */
  @action setItemsFromApi(accounts) {
    const items = accounts
      .map(({ id, service, email }) => {
        return CalendarAccount.fromProps({ id, service, email, items: [] })
      })
    this.setItems(items)
    return this.items.map(item => item.fetch())
  }

}
