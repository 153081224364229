import React from 'react';
import { observer } from 'mobx-react';
import HabitDetail from './HabitDetail/HabitDetail';

const debug = require('debug')('treks:component:goal');

class Habit extends React.Component {
	render() {
		const { habit, habitList } = this.props;
		debug('render', { habit, habitList });
		return (
			<div
				style={{
					padding: 10,
				}}
			>
				<HabitDetail habitList={habitList} habit={habit} />
			</div>
		);
	}
}

export default observer(Habit);
