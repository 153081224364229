import React from 'react'
import { observer } from 'mobx-react'
import './TasksImportLabel.scss'
import { FormControlDropdown } from 'theme/Form'
import { observable } from 'mobx';

const debug = require('debug')('treks:project:settings:label:sprint')

class TasksImportLabel extends React.Component {

  store = observable({
    project: null
  })

  onSprintLenChange = event => {
    const { onChange, field } = this.props
    onChange(event.target.value, field)
  }

  importTasksFromProject = project => {
    debug('importing tasks from project', { project })
    this.store.project = project
  }

  render() {
    const { field } = this.props
    const { project } = this.store
    const projectsOptions = [
      {
        label: 'Select',
        value: null,
      },
      {
        label: 'Project 1',
        value: 1,
      },
      {
        label: 'Project 2',
        value: 2,
      },
    ]
    debug('render', { field })
    return (
      <div className="project-tasks-import-label row-flex">
        <div className="project-settings-label">Import tasks from: </div>
        <FormControlDropdown
          className="field-select-import-tasks"
          items={projectsOptions}
          value={project}
          onChange={this.importTasksFromProject}
        />
        {
          project !== null && (
            <button className="btn-import">Import</button>
          )
        }
      </div>
    )
  }
}

export default observer(TasksImportLabel)
