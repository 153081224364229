import List from '../Lists/List'
import MemberItem from './MemberItem'
import { UserI } from 'Stores/User/Type/User'

export default class MemberList extends List {

  get ModelType() {
    return MemberItem
  }

  findITemByUser(user: UserI):MemberItem {
    return this.items.find(item => item.user === user)
  }

}