import { observable, computed, action, override } from "mobx";
import shajs from "sha.js";
import { ApiRequest } from "../Service";
import { Item } from "Stores/Lists";
import { hasOne } from "Relationships/RelationshipDecorators";
import { ProjectItem } from "Stores/Project";
import { TaskItem } from "Stores/Task";
import stores from "Stores";

const debug = require("debug")("treks:store:file");

function mimematch(mimeType, pattern) {
  const regex = new RegExp(pattern.replace("*", ".*"));
  return regex.test(mimeType);
}

/**
 * File
 */
export class FileItem extends Item {
  buffer: string | ArrayBuffer = null;
  binary = null;

  /**
   * @private
   */
  fileTypeMap = {
    "image/jpg": "jpg",
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/*": "image",
    "video/*": "video",
    "*/pdf": "pdf",
    "*/csv": "csv",
    "*/*word": "word",
    "*/*powerpoint*": "powerpoint",
    "*/json": "code",
    "*/javascript": "code",
    "*/html": "code",
    "*/*zip": "archive",
  };

  /**
   * @private
   */
  @observable remote = {
    hash: null,
    file: null,
    name: null,
    mimeType: null,
    size: null,
    path: null,
  };

  /**
   * @private
   */
  @observable local = {
    file: null,
  };

  /**
   * @property {bool} Is the file on server
   */
  @observable isUploaded = false;


  @observable hash = '';

  @observable isRemote = false;


  @computed get name(): string {
    return this.isRemote ? this.remote.name : this.file && this.file.name;
  }

  @computed get type() {
    const { mimeType } = this;
    const match =
      mimeType &&
      Object.entries(this.fileTypeMap).find(([pattern]) =>
        mimematch(mimeType, pattern)
      );
    return match ? match.pop() : "";
  }

  @computed get mimeType(): string {
    return this.isRemote ? this.remote.mimeType : this.file && this.file.type;
  }

  @computed get path(): string {
    return this.isRemote ? this.remote.path : this.file && this.file.path;
  }

  @computed get size(): number {
    return this.isRemote ? this.remote.size : this.file && this.file.size;
  }

  /**
   * @property {File | Promise<File>}
   */
  @computed get file() {
    if (this.isRemote) {
      if (!this.remote.file) {
        return this.downloadRemoteFile();
      }
      return this.remote.file;
    }
    return this.local.file;
  }
  set file(file) {
    this.local.file = file;
  }

  /**
   * @property {Project} Project file belongs to
   */
  @hasOne(() => stores.ProjectItem)
  project: ProjectItem;

  /**
   * @property {Task} Task file belongs to
   */
  @hasOne(() => stores.TaskItem)
  task: TaskItem;

  /**
   * @property {Comment} Comment file belongs to
   */
  @hasOne(() => stores.CommentItem)
  comment: Comment;

  /**
   * @property {ApiRequest}
   */
  @observable uploadState = new ApiRequest();

  /**
   * @property {ApiRequest}
   */
  @observable downloadState = new ApiRequest();

  /**
   * @param {File} file
   */
  @action setFile(file) {
    debug("set file", file);
    this.file = file;
  }

  @action setTask(task) {
    this.task = task;
  }

  @action setProject(project) {
    this.project = project;
  }

  @action setComment(comment) {
    this.comment = comment;
  }

  async toBinary() {
    if (this.binary) return this.binary;
    const file = await this.file;
    debug("toBinary this.file", file);
    if (!file) return null;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((this.binary = reader.result));
      reader.onabort = () => reject();
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  }

  toArrayBuffer = async (): Promise<string | ArrayBuffer> => {
    if (this.buffer) return this.buffer;
    const file = await this.file;
    debug("toArrayBuffer this.file", file);
    if (!file) return null;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve((this.buffer = reader.result));
      reader.onabort = () => reject();
      reader.onerror = (error) => reject(error);
      debug("file", file);
      reader.readAsArrayBuffer(file);
    });
  };

  async toNodeBuffer() {
    const ab = await this.toArrayBuffer();
    return ab && Buffer.from(ab as ArrayBuffer);
  }

  async toHash() {
    const file = await this.file;
    if (!file) {
      throw new Error('FileItem.file must be set before creating hash.')
    }
    if (!this.hash && file) {
      const buffer = await this.toNodeBuffer();
      if (buffer) {
        this.hash = shajs("sha256").update(buffer).digest("hex");
      }
    }
    return this.hash;
  }

  async toUrl() {
    return "file/download/" + (await this.toHash());
  }

  async downloadRemoteFile() {
    const { name, mimeType, hash, file } = this.remote;
    if (!hash) {
      throw new Error('File.remote.hash must be set before download.')
    }
    if (!file && hash) {
      try {
        const url = "file/download/" + this.remote.hash;
        const blob = await this.downloadState.download(url);
        this.remote.file = new File([blob], name, {
          type: mimeType, // @todo test
        });
      } catch (error) {
        debug("Failed to download remote file", error);
        return Promise.reject(error);
      }
    }
    return this.remote.file;
  }

  async toJSON() {
    const { project, task, comment } = this;
    const json = {
      name: this.name,
      path: this.path,
      size: this.size,
      mimeType: this.mimeType,
      hash: await this.toHash(),
      projectId: project ? project.id : null,
      taskId: task ? task.id : null,
      commentId: comment ? comment.id : null,
    };
    debug("toJSON", json);
    return json;
  }

  /**
   * @todo optimize by lazy creation of blob
   * @param {json} json
   */
  @override async fromJSON(json) {
    debug("fromJSON", { json });
    this.hash = json.hash;
    this.remote = json;
    this.isRemote = true;
    debug("fromJSON created", { json, self: this });
    return this;
  }

  async save() {
    debug("saving file", this);
    const hash = await this.toHash();
    let savedFile = null;
    try {
      const resp = await this.saveState.get("file/find", { hash });
      savedFile = resp && resp.data && resp.data.file;
    } catch (error) {} // eslint-disable-line no-empty
    if (!savedFile) {
      const json = await this.toJSON();
      savedFile = await this.saveState.post("file/save", json);
    }
    if (!savedFile.isUploaded) {
      savedFile = await this.uploadState.post("file/upload", {
        hash,
        binary: this.file,
      });
    }
    this.isUploaded = true;
    return savedFile;
  }
}

export default FileItem;
