import { getStore } from "Stores";
import { CalendarAccountList } from "Stores/Calendar";
import Model from "Stores/Model";

export class UserCalendarService extends Model {

  async getCalendarAccounts() {
    const calendarAccounts = getStore(CalendarAccountList) as CalendarAccountList
    await calendarAccounts.fetched()
    return calendarAccounts
  }

  async getCalendarAccountByEmail(email: string) {
    const calendarAccounts = await this.getCalendarAccounts()
    const account = calendarAccounts.items.find(account => account.email === email)
    await account.fetched()
    return account
  }


}

const userCalendarService = UserCalendarService.create() as UserCalendarService
export const getUserCalendarService = () => userCalendarService