import { observable, action, computed, override } from 'mobx'
import OrderedList from './OrderedList'
import { stores } from 'Stores'

const debug = require('debug')('treks:store:list:paged')

export class PagedOrdredList extends OrderedList {

  @observable totalItems = 0

  @observable perPageCount = 100

  @observable visiblePageIndex = 1

  @observable fetchedPageIndex = 0

  @computed get totalPages(): number {
    return Math.ceil(this.totalItems / this.perPageCount)
  }

  @computed get visibleItemsCount(): number {
    return this.visiblePageIndex * this.perPageCount
  }

  @override get visibleItems() {
    return super.visibleItems.slice(0, this.visibleItemsCount)
  }

  hasNextVisiblePage(): boolean {
    return this.visiblePageIndex < this.totalPages
  }

  hasNextFetchablePage(): boolean {
    return this.fetchedPageIndex === 0 || this.fetchedPageIndex < this.totalPages
  }

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('list/paged', {
      start,
      count
    })
  }

  fromJSON(data: any) {
    if (data.total) {
      this.totalItems = data.total
    }
    if (data.order) {
      debug('order', data.order)
      this.setOrder(data.order)
    }
    if (data.items) {
      this.setItemsFromJSON(data.items)
    }
    return this
  }

  async fetchNextPage(): Promise<any> {
    if (!this.hasNextFetchablePage()) return
    const start = this.fetchedPageIndex * this.perPageCount
    const count = this.perPageCount
    this.fetchedPageIndex++
    const req = await this.createFetchPageRequest(start, count)
    debug('req', req.data, { start, count }, req.data.items?.map(i => i.uid))
    if (req.data) {
      this.fromJSON({
        order: req.data.order,
        total: req.data.total || req.data.items?.length // no total so stop pagination
      })
      req.data.items && this.addItemsFromJSON(req.data.items)
    }
  }

  showNextPage() {
    if (this.hasNextVisiblePage()) {
      this.visiblePageIndex++
    }
  }

}