import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import './AccountSettings.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import stores, { getStore, models } from 'Stores'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import PaymentIcon from '../../../theme/PaymentIcon'
import SettingsGroup from './SettingsGroup'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:account:profile')

const PaymentMethod = ({ card_type, last_four }) => (
  <>
    <PaymentIcon icon={typeof card_type === 'string' && card_type.toLowerCase()} />
    <span> {card_type} ending {last_four}</span>
  </>
)

const BillingAddress = ({ address1, address2, city, state, zip, country }) => (
  <>
    { 
      ([address1, address2, city, state, zip, country])
        .filter(field => field)
        .map(field => (<span>{field}</span>))
    }
  </>
)

const BillingInfo = ({ BillingInfo }) => (
  <div className="settings-list">
    <SettingsGroup label="Payment Method" value={<PaymentMethod {...BillingInfo} />} />
    <SettingsGroup label="Billing Address" value={<BillingAddress {...BillingInfo} />} />
    <SettingsGroup value={<Link to={'/account/billing/history'}>Show payment history and receipts</Link>} />
  </div>
)

const NoBillingInfo = () => (
  <div className="settings-list">
    <SettingsGroup value={<Link to={'/account/billing/add'}>Add your billing details</Link>} />
  </div>
)

class AccountSettings extends React.Component {

  get account() {
    return getStore(models.Session).Account
  }

  componentDidMount() {
    this.fetchBillingInfo()
  }

  fetchBillingInfo() {
    this.BillingInfo = getStore(stores.BillingInfo)
    this.BillingInfo.fetch()
      .catch(error => {
        if (error && error.symbol === 'not_found') {
          return /* no billing info */
        }
        debug('error', error)
        getAppNotifications().error('Error fetching billing info')
      })
  }

  changePlan = () => {

  }

  deactivateAccount = () => {

  }

  render() {
    const { account } = this
    debug('render', { account, accountJSON: toJS(account) })
    return (
      <Container className="settings account-settings account-settings-billing">
        <Row className="settings-row account-settings-row">
          <Col>
            <header className="header">ACCOUNT - Personal</header>
            <div className="settings-list">
              <SettingsGroup label="Plan" value="Standard" />
              <SettingsGroup label="Payment Frequency" value="Annually" />
              <SettingsGroup label="Join Date" value="March 21, 2018" />
              <SettingsGroup label="Status" value="Active" />
              <SettingsGroup value={<Button variant="outline" onClick={this.changePlan}>Change Plan/Frequency</Button>} />
              <SettingsGroup value={<Button variant="outline" onClick={this.deactivateAccount}>Deactivate My Account</Button>} />
            </div>
          </Col>
        </Row>
        <Row className="settings-row account-settings-row">
          <Col>
            <header className="header">BILLING INFORMATION</header>
            {
              this.BillingInfo.card_type
                ? <BillingInfo BillingInfo={this.BillingInfo} />
                : <NoBillingInfo />
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default observer(AccountSettings)