import React from 'react'
import { observer } from 'mobx-react'
import './ProjectTaskStatusAssignment.scss'
import ProjectTaskStatusAssignmentList from './ProjectTaskStatusAssignmentList'
import users from 'Stores/User/items'
import Group from 'Stores/Group'
import AssignmentList from 'Stores/Project/ProjectTaskStatusAssignmentList'
import Pressable from 'theme/Pressable'

const debug = require('debug')('treks:project:settings:assignments')

global.Group = Group
global.users = users

const assignments = [
  {
    status: 'Planning',
    duration: 60
  },
  {
    status: 'In Process',
    duration: 150
  },
  {
    status: 'In Review',
    assignedTo: { user: users[0] },
    duration: 20
  },
  {
    status: 'Approval',
    assignedTo: { user: users[1] },
    duration: 5
  },
  {
    status: 'Complete'
  },
]

class ProjectTaskStatusAssignment extends React.Component {

  static defaultProps = {
    assignments: AssignmentList.fromItems(assignments)
  }

  onAddAssignment = () => {
    const { assignments } = this.props
    assignments.addItem({
      status: 'Test Status ' + assignments.items.length,
      duration: 15
    })
  }

  render() {
    const { project, settings, assignments } = this.props
    global.assignments = assignments
    debug('render', { project, settings, assignments })
    return (
      <div className="project-task-status-assignment">
        <ProjectTaskStatusAssignmentList
          project={project}
          assignments={assignments}
          settings={settings}
        />
        <Pressable
            className="btn-add-assignment"
            onClick={this.onAddAssignment}
          >
            + Add Status
          </Pressable>
      </div>
    )
  }
}

export default observer(ProjectTaskStatusAssignment)
