import { observable, action, override } from 'mobx';
import List from '../Lists/List'
import Calendar from './Calendar'

const debug = require('debug')('treks:store:calendar:account:item')

export default class CalendarAccount extends List {

  get ModelType() {
    return Calendar
  }

  /**
   * @property {string}
   */
  @observable email = null

  /**
   * @property {string} (gmail|ical)
   */
  @observable service = null

  @override fetch() {
    const { service, email } = this
    debug('fetching', this)
    return this.fetchState.get(`calendar/account/sync?service=${service}&email=${email}`)
      .then(resp => this.setApiItems(resp.data.calendars))
  }

  @action setApiItems(apiItems) {
    const items = (apiItems || [])
      .map(({ id, calendarId, title, description, timezone, category, actionPlanner }) => (
        { id, calendarId, title, description, timezone, category, actionPlanner }
      ))
    this.setItems(items)
  }

  createItemProps(props) {
    return { ...props, calendarAccount: this }
  }

}
