import React from 'react'
import { observer } from 'mobx-react'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import { 
  AdminDashboard,
} from '../../Components/Admin/Dashboard'
import { Switch, Route } from 'react-router-dom'

const modalStore = ModalStore.fromProps()
modalStore.setProps({
  show: true,
})

// do not allow closing
const canClose = false

class AdminUserModal extends React.Component {

  handleClose = () => {
    modalStore.setState({ show: false })
  }

  render() {
    const { show} = modalStore
    return (
      <Modal 
          show={canClose ? show : true} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="settings-modal modal-size-80"
        >
          <Modal.Body>
            <Switch>
              <Route path="/admin" component={AdminDashboard} />
            </Switch>
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(AdminUserModal)