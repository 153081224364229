import React from 'react'
import Pressable from "theme/Pressable";
import { components } from 'react-select';

const SortMenuItem = ({ style, onChange, checked, onClickLabel, label }) => {
  return (
    <div style={style}>
      <Pressable
        className="sort-within-label"
        onClick={onClickLabel}
      >
        <input
          type="checkbox"
          onChange={onChange}
          checked={checked}
        />
        {label}
      </Pressable>
    </div>
  )
}

export const SortMenuList = ({
    sortGroupingLabel,
    sortCategoryLabel,
    sortGroupingChecked,
    sortCategoryChecked,
    onSortGroupingChange,
    onSortCategoryChange,
    ...props 
  }) => {

  const sortWithinParentStyle = {
    borderTop: '1px solid #ccc',
    padding: 12
  }
  const sortWithinCategoryStyle = {
    padding: '0 12px 12px 12px'
  }
  const onChangeSortParent = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onSortGroupingChange(!sortGroupingChecked)
  }
  const onChangeSortCategory = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onSortCategoryChange(!sortCategoryChecked)
  }
  return (
    <components.MenuList {...props}>
      {props.children}
      <SortMenuItem
        style={sortWithinParentStyle}
        onChange={onChangeSortParent}
        checked={sortGroupingChecked}
        onClickLabel={onChangeSortParent}
        label={sortGroupingLabel}
        inputId='sort-within-parent'
      />
      <SortMenuItem
        style={sortWithinCategoryStyle}
        onChange={onChangeSortCategory}
        checked={sortCategoryChecked}
        onClickLabel={onChangeSortCategory}
        label={sortCategoryLabel}
        inputId='sort-within-category'
      />
    </components.MenuList>
  );
};