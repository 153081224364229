import List from "Stores/Lists";
import { action, override } from "mobx";
import { uid } from "Stores/utils";
import InstanceItem from "./InstanceItem";
import { HabitItem } from ".";
import { importPlanItem } from "./PlanItem";

export default class PlanList extends List {
  get ModelType() {
    return importPlanItem();
  }

  @action newItem(habit, instance) {
    let id = uid();
    return this.addItem({
      budget: 0,
      uid: id,
      instance,
      habit,
    });
  }

  @override async fetch(habitId, startDate = null, endDate = null) {
    let endpoint = "habit/fetch/" + habitId + "/plans";
    if (startDate) {
      endpoint += "?startDate=" + startDate.toString();
      endpoint += "&endDate=" + endDate.toString();
    }
    let promises = [];
    this.fetchState.get(endpoint).then(async ({ data }) => {
      let promise = null;
      let instancePromise = null;
      data.forEach((d) => {
        promise = HabitItem.fromId(data.habitId);
        d.habit = promise;

        instancePromise = InstanceItem.fromId(data.instanceId);
        d.instance = instancePromise;

        promises.push(promise);
      });
      await Promise.all(promises);
      this.fromJSON({ items: [...data] });
    });
  }
}
