import React from 'react'
import { observer } from 'mobx-react'
import './ListViewProjectStats.scss'
import { computed } from 'mobx'

const debug = require('debug')('treks:list-view:project:stats')

const formatFixed = fixed => {
  return Math.round(fixed) - fixed ? fixed : Math.round(fixed)
}

const Stat = ({ value, suffix, label, status }) => {
  return (
    <div className="project-stat">
      <div className="project-stat-amount">
        <span className={'project-stat-value project-stat-status-' + status}>
          {value}
        </span>
        {
          suffix && <span className="stat-suffix">{suffix}</span>
        }
      </div>
      <label>{label}</label>
    </div>
  )
}

class ProjectStats extends React.Component {

  inputRef = null

  @computed get stats() {
    const { cumulativeStats:stats } = this.props.project
    return [
      {
        value: stats.members.length,
        label: 'members',
      },
      {
        value: stats.tasks.length,
        label: 'tasks',
        status: ''
      },
      {
        value: stats.blockedTasks.length,
        label: 'blockers',
        status: 'warn'
      },
      {
        value: stats.overdueTasks.length,
        label: 'overdue tasks',
        status: 'warn'
      },
      {
        value: formatFixed(stats.totalHours),
        label: 'hours total',
      },
      {
        value: formatFixed(stats.totalHoursDone),
        label: 'hours complete',
      },
      {
        value: formatFixed(stats.completePercent) + '%',
        label: 'complete',
        status: 'success'
      },
      {
        value: formatFixed(stats.velocity),
        suffix: 'hrs/day',
        label: 'velocity (3 day)',
      }
    ]
  }

  constructor(props) {
    super(props)
    const { project } = props
    debug('component created', project)
  }

  render() {
    const { project } = this.props
    const { stats } = this
    debug('render', { project, stats })
    return (
      <div className="project-detail-stats">
        {
          stats.map(stat => (<Stat key={stat.label} {...stat} />))
        }
      </div>
    )
  }
}

export default observer(ProjectStats)