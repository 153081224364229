import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import { FormControlDropdown } from './'
import moment from 'moment'

const debug = require('debug')('treks:form:FormControlCardMeta')

const currYear = parseInt(moment().format('YYYY'), 10)
const yearItems = [ ...(new Array(20)).keys() ].map(i => (currYear + i).toString())

export default observer(function FormControlCardMeta(field) {
  let { placeholder, onChange, value = {}, cvvLabel = 'CVV' } = field
  const onMetaValueChange = metaValue => {
    const nextValue = { ...value, ...metaValue }
    onChange && onChange(nextValue)
  }
  const { month, year, cvv } = value
  debug('meta', { field, month, year, cvv  })
  return (
    <div className="form-control-card-meta">
      <Form.Control 
        {...field}
        id="card-meta-month"
        name="card-meta-month"
        value={month || ''}
        onChange={event => onMetaValueChange({ month: event.target.value })}
        className="form-control-meta form-control-meta-month"
        placeholder={placeholder.month}
      />
      <FormControlDropdown 
        {...field}
        id="card-meta-year"
        name="card-meta-year"
        value={year || ''}
        onChange={year => onMetaValueChange({ year })}
        className="form-control-meta form-control-meta-year"
        placeholder={placeholder.year}
        items={yearItems}
      />
      <div className="cvv-label">{cvvLabel}</div>
      <Form.Control 
        {...field}
        id="card-meta-cvv"
        name="card-meta-cvv"
        value={cvv || ''}
        onChange={event => onMetaValueChange({ cvv: event.target.value })}
        type="password"
        className="form-control-meta form-control-meta-cvv"
        placeholder={placeholder.cvv}
      />
    </div>
  )
})