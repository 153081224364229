import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import './AdminUser.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import stores from 'Stores'
import userAddFields from './Fields/UserAdd'
import UserForm from './UserForm'
import { getAppNotifications } from '../../../AppNotifications'

const debug = require('debug')('treks:admin:user:add')

const headerText = 'Add User'
const defaultSaveError = 'An error occurred while trying to save the user.'

export default withRouter(observer(class AdminUserAdd extends React.Component {

  userAddFields = stores.Form.fromFields(userAddFields)

  onSubmit = params => {
    debug('creating user', params)
    const user = stores.User.fromProps(params)
    user.add()
      .then(() => {
        getAppNotifications().success('The new user has been created!')
        this.userAddFields.reset()
      })
      .catch(error => {
        if (error.fields) {
          debug('Server sent errors', error)
          this.userAddFields.setFormValidationErrors(error)
          debug('errors set', this.userAddFields)
        }
        getAppNotifications().error(error.message || defaultSaveError)
      })
  }

  render() {
    debug('render', { userAddFields: this.userAddFields })

    return (
      <Container className="settings admin-user admin-user-list">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>

            <UserForm 
              fieldsStore={this.userAddFields}
              onSubmit={this.onSubmit}
            />

          </Col>
        </Row>
      </Container>
    )
  }
}))