import React from 'react'
import { observer } from 'mobx-react'
import GoalCommentItem from './GoalCommentItem'
import GoalCommentReply from './GoalCommentReply'
import PropTypes from 'prop-types'
import CommentList from 'Stores/Comment/CommentList'
import './GoalCommentList.scss'
import Followers from 'theme/Followers'
import GoalCommentNotify from './GoalCommentNotify'
import users from 'Stores/User/items'
import { UserList } from 'Stores/User'
import GoalAddReply from './GoalAddReply'

const userList = UserList.fromItems(users)

class GoalCommentList extends React.Component {

  static propTypes = {
    comments: PropTypes.objectOf(() => CommentList).isRequired,
  }

  onAddReply = (comment, reply) => {
    comment.replies.addItem({
      comment: reply.text
    })
    return true
  }

  onAddFollower = (comment, user) => {
    comment.followers.addItem({ user, comment })
  }

  render() {
    const { comments } = this.props
    if (!comments.items.length) return null
    return (
      <div className="goal-comment-list-container">
        {comments.items.map(comment => (
          <div  key={comment.id}>
            <div className="container comment-list">
              <GoalCommentItem comment={comment} />
              {comment.replies.items.length ? (
                <div className="comment-reply-list">
                  {
                    comment.replies.items.map(comment => (
                      <GoalCommentReply key={comment.id} comment={comment} />
                    ))
                  }
                </div>
              ) : null}
            </div>
            <div className="container-add-reply">
              <GoalAddReply onAddReply={reply => this.onAddReply(comment, reply)} />
            </div>
            <div className="container container-follow">
              <div className="row-flex">
                <Followers
                  followers={comment.followers}
                  users={userList}
                  onAddFollower={user => this.onAddFollower(comment, user)}
                />
                <GoalCommentNotify comment={comment} />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default observer(GoalCommentList)
