import List from "Stores/Lists";
import {importInboxItem} from './InboxItem'
import { action, observable, override } from 'mobx'

const debug = require('debug')('treks:models:InboxList')

export default class InboxList extends List {
  get ModelType() {
    return importInboxItem()
  }

  @observable focusedItem = null
  @observable unreadComments = 0
  @observable unreadActivities = 0

  @observable newest = Number.MIN_SAFE_INTEGER
  @observable oldest = Number.MAX_SAFE_INTEGER
  @observable total = 0

  @observable showArchived = false

  @observable searchTimeout = null
  @observable searching = false
  @observable searchTs = 0

  // @observable projects = InboxProjectGroupList //List singleton
  
  // @computed get groups() {
  //   const {items} = this


  //   let projectGroups = {}
  //   let taskGroups = {}
  //   let entries = []

  //   items.forEach(async (i, idx) => {
  //     if (i.project){
  //       i.project.uid = null
  //       if (!projectGroups[i.project.id]){
  //         projectGroups[i.project.id] = i.project
  //         projectGroups[i.project.id].newest = i.id //will be used for sorting
  //         projectGroups[i.project.id].entries = []
  //         projectGroups[i.project.id].tasks = []

  //       }
  //       projectGroups[i.project.id].entries.push(i)
  //     }
  //     else if (i.task){
  //       if (!taskGroups[i.task.id]){
  //         taskGroups[i.task.id] = i.task
  //         taskGroups[i.task.id].newest = i.id //will be used for sorting
  //         taskGroups[i.task.id].entries = []
  //         // this.props.inboxList.setFocusedItem(i)
  //       }
  //       taskGroups[i.task.id].entries.push(i)
        
  //     }
  //     else {
  //       entries.push(i)
  //     }
  //   })

  //   Object.values(taskGroups).forEach((t) => {
  //     if (t.project && t.project.id){
  //       if (projectGroups[t.project.id]){
  //         projectGroups[t.project.id].tasks.push(t)
  //         if (t.entries[0].id  > projectGroups[t.project.id].newest)
  //         projectGroups[t.project.id].newest = t.entries[0].id

  //       }

  //       taskGroups[t.id] = null
  //     }
  //   })

  //   return {projects: Object.values(projectGroups).sort((a,b) => b.newest - a.newest), tasks: Object.values(taskGroups)}
  // }

  @action switchView(){
    this.showArchived = !this.showArchived

    this.setItems([])

    //fetch a whole new list
    this.fetch([])
  }

  @action modifyUnread(type, num) {
    debug('modify unread', type, num)
    if (type === 'comments'){
      this.unreadComments += num
    } 
    if (type === 'activities'){
      this.unreadActivities += num
    } 
  }


  @action setFocusedItem(item) {
    debug('setting focused item', item)
    // if (this.focusedItem && this.focusedItem.id === item.id)
    // this.focusedItem = null
    // else
    this.focusedItem = item
  }

  @action archiveAll() {
    debug('archiving')
    this.saveState.post('inbox/archive')
    this.setItems([])
  }

  @override fromJSON(json:any) {
    super.fromJSON(json)
    debug('inboxlist fromjson', json)


    return this
  }

  @action search(term) {
    if (!term){
      this.fetch()
      return
    }

    if (this.searchTimeout){
      clearTimeout(this.searchTimeout)
    }

    function queryText(needle, haystack){
      debug(needle, haystack)

      let found = false
      needle = needle.toLowerCase()
      haystack.forEach((h) => {
        h = h.toLowerCase()

        if (h.indexOf(needle) > -1)
        found = true
      })

      return found
    }

    let arr = []
    this.items.forEach((i) => {
      if (queryText(term, [i.text, i.sender.name, i.title]))
      arr.push(i)
    })

    this.searchTimeout = setTimeout(() => {
      this.apiSearch(term)
    }, 200)

    debug('setting items', arr)

    this.setItems(arr)
  }

  @action apiSearch(term) {
    debug('doing api search, term')
    this.searching = true
    this.searchTs = Date.now()
    this.fetch([
      {type: 'before', val: this.oldest},
      {type: 'search', val: term},
      {type: 'ts', val: this.searchTs}
    ])
  }

  @override fetch(args = []) {
    debug('fetching with args', args)
    let endpoint = 'inbox/list/'
    let append = false


    if (this.showArchived) {
      if (args) args.push({type: 'archived', val: 1})
      else args = [{type: 'archived', val: 1}]
    }

    if (args){
      args.forEach((a, i) => {
        if (i === 0) endpoint += "?"+a.type+"="+a.val
        else endpoint += "&"+a.type+"="+a.val

        if (a.type === 'before')
        append = true


      })
    }
    return this.fetchState.get(endpoint)
      .then(({ data }) => {
        let arr = [...data.arr]
        if (this.searchTs && data.ts){
          if (data.ts < this.searchTs)
          return
        }
        debug('items fromjson', { items: arr})
        this.fromJSON({ items: append ? [...this.items].concat(arr) : arr, unreadComments: data.unread.comments, unreadActivities: data.unread.activities, total: data.total, newest: (data.newest > this.newest || !append) ? data.newest : this.newest, oldest: (data.oldest < this.oldest || !append) ? data.oldest : this.oldest, searching :false })

      

      })
  }


}

