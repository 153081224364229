import { action, computed, observable } from "mobx";
import Model from "Stores/Model";
import { ModelPropsI } from "Stores/Model/Type/Model";
import { getStore, models } from "Stores/Stores";
import TaskItem from "Stores/Task";
import ActionPlanner from "../ActionPlanner";
import { dateAddMins, isSameDay, toStartOfDayDate } from "../ActionPlannerUtils";
import { DayListFlow } from "./DayListFlow";
import { DatedItemFlow } from "./Event/DatedItemFlow";
import { TaskListFlow } from "./TaskListFlow";
import { TaskPieceFlow } from "./TaskPieceFlow";
import { TimespanPieceFlow } from "./TimespanPieceFlow";
import { PlannerTaskListFlow } from "./PlannerTaskListFlow";
import { TimespanFlow } from "./Timespan/TimespanFlow";
import { AccountTimespanList, getAccountTimespanList } from "./Timespan/AccountTimespanList";
import { getDevConsole } from "Stores/utils/debugging";
import { TimespanPieceSplit } from "./Timespan/TimespanPieceSplit";
import { getTaskPieceUid } from "./PlannerFlowUtils";
import { getTaskPiecesFlow } from "./PlannerTaskPiecesFlow";
import { PlannerEventsFlow } from "./PlannerEventsFlow";
import { getTimespanFlowMerged, getTimespanFlowSplitAtDay } from "./PlannerTimespanFlow";

const logger = getDevConsole()

export class PlannerFlow extends Model {

  // @todo remove actionPlanner when focusedDate is plannerFlow only
  actionPlanner: ActionPlanner = getStore(models.ActionPlanner)

  eventsFlow: PlannerEventsFlow = PlannerEventsFlow.fromProps({ 
    plannerFlow: this 
  }) as PlannerEventsFlow

  @computed get visibleEvents() {
    return this.eventsFlow.visibleEvents
  }

  @computed get allEvents() {
    return this.eventsFlow.events
  }

  getTaskPiecesOnDatedItem(datedItem: DatedItemFlow) {
    return this.eventsFlow.getTaskPiecesOnDatedItem(datedItem)
  }

  getFirstEventStartingWithTaskPiece(taskPiece: TaskPieceFlow, marginInclusive = 0) {
    return this.eventsFlow.getFirstEventStartingWithTaskPiece(taskPiece, marginInclusive)
  }

  getEventsDurationOnPiece(events: DatedItemFlow[], pieceStartDuration: number, pieceEndDuration: number) {
    return this.eventsFlow.getEventsDurationOnPiece(events, pieceStartDuration, pieceEndDuration)
  }

  getEventsOverlapping(event: DatedItemFlow): DatedItemFlow[] {
    return this.eventsFlow.getEventsOverlapping(event)
  }

  getEventsOverlappingEndDuration(event: DatedItemFlow) {
    return this.eventsFlow.getEventsOverlappingEndDuration(event)
  }

  @computed get minsSinceStartOfToday() {
    return Math.floor((this.currentDate.getTime() - this.todayDate.getTime()) / (60 * 1000))
  }

  @computed get minsSinceStartOfPlanner() {
    return Math.floor((this.currentDate.getTime() - this.startDate.getTime()) / (60 * 1000))
  }

  getMinsSinceStartOfPlanner(date: Date) {
    return Math.floor((date.getTime() - this.startDate.getTime()) / (60 * 1000))
  }

  getMinsSinceStartOfDay(date: Date) {
    const startDayDate = new Date(date)
    startDayDate.setUTCHours(0,0,0,0)
    return Math.floor((date.getTime() - startDayDate.getTime()) / (60 * 1000))
  }

  getDateAtPlannerDuration(mins: number) {
    return dateAddMins(this.startDate, mins)
  }

  @computed get focusedDate(): Date {
    return this.actionPlanner.focusedDate
  }
  set focusedDate(date: Date) {
    this.actionPlanner.setFocusedDate(date)
  }

  @computed get startDate(): Date {
    return this.getAttribute('startDate')
  }
  set startDate(date: Date) {
    this.setAttribute('startDate', toStartOfDayDate(date))
  }

  @computed get endDate(): Date {
    return this.getAttribute('endDate')
  }
  set endDate(date: Date) {
    this.setAttribute('endDate', toStartOfDayDate(date))
  }

  @observable startDuration: number = 0

  @computed get endDuration(): number {
    return (this.endDate.getTime() - this.startDate.getTime()) / (1000*60)
  }

  @computed get order(): string[] {
    return this.actionPlanner.order
  }

  @action setOrder(order: string[]) {
    this.order.splice(0, this.order.length, ...order)
  }

  async saveOrder() {
    return this.actionPlanner.saveOrder()
  }

  @computed get todayDate(): Date {
    return this.actionPlanner.startOfTodayDate
  }

  @computed get todayDuration(): number {
    return (this.todayDate.getTime() - this.startDate.getTime()) / (1000*60)
  }

  @computed get daysLength(): number {
    return this.dayList.daysLength
  }

  @observable accountTimespans: AccountTimespanList = getAccountTimespanList()

  @observable dayList: DayListFlow = DayListFlow.fromProps({
    plannerFlow: this
  })

  @observable taskList: TaskListFlow = PlannerTaskListFlow.fromProps({
    actionPlanner: this.actionPlanner
  })

  @computed get tasks(): TaskItem[] {
    const tasks =  this.taskList.visibleItems as TaskItem[]
    return tasks.filter(task => !task.isSticky)
  }

  @computed get stickyTasks(): TaskItem[] {
    const tasks =  this.taskList.items as TaskItem[]
    return tasks.filter(task => task.isSticky)
  }

  @computed get tasksDueToday(): TaskItem[] {
    return this.tasks.filter((task) => {
      return task.dueDate && isSameDay(task.dueDate, this.todayDate)
    })
  }

  @computed get tasksPastDue(): TaskItem[] {
    return this.tasks.filter((task) => {
      return task.dueDate && 
        (isSameDay(task.dueDate, this.todayDate) || task.dueDate < this.todayDate)
    })
  }

  @computed get orderSnapshot(): string[] {
    const uids = this.tasks.map(task => task.uid)
    const uiUids = this.timespanFlow.reduce((uids, timespan) => {
      const timespanUids = timespan.taskPieces.map(piece => piece.task.uid)
      return [ ...uids, ...timespanUids ]
    }, [])
    const allUids = [ ...uiUids, ...uids ]
    const ordereUids = allUids.filter((uid, index) => allUids.indexOf(uid) === index)
    return ordereUids
  }

  /**
   * @deprecated Use this.tasks which is ordered by ActionPlanner store
   * @todo fix this for done tasks and then re-use?
   */
  @computed get orderedTasks(): TaskItem[] {
    const sortedTasks = this.order
      .map(uid => this.tasks.find(task => task.uid === uid))
      .filter(task => task)
    const allTasks = [ ...sortedTasks, ...this.tasks ]
    return allTasks.filter((task, index) => allTasks.indexOf(task) === index) 
  }

  @computed get timespans(): TimespanFlow[] {
    return this.dayList.items
      .reduce((timespans, day) => {
        return [ ...timespans, ...day.timespanList.orderedItems ]
      }, [])
  }

  @action removeTimespan(timespan: TimespanFlow) {
    timespan.list.removeItem(timespan)
  }

  getTimespanFlowMerged(timespans: TimespanFlow[]) {
    return getTimespanFlowMerged(timespans, this)
  }

  @computed get timespanFlowSplitAtDay(): TimespanPieceFlow[] {
    return getTimespanFlowSplitAtDay(this.timespanFlowMerged, this)
  }

  @computed get timespanFlowMerged(): TimespanPieceFlow[] {
    return this.getTimespanFlowMerged(this.timespans)
  }

  @computed get timespanFlow(): TimespanPieceFlow[] {
    return this.timespanFlowMerged
  }

  @computed get timespanTypes(): string[] {
    const types = this.timespanFlow.map(timespan => timespan.type)
    return types.filter((type, index) => types.indexOf(type) === index)
  }

  @observable resizingPieceUid: string = null

  @observable resizingPieceDuration: number = null

  getTaskPiecesFlow(
    timespanPieces: TimespanPieceFlow[], 
    tasks: TaskItem[], 
    events: DatedItemFlow[],
    plannerFlow: PlannerFlow
  ): TaskPieceFlow[] {
    return getTaskPiecesFlow(timespanPieces, tasks, events, this)
  }

  @computed get taskPiecesFlow(): TaskPieceFlow[] {
    return this.timespanFlow.map(piece => piece.taskPieces).flat()
  }

  getTaskPiecesForTask(task: TaskItem) {
    return this.taskPiecesFlow.filter(taskPiece => {
      return task.uid === taskPiece.task.uid
    })
  }

  getFirstTaskPieceForTask(task: TaskItem) {
    return this.getTaskPiecesForTask(task).shift()
  }

  getTimespanPieceByUid(uid: string): TimespanPieceFlow|undefined {
    return this.timespanFlow.find(piece => piece.uid === uid)
  }

  getTaskPieceStartDate(taskPiece: TaskPieceFlow) {
    return dateAddMins(this.startDate, taskPiece.startDuration)
  }

  getTaskPieceEndDate(taskPiece: TaskPieceFlow) {
    return dateAddMins(this.startDate, taskPiece.endDuration)
  }

  /**
   * Set the duration 
   * while constraining timespan duration to other timespans in day (24hr total)
   */
  @action resizeTimespanPiece(piece: TimespanPieceFlow, delta: number, isResizeNext = true): number {

    // @note do not round delta as it will get out of sync
    // use roundTimespans() or round full duration when resizeStop

    const duration = piece.timespans.reduce((sum, t) => sum + t.duration, 0)
    const newDuration = duration + delta

    if (newDuration < 0) {
      delta = duration
    }

    logger.group('resize')

    logger.log('start', {
      type: piece.type, 
      piece: piece.duration, 
      timespans: duration,
      delta
    })

    const deltaLeft = isResizeNext ? this.resizeNextPieces(piece, delta) : 0
    const appliedDelta = delta - deltaLeft

    let deltaToApply = -appliedDelta
    this.resizePiece(piece, deltaToApply)
    

    logger.log('resized', {
      timespans: duration, 
      piece: piece.duration, 
      appliedDelta, 
      deltaLeft,deltaToApply
    })

    logger.groupEnd()

    return appliedDelta
  }

  @action resizePiece(piece: TimespanPieceFlow, delta: number) {
    piece.timespans.forEach(timespan => {
      if (timespan.duration < delta) {
        logger.log('resize timespan to zero', { delta, ts: timespan.duration })
        delta -= timespan.duration
        timespan.duration = 0
      } else {
        logger.log('resize timespan w/ delta', { delta, ts: timespan.duration })
        timespan.duration -= delta
        delta = 0
      }
    })
  }

  @action resizeNextPieces(piece: TimespanPieceFlow, delta: number): number {
    let nextDelta = delta

    const getNextTimespan = (timespan: TimespanFlow) => {
      const index = this.timespans.findIndex(t => t.uid === timespan.uid)
      return this.timespans[index + 1]
    }
    
    let timespan = piece.timespans.slice(-1).pop()
    let nextTimespan = getNextTimespan(timespan)

    while(nextDelta && nextTimespan) {

      // ignore previously 0ed timespans
      if (nextTimespan.duration === 0) {
        nextTimespan = getNextTimespan(nextTimespan)
        continue
      }

      logger.log('resize next', {
        type: nextTimespan.type, duration: nextTimespan.duration, nextDelta 
      })

      if (nextDelta > nextTimespan.duration) {
        nextDelta -= nextTimespan.duration
        nextTimespan.duration = 0
        nextTimespan = getNextTimespan(nextTimespan)
      } else {
        nextTimespan.duration = nextTimespan.duration - nextDelta
        nextDelta = 0
      }
    }

    return nextDelta
  }

  @action roundTimespans() {
    this.timespans.forEach(timespan => {
      if (Math.round(timespan.duration) !== timespan.duration) {
        timespan.duration = Math.round(timespan.duration)
      }
    })
  }

  getLastTimespanPiece(timespanPiece: TimespanPieceFlow) {
    const index = this.timespanFlow.findIndex(piece => piece.uid === timespanPiece.uid)
    const nextPieces = this.timespanFlow.slice(index)
    const lastIndex = nextPieces.findIndex(piece => piece.type !== timespanPiece.type)
    const lastPiece = nextPieces.slice(0, lastIndex).pop()
    return lastPiece
  }

}