import React from 'react'
import Button from 'theme/Button'

export const ListViewFilterBtn = ({ className, label, onClick }) => (
  <Button
    className={className}
    onClick={onClick}
  >
      {label}
  </Button>
)