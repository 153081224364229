import React from 'react'
import { observer } from 'mobx-react'
import data from './data'
import ResultsGraph from './ResultsGraph'

const debug = require('debug')('treks:goal:metrics:blockers')

class GoalMetricThrash extends React.Component {

  static defaultProps = {
    data
  }

  render() {
    const { data } = this.props
    debug('render', data)
    return (
      <div className="metrics-graph-container metrics-graph-blockers">
        <header>
          <h2>Goal Results</h2>
        </header>
        <div className="metrics-graph">
          <ResultsGraph data={data} />
        </div>
      </div>
    )
  }
}

export default observer(GoalMetricThrash)
