import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskCheckBox from 'Components/TaskDetail/TaskCheckbox'
import TaskTitle from 'Components/TaskDetail/TaskTitle'
import OnPlannerIcon from 'Components/ui/OnPlannerIcon'
import { DueDateField } from '../Shared/ListViewFields/DueDateField'
import { AssignedUserField } from '../Shared/ListViewFields/AssignedUserField'
import { TaskDurationField } from '../Shared/ListViewFields/TaskDurationField'
import { RemoveBtnField } from '../Shared/ListViewFields/RemoveBtnField'
import { getListViewUiStore } from '../Shared/ListViewContainer/models/ListViewUiModel'
import { TaskDetailChevron } from '../Shared/ListViewFields/TaskDetailChevron'

class ListViewTaskItem extends React.Component {

  static defaultProps = {
    dateFormatOpts: {
      dateFormat: 'MMM D, YYYY',
      timeFormat: ''
    }
  }

  render() {
    const { item, list, parent, fields, dateFormatOpts } = this.props
    const listUiStore = getListViewUiStore()
    const isSelected = listUiStore.isTaskSelected(item)
    return (
      <div className={"task-item" + (isSelected ? ' selected' : '')}>
        <Icon name={'drag'} color={'#97aaae'} size={10} />
        <OnPlannerIcon
          color={item.onPlanner ? '#97aaae' : '#dcdddf'}
          item={item}
          iconSize={15}
        />
        <TaskCheckBox item={item} size={14} />
        <TaskTitle item={item} list={list} parent={parent} />
        <div className="attributes">
          <div className="actions">
            <DueDateField
              item={item}
              list={list}
              fields={fields}
              dateFormatOpts={dateFormatOpts}
            />
            <AssignedUserField item={item} list={list} fields={fields} />
            <TaskDetailChevron item={item} list={list} fields={fields} />
            <TaskDurationField item={item} list={list} fields={fields} />
            <RemoveBtnField item={item} list={list} fields={fields} />
          </div>
        </div>
      </div>
     )
  }
}

export default observer(ListViewTaskItem)