import React from "react";
import { observer } from "mobx-react";
import Select from "theme/Form/Select";
import { getHabitPointsStore } from "Containers/PFA/stores/PFAHabitPointsStore";
import { getHabitExtraPointsOptions } from "./stores/HabitExtraPointsOptions";

class HabitExtraPointsSelect extends React.Component {

  static defaultProps = {
    options: getHabitExtraPointsOptions(),
    habitPointsStore: getHabitPointsStore(),
    defaultValue: '3'
  };

  onChange = ({ value }) => {
    this.props.habitPointsStore.setProp('extraPoints', parseInt(value))
  }

  getValue() {
    return String(this.props.habitPointsStore.extraPoints || this.props.defaultValue)
  }

  render() {
    const { options } = this.props
    return (
      <Select
        options={options}
        value={this.getValue()}
        onChange={this.onChange}
      />
    )
  }

}

export default observer(HabitExtraPointsSelect)
