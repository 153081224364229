import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import { stores } from 'Stores'

class UserAvatar extends React.Component {

  static defaultProps = {
    size: 30,
    initialsLen: 2,
    initialsSep: '',
    initialsEnd: ''
  }

  static propTypes = {
    user: PropTypes.objectOf(() => stores.User).isRequired
  }

  render() {
    const { user } = this.props
    const avatarProps = (({ user, ...props }) => props)(this.props) // eslint-disable-line no-unused-vars
    return (
      <Avatar 
        src={user.profileImage}
        name={user.name}
        {...avatarProps}
      />
    )
  }
}

export default observer(UserAvatar)