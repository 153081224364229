import List, { Item } from "Stores/Lists";
import { once } from "Stores/utils";

export const importDynamicItem = once(() => {
  class DynamicItem extends Item {
    /**
     * Override setProps to allow dynamic props
     * @param {any} props
     */
    setProps(props) {
      Object.assign(this, props);
    }
  }

  return DynamicItem;
});

export const importDynamicList = once(() => {
  class DynamicList extends List {
    get ModelType() {
      return importDynamicItem();
    }
  }

  return DynamicList;
});

export default importDynamicList;
