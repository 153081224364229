import React from 'react'
import { observer } from 'mobx-react'
import Select from './Select'

const debug = require('debug')('treks:theme:form:select')

/**
 * @todo verify props
 */
export default observer(function FormControlSelect(field) {
  let { placeholder, value, onChange, items = [], className, disabled } = field
  items = items.map(item => {
    return typeof item === 'string' 
      ? { label: item, value: item }
      : { label: (item.label || item.value), value: (typeof item.value !== 'undefined' ? item.value : item.label)}
  })
  debug('render select', { field, value })
  return (
    <Select  
      placeholder={placeholder}
      onChange={onChange}
      options={items}
      value={value}
      styles={selectStyles}
      className={className}
      disabled={disabled}
    />
  )
})

const selectStyles = {
  control: styles => {
    return {
      ...styles,
      minHeight: 30,
      height: 30,
      marginTop: 0,
      marginLeft: 5,
      minWidth: 150
    }
  },
}