import { action, computed, observable, override } from 'mobx'
import { GoalItem } from '.'
import TaskItem, { TaskList } from 'Stores/Task';
import { hasOne } from 'Relationships/RelationshipDecorators';


export type ProjectTaskDay = {
  startDate: Date;
  endDate: Date;
  activeTasks: TaskItem[];
  doneTasks: TaskItem[];
  createdTasks: TaskItem[];
  addedTasks: TaskItem[];
  deletedTasks: TaskItem[];
  remainingTasks: TaskItem[];
}

export type ProjectTaskWeek = {
  startDate: Date;
  endDate: Date;
  items: TaskItem[]
}

export type WeeksUnassignedTasks = ProjectTaskWeek & {
  assigned: TaskItem[];
  unAssigned: TaskItem[];
}

export default class GoalTaskList extends TaskList {

  get ModelType() {
    return TaskItem
  }

  @hasOne(() => GoalItem, (goal: GoalItem) => goal.taskList)
  goal: GoalItem

  @computed get unAssignedTasks() {
    return this.items
      .filter((task: TaskItem) => !task.assignedUser?.id)
  }

  @computed get assignedTasks() {
    return this.items
      .filter((task: TaskItem) => task.assignedUser?.id)
  }

  @observable dayCount = 20

  @computed get startDate() {
    const dayMs = 1000 * 60 * 60 * 24;
    return new Date(Date.now() - (this.dayCount * dayMs))
  }

  @computed get dayTasks(): ProjectTaskDay[] {
    const now = Date.now();
    const dayMs = 1000 * 60 * 60 * 24;
    const days = new Array(this.dayCount)
      .fill(null)
      .map((_, index) => index + 1)
      .reverse()
    const items = this.items as TaskItem[]
    const tasksDays = days.map((index) => {
      const startDate = new Date(now - index * dayMs);
      const endDate = new Date(now - (index - 1) * dayMs);
      
      const doneTasks = items.filter((task) => {
        const doneDate = task.doneDate;
        return doneDate < endDate && doneDate >= startDate;
      })  as TaskItem[];
      const createdTasks = items.filter((task) => {
        const createDate = task.createDate;
        return createDate < endDate && createDate >= startDate;
      })  as TaskItem[];
      const deletedTasks = items.filter((task) => {
        const trashedDate = task.trashedDate;
        return trashedDate < endDate && trashedDate >= startDate;
      })  as TaskItem[];
      const activeTasks = items.filter((task) => {
        const { done, createDate, doneDate } = task;
        return (!done || doneDate > endDate) && createDate < endDate
      })
      
      const excludeTasks = [ ...deletedTasks, ...createdTasks, ...doneTasks]
      const addedTasks = createdTasks
        .filter(task => !excludeTasks.includes(task))
      
      const remainingTasks = activeTasks
        .filter(task => !excludeTasks.includes(task))
      
      return {
        index,
        startDate,
        endDate,
        activeTasks,
        remainingTasks,
        doneTasks,
        createdTasks,
        addedTasks,
        deletedTasks,
        allTaks: items
      };
    });
    return tasksDays;
  }

  @computed get weekTasks(): ProjectTaskWeek[] {
    const now = Date.now();
    const weekMs = 1000 * 60 * 60 * 24 * 7;
    const activityWeeks = [7, 6, 5, 4, 3, 2, 1].map((weekIndex) => {
      const startDate = new Date(now - weekIndex * weekMs);
      const endDate = new Date(now - (weekIndex - 1) * weekMs);
      const items = this.items.filter((item: TaskItem) => {
        const createDate = item.createDate;
        return createDate <= endDate && createDate >= startDate;
      }) as TaskItem[];
      return {
        startDate,
        endDate,
        items
      };
    });
    return activityWeeks;
  }

  @computed get weeksUnassignedTasks(): WeeksUnassignedTasks[] {
    return this.weekTasks.map(week => {
      return {
        ...week,
        assigned: week.items.filter((task: TaskItem) => task.assignedUser?.id),
        unAssigned: week.items.filter((task: TaskItem) => !task.assignedUser?.id)
      }
    })
  }

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('task/list/paged', {
      start,
      count,
      projectId: this.project.id
    })
  }

  @override async fetch() {
    return this.fetchState.get('task/list?projectId=' + this.project.id)
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

  @action async fetchBurndown() {
    return this.fetchState.get('task/project/burndown', {
      projectId: this.project.id,
      startDate: this.startDate.getTime(),
      endDate: Date.now()
    })
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}