import { observable } from 'mobx'
import { Item } from '../Lists'
import { User } from '../User'
import { OrganizationItem } from '../Organization';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';
import { ProjectItem, ProjectList } from 'Stores/Project';
import { CategoryItem, CategoryList } from 'Stores/Category';

export default class TeamItem extends Item {

  @observable name:string = ''

  @observable description:string = ''

  @observable type:string = ''

  @observable createdTS:Date = new Date()

  @observable memberByInvitation:boolean = false

  @observable memberAutomatic:boolean = false

  @observable memberByRequest:boolean = false

  @observable memberOpen:boolean = false

  @observable hidden:boolean = false

  @hasOne(() => User, (user: User) => user.createdTeamList)
  createdBy: User

  @hasOne(() => OrganizationItem, (organization: OrganizationItem) => organization.team)
  organization: OrganizationItem

  @hasMany(() => ProjectList, (project: ProjectItem) => project.team)
  projectList: ProjectList

  @hasMany(() => CategoryList, (category: CategoryItem) => category.team)
  categoryList: CategoryList

  @observable PaymentMethod:string

}
