import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Icon from 'theme/Icon'
import './ProjectActivity.scss'
import { observable } from 'mobx';
import ProjectActivityList from './ProjectActivityList'
import { ProjectItem } from 'Stores/Project';
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:project:activity')

class ProjectActivity extends React.Component {

  static propTypes = {
    project: PropTypes.objectOf(() => ProjectItem).isRequired,
  }

  store = observable({
    view: null,
    order: null
  })

  componentDidMount() {
    const { project } = this.props
    project.activityList.fetch()
      .catch(error => {
        debug('activity fetch error', error)
        getAppNotifications().error('Error fetching activity')
      })
  }

  render() {
    const { project } = this.props
    debug('render', { project })
    return (
      <div className="project-activity">
        <header className="row tab-header">
          <Icon icon="attach_activity" size={15} />
          <div className="tab-title">HISTORY</div>
        </header>
        <ProjectActivityList
          project={project}
          activityList={project.activityList}
        />
      </div>
    )
  }
}

export default observer(ProjectActivity)