import 'whatwg-fetch'; // see https://github.com/github/fetch
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'; // https://github.com/mo/abortcontroller-polyfill#readme
import { request, createGetParams, createPostParams } from './request';

const debug = require('debug')('treks:api');

const optionsDefault = {
  method: "GET",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  redirect: "follow",
  referrer: "no-referrer",
  body: null,
  headers: {
    // backwards compat with xhr detection in express
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export class Api {

  baseUrl = null

  constructor({ baseUrl }) {
    if (baseUrl) this.setBaseUrl(baseUrl)
  }

  setBaseUrl(url) {
    this.baseUrl = url;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  createUrl(path = '', params) {
    return this.getBaseUrl() + path + (params ? '?' + createGetParams(params) : '');
  }

  fetch(path, options = {}) {
    return request(this.createUrl(path), { ...optionsDefault, ...options });
  }

  post(path, data = {}, options) {
    debug('post', { path, data });
    const params = createPostParams(data);
    const postOptions = {
      method: "POST",
      body: params,
      json: true,
      ...options,
    };
    return this.fetch(path, postOptions);
  }

  get(path, data = {}, options = {}) {
    debug('get', { path, data });
    const params = createGetParams(data);
    if (params.toString()) {path += '?' + params;}
    return this.fetch(path, { json: true, ...options });
  }

  download(path, data = {}, options = {}) {
    debug('download', { path, data });
    const params = createGetParams(data);
    if (params.toString()) {path += '?' + params;}
    return this.fetch(path, options)
      .then(resp => resp.blob());
  }

}