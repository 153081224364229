export const users = [
  {
    id: 1,
    name: 'Paul Seiter',
  },
  {
    id: 2,
    name: 'Gabe L',
  },
  {
    id: 3,
    name: 'Christina M'
  }
]
export default users
