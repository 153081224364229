import { override } from 'mobx';
import List from '../Lists/List'
import TeamItem from './TeamItem';

export default class TeamList extends List {

  @override items:Array<TeamItem> = []

  constructor(items:Array<TeamItem> = []) {
    super()
    items && this.setItems(items)
  }

  getItem(props:any):TeamItem {
    return TeamItem.fromProps(props)
  }

}