import React from 'react'
import Draggable from 'Modules/Draggable';

const debug = require('debug')('treks:module:DraggableWithScroll')

export class DraggableWithScroll extends React.Component {

  static defaultProps = {
    scrollElSel: 'body'
  }

  uiState = {
    scrollPos: { top: 0, left: 0, topStart: 0, leftStart: 0 },
    dragPos: { top: 0, left: 0 }
  }

  getScrollOffset() {
    const { scrollPos } = this.uiState
    const offset = {
      top: scrollPos.top - scrollPos.topStart,
      left: scrollPos.left - scrollPos.leftStart
    }
    debug('getScrollOffset', offset)
    return offset
  }

  getDragPosWithScroll({ top, left }) {
    const scrollOffset = this.getScrollOffset()
    const pos = {
      top: top + scrollOffset.top,
      left: left + scrollOffset.left
    }
    debug('getDragPosWithScroll', pos)
    return pos
  }

  getScrollEl() {
    return document.querySelector(this.props.scrollElSel)
  }

  // always called after onDragStart which sets this.uiState.dragPos
  onScroll = () => {
    const scrollEl = this.getScrollEl()
    if (!scrollEl) throw 'scrollEl not found'
    Object.assign(this.uiState.scrollPos, {
      top: scrollEl.scrollTop,
      left: scrollEl.scrollLeft
    })
    const scrollPos = this.getDragPosWithScroll(this.uiState.dragPos)
    this.props.onDrag(scrollPos)
    debug('onScroll', { scrollEl, scrollPos })
  }

  onDragStart = ({ top, left }) => {
    const scrollEl = this.getScrollEl()
    Object.assign(this.uiState, {
      scrollPos: {
        top: scrollEl.scrollTop,
        left: scrollEl.scrollLeft,
        topStart: scrollEl.scrollTop,
        leftStart: scrollEl.scrollLeft
      },
      dragPos: {
        top,
        left
      }
    })
    scrollEl.addEventListener('scroll', this.onScroll)
    debug('onDragStart', this.uiState, { top, left, scrollEl })
    this.props.onDragStart({
      top,
      left
    })
  }

  onDrag = ({ top, left }) => {
    debug('onDrag', { top, left })
    Object.assign(this.uiState.dragPos, {
      top,
      left
    })
    const dragPos = this.getDragPosWithScroll({ top, left })
    debug('onDrag', { dragPos })
    return this.props.onDrag(dragPos)
  }

  onDragEnd = ({ top, left }) => {
    const scrollEl = this.getScrollEl()
    scrollEl.removeEventListener('scroll', this.onScroll)
    const dragPos = this.getDragPosWithScroll({ top, left })
    this.props.onDragEnd(dragPos)
  }

  render() {
    const { 
      scrollElSel, children, onDrag, onDragStart, onDragEnd, 
      ...draggableProps } = this.props

    return (
      <Draggable
        onDrag={this.onDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        {...draggableProps}
      >
        {
          children
        }
      </Draggable>
    )
  }
}