import { observable, override } from 'mobx'
import { hasOne } from 'Relationships/RelationshipDecorators';
import { Item } from '../Lists';
import HabitItem from './HabitItem';

export default class HabitTemplate extends Item {
  
  @observable title:string

  @hasOne(() => HabitItem, (item:HabitItem) => item.template)
	habit:HabitItem

  @override async fetch() {
    return this.fetchState.get('habit/template/' + this.id)
      .then(resp => this.fromJSON(resp.data))
  }

}
