import MQTTChannel from './lib/channel'
import MqttTransport from './lib/mqtt'
import { EventEmitter } from 'events'

const debug = require("debug")("treks:mqtt:client");

const MQTT_HOST = process.env.REACT_APP_MQTT_HOST || 'ws://localhost:8883'

export type MqttUser = {
  id: string;
  email: string;
  token: string;
}

export default class MqttLiveSyncClient extends EventEmitter {

  opts = {
    host: MQTT_HOST
  }

  constructor(opts = {}) {
    super()
    this.setOpts(opts)
  }

  setOpts(opts = {}) {
    Object.assign(this.opts, opts)
  }

  async connect(user: MqttUser) {

    // optimize
    const mqtt = (await import('mqtt/dist/mqtt')).default

    global.mqtt = mqtt // debugging

    const mqttOptions = {
      clientId: user.id,
      username: user.email,
    }
  
    debug('Connecting to ', this.opts.host, 'as', user.id)
    const client = mqtt.connect(this.opts.host, mqttOptions);
    const transport = new MqttTransport({
      id: 'channelId',
      client
    });
  
    transport.once("connect", () => {
      this.createChannel(transport, user)
    });
  }
  
  createChannel(transport: MqttTransport, user: MqttUser) {
    debug('Subscribing to server')
    const channelId = 'storage/' + user.email
    const channel = transport.channel(channelId);
    this.subscribe(channel, user)
  }

  subscribe(channel: MQTTChannel, user: MqttUser) {
    debug("On secure channel");
    const events = ['create', 'update', 'upsert', 'delete']
  
    // storage events
    // channel.subscribe("+", data => {
    //   debug('storage event', data)
    // });

    events.map((event: string) => {
      channel.subscribe(event, (data) => {
        debug('mqtt emit ', { event, data })
        this.emit(event, data)
      });
    })
  
    // start sync
    channel.publish("auth", { user })
  }

}

