import React from 'react'
import { observer } from 'mobx-react'
import ProjectActivityItem from './ProjectActivityItem';

const debug = require('debug')('treks:project:activity:list')

class ProjectActivityList extends React.Component {

  render() {
    const { activityList } = this.props
    debug('render', { activityList })
    return (
      <div className="project-activity-list">
        {
          activityList.items.map(activityItem => {
            return <ProjectActivityItem
              key={activityItem.hash}
              activityItem={activityItem}
            />
          })
        }
      </div>
    )
  }
}

export default observer(ProjectActivityList)