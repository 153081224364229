import React from 'react'
import { observer } from 'mobx-react'
import TimeSpansOverview from './TimeSpansOverview'
import TimeSpanDetail from './TimeSpanDetail'

const debug = require('debug')('treks:planner:settings:header')

class PlannerSettingsHeader extends React.Component {

  render() {
    const { plannerFlow, plannerSettings, dayList, bucket } = this.props
    const { focusedItem } = dayList
    debug('render', focusedItem)
    return (
      <div className="planner-settings-header">
        <TimeSpansOverview
          plannerFlow={plannerFlow}
          plannerSettings={plannerSettings}
          dayList={dayList}
          bucket={bucket}
        />
        <TimeSpanDetail
          plannerFlow={plannerFlow}
          plannerSettings={plannerSettings}
          dayList={dayList}
          bucket={bucket}
        />
      </div>
    )
  }
}

export default observer(PlannerSettingsHeader)