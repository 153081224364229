import React from 'react'
import { observer } from 'mobx-react'
import { observable, when, toJS } from 'mobx'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'
import stores, { getStore } from 'Stores'
import { FormField } from 'theme/Form'
import fields, { caption } from './AddTeammatesFields'
import { getAppNotifications } from 'AppNotifications'

const debug = require('debug')('treks:account:register:team')

const headerText = 'Welcome to TREKS!'
const infoText = 'Tell us about your team'
const ContinueLabel = 'Continue'
const AddTeammateLabel = '+ Add Another Teammate'

export default withRouter(observer(class AccountRegisterTeam extends React.Component {

  formControlRefs = {}

  accountRegisterStore = getStore(stores.AccountRegister)
  sessionStore = getStore(stores.Session)
  accountStore = getStore(stores.Session).Account
  formStoreList = observable([
    stores.Form.fromFields([ ...fields ]),
    stores.Form.fromFields([ ...fields ]),
    stores.Form.fromFields([ ...fields ]),
  ])

  componentDidMount() {
    when(
      () => this.accountStore.id,
      () => {
        debug('autorun setting auth account to form', toJS(this.accountStore))
        this.accountRegisterStore.setProps(this.accountStore)
      }
    )
  }

  onClickAddTeammate = () => {
    debug('adding teammate form', this.formStoreList)
    this.formStoreList.push(stores.Form.fromFields([ ...fields ]))
  }

  onClickContinue = async () => {
    try {
      await this.sendEmailsToTeammates()
      this.props.history.push('/account/register/start-free-trial')
    } catch(error) {
      debug('team details error', error)
      getAppNotifications().error('An error occurred while trying to add your team details')
    }
  }

  async sendEmailsToTeammates() {
    const teammates = this.formStoreList.map(formStore => {
      return formStore.getFieldValues()
    })
    debug('sending emails to teammates', teammates)
  }

  onFieldValueChange = (field, value) => {
    debug('field change', field, value)
    field.value = value
  }

  render() {

    debug('render', { formStoreList: this.formStoreList })

    const busy = this.sessionStore.isAuthReqPending

    return (
      <Container className="account-register account-register-team settings">
        <Row className="centered-header">
          <Col>
            <header className="header">
              {headerText}
            </header>
            <div className="info-text">
              {infoText}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form className="account-register-form">

            <FormField
              {...caption}
            />

            {
              this.formStoreList.map((formStore, index) => {
                return (
                  <div className="add-teammate-group" key={index}>
                  { 
                    (
                      formStore.fields.map(field => {
                        return (
                          <FormField
                            key={field.name}
                            {...field}
                            disabled={field.disabled || busy} 
                            onChange={value => this.onFieldValueChange(field, value)} 
                          />
                        )
                      })
                    )
                  }
                  </div>
                )
              })
            }

            <Form.Group controlId="addTeammate">
              <div className="form-btn-container-add-teammate">
                <Button 
                  onClick={this.onClickAddTeammate} 
                  variant="outline"
                  disabled={busy}
                >
                  {AddTeammateLabel}
                </Button>
              </div>
            </Form.Group>
            
            <Form.Group controlId="continue">
              <div className="form-btn-container">
                <Button 
                  onClick={this.onClickContinue} 
                  variant="primary"
                  disabled={busy}
                >
                  {ContinueLabel}
                </Button>
              </div>
            </Form.Group>

          </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>
    )
  }
}))