import React from 'react'
import Button from 'theme/Button';
import Icon from 'theme/Icon';
import PropTypes from 'prop-types';
import './ButtonViewDetail.scss'

export default class ButtonViewDetail extends React.Component {

  static propTypes = {
    onClick: PropTypes.func
  }

  static defaultProps = {
    icon: 'arrow-right',
    iconSet: 'fa',
    className: '',
    size: 20,
    style: {},
    onClick: () => {}
  }

  render() {
    const { icon, iconSet, className, onClick, size, style } = this.props
    const btnStyle = {
      width: size,
      height: size,
      ...style
    }
    return (
      <Button className={'btn-view-detail ' + className} onClick={onClick} style={btnStyle}>
        <Icon icon={icon} set={iconSet} />
      </Button>
    )
  }
}
