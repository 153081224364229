import React from 'react'
import { observer } from 'mobx-react'
import MainMenu from 'Components/MainMenu'
import { items } from 'Stores/MainMenu'
import { MainMenuComponents } from './data/MainMenuComponents'
import { getStore, models } from 'Stores'

class MainMenuContainer extends React.Component {

  store = getStore(models.MainMenu)

  componentDidMount() {
    this.setMenuComponents(items)
    this.store.setItems(items)
  }

  // separated so models don't have component imports
  setMenuComponents(items) {
    Object.values(MainMenuComponents).forEach(({ slug, SubMenu }) => {
      const item = items.find(item => item.slug === slug)
      if (item) Object.assign(item, { slug, SubMenu })
    })
  }

  render() {
    return (
      <MainMenu store={this.store} />
    )
  }
}

export default observer(MainMenuContainer)