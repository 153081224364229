import React from 'react'
import AdminDashboardModal from './AdminDashboardModal'
import AdminUserModal from './AdminUserModal'
import { Route, Switch } from 'react-router-dom'

const AdminRoutes = () => (
  <Switch>
    <Route path="/admin/user" component={AdminUserModal} />
    <Route path="/admin" component={AdminDashboardModal} />
  </Switch>
)

export default AdminRoutes