import React from 'react'
import { observer } from 'mobx-react'
import ListViewTasksContainer from '../ListViewTasks'
import HabitList from 'Stores/Habit/HabitList'
import { getStore } from 'Stores'
import { ListViewList } from '../Shared/ListViewContainer/models/ListViewList'

class ListViewHabitsContainer extends React.Component {

  static defaultProps = {
    parentLists: ListViewList.create(),
    headers: [ // order matters
      {
        name: 'parent',
        label: 'PROJECT/GOAL:Category',
        required: true
      },
      {
        name: 'item_name',
        label: 'Habit Name',
        required: true
      },
      {
        name: 'due_date',
        label: 'Due Date'
      },
      {
        name: 'assigned_user',
        label: 'Assigned User'
      },
      {
        name: 'duration',
        label: 'Duration'
      }
    ]
  }

  componentDidMount() {
    this.props.parentLists.setItems([
      getStore(HabitList)
    ])
  }

  render() {
    return (
      <ListViewTasksContainer {...this.props} />
    )
  }
  
}

export default observer(ListViewHabitsContainer)