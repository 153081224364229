import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { ProjectItem } from 'Stores/Project'
import { CollapsableIcon } from '../Shared/CollapsableIcon'
import { makeObservable, observable } from 'mobx'
import ListViewProjectStats from './ListViewProjectStats'
import { RemoveBtn } from 'theme/RemoveBtn/RemoveBtn'
import { getStore, models } from 'Stores'
import { ProjectPane } from 'Stores/MainPaneGroup/data/MainPaneItems'

class ListViewProjectDetail extends React.Component {

  static propTypes = {
    item: PropTypes.instanceOf(ProjectItem).isRequired
  }

  @observable isCollapsed = false
  onPressCollapsable = () => {
    this.isCollapsed = !this.isCollapsed
  }

  constructor() {
    super()
    makeObservable(this)
  }

  onClickProjectTitle = () => {
    const { item } = this.props
    if (!item.id) return
    const paneItem = getStore(models.MainPaneGroup).addOrUpdateItem(ProjectPane)
    paneItem?.setModelId(item.id)
  }

  render() {
    const { item } = this.props
    const collapseClass = this.isCollapsed ? ' collapsed' : ''
    const inputStyle = {
      background: item.color,
      height: '100%',
      padding: '2px 5px',
      cursor: 'pointer'
    }
    return (
      <div className={"list-view-category-list"}>
        <header className="category-list-header">
          <a
            className='project-title parent-title-input title-input input-borderless'
            onClick={this.onClickProjectTitle}
            style={inputStyle}
          >
            {item.title}
          </a>
          <CollapsableIcon
            onPress={this.onPressCollapsable}
            isCollapsed={this.isCollapsed}
          />
          <RemoveBtn />
        </header>
        <div className={'category-list-body' + collapseClass}>
          <ListViewProjectStats project={item} />
        </div>
      </div>
    )
  }

}

export default observer(ListViewProjectDetail)