import React from 'react'
import { intercept } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import TaskItem from 'Stores/Task/TaskItem';
import { uid } from 'Stores/utils';

const debug = require('debug')('treks:task:list:title')

class ListTitle extends React.Component {

  static propTypes = {
    item: PropTypes.objectOf(() => TaskItem)
  }

  inputRef = null

  constructor(props) {
    super(props)
    const { item } = props
    debug('component created', item)
    this.dispose = intercept(item, 'focusOnTitle', change => {
      if (change.newValue === true) {
        this.inputRef && this.inputRef.focus()
      }
      return null
    })
  }

  componentWillUnmount() {
    this.dispose()
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    const { item } = this.props
    const { value } = event.target
    debug('title changed', event)
    item.setTitle(value)
    item.willSave()
  }

  revert() {
    const { parent, item } = this.props
    const { createdFromTask } = item
    debug('parent', parent, item)
    if (item.isDeletable) {
      createdFromTask.setTitle(item.title + ': ')
      createdFromTask.setFocusOnTitle()
      this.removeSelf()
    }
  }

  removeSelf() {
    const { parent, item } = this.props
    parent.removeItem(item)
  }

  /**
   * @todo move to ListItem
   */
  onKeyDown = event => {
    const { item } = this.props
    debug('keyDown', item, event.nativeEvent)
    if (event.key === 'Enter') {
      this.editNextEmptyItemOrAddOne()
    }
    // backspace + (CMD key or CTRL key)
    if (event.key === 'Backspace' && (event.metaKey || event.ctrlKey)) {
      this.focusOnPrevOrNextItemTitle()
      if (item.items.length > 0) {
        this.convertToFlatList()
      } else {
        this.removeSelf()
      }
    }
    if (event.key === ' ' && item.createdFromTask && !item.changed) {
      return this.revert()
    }
    if (item.createdFromTask && !item.changed) {
      this.untrackCreator()
    }
  }

  untrackCreator() {
    const { item } = this.props
    item.setChanged()
    debug('item created', item.createdFromTask)
    //item.createdFromTask.trash()
  }

  editNextEmptyListOrAddOne() {
    const { parent } = this.props
    const emptyList = parent.findFirstEmptyList() || parent.addItem(this.createEmptyList())
    emptyList.setFocusOnTitle()
  }

  createEmptyList() {
    return {
      id: uid(),
      title: '',
      items: []
    }
  }

  editNextEmptyItemOrAddOne() {
    const { list } = this.props
    const item = list.findFirstItemWithEmptyTitle() || list.addItem(this.createEmptyItem())
    debug('add or edit item', item)
    item.setFocusOnTitle()
  }

  createEmptyItem() {
    return {
      title: '',
      duration: 15
    }
  }

  convertToFlatList() {
    this.props.item.setProps({ isFlatList: true })
  }

  focusOnPrevOrNextItemTitle() {
    const { parent, item } = this.props
    const list = parent.getPrevItem(item) || parent.getNextItem(item)
    if (list) {
      const lastListItem = list.getLastItem()
      lastListItem ? lastListItem.setFocusOnTitle() : list.setFocusOnTitle()
    }
  }

  onFocus = event => {
    const { item, onFocus } = this.props
    onFocus && onFocus(event, item)
  }

  onBlur = event => {
    const { item, onBlur } = this.props
    onBlur && onBlur(event, item)
  }

  render() {
    const { item, list } = this.props
    debug('render', { item, list })
    return (
      <input 
        ref={this.onInputRef}
        className={"title title-input input-borderless " + (item.done ? 'done' : '')} 
        value={ item.title } 
        onChange={this.onChange} 
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    )
  }
}

export default observer(ListTitle)