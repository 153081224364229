import Model from "Stores/Model";
import { ModelContext } from "Stores/Model/ModelContext";
import { ModelPropsI } from "Stores/Model/Type/Model";

type TypeOfModel<M extends typeof Model> = M | (() => M);

export class StoreService {
  static contextKey = Symbol("StoreService.ModelContext");

  static _normalizeType<M extends typeof Model>(Type: TypeOfModel<M>): M {
    if (typeof Type === "function" && !(Type.prototype instanceof Model)) {
      return (Type as Function)();
    }
    return Type as M;
  }

  static getModel<M extends typeof Model>(
    Type: TypeOfModel<M>,
    context: any = this,
    key?: any,
    props?: ModelPropsI<M>
  ): Model {
    Type = this._normalizeType(Type);
    return this.getStorable(Type, context, key, props).fromProps();
  }

  static getStorable<T extends typeof Model, M extends Model = InstanceType<T>>(
    Type: TypeOfModel<T>,
    context: any = this,
    key?: any,
    props?: ModelPropsI<T>
  ): ModelContext<M> {
    Type = this._normalizeType(Type);
    if (!key) key = Type;
    // stores are saved on the context itself
    // this simplifies Mapping state to the context
    // a Proxy(context) !== context which makes state mapping complex
    if (!context[this.contextKey]) {
      Object.defineProperty(context, this.contextKey, {
        value: new Map<string, ModelContext<M>>(),
        enumerable: false,
      });
    }
    const stores = context[this.contextKey];
    let store: ModelContext<M> = stores.get(key);
    if (!store) {
      // @note props only set if model does not exist
      const model = (Type as typeof Model).fromProps(props);
      store = new ModelContext<M>(model as M, context, key);
      stores.set(key, store);
    }
    return store;
  }
}

// rekeyd get* since linter complains about use* hooks outside of function components
export const getStorable = <M extends typeof Model>(
  Type: M | (() => M),
  context?: any,
  key?: any,
  props?: ModelPropsI<M>
): ModelContext<InstanceType<M>> =>
  StoreService.getStorable(Type, context, key, props);

export const getModel = <M extends typeof Model>(
  Type: M | (() => M),
  context?: any,
  key?: any,
  props?: ModelPropsI<M>
): InstanceType<M> =>
  StoreService.fromProps(Type, context, key, props) as InstanceType<M>;
