import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import './ListViewFilter.scss'
import ListViewFilterRow from './ListViewFilterRow'
import { ListViewFilterBtn } from './ListViewFilterBtn'
import { ListViewFilterRows } from './ListViewFilterRows'
import { toJSON } from 'lib/utils'

const debug = require('debug')('treks:list:task:filter')

class ListViewFilter extends React.Component {

  static propTypes = {
    filter: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired
  }

  static defaultProps = {
    rows: ListViewFilterRows
  }

  onClose = () => {
    this.props.onClose()
  }

  onClear = () => {
    this.props.onClear()
  }

  onFilter = ({ name, value, label }) => {
    this.props.onFilter({ name, value, label })
    debug('onFilter', toJSON({ name, value, label }))
  }

  render() {
    const { rows, filter } = this.props
    debug('render', toJSON(filter))
    return (
      <div className="list-view-filter-container">
        <header className="filter-header">
          <h2>Filter</h2>
        </header>
        <section className="filter-body">
          {
            rows.map(row => (
              <ListViewFilterRow key={row.label} label={row.label}>
                <row.Component
                  name={row.name}
                  label={row.label}
                  value={filter[row.name] && filter[row.name].value}
                  onChange={this.onFilter}
                />
              </ListViewFilterRow>
            ))
          }
        </section>
        <footer className="filter-footer">
          <ListViewFilterBtn
            className="btn-filter-done"
            onClick={this.onClose}
            label="Done"
          />
          <ListViewFilterBtn
            className="btn-filter-clear"
            onClick={this.onClear}
            label="Clear"
          />
        </footer>
      </div>
     )
  }
}

export default observer(ListViewFilter)