import { observable, override } from "mobx";
import { HabitItem } from ".";
import { Item } from "../Lists";
import InstanceList from "./InstanceList";

export default class InstanceItem extends Item {
  get ListType() {
    return InstanceList;
  }

  @observable title: String;
  @observable habit: HabitItem;

  @observable time: Number = 0; //epoch date
  @observable duration: Number = 0;
  @observable notify: Number;
  @observable budget: Number = 0;

  @override fromJSON(json: any) {
    console.log("InstanceItem fromjson", json);

    super.fromJSON(json);

    return this;
  }

  async save() {
    console.log("saving");
    return this.saveState.postJson(
      "habit/instance/save/" + this.habit.id,
      this.toJSON()
    );
  }
}
