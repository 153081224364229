import { action, observable, computed, override } from 'mobx'
import TimeSpanList from '../TimeSpanList'
import { ApiRequest } from 'Stores/Service'

const debug = require('debug')('treks:store:timespan:list:settings:day')

/**
 * User timespan settings for each day of week
 */
export default class TimeSpanListDaySettings extends TimeSpanList {

  constructor(props) {
    super(props)
    this.emptyItem = null
  }

  toJSON() {
    const { order } = this
    const timespans = this.orderedItems.map(item => item.toJSON())
    debug('toJSON', { dayId: this.dayId, timespans, order })
    return { timespans, order }
  }

  @override fromJSON(json) {
    debug('fromJSON', json)
    if (json) {
      debug('fromJSON update', json)
      if (json.order) this.setOrder(json.order)
      if (json.timespans) this.setItems(json.timespans)
    }
    return this
  }

  @override fetch() {
    debug('fetch', this)
    return this.fetchState.get('account/settings/timespans/day/'  + this.dayId)
      .then(async resp => {
        debug('fetch resp', resp)
        resp.data && this.fromJSON(resp.data)
        return resp
      })
  }

  save() {
    debug('save settings', this.fetchState.state)
    return this.saveState.postJson(`account/settings/timespans/day/${this.dayId}/save`, this.toJSON())
  }

  /**
   * @param {Date} Focused date
   */
  @computed get date(): Date {
    return this.actionPlanner.focusedDate
  }

  @observable fetchByDateState = new ApiRequest()
  @observable saveByDateState = new ApiRequest()

  @computed get dateParam(): number {
    return this.date.getTime()
  }

  @action async fetchByDate() {
    debug('fetchByDate', this)
    return this.fetch()
      .then(() => this.fetchByDateState.get('account/settings/timespans/date/' + this.dateParam))
      .then(resp => {
        debug('fetchByDate resp', resp)
        resp.data && this.fromJSON(resp.data)
        return resp
      })
  }

  saveByDate() {
    debug('saveByDate', this.saveByDateState)
    return this.saveByDateState.postJson('account/settings/timespans/date/' + this.dateParam + '/save',  this.toJSON())
  }

}