import { override } from 'mobx'
import { ActivityList } from '../../Activity'
import TaskItem from 'Stores/Task/TaskItem'
import { TaskActivityItem } from 'Stores/Task/Activity'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class TaskActivityList extends ActivityList {

  get ModelType() {
    return TaskActivityItem
  }

  constructor() {
    super()
    this.type = 'task'
  }

	@hasOne(() => TaskItem, (task: TaskItem) => task.activityList)
  task: TaskItem

  @override fetch() {
    const { type, task: { id: taskId } } = this
    return this.fetchState.get('activity/list', { type, taskId })
      .then(({ data }) => {
        this.fromJSON(data)
        return { data }
      })
  }

  fromJSON(json) {
    const { task } = json
    const props = (({ task, ...props}) => props)(json) // eslint-disable-line
    super.fromJSON(props)
    if (task) {
      this.task = TaskItem.getModel({ id: task.id })  // do not update task
    }
    return this
  }

}