import React from 'react'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import './ActionPlannerSettings.scss'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import HelpTip from './HelpTip'
import PlannerSettingsDays from './PlannerSettingsDays'
import PlannerSettingsHeader from './PlannerSettingsHeader'
import { action } from 'mobx';
import { getAppNotifications } from 'AppNotifications'
import { getWeekPlanner } from 'Stores/ActionPlanner/Planner/WeekPlanner'
import { getPlannerSettings } from './models/PlannerSettings'

class PlannerSettings extends React.Component {

  static defaultProps = {
    syncDaysErrorMsg: 'Failed to sync planner days settings',
    syncErrorMsg: 'Failed to sync some days in planner settings',
    saveSuccessMsg: 'Updated your timespan settings for {day}',
    saveErrorMsg: 'Failed to update your timespan settings for {day}'
  }

  get plannerSettings() {
    return getPlannerSettings()
  }

  get plannerFlow() {
    return getWeekPlanner().plannerFlow
  }

  get dayList() {
    return this.plannerFlow.dayList
  }

  get bucket() {
    return this.plannerFlow.accountTimespans
  }

  async componentDidMount() {
    this.plannerFlow.actionPlanner.fetched()
    await this.bucket.fetch()
    this.sync()
    global.dayTimespans = this.dayTimespans
    global.plannerSettings = this.plannerSettings
  }

  @action async sync() {
    const { syncErrorMsg } = this.props
    try {
      return Promise.all([this.dayList.items.forEach(async day => {
        await day.fetchDaySettings()
      })])
    } catch(err) {
      console.err('Error sync', err)
      getAppNotifications().error(syncErrorMsg)
    }
  }

  render() {
    return (
      <Container className="planner-settings">
        <Row>
          <Col className="settings-side-bar" md={2}>
            <Row>
              <header>Overdue Item <HelpTip tooltip="Overdue Item Help Text" /> </header>
            </Row>
            <div className="alert-items">
              <Row>
                <Icon icon="alert" color="#657e82" /> 
                No Alert
              </Row>
              <Row>
                <Icon icon="volume" color="#657e82" />
                5
              </Row>
            </div>
          </Col>
          <Col className="settings-content">
            <Row>
              <header>Time spans <HelpTip tooltip="Time Spans Help Text" /> </header>
            </Row>
            <Row>
              <PlannerSettingsHeader
                plannerFlow={this.plannerFlow}
                plannerSettings={this.plannerSettings}
                dayList={this.dayList}
                bucket={this.bucket}
              />
            </Row>
            <Row className="time-spans">
              <PlannerSettingsDays 
                plannerFlow={this.plannerFlow}
                plannerSettings={this.plannerSettings}
                bucket={this.bucket}
                dayList={this.dayList} 
              />
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default observer(PlannerSettings)