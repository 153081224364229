import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import DetailPeopleItem from './DetailPeopleItem'
import Table from 'react-bootstrap/Table'
import { UserList } from 'Stores/User'
import Model from 'Stores/Model'
import './DetailPeople.scss'

const debug = require('debug')('treks:detail:people:list')

class DetailPeopleList extends React.Component {

  static propTypes = {
    userList: PropTypes.objectOf(() => UserList).isRequired,
    item: PropTypes.objectOf(() => Model).isRequired,
  }

  async componentDidMount() {
    await this.props.userList.fetched()
  }

  render() {
    const { userList, item } = this.props
    const { memberList } = item
    debug('render', { memberList })
    if (!memberList.items.length) return null
    return (
      <Table responsive className="detail-people-table">
        <thead>
          <tr>
            <th></th>
            <th>
              <div className="notification-header">Notifications</div>
            </th>
            <th colSpan="2"></th>
          </tr>
          <tr>
            <th></th>
            <th>
              <div className="notification-header">
                <label>Conversations</label>
                <label>Tasks</label>
                <label>Status</label>
              </div>
            </th>
            <th colSpan="2"></th>
          </tr>
        </thead>
        <tbody>
          {
            memberList.items.map(member => {
              return (
                <DetailPeopleItem
                  key={member.uid}
                  member={member}
                  memberList={memberList}
                  userList={userList}
                />
              )
            })
          }
          </tbody>
      </Table>
    )
  }
}

export default observer(DetailPeopleList)
