import React from 'react'
import { observer } from 'mobx-react'
import FormControlSelect from 'theme/Form/FormControlSelect';

class GoalDetailFieldDropdown extends React.Component {

  onFieldChange = option => {
    const { field, goal } = this.props
    goal[field.name] = option.value
    goal.save()
  }

  render() {
    const { field, goal } = this.props
    const { name, items } = field
    const value = goal[field.name]
    return (
      <FormControlSelect
        name={name}
        items={items}
        value={value}
        onChange={this.onFieldChange}
      />
    )
  }
}

export default observer(GoalDetailFieldDropdown)
