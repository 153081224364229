import React from 'react'
import { observer } from 'mobx-react'
import { CalendarEvent } from 'Stores/Calendar'
import Select from 'theme/Form/Select'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import { CalendarEventReminder, ReminderMethod, ReminderPeriod, reminderMethods, reminderPeriods } from 'Stores/Calendar/CalendarEventReminder'
import { XIcon } from 'lucide-react'

const debug = require('debug')('treks:event:notification')

type EventNotificationProps = {
  event: CalendarEvent;
  notification: CalendarEventReminder;
}

type SelectOption = {
  label: string;
  value: string;
}

class EventNotification extends React.Component<EventNotificationProps> {

  static defaultProps = {
    
  }

  uiState = ArbitraryModel.fromProps({
    modalOpen: false,
  })

  getEventReminderMethodsOptions(): SelectOption[] {
    return reminderMethods.map(method => ({
      label: method[0].toUpperCase() + method.substring(1),
      value: method
    }))
  }

  getEventReminderMethodOption(options: SelectOption[], method: ReminderMethod): SelectOption {
    return options.find(option => option.value === method)
  }

  onChangeReminderMethod = (option: SelectOption) => {
    this.props.notification.setMethod(option.value as ReminderMethod)
    this.props.event.willSave()
  }

  getEventReminderPeriodsOptions(): SelectOption[] {
    return reminderPeriods.map(period => ({
      label: period[0].toUpperCase() + period.substring(1),
      value: period
    }))
  }

  getEventReminderPeriodsOption(options: SelectOption[], period: ReminderPeriod): SelectOption {
    return options.find(option => option.value === period)
  }

  onChangeReminderPeriod = (option: SelectOption) => {
    this.props.notification.setPeriod(option.value as ReminderPeriod)
    this.props.event.willSave()
  }

  onChangeReminderValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { notification } = this.props
    const value = Math.max(0, Number(event.target.value))
    notification.setMinutesByValueAndPeriod(value, notification.period)
    this.props.event.willSave()
  }

  onRemoveNotification = () => {
    this.props.event.notifications.removeItem(this.props.notification)
    this.props.event.willSave()
  }

  render() {
    const { event, notification } = this.props
    const { notifications } = event

    const reminderMethodsOptions = this.getEventReminderMethodsOptions()
    const reminderMethodOption = this.getEventReminderMethodOption(reminderMethodsOptions, notification.method)
    
    const reminderPeriodsOptions = this.getEventReminderPeriodsOptions()
    const reminderPeriodsOption = this.getEventReminderPeriodsOption(reminderPeriodsOptions, notification.period)

    debug('render', { event, notifications })
    return (
      <div className="event-notification">
        <Select
          value={reminderMethodOption}
          options={reminderMethodsOptions}
          onChange={this.onChangeReminderMethod}
          className="reminder-method-select"
        />
        <input
          className='reminder-value-input'
          type='number'
          value={notification.value}
          onChange={this.onChangeReminderValue}
        />
        <Select
          value={reminderPeriodsOption}
          options={reminderPeriodsOptions}
          onChange={this.onChangeReminderPeriod}
          className="reminder-period-select"
        />
        <button onClick={this.onRemoveNotification} style={{ border: 0, background: 'transparent' }}>
          <XIcon color='#ddd' />
        </button>
      </div>
    )
  }
}

export default observer(EventNotification)
