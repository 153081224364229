import ProjectItem from "../ProjectItem";
import { hasOne } from "Relationships/RelationshipDecorators";
import { computed } from "mobx";
import { ProjectBlockerItem } from "./ProjectBlockerItem";
import { ActivityList } from "Stores/Activity";

export class ProjectBlockerList extends ActivityList {

  get ModelType() {
    return ProjectBlockerItem;
  }

  @hasOne(() => ProjectItem, (project: ProjectItem) => project.blockerList)
  project: ProjectItem;

  @computed get weeksBlockers() {
    const activityWeeks = this.activityWeeks.map((week) => {
      const blockers = week.items.filter(item => item.type === 'task.blocker');
      const unblockers = week.items.filter(item => item.type === 'task.unblocker');
      
      return {
        blockers: blockers as ProjectBlockerItem[],
        unblockers: unblockers as ProjectBlockerItem[],
        items: week.items as ProjectBlockerItem[]
      };
    });
    return activityWeeks;
  }

  async fetch() {
    const res = await this.fetchState.get("activity/project/blocker", {
      projectId: this.project.id,
      sinceTimestamp: this.startDate.getTime(),
    });
    console.log('res', res.data)
    return this.fromJSON(res.data);
  }
}
