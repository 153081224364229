import { observer } from 'mobx-react'
import './CalendarDayLabel.scss'

type CalendarDayLabelProps = {
  date: Date;
  isFocused: boolean;
  onFocusDate: (date: Date) => void;
}

export const CalendarDayLabel = observer(({ date, isFocused, onFocusDate }: CalendarDayLabelProps) => {
  return (
    <label className={'calendar-day-label'}>
      <button
        className={'label-btn ' + (isFocused ? 'is-focused' : '')}
        onMouseDown={event => {
          event.preventDefault()
          onFocusDate && onFocusDate(date)
        }}
      >
        <span>
          {date.getMonth() + '/' + date.getMonth() + 1}
        </span>
        <span>
          {date.toLocaleDateString('en-US', { weekday: 'long' })}
        </span>
      </button>
    </label>
  )
})