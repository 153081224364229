import React from 'react'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { withRouter, Link } from 'react-router-dom'
import stores, { getStore } from 'Stores'
import AccountRegisterFooter from './AccountRegisterFooter'
import { getAppNotifications } from '../../../AppNotifications'

const AccountRegisterLabel = 'Register your free account'
const AccountRegisterButtonText = 'Register your account'
const inputPlaceholder = 'name@company.com'
const accountRegisterInfoMsg = 'Please enter your email to create your Treks account.'
const invalidEmailMsg = 'Please enter a valid email address'
const failedCreatingAccountMsg = 'An error occurred while trying to create your account'
const failedSendingEmailMsg = 'An error occurred sending your verification email'

const debug = require('debug')('treks:component:account:register')

export default withRouter(class AccountRegisterComponent extends React.Component {

  accountRegisterStore = getStore(stores.AccountRegister)

  state = {
    email: null
  }

  static defaultProps = {
    agreeToTOSErrorMsg: 'You must agree to the terms of service to complete your registration'
  }

  onKeyUp = event => {
    const email = event.target.value
    debug('keypress', event.key, this.state.email, email)
    if (event.key === 'Enter') {
      this.registerAccount()
    } else {
      this.setState({
        email
      })
    }
  }

  validateEmail(email) {
    return email && email.toString().match(/.+@.+\..+/);
  }

  onClick = event => {
    event.preventDefault()
    this.registerAccount()
  }

  registerAccount() {
    const { agreeToTOSErrorMsg } = this.props
    const { email } = this.state
    if (!this.accountRegisterStore.agreedToTOS) {
      return getAppNotifications().error(agreeToTOSErrorMsg)
    }
    if (!this.validateEmail(email)) {
      return getAppNotifications().error(invalidEmailMsg)
    }
    this.accountRegisterStore.setProps({ email })
    return this.accountRegisterStore.registerAccount()
      .then(() => this.sendVerificationEmail())
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedCreatingAccountMsg)
      })
  }

  sendVerificationEmail() {
    return this.accountRegisterStore.sendVerificationEmail()
      .then(() => {
        const { email } = this.accountRegisterStore
        this.props.history.push('/account/register/verify/' + email)
      })
      .catch(error => {
        debug('error', error)
        getAppNotifications().error(failedSendingEmailMsg)
      })
  }

  render() {
    const { registerAccountState, sendEmailState } = this.accountRegisterStore
    const busy = registerAccountState.state === 'pending' || sendEmailState.state === 'pending'
    return (
      <Container className="account-register account-register-email settings">
        <Row>
          <Col>
            <header className="header">
              {AccountRegisterLabel}
            </header>
            <Form.Group>
              <p className="info">
                {accountRegisterInfoMsg}
              </p>
              <InputGroup>
                <Form.Control
                  autoFocus
                  className="input input-email"
                  placeholder={inputPlaceholder}
                  onKeyUp={this.onKeyUp}
                  type="email"
                  disabled={busy}
                />
                <Button 
                    onClick={this.onClick} 
                    variant="primary"
                    disabled={busy}
                  >
                    {AccountRegisterButtonText}
                  </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Already have a Treks Account? <Link to="/account/login">Login to Treks</Link></Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountRegisterFooter />
          </Col>
        </Row>
      </Container>
    )
  }
})