import Model from "Stores/Model"

export class PFAScoreSnap extends Model {

  pfaScore: number

  plan: number

  focus: number

  accomplish: number
  
}