import { override } from 'mobx'
import { CategoryItem } from '.'
import { TaskList } from 'Stores/Task';
import { hasOne } from 'Relationships/RelationshipDecorators';
import { getStore, models } from 'Stores';

export class CategoryTaskList extends TaskList {

  @hasOne(() => CategoryItem, (category: CategoryItem) => category.taskList)
  category: CategoryItem

  @override get parent() {
    return this.category
  }

  // @note item.category and addItem both update the same relation but are needed
  // this is because relationships call addItem() to push to lists
  // overriding addItem() to not add to a list makes the item never add to a list!
  addItem(props?: any, index?: number) {
    const item = this.createItem(props)
    const taskList = getStore(models.TaskList) as TaskList
    if (!taskList.hasItem(item)) {
      taskList.addItem(item, index) // @todo: fix push to global taslist
    }
    if (!this.hasItem(item)) {
      // needed or it will never be added as relation calls addItem()
      super.addItem(item, index) 
    }
    item.project = this.category.project // ensure same project
    return item
  }

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('task/list/paged', {
      start,
      count,
      categoryId: this.category.id,
      projectId: this.category.project.id,
      // goalId: this.category.goal.id
    })
  }

  @override async fetch() {
    return this.fetchState.get('category/task/list/' + this.parent.id)
      .then((resp: any) => resp && this.fromJSON(resp.data))
  }

}