export const pfaEnglishLang = {
  timespansToTrack: 'Timespans to track',
  daysToTrack: 'Days to track',
  pfaGoal: 'PFA Goal',
  scoreWeighting: 'Score Weighting',
  plan: 'Plan',
  focus: 'Focus',
  accomplish: 'Accomplish',
  targetProductivity: 'Target Productivity',
  thrashAcceptableRange: 'Execution (Thrash) acceptable range',
  thrashAcceptableRangeInfo: `In order to accomodate important interactions with others, we recommend starting with a low end around 65%.
  If the low end is too low, you never have to follow your plan. If it is too high you aren't able to accomodate others.
  The high end can either be 100% or something slightly less. Less than 100% penalizes you if you don't change your plan at least somewhat.
  `,
  habits: 'Habits',
  pointsEarnedAfter: 'Points Earned After',
  extraPoints: 'Extra Points',
  extraPointsLimit: 'Extra Points Limit',
  habitsInfo: 'Extra points are earned after each habit is logged or only after a certain number (or all) of the habits due that day are logged.',
  kindDeeds: 'Kind Deeds',
}

export default pfaEnglishLang