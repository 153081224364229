import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom';
import { getWeekPlanner } from 'Stores/ActionPlanner/Planner/WeekPlanner';
import ActionPlannerFooter from './ActionPlannerFooter';

class WeekPlannerFooter extends React.Component {

  static defaultProps = {
    classNamePrefix: 'week-planner-footer',
    get plannerUi() {
      return getWeekPlanner()
    },
    getPaneEl() {
      return document.querySelector('.main-pane-component:has(.action-planner.week-view)')
    }
  }

  onToggleCollapse = () => {
    this.props.plannerUi.toggleUiExpandCollapse()
  }

  render() {
    const { plannerUi, getPaneEl, classNamePrefix } = this.props
    return (
      <ActionPlannerFooter
        classNamePrefix={classNamePrefix}
        plannerUi={plannerUi}
        getPaneEl={getPaneEl}
        onToggleCollapse={this.onToggleCollapse}
      />
    )
  }
}

export default withRouter(observer(WeekPlannerFooter))
