import React from 'react'
import { observer } from 'mobx-react';
import './TaskProject.scss'

const TaskProject = ({ project }) => {
  const { color, title } = project
  const styles = {
    backgroundColor: color
  }
  return (
    title ? (
      <div className="planner-task-item-project" style={styles}>
        <div>{title}</div>
      </div>
    ) : null
  )
}

export default observer(TaskProject)