import React from 'react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'

const debug = require('debug')('treks:theme:Pressable:Icon')

export default class PressableIcon extends React.Component {

  static defaultProps = {
    colorPressed: '#808285',
    color: '#666',
    icon: null,
    iconPressed: null,
    set: 'treks',
    size: 24,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
    className: ''
  }

  render() {
    const { 
      size, set, color, icon, className, style,
      colorPressed, iconPressed,
      onClick, onFocus, onBlur,
      pressed, title
    } = this.props
    debug('render', { pressed, size })
    const _color = pressed ? colorPressed || color : color
    const _icon = pressed ? iconPressed || icon : icon
    return (
      <Pressable
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        className={'pressable-icon ' + className}
        style={style}
        title={title}
      >
          <Icon set={set} name={_icon} color={_color} size={size} />
      </Pressable>
    )
  }
}