import React from 'react'
import { observer } from 'mobx-react'
import Avatar from 'theme/Avatar'
import Likes from '../../../../theme/Likes';

class ProjectCommentReply extends React.Component {

  onClickLike = () => {
    const { comment } = this.props
    comment.likeCount += comment.liked ? -1 : 1
    comment.liked = !comment.liked
  }

  render() {
    const { comment } = this.props
    const { name, profileImage } = comment.user
    return (
      <div className="comment comment-reply">
        <div className="comment-attributes row-flex">
            <div className="avatar">
              <Avatar name={name} src={profileImage} size={24} />
            </div>
            <div className="name">{name}</div>
            <div className="since">{comment.since?.toString()}</div>
            <Likes
              liked={comment.liked}
              count={comment.likeCount}
              onClick={this.onClickLike}
            />
          </div>
          <div className="comment-text">
            {comment.text}
          </div>
      </div>
    )
  }
}

export default observer(ProjectCommentReply)