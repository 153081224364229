import { observable, computed, action, autorun, override } from 'mobx';
import Item from '../../Lists/Item'
import ActionPlannerUtils, { isSameDay, msPerDay, numDaysBetweenDates, toDayId } from '../ActionPlannerUtils';
import ArbitraryModel from '../../Model/ArbitraryModel';
import { TimespanTaskPiece } from './TimespanTaskPiece';
import DayList, { TimeSpanList } from '.';
import TaskItem from 'Stores/Task';
import ActionPlanner from '..';
import { JsonMap } from 'Stores/Model/Type/Json';
import { computedFn } from 'mobx-utils';
import PFAPlan from 'Stores/PFA/PFAPlan';

const debug = require('debug')('treks:store:timespan:item')

/**
 * Round a number to the given modulo
 * @param {Number} num Number
 * @param {Number} mod Modulo
 */
function round(num, mod = 5) {
  const remainder = num%mod
  return num - remainder + (remainder > mod/2 ? mod : 0)
}

export default class TimeSpanItem extends Item {

  list: TimeSpanList = null

  /**
   * Is being created in UI
   * @property {bool}
   */
  uiState = ArbitraryModel.fromProps({
    focus: false,
    isCreating: false,
    deleteIfNotCompleted: false,
  })

  /**
   * @property {ActionPlanner}
   */
   @computed get actionPlanner(): ActionPlanner {
    return this.list.actionPlanner
  }

  @computed get plannerUtils(): ActionPlannerUtils {
    throw new Error('Deprecated. UI info are moved out of models.')
  }

  @computed get pfaPlan(): PFAPlan {
    return this.getAttribute('pfaPlan', () => PFAPlan.fromProps({ timespan: this}))
  }

  getOrder(): string[] {
    return this.tasksStartIn.map(task => task.uid)
  }

  syncTaskEvents() {

    if (this.prevSiblingsOrderedOfSameType.length) return // only run a timespan type once

    debug('timespan sync task events', this, this.title)

    this.tasks.reduce((accumDuration, task) => {
      const taskStart = accumDuration
      const taskEnd = taskStart + task.duration

      const visibleDurations = this.getVisibleDurationsWithinDurations(taskStart, taskEnd)
      const events = visibleDurations.map(({ start, end }) => {
        const offsetStart = this.getOffsetTopFromDuration(start)
        const offsetEnd = this.getOffsetTopFromDuration(end)
        return this.actionPlanner.getEventsStartingWithinOffsetTop(offsetStart, offsetEnd)
      }).flat()

      if (events.length) {
        debug('timespan sync task events found', this.title, events, visibleDurations)
      }

      const eventsDuration = events.reduce((duration, event) => duration + event.duration, 0)
      task.events = events // set events after adding it's duration to task duration
      return taskEnd + eventsDuration
    }, 0)
  }

  getOffsetTopFromDuration(duration) {
    let offsetTopOfPlanner = 0
    this.siblingsOrderedOfSameType.find(timespan => {
      const inTimespan = timespan.startDurationOfSameType <= duration && timespan.endDurationOfSameType > duration
      if (inTimespan) {
        const offsetTopOfTimespan = duration - timespan.startDurationOfSameType
        offsetTopOfPlanner = timespan.startDuration + offsetTopOfTimespan
        return true
      }
      return false
    })
    return offsetTopOfPlanner
  }

  getVisibleDurationsWithinDurations(start, end) {
    return this.siblingsOrderedOfSameType.map(timespan => {
      const inTimespan = timespan.endDurationOfSameType > start && timespan.startDurationOfSameType < end
      if (inTimespan) {
        const visibleStart = start >= timespan.startDurationOfSameType ? start : timespan.startDurationOfSameType
        const visibleEnd = end >= timespan.endDurationOfSameType ? timespan.endDurationOfSameType : end
        return { start: visibleStart, end: visibleEnd }
      }
      return null
    }).filter(notEmpty => notEmpty)
  }

  @computed get focusedDate(): Date {
    return new Date(this.actionPlanner.focusedDate)
  }

  @observable dayId: string = null

  @observable dayIndex: number = null

  @observable minDuration: number = 0

  @computed get duration(): number {
    return Math.max(parseFloat(this.getAttribute('duration')), this.minDuration)
  }
  set duration(duration: number) {
    try {
      this.setAttribute('duration', Math.max(duration, this.minDuration))
    } catch(err) {
      console.warn('error setting timespan duration', err) // @todo fix
    }
  }

  @computed get pastDuration(): number {
    if (this.actionPlanner.minsSinceStartOfDay <= this.startDuration) return 0
    return Math.min(this.actionPlanner.minsSinceStartOfDay, this.endDuration) - this.startDuration
  }

  @computed get flowableDuration(): number {
    return this.duration - this.earmarkedTasksDuration
  }

  @computed get height() {
    throw new Error('Deprecated. UI info are moved out of models.')
  }

  @observable type: string = ''

  @computed get bucketTimespanOfSameType(): TimeSpanItem {
    return this.list.bucket.items.find(item => item.type === this.type)
  }

  @action setType(type: string) {
    this.type = type
  }

  // set title/color when timespan type changes
  // we do not inherit since timespan type may be deleted
  // orphaned timespans keep their title/color
  syncPropsToType() {
    if (this.syncTypeDisposer) this.syncTypeDisposer()
    this.syncTypeDisposer = autorun(() => {
      const timespan = this.bucketTimespanOfSameType
      if (timespan) {
        this.title = timespan.title
        this.color = timespan.color
      }
    })
  }

  @observable title: string = null

  @observable color: string = null

  @computed get dayList(): DayList {
    return this.list.dayList
  }

  /**
   * @property {bool} Allow moving timespan (sort in day)
   */
  @observable canMove: boolean = true

  /**
   * @property {bool} Allow resizing timespan
   */
  @observable canResize: boolean = true

  /**
   * @property {bool} isResizing
   */
  @observable isResizing: boolean = false

  /**
   * @property {bool} Allow tasks, events to be assigned to timespan
   */
  @observable canHaveItems: boolean = true

  /**
   * Focus on the title
   */
  @observable focusOnTitle: boolean = false

  @action setFocusOnTitle() {
    window.setTimeout(() => this.focusOnTitle = true)
  }

  @computed get siblingsOrdered(): TimeSpanItem[] {
    return this.list.orderedItems
  }

  @computed get index(): number {
    return this.siblingsOrdered.indexOf(this)
  }

  @computed get indexOfSameType(): number {
    return this.siblingsOrderedOfSameType.indexOf(this)
  }

  @computed get prevSiblingsOrdered(): TimeSpanItem[] {
    return this.siblingsOrdered.slice(0, this.index)
  }

  @computed get siblingsOrderedOfSameType(): TimeSpanItem[] {
    return this.siblingsOrdered.filter(item => item.type === this.type)
  }

  @computed get prevSiblingsOrderedOfSameType(): TimeSpanItem[] {
    return this.siblingsOrderedOfSameType.slice(0, this.indexOfSameType)
  }

  @computed get nextSiblingsOrderedOfSameType(): TimeSpanItem[] {
    return this.siblingsOrderedOfSameType.slice(this.indexOfSameType)
  }

  @computed get visiblePlannerTasks(): TaskItem[] {
    return this.list?.visibleTasks || []
  }

  @computed get flowingTasks(): TaskItem[] {
    return this.visiblePlannerTasks
      .filter(item => item.timespanType === this.type)
      .filter(task => !task.isEarmarked)
  }

  @computed get plannerEarmarkedTasks(): TaskItem[] {
    return this.visiblePlannerTasks.filter(task => {
      return task.isEarmarked && isSameDay(task.earmarkedDate, this.dayDate)
    })
  }

  @computed get earmarkedTasksOfSameType(): TaskItem[] {
    return this.plannerEarmarkedTasks.filter(task => task.timespanType === this.type)
  }

  @computed get earmarkedTasksWithinTimespan(): TaskItem[] {
    let startDuration, endDuration = 0
    const { startStaticDayDurationOfSameType, endStaticDayDurationOfSameType } = this
    return this.earmarkedTasksOfSameType.filter(task => {
      startDuration = endDuration
      endDuration += task.totalDuration
      const startsInTimespan = startDuration >= startStaticDayDurationOfSameType && startDuration < endStaticDayDurationOfSameType
      const endsInTimespan = endDuration > startStaticDayDurationOfSameType && endDuration <= endStaticDayDurationOfSameType
      return startsInTimespan || endsInTimespan
    })
  }

  @computed get earmarkedTasks() {
    return this.earmarkedTasksWithinTimespan
  }

  @computed get earmarkedTasksDuration(): number {
    return this.earmarkedTasks.reduce((sum, task) => sum += task.duration, 0)
  }

  @computed get firstTask(): TaskItem {
    return this.tasks.length > 0 && this.tasks[0]
  }

  @computed get lastTask(): TaskItem {
    return this.tasks.length > 0 && this.tasks[this.tasks.length - 1]
  }

  @computed get visiblePlannerTasksWithinTimespan(): TaskItem[] {
    let startDuration, endDuration = 0
    const { startDurationOfSameType, endDurationOfSameType } = this
    const timespanFlowingTasks = this.flowingTasks.filter(task => {
      startDuration = endDuration
      endDuration += task.totalDuration
      const startsInTimespan = startDuration >= startDurationOfSameType && startDuration < endDurationOfSameType
      const endsInTimespan = endDuration > startDurationOfSameType && endDuration <= endDurationOfSameType
      return startsInTimespan || endsInTimespan
    })
    const tasks = [ ...this.earmarkedTasks, ...timespanFlowingTasks ]
    return tasks
  }

  @computed get tasks(): TaskItem[] {
    return this.visiblePlannerTasksWithinTimespan
  }

  // ensure we memoize a TaskPiece per Task/Timespan
  taskPieceMap = new Map()
  getTaskPiece(task: TaskItem): TimespanTaskPiece {
    let piece = this.taskPieceMap.get(task)
    if (!piece) {
      piece = TimespanTaskPiece.fromProps({ task, timespan: this })
      this.taskPieceMap.set(task, piece)
    }
    return piece as TimespanTaskPiece
  } 

  @computed get tasksPieces(): TimespanTaskPiece[] {
    const pieces = this.tasks.map(task => this.getTaskPiece(task))
    return pieces
  }

  @computed get tasksStartIn(): TaskItem[] {
    const flowingTasks = this.tasksPieces
      .filter(piece => piece.underflow === 0)
      .map(piece => piece.task)
    const staticTasks = this.earmarkedTasksWithinTimespan
    return [ ...staticTasks, ...flowingTasks ]
  }

  @computed get tasksEndIn(): TaskItem[] {
    const flowingTasks = this.tasksPieces
      .filter(piece => piece.overflow === 0)
      .map(piece => piece.task)
    const staticTasks = this.earmarkedTasksWithinTimespan
    return [ ...staticTasks, ...flowingTasks ]
  }

  getItemIndex(task: TaskItem): number {
    return this.items.indexOf(task)
  }

  getTaskIndex(task: TaskItem): number {
    return this.tasks.indexOf(task) // !== getItemIndex()
  }

  getTaskById(id: string|number): TaskItem {
    return this.tasks.find(task => task.id === id || task.id.toString() === id.toString())
  }

  getTaskByUid(uid: string): TaskItem {
    return this.tasks.find(task => task.uid === uid)
  }

  /**
   * Adds the tasks to this timespan type only (assigning to a timespan is deprecated)
   */
  @action addTasks(tasks: TaskItem[]) {
    tasks.forEach(task => task.setTimespanType(this.type))
  }

  /**
   * Sets the tasks to this timespan type only (assigning to a timespan is deprecated)
   */
   @action setTasks(tasks: TaskItem[]) {
    this.tasks.forEach(task => task.setTimespanType(''))
    this.addTasks(tasks)
  }

  getPrevTaskOfSameType(task: TaskItem): TaskItem {
    return this.getPrevTasksOfSameType(task).pop()
  }

  getPrevTasksOfSameType(task: TaskItem): TaskItem[] {
    return task.isEarmarked 
      ? this.earmarkedTasks.slice(0, this.earmarkedTasks.indexOf(task)) 
      : this.flowingTasks.slice(0, this.flowingTasks.indexOf(task))
  }

  getTaskStartDurationOfSameType(task: TaskItem): number {
    const tasksDuration = this.getPrevTasksOfSameType(task).reduce((total, { totalDuration }) => total + totalDuration, 0)
    if (task.isEarmarked) {
      return this.startDurationOfSameType + tasksDuration // earmarked start from timespan start
    }
    return tasksDuration
  }

  getTaskEndDurationOfSameType(task: TaskItem): number {
    return this.getTaskStartDurationOfSameType(task) + task.totalDuration
  }

  getTaskPrevTaskPieces(task: TaskItem) {
    const piece = this.tasksPieces.find(piece => piece.task.uid === task.uid)
    return this.tasksPieces.slice(0, this.tasksPieces.indexOf(piece))
  }

  getTaskStartDurationWithinTimespan(task: TaskItem): number {
    return this.getTaskPrevTaskPieces(task).reduce((sum, piece) => sum + piece.duration, 0)
  }

  getTaskEndDurationWithinTimespan(task: TaskItem): number {
    return this.getTaskStartDurationWithinTimespan(task) + this.getTaskVisibleDuration(task)
  }

  getTaskStartDate(task: TaskItem): Date {
    const { startDuration } = this
    const taskStartDuration = this.getTaskStartDurationWithinTimespan(task)
    const startDate = new Date(new Date(this.focusedDate).setMinutes(startDuration + taskStartDuration))
    debug('getTaskStartDate', { task, startDate, startDuration, taskStartDuration })
    return startDate
  }

  getTaskEndDate(task: TaskItem): Date {
    const startDate = this.getTaskStartDate(task)
    if (!startDate) return null
    const visibleDuration = this.getTaskVisibleDuration(task)
    const endDate = new Date(new Date(startDate).setMinutes(startDate.getMinutes() + visibleDuration))
    return endDate
  }

  getTaskVisibleDuration(task: TaskItem): number {
    const startDuration = this.getTaskStartDurationOfSameType(task)
    const endDuration = this.getTaskEndDurationOfSameType(task)
    const visibleDuration = startDuration < this.startDurationOfSameType 
      ? endDuration - this.startDurationOfSameType 
      : endDuration > this.endDurationOfSameType
      ? this.endDurationOfSameType - startDuration
      : task.duration
    return visibleDuration
  }

  getTaskUnderflow(task: TaskItem): number {
    const startDuration = this.getTaskStartDurationOfSameType(task)
    return startDuration < this.startDurationOfSameType ? this.startDurationOfSameType - startDuration : 0
  }

  getTaskOverflow(task: TaskItem): number {
    const endDuration = this.getTaskEndDurationOfSameType(task)
    return endDuration > this.endDurationOfSameType ? endDuration - this.endDurationOfSameType : 0
  }

  getTaskAtTimespanDuration(duration: number): TaskItem {
    const task = this.tasks.find(task => {
      const startDuration = this.getTaskStartDurationWithinTimespan(task)
      const endDuration = startDuration + this.getTaskVisibleDuration(task)
      // inclusive of start
      return startDuration <= duration && endDuration > duration
    })
    return task
  }

  getTaskAtDate(date: Date): TaskItem {
    const duration = this.getTimespanDurationAtDate(date)
    const task = this.getTaskAtTimespanDuration(duration)
    return task
  }

  getTimespanDurationAtDate(date: Date): number {
    const mins = this.getDayDurationAtDate(date) - this.getDayDurationAtDate(this.startDate)
    return mins
  }

  getDayDurationAtDate(date: Date): number {
    return (new Date(date).getHours() * 60) + new Date(date).getMinutes()
  }

  /**
   * @property {Number}
   */
  @computed get tasksDuration(): number {
    return this.tasks.reduce((sum, { duration }) => sum + duration, 0)
  }

  /**
   * Set the duration while constraining timespan duration to other timespans in list (24hr total)
   */
  @action setDuration(newDuration: number, resizeNextTimespan: boolean = false, constrain: boolean = false) {
    const { duration, list } = this
    const delta = newDuration - duration
    let newDelta = delta
    if (resizeNextTimespan) {
      const next = this.list.getNextItem(this)
      if (next) {
        const nextDuration = next.duration
        const newNextDuration = next.duration - delta
        next.duration = newNextDuration // set directly
        newDelta = nextDuration - next.duration // actual applied delta
      }
    }
    if (constrain) {
      if (list.totalDuration + newDelta > list.maxTotalDuration) {
        newDelta = list.maxTotalDuration - list.totalDuration
      }
    }
    this.duration += newDelta
  }

  /**
   * Set the duration to 5 (modulo) min increments
   */
  @action setSnappyDuration(duration: number, modulo: number = 5, resizeNextTimespan: boolean = false) {
    this.setDuration(round(duration, modulo), resizeNextTimespan)
  }

  isTasksOverflow(): boolean {
    return this.duration < this.tasksDuration
  }

  @computed get todayDate(): Date {
    return this.list.todayDate
  }

  @computed get dayDate(): Date {
    return this.list.dayDate
  }

  @computed get numDaysSinceToday(): number {
    return numDaysBetweenDates(this.todayDate, this.dayDate)
  }

  /**
    * Duration of timepans on days since present day not loaded on planner 
    * @note only the focused week is loaded. 
    * @note We construct days by mapping to this week as timespans are the same each week
   */
  @computed get prevDaysSincePresent(): TimeSpanList[] {
    return new Array(Math.max(0, this.numDaysSinceToday))
      .fill(null)
      .map((_, index) => {
        const ms = index * msPerDay
        return toDayId(new Date(this.todayDate.getTime() + ms))
      })
      .map(dayId => {
        return this.dayList.items.find(day => day.dayId === dayId)
      })
  }

  @computed get dayStartDurationOfSameType(): number {
    return this.prevDaysSincePresent.reduce((total, day) => {
      return total + day.items
        .filter(timespan => timespan.type === this.type)
        .reduce((sum, timespan) => sum + timespan.flowableDuration, 0)
    }, 0)
  }
  
  @computed get startDurationOfSameType(): number {
    return this.dayStartDurationOfSameType
      + this.prevSiblingsOrderedOfSameType.reduce((total, { flowableDuration }) => flowableDuration + total, 0)
  }
  
  @computed get endDurationOfSameType(): number {
    return this.startDurationOfSameType + this.flowableDuration
  }
  
  @computed get startDuration(): number {
    return this.prevSiblingsOrdered.reduce((total, { flowableDuration }) => flowableDuration + total, 0)
  }
  
  @computed get endDuration(): number {
    return this.startDuration + this.flowableDuration
  }

  @computed get startStaticDayDurationOfSameType(): number {
    return this.prevSiblingsOrderedOfSameType.reduce((total, { duration }) => duration + total, 0)
  }
  
  @computed get endStaticDayDurationOfSameType(): number {
    return this.startDurationOfSameType + this.duration
  }

  @computed get startDate(): Date {
    return new Date(new Date(this.focusedDate).setMinutes(this.startDuration))
  }

  @computed get endDate(): Date {
    return new Date(new Date(this.focusedDate).setMinutes(this.endDuration))
  }

  @computed get startTime(): string {
    return this._durationToTime(this.startDuration)
  }

  @computed get endTime(): string {
    return this._durationToTime(this.endDuration)
  }

  @computed get tasksEndDuration(): number {
    return this.startDuration +  this.tasksDuration
  }

  @computed get tasksEndTime(): string {
    return this._durationToTime(this.tasksEndDuration)
  }

  /**
   * @note tasksDuration takes events into account
   */
  @computed get filledSpaceDuration(): number {
    return this.tasksDuration
  }

  @computed get emptySpaceDuration(): number {
    return this.duration - this.filledSpaceDuration
  }

  @computed get percentSpaceFilled(): number {
    const { filledSpaceDuration, duration } = this
    return filledSpaceDuration/duration * 100
  }

  @observable emptySpaceTask = null

  @computed get durationDone(): number {
    return this.tasks.reduce((accum, task) => accum + task.durationDone, 0)
  }

  @computed get durationShouldBeDone(): number {
    return this.tasks.reduce((accum, task) => accum + task.durationShouldBeDone, 0)
  }

  @computed get percentComplete(): number {
    const total = this.tasks.reduce((accum, task) => accum + (task.done ? 100 : task.percentComplete), 0)
    return this.tasks.length ? total / this.tasks.length : 0
  }

  @computed get isPercentCompleteOnTime(): boolean {
    return this.durationShouldBeDone <= this.durationDone
  }

  _durationToTime(duration): string {
    duration = parseFloat(duration)
    const PM = duration > 12*60
    return this._convertMinsToHrsMins(PM ? duration - 12*60 : duration) 
      + ' ' + (PM ? 'PM' : 'AM')
  }

  _convertMinsToHrsMins(mins): string {
    const h = Math.floor(mins / 60)
    const extra = mins % 60
    const m = extra < 10 ? '0' + extra : extra
    return `${h}:${m}`
  }

  @override fromJSON(data: JsonMap) {
    Object.assign(this, {
      id: data.id || data.uid,
      uid: data.uid || data.id,
      dayId: data.dayId,
      dayIndex: data.dayIndex,
      type: data.type,
      title: data.title,
      duration: data.duration,
      color: data.color,
    })
    return this
  }

  toJSON(): JsonMap {
    return {
      id: this.id || this.uid,
      uid: this.uid || this.id,
      dayId: this.dayId,
      dayIndex: this.dayIndex,
      type: this.type,
      title: this.title,
      duration: this.duration,
      color: this.color,
    }
  }

}
