export const MultiDayPlannerConfig = {
  default: {
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'h:mm A',
    durationStep: 1,
    durationInterval: 15, 
    durationHeightPerInterval: 45, 
    taskMargin: 0,
    minDuration: 5,
    minutesPerRow: 5,
  },
  collapsed: {
    dateFormat: 'MMMM D, YYYY',
    timeFormat: 'h:mm A',
    durationStep: 1,
    durationInterval: 30, 
    durationHeightPerInterval: 45, 
    taskMargin: 0,
    minDuration: 5,
    minutesPerRow: 30,
  }
}