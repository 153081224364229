import React from 'react'
import PropTypes from 'prop-types'
import FieldWithinRange from './FieldWithinRange'

export default class FilterFieldDueDate extends React.Component {

  static propTypes = {
    options: PropTypes.object,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: 'dueDate',
    label: 'Due',
    options: {
      range: [
        {
          label: 'Within next',
          value: 'within_next'
        },
        {
          label: 'Within last',
          value: 'within_last'
        },
        {
          label: 'Before',
          value: 'before'
        },
        {
          label: 'Between',
          value: 'between'
        },
        {
          label: 'On',
          value: 'on'
        },
      ],
      unit: [
        {
          label: 'Days',
          value: 'days'
        },
        {
          label: 'Weeks',
          value: 'weeks'
        },
        {
          label: 'Months',
          value: 'months'
        },
      ],
    }
  }

  onChange = value => {
    const { name, label, onChange } = this.props
    onChange({ name, label, value })
  }

  render() {
    const { options, value } = this.props
    return (
      <FieldWithinRange
        onChange={this.onChange}
        className="filter-due-date"
        options={options}
        value={value}
      />
     )
  }
}