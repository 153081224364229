import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './AccountSettings.css'
import AccountSettingsProfile from './AccountSettingsProfile'
import AccountSettingsBilling from './AccountSettingsBilling'
import AccountSettingsDisplay from './AccountSettingsDisplay'
import AccountSettingsEmailNotifications from './AccountSettingsEmailNotifications'

export default class AccountSettings extends React.Component {

  render() {
    return (
      <Switch>
        <Route path="/account/settings/display" component={AccountSettingsDisplay} />
        <Route path="/account/settings/billing" component={AccountSettingsBilling} />
        <Route path="/account/settings/profile" component={AccountSettingsProfile} />
        <Route path="/account/settings" component={AccountSettingsEmailNotifications} />
      </Switch>
    )
  }
}