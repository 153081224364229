import React from 'react'
import AppLoadingIndicator from 'Screens/App/AppLoadingIndicator'

export const ActionPlannerLoadingIndicator = ({ style }) => {
    return (
      <AppLoadingIndicator style={style} />
    )
}

export default ActionPlannerLoadingIndicator
