import { observable, action, override } from 'mobx';
import { List } from 'Stores/Lists'
import ActivityType from './ActivityType'

const debug = require('debug')('treks:store:task:activity:types')

/**
 * Avaialble  Activity Types
 */
export default class ActivityTypes extends List {

  get ModelType() {
    return ActivityType
  }

  /**
   * @property {string}
   */
  @observable type = null

  getItemByName(name) {
    return this.items.find(item => item.name === name)
  }

  @override async fetchLocal() {
    const { type } = this
    const activities = await this.localState.get(`activity/types/${type}`)
    this.fromJSON(activities)
  }

  @action async saveLocal() {
    const { type } = this
    this.localState.set(`activity/types/${type}`, this.toJSON())
  }

  @override async fetch() {
    const { type } = this
    const activites = await this.fetchState
      .get(`activity/types/${type}`)
    this.fromJSON(activites)
  }

  @override async save() {
    const { type } = this
    return this.saveState
      .post(`activity/types/${type}`, this.toJSON())
  }

  @override fromJSON(json) {
    Object.assign(this, {
      id: json.id && json.id.toString(),
      type: this.type,
      items: json.items && json.items.map(item => item.toJSON())
    })
    debug('fromJSON', { json, self: this })
    return this
  }

  async toJSON() {
    const json = {
      id: this.id && this.id.toString(),
      type: this.type,
      items: this.items.map(item => item.toJSON())
    }
    debug('toJSON', { json, self: this })
    return json
  }

}
