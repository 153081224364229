import React from 'react'
import { observer } from 'mobx-react'
import { darkenColor } from '../../lib/utils'

export default observer(({ timespan }) => {
  const { color, title, percentSpaceFilled } = timespan
  const filled = Math.min(100, Math.floor(percentSpaceFilled))
  return (
    <h4
      className="timespan-title"
      style={{ color: darkenColor(color, 15) }}
    >
      {title} {timespan.taskPieces.length ? '- ' + filled + '%' : ''}
    </h4>
  )
})