import { observer } from 'mobx-react'
import './ActionPlannerDayLabel.scss'

export const ActionPlannerDayLabel = observer(({ date, isFocused, onFocusDate }) => {
  return (
    <label className={'planner-day-label'}>
      <button
        className={'label-btn ' + (isFocused ? 'is-focused' : '')}
        onMouseDown={event => {
          event.preventDefault()
          onFocusDate && onFocusDate(date)
        }}
      >
        {date.toString().slice(0, 3)}
      </button>
    </label>
  )
})