import ReactDOM from 'react-dom'
import { observable, computed, action, override } from 'mobx';
import { once } from 'Stores/utils';
import OrderedList from 'Stores/Lists/OrderedList';
import MainPaneItem from './MainPaneItem';
import List from 'Stores/Lists';
import EventEmitter from 'eventemitter2'

const debug = require('debug')('treks:store:main-pane-group')

export const importMainPaneGroup = once((view) => {

  type Private<T> = {
    [key in keyof T as key extends `_${string}` ? key : never]: T[key]
  }
  
  type Public<T> = Omit<T, keyof Private<T>>

  type MainPaneItemProps = Partial<Public<MainPaneItem>>
  type MainPaneGroupJSON = Partial<Public<MainPaneGroup>>
  
  class MainPaneGroup extends OrderedList {
  
    get ModelType() {
      return MainPaneItem
    }

    get items(): MainPaneItem[] {
      return super.items
    }

    eventEmitter = new EventEmitter() 

    on(eventName: string, cb: (...args: any) => void) {
      return this.eventEmitter.on(eventName, cb)
    }
  
    @observable view = null

    @observable defaults = []

    @observable colSpan: number = 3

    @computed get focusedPane(): MainPaneItem {
      return this.lastFocusedPane
    }

    @computed get panesOrderedByLastFocus() {
      return [ ...this.panes ]
      .sort(
        (a, b) => a.focusTimestamp -  b.focusTimestamp
      )
      .reverse()
    }

    @computed get lastFocusedPane() {
      return this.panesOrderedByLastFocus[0]
    }

    @action setFocusedPane(props: Partial<MainPaneItem>) {
      const pane = this.getItemByUid(props?.uid) as MainPaneItem
      if (pane) {
        pane.focusDate = new Date()
      }
    }

    canAddPane(pane: MainPaneItem): boolean {
      return this.filledCols + pane.colSpan <= 3 
    }

    canAddTab(pane: MainPaneItem): boolean {
      return this.getPaneCanAddTab(pane) ? true : false
    }

    getPaneCanAddTab(tab: MainPaneItem): MainPaneItem {
      return this.panesOrderedByLastFocus.find(pane => pane.canAddTab(tab))
    }

    getPaneCreateProps(pane: Partial<MainPaneItem>): Exclude<Partial<MainPaneItem>, { id: string, uid: string }> {
      const { id, uid, focusedTabUid, ...props } = pane instanceof MainPaneItem ? pane.toJSON() : pane
      return props
    }

    @action addTab(tab: Partial<MainPaneItem>): MainPaneItem {
      const pane = this.getPaneCanAddTab(tab)
      const newTab = this.createItem(tab)
      debug('addTab', { pane, tab, newTab })
      return pane.addTab(newTab)
    }

    @action addPane(pane: Partial<MainPaneItem>): MainPaneItem {
      const newPane = this.createItem(pane)
      this.setFocusedPane(newPane)
      return this.addItem(newPane)
    }

    @action focusOrAddPaneOrTab(props: Partial<MainPaneItem>): MainPaneItem {
      debug('focus tab/pane', props)
      const pane = this.panesOrderedByLastFocus.find(pane => pane.slug === props.slug)
      if (pane) {
        this.setFocusedPane(pane)
        return pane
      } else {
        const pane = this.panesOrderedByLastFocus.find(pane => {
          return pane.tabsOrderedByLastFocus.find(tab => tab.slug === props.slug) ? pane : false
        })
        if (pane) {
          const tab = pane.tabsOrderedByLastFocus.find(tab => tab.slug === props.slug)
          pane.setFocusedTab(tab)
          return tab
        } else {
          return this.addPaneOrTab(props)
        }
      }
      
    }

    @action addPaneOrTab(pane: MainPaneItem): MainPaneItem {
      if (this.canAddPane(pane)) {
        return this.addPane(pane)
      } else if (this.canAddTab(pane)) {
        return this.addTab(pane)
      }
    }

    @computed get filledCols(): number {
      return this.items.reduce((total, item) => total + item.colSpan, 0)
    }

    @computed get itemsOrdered(): MainPaneItem[] {
      const itemsOrdered = this.order.map(slug => this.items.find(item => item.slug === slug))
        .filter(item => item) // order can get out of sync
      itemsOrdered.push(...this.items) // add items not ordered
      const uniqueItemsOrdered = itemsOrdered.filter((item, index) => itemsOrdered.indexOf(item) === index)
      return uniqueItemsOrdered
    }

    @computed get panes(): MainPaneItem[] {
      return [ ...this.itemsOrdered ]
    }

    @computed get lastPane(): MainPaneItem {
        return this.panes.length && this.panes[this.panes.length - 1]
    }

    @computed get childTabs(): MainPaneItem[] {
      return [ ...this.panes ].map(pane => [ ...pane.tabs ]).flat()
    }

    getChildTabBySlug(slug: string): MainPaneItem {
      return this.childTabs.find(tab => tab.slug === slug)
    }

    getChildTabByType(PaneType): MainPaneItem {
      return this.getChildTabBySlug(PaneType.slug)
    }
  
		@observable layout = []

    @observable bucket: Partial<MainPaneItem>[] = []

    @observable isSortable: boolean
  
    /**
     * @property {SortablePane} SortablePane Ref
     */
    @observable panesRef: HTMLElement

    @observable _containerWidth: number
  
    @computed get containerWidth(): number {
      return this._containerWidth || this.getContainerWidthFromElement()
    }
  
    @computed get totalWidth(): number {
      return this.items.reduce((total, item) => {
        const el =  ReactDOM.findDOMNode(item.ref) as HTMLElement
        return total + (item.width || el?.offsetWidth)
      }, 0)
    }
  
    @computed get emptyWidth(): number {
      const {  containerWidth, totalWidth, panesRef } = this
      debug('empty width', { containerWidth, totalWidth, panesRef })
      return containerWidth - totalWidth
    }

    constructor(items = [], view = null) {
      super()
      this.setItems(items)
      this.setView(view)
      // @todo implement for react-native
      if (typeof window !== 'undefined') {
        window.addEventListener('load', () => this.setContainerWidth())
        window.addEventListener('resize', () => this.setContainerWidth())
      }
    }
  
    getContainerWidthFromElement(): number {
      return this.panesRef && 
        (ReactDOM.findDOMNode(this.panesRef) as HTMLElement)?.offsetWidth
    }
  
    @action setContainerWidth() {
      this._containerWidth = this.getContainerWidthFromElement()
    }
    
    @action setView(view = null, defaults = []) {
      this.view = view
      this.defaults = defaults
      if (defaults.length > 0)
        this.setItems(defaults)
    }
  
    /**
     * Update the order of items will keeping existing
     */
    @action mergeOrder(order: string[] = []) {
      const mergedOrder = [ ...order, ...this.order ]
      const uniqueMergedOrder = mergedOrder.filter((slug, index) => {
        return mergedOrder.indexOf(slug) === index
      })
      this.setOrder(uniqueMergedOrder)
    }

    @override setItems(items: MainPaneItem[]) {
      super.setItems(items)
      this.setOrder(items.map(item => item.slug))
      const { order } = this
      debug('set items', { items, order })
    }

    /**
     * @fix should not update by type
     * We need to move all UI that uses this to addOrUpdateItemOfSameType
     */
    @action addOrUpdateItem(item: Partial<MainPaneItem>): MainPaneItem {
      return this.focusOrAddAndUpdateItemOfSameType(item)
    }

    @action focusOrAddAndUpdateItemOfSameType(item: Partial<MainPaneItem>): MainPaneItem {
      const pane = this.focusOrAddPaneOrTab(item)
      if (pane) {
        const { uid, id, ...props } = item?.isModel ? item.toJSON() : item
        pane.setProps(props)
        return pane
      }
    }

    @override updateItem(item: Partial<MainPaneItem>) {
      this.setPropJSON(item)
    }
  
    @action setBucketItems(items: MainPaneItemProps[]) {
      this.bucket = items // .map(item => MainPaneItem.fromProps(item))
    }
  
    @action setItemByIndex(index: number, item: MainPaneItem) {
      this.items[index] = MainPaneItem.fromProps(item)
    }
  
    getItemBySlug(slug: string) {
      return this.items.find(item => item.slug === slug)
    }
  
    getItemByPaneType(PaneType: Partial<MainPaneItem>) {
      return this.getItemBySlug(PaneType.slug)
    }
  
    getItemNode(paneItem: MainPaneItem) {
      return ReactDOM.findDOMNode(paneItem.ref)
    }

    getAdjacentItem(paneItem: MainPaneItem, pos: number = 1) {
      const item = this.items.find(item => item.slug === paneItem.slug)
      const index = this.getItemIndex(item)
      const adjacentIndex = index + pos
      return (adjacentIndex >= 0 && adjacentIndex < this.items.length) && this.items[adjacentIndex]
    }
  
    @override addItem(item: MainPaneItem) {
      if (item.uid && this.getItemByUid(item.uid)) {
        throw 'dupe'
      }
      const paneItem = item instanceof MainPaneItem ? item : MainPaneItem.fromProps(item)
      List.prototype.addItem.call(this, item) // add parent
      this.order.push(paneItem.slug)
      const { items, order } = this
      debug('addItem', { item, items, order })
      this.eventEmitter.emit('addItem', item)
      return paneItem
    }
  
    @override removeItem(item: MainPaneItem): MainPaneItem|void {
      debug('removeItem', item)
      const pane = this.items.find(pane => pane.slug === item.slug)
      const index = this.getItemIndex(pane)
      this.order.splice(this.order.indexOf(item.slug), 1)
      if (index > -1) {
        this.items.splice(index, 1)
        this.eventEmitter.emit('removeItem', item)
        return item
      }
    }
  
    @override replaceItem(item, newItem) {
      super.replaceItem(item, newItem)
      this.order.splice(this.order.indexOf(item.slug), 1, newItem.slug)
      this.eventEmitter.emit('replaceItem', item, newItem)
    }

    setItem(paneItem: MainPaneItem, item: MainPaneItem|MainPaneItemProps) {
      if (item instanceof MainPaneItem) {
        this.replaceItem(paneItem, item)
        this.eventEmitter.emit('setItem', paneItem, item)
        return item
      } else {
        paneItem.setProps(item)
        this.eventEmitter.emit('setItem', paneItem, item)
        return paneItem
      }
    }

    @action setItemBySlug(slug: string, item: MainPaneItem|MainPaneItemProps) { 
      return this.getItemBySlug(slug)?.setItem(item)
    }

    getBucketItemBySlug(slug: string): MainPaneItem {
      const props = this.getBucketItemPropsBySlug(slug)
      return props && MainPaneItem.fromProps(props) as MainPaneItem
    }
  
    getBucketItemPropsBySlug(slug: string): Partial<MainPaneItem> {
      return this.bucket.find(item => item.slug === slug)
    }
  
    getItemsFromJSON(items: Partial<MainPaneItem>[]) {
      if (items) {
        return items.map((props) => {
          return this.getItemFromJSON(props)
        }).filter(item => item)
      }
      return []
    }

    getItemFromJSON(props: Partial<MainPaneItem>) {
      const bucketProps = this.getBucketItemPropsBySlug(props.slug)
      const pane = MainPaneItem.fromJSON(props)
      bucketProps && pane.setProps(bucketProps)
      debug('bucket', bucketProps, props, pane.toJSON())
      return pane
    }
  
    toJSON(): MainPaneGroupJSON {
      const { items, layout, focusedPaneSlug } = OrderedList.prototype.toJSON.call(this)
      const order = [ ...this.order ]
      debug('toJSON', { items, order, focusedPaneSlug })
      return { items, order, focusedPaneSlug, layout }
    }
  
    @override fromJSON(json: MainPaneGroupJSON) {
      const items = this.getItemsFromJSON(json?.items)
      const order = json ? json.order : []
      console.log('fromJSON', { json, items, order })
      this.setItems(items)
      this.mergeOrder(order)
			if (json && json.layout) this.layout = json.layout
      return this
    }
  
    @override async fetchLocal(page: string = '') {
      const { modelName } = this
      return this.localState.get(modelName + '/' + page)
        .then(json => this.fromJSON(json as MainPaneGroupJSON))
    }
  
    async saveLocal(page: string = '') {
      const { modelName } = this
      if (view) return
      const json = this.toJSON()
      debug('save local', { modelName: modelName + '/' + page, json })
      return this.localState.set(modelName + '/' + page, json)
    }
  
    async clearLocal(page: string = '') {
      return this.localState.set(this.modelName + '/' + page, {})
    }
  
  }

  return MainPaneGroup

})