import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

export const ThemeButton = props => {

  let style = {}
  if (props.size === 'xs') {
    style = { ...style, ...styles.xs }
  }

  const Btn = () => {
    return (
      <Button 
        {...props} 
        size={(props.size === 'xs' ? 'sm' : props.size)}
        style={style}
      />
    )
  }

  return (
    props.to 
      ? <Link to={props.to}><Btn /></Link>
      : <Btn />
  )
}

const styles = {
  xs: {
    height: 23,
    padding: '0 0.65rem',
    fontSize: 15,
    lineHeight: '17.5px',
  }
}
export default ThemeButton