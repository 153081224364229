import List from "Stores/Lists";
import { action, observable, override } from 'mobx'
import { uid } from "Stores/utils";
import { importDashboard } from "./Dashboard";

export default class DashboardList extends List {
  get ModelType() {
    return importDashboard()
  }


  @observable focusedItem = null
  @observable availableWidgets: Record<string, any>

  @action setFocusedItem(item) {
    console.log('setting focused item', item)
    // if (this.focusedItem && this.focusedItem.id === item.id)
    // this.focusedItem = null
    // else
    this.focusedItem = item
  }

  
  
  @override async fetch() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      let endpoint = 'dashboard/list/'
      console.log('fetching', endpoint)
      try {
        this.fetchState.get(endpoint)
        .then(async ({ data }) => {

          const {records, widgetTypes} = data
          this.availableWidgets = widgetTypes
          
          console.log('dashboard main fetch result', data)
          let id = uid()
          let defaultItem = importDashboard().create()
          defaultItem.uid = id 
          defaultItem.name = "Default"
          if (records.length < 1){
            this.addItem(defaultItem)
            console.log('setting default item', defaultItem)
            defaultItem.save()
            this.setFocusedItem(defaultItem)
            res(defaultItem)
          }
          else {
            await this.fromJSON({ items: [...records] })
            console.log('setting focused item', this.items[0])
            this.setFocusedItem(this.items[0])
            res(this.items[0])
          }
  
  
  
  
        })
      } catch (err) {
        console.log('dashboard fetch failed', err)
      }
    })


  }
}