import React from 'react'
import { observer } from 'mobx-react'
import { fieldIsEnabled } from './utils'
import TaskDuration from 'Components/TaskDetail/TaskDuration'

export const TaskDurationField = observer(({ item, list, fields }) => {
  return (
    fieldIsEnabled(fields, 'duration') ? (
      <div className="attribute attribute-duration">
        <TaskDuration item={item} list={list} className={'attribute'} />
      </div>
    ) : null
  )
})