import React from 'react'
import Icon from 'theme/Icon'
import Pressable from 'theme/Pressable'
import './DoneFilter.scss'

const debug = require('debug')('treks:theme:list-filter:done')

class DoneFilter extends React.Component {

  static defaultProps = {
    view: 'all',
    views: [
      {
        icon: 'task',
        view: 'not-done',
      },
      {
        icon: 'task_complete',
        view: 'done',
      },
      {
        text: 'All',
        view: 'all',
      }
    ]
  }

  onChange = view => {
    debug("view is", view)
    this.props.onChange(view)
  }

  render() {
    const { views, view } = this.props
    debug('render', { views, view })
    return (
      <div className="done-filter">
        <span>View</span>
        <div className="done-filter-options">
          {
            views.map(item => {
              const { text, icon, view } = item
              const isActive = this.props.view === view
              return (
                <Pressable 
                  key={view}
                  className={'done-filter-option ' + (isActive ? 'active' : '') } 
                  onClick={() => this.onChange(view)}
                >
                  {
                    (icon ? <Icon icon={icon} size={15} /> : text)
                  }
                </Pressable>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default DoneFilter