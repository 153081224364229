import { action, override } from 'mobx';
import Goal from '../GoalItem'
import { ActivityItem } from '../../Activity'
import TaskItem from '../../Task/TaskItem';
import { hasOne } from 'Relationships/RelationshipDecorators';

const debug = require('debug')('treks:store:goal:activity')

/**
 * Goal Activity Item
 */
export default class GoalActivityItem extends ActivityItem {

  /**
   * @property {TaskItem}
   */
  @hasOne(() => TaskItem)
	task:TaskItem

  /**
   * @property {GoalItem}
   */
	@hasOne(() => Goal)
  goal:Goal

  /**
   * Human readable activity title
   */
  @override get titleFormatted() {
    return this.title
      .replace('{user}', this.user)
      .replace('{creator}', this.creator)
      .replace('{goal}', this.goal)
      .replace('{task}', this.task)
  }

  /**
   * @param {object} item 
   * @param {Goal} Goal activity belongs to
   */
  constructor(item = {}, list, user, goal, task) {
    super(item, list, user)
    goal && this.setGoal(goal)
    task && this.setGoal(task)
    // @todo save()
    debug('create', { item, goal, task, list })
  }

  /**
   * @param {Goal}
   */
  @action setGoal(goal) {
    this.goal = goal
  }

  /**
   * @param {Task}
   */
  @action setTask(task) {
    this.task = task
  }

  async save() {
    const json = await this.toJSON()
    debug('save', { json })
    return this.saveState.post('goal/activity/save', json)
  }

 @override fromJSON(data) {
    Object.assign(this, {
      id: data.id && data.id.toString(),
      goal: this.getGoalById(data.goalId),
      user: this.getUserById(data.userId),
      activity: data.activity,
      trashed: data.trashed,
    })
    return this
  }

  async toJSON() {
    const json = {
      id: this.id,
      taskId: this.task && this.task.id,
      goalId: this.goal && this.goal.id,
      user: this.user && this.user.id,
      activity: this.activity,
      trashed: this.trashed,
    }
    return json
  }

}
