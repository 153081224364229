import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import { AccountPasswordReset,
  AccountPasswordResetComplete,
  AccountPasswordResetEmailSent,
  AccountPasswordResetVerify
} from '../../Components/Account/Password'
import { Switch, Route } from 'react-router-dom'

export default withRouter(observer(class AccountPasswordResetModal extends React.Component {

  modalStore = ModalStore.fromProps({
    show: true,
  })

  handleClose = () => {
    this.modalStore.setState({ show: false })
  }

  render() {
    return (
      <Modal 
          show={true} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="settings-modal modal-size-80"
        >
          <Modal.Body>
            <Switch>
              <Route path="/account/password/reset/verify-token" component={AccountPasswordResetVerify} />
              <Route path="/account/password/reset/sent" component={AccountPasswordResetEmailSent} />
              <Route path="/account/password/reset/complete" component={AccountPasswordResetComplete} />
              <Route path="/" component={AccountPasswordReset} />
            </Switch>
          </Modal.Body>
        </Modal>
    )
  }
}))