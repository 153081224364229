export type ListenerDisposer = () => void
export type EventListener = Function

export type EventState = {
  [key: string]: any
}

const debug = require('debug')('treks:model:Event')

/**
 * Single event 
 * @example 
 * const event = new Event()
 * event.add((...args) => console.log('event fired', ...args))
 * event.fire('hello')
 */
export class Event {

  state: EventState = {}

  constructor(state?: EventState) {
    if (state) {
      Object.assign(this.state, state)
    }
  }

  private _listeners: Set<EventListener> = new Set()

  add(listener: EventListener): ListenerDisposer {
    this._listeners.add(listener)
    this.debug('+++(1) add listener', listener)
    return () => {
      this.debug('---(1) dispose listener', listener)
      this._listeners.delete(listener)
    }
  }

  fire(...args) {
    const { size } = this._listeners
    if (this._listeners.size === 0) {
      this.debug('===>(0) triggered 0 listeners', ...args)
    }
    this.debug(`===>(${size}) triggered ${size} listener`, this._listeners, ...args)
    this._listeners.forEach(listener => {
      return listener(...args)
    })
  }

  has(listener: EventListener) {
    return this._listeners.has(listener)
  }

  private debug(...args) {
    const _args = [
      this.state.context?.uid, 
      this.state.name, 
      this.state.model?.modelName, 
      this.state.model?.id
    ]
      .filter(arg => arg)
    debug(_args, ...args)
  }

}