/**
 * See: Stores/MainPaneGroup/data/MainPaneItems.ts for MainPane Panes
 * See: Containers/MainMenu/MainMenuItems.ts for SubMenu components
 */
export const MainMenuItems = [
  {
    name: 'Action Planner',
    path: '/day-planner',
    icon: 'action_planner',
    slug: 'planner',
  },
  {
    name: 'Tasks',
    path: '/tasks',
    icon: 'task',
    slug: 'tasks',
  },
  {
    name: 'Projects',
    path: '/projects',
    icon: 'project',
    slug: 'projects',
  },
  // {
  //   name: 'Integrations',
  //   path: '/integrations',
  //   icon: 'integrations',
  // },
  {
    name: 'Calendar',
    path: '/calendar',
    icon: 'calendar',
    slug: 'calendar',
  },
  {
    name: 'Goals',
    path: '/goals',
    icon: 'goal',
    slug: 'goals'
  },
  {
    name: 'Metrics',
    path: '/metrics',
    icon: 'metrics',
    slug: 'metrics',
  },
  {
    name: 'Inbox',
    path: '/inbox',
    icon: 'inbox',
    slug: 'inbox',
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    slug: 'dashboard',
  }
]
export default MainMenuItems