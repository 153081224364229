import List from '../Lists/List'
import OrganizationItem from './OrganizationItem';

export default class OrganizationList extends List {

  get ModelType() {
    return OrganizationItem
  }

}
