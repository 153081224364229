const { hostname, protocol, port } = window.location
export const AppConfig = {
  appBaseUrl: process.env.REACT_APP_URL || `${protocol}//${hostname}` + (port === 80 ? '' : `:${port}`) + '/',
  apiBaseUrl: process.env.REACT_APP_API_URL || `${protocol}//${hostname}:5000/`,
  billingApiBaseUrl: process.env.REACT_APP_BILLING_API_URL || `${protocol}//${hostname}:8001/authorize.net/`,
  apiDevUrl: process.env.REACT_APP_API_URL || `${protocol}//${hostname}:5001/`,
}

global.AppConfig = AppConfig // debug

export default AppConfig
