import React, { ChangeEvent } from 'react'
import { observer } from 'mobx-react'
import Modal from 'react-bootstrap/Modal'
import Button from 'theme/Button'
import Icon from 'theme/Icon'
import { CalendarEvent } from 'Stores/Calendar'
import Select from 'theme/Form/Select'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import DatePicker from 'theme/DatePicker'
import { CircleCheckIcon, CircleIcon } from 'lucide-react'
import { ALL_WEEKDAYS, RRule, RRuleStrOptions, WeekdayStr } from 'rrule'
import { format } from 'date-fns'

const debug = require('debug')('treks:event:recurrence:custom')

type EventRecurrenceCustomProps = {
  event: CalendarEvent;
  onClose: () => void;
  onCancel: () => void;
  onSave: (state: EventRecurrenceCustomState) => void;
  show: boolean;
  modalTitle?: string;
  modalIcon?: string;
  errMsg?: string;
  busyIcon?: string;
}

export type EventRecurrenceCustomState = {
  repeatInterval: number;
  repeatFreq: string;
  repeatWeekDays: typeof ALL_WEEKDAYS;
  repeatMonthDays: number[];
  rangeType: string;
  endDate: any;
  endOccurrences: number;
}

export class EventRecurrenceCustomModal extends React.Component<EventRecurrenceCustomProps> {

  static defaultProps = {
    show: false,
    modalIcon: 'event',
    busyIcon: 'spinner',
    modalTitle: 'Custom Event Recurrence'
  }

  uiState = ArbitraryModel.fromProps({
    repeatInterval: 1,
    repeatFreq: '',
    repeatWeekDays: [],
    repeatMonthDays: [],
    rangeType: '',
    endDate: null,
    endOccurrences: 0
  } as EventRecurrenceCustomState)

  componentDidMount() {
    const { startDate } = this.props.event
    const dayNameShort = format(startDate, 'EEEEEE').toUpperCase();
    this.uiState.setState({
      repeatFreq: 'week',
      repeatWeekDays: [dayNameShort],
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onSave() {
    this.props.onSave(this.uiState.toJSON() as EventRecurrenceCustomState)
  }

  preventEvent = (event: MouseEvent) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  onChangeInterval = (event: ChangeEvent<HTMLInputElement>) => {
    const repeatInterval = event.target.value
    this.uiState.setState({ repeatInterval })
  }

  onChangeFreq = ({ value }: { name: string; value: string }) => {
    this.uiState.setState({ repeatFreq: value })
  }

  onChooseRepeatWeekDay = (repeatWeekDay: WeekdayStr) => {
    const repeatWeekDays = [ ...this.uiState.repeatWeekDays ]
    if (repeatWeekDays.includes(repeatWeekDay)) {
      repeatWeekDays.splice(repeatWeekDays.indexOf(repeatWeekDay), 1)
    } else {
      repeatWeekDays.push(repeatWeekDay)
    }
    this.uiState.setState({ repeatWeekDays })
  }

  onRangeTypeChange = (rangeType: string) => {
    this.uiState.setState({ rangeType })
  }

  onChangeEndOccurrences = (event: ChangeEvent<HTMLInputElement>) => {
    const endOccurrences = event.target.value
    this.uiState.setState({ endOccurrences })
  }

  onChangeEndDate = (endDate: Date) => {
    this.uiState.setState({ endDate })
  }

  render() {
    const { event, show, onClose, modalIcon, modalTitle } = this.props
    const monthDayNumber = parseInt(format(event.startDate, 'd'), 10);
    const {
      repeatInterval,
      repeatFreq,
      repeatWeekDays,
      rangeType,
      endDate,
      endOccurrences
    } = this.uiState
    const freqOptions = [
      { value:"day", label:"day" },
      { value:"week", label:"week" },
      { value:"month", label:"month" },
      { value:"year", label:"year" },
    ]
    const dayOptions = [
      { value: 'SU', label: 'S' },
      { value: 'MO', label: 'M' },
      { value: 'TU', label: 'T' },
      { value: 'WE', label: 'W' },
      { value: 'TH', label: 'T' },
      { value: 'FR', label: 'F' },
      { value: 'SA', label: 'S' },
    ]
    
    debug('render', { event, rangeType }, this.uiState.toJSON())
    return (
      <Modal 
          show={show} 
          onHide={onClose}
          size="lg"
          centered
          dialogClassName="modal-size-80"
          onClick={this.preventEvent}
        >
        <Modal.Header>
            <Modal.Title>
              <Icon 
                set="file" 
                icon={modalIcon} 
                size={16}
                color={'#808284'} 
              />
              &nbsp;
              {modalTitle}
            </Modal.Title>
            <Button variant="outline" onMouseDown={onClose} className="modal-close-button">
              <Icon set="fa" icon="times" size="xs" color={'#808284'} className="modal-close-icon" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="event-recurrence-custom">
              <div className='row'>
                <label>Repeats every</label>
                <input
                  type='number'
                  value={repeatInterval}
                  onChange={this.onChangeInterval}
                />
                <Select 
                  options={freqOptions}
                  value={repeatFreq}
                  onChange={this.onChangeFreq}
                />
              </div>
              {
                ['week', 'month'].includes(repeatFreq) && (
                  <div className='row'>
                    <label>Repeat on</label>
                    <div className='row days'>
                      {
                        repeatFreq === 'week' ? (
                          dayOptions.map(option => (
                            <button
                              key={option.value}
                              className={'day ' + (repeatWeekDays.includes(option.value as WeekdayStr) ? 'selected' : '') }
                              onClick={() => this.onChooseRepeatWeekDay(option.value as WeekdayStr)}
                              >
                                {option.label}
                              </button>
                          ))
                        ) : (
                          repeatFreq === 'month' && (
                            <div>day {monthDayNumber} of each month</div>
                          )
                        )
                      }
                    </div>
                  </div>
                )
              }
              <div className='row'>
                <label>Ends</label>
              </div>
              <div className='row row-range'>
                <div className='choice-selector' onClick={() => this.onRangeTypeChange('never')}>
                  {
                    rangeType === 'never' || rangeType === '' ? (
                      <CircleCheckIcon size={20} color='#106ba3' />
                    ) : (
                      <CircleIcon size={20} />
                    )
                  }
                  <label htmlFor='never'>Never</label>
                </div>
              </div>
              <div className='row'>
                <div className='choice-selector' onClick={() => this.onRangeTypeChange('on')}>
                {
                    rangeType === 'on' ? (
                      <CircleCheckIcon size={20} color='#106ba3' />
                    ) : (
                      <CircleIcon size={20} />
                    )
                  }
                  <label htmlFor='on'>On</label>
                </div>
                <div className='choice-input'>
                  <DatePicker
                    onChange={this.onChangeEndDate}
                    value={endDate}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='choice-selector' onClick={() => this.onRangeTypeChange('after')}>
                {
                    rangeType === 'after' ? (
                      <CircleCheckIcon size={20} color='#106ba3' />
                    ) : (
                      <CircleIcon size={20} />
                    )
                  }
                  <label htmlFor='after'>After</label>
                </div>
                <div className='choice-input'>
                  <input
                    type='number'
                    name='occurrences'
                    value={endOccurrences}
                    onChange={this.onChangeEndOccurrences}
                  />
                  <label htmlFor='occurrences'>occurrences</label>
                </div>
              </div>
              <div className='row'>
                <div className='form-actions'>
                  <button className='cancel' onClick={() => this.onCancel()}>Cancel</button>
                  <button className='save' onClick={() => this.onSave()}>Save</button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(EventRecurrenceCustomModal)