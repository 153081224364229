import React from 'react'
import { observer } from 'mobx-react'
import { CalendarEvent } from 'Stores/Calendar'
import EventNotification from './EventNotification'

const debug = require('debug')('treks:event:notifications')

type EventNotificationsProps = {
  event: CalendarEvent;
  addLabel?: string;
}

class EventNotifications extends React.Component<EventNotificationsProps> {

  static defaultProps = {
    addLabel: 'Add Notification'
  }

  addNotification = () => {
    this.props.event.notifications.addItem({
      method: 'popup',
      value: 15,
      period: 'minutes'
    })
    this.props.event.willSave()
  }

  render() {
    const { event, addLabel } = this.props
    const { notifications } = event
    debug('render', { event, notifications, addLabel })
    return (
      <div className="event-notifications">
        {
          notifications.items.map(notification => {
            return (
              <EventNotification
                key={notification.uid}
                event={event}
                notification={notification}
              />
            )
          })
        }
        <div>
          <button
            onClick={this.addNotification}
            style={{
              border: 0,
              background: 'transparent',
              color: '#ddd'
            }}
          >
            <span>{addLabel}</span>
          </button>
        </div>
      </div>
    )
  }
}

export default observer(EventNotifications)
