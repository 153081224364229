import React from 'react'
import { observer } from 'mobx-react'
import Select from 'theme/Form/Select'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import AddUserDropdown from '../../AddUserDropdown'
import DetailMemberItem from './DetailMemberItem'

const debug = require('debug')('treks:detail:members')

class DetailAddMembers extends React.Component {

  static defaultProps = {
    addMemberPlaceholder: 'Add Members',
  }

  store = ArbitraryModel.fromProps({
    isAddingUser: false,
    name: null,
    email: null
  })

  onCreateOption = name => {
    this.store.setState({ name, isAddingUser: true })
    debug('onCreateOption', { isAddingUser: this.store.isAddingUser })
  }

  onAddUser = () => {
    const { item } = this.props
    const { name, email } = this.store
    const newItem = item.memberList.addItem({ name, email })
    item.addMember(item.memberList.getItemByIdString(newItem.id))
    this.store.setState({ name: null, email: null, isAddingUser: false })
    debug('onCreateOption', { item, newItem })
  }

  onChange = option => {
    const { item } = this.props
    const member = item.memberList.getItemByIdString(option.value)
    item.addMember(member)
    debug('set item member', { item, option })
  }

  onHideAddUser = () => {
    this.store.setState({ isAddingUser: false })
  }

  render() {
    const { item, addMemberPlaceholder, selectStyles, avatarStyles } = this.props
    const { isAddingUser } = this.store
    const members = item.memberList.items
    const memberIds = members.map(member => member.id.toString())
    const options = item.memberList.items
      .filter(({ id }) => !memberIds.includes(id.toString()))
      .map((member) => ({ 
        label: member.name, 
        value: member.id 
      }))
    debug('render', { item, options, store: this.store })
    return (
      <>
        {
          members.length > 0 ? (
            <div className="avatar-list">
              {members.map(member => (
                <DetailMemberItem
                  key={member.id}
                  member={member}
                  item={item}
                  avatarStyles={avatarStyles}
                />
              ))}
            </div>
          ) : null
        }
        <div className="detail-member-select">
          <Select 
            isClearable
            hasAddOption
            createOptionPosition={'first'}
            placeholder={addMemberPlaceholder}
            onChange={this.onChange}
            onCreateOption={this.onCreateOption}
            options={options}
            value={null}
            styles={{ ...selectStyles, ...selectStylesDefault}}
          />
          {
            isAddingUser && (
              <AddUserDropdown
                store={this.store}
                onHide={this.onHideAddUser}
                onAddUser={this.onAddUser}
              />
            )
          }
        </div>
      </>
    )
  }
}

export default observer(DetailAddMembers)

const selectStylesDefault = {
  control: styles => {
    return {
      ...styles,
      minHeight: 30,
      height: 30,
      marginTop: 0,
      marginLeft: 5,
    }
  },
}
