import { observable, override } from 'mobx'
import { stores } from 'Stores';
import { TaskLabel } from './TaskLabel';
import OrderedList from 'Stores/Lists/OrderedList';
import { hasOne } from 'Relationships/RelationshipDecorators';
import { LabelGroup } from './LabelGroup';

export class LabelList extends OrderedList {

  @observable title: string = null

  get ModelType(): TaskLabel {
    return stores.TaskLabel
  }

  @hasOne(() => stores.LabelGroup, (group: LabelGroup) => group.labelList)
  labelGroup: LabelGroup

  @override get saveOrderKey(): string {
    const key = 'list/order/' + this.modelName + '/' + this.labelGroup?.id
    return key
  }

  @override async fetch() {
    const resp = await this.fetchState.get('task/label/list')
    console.log('LabelList fetch', { resp })
    return this.fromJSON(resp?.data)
  }

}