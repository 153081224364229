import { computed, observable } from "mobx";
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";
import { TimespanPieceFlow } from "Stores/ActionPlanner/PlannerFlow/TimespanPieceFlow";
import Model from "Stores/Model";

export class WeekPlannerFlowModel extends Model {

  @observable plannerFlow: PlannerFlow = null

  getTaskPiecesForTimespan(ts: TimespanPieceFlow) {
    const typeIndex = this.plannerFlow.timespanTypes
      .indexOf(ts.type)
    return this.plannerFlow.taskPiecesFlow[typeIndex]?.taskFlowTimespans
      .filter(piece => piece.timespanPiece === ts) || []
  }

  @computed get dayGroupedTimespans(): TimespanPieceFlow[][] {
    const { timespanFlow, daysLength } = this.plannerFlow
    const timespans = [ ...timespanFlow ]
    return [...Array(daysLength)].map(() => {
      let duration = 0
      const dayTimespans = []
      while(duration < 24*60) {
        const timespan = timespans.shift()
        if (!timespan) break
        duration += timespan.duration
        dayTimespans.push(timespan)
      }
      return dayTimespans
    })
  }

  @computed get firstDayTimespans() {
    return this.dayGroupedTimespans[0]
  }
}