import { ApiRequest } from 'Stores/Service'
import Model from '.'
import { RequestAdapterType } from './ModelRequestAdapter'
import ModelRequestApi from './ModelRequestApi'

/**
 * Manages CRUD operations on Models
 */
export default class ModelRepository {

  /**
   * @property {Request} Request creator for this implementation
   */
  RequestType: typeof ModelRequestApi = ModelRequestApi

  constructor(RequestType?: typeof ModelRequestApi) {
    if (RequestType) this.RequestType = RequestType
  }

  createRequest(model: Model, type: RequestAdapterType, request: ApiRequest): ModelRequestApi {
    return new this.RequestType(model, type, request)
  }

}
