import { action, makeObservable } from 'mobx';
import { Api } from '../../services/api'
import { RequestState } from './Request'
import { ModelJsonI } from 'Stores/Model/Type/Model';
import { TreksApi } from 'services/api/TreksApi';
import stores, { getStore } from 'Stores/Stores';

const debug = require('debug')('treks:stores:service:api:req')

/**
 * Wraps API request with state
 */
export default class ApiRequest extends RequestState {

  /**
   * @property {api}
   */
  api:Api

  constructor(api?:Api) {
    super()
    this.api = api ? api : new TreksApi()
    makeObservable(this)
  }

  /**
   * Make an API post and track request state
   */
  @action post = async (path:string, data:ModelJsonI = {}): Promise<ModelJsonI> => {
    debug('post', { path, data })
    const { deviceUid } = getStore(stores.Session)
    return await this.setRequest(this.api.post(path, {
      ...data,
      updateDeviceUid: deviceUid
    }))
  }

  /**
   * Make an API post and track request state
   */
  @action postJson = async (path:string, data:ModelJsonI = {}): Promise<ModelJsonI> => {
    debug('post', { path, data })
    const { deviceUid } = getStore(stores.Session)
    return await this.setRequest(this.api.fetch(path, {
      json: true,
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        ...data,
        updateDeviceUid: deviceUid
      })
    }))
  }
  
  /**
   * Make an API get and track request state
   */
  @action get = async (path:string, data:ModelJsonI = {}, options:any = {}): Promise<ModelJsonI> => {
    debug('get', { path, data })
    return await this.setRequest(this.api.get(path, data, options))
  }

  /**
   * Make an API download and track request state
   */
  @action download = async (path:string, data:ModelJsonI = {}): Promise<Blob> => {
    debug('download', { path, data })
    return await this.setRequest(this.api.download(path, data))
  }

  /**
   * Make an API raw fetch and track request state
   */
  @action fetch = async (path:string, options:any = {}): Promise<ModelJsonI> => {
    debug('fetch', { path, options })
    return await this.setRequest(this.api.fetch(path, options))
  }

}
