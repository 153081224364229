import Model from "Stores/Model"

export default class ModelApiMiddleware {

  fn = null

  constructor(fn:Function) {
    if (!fn) throw new TypeError('Function is required')
    this.fn = fn
  }

  /**
   * Calls the middleware `function` in the context of `model` 
   * with api `action`, `json` payload and `next` Middleware in stack
   */
  call(model:Model, action:string, json:any, next:ModelApiMiddleware|null) {
    return this.fn.call(model, action, json, next)
  }

}