import { computed, observable, override } from "mobx";
import stores, { getStore } from "Stores";
import ActionPlanner from "Stores/ActionPlanner";
import { TimeSpanListDaySettings } from "Stores/ActionPlanner/TimeSpan/Settings";
import Model from "Stores/Model";
import { getPFASettingsStore } from "../../stores/PFASettingsStore";
import { PFAPlanScoreModel } from "./PFAPlanScoreModel";
import { PFAAccomplishScoreModel } from "./PFAAccomplishScoreModel";
import PFASettingsModel from "../PFASettings/PFASettingsModel";
import { PFAScoreSnap } from "./PFAScoreSnapshot/PFAScoreSnap";

export class PFAScoreModel extends Model {

  @computed get pfaSettings(): PFASettingsModel {
    return getPFASettingsStore()
  }

  @computed get dayActionPlanner(): ActionPlanner {
    return getStore(stores.ActionPlanner as any)
  }

  @computed get dayTimespans(): TimeSpanListDaySettings {
    return this.dayActionPlanner.dayTimespans
  }

  @observable planScore: PFAPlanScoreModel = PFAPlanScoreModel.create()

  @observable focusScore: PFAPlanScoreModel = PFAPlanScoreModel.create()

  @observable accomplishScore: PFAAccomplishScoreModel = PFAAccomplishScoreModel.create()

  @computed get scores() {
    return {
      plan: this.planScore.total, 
      focus: this.focusScore.total, 
      accomplish: this.accomplishScore.total
    }
  }

  @computed get values() {
    return [this.planScore.total, this.focusScore.total, this.accomplishScore.total]
  }

  @computed get total() {
    const maxSum = this.values.length * 100
    return this.values.reduce((sum, value) => sum + value, 0) / maxSum
  }

  @computed get pfaScore(): number {
    const { scoreWeights } = this.pfaSettings
    const weights = scoreWeights.values
    const scores = this.values
    const weightedScores = scores.map((score, index) => score * weights[index])
    const totalScore = weightedScores.reduce((total, score) => total + score, 0)
    return totalScore
  }

  @computed get pfaScoreSnapshot(): PFAScoreSnap {
    const { scores, pfaScore } = this
    return {
      pfaScore,
      ...scores
    } as PFAScoreSnap
  }

  @override async save() {
    return this.saveState.postJson('pfa/score/save', this.pfaScoreSnapshot)
  }

}