
import loadable from '@loadable/component'

export const DayTabItems = [
  {
    key: 'plan',
    label: 'Plan',
    component: loadable(() => import('../Tabs/PlanTab'))
  },
  {
    key: 'focus',
    label: 'Focus',
    component: loadable(() => import('../Tabs/FocusTab'))
  },
  {
    key: 'accomplish',
    label: 'Accomplish',
    component: loadable(() => import('../Tabs/AccomplishTab'))
  }
]