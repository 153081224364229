import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
/**
 * Removed @fortawesome/free-brands-svg-icons
 * https://github.com/FortAwesome/react-fontawesome/issues/70#issuecomment-411165298
 * @todo code splitting works for @fortawesome/fontawesome-svg-core only
 * @see .babelrc
 */
//import { fab } from '@fortawesome/free-brands-svg-icons'
import { 
  faChevronRight, faChevronLeft, faChevronDown, faChevronUp,
  faTimes, faFile, faFileUpload,
  faFileImage, faFileWord, faFileAudio, faFileCode, faFileArchive, faFileVideo,
  faFilePdf,
  faFileExcel,
  faFileCsv,
  faFileDownload,
  faFileAlt,
  faQuestionCircle,
  faArrowCircleDown, faArrowCircleLeft, faArrowCircleUp, faArrowCircleRight,
  faArrowDown, faArrowLeft, faArrowUp, faArrowRight,
  faSearchPlus, faSearch,
  faPlus, faTimesCircle,
  faSpinner, faCloudUploadAlt, faCloudDownloadAlt, faCircleNotch,
  faThumbtack, faInbox, faCircle, faReply, faArchive, faEnvelope, faEnvelopeOpen, faHeartbeat, faGripVertical,
  faHashtag, faAdd, faCirclePlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faChevronRight, faChevronLeft, faChevronDown, faChevronUp,
  faTimes, faFile, faFileUpload, faFileDownload, faFileAlt,
  faFileImage, faFileWord, faFileAudio, faFileCode, faFileArchive, faFileVideo,
  faFilePdf, faFileExcel, faFileCsv,
  faQuestionCircle,
  faArrowCircleDown, faArrowCircleLeft, faArrowCircleUp, faArrowCircleRight,
  faArrowDown, faArrowLeft, faArrowUp, faArrowRight,
  faSearchPlus, faSearch,
  faPlus, faTimesCircle,
  faSpinner, faCloudUploadAlt, faCloudDownloadAlt, faCircleNotch,
  faThumbtack, faInbox, faCircle, faReply, faArchive, faEnvelope, faEnvelopeOpen, faHeartbeat,
  faGripVertical,
  faHashtag, faAdd, faCirclePlus
)

const sizeProps = ["lg","xs","sm","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]

export const Icon = ({ size, ...props }) => {
  // attempt conversion of px size to FontAwesome format
  if (!sizeProps.includes(size)) {
    const sizeX = Math.round(parseInt(size, 10) / 16) + 'x'
    props.size = sizeProps.includes(sizeX) ? sizeX : size
  }
  return (
    <FontAwesomeIcon {...props} />
  )
}
export default Icon