import { observable, computed } from "mobx";
import TaskItem from "Stores/Task";
import { TimeSpanItem } from ".";
import { Item } from "Stores/Lists";

/**
 * A Piece of a task split between timespans
 * Each pieces is unique to task and timespan
 * @note this is an optimization for TimespanItem.getTaskVisibleDuration(TaskItem)
 * @note As a model, we can memoize and trigger re-computes only when dependent changes occur
 */
 export class TimespanTaskPiece extends Item {

  @observable task:TaskItem = null

  @observable timespan:TimeSpanItem = null

  @computed get duration(): number {
    return this.timespan.getTaskVisibleDuration(this.task)
  }

  @computed get underflow(): number {
    return this.timespan.getTaskUnderflow(this.task)
  }

  @computed get overflow(): number {
    return this.timespan.getTaskOverflow(this.task)
  }

}