import TaskList from "./TaskList"

export class TaskListOrphaned extends TaskList {

  createFetchPageRequest(start: number, count: number): Promise<any> {
    return this.fetchState.get('task/list/paged', {
      start,
      count,
      noCategory: 1,
      noProject: 1,
      noGoal: 1
    })
  }

}