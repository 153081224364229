import React from 'react'
import { observer } from 'mobx-react'
import TaskDetail from '../Components/TaskDetail'
import { getStore, models } from 'Stores'
import { reaction } from 'mobx'
import { getMQTTState } from 'uiState/MQTTState'

const debug = require('debug')('treks:container:task')

class TaskDetailContainer extends React.Component {

  store = getStore(models.ActionPlanner)

  componentDidMount() {
    const { focusedItem } = this.store
    if (!focusedItem?.item?.id) {
      focusedItem.fetch()
    }
    this.disposeFocusItem = reaction(() => focusedItem?.item, (item) => {
      debug('TaskDetailContainer', item, item?.id)
      if (item?.id) {
        focusedItem.save()
      }
    })
    this.onMqtt()
  }

  componentWillUnmount() {
    if (this.disposeFocusItem) this.disposeFocusItem()
    this.offMqtt()
  }

  onMqtt() {
    const mqttSyncClient = getMQTTState().getClient()
    mqttSyncClient.on('create', this.onCreateModel)
    mqttSyncClient.on('update', this.onUpdateModel)
  }

  offMqtt() {
    const mqttSyncClient = getMQTTState().getClient()
    mqttSyncClient.removeListener('create', this.onCreateModel)
    mqttSyncClient.removeListener('update', this.onUpdateModel)
  }

  onCreateModel = (data) => {
    debug('mqtt create', data)
    if (data.model.Activity) {
      const activity = data.model.Activity
      const item = this.getItem()
     debug(' this.props.item',  item, activity)
     item.activityList.updateItemFromJSON(activity)
    }
  }

  onUpdateModel = (data) => {
    debug('mqtt update', data)
    if (data.model.Activity) {
      const activity = data.model.Activity
      const item = this.getItem()
      debug(' this.props.item',  item, activity)
      item.activityList.updateItemFromJSON(activity)
    }
  }

  getItem() {
    const { store } = this
    const item = store.focusedItem?.item
    return item
  }

  render() {
    const { pane } = this.props
    const { store } = this
    const item = this.getItem()
    debug('render', { store, item })
    /**
     * @todo add loading animation for saved focused item
     * store.focusedItem.fetchState.state !== 'fulfilled' && <Loading />
     */
    if (!item) return null
    return (
      <TaskDetail store={store} list={store} item={item} pane={pane} />
    )
  }
}

export default observer(TaskDetailContainer)