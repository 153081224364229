import React from 'react'
import './SubMenu.scss'
import { observer } from 'mobx-react';
import Select from '../../../theme/Form/Select';
import { action, when } from 'mobx'
import { ProjectPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { getAppNotifications } from 'AppNotifications';
import { getStore, models } from 'Stores';

const debug = require('debug')('treks:menu:project')

class ProjectSubMenu extends React.Component {

  static defaultProps = {
    selectPlaceholder: 'Projects',
    noOptionsMessage: () => 'No projects matching search...',
    menuOpenValue: 'Search Projects...',
    menuOpenPlaceholder: 'Search Projects...',
    addOptionLabel: 'Create New Project...',
    get projectList() {
      return getStore(models.ProjectList)
    },
    get mainPaneGroupStore() {
      return getStore(models.MainPaneGroup)
    }
  }

  async componentDidMount() {
    when(() => getStore(models.Session).isLoggedIn, () => {
      this.fetchProjects()
    })
  }

  async fetchProjects() {
    return await this.props.projectList.fetched()
      .catch(err => {
        console.error('Error', err)
        getAppNotifications().error('Failed to load your projects')
      })
  }

  getProject() {
    const { projectList, mainPaneGroupStore } = this.props
    const pane = mainPaneGroupStore.getItemByPaneType(ProjectPane) 
      || mainPaneGroupStore.getChildTabByType(ProjectPane)
    const project = pane?.modelId && projectList.getItemById(pane.modelId)
    return project
  }

  @action setProject(project) {
    const paneItem = this.props.mainPaneGroupStore.addOrUpdateItem(ProjectPane)
    paneItem?.setModelId(project.id)
  }

  @action onChange = option => {
    const { value } = option || { value: null }
    const project = value && this.props.projectList.getItemByIdString(value)
    this.setProject(project)
    debug('onChange', { value, option, project })
  }

  @action onCreateOption = async label => {
    const { projectList } = this.props
    const project = await projectList.addItem({ title: label }).save()
    this.setProject(project)
    debug('onCreateOption', { label, project })
  }

  render() {
    const { 
      selectPlaceholder, noOptionsMessage, menuOpenValue, menuOpenPlaceholder, addOptionLabel, projectList 
    } = this.props
    const project = this.getProject()
    const options = projectList.items
      .map(({ title, id }) => ({ 
        label: title, 
        value: id && id.toString()
      }))
    const value = project && project.title
      ? { label: project.title, value: project.id }
      : null
    debug('render', { project, options, value })
    return (
      <Select
        isClearable
        hasAddOption
        createOptionPosition={'first'}
        addOptionLabel={addOptionLabel}
        onAddOption={this.onAddOption}
        menuOpenPlaceholder={menuOpenPlaceholder}
        menuOpenValue={menuOpenValue}
        noOptionsMessage={noOptionsMessage}
        placeholder={selectPlaceholder}
        onChange={this.onChange}
        options={options} 
        value={value}
        onCreateOption={this.onCreateOption}
      /> 
    )
  }
}

export default observer(ProjectSubMenu)