import React from 'react'
import { observer } from 'mobx-react'

class ListCol extends React.Component {

static defaultProps = {
  className: ''
}

  render() {
    const { className } = this.props
    return (
      <div className={'list-col ' + className}>
        {this.props.children}
      </div>
    )
  }
}

export default observer(ListCol)