import React from 'react'
import { observer } from 'mobx-react'
import ListViewTaskList from './ListViewTaskList'
import Sortable from 'Modules/ReactSortable/ReactSortable'
import { AddItemBtn } from 'Components/ui/AddItemBtn'
import { ParentItemTitleInput } from '../Shared/ItemTitleInput'
import { observable } from 'mobx'
import { CollapsableIcon } from '../Shared/CollapsableIcon'
import { Collapsable } from 'theme/Collapsable'
import { CategoryItem, CategoryList } from 'Stores/Category'
import ListViewFlatList from '../Shared/ListViewFlatList/ListViewFlatList'
import { getStore, models } from 'Stores'
import Icon from 'theme/Icon'
import { ProjectItem } from 'Stores/Project'
import { GoalItem } from 'Stores/Goal'
import { LabelGroup } from 'Stores/Task/Label/LabelGroup'
import { RemoveBtn } from 'theme/RemoveBtn/RemoveBtn'
import { IconPlusCircle } from 'theme/Icons/IconPlusCircle'
import TaskItem from 'Stores/Task'

const debug = require('debug')('treks:list:task:category:list')

type Props = {
  item: GoalItem | ProjectItem ;
  categoryList: CategoryList;
  showHeader: boolean;
  showFooter: boolean;
  showCategoryHeader: boolean;
  showCategoryFooter: boolean;
  icon: string;
  flat: boolean;
  filter: () => TaskItem[];
  fields: string[];
  order: string[];
  showUncategorized: boolean;
  noCategory: boolean;
  noProject: boolean;
}

class ListViewTaskCategoryList extends React.Component<Props> {

  static defaultProps = {
    showHeader: true,
    showFooter: true,
    showCategoryHeader: true,
    showCategoryFooter: true
  }

  componentDidMount() {
    const { categoryList } = this.props
    categoryList.fetchOrder()
    // categoryList.fetch()
  }

  isCollapsed = observable.box(false)
  
  onPressCollapsable = () => {
    this.isCollapsed.set(!this.isCollapsed.get())
  }

  onAddTask = () => {
    const { item, categoryList } = this.props
    const lastList = [ ...categoryList.items ].pop()
    if (lastList && lastList.isFlatList) {
      lastList.addItem(this.createEmptyItem()).setFocusOnTitle()
    } else {
      const newItem = item.taskList.addItem(this.createEmptyItem()) as TaskItem
      newItem.setFocusOnTitle()
    }
  }

  onAddList = () => {
    this.addList({ isFlatList: false })
  }

  addList(props) {
    const { categoryList } = this.props
    const emptyCategory = categoryList.addItem(this.createEmptyCategory(props)) as CategoryItem
    if (props.isFlatList) {
      const task = emptyCategory.taskList.addItem(this.createEmptyItem()) as TaskItem
      task.setFocusOnTitle()
    } else {
      emptyCategory.setFocusOnTitle()
    }
  }

  createEmptyCategory(props) {
    return {
      title: '',
      ...props
    }
  }

  createEmptyItem(props = {}) {
    const { item } = this.props
    const { focusedTimespan } = item.list
    return {
      title: '',
      duration: 15,
      timespan: focusedTimespan || item.startTimespan,
      onPlanner: true,
      ...props
    }
  }

  onSort = (uids) => {
    const { categoryList, item } = this.props
    const categories = uids.map(uid => {
      const model = categoryList.ModelType.fromProps({ uid })
      return model
    })
    const catUids = categories.map(cat => cat.uid)
    debug('onSort', { item, categoryList, uids, categories, catUids })
    categoryList.setOrder(uids)
    categoryList.saveOrder()
  }

  viewFilter = (items) => {
    const { view } = this.props
    if (view === 'not-done') {
      return items.filter(item => item.done !== true)
    }
    if (view === 'done') {
      return items.filter(item => item.done === true)
    }
    return items
  }

  render() {
    const { 
      icon,
      flat,
      item,
      categoryList,
      filter,
      fields,
      order,
      showUncategorized,
      showHeader,
      showFooter,
      showCategoryHeader,
      showCategoryFooter,
      noCategory,
      noProject
    } = this.props
    const isCollapsed = this.isCollapsed.get()
    const style = {
      borderColor: item.color
    }
    const flatCatFields = ['category', ...fields.filter(field => field !== 'parent')]
    const noCategoryItem = getStore(models.CategoryItem, { uid: 'no-category', title: 'Uncategorized'})
    const  { noCategoryItems } = item.taskList

    const parentType = (item instanceof ProjectItem) ? 'project'
      : (item instanceof GoalItem) ? 'goal' 
      : (item instanceof LabelGroup) ? 'label' : ''
    const groupName = 'categories-' + parentType
    const iconName = parentType === 'label' ? 'hashtag' : parentType
    const iconSet = iconName === 'hashtag' ? 'fa' : ''

    debug('render', item.title, { item, categoryList, filter, order })
    return (
      <div className={"list-view-category-list task-category-list"} style={style}>
        {
          showHeader ? (
            <header className="category-list-header">
              <Icon name={'drag'} color={'#97aaae'} size={10} />
              {
                icon || (
                  <Icon name={iconName} color={'#97aaae'} size={15} set={iconSet} />
                )
              }
              <ParentItemTitleInput item={item} />
              <CollapsableIcon
                onPress={this.onPressCollapsable}
                isCollapsed={isCollapsed}
              />
              <RemoveBtn style={{ visibility: 'hidden' }} />
            </header>
          ) : null
        }
        <Collapsable isCollapsed={isCollapsed}>
          <Sortable
            className={'category-list-body ' + groupName}
            options={{
              handle: '.icon-drag',
              animation: 100,
              fallbackOnBody: true,
              swapThreshold: 0.65,
              group: {
                name: groupName,
                put: [groupName],
              }
            }}
            onChange={this.onSort}
          >
            {
              flat
              ? <ListViewFlatList
                  key={item.uid}
                  showParent={false}
                  taskList={item.taskList}
                  tasks={this.viewFilter(item.taskList.visibleItems)}
                  filter={filter}
                  fields={flatCatFields}
                  order={order}
                />
              : categoryList.visibleItems.map((category) => (
                  <ListViewTaskList
                    key={category.uid}
                    showHeader={showCategoryHeader}
                    showFooter={showCategoryFooter}
                    category={category}
                    list={category.taskList}
                    items={this.viewFilter(category.taskList.visibleItems)}
                    parent={item} 
                    filter={filter}
                    fields={fields}
                    order={order}
                    noCategory={noCategory}
                    noProject={noProject}
                  />
                ))
            }
            {
              (!flat && showUncategorized)
                ? (
                  <ListViewTaskList
                    showHeader={noCategoryItems.length > 0 ? true : false}
                    showFooter={false}
                    key={item.taskList.uid}
                    category={noCategoryItem}
                    list={item.taskList}
                    items={noCategoryItems}
                    parent={item} 
                    filter={filter}
                    fields={fields}
                    order={order}
                    noCategory={true}
                  />
                )
                : null
            }
          </Sortable>

        {
          flat
            ? null
            : showFooter ? (
              <footer className="add-item-btns">
                <AddItemBtn
                  label={(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <svg style={{ width: 18, height: 18 }} className="icon-plus-circle" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.8v8.4M7.8 12h8.4m4.8 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                      <span style={{ marginLeft: 5 }}>{' Add Task'}</span>
                    </div> 
                  )}
                  onClick={this.onAddTask}
                  className={'btn-add-task'}
                  tooltipTitle={'Enter'}
                />
                <AddItemBtn
                  label={(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconPlusCircle size={18} />
                      <span style={{ marginLeft: 5 }}>{' Add Category'}</span>
                    </div> 
                  )}
                  onClick={this.onAddList}
                  className={'btn-add-category'}
                  tooltipTitle={'Ctrl + Enter'}
                />
              </footer>
            ) : null
        }


        </Collapsable>
      </div>
    )
  }

}

export default observer(ListViewTaskCategoryList)