import React from 'react'
import Icon from '../../theme/Icon'
import { observer } from 'mobx-react';

const TaskDetailRow = ({ className, icon, children, hasValue, iconSet }) => {
  return (
    <div className={'row' + (className ? ' ' + className : '')}>
      <div className={'row-icon' + (hasValue ? ' has-value' : '') }>
        {
          typeof icon == 'string'
            ? <Icon icon={icon} set={iconSet || 'treks'} size={18} />
            : icon
        }
      </div>
      {children}
    </div>
  )
}

export default observer(TaskDetailRow)