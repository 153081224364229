import React from 'react'
import { observer } from 'mobx-react'
import { parseJSON } from 'Stores/utils'
import stores, { getStore } from 'Stores'

const debug = require('debug')('treks:task:activity:text')

class TaskActivityText extends React.Component {

  get actionPlanner() {
    return getStore(stores.ActionPlanner)
  }

  getUpdateText(item) {
    const key = [ ...Object.keys(item.changes) ].pop()
    const value = item.changes[key]
    if (key) {
      if (key === 'assignedUser') {
        return `assigned the task to ${parseJSON(item.changes.assignedUser).name}`
      }
      if (key === 'project') {
        return `changed the project to ${parseJSON(item.changes.project).title}`
      }
      if (key === 'category') {
        return `changed the category to ${parseJSON(item.changes.category).title}`
      }
      if (key === 'fileList') {
        return `updated the files`
      }
      if (key === 'memberList') {
        return `updated the members`
      }
      if (key === 'dueDate') {
        return `changed the due date to ${this.actionPlanner.formatDateTime(item.changes.dueDate)}`
      }
      if (key === 'duration') {
        return `changed the duration to ${item.changes.duration} mins`
      }
      if (key === 'onPlanner') {
        return item.changes.onPlanner ? `added the task to planner` : 'removed the task from planner'
      }
      if (key === 'subTasksList') {
        return `changed the subtasks`
      }
      return `changed the ${key} to ${value}`
    }
    return item.title
  }

  getText(item) {
    if (item.action === 'update' && item.changes) {
      return this.getUpdateText(item)
    } else {
      return item.title
    }
  }

  render() {
    const { item } = this.props
    const text = this.getText(item)
    debug('render', { item, text })
    if (!text) return null
    return (
      <>
        {text}
      </>
    )
  }
}

export default observer(TaskActivityText)