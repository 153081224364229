import React from 'react'
import ActionPlannerSubtaskList from '../ActionPlannerSubtaskList';
import { observer } from 'mobx-react';
import TaskList from 'Components/ui/SubTasks/TaskList';

const debug = require('debug')('treks:planner:item:body')

class ActionPlannerItemBody extends React.Component {

  render() {
    const { item, taskPiece } = this.props
    const { subTasksList } = item
    debug('render', this.props)

    return (
      <div className="action-planner-item-body md-light-scrollbar">
        {subTasksList && !(taskPiece.underFlow > 0) && (
          <div className="action-planner-sub">
          {/* <ActionPlannerSubtaskList
            task={item}
            parent={subTasksList}
            list={subTasksList} 
          /> */}
          <TaskList
            showTitle={false}
            item={item}
            list={subTasksList}
            parent={subTasksList} 
            // view={view}
            // order={order}
          />
          </div>
        )}
      </div>
    )
  }
}

export default observer(ActionPlannerItemBody)
