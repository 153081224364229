import React from 'react'
import { observer } from 'mobx-react'
import './Settings.scss'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CalendarAccounts from './CalendarAccounts'
import CalendarGlobalSettings from './CalendarGlobalSettings'
import CalendarConfig from '../../../Config/CalendarConfig'
import Pressable from '../../../theme/Pressable';
import stores, { getStore } from 'Stores';

const CalendarAccountsLabel = 'Calendar Accounts'
const CalendarSettingsLabel = 'Settings'
const { addAccountUrl } = CalendarConfig

class CalendarSettings extends React.Component {

  onAddAccount = async () => {
    const Session = getStore(stores.Session)
    const token = await Session.getToken()
    window.location.href = addAccountUrl + '?token=' + token
  }

  render() {
    return (
      <Container className="calendar-settings">
        <Row className="calendar-accounts">
          <Col>
            <header className="header ">
              <h3>{CalendarAccountsLabel}</h3>
            </header>
            <CalendarAccounts />
          </Col>
        </Row>
        <Row className="calendar-add">
          <Col>
            <Pressable className="btn-calendar-add" onClick={this.onAddAccount}>+ Add Account</Pressable>
          </Col>
        </Row>
        <Row className="calendar-settings">
          <Col>
            <header className="header ">{CalendarSettingsLabel}</header>
            <CalendarGlobalSettings />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default observer(CalendarSettings)