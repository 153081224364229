import { override } from 'mobx'
import { List } from '../../Lists'
import { hasOne } from 'Relationships/RelationshipDecorators'
import { models } from 'Stores/Stores'
import TaskItem from '..'

export default class TaskCommentList extends List {

	@hasOne(() => models.TaskItem, (task: TaskItem) => task.commentList)
  task: TaskItem

  get ModelType() {
    return models.TaskCommentItem
  }

  @override async fetch() {
    return this.fetchState.get('comment/list', { taskId: this.task.id })
      .then(resp => {
        if (resp && resp.data && resp.data.comments) {
          this.fromJSON({ items: resp.data.comments })
        }
      })
  }

}