import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import Pressable from '../../../theme/Pressable'

class EventDoneCheckbox extends React.Component {

  onClick = () => {
    const { event} = this.props
    event.done = !event.done
  }

  render() {
    const { event } = this.props
    const icon = event.done ? 'checkbox_checked' : 'checkbox_empty'
    return (
      <Pressable
        onClick={this.onClick}
        className={'press-event-done'}
      >
         <Icon
          icon={icon}
          size={15}
          className={'icon-event-done'}
        />
      </Pressable>
     
    )
  }
}

export default observer(EventDoneCheckbox)