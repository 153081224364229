import React from 'react'
import GoalDetailRow from '../GoalDetailRow'
import { observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './GoalAddComment.scss'

const debug = require('debug')('treks:goal:comment:add')

class GoalAddComment extends React.Component {

  textRef = null

  store = observable({
    focused: null,
    text: ''
  })

  static propTypes = {
    onAddReply: PropTypes.func.isRequired
  }

  resetStore() {
    Object.assign(this.store, {
      focused: null,
      text: '',
    })
  }

  onTitleFocus = () => {
    this.store.focused = true
    setTimeout(() => this.textRef.focus(), 50)
    debug('focused', toJS(this.store))
  }

  onTextChange = event => {
    this.store.text = event.target.value
  }

  onSubmit = async () => {
    const added = await this.props.onAddReply({
      text: this.store.text
    })
    if (added) {
      this.resetStore()
      
    }
  }

  render() {
    const { focused } = this.store
    return (
      <div className={'container comment-add' + (focused ? ' focused' : '')}>
        <GoalDetailRow icon="comment">
          <div className="comment-box">
            {
              focused ? (
                <>
                <Form.Control
                  ref={ref => this.textRef = ref}
                  as="textarea"
                  rows="3"
                  value={this.store.text}
                  onChange={this.onTextChange}
                  className="comment-input comment-text"
                  placeholder={'Response'}
                />
                  <Form.Group className="form-group-btn" controlId="comment-add-btn">
                    <Button onClick={this.onSubmit}>Respond</Button>
                  </Form.Group>
                </>
              ) : (
                <Form.Control 
                  placeholder={'Respond'}
                  className="comment-input" 
                  onFocus={this.onTitleFocus}
                />
              )
            }
          </div>
        </GoalDetailRow>
      </div>
    )
  }
}

export default observer(GoalAddComment)
