export const defaultTimespanList = [
  {
    type: 'sleep',
    title: 'Sleep',
    color: '#999999'
  },
  {
    type: 'personal',
    title: 'Personal AM',
    color: '#e8f7fc',
  },
  {
    type: 'work',
    title: 'Work',
    color: '#f5f5f5',
  }
]