import React from 'react'
import './SubMenu.scss'
import { observer } from 'mobx-react';
import Select from '../../../theme/Form/Select';
import { action } from 'mobx'
import { models } from 'Stores';

const debug = require('debug')('treks:menu:task')

class TaskSubMenu extends React.Component {

  static defaultProps = {
    selectPlaceholder: 'Tasks',
    noOptionsMessage: () => 'No Tasks matching search...',
    menuOpenValue: 'Search Tasks...',
    menuOpenPlaceholder: 'Search Tasks...',
    addOptionLabel: 'Create New Task...',
    get taskList() {
      return getStore(models.ActionPlanner)
    },
    get mainPaneGroupStore() {
      return getStore(models.MainPaneGroup)
    },
    get actionPlannerStore() {
      return getStore(models.ActionPlanner)
    },
    fetchTasksErrMsg: 'Unable to fetch tasks',
    get session() {
      return getStore(models.Session)
    },
  }

  @action setTask(task) {
    this.props.actionPlannerStore.setFocusedItem(task)
    task.setFocusOnTitle()
    task.save()
  }

  createTask(title) {
    const { actionPlannerStore } = this.props
    const { focusedTimespan } = actionPlannerStore
    const taskProps = {
      title,
      duration: 15,
      timespan: focusedTimespan || { type: 'work' },
      onPlanner: true
    }
    const task = actionPlannerStore.addItem(taskProps)
    this.setTask(task)
  }

  @action onChange = option => {
    const { value } = option || { value: null }
    const task = value && this.props.taskList.getItemByUid(value)
    this.setTask(task)
    debug('onChange', { value, option, task })
  }

  @action onCreateOption = label => {
    this.createTask(label)
    debug('onCreateOption', { label })
  }

  render() {
    const { selectPlaceholder, noOptionsMessage, menuOpenValue, menuOpenPlaceholder, addOptionLabel } = this.props
    const task = this.props.actionPlannerStore?.focusedItem?.item
    const options = this.props.taskList.visibleItems
      .map(({ title, uid }) => ({ 
        label: title, 
        value: uid.toString()
      }))
    const value = task && task.title
      ? { label: task.title, value: task.uid }
      : null
    debug('render', { task, options, value })
    return (
      <Select
        isClearable
        hasAddOption
        createOptionPosition={'first'}
        addOptionLabel={addOptionLabel}
        onAddOption={this.onAddOption}
        menuOpenPlaceholder={menuOpenPlaceholder}
        menuOpenValue={menuOpenValue}
        noOptionsMessage={noOptionsMessage}
        placeholder={selectPlaceholder}
        onChange={this.onChange}
        options={options} 
        value={value}
        onCreateOption={this.onCreateOption}
      /> 
    )
  }
}

export default observer(TaskSubMenu)