/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';
import { getStore, models } from 'Stores';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'Modules/moment';
var {
	startOfDay,
	endOfDay,
	startOfYesterday,
	endOfYesterday,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
} = require('date-fns');

const INTERVAL_OPTIONS = [
	{
		text: 'Today',
		start: function () {
			return startOfDay(new Date());
		},
		end: function () {
			return endOfDay(new Date());
		},
	},
	{
		text: 'Yesterday',
		start: function () {
			return startOfYesterday();
		},
		end: function () {
			return endOfYesterday();
		},
	},
	{
		text: 'This Week',
		start: function (weekStart = 0) {
			return startOfWeek(new Date(), weekStart);
		},
		end: function (weekStart = 0) {
			return endOfWeek(new Date(), weekStart);
		},
	},
	{
		text: 'Last Week',
		start: function (weekStart = 0) {
			var d = new Date();

			var nd = d.getDate() - 7;
			d.setDate(nd);
			return startOfWeek(d, weekStart);
		},
		end: function (weekStart = 0) {
			var d = new Date();

			var nd = d.getDate() - 7;
			d.setDate(nd);
			return endOfWeek(d, weekStart);
		},
	},
	{
		text: 'This Month',
		start: function () {
			return startOfMonth(new Date());
		},
		end: function () {
			return endOfMonth(new Date());
		},
	},
	{
		text: 'Last Month',
		start: function () {
			var d = new Date();
			d.setMonth(d.getMonth() - 1);
			return startOfMonth(d);
		},
		end: function () {
			var d = new Date();
			d.setMonth(d.getMonth() - 1);
			return endOfMonth(d);
		},
	},
];

class DashboardDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionMode: 'interval',
            selectionIndex: 0,
            start: null,
            end: null,
        };
    }

    static propTypes = {
        entry: PropTypes.objectOf(() => models.GridItem).isRequired,
    };

    static defaultProps = {
        get gridList() {
            return getStore(models.GridList);
        },
    };

    componentDidMount() {
        const { entry } = this.props;

        if (entry.dateInterval && entry.dateInterval !== "Custom") {
            INTERVAL_OPTIONS.forEach((o, i) => {
                if (o.text === entry.dateInterval) {
                    this.setState({ selectionIndex: i });
                    this.changeInterval(i);
                }
            });
        } else this.setState({ selectionMode: 'range', selectionIndex: 'Custom' });

        //
    }

    onChange = (option) => {
        const { entry } = this.props;
        const { start, end } = this.state;

        console.log('onchange', option.target.value);
        if (option.target.value === 'Custom') {
            this.setState({ selectionMode: 'range' });
            entry.dateInterval = option.target.value
            entry.startDate = start;
            entry.endDate = end;
            this.setState({ selectionIndex: option.target.value });

            return;
        }
        this.setState({ selectionIndex: option.target.value });

        this.changeInterval(option.target.value);
    };

    changeInterval = (idx) => {
        const { entry } = this.props;
        let start = null;
        let end = null;
        let opt = INTERVAL_OPTIONS[parseInt(idx)];

        start = opt.start();
        end = opt.end();

        this.setState({ selectionMode: 'interval', start, end });
        entry.dateInterval = opt.text;
        entry.save();
    };

    render() {
        const { entry } = this.props;
        const { selectionMode, selectionIndex, start, end } = this.state;
        // const { dataType } = entry;
        // const options = this.props[dataType + 'List']
        // 	? this.props[dataType + 'List'].items.map(({ title, id }) => ({
        // 			label: title,
        // 			value: id.toString(),
        // 	  }))
        // 	: [];
        return (
            <div className="dashboarddate">
                <div className="intervals">
                    <select value={selectionIndex} onChange={this.onChange}>
                        {INTERVAL_OPTIONS.map((i, idx) => {
                            return (
                                <option key={'interval' + idx} value={idx}>
                                    {i.text}
                                </option>
                            );
                        })}
                        <option>Custom</option>
                    </select>
                </div>
                {selectionMode === 'interval' ? (
                    <>
                        <IntervalDate start={start} end={end} />
                    </>
                ) : (
                    <DateRangePicker
                        clearIcon={null}
                        calendarIcon={null}
                        value={selectionMode === 'range' ? [entry.startDate, entry.endDate] : [start, end]}
                        disabled={selectionMode === 'interval'}
                        // isOpen={selectionMode == 'range'}
                        onChange={(v) => {
                            console.log('DateRangePicker onChange', v);
                            entry.startDate = v[0];
                            entry.endDate = v[1];
                            entry.save();
                        }}
                    />
                )}{' '}
            </div>
        );
    }
}

export default observer(DashboardDate)

function IntervalDate({ start, end }) {
	if (!start) return <></>;
	if (start.getDate() === end.getDate()) return <div> {moment(start).format('l')} </div>;
	return (
		<div>
			{moment(start).format('l')} - {moment(end).format('l')}
		</div>
	);
}
