import React from 'react'
import Form from 'react-bootstrap/Form'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:form:control:user-select')

const UserSelect = observer(({ user, uiStore }) => {
  const onSelect = event => {
    debug('selected', user, uiStore)
    event.target.blur()
    if (uiStore.isSelected(user)) {
      uiStore.unSelectUsers([ user ])
    } else {
      uiStore.selectUsers([ user ])
    }
    
  }
  debug('render', { user, uiStore, checked: uiStore.isSelected(user) })
  return (
    <Form.Check 
      type="checkbox" 
      onChange={onSelect} 
      checked={uiStore.isSelected(user) || false} 
    />
  )
})

export default UserSelect