import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import './GoalHabits.scss'
import { observable } from 'mobx';
import GoalHabitList from './GoalHabitList'
import { stores, models, getStore } from 'Stores'
import Pressable from '../../../../theme/Pressable';

const debug = require('debug')('treks:goal:habits')

class GoalHabits extends React.Component {

  store = observable({
    view: null,
    order: null
  })

  static propTypes = {
    goal: PropTypes.objectOf(() => stores.Goal).isRequired,
    account: PropTypes.objectOf(() => stores.Account),
  }

  static defaultProps = {
    get account() {
      return getStore(models.Session).Account
    }
  }

  addHabit = ({ title, startDate }) => {
    const { account, goal } = this.props
    const item = {
      members: [{ user: account }],
      title,
      startDate
    }
    debug('adding habit', item)
    goal.habits.addItem(item)
    goal.save()
    return true
  }

  onAddHabit = () => {
    this.addHabit({
      title: '',
      startDate: new Date(),
      goal: this.props.goal
    })
  }

  render() {
    const { goal } = this.props
    debug('render', { goal })
    return (
      <div className="goal-habits">
        <header className="row tab-header">
          <Icon icon="habit" size={15} />
          <div className="tab-title">Habits</div>
        </header>
        <div className="goal-habits-list">
          <GoalHabitList goal={goal} habits={goal.habits} />
          <Pressable
            className="btn-add"
            onClick={this.onAddHabit}
          >
            + Add Habit
          </Pressable>
        </div>
      </div>
    )
  }
}

export default observer(GoalHabits)
