import React from 'react'
import PropTypes from 'prop-types'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'

class FieldCheckBoxList extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    isMulti: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ])
  }

  static defaultProps = {
    options: [],
    isMulti: false
  }

  @observable selected = ''

  constructor() {
    super()
    makeObservable(this)
  }

  componentDidMount() {
    const { value, isMulti } = this.props
    this.selected = value ? value : (isMulti ? [] : '')
  }

  componentDidUpdate(nextProps) {
    const { value, isMulti } = this.props
    if (nextProps.value !== value) {
      this.selected = value ? value : (isMulti ? [] : '')
    }
  }

  onChange = event => {
    const { value, checked } = event.target
    const label = event.target.getAttribute('data-label')
    const { isMulti, onChange } = this.props
    const option = { label, value }
    if (isMulti) {
      if (checked) {
        this.selected.push(option)
      } else {
        const index = this.selected.findIndex(sel => sel.value === value)
        this.selected.splice(index, 1)
      }
    } else {
      this.selected = checked ? option : ''
    }
    onChange(this.selected)
  }

  render() {
    const { name, options, isMulti } = this.props
    const { selected } = this
    return (
      <div className="checkbox-list">
        {
          options.map(option => {
            const checked = isMulti 
              ? selected && selected.map(({value}) => value).includes(option.value)
              : selected.value === option.value
            return (
              <div key={option.value} className="checkbox-item">
                <input
                  id={option.id || option.label}
                  data-label={option.label}
                  name={name}
                  type={isMulti ? 'checkbox' : 'radio'}
                  value={option.value}
                  checked={checked}
                  onChange={this.onChange}
                />
                <label
                  htmlFor={option.id || option.label}
                  className="checkbox-item-label"
                >
                  {option.label}
                </label>
              </div>
            )
          })
        }
      </div>
     )
  }
}

export default observer(FieldCheckBoxList)