import { action, computed, when } from "mobx";
import TaskMemberList from "./TaskMemberList";
import TaskCommentList from "./Comment/TaskCommentList";
import FileList from "../File/FileList";
import { TaskActivityList } from "./Activity";
import { ProjectItem } from "../Project";
import { CategoryItem } from "../Category";
import { User, importUser } from "../User";
import ActionPlannerUtils, {
  dateAddMins,
  toStartOfDayDate,
} from "../ActionPlanner/ActionPlannerUtils";
import TaskList from "./TaskList";
import TaskActions from "./TaskActions";
import { round } from "../utils";
import {
  millisecsToMins,
  minsToMillisecs,
} from "Stores/Calendar/CalendarUtils";
import { importAccount } from "Stores/Account";
import { TimeSpanItem } from "Stores/ActionPlanner/TimeSpan";
import ActionPlanner from "Stores/ActionPlanner";
import { FileItem } from "Stores/File";
import { ActivityItem } from "Stores/Activity";
import { JsonAny } from "Stores/Model/Type/Json";
import { UserI } from "Stores/User/Type/User";
import { ModelPropsI } from "Stores/Model/Type/Model";
import { ItemI } from "Stores/Lists/Type/Item";
import {
  hasGlobal,
  hasMany,
  hasOne,
} from "Relationships/RelationshipDecorators";
import CalendarEvent from "../Calendar/CalendarEvent";
import { Item } from "Stores/Lists";
import { property } from "Stores/Model/ModelDecorators";
import { models, stores } from "Stores";
import { TaskLabel } from "./Label/TaskLabel";
import { TaskLabelList } from "./Label/TaskLabelList";
import Model from "Stores/Model";
import { TaskSubTasksList } from "./TaskSubTasksList";
import { GoalItem } from "Stores/Goal";

const debug = require("debug")("treks:store:task:item");

/**
 * Task
 */
export default class TaskItem extends Item {
  get modelName(): string {
    return "TaskItem"; // prod mangles class names
  }

  list: ActionPlanner;

  // <-- start model props -->

  @property createDate: Date;
  @property updateDate: Date;
  @property timespanType: string;
  @property title: string;
  @property description: string;
  @property isSticky: boolean;
  @property user: User;
  @property done: boolean;
  @property doneDate: Date;
  @property editing: boolean;
  @property focusOnTitle: boolean;
  @property dueDate: Date;
  @property isResizing: boolean;
  @property trashed: boolean;
  @property trashedDate: Date;
  @property updateUserId: number;
  @property isAutoSaved: boolean;
  @property onPlanner: boolean;
  @property events: Array<CalendarEvent> = [];
  @property eventsDurationOverlap: number;
  @property isHovered: boolean;
  @property updateTokenUid: string;
  @property earmarkedDate: Date;
  @property reOrderDate: Date;

  @hasOne(() => User, (user: User) => user.assignedTaskList)
  assignedUser: User;

  // labels
  @hasMany(() => stores.TaskLabelList, (label: TaskLabel) => label.taskList)
  taskLabelList: TaskLabelList;

  // subtasks
  @hasMany(() => stores.TaskSubTasksList, (task: TaskItem) => task.parentTask)
  subTasksList: TaskSubTasksList;

  @hasOne(() => TaskItem, (task: TaskItem) => task.subTasksList)
  parentTask: TaskItem;

  static canBeSubTask(parent: TaskItem, subtask: TaskItem) {
    if (subtask && !(subtask instanceof models.TaskItem)) {
      throw new TypeError("Subtask must be a TaskItem");
    }
    if (
      subtask &&
      models.TaskItem.isSameModel(parent as Model, subtask as Model)
    ) {
      throw new TypeError("Subtask cannot not be the same as parent task");
    }
    if (parent instanceof models.TaskItem && parent.parentTask.id) {
      throw new TypeError("Subtask parent cannot be a subtask");
    }
    if (
      subtask &&
      subtask instanceof models.TaskItem &&
      subtask.subTasksList.items.length > 0
    ) {
      throw new TypeError("A subtask can not have subtasks");
    }
  }

  setParentTask(task: TaskItem) {
    TaskItem.canBeSubTask(this, task);
    this.parentTask = task;
  }

  // blocking/blockedBy
  @hasMany(() => TaskList, (task: TaskItem) => task.blockedByList)
  blockingList: TaskList;

  @hasMany(() => TaskList, (task: TaskItem) => task.blockingList)
  blockedByList: TaskList;

  // hierarchy
  @hasOne(() => ProjectItem, (project: ProjectItem) => project.taskList)
  project: ProjectItem;

  @hasOne(() => GoalItem, (goal: GoalItem) => goal.taskList)
  goal: GoalItem;

  @hasOne(() => CategoryItem, (category: CategoryItem) => category.taskList)
  category: CategoryItem;

  // lists
  @hasMany(() => TaskMemberList, (list: TaskMemberList) => list.task)
  memberList: TaskMemberList;

  @hasMany(() => TaskCommentList, (list: TaskCommentList) => list.task)
  commentList: TaskCommentList;

  @hasMany(() => FileList, (list: FileList) => list.task)
  fileList: FileList;

  @hasMany(() => TaskActivityList, (list: TaskActivityList) => list.task)
  activityList: TaskActivityList;

  @computed get earmarkedTimestamp(): number {
    return toStartOfDayDate(this.earmarkedDate).getTime();
  }

  @action setReOrderDate(date: Date) {
    this.reOrderDate = date;
  }

  @computed get isEarmarked(): boolean {
    return this.earmarkedDate && this.earmarkedDate instanceof Date;
  }

  @computed get isEarmarkedOverdue(): boolean {
    if (!this.isEarmarked) return false;
    const todayTS = toStartOfDayDate(
      this.actionPlanner.startOfTodayDate,
    ).getTime();
    const taskEarmarkedDayTS = toStartOfDayDate(this.earmarkedDate).getTime();
    return !this.done && taskEarmarkedDayTS < todayTS;
  }

  @action setTimespanType(timespanType: string) {
    this.timespanType = timespanType;
  }

  /**
   * @deprecated use task.uid.
   * @note Kept for compat
   */
  @computed get taskId(): string {
    return this.uid;
  }
  set taskId(id) {
    throw new Error("Task taskId is deprecated");
  }

  // does not affect task flow
  @property resizeDuration: number = null;

  @computed get duration(): number {
    return Math.max(this.taskDuration, this.subTasksDuration, this.minDuration);
  }

  set duration(duration: number) {
    this.setAttribute(
      "duration",
      Math.max(Math.round(duration), this.minDuration),
    );
  }

  // <-- end model props -->

  get plannerUtils(): ActionPlannerUtils {
    return this.actionPlanner?.utils;
  }

  @computed get account(): ReturnType<typeof importAccount> {
    return this.actionPlanner && this.actionPlanner.account;
  }

  /**
   * Timespan task lies in is dynamic based on task flow through week days timespans
   * @deprecated Use task.startTimespan and task.endTimespan
   */
  @computed get timespan(): TimeSpanItem {
    return this.startTimespan;
  }

  /**
   * Only set the timespan type as the timespan itself is dynamic
   */
  set timespan(timespan: TimeSpanItem) {
    this.timespanType = timespan?.type;
  }

  @computed get startTimespan(): TimeSpanItem {
    return this.list?.dayTimespans?.items.find((timespan) =>
      timespan.tasksStartIn.map((task) => task.uid).includes(this.uid),
    );
  }

  @computed get endTimespan(): TimeSpanItem {
    return this.list?.dayTimespans?.items.find((timespan) =>
      timespan.tasksEndIn.map((task) => task.uid).includes(this.uid),
    );
  }

  @hasGlobal(() => ActionPlanner)
  actionPlanner: ActionPlanner;

  @action setSticky(date: Date) {
    this.isSticky = true
    this.setAttribute("startDate", date)
  }

  @action setNotSticky() {
    this.isSticky = false
    this.setAttribute("startDate", undefined)
  }

  /**
   * @deprecated use setSticky(date: Date) | setNotSticky()
   */
  @action toggleSticky() {
    if (!this.isSticky) {
      this.setAttribute("startDate", this.startDate);
    }
    this.isSticky = !this.isSticky;
  }

  @computed get taskDuration(): number {
    const taskDuration = this.getAttribute("duration", () => 0);
    const duration = Math.max(
      Number.isInteger(taskDuration) ? taskDuration : 0,
      this.minDuration,
    );
    return duration;
  }

  @computed get subTasksDuration(): number {
    return this.subTasksList?.duration || 0;
  }

  /**
   * Set the duration to 5 (modulo) min increments
   */
  @action setSnappyDuration(toDuration: number, modulo: number = 5) {
    const { duration, startDuration } = this;
    const endDuration = startDuration + duration; // not task.endDuration
    debug("snappy before", { duration, startDuration, endDuration });
    let newDuration = toDuration;
    const newEndDuration = startDuration + toDuration;
    const snappyEndDuration = round(newEndDuration, modulo);
    newDuration = snappyEndDuration - startDuration;
    debug("snappy after", {
      toDuration,
      newDuration,
      newEndDuration,
      snappyEndDuration,
    });
    this.setProp("duration", newDuration);
  }

  /**
   * Allow 0...∞
   */
  @computed get minDuration(): number {
    return Number.isInteger(this.list?.opts?.minDuration)
      ? Math.max(0, this.list?.opts?.minDuration)
      : 5;
  }

  @computed get totalDuration(): number {
    return this.duration + this.eventsDuration; //Math.max(this.eventsDuration, this.eventsDurationOverlap)
  }

  @computed get durationDone(): number {
    return this.done ? this.duration : this.subTasksList?.durationDone;
  }

  @computed get durationShouldBeDone(): number {
    return this.subTasksList?.durationShouldBeDone; // same value
  }

  @computed get height(): number {
    return this.plannerUtils.getHeightFromDuration(this.totalDuration);
  }

  /**
   * Completed percent from subtasks or task done
   */
  @computed get percentComplete(): number {
    const { durationDone, duration } = this;
    const percent = durationDone
      ? parseInt(String((durationDone / duration) * 100), 10)
      : 0;
    return percent;
  }

  @computed get isPercentCompleteOnTime(): boolean {
    return this.durationShouldBeDone <= this.durationDone;
  }

  @computed get parent(): ProjectItem | CategoryItem {
    return this.project || this.category;
  }

  @computed get dueDateFormatted(): string {
    const { dueDate } = this;
    return dueDate
      ? dueDate.getDate() +
          ":" +
          dueDate.getMonth() +
          ":" +
          dueDate.getFullYear()
      : "";
  }

  @computed get dueTime(): number {
    const { dueDate } = this;
    return dueDate ? dueDate.getHours() * 60 + dueDate.getMinutes() : 0;
  }

  @computed get dueTimeFormatted(): string {
    const { dueTime } = this;
    const hour = Math.floor(dueTime / 60);
    return hour + ":" + (dueTime - hour * 60);
  }

  @computed get durationFormatted(): string {
    const durationMins = this.plannerUtils.getDurationMins(this.duration);
    return this.plannerUtils.formatDuration(durationMins);
  }

  @computed get resizeDurationFormatted(): string {
    const durationMins = this.plannerUtils.getDurationMins(this.resizeDuration);
    return this.plannerUtils.formatDuration(durationMins);
  }

  @computed get startDuration(): number {
    if (!this.startTimespan) return null;
    return (
      this.startTimespan.getTaskStartDurationOfSameType(this) +
      this.startTimespan.startDuration
    );
  }

  @computed get endDuration(): number {
    if (!this.endTimespan) return null;
    return (
      this.endTimespan.getTaskVisibleDuration(this) +
      this.endTimespan.startDuration
    );
  }

  @computed get startDate(): Date {
    if (this.isSticky) {
      return this.getAttribute("startDate");
    }
    return this.startTimespan?.getTaskStartDate(this);
  }
  set startDate(startDate: Date) {
    if (this.isSticky) {
      this.setAttribute("startDate", startDate);
    }
  }

  @computed get endDate(): Date {
    return this.startDate && dateAddMins(this.startDate, this.duration)
  }

  @computed get startOfDayDate(): Date {
    return new Date(new Date(this.startDate).setHours(0, 0, 0, 0));
  }

  @computed get endOfDayDate(): Date {
    return new Date(this.startOfDayDate.getTime() + 86400000);
  }

  @computed get msSinceStartOfDay(): number {
    const { startOfDayDate, startDate } = this;
    return startDate.getTime() - startOfDayDate.getTime();
  }

  @computed get msSinceStartOfDayToEnd(): number {
    const { startOfDayDate, endDate } = this;
    return endDate.getTime() - startOfDayDate.getTime();
  }

  @computed get minutesSinceStartOfDay(): number {
    return millisecsToMins(this.msSinceStartOfDay);
  }

  @computed get minutesSinceStartOfDayToEnd(): number {
    return this.minutesSinceStartOfDay + this.duration;
  }

  @computed get offsetHeightFromStartOfDay(): number {
    return this.plannerUtils.getHeightFromDuration(this.minutesSinceStartOfDay);
  }

  @action setMinutesSinceStartOfDay(minsSinceStartOfDay: number) {
    const msSinceStartOfDay = minsToMillisecs(minsSinceStartOfDay);
    this.moveStartDate(
      new Date(this.startOfDayDate.getTime() + msSinceStartOfDay),
    );
  }

  @action moveStartDate(startDate: Date) {
    this.startDate = startDate;
  }

  @computed get onTask(): TaskItem {
    return this.actionPlanner.visibleItems.find((task: TaskItem) => {
      return task.startDate <= this.startDate && task.endDate > this.startDate;
    });
  }

  @computed get onStickyTask(): TaskItem {
    const { minutesSinceStartOfDay } = this;
    return this.actionPlanner.stickyTasks.find((task: TaskItem) => {
      return (
        minutesSinceStartOfDay > task.minutesSinceStartOfDay &&
        minutesSinceStartOfDay < task.minutesSinceStartOfDayToEnd
      );
    });
  }

  @computed get stackIndex(): number {
    const { onStickyTask, onTask } = this;
    const stackIndex = onStickyTask
      ? onStickyTask.stackIndex + 1
      : onTask
        ? 1
        : 0;
    debug("stackIndex", stackIndex);
    return stackIndex;
  }

  /**
   * Events total duration
   */
  @computed get eventsDuration(): number {
    return this.events.reduce(
      (duration, event) => duration + event.duration,
      0,
    );
  }

  @computed get isFocused(): boolean {
    const { focusedItem } = this.list;
    return focusedItem && focusedItem.item === this;
  }

  /**
   * Snapshot of current subtasks
   */
  @computed get flatSubTasks(): Array<TaskItem> {
    return this.subTasksList?.items || [];
  }

  @action setUpdateTokenUid(updateTokenUid: string) {
    this.updateTokenUid = updateTokenUid;
  }

  @computed get prevTaskInTimespan(): TaskItem {
    return this.startTimespan?.getPrevTaskOfSameType(this);
  }

  onObservable() {
    // @todo taskActions is deprecated
    // setTimeout(() => this.watchActions(), 500) // @todo fix to run after fromJSON()
    // @todo save() this
  }

  taskActions: TaskActions;
  watchActions() {
    this.taskActions = new TaskActions(this);
    this.taskActions.watchActions();
  }

  getSubTaskByIdString(id: string): TaskItem {
    return this.flatSubTasks.find(
      (subtask) => subtask.id.toString() === id.toString(),
    );
  }

  getSubTaskByUid(uid: string): TaskItem {
    return this.flatSubTasks.find((subtask) => subtask.uid === uid);
  }

  getTimespanByUid(uid: string): TimeSpanItem {
    return (
      this.list &&
      this.list.dayTimespans &&
      this.list.dayTimespans.getItemByUid(uid)
    );
  }

  getTimespanByType(type: string): TimeSpanItem {
    return (
      this.list &&
      this.list.dayTimespans &&
      this.list.dayTimespans.getItemByType(type)
    );
  }

  /**
   * Set the duration without receding minDuration
   */
  @action setDuration(duration: number) {
    this.setProps({ duration });
  }

  @action setTitle(title: string) {
    if (this.done) return;
    this.setProps({ title });
  }

  @action setEditing(editing: boolean) {
    if (this.done) return;
    this.setProps({ editing });
  }

  @action setProject(project: ProjectItem) {
    if (this.done) return;
    this.setProp("project", ProjectItem.fromProps(project));
  }

  @action setCategory(category: CategoryItem) {
    if (this.done) return;
    this.setProp("category", CategoryItem.fromProps(category));
  }

  @action setAssignedUser(user: UserI) {
    if (this.done) return;
    this.setProp("assignedUser", importUser().fromProps(user));
  }

  @action addMember(user: ModelPropsI) {
    if (this.done) return;
    this.memberList.addItem(user);
  }

  @action removeMember(user: UserI) {
    if (this.done) return;
    this.memberList.removeItem(user as ItemI);
  }

  @action toggleDone(doneDate: Date = new Date()) {
    this.done = !this.done;
    if (this.done) {
      this.doneDate = doneDate;
    }
  }

  /**
   * @deprecated 
   * @note replaced by Context specific to component to focus on
   */
  @action setFocusOnTitle() {
    throw new Error('TaskItem.setFocusOnTitle() is deprecated. use context api')
  }

  @action unTrash() {
    if (this.trashed) this.trashedDate = undefined;
    this.trashed = false;
  }

  @action setTrashed(trashed) {
    if (trashed) this.trashedDate = new Date();
    this.trashed = trashed;
  }

  @action trash() {
    this.setTrashed(true);
  }

  @action setFiles(files: Array<FileItem>) {
    debug("set files", files);
    if (this.done) return;
    this.fileList.setItems(files);
  }

  @action setActivities(items: Array<ActivityItem>) {
    if (this.done) return;
    this.activityList.setItems(items);
  }

  @action addActivity(item: ActivityItem) {
    if (this.done) return;
    this.activityList.addItem(item);
  }

  @action toggleOnPlanner() {
    if (this.done) return;
    this.onPlanner = !this.onPlanner;
  }

  saveWhenIdleTimeout: number;

  async saveThrottled(timeout = 500) {
    when(
      () => !this.saveState.isBusy,
      () => {
        clearTimeout(this.saveWhenIdleTimeout);
        this.saveWhenIdleTimeout = window.setTimeout(() => {
          this.save();
        }, timeout);
      },
    );
  }

  /**
   * Files need to be saved separately
   */
  async saveFiles() {
    return await this.fileList?.save()
  }

  /**
   * Saving a task details. 
   * @note files must be saved separately
   */
  async save(): Promise<TaskItem> {
    const json = await this.toJSON();
    debug("save", { json });
    return this.saveState
      .postJson("task/save", json)
      .then(({ data }) => this.fromJSON({ id: data.task.id }));
  }

  /**
   * Fetch the task details
   * @note does not fetch files content, only files metadata
   */
  async fetch(): Promise<TaskItem> {
    return this.saveState
      .get("task/fetch/" + this.id)
      .then(({ data }) => this.fromJSON(data));
  }

  /**
   * Create Date from string
   */
  parseDate(dateString: string) {
    const isValidDate = (d) => d instanceof Date && !isNaN(d.getTime());
    const date =
      dateString &&
      new Date(Date.parse(String(dateString)) || parseInt(dateString, 10));
    return isValidDate(date) ? date : null;
  }

  transformFromJSON(data: any) {
    const { projectId, categoryId, userId, assignedUserId, parentTaskId } =
      data;
    // @todo this should be handled by Model.fromJSON()
    if (projectId) {
      this.setProp("project", ProjectItem.fromId(projectId));
    }
    if (categoryId) {
      this.setProp("category", CategoryItem.fromId(categoryId));
    }
    if (userId) {
      this.setProp("user", importUser().fromId(userId));
    }
    if (assignedUserId) {
      this.setProp("assignedUser", importUser().fromId(assignedUserId));
    }
    if (parentTaskId) {
      this.setProp("parentTask", TaskItem.fromId(parentTaskId));
    }
    const transforms = {
      duration: (duration) => parseFloat(duration),
      onPlanner: (onPlanner) => !!onPlanner,
      doneDate: (doneDate) => this.parseDate(doneDate),
      earmarkedDate: (earmarkedDate) => this.parseDate(earmarkedDate),
      dueDate: (dueDate) => this.parseDate(dueDate),
      createDate: (createDate) => this.parseDate(createDate),
      trashed: (trashed) => !!trashed,
    };
    const noTransoforms = [
      "id",
      "uid",
      "title",
      "description",
      "updateTokenUid",
      "timespanType",
    ];
    const transform = ({ key, value }) => {
      if (key in transforms) {
        this.setProp(key, transforms[key].call(this, value));
      } else if (noTransoforms.includes(key)) {
        this.setProp(key, value);
      }
    };
    Object.entries(data).forEach(([key, value]) => {
      transform({ key, value });
    });
  }

  setLabelsFromTaskLabel(TaskLabel: { Label: object }[]) {
    const labels = TaskLabel.map((data) => {
      return stores.TaskLabel.fromJSON(data.Label);
    });
    debug("labels", this.title, { TaskLabel, labels });
    this.taskLabelList.setItems(labels);
  }

  fromJSON(data: any): TaskItem {
    debug("fromJSON", data);
    if (data) {
      this.transformFromJSON(data);
      data.TaskLabel && this.setLabelsFromTaskLabel(data.TaskLabel);
      // @todo deprecate this
      this.setFromJSONString('memberList', data.memberList)
      this.setFromJSONString('fileList', data.fileList)
      "done" in data && this.setProp("done", !!data.done); // last since done tasks will block setProp()
    }
    return this;
  }

  @action setFromJSONString(name: string, jsonStr: any) {
    try {
      const json = typeof jsonStr === "string" ? JSON.parse(jsonStr) : {};
      this.setFromJSON(name, json);
    } catch (error) {
      console.warn("TaskItem Error parsing JSON", { name, jsonStr, error });
    }
  }

  @action setFromJSON(name: string, json: any) {
    try {
      this[name]?.fromJSON(json);
    } catch (error) {
      console.warn("TaskItem Error parsing JSON", { name, json, error });
    }
  }

  async toJSON(): Promise<JsonAny> {
    const json = {
      id: this.id,
      uid: this.uid,
      timespanType: this.timespanType,
      title: this.title,
      description: this.description,
      duration: Math.max(parseFloat(String(this.duration)), this.minDuration),
      project: this.project?.toJSONRef(),
      projectId: this.project?.id,
      category: this.category?.toJSONRef(),
      categoryId: this.category?.id,
      assignedUser: this.assignedUser?.toJSONRef(),
      assignedUserId: this.assignedUser?.id,
      onPlanner: !!this.onPlanner,
      done: !!this.done,
      doneDate: this.doneDate?.toString(),
      dueDate: this.dueDate?.toString() || "",
      memberList: this.memberList?.toJSONRef(),
      trashed: !!this.trashed,
      fileList: await this.fileList?.toJSONRef(),
      // subTasksList: await this.subTasksList?.toJSON(), // @todo fix real tasks
      updateTokenUid: this.updateTokenUid,
      earmarkedDate: this.earmarkedDate?.toString(),
      labels: this.taskLabelList.items.map((label) => label.id),
      parentTaskId: this.parentTask?.id,
      // blockingList: this.blockingList.toJSONRef(),
      blocker: !!this.blockingList.items.length,
      createDate: this.createDate ? new Date(this.createDate) : null
    };
    debug("toJSON", json);
    return json;
  }

  async asyncToJSON(): Promise<JsonAny> {
    return await this.toJSON();
  }
}
