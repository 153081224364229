import React from 'react'
import './MainMenu.css'
import MainMenuItem from './MainMenuItem'
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { stores } from 'Stores';

class MainMenu extends React.Component {

  static propTypes = {
    store: PropTypes.instanceOf(stores.MainMenu)
  }
  
  render() {
    const { items } = this.props.store
    return (
      <nav className="main-menu">
        <ul>
          {
            items.map(item => (
              <MainMenuItem item={item} key={item.uid} />
            ))
          }
        </ul>
      </nav>
    )
  }
}

export default observer(MainMenu)