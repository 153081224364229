import React from 'react'
import './SubMenu.scss'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'theme/Button'
import { observer } from 'mobx-react'
import { MultiDaylannerPane, WeekPlannerPane } from 'Stores/MainPaneGroup/data/MainPaneItems'
import { models } from 'Stores'

export default observer(() => {
  const mainPaneGroup = getStore(models.MainPaneGroup)
  const addOrOpenPane = (pane) => {
    if (!pane) throw new Error('Pane must be defined')
    if (hasPane(pane) || !mainPaneGroup.addPaneOrTab(pane)) {
      window.open('/' + pane.slug, '_blank').focus()
    }
  }
  const onClickDay = () => addOrOpenPane(MultiDaylannerPane)
  const onClickWeek = () => addOrOpenPane(WeekPlannerPane)
  const hasPane = pane => {
    return mainPaneGroup.getItemByPaneType(pane)
  }
  const dayVariant = hasPane(MultiDaylannerPane) ? 'secondary' : 'outline-dark'
  const weekVariant = hasPane(WeekPlannerPane) ? 'secondary' : 'outline-dark'
  return (
    <ButtonGroup aria-label="Action Planner" toggle="true">
      <Button onClick={onClickDay} variant={dayVariant} size="xs">Day</Button>
      <Button onClick={onClickWeek} variant={weekVariant} size="xs">Week</Button>
    </ButtonGroup>
  )
})