import React from 'react'
import { observer } from 'mobx-react'
import ButtonViewDetail from '../../../../theme/List/ButtonViewDetail';
import { withRouter } from 'react-router-dom'
import { GoalHabitTitle } from './GoalHabitTitle'
import { computed } from 'mobx';
import { GoalDataSource } from 'Stores/Goal/GoalData';

class GoalHabitItem extends React.Component {

  onViewDetail = () => {
    const { habit, history } = this.props
    history.push('/habit/' + habit.id)
  }

  onChangeRollupData = event => {
    const { goal, habit } = this.props
    if (!goal.dataSourceId) goal.dataSourceId = []
    goal.dataSourceId = event.target.checked 
      ? [ ...goal.dataSourceId, habit.id.toString() ]
      : [ ...goal.dataSourceId ].filter(id => id !== habit.id.toString())
  }

  @computed get checked() {
    const { goal, habit } = this.props
    return (this.enabled && goal.dataSourceId)
      ? goal.dataSourceId.includes(habit.id.toString())
      : false
  }

  @computed get enabled() {
    return this.props.goal.dataSource === GoalDataSource.habits
  }

  render() {
    const { habit } = this.props
    return (
      <tr>
        <td>
          <ButtonViewDetail onClick={this.onViewDetail} />
        </td>
        <td>
          <GoalHabitTitle habit={habit} />
        </td>
        <td>
          <input
            disabled={!this.enabled}
            type="checkbox"
            className="checkbox-rollupData"
            checked={this.checked}
            onChange={this.onChangeRollupData}
          />
        </td>
        <td>{habit.type}</td>
        <td>{habit.value}</td>
        <td>{habit.plan}</td>
      </tr>
    )
  }
}

export default withRouter(observer(GoalHabitItem))
