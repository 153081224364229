import React from 'react'
import Project from './Project'
import { Route, Switch } from 'react-router-dom'

const ProjectRoutes = (props) => (
  <Switch>
    <Route path="/project/tab/:projectTab" component={Project} />
    <Route path="/:page?" render={() => <Project {...props} />} />
  </Switch>
)

export default ProjectRoutes