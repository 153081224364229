export const MultiDaylannerPane = {
  name: 'Action Planner',
  slug: 'day-planner',
  icon: 'action_planner',
  colSpan: 2,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const WeekPlannerPane = {
  name: 'Week Planner',
  slug: 'week-planner',
  icon: 'action_planner',
  colSpan: 2,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const TaskDetailPane = {
  name: 'Task Detail',
  slug: 'task-detail',
  icon: 'task',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const CalendarMonthPane = {
  name: 'Calendar - Month',
  slug: 'calendar-month',
  icon: 'calendar',
  colSpan: 3,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const CalendarWeekPane = {
  name: 'Calendar - Week',
  slug: 'calendar-week',
  icon: 'calendar',
  colSpan: 2,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const AccountPane = {
  name: 'Account',
  slug: 'account',
  icon: 'account',
  colSpan: 2,
  openIn: ['modal-with-tabs'],
  resizable: false,
}

export const ProjectPane = {
  name: 'Project',
  slug: 'project',
  icon: 'project',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const GoalPane = {
  name: 'Goal',
  slug: 'goal',
  icon: 'goal',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const IntegrationsPane = {
  name: 'Integrations',
  slug: 'integrations',
  icon: 'integrations',
}

export const EventDetailPane = {
  name: 'Event Detail',
  slug: 'event-detail',
  icon: 'calendar',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const TaskListPane = {
  name: 'Task List',
  slug: 'tasks',
  icon: 'task',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const HabitListPane = {
  name: 'Habit List',
  slug: 'habits',
  icon: 'recurring',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const HabitPane = {
  name: 'Habit',
  slug: 'habit',
  icon: 'recurring',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const ProjectListPane = {
  name: 'Project List',
  slug: 'projects',
  icon: 'project',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const GoalListPane = {
  name: 'Goal List',
  slug: 'goals',
  icon: 'goal',
  colSpan: 1,
  openIn: ['new-pane', 'new-tab'],
}

export const MetricsDayPane = {
  name: 'Metrics',
  slug: 'metrics-day',
  icon: 'metrics',
}

export const MetricsWeekPane = {
  name: 'Metrics - Week',
  slug: 'metrics-week',
  icon: 'metrics',
}

export const MetricsProjectPane = {
  name: 'Metrics - Project',
  slug: 'metrics-project',
  icon: 'metrics',
}

export const InboxPane = {
  name: 'Inbox',
  slug: 'inbox',
  icon: 'inbox',
  colSpan: 2,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const DashboardPane = {
  name: 'Dashboard',
  slug: 'dashboard',
  icon: 'dashboard',
  colSpan: 2,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const DashboardViewingPane = {
  name: 'Dashboard Viewing',
  slug: 'dashboard-viewing',
  icon: 'dashboard',
  colSpan: 3,
  openIn: ['new-browser-tab'],
  resizable: false,
}

export const items = [ MultiDaylannerPane, InboxPane ]
export const bucket = [
  MultiDaylannerPane,
  InboxPane,
  DashboardPane,
  WeekPlannerPane,
  TaskDetailPane,
  CalendarMonthPane,
  CalendarWeekPane,
  ProjectPane,
  GoalPane,
  EventDetailPane,
  AccountPane,
  TaskListPane,
  HabitListPane,
  ProjectListPane,
  GoalListPane,
  MetricsDayPane,
	HabitPane
]

let bucketList
export const getBucketList = () => {
  const { importMainPaneGroup } = require('Stores/MainPaneGroup')
  if (!bucketList) {
    bucketList = importMainPaneGroup().fromItems(bucket)
  }
  return bucketList
}

export default items