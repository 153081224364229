import { observable } from 'mobx'
import { Item } from 'Stores/Lists'

/**
 * Activity Type
 */
export default class ActivityType extends Item {

  /**
   * @property {string}
   */
  @observable type = null

  /**
   * @property {string}
   */
  @observable action = null

  /**
   * @property {string}
   */
  @observable title = null

  /**
   * @property {Date}
   */
  @observable createDate = new Date()

}
