export const displaySettingsFields = [
  {
    name: 'language',
    label: 'Language',
    value: null,
    errors: [],
    items: [
      { label: 'English', value: 'en' }
    ]
  },
  {
    name: 'plannerPane',
    label: 'Action Planner Pane',
    value: null,
    errors: [],
    items: [
      { label: 'Left (default)', value: 'left' },
      { label: 'Right', value: 'right' },
    ]
  },
  {
    name: 'clock',
    label: 'Clock',
    value: null,
    errors: [],
    items: [
      { label: '12-hour (default)', value: '12' },
      { label: '24-hour', value: '24' },
    ]
  },
  {
    name: 'duration',
    label: 'Duration',
    value: null,
    errors: [],
    items: [
      { label: 'd:hh:mm (default)', value: 'd:hh:mm' }
    ]
  },
  {
    name: 'workHours',
    label: 'Normal Work Hours',
    value: null,
    errors: [],
    items: [
      { label: '8 hours (default)', value: '8hours' }
    ]
  },
  {
    name: 'firstWeekDay',
    label: 'First Day of the Week',
    value: null,
    errors: [],
    items: [
      { label: 'Monday', value: 'monday' },
      { label: 'Sunday', value: 'sunday' },
    ]
  }
]