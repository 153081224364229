import { observable, override } from "mobx"
import OrderedList from "Stores/Lists/OrderedList"
import TaskItem from "Stores/Task"
import { PlannerFlow } from "./PlannerFlow"


const debug = require('debug')('treks:store:task:list')

export class TaskListFlow extends OrderedList {

  get ModelType(): typeof TaskItem {
    return TaskItem
  }

  @override get items(): TaskItem[] {
    return super.items
  }

  @observable plannerFlow: PlannerFlow = null

  @override async fetch(): Promise<TaskListFlow> {
    const timestamp = Date.parse(this.plannerFlow.startDate.toString())
    this.fetchState.get('planner/tasks?date=' + timestamp)
      .then(resp => {
        this.setItemsFromJSON(resp.data)
        return resp
      })
    return this
  }

}