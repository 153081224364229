import { action, observable, override } from 'mobx';
import Model from 'Stores/Model'
import { PFAScoreWeightsModel } from './PFASettingsScoreWeightsModel';
import { TimespansToTrack } from './PFASettingsTimespansToTrackModel';

export class RangeModel extends Model {

  @observable range: number[] = [0, 100]

  @action setRange(range) {
    this.range.splice(0, this.range.length, ...range)
  }
}

export class ThrashAcceptableRangeModel extends RangeModel {
  @override range: number[] = [65, 95]
}

export class HabitPoints extends Model {
  @observable earnedAfter: string = 'each'
  @observable extraPoints: number = 5
  @observable extraPointsLimit: number = 5
}

export class KindDeedsPoints extends Model {
  @observable extraPoints: number = 5
  @observable extraPointsLimit: number = 15
}

export class DaysToTrackOptions extends Model {

  static nonMultiOpts = ['everyday', 'weekdays']

  static multiOpts = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday'
  ]

  @observable value: typeof DaysToTrackOptions.multiOpts | keyof typeof DaysToTrackOptions.nonMultiOpts = []
}

export default class PFASettingsModel extends Model {

  @observable timespansToTrack: TimespansToTrack = TimespansToTrack.create()
  
  @observable daysToTrack: DaysToTrackOptions["value"] = []

  @observable scoreGoal: number = 75

  @observable scoreWeights: PFAScoreWeightsModel = PFAScoreWeightsModel.create()

  @observable targetProductivity: number = 75

  @observable thrashAcceptableRange: RangeModel = ThrashAcceptableRangeModel.create()

  @observable habitPoints: HabitPoints = HabitPoints.create()

  @observable kindDeedsPoints: KindDeedsPoints = KindDeedsPoints.create()

}