import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Account from '../Containers/Account'
import Admin from '../Containers/Admin'
import MainPaneRoute from './MainPaneRoute'

const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/account" component={Account} />
      <Route path="/:page/detail/:id?" component={MainPaneRoute} />
      <Route path="/:page?/:id?" component={MainPaneRoute} />
    </Switch>
  )
}

export default AppRoutes