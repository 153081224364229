import React from 'react'
import { observer } from 'mobx-react';
import PropTypes from 'prop-types'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow';
import { dateAddMins } from 'Stores/ActionPlanner/ActionPlannerUtils';
import { computed } from 'mobx';
import moment from 'moment';
import Pressable from 'theme/Pressable';
import { getDayPlanner } from 'Stores/ActionPlanner/Planner/DayPlanner';

const debug = require('debug')('treks:planner:DaySplitLabel')

export default observer(class DayPlannerDaySplitLabel extends React.Component {

  static defaultProps = {
    dateFormat: 'h:mm A',
    dayPlanner: getDayPlanner()
  }

  static propTypes = {
    plannerFlow: PropTypes.objectOf(() => PlannerFlow).isRequired,
    dateFormat: PropTypes.string
  }

  midNights = computed(() => {
    const { plannerFlow } = this.props
    const dayDuration = 60 * 24
    const midNights = []
    const midNightCount = plannerFlow.endDuration / dayDuration
    for(let i = 1; i < midNightCount; i++) {
      const duration = i * dayDuration
      midNights.push({
        duration,
        date: dateAddMins(plannerFlow.startDate, duration)
      })
    }
    return midNights
  })

  get paneEl() {
    return document.querySelector('.main-pane-component:has(.action-planner.day-view)')
  }

  onPress = midnight => {
    const { utils } = this.props.dayPlanner
    const topMargin = 20 // planner header height
    const height = utils.getHeightFromDuration(midnight.duration)
    this.paneEl.scrollTo({ 
      top: height + topMargin,
      behavior: 'smooth'
    })
  }

  render() {
    const { plannerUtils, dateFormat } = this.props
    const midnights = this.midNights.get()
    
    return (
      <div className='planner-midnights'>
        {
          midnights.map(midnight => {
            const top = plannerUtils.getHeightFromDuration(midnight.duration)
            return (
              <div
                key={midnight.duration}
                className='midnight-label'
                style={{ top }}
              >
                <Pressable onClick={() => this.onPress(midnight)}>
                  {
                    moment(midnight.date).format(dateFormat)
                  }
                </Pressable>
              </div>
            )
          })
        }
      </div>
    )
  }
})
