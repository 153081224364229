import DataList from 'Stores/Data/DataList'
import { GoalItem } from '..'
import DataItem from 'Stores/Data/DataItem'
import { hasOne } from 'Relationships/RelationshipDecorators'

export default class GoalDataList extends DataList {

  get ModelType() {
    return DataItem
  }

	@hasOne(() => GoalItem, (goal: GoalItem) => goal.data)
	goal: GoalItem
	

  createItemProps(props:any) {
    return { goal: this.goal, ...props }
  }

}
