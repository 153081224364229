import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:habit:budget')

class HabitBudget extends React.Component {
  inputRef = null

  constructor(props) {
    super(props)
    const { habit } = props
    debug('component created', habit)
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    debug('title changed', event)
    this.props.habit.periodUnits = event.target.value
    this.props.habit.save()
  }

  render() {
    const { habit } = this.props
    debug('render', habit)
    return (
      <input 
        ref={this.onInputRef}
        className={"input-borderless input-budget"} 
        value={ habit.periodUnits } 
        onChange={this.onChange}
      />
    )
  }
}

export default observer(HabitBudget)