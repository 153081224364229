import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import TaskItem from 'Stores/Task/TaskItem';
import { uid } from 'Stores/utils';
import Tooltip from 'theme/Tooltip';
import List from 'Stores/Lists';
import { getStore, models } from 'Stores';
import Model from 'Stores/Model';
import { getMQTTState } from 'uiState/MQTTState';
import { IS_DEV } from 'env';

const debug = require('debug')('treks:task:title')

class SubTaskTitle extends React.Component {

  static propTypes = {
    parent: PropTypes.instanceOf(Model),
    list: PropTypes.instanceOf(List).isRequired,
    item: PropTypes.instanceOf(TaskItem).isRequired,
    disabled: PropTypes.bool,
    listenUpdates: PropTypes.bool,
    emptyItemProps: PropTypes.object
  }

  static defaultProps = {
    disabled: false,
    get sessionStore() {
      return getStore(models.Session)
    },
    busyWaitMs: 5000,
    busyMsg: 'Another user is typing...',
    listenUpdates: false,
    emptyItemProps: {}
  }

  uiState = observable({
    isBusy: null
  })
  isBusyTimer = null

  inputRef = null

  disableMqtt = IS_DEV ? !localStorage.enableMqtt : localStorage.disableMqtt

  mqttSyncClient = null

  componentDidUpdate(prevProps) {
    const { item, store } = this.props
    if (prevProps.item !== item) {
      debug('updated component', prevProps.item.title, item.title)
    }
    if (store?.isFocusedSubTask(item)) {
      this.focusOnInput()
    }
  }

  componentDidMount() {
    const { item, listenUpdates, store } = this.props
    debug('componentDidMount', { item, store, listenUpdates })
    
    if (store?.isFocusedSubTask(item)) {
      this.focusOnInput()
    }

    // @todo optimize
    if (!this.disableMqtt) {
      debug('mqtt is enabled')
      if (listenUpdates) {
        debug('listen mqtt updates', item.uid, item.title)
        this.listenForMQTTUpdates()
      }
    } else {
      debug('mqtt is disabled')
    }
    
  }

  // @todo fix to work
  listenForMQTTUpdates() {
    this.mqttSyncClient = getMQTTState().getClient()
    // this.mqttSyncClient.on('update', this.onMqttTaskUpdate)
    // this.mqttSyncClient.on('create', this.onMqttTaskUpdate)
  }

  async onMqttTaskUpdate(task) {
    const { item, sessionStore } = this.props
    const { uid, updateDeviceUid } = task
    debug('mqtt update', uid, item.title, task.title)
    if (item.uid === uid && updateDeviceUid !== sessionStore.deviceUid) {
      debug('another device update task', { item, sessionStore, task })
      this.uiState.isBusy = true 
      clearTimeout(this.isBusyTimer)
      this.isBusyTimer = setTimeout(() => {
        this.uiState.isBusy = false
      }, busyWaitMs)
    }
  }

  componentWillUnmount() {
    // this.mqttSyncClient?.off('update', this.onMqttTaskUpdate)
    // this.mqttSyncClient?.off('create', this.onMqttTaskUpdate)
  }

  focusOnInput(timeout = 1000) {
    setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus()
      } else if (timeout > 0) {
        this.focusOnInput(timeout - 50)
      }
    }, 50)
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    const { item, sessionStore } = this.props
    debug('title changed', event)
    item.setUpdateTokenUid(sessionStore.tokenUid)
    item.setTitle(event.target.value)
    item.willSave()
  }

  /**
   * @todo move to TaskItem
   */
  onKeyDown = event => {
    const { item, parent } = this.props
    const { value } = event.target
    const isFlatList = !(event.metaKey || event.ctrlKey)
    debug('keyDown', item, event.nativeEvent)
    event.stopPropagation() // so shift works
    if (event.key === 'Enter') {
      if (value) {
        if (!isFlatList) {
          this.addEmptyList({ isFlatList })
        } else {
          this.addEmptyItem()
        }
      }
    }
    if (event.key === 'Backspace' && !item.title && !this.props.isPlannerTask) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
    // backspace + (CMD key or CTRL key)
    if (event.key === 'Backspace' && (event.metaKey || event.ctrlKey)) {
      this.focusOnPrevOrNextItemTitle()
      this.removeItem()
    }
    if (parent && event.key === ':' && this.isCaretAtEndOfInput(event.target)) {
      event.preventDefault()
      const { title } = item
      item.setTitle('')
      item.save()
      this.addEmptyList({ title })
    }
  }

  isCaretAtEndOfInput(input) {
    return this.getCaretPosition(input) === input.value.length
  }

  getCaretPosition(input) {
    if (document.selection) {
      input.focus()
      var oSel = document.selection.createRange()
      oSel.moveStart('character', -input.value.length)
      return oSel.text.length;
    }
    else if (input.selectionStart || input.selectionStart === '0') {
      return input.selectionStart
    }
      
  }

  addEmptyList(props) {
    const { parent, store } = this.props
    const emptyList = parent.addItem(this.createEmptyList(props))
    if (!props.isFlatList) {
      store.setFocusedSubTaskList(emptyList)
    } else {
      const item = emptyList.addItem(this.createEmptyItem())
      store.setFocusedSubTask(item)
    }
  }

  createEmptyList(props) {
    const { item } = this.props
    const list = {
      id: uid(),
      title: '',
      items: [],
      createdFromTask: item,
      ...props
    }
    debug('createEmptyList', list)
    return list
  }

  addEmptyItem() {
    const { list, emptyItemProps, store, item } = this.props
    const task = list.addEmptyItemAfterTask(item, emptyItemProps)
    debug('add task', { task, list, task_list: task.list })
    const index = list.getItemIndex(task)
    debug('addEmptyItem', { task, index })
    task.save()
    store.setFocusedSubTask(task)
    list.saveOrder()
  }

  removeItem() {
    this.props.item.trash()
    this.props.item.save()
  }

  focusOnPrevOrNextItemTitle() {
    const { list, item, store } = this.props
    const targetItem = list.getPrevItem(item) || list.getNextItem(item)
    targetItem ? store.setFocusedSubTask(item) : store.setFocusedSubTaskList(list)
  }

  onFocus = event => {
    const { item, list, onFocus } = this.props
    onFocus && onFocus(event, item, list)
  }

  onBlur = event => {
    const { item, onBlur } = this.props
    onBlur && onBlur(event, item)
  }

  render() {
    const { item, disabled, busyMsg } = this.props
    const { isBusy } = this.uiState
    debug('render', item)
    const tooltipStyle = { 
      left: 'calc( 50% - 100px )',
      bottom: -20
    }
    return (
      <>
        <input 
          ref={this.onInputRef}
          className={"title title-input input-borderless " + (item.done ? 'done' : '')} 
          value={ item.title || '' } 
          onChange={this.onChange} 
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled || isBusy ? 'disabled' : ''}
        />
        {
          isBusy
            ? (
              <Tooltip placement="bottom" tooltipStyle={tooltipStyle}>
                {busyMsg}
              </Tooltip>
            ) : null
        }
      </>
    )
  }
}

export default observer(SubTaskTitle)