import React from 'react'
import 'theme/style/settings.css'
import './AccountRegister.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import { withRouter } from 'react-router-dom'

const headerText = 'Registration Complete!'
const infoText = 'Your account is now registered and you are subscribed to the 7 days free trial.'
const ContinueRegistrationLabel = 'Go to your Profile'

export default withRouter(class AccountRegisterComplete extends React.Component {

  onClickContinue = () => {
    this.props.history.push('/account/settings/profile')
  }

  render() {
    return (
      <Container className="account-register settings">
        <Row>
          <Col>
            <header className="header">
              {headerText}
            </header>
            <p className="info">
              {infoText}
            </p>
            <Button 
              onClick={this.onClickContinue} 
              variant="primary"
            >
              {ContinueRegistrationLabel}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
})