import React from 'react'
import { observer } from 'mobx-react'
import ActionPlannerTimespanTasks from '../ActionPlannerTimespanTasks'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import TimespanContextMenu from './TimespanContextMenu'
import CreateTimespanMenu from './CreateTimespanMenu'

const debug = require('debug')('treks:planner:timespan')

class ActionPlannerTimespan extends React.Component {

  timespanRef = null

  uiState = ArbitraryModel.fromProps({
    showContextMenu: false,
    contextMenuPos: { left: 0, top: 0 },
    isCreating: false
  })

  componentDidMount() {
    const { timespan } = this.props
    if (this.uiState.focus) {
      this.uiState.setState({ focus: false })
      window.setTimeout(() => this.focusOnTimespan())
    }
  }

  focusOnTimespan = () => {
    this.timespanRef && this.timespanRef.scrollIntoView({ smooth: true })
  }

  onShowContextMenu = (event) => {
    const { timespan, actionPlannerStore } = this.props
    debug('onShowContextMenu', { timespan })
    actionPlannerStore.setFocusedTimespan(timespan)
    if (localStorage.noContextMenu) return // disable in dev
    event.preventDefault()
    event.stopPropagation()
    const { nativeEvent } = event
    const { clientX, clientY } = nativeEvent
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.addEventListener('scroll', this.handleScroll)
    this.uiState.setState({ 
      showContextMenu: true, 
      contextMenuPos: { left: clientX, top: clientY }
    })
    debug('showing context menu on task', { timespan, nativeEvent })
  }

  onHideContextMenu = () => {
    const plannerBody = document.querySelector('.action-planner-body')
    plannerBody && plannerBody.removeEventListener('scroll', this.handleScroll)
    this.uiState.setState({
      showContextMenu: false,
      contextMenuPos: null
    })
  }

  onCreateTimespan = () => {
    const days = timespanPiece.timespans.map(ts => ts.list.day)
    days.map(day => day.save())
  }

  onDeleteTimespan = () => {
    const days = timespanPiece.timespans.map(ts => ts.list.day)
    days.map(day => day.save())
  }

  handleScroll = () => {
    this.onHideContextMenu()
  }

  onHideCreateMenu = () => {
    const { timespan } = this.props
    const { deleteIfNotCompleted } = this.uiState
    this.uiState.setState({ isCreating: false })
    if (deleteIfNotCompleted && timespan.type === 'empty') {
      timespan.list.removeItem(timespan)
      timespan.list.save()
    }
  }
  
  render() {
    const { 
      timespan, timespanPiece, plannerFlow, actionPlannerStore, plannerUtils, order, onOrderChange, 
      onTaskDurationWillChange, onTaskDurationChange, onTaskDurationUpdate, 
      onTaskPress, onContextMenu, isResizing, focusedTimespan, showTimespanColor
    } = this.props
    debug('render', { timespanPiece, timespan, type: timespan.type, id: timespan.id, isResizing, focusedTimespan })
    const { showContextMenu, contextMenuPos, isCreating } = this.uiState
    const colorStyle = { background: timespan.color }
    return (
      <div 
        ref={ref => this.timespanRef = ref}
        className={'action-planner-timespan timespan-' + timespan.type} 
        onContextMenu={this.onShowContextMenu}
        style={showTimespanColor ? colorStyle : {}}
      >
        {
          timespan.type !== 'sleep' && (
            <ActionPlannerTimespanTasks
              timespan={timespan}
              timespanPiece={timespanPiece}
              plannerFlow={plannerFlow}
              plannerUtils={plannerUtils}
              actionPlannerStore={actionPlannerStore}
              order={order}
              onOrderChange={onOrderChange}
              onTaskDurationWillChange={onTaskDurationWillChange}
              onTaskDurationChange={onTaskDurationChange}
              onTaskDurationUpdate={onTaskDurationUpdate}
              onTaskPress={onTaskPress}
              onContextMenu={onContextMenu}
            />
          )
        }
        {
          showContextMenu && (
            <TimespanContextMenu
              contextMenuPos={contextMenuPos}
              plannerFlow={plannerFlow}
              timespan={timespan}
              timespanPiece={timespanPiece}
              onHideContextMenu={this.onHideContextMenu}
              onCreateTimespan={this.onCreateTimespan}
              onDeleteTimespan={this.onDeleteTimespan}
            />
          )
        }
        {
          isCreating && (
            <CreateTimespanMenu
              onHide={this.onHideCreateMenu}
              timespan={timespan}
              dayList={actionPlannerStore.dayList}
            />
          )
        }
      </div>
    )
  }
}

export default observer(ActionPlannerTimespan)
