import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import Icon from '../../../theme/Icon'
import moment from 'moment'
import Pressable from 'theme/Pressable'
import ActionPlanner from 'Stores/ActionPlanner'
import { ActionPlannerList } from 'Stores/ActionPlanner/ActionPlannerList'
import { PFAScoreHeader } from './PFAHeader'

const debug = require('debug')('treks:planner:header')

export default withRouter(observer(class ActionPlannerHeader extends React.Component {

  whenLoginDisposer = null

  static propTypes = {
    actionPlannerStore: PropTypes.objectOf(() => ActionPlanner),
    actionPlannerList: PropTypes.objectOf(() => ActionPlannerList),
    showPFA: PropTypes.bool,
    updateUrl: PropTypes.bool
  }

  static defaultProps = {
    updateUrl: false
  }

  setFocusedDate(date) {
    const { actionPlannerStore, updateUrl } = this.props
    actionPlannerStore.setFocusedDate(date)
    if (updateUrl) {
      this.props.history.push('/planner/' + actionPlannerStore.focusedDate.getTime())
    }
  }

  onClickPrevDay = () => {
    this.setFocusedDate(moment(this.props.actionPlannerStore.focusedDate).subtract(1, 'day'))
  }

  onClickNextDay = () => {
    this.setFocusedDate(moment(this.props.actionPlannerStore.focusedDate).add(1, 'day'))
  }

  render() {
    const { actionPlannerStore, isFloating, showPFA } = this.props
    const { focusedDate, opts } = actionPlannerStore
    const date = moment(focusedDate)
    const title = date.format(opts.dateFormat)
    debug('render', { title, focusedDate })
    return (
      <header className={'action-planner-header ' + (isFloating ? 'float' : 'inline')}>
        <div className='header-item'>
          { 
            showPFA ? <PFAScoreHeader /> : null
          }
        </div>
        <div className='header-item'>
          <nav className={'action-planner-header-nav'}>
            <Pressable onClick={this.onClickPrevDay}>
              <Icon set="fa" icon="chevron-left" size="xs" color={'#808284'} />
            </Pressable>
            <h2 className="action-planner-title">{title}</h2>
            <Pressable onClick={this.onClickNextDay}>
              <Icon set="fa" icon="chevron-right" size="xs" color={'#808284'} />
            </Pressable>
          </nav>
        </div>
        <div className='header-item'></div>
      </header>
    )
  }

}))
