import React from 'react'
import { observer } from 'mobx-react'
import ModalStore from 'Stores/Screen/Modal'
import Modal from 'react-bootstrap/Modal'
import { AccountReigsterRoutes } from './AccountRegisterRoutes'
import stores, { getStore } from 'Stores'

class AccountReigsterModal extends React.Component {

  modalStore = ModalStore.fromProps({ show: true })
  accountRegisterStore = getStore(stores.AccountRegister)

  static defaultProps = {
    // do not allow closing
    canClose: false
  }

  componentDidMount() {
    this.setAccountRegisterStoreFromUrlParams()
  }

  setAccountRegisterStoreFromUrlParams() {
    if (this.hasUrlParams()) {
      const params = this.getUrlParamsObject()
      this.accountRegisterStore.setProps(params)
    }
  }

  hasUrlParams() {
    const params = this.getUrlParams()
    return [...params].length > 0
  }

  getUrlParams() {
    return new URLSearchParams(window.location.search);
  }

  getUrlParamsObject() {
    const params = this.getUrlParams()
    return [...params.entries()].reduce((next, entry) => {
      return { ...next, [entry[0]]: entry[1] }
    }, {})
  }

  handleClose = () => {
    this.modalStore.setProps({ show: false })
  }

  render() {
    const { show} = this.modalStore
    return (
      <Modal 
          show={this.props.canClose ? show : true} 
          onHide={this.handleClose}
          size="lg"
          centered
          dialogClassName="settings-modal modal-size-80"
        >
          <Modal.Body>
            <AccountReigsterRoutes />
          </Modal.Body>
        </Modal>
    )
  }
}

export default observer(AccountReigsterModal)