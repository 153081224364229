import { observable, action, computed, override } from 'mobx'
import TimeSpanItem from './TimeSpanItem'
import OrderedList from 'Stores/Lists/OrderedList';
import { uid } from '../../utils';
import ActionPlanner from 'Stores/ActionPlanner';
import { getAppStore } from 'Stores/App/AppStore';
import DayList, { TimeSpanBucketList } from 'Stores/ActionPlanner/TimeSpan';
import TaskItem from 'Stores/Task';
import { isSameDay, msPerDay, toDayIndex, toUTC } from '../ActionPlannerUtils';
import { CalendarEvent } from 'Stores/Calendar';

const debug = require('debug')('treks:store:timespan:list')

/**
 * A Day or List of timespans for any period
 */
export default class TimeSpanList extends OrderedList {

  get ModelType() {
    return TimeSpanItem
  }

  @observable list: DayList = null

  @override get items(): TimeSpanItem[] {
    return super.items
  }

  @override get orderedItems(): TimeSpanItem[] {
    return super.orderedItems
  }

  @observable itemTypes: string[] = ['personal', 'work', 'empty', 'sleep']

  @observable nonFillableTypes: string[] = ['empty', 'sleep']

  @observable fillableTypes: string[] = ['personal', 'work']

  @observable title = ''

  /**
   * @property {Number} (Sun=0 ... Sat=6)
   */
   @observable dayIndex = null

   /**
    * @property {String} (Sun ... Mon)
    */
   @observable dayId = null

   @observable dayList: DayList = null

  @computed get actionPlanner(): ActionPlanner {
    return this.dayList?.actionPlanner
  }

  @computed get todayDate(): Date {
    return this.actionPlanner?.startOfTodayDate
  }

  @computed get todayIndex(): number {
    return toDayIndex(this.todayDate)
  }

  @computed get focusedDayIndex(): number {
    return this.actionPlanner?.focusedDayIndex
  }

  @computed get focusedDayDate(): Date {
    return this.actionPlanner?.focusedDate
  }

  @computed get dayDate(): Date {
    const dayDate = new Date(toUTC(this.focusedDayDate)?.getTime() + (msPerDay * (this.dayIndex - this.focusedDayIndex)))
    return new Date(dayDate.setHours(0, 0, 0, 0)) // ensure start of day in case msPerDay is wrong
  }

  @computed get isDayInPast(): boolean {
    return this.dayDate.getTime() < this.todayDate.getTime()
  }

  @computed get isDayInPresent(): boolean {
    return this.dayDate.getTime() === this.todayDate.getTime()
  }

  @computed get isDayInFuture(): boolean {
    return this.dayDate.getTime() > this.todayDate.getTime()
  }

  @computed get pastDays(): TimeSpanList[] {
    return this.list?.items.filter(day => day.dayDate < this.todayDate)
  }

  @computed get presentDays(): TimeSpanList[] {
    return this.list?.items.filter(day => day.dayDate >= this.todayDate)
  }

  @computed get prevDays(): TimeSpanList[] {
    return this.list?.items.filter(day => day.dayDate < this.dayDate)
  }

  @computed get prevDaysPresent(): TimeSpanList[] {
    return this.list?.items.filter(day => {
      return day.dayDate < this.dayDate && day.dayDate >= this.todayDate
    })
  }

  @computed get prevDaysPast(): TimeSpanList[] {
    return this.list?.items.filter(day => {
      return day.dayDate < this.dayDate && day.dayDate < this.todayDate
    })
  }

  @computed get nextDay(): TimeSpanList {
    return this.list?.items.length > this.dayIndex 
      && this.list?.items[this.dayIndex + 1]
  }

  @computed get prevDay(): TimeSpanList {
    return this.dayIndex > 0 && this.list?.items[this.dayIndex - 1]
  }

  // -- day specific tasks

  @computed get tasksOrdered(): TaskItem[] {
    return this.actionPlanner?.visibleItemsByOrder || []
  }

  @computed get visibleTasks(): TaskItem[] {
    if (this.isDayInPast) return this.visibleTasksWithinDayInPast
    return this.tasksOrdered
  }

  @computed get visibleTasksWithinDayInPast(): TaskItem[] {
    const done = this.doneTasks
    const earmarked = this.tasksOrdered.filter(task => task.isEarmarked && isSameDay(task.earmarkedDate, this.dayDate))
    return [ ...done, ...earmarked ]
  }

  @computed get itemsDue(): (CalendarEvent|TaskItem)[] {
    const { dueTasks, dueEvents } = this
    return [...dueTasks, ...dueEvents]
  }

  @computed get dueTasks(): TaskItem[] {
    return this.actionPlanner?.items.filter(({ dueDate, onPlanner, done }) => {
      return dueDate && isSameDay(dueDate, this.dayDate)  && !onPlanner && !done
    })
  }

  @computed get doneTasks(): TaskItem[] {
    return this.actionPlanner?.doneTasks.filter(task => {
      return task.doneDate && isSameDay(task.doneDate, this.dayDate)
    })
  }

  @computed get dueEvents(): CalendarEvent[] {
    return this.actionPlanner.eventList.items.filter(event => {
      return event.allDayEvent && isSameDay(event.startDate, this.dayDate)
    })
  }

  // -- end day specific tasks

  /**
  * @property {int} Maximum duration (default 1 day)
  */
  @observable maxTotalDuration = 24 * 60

  @computed get totalDuration() {
    return (this.items || []).map(({ duration }) => duration)
      .reduce((total, duration) => total + duration, 0)
  }

  @computed get emptyItems(): TimeSpanItem[] {
    return this.items.filter(({ type }) => type === 'empty')
  }

  @computed get fillableItems(): TimeSpanItem[] {
    return this.items
      .filter(({ type }) => this.fillableTypes.includes(type))
  }

  @computed get nonFillableItems(): TimeSpanItem[] {
    return this.items
      .filter(({ type }) => this.nonFillableTypes.includes(type))
  }

  @computed get totalDurationFillable(): number {
    return this.fillableItems
      .reduce((total, { duration }) => total + duration, 0)
  }

  @computed get totalDurationNonFillable(): number {
    return this.nonFillableItems
      .reduce((total, { duration }) => total + duration, 0)
  }

  /**
   * @property {Number} Morning Non-Sleep start minute
   */
  @computed get wakeMinute(): number {
    return this.items.reduce((sum, { duration, type }) => sum + (type === 'sleep' ? duration : 0), 0)
  }

  /**
   * @property {Number} Night Sleep start minute
   */
  @computed get sleepMinute(): number {
    return this.items.reduce((sum, { duration, type }) => sum - (type === 'sleep' ? duration : 0), 60*24)
  }

  /**
   * @property {Number} 12 noon in minutes since midnight
   */
  @observable noonMinute: number = 12 * 60

  /**
   * @property {object[]} List of Timespan items to use
   * @note lazy singleton prevents recursion and optimizes fetch()
   */
  @computed get bucket(): TimeSpanBucketList {
    return getAppStore(TimeSpanBucketList)
  }

  /**
   * @param {object[]} items 
   */
  @action setBucketItems(items) {
    this.bucket.setItems(items)
  }

  /**
   * @property {Array} List of durations 
   */
  @computed get durationList() {
    return this.items.map(item => item.duration)
  }

  /**
   * @property {Array} Gradient CSS generated for timespan colors
   */
  @computed get gradientList(): {prevDuration: number, currDuration: number, color: string}[] {
    let prevDuration = 0
    return this.orderedItems.reduce((gradients, { duration, color }) => {
      const gradient = {
        prevDuration,
        currDuration: prevDuration += duration,
        color,
      }
      return [...gradients, gradient]
    }, [])
  }

  /**
   * @property {object} Empty Item
   */
  @observable emptyItem = {
    type: 'empty',
    title: 'Empty',
    duration: 1*60,
    color: 'green',
  }

  /**
   * Retrieve a timespan given type
   * @param {string} type 
   */
  getItemByType(type): TimeSpanItem {
    return this.items.find(item => item.type === type)
  }

  getItemByDuration(time): TimeSpanItem {
    let duration = 0
    return this.items.find(item => {
      duration += item.duration
      return (duration > time)
    })
  }

  /**
   * Generate a unique empty item
   */
  createEmptyItem(props): TimeSpanItem {
    const { emptyItem, dayId } = this
    const id = uid()
    return this.createItem({ ...emptyItem, dayId, id, ...props })
  }

  @override updateItems(items) {
    debug('update items', { items, timespans: this.toJSON() })
    items.forEach(item => {
      const timespan = this.getItemByUid(item.uid)
      if (timespan) {
        debug('set item', { timespan, item })
        timespan.setProps(item)
      }
    })
  }

  @override fetch() {
    return this.fetchState.get('account/timespan/list')
      .then(resp => {
        this.updateItems(resp.data.list)
        return resp
      })
  }

  save() {
    debug('save', this.fetchState.state)
    const list = this.items.filter(item => item.duration && item.uid)
    return this.saveState.postJson('account/timespan/list/save', { list })
  }

}