import React from 'react'
import { observer } from 'mobx-react'
import './EventDetail.scss'
import EventMembers from './EventMembers'
import DetailHeaderIcon from '../ui/Detail/DetailHeaderIcon'
import EventTitle from './EventTitle'
import DetailDuration from '../ui/Detail/DetailDuration'
import OnPlannerIcon from '../ui/OnPlannerIcon';
import stores, { getStore } from 'Stores'
import DetailRow from '../ui/DetailRow'
import DetailDateTime from '../ui/Detail/DetailDateTime'
import moment from 'moment'
import DetailFiles from '../ui/Detail/DetailFiles'
import EventAllDay, { AllDayCheckbox } from './EventAllDay'
import EventRecurrence from './EventRecurrence'
import EventTimezone from './EventTimezone'
import ActionPlanner from 'Stores/ActionPlanner'
import { CalendarEvent } from 'Stores/Calendar'
import EventLocation from './EventLocation'
import EventNotifications from './EventNotifications'

const debug = require('debug')('treks:event:detail')

type EventDetailProps = {
  actionPlanner: ActionPlanner;
  event: CalendarEvent;
}

class EventDetail extends React.Component<EventDetailProps> {

  static defaultProps = {
    get actionPlanner() {
      return getStore(stores.ActionPlanner)
    }
  }

  onDateChange = (key: string, dateTime: Date) => {
    const { event } = this.props
    if (!dateTime || !moment(dateTime).isValid()) {
      return /** @todo fix */
    }
    if (key === 'startDate') {
      event.moveStartDate(dateTime)
    } else {
      event.moveEndDate(dateTime)
    }
    event.willSave()
  }

  onStartDateChange = (dateTime: Date) => {
    this.onDateChange('startDate', dateTime)
  }

  onEndDateChange = (dateTime: Date) => {
    this.onDateChange('endDate', dateTime)
  }

  onChangeAllDay = (allDayEvent: boolean) => {
    const { event } = this.props
    event.setProps({ allDayEvent })
    event.willSave()
  }

  onFileListChange = (items) => {
    console.log('files', items)
    // this.props.event.fileList.setItems()
  }

  render() {
    const { actionPlanner, event } = this.props
    const EventAllDayCheckbox = <AllDayCheckbox
      allDayEvent={event && event.allDayEvent}
      onChange={this.onChangeAllDay}
    />
    debug('render', { event })
    if (!event) return null
    return (
      <div className="resource-detail event-detail">
        <div className="container">
          <header className="row header">
            <DetailHeaderIcon item={event} iconBusySize="lg" iconDone={'calendar'} iconNotDone={'calendar'} />
            <EventTitle item={event} />
            <OnPlannerIcon item={event} />
            <DetailDuration item={event} disabled={event.done} />
          </header>
        </div>
        <div className="container">
          <DetailRow label={'from'} hasValue={Boolean(event.startDate)} disabled={event.allDayEvent}>
            <DetailDateTime
              item={event}
              list={actionPlanner}
              dateTime={event.startDate}
              onChange={this.onStartDateChange}
              disabled={event.allDayEvent}
            />
          </DetailRow>
          <DetailRow label={'to'} hasValue={Boolean(event.endDate)} disabled={event.allDayEvent}>
            <DetailDateTime
              item={event}
              list={actionPlanner}
              dateTime={event.endDate}
              onChange={this.onEndDateChange}
              disabled={event.allDayEvent}
            />
          </DetailRow>
          <DetailRow label={EventAllDayCheckbox} hasValue={event.allDayEvent}>
            <EventAllDay allDayEvent={event.allDayEvent} onChange={this.onChangeAllDay} />
          </DetailRow>
          <DetailRow icon={'time_zone'} hasValue={Boolean(event.startTimeZone)}>
            <EventTimezone event={event} />
          </DetailRow>
          <DetailRow icon={'recurring'} hasValue={event.recurrence.length > 0}>
            <EventRecurrence event={event} />
          </DetailRow>
          <DetailRow icon={'location'} hasValue={Boolean(event.location)}>
            <EventLocation event={event} />
          </DetailRow>
          <DetailRow icon={'calendar'} hasValue={Boolean(event.calendar)}>
            {`${event.calendar?.title} (${event.calendarAccount?.service})`}
          </DetailRow>
          <DetailRow icon={'alert'} hasValue={event.notifications.items.length > 0}>
            <EventNotifications event={event} />
          </DetailRow>
          <DetailRow icon={'attach_file'} hasValue={event.fileList.items.length > 0}>
            <DetailFiles
              item={event}
              list={actionPlanner}
              fileList={event.fileList}
              onChange={this.onFileListChange}
            />
          </DetailRow>
        </div>
        <div className="container-scrollable md-light-scrollbar">
          <EventMembers item={event} />
        </div>
      </div>
    )
  }
}

export default observer(EventDetail)