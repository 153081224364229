import PropTypes from 'prop-types';
import stores, { getStore, models } from 'Stores';
import { Link } from 'react-router-dom';

import moment from 'moment';
import Avatar from 'theme/Avatar';
import React from 'react';
import { importInboxItem, InboxList } from 'Stores/Inbox';
import { observer } from 'mobx-react';
import Icon from 'theme/Icon';
import Draggable from 'Modules/Draggable';
import TaskItem from 'Stores/Task';

class InboxEntry extends React.Component {
    static propTypes = {
        entry: PropTypes.objectOf(() => importInboxItem()).isRequired,
    };

    static defaultProps = {
        get account() {
            return getStore(models.Session).Account;
        },
        get inboxList() {
            return getStore(InboxList);
        },
        get actionPlannerStore() {
            return getStore(stores.ActionPlanner);
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            replyInput: '',
            replies: [],
            sending: false,
        };
    }

    elementRef = null;
    elementClone = null;

    prevTop = 0;
    isDragging = false;

    onElementRef = (ref) => {
        if (ref) this.elementRef = ref;
    };

    onDragStart = () => {
        document.body.style.cursor = 'grab';
        this.isDragging = true;
    };

    onDrag = ({ top, left }) => {
        // this.setTaskPosition(top);
        console.log(top, left)
        return false;
    };

    onDragEnd = ({ top }) => {
        document.body.style.cursor = '';
        // this.setTaskPosition(top);
        this.isDragging = false;
        this.createTask()
    };

    componentDidMount() {}

    markRead = (e, force = false) => {
        e.stopPropagation();
        let { entry } = this.props;

        //already read
        if (force && entry.readDate) return;

        entry.readDate = entry.readDate && !force ? null : new Date();

        console.log(entry.toJSON());
        if (entry.readDate) {
            this.props.inboxList.modifyUnread(entry.comment ? 'comments' : 'activities', -1);
        } else {
            this.props.inboxList.modifyUnread(entry.comment ? 'comments' : 'activities', 1);
        }
        entry.save();
    };

    archive = (e) => {
        e.stopPropagation();
        let { entry } = this.props;
        entry.archivedDate = entry.archivedDate ? null : new Date();
        console.log(entry.toJSON());
        entry.save();

        if (!entry.readDate && entry.archivedDate) this.markRead(e, true);

        if (entry.archivedDate) this.props.inboxList.removeItem(entry);

        // this.forceUpdate();
    };

    createTask = async (e, onPlanner = true) => {
        // e.stopPropagation();
        const { actionPlannerStore, entry } = this.props;
        const { focusedItem } = actionPlannerStore;
        const { item: focusedTask } = focusedItem || null;

        let task;
        let taskDetails = { title: entry.text.substr(0, 255), description: 'null' };

        if (focusedTask && onPlanner) {
            task = actionPlannerStore.addEmptyItemAfterTask(focusedTask);
            task.setFocusOnTitle();
        } else {

            taskDetails.onPlanner = false;
            task = TaskItem.create(taskDetails)
        }

        await task.fromJSON(taskDetails);
        task.save();
        console.log('new task', task);
    };

    onAddReply = async (comment) => {
        const { account, entry } = this.props;
        console.log('onAddReply', account);
        const { replyInput, replies } = this.state;

        this.setState({
            sending: true,
        });

        let replyObj = {
            comment: replyInput,
            user: account,
            commentDate: new Date(),
            title: 'test title',
            replyTo: comment,
        };

        if (entry.project) replyObj['project'] = entry.project;
        if (entry.task) replyObj['task'] = entry.task;

        // let commentItem = CommentItem.create()
        // commentItem.fromJSON(replyObj)

        let commentItem = comment.replies.addItem();

        commentItem.fromJSON(replyObj);

        //TODO get the model ID from here
        // await commentItem.save();

        let arr = [...replies];
        arr.push(commentItem);
        this.setState({ replies: arr, replyInput: '', sending: false });

        entry.replyId = commentItem.id;
        console.log('replyId', entry.replyId);

        entry.save();
    };

    render() {
        const { entry, inboxList, account } = this.props;
        const { focusedItem } = inboxList;

        const {
            id,
            title,
            sender,
            icon,
            createDate,
            archivedDate,
            readDate,
            text,
            task,
            project,
            comment,
            replyId,
        } = entry;

        const { replies, sending } = this.state;

        const focused = focusedItem && focusedItem.id === id;

        return (
            <div
                className={'entry-container '}
                key={'inboxentry' + id}
                style={{
                    opacity: readDate && archivedDate ? 0.5 : 1,
                    borderLeft: project && project.color ? project.color + ' 5px solid' : '#aaa 5px solid',
                    fontWeight: readDate ? 'normal' : 'bold',
                }}
                onClick={(e) => {
                    inboxList.setFocusedItem(entry);
                    if (!entry.readDate) this.markRead(e);
                }}
            >
                <Draggable
                    ref={this.onElementRef}
                    dragDirection={'vertical'}
                    onDrag={this.onDrag}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    onContextMenu={({ nativeEvent }) => this.onShowContextMenu({ nativeEvent, task })}
                >
                    <div className={'entry ' + (focused ? 'entry-focused' : '')}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="entry-indicator">
                                {!readDate ? (
                                    <Icon
                                        set="fa"
                                        icon="circle"
                                        size="xs"
                                        color={'#1c71b2'}
                                        style={{
                                            width: 8,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {replyId ? (
                                    <Icon
                                        set="fa"
                                        icon="reply"
                                        size="xs"
                                        color={'#97ABB0'}
                                        style={{
                                            width: 10,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="entry-data">
                                <div className="details">
                                    <div className={icon + ' detail-icon'} />
                                    <div className="detail-text">{text}</div>
                                </div>
                                <div className="metadata">
                                    <Avatar size={20} name={sender.name} profileImage={sender.profileImage} />
                                    <div style={{ flexGrow: 1 }}>
                                        <div className="details">
                                            <div className="who">{sender.name}</div>
                                            <div className="what">{title}</div>
                                            <div className="when">{moment(createDate).fromNow()}</div>
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: 10,
                                                display: 'flex',
                                            }}
                                        >
                                            {project ? (
                                                <div
                                                    className="planner-task-item-project"
                                                    style={{
                                                        background: project.color ? project.color : '#eee',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div className="icon-project group-icon"></div>

                                                    <div className="group-text">{project.title}</div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {task && task.project && task.project.title ? (
                                                <div
                                                    className="planner-task-item-project"
                                                    style={{
                                                        background: task.project.color
                                                            ? task.project.color
                                                            : '#eee',
                                                        display: 'flex',
                                                        marginRight: 5,
                                                    }}
                                                >
                                                    <div className="icon-project group-icon"></div>
                                                    <Link to={'/inbox/project/' + task.project.id}>
                                                        <div className="group-text">{task.project.title}</div>
                                                    </Link>
                                                </div>
                                            ) : (
                                                <></>
                                            )}

                                            {task ? (
                                                <div
                                                    className="planner-task-item-project"
                                                    style={{
                                                        background: '#eee',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div className="icon-task group-icon"></div>

                                                    <div className="group-text">{task.title}</div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>

                                    <div className="buttons">
                                        <div className="button icon-task" onClick={(e) => entry.createTask()}>
                                            {' '}
                                        </div>
                                        <div
                                            className="button icon-action_planner"
                                            onClick={(e) => this.createTask(e)}
                                        ></div>
                                        <Icon
                                            set="fa"
                                            size="xs"
                                            icon="archive"
                                            className="icon-button"
                                            onClick={this.archive}
                                        ></Icon>

                                        <Icon
                                            set="fa"
                                            size="xs"
                                            icon="envelope"
                                            className="icon-button"
                                            onClick={this.markRead}
                                        ></Icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {focused && comment ? (
                            <div className="focused-container">
                                <div className="replies ">
                                    {replies.map((r) => (
                                        <div className="reply" key={'inboxentry' + id + '-reply' + r.id}>
                                            {' '}
                                            <Avatar
                                                size={20}
                                                name={account.name}
                                                profileImage={account.profileImage}
                                            />
                                            <div className="reply-text">{r.comment}</div>
                                            <div className="when">{moment(r.commentDate).fromNow()}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="focused-actions">
                                    <div className={icon + ' focus-icon'} />
                                    <div className="focus-input">
                                        <input
                                            disabled={sending}
                                            onChange={(e) =>
                                                this.setState({
                                                    replyInput: e.target.value,
                                                })
                                            }
                                            value={this.state.replyInput}
                                            placeholder={'Respond'}
                                            style={{ width: '100%', paddingLeft: 5 }}
                                        />
                                    </div>
                                    <div className="send-button" onClick={() => this.onAddReply(comment)}>
                                        {' '}
                                        SEND{' '}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </Draggable>
            </div>
        );
    }
}

export default observer(InboxEntry)
