import { override } from 'mobx'
import TaskItem, { TaskList } from 'Stores/Task';
import { stores } from 'Stores';
import { hasOne } from 'Relationships/RelationshipDecorators';
import { TaskLabel } from './TaskLabel';

const debug = require('debug')('treks:model:LabelTaskList')

type TaskItemJson = {
  labelId: number
  taskId: number
  TaskLabel: {
    Label: {
      id: number
      title: string
    }
  }[]
}

type LabelTaskListJsonFetchRes = {
  items?: TaskItemJson[]
  total?: number
}

export class LabelTaskList extends TaskList {

  get ModelType(): TaskItem {
    return stores.TaskItem
  }

  // @todo fix: this triggers label.taskList = null when removing label from TaskLabelList
  // @hasOne(() => TaskLabel, (label: TaskLabel) => label.taskList) 
  declare label: TaskLabel

  @override get parent() {
    return this.label
  }

  async createFetchPageRequest(start: number, count: number): Promise<any> {
    // @todo fix hasOne(TaskLabel) above
    if (!this.label) return { data: { items: [], total: 0 } }  
    const { id } = this.label
    debug('createFetchPageRequest labelTaskList', { id, start, count })

    return this.fetchState.get('task/label/task/list/' + id, {
      start,
      count
    })
  }

  _appendLabels(item: TaskItemJson) {
    const labels = item.TaskLabel.map(label => {
      return label.Label
    })
    return {
      ...item,
      taskLabelList: {
        items: labels
      }
    }
  }

  addItemsFromJSON(items: TaskItemJson[]) {
    debug('add items json', items)
    const tasks = items.map(item => {
      const task = this._appendLabels(item)
      return task
    })
    debug('add items json', { items, tasks })
    return super.addItemsFromJSON(tasks)
  }

  fromJSON(json: LabelTaskListJsonFetchRes) {
    debug('LabelTaskList fromJSON', json)
    const items = json?.items?.map(item => {
      const task = this._appendLabels(item)
      return task
    })
    if (json?.total) {
      debug('totalItems', json.total)
      this.totalItems = json.total
    }
    debug('labelTaskList', { items })
    return super.fromJSON({ items } as any)
  }

  @override async fetch() {
    const resp = await  this.fetchState.get('task/label/task/list', { labelId: this.parent.id })
    debug('fetch labeltasklist', { resp })
    return this.fromJSON(resp?.data)
  }

}