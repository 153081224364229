import { FormControlDropdown } from 'theme/Form'

const seatItems = [...(new Array(50)).keys()]
  .map(index => ({ value: index + 1, label: `${index + 1} Seat` + (index ? 's' : '')}))

const fields = [
  {
    name: 'plan_code',
    label: 'Plan',
    placeholder: 'Pro Business',
    value: null,
    Component: FormControlDropdown,
    items: []
  },
  {
    name: 'seats',
    label: 'Seats',
    placeholder: '1 Seat',
    value: null,
    Component: FormControlDropdown,
    items: seatItems
  },
  {
    name: 'billingDuration',
    label: 'Billed',
    placeholder: 'Annually',
    value: null,
    Component: FormControlDropdown,
    items: [
      'Annually',
      'Monthly',
    ]
  },
]

export default fields