export const complexTask = {
  type: 'task',
  title: 'Initial Research: Write up next iteration test plan',
  slug: 'project',
  icon: 'project',
  due: false,
  duration: 40,
  project: 'Incitable - Wireframe',
  subTasks: [
    {
      id: '1',
      title: 'Prep Work',
      items: [
        {
          id: '1',
          title: 'Review email from Tom',
          duration: 5,
          done: true,
        },
        {
          id: '2',
          title: 'Build test plan matrix in XL',
          duration: 25,
        },
      ]
    },
    {
      id: '2',
      title: 'Deliverable',
      items: [
        {
          id: '3',
          title: 'Deliverable: Import into Pivotal Tracker',
          duration: 10,
        }
      ]
    }
  ],
  childTasks: [
    {
      id: '1',
      type: 'task',
      title: 'RUNWAY: Design Review',
      slug: 'project',
      icon: 'project',
      due: false,
      duration: 60,
    },
  ]
}

const tasks = [
  {
    type: 'task',
    title: 'EXERCISE: mountain bike - black mt. - 6 mi',
    slug: 'project',
    icon: 'project',
    due: false,
    duration: 45,
  },
  {
    type: 'task',
    title: 'drive to work',
    slug: 'project',
    icon: 'project',
    due: false,
    duration: 15,
  },
  {
    type: 'task',
    title: 'Morning tea break',
    slug: 'project',
    icon: 'project',
    due: false,
    duration: 20,
    done: true,
  },
  complexTask,
]

const tasksDue = [
  {
    type: 'task',
    title: 'ACCOUNTING: Pay bills',
    slug: 'action-planner',
    icon: 'action_planner',
    due: true,
    duration: 30,
  },
  {
    type: 'task',
    title: 'STAFFING: Create bookkeeper listing',
    slug: 'project',
    icon: 'project',
    due: true,
    duration: 75,
  },
  {
    type: 'task',
    title: 'PORTA FITNESS: Call about invoice',
    slug: 'project',
    icon: 'project',
    due: true,
    duration: 10,
  },
]

// mocks
export const items = tasks.map(item => ({
  ...item,
  id: item.title,
}))
export const itemsDue = tasksDue.map(item => ({
  ...item,
  id: item.title,
}))
export const order = items.map(item => item.id)

export const user = {
  id: '1',
  name: 'Paul Seiter',
}
export const user2 = {
  id: '2',
  name: 'Gabe',
}
export const memberList = [
  user,
  user2
]
export const commentList = [
  {
    id: 1,
    user,
    comment: `This task isn’t going to be easy. 
    I’d venture to say it will be the 
    hardest task of the whole project.`,
    since: '2 hours ago',
  },
  {
    id: 2,
    user,
    comment: `Can anyone give me an idea 
    on how to log into Pivotal Tracker?`,
    since: '6 hours ago',
  }
]
export const activityList = [
  {
    id: 1,
    user: user2,
    action: `created Task`,
    since: '2 days ago',
  },
  {
    id: 2,
    user: user2,
    action: `put task on his Action Planner`,
    since: '2 days ago',
  },
]
