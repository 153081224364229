import React from 'react'
import { observer } from 'mobx-react'
import ButtonViewDetail from '../../../../theme/List/ButtonViewDetail';
import { withRouter } from 'react-router-dom'
import { GoalSubGoalTitle } from './GoalSubGoalTitle';
import { computed } from 'mobx';
import { GoalDataSource } from 'Stores/Goal/GoalData';

class GoalSubGoalItem extends React.Component {

  onViewDetail = () => {
    const { subgoal, history } = this.props
    history.push('/subgoal/' + subgoal.id)
  }

  onChangeRollupData = event => {
    const { goal, subgoal } = this.props
    if (!goal.dataSourceId) goal.dataSourceId = []
    goal.dataSourceId = event.target.checked 
      ? [ ...goal.dataSourceId, subgoal.id.toString() ]
      : [ ...goal.dataSourceId ].filter(id => id !== subgoal.id.toString())
  }

  @computed get checked() {
    const { goal, subgoal } = this.props
    return (this.enabled && goal.dataSourceId)
      ? goal.dataSourceId.includes(subgoal.id.toString())
      : false
  }

  @computed get enabled() {
    return this.props.goal.dataSource === GoalDataSource.subgoals
  }

  render() {
    const { subgoal } = this.props
    return (
      <tr>
        <td>
          <ButtonViewDetail onClick={this.onViewDetail} />
        </td>
        <td>
          <GoalSubGoalTitle subgoal={subgoal} />
        </td>
        <td>
          <input
            disabled={!this.enabled}
            type="checkbox"
            className="checkbox-rollupData"
            checked={this.checked}
            onChange={this.onChangeRollupData}
          />
        </td>
        <td>{subgoal.fromValue}</td>
        <td>{subgoal.toValue}</td>
      </tr>
    )
  }
}

export default withRouter(observer(GoalSubGoalItem))
