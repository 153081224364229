import { observable, action, override } from 'mobx'
import { Item } from '../Lists'
import GoalMemberList from './GoalMemberList'
import HabitList from '../Habit/HabitList';
import MeetingList from '../Meeting/MeetingList';
import { OrganizationItem } from '../Organization';
import SubTaskListList from '../Task/SubTask/SubTaskListList';
import { FileList } from 'Stores/File';
import GoalActivityList from './Activity/GoalActivityList'
import { TeamItem } from 'Stores/Team';
import GoalTemplate from './GoalTemplate';
import GoalSubGoalList from './GoalSubGoalList';
import { GoalList } from 'Stores/Goal';
import GoalDataList from './GoalData/GoalDataList';
import { GoalDataSource } from './GoalData/GoalDataSource';
import { CumulativeStats } from 'Stores/Stats/CumulativeStats';
import { hasMany, hasOne } from 'Relationships/RelationshipDecorators';
import GoalFollowerList from './GoalFollowerList';
import { UserI } from 'Stores/User/Type/User';
import { ProjectItem } from 'Stores/Project';
import { CategoryItem, CategoryList } from 'Stores/Category';
import GoalTaskList from './GoalTaskList';
import TaskItem from 'Stores/Task';

export default class GoalItem extends Item {

  get cumulativeStats():CumulativeStats {
    return this.getAttribute('cumulativeStats', () => {
      return CumulativeStats.fromProps({ model: this })
    })
  }

  @observable title:string = ''

  @observable color: string = ''

  @action setTitle(title:string) {
    this.title = title
  }

	@hasMany(() => GoalMemberList, (list: GoalMemberList) => list.goal)
  memberList:GoalMemberList

	@hasMany(() => GoalFollowerList, (list: GoalFollowerList) => list.goal)
  followerList:GoalFollowerList

  @hasMany(() => CategoryList, (category: CategoryItem) => category.project)
  categoryList: CategoryList

  @observable createdTS:Date = new Date()

  @observable status:string = ''

  @observable units:string = ''

  @observable startDate:Date = new Date()

  @observable endDate:Date = new Date()

  @observable fromValue:string = ''

  @observable toValue:string = ''

  @observable rollupData:boolean = false

  // @todo add to schema
  @observable highlightColor:string = ''

  // @todo add to schema
  @observable dataSource:GoalDataSource = GoalDataSource.goal

  // @todo add to schema
  @observable dataSourceId:Array<string> = []

	@hasMany(() => GoalDataList, (list: GoalDataList) => list.goal)
  data:GoalDataList

  @observable createdBy:UserI

	@hasOne(() => ProjectItem, (project: ProjectItem) => project.goalList)
  project: ProjectItem


	@hasMany(() => HabitList, (list: HabitList) => list.goal)
  habits: HabitList


	@hasMany(() => MeetingList, (list: MeetingList) => list.goal)
  meetings:MeetingList

  @observable owner:UserI

  @hasOne(() => OrganizationItem)
	organization:OrganizationItem


	@hasMany(() => GoalSubGoalList, (list: GoalSubGoalList) => list.goal)
  subgoals:GoalSubGoalList

	@hasMany(() => FileList, (list: FileList) => list.goal)
  fileList:FileList 

	@hasMany(() => GoalActivityList, (list: GoalActivityList) => list.goal)
  activityList:GoalActivityList

  @observable team:TeamItem

  @hasOne(() => GoalTemplate, (template: GoalTemplate) => template.goal)
	template:GoalTemplate

  // @deprecated
  @hasMany(() => SubTaskListList, (list: SubTaskListList) => list.goal)
	subTasksList:SubTaskListList

  @hasMany(() => GoalTaskList, (task: TaskItem) => task.goal)
  taskList: GoalTaskList;

  @observable planCompletePercent:number = 0

  @override async save() {
    return this.saveState.postJson('goal/save', this.toJSON())
  }

}
