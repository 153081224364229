import React from 'react'
import { observer } from 'mobx-react'

export const GoalHabitTitle = observer(({ habit }) => {

  const onChange = event => {
    habit.title = event.target.value
  }

  return (
    <input 
      onChange={onChange}
      value={habit.title}
      className={'title title-input input-borderless'}
      placeholder={'habit title'}
    />
  )
})