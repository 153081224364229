import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Icons from './Icons'
import Sortable from './Sortable';
import Draggable from './Draggable'
import Recaptcha from './Recaptcha'
import { PlannerFlowDoc, WeekPlannerFlowDoc } from './PlannerFlow';

const components = {
  'icons': Icons,
  'sortable': Sortable,
  'draggable': Draggable,
  'recaptcha': Recaptcha,
  'plannerFlow': PlannerFlowDoc,
  'weekPlannerFlow': WeekPlannerFlowDoc
}

const DocsIndex = () => {
  
  return (
    <div>
      <h1>Docs</h1>
      <ul>
      {
        Object.keys(components).map(name => (
          <li key={name}><a href={'/docs/'+ name}>{name}</a></li>
        ))
      }
    </ul>
    </div>
  )
}

export const DocsRoutes = () => {
  return (
    <Switch>
      {
        Object.entries(components).map(([key, value]) => {
          return (
            <Route key={key} path={'/docs/'+key} component={value} />
          )
        })
      }
      
      <Route component={DocsIndex} />
    </Switch>
  )
}
export default DocsRoutes