import { computed, observable } from 'mobx';
import Model from './Model'
import { importUser } from './User';

export default class Invitation extends Model {

  @observable invitationId:string

  @observable inviterId:string

  @computed get inviter() {
    return importUser().fromId(this.inviterId)
  }
  
  @observable inviteeId:string

  @observable inviteeEmail:string

  @observable inviteSentTS:Date

  @observable acceptedTS:Date

  @observable deniedTS:Date

  @observable approvalIP:string

  @observable plan:string

}