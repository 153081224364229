import React from 'react'
import { observer } from 'mobx-react'
import { getStore, stores } from 'Stores'
import './EventDetailFooter.scss'
import PressableIcon from '../../theme/Pressable/PressableIcon';
import { ProjectItem } from 'Stores/Project';

const debug = require('debug')('treks:task:footer')

class EventDetailFooter extends React.Component {

  static defaultProps = {
    size: { 
      width: '100%'
    },
    get focusedItem() {
      return getStore(stores.ActionPlanner).focusedItem
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  onClickTrash = event => {
    const { focusedItem } = this.props
    debug('delete focusedItem', focusedItem)
    event.preventDefault()
    focusedItem.item.trash()
  }

  onClickPrint = event => {
    event.preventDefault()
    window.print()
  }

  onConvertToProject = event => {
    const { focusedItem } = this.props
    event.preventDefault()
    const { title, description, memberList, subEventsList } = focusedItem.toJSON()
    const project = ProjectItem.fromProps({ title, description, memberList, subEventsList })
    debug('onConvertToProject', focusedItem, project)

  }

  onConvertToEvent = event => {
    event.preventDefault()
    window.print()
  }

  onCopyUrl = event => {
    event.preventDefault()
    window.print()
  }

  render() {
    const { size, pos, focusedItem } = this.props
    debug('render', { size, pos, focusedItem })
    return (
      <footer
        id="task-detail-footer"
        className="main-pane-footer"
        style={{
          ...size,
          ...pos
        }}
      >
        <PressableIcon icon="trash" size="xs" style={{marginLeft: -10 }} onClick={this.onClickTrash} />
        <PressableIcon icon="print" size="xs" onClick={this.onClickPrint} />
        <PressableIcon icon="convert_to_project" size={20} onClick={this.onConvertToProject} />
        <PressableIcon icon="convert_to_event" size={20} onClick={this.onConvertToEvent} />
        <PressableIcon icon="url" size="xs"  onClick={this.onCopyUrl} />
      </footer>
    )
  }
}

export default observer(EventDetailFooter)
