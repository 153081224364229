import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom';
import ActionPlannerFooter from '../ActionPlannerFooter';
import { DayPlanner, getDayPlanner } from 'Stores/ActionPlanner/Planner/DayPlanner';

const debug = require('debug')('treks:planner:footer')

class DayPlannerFooter extends React.Component<{
  classNamePrefix?: string;
  plannerUi?: DayPlanner;
  getPaneEl?: () => HTMLElement;
}> {

  static defaultProps = {
    classNamePrefix: 'day-planner-footer',
    get plannerUi() {
      return getDayPlanner()
    },
    getPaneEl() {
      return document.querySelector('.main-pane-component:has(.action-planner.day-view)')
    }
  }

  componentDidMount() {
    const { plannerUi } = this.props
    plannerUi.fetchLocal()
  }

  onToggleCollapse = () => {
    const { plannerUi, getPaneEl } = this.props
    const scrollTop = getPaneEl().scrollTop
    const scrollDuration = plannerUi.utils.getDurationFromHeight(scrollTop)
    plannerUi.toggleUiExpandCollapse()
    plannerUi.saveLocal()
    this.handleScrollOnLayoutChange(scrollDuration)
  }

  handleScrollOnLayoutChange(scrollDuration: number) {
    const { plannerUi, getPaneEl } = this.props
    
    requestAnimationFrame(() => {
      const paneEl = getPaneEl()
      if (!paneEl) {
        return // pane can unmount before raf
      }
      const nextScrollTop = plannerUi.utils.getHeightFromDuration(scrollDuration)
      paneEl.scrollTop = nextScrollTop
      console.log('scroll', { scrollDuration, nextScrollTop })
    })
  }

  render() {
    const { plannerUi, getPaneEl, classNamePrefix } = this.props
    return (
      <ActionPlannerFooter
        classNamePrefix={classNamePrefix}
        plannerUi={plannerUi}
        getPaneEl={getPaneEl}
        onToggleCollapse={this.onToggleCollapse}
      />
    )
  }
}

export default withRouter(observer(DayPlannerFooter))
