import React from 'react'
import { observer } from 'mobx-react'
import ActionPlannerTask from './ActionPlannerTask'
import TaskResizeHandle from './TaskResizeHandle'
import { action } from 'mobx';
import PropTypes from 'prop-types'
import { ResizableWithScroll } from 'Modules/Resizable/ResizableWithScroll';
import { round } from 'Stores/utils';
import { getKeyPressState } from 'uiState/KeypressState';

const keyPressState = getKeyPressState()

const debug = require('debug')('treks:planner:ResizableTask')

class ActionPlannerResizableTask extends React.Component {

  static propTypes = {
    onTaskDurationUpdate: PropTypes.func.isRequired
  }

  static defaultProps = {
    scrollElSel: '.main-pane-component:has(.action-planner)'
  }

  get isRoundDuration() {
    return keyPressState.isAnyCtrlKeyPressed
  }

  getPieceDurationFromHeight(taskPiece, height) {
    const { plannerUtils } = this.props
    const duration = plannerUtils.getDurationFromHeight(height)
    return taskPiece.pieceIndex === 0 
      ? Math.max(plannerUtils.opts.minDuration, duration) 
      : duration
  }

  getNextTaskDuration(taskPiece, nextPieceDuration, addEventDuration = true) {
    const { plannerFlow, plannerUtils } = this.props
    const { startDuration, pieces } = taskPiece
    const endDuration = startDuration + nextPieceDuration
    
    const eventsDuration = addEventDuration ? 
      plannerFlow.getEventsDurationOnPiece(plannerFlow.visibleEvents, startDuration, endDuration) : 0
    
    let nextTaskDuration =  pieces.reduce((sum, piece) => {
      // piece.addedDuration is events/sticky durations added on top of piece
      const duration = taskPiece === piece ? nextPieceDuration - eventsDuration  
        : piece.duration - piece.addedDuration
      return duration + sum
    }, 0)

    // restrict to >= minDuration
    nextTaskDuration = Math.max(Math.round(nextTaskDuration), plannerUtils.opts.minDuration)

    debug('getNextTaskDuration', { eventsDuration, nextTaskDuration })
    return nextTaskDuration
  }

  @action onResizeStart = ({ height }) => {
    const { plannerFlow, item:task, taskPiece, actionPlannerStore } = this.props
    document.body.style.cursor = 'row-resize'
    const nextPieceDuration = this.getPieceDurationFromHeight(taskPiece, height)
    task.isResizing = true
    task.resizeDuration = task.duration
    actionPlannerStore.resizingTask = task
    plannerFlow.resizingPieceUid = taskPiece.uid // triggers plannerFlow
    plannerFlow.resizingPieceDuration = nextPieceDuration
    debug('resize start', { height, nextPieceDuration, duration: task.duration })
  }

  @action onResizeStop = ({ height }) => {
    const { item:task, taskPiece, actionPlannerStore, onTaskDurationUpdate } = this.props
    document.body.style.cursor = ''
    task.isResizing = false
    const duration = task.resizeDuration
    task.resizeDuration = null
    actionPlannerStore.resizingTask = null // triggers plannerFlow
    plannerFlow.resizingPieceUid = null
    onTaskDurationUpdate({ task, duration })
    debug('resize stop', { height, duration })
  }

  @action onResize = ({ height }) => {
    const { plannerFlow, item:task, taskPiece, plannerUtils, actionPlannerStore } = this.props
    let nextPieceDuration = this.getPieceDurationFromHeight(taskPiece, height)

    debug('nextPieceDuration', nextPieceDuration)
    if (this.isRoundDuration) {
      const roundedDuration = round(taskPiece.startDuration + nextPieceDuration, 5)
      nextPieceDuration = roundedDuration  - taskPiece.startDuration
      debug('roundedDuration', { nextPieceDuration, roundedDuration })
    }

    const timespanEndDuration = taskPiece.timespanPiece.endDuration
    const pieceEndDuration = taskPiece.startDuration + nextPieceDuration

    if (Math.abs(timespanEndDuration - pieceEndDuration) <= 5 ) {
      nextPieceDuration = timespanEndDuration
    }

    const resizeDuration = this.getNextTaskDuration(taskPiece, nextPieceDuration, true)
    plannerFlow.resizingPieceDuration = nextPieceDuration
    task.resizeDuration = resizeDuration
    debug('resize', { height, nextPieceDuration, resizeDuration })
    // const bottomPos = plannerUtils.getHeightFromDuration(taskPiece.startDuration + nextPieceDuration)
    // this.scrollActionPlannerIfNeeded(bottomPos)

    actionPlannerStore.setFocusedTask(task)
  }

  onTaskPress = (event) => {
    global.focusedTaskPiece = this.props.taskPiece // dev
    if (this.props.onTaskPress) {
      this.props.onTaskPress({ task: this.props.item, event })
    }
  }

  onContextMenu = event => {
    event.stopPropagation()
    if (this.props.onContextMenu && !localStorage.noTaskContextMenu) {
      this.props.onContextMenu(event, this.props.item)
    }
  }

  // optimized to return a new func for resizing item only
  getResizeHandle = () => {
    const { taskPiece, plannerFlow, item } = this.props
    if (!this.ResizeHandle || item.isResizing) {
      this.ResizeHandle = props => (
        <TaskResizeHandle
          {...props}
          taskPiece={taskPiece}
          plannerFlow={plannerFlow}
        />
      )
    }
    return this.ResizeHandle
  }

  getScrollEl() {
    return document.querySelector(this.props.scrollElSel)
  }

  scrollActionPlannerIfNeeded(bottom) {
    if (this.isScrollBusy) return
    const pane = this.getScrollEl()
    const { offsetHeight, scrollTop } = pane
    const scrollBottom = scrollTop + offsetHeight
    const buffer = 100
    debug('scrollifneeded', { bottom, offsetHeight, scrollTop, scrollBottom })
    if (scrollBottom < bottom + buffer) {
      this.isScrollBusy = true
      pane.addEventListener('scroll', this.handleScrollBusy)
      pane.scrollTo({ top: scrollTop + buffer, behavior: 'smooth' })
    }
  }

  handleScrollBusy = () => {
    clearTimeout(this.scrollTimer)
    this.scrollTimer = setTimeout(() => {
      const pane = this.getScrollEl()
      this.isScrollBusy = false
      pane.removeEventListener('scroll', this.handleScrollBusy)
    }, 30)
  }

  render() {
    const { plannerFlow, plannerUtils, actionPlannerStore, item, taskPiece, scrollElSel, plannerOpacityPercent } = this.props
    const { focusedItem } = actionPlannerStore
    const { uid } = item
    const { underflow, overflow } = taskPiece
    const height = plannerUtils.getHeightFromDuration(taskPiece.duration)
    const isFocused = focusedItem.item?.uid === uid
    const size = { width: '100%', height }
    const isActiveClassName = (isFocused  ? 'active' : '')
    const isUnderflowingClassName = (underflow > 0 ? 'undeflowing' : '')
    const isOverflowingClassName = (overflow > 0 ? 'overflowing' : '')
    const hasDueDateClassName = (item.dueDate ? 'has-due-date' : '')
    const isDoneClassName = (item.done ? 'done' : '')
    const isResizingClassName = (item.isResizing ? 'resizing' : '')
    const className = [
      isActiveClassName, isUnderflowingClassName, isResizingClassName,
      isOverflowingClassName, hasDueDateClassName, isDoneClassName
    ].join(' ')
    const eventAtStart = plannerFlow.getFirstEventStartingWithTaskPiece(taskPiece, 5)
    const lastEventEndDuration = eventAtStart ? plannerFlow.getEventsOverlappingEndDuration(eventAtStart) : 0
    const eventsAtStartDuration = eventAtStart ? lastEventEndDuration - taskPiece.startDuration : 0
    let eventsAtStartMargin = plannerUtils.getHeightFromDuration(eventsAtStartDuration)
    //if (eventsAtStartDuration >= taskPiece.duration - 10) eventsAtStartMargin = 0 // fix: do not hide task
    const taskOpacity = Number.isInteger(plannerOpacityPercent) ? plannerOpacityPercent : 100
    debug('render item', { item, actionPlannerStore })
    return (
      <ResizableWithScroll
        scrollElSel={scrollElSel}
        key={uid}
        data-id={uid}
        style={{ ...size, opacity: taskOpacity }} 
        className={"action-planner-pane " + className}
        onClick={this.onTaskPress}
        onContextMenu={this.onContextMenu}
        resizeHandleClassName={'action-planner-task-resize-handle'}
        Handle={this.getResizeHandle()}
        resize={'vertical'}
        onResize={this.onResize}
        onResizeStart={this.onResizeStart}
        onResizeEnd={this.onResizeStop}
      >
        <ActionPlannerTask
          style={{ marginTop: eventsAtStartMargin, height: `calc(100% - ${eventsAtStartMargin}px)` }}
          taskPiece={taskPiece}
          item={item}
          actionPlannerStore={actionPlannerStore}
          plannerFlow={plannerFlow}
        />
      </ResizableWithScroll>
    )
  }
}

export default observer(ActionPlannerResizableTask)
