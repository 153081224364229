import React from 'react'
import { observer } from 'mobx-react';
import Draggable from '../Modules/Draggable';

const debug = require('debug')('treks:docs:draggable:item')

export default observer(class DraggableItem extends React.Component {

  elementRef = null
  elementClone = null

  onElementRef = ref => {
    if (ref) this.elementRef = ref
  }

  onDragStart = ({ elementRef }) => {
    document.body.style.cursor = 'grab'
    const clone = elementRef.cloneNode(true)
    clone.classList.add('draggable-ghost')
    clone.style.position = 'absolute'
    clone.style.width = elementRef.offsetWidth + 'px'
    this.elementClone = clone
    elementRef.parentNode.insertBefore(clone, elementRef)
  }

  onDrag = ({ top }) => {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.elementClone.style.transform = `translate(0, ${top}px)`
      })
    }, 100)
  }

  onDragEnd = ({ top, elementRef }) => {
    document.body.style.cursor = ''
    this.elementClone.parentNode.removeChild(this.elementClone)
    window.requestAnimationFrame(() => {
      elementRef.style.transform = `translate(0, ${top}px)`
    })
  }

  render() {
    const { item } = this.props
    const { id, name, type } = item
    

    debug('render', this.props)

    return (
      <Draggable
        ref={this.onElementRef}
        data-id={id}
        data-type={type}
        className={type + '-item item'}
        style={{ top: item.top }}
        dragDirection={'both'}
        onDrag={this.onDrag}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
      >
        <h5 className="title">{name}</h5>
      </Draggable>
    )
  }
})