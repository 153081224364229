import React from 'react'
import { observer } from 'mobx-react'
import { autorun, computed, when } from 'mobx'
import DueToday from 'Components/ActionPlanner/DueToday'
import RealtimeIndicator from 'Components/ActionPlanner/RealtimeIndicator'
import CalendarTimespan from 'Components/Calendar/CalendarTimespan'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import Resizable from 'Modules/Resizable'
//import TimespanResizeHandle from '../TimespanResizeHandle'
import WeekCalendarEventsFlow from 'Components/Calendar/WeekCalendar/WeekCalendarEventsFlow'
import stores from 'Stores'
import TimespanTitle from 'Components/ActionPlanner/TimespanTitle'
import ActionPlannerUtils from 'Stores/ActionPlanner/ActionPlannerUtils'
import ActionPlannerStickyTasks from 'Components/ActionPlanner/StickyTasks/ActionPlannerStickyTasks'
import ActionPlannerHeader from 'Components/ActionPlanner/Header/ActionPlannerHeader'
import { WeekPlanner, getWeekPlanner } from 'Stores/ActionPlanner/Planner/WeekPlanner'
import { WeekPlannerConfig } from 'Stores/ActionPlanner/config/WeekPlannerConfig'
import { round } from 'Stores/utils'
import { getKeyPressState } from 'uiState/KeypressState'
import ActionPlanner from 'Stores/ActionPlanner'
import { PlannerFlow } from 'Stores/ActionPlanner/PlannerFlow/PlannerFlow'
import { CalendarEvent } from 'Stores/Calendar'
import { TimeSpanItem } from 'Stores/ActionPlanner/TimeSpan'
import { createMockEvents } from 'Screens/Docs/PlannerFlow/mocks'
import "./WeekCalendar.scss"
import "./WeekCalendarEventsFlow.scss"

const debug = require('debug')('treks:planner')

type WeekCalendarFlowProps = {
  plannerOpacityPercent: number;
  actionPlannerStore: ActionPlanner;
  plannerUtils: ActionPlannerUtils;
  plannerFlow: PlannerFlow;
  weekPlanner?: WeekPlanner;
  resizeNextTimespan?: boolean;
  syncTimespansErrorMsg?: string;
  onFocusEvent: (event: CalendarEvent) => void;
  onFocusTimespan: (timespan: TimeSpanItem) => void;
  items: [];
  itemsDue: [];
  onTaskDurationWillChange: () => void;
  onTaskDurationChange: () => void;
  onTaskDurationUpdate: () => void;
  onTaskPress: () => void;
  onOrderChange: () => void;
  onContextMenu: () => void;
  showHeader?: boolean;
  showTimespanTitle?: boolean;
  showDueToday?: boolean;
  showRealtimeIndicator?: boolean;
  dueTodayLabel?: string;
}

class WeekCalendarFlow extends React.Component<WeekCalendarFlowProps> {

  static defaultProps = {
    weekPlanner: getWeekPlanner(),
    resizeNextTimespan: true,
    syncTimespansErrorMsg: 'Unable to sync your timespan settings'
  }

  uiState = ArbitraryModel.fromProps({
    isResizing: false,
    focusedTimespan: null,
    isMounted: false,
  })

  resizeState = {
    lastDuration: null,
    startDuration: null
  }
  
  get isviewingCurrentDay() {
    return computed(() => {
      const { focusedDate, currentDate } = this.props.actionPlannerStore
      const startCurrentDayMs = new Date(currentDate).setHours(0, 0, 0, 0)
      const viewingCurrentDay = startCurrentDayMs === focusedDate.getTime()
      return viewingCurrentDay
    }).get()
  }

  get plannerFlow() {
    return this.props.plannerFlow
  }

  componentDidMount() {
    const { actionPlannerStore } = this.props

    autorun(() => {
      const { focusedEvent } = actionPlannerStore
      if (focusedEvent) this.props.onFocusEvent(focusedEvent)
    })
    autorun(() => {
      const { focusedTimespan } = actionPlannerStore
      if (focusedTimespan) this.props.onFocusTimespan(focusedTimespan)
    })

    this.syncTimespanSettings()

    // wait for events to be fetched as they overwrite
    when(() => actionPlannerStore.eventList.fetchState.isFetched, () => {
      setTimeout(() => {
        this.createMockEvents()
      }, 1000) 
    })

    global.plannerFlow = this.plannerFlow
    global.plannerUtils = this.props.plannerUtils
  }

  createMockEvents() {
    const events = createMockEvents(10)
    console.log('setting events', events)
    this.props.actionPlannerStore.setEvents(events)
  }

  syncTimespanSettings() {
    const { plannerFlow } = this.props
    autorun(() => {
      const { startDate, endDate } = plannerFlow
      debug('planner start/end change', { startDate, endDate })
      plannerFlow.dayList.items.map(async day => {
        const dayJSON = await day.fetched()
        debug('updated day json', dayJSON)
      })
    })
  }

  get isAnyCtrlKeyPressed() {
    return getKeyPressState().isAnyCtrlKeyPressed
  }

  onResize = (timespanPiece, { height }) => {
    const sticky = this.isAnyCtrlKeyPressed
    const { plannerUtils, plannerFlow } = this.props
    const { lastDuration } = this.resizeState
    const heightDuration = plannerUtils.getDurationFromHeight(height)
    const deltaDuration = heightDuration - lastDuration
    const lastPiece = plannerFlow.getLastTimespanPiece(timespanPiece)
    const lastPieceEndDuration = lastPiece.endDuration
    const nextLastPieceEndDuration = lastPieceEndDuration + deltaDuration
    const nextDeltaDuration = round(nextLastPieceEndDuration, sticky ? 5 : 1) - lastPieceEndDuration
    const appliedDelta = plannerFlow.resizeTimespanPiece(timespanPiece, nextDeltaDuration, true)
    const nextDuration = lastDuration + appliedDelta
    this.resizeState.lastDuration = nextDuration
    debug('resize', { heightDuration, lastDuration, deltaDuration, nextDeltaDuration, appliedDelta, nextDuration })
  }

  onResizeStart = timespanPiece => {
    debug('resize start', { timespanPiece })
    document.body.style.cursor = 'row-resize'
    this.uiState.setState({
      isResizing: true,
      focusedTimespan: timespanPiece.piece
    })
    this.resizeState.startDuration = timespanPiece.duration
    this.resizeState.lastDuration = timespanPiece.duration
  }

  onResizeEnd = timespanPiece => {
    debug('resize stop', { timespanPiece })
    document.body.style.cursor = ''
    this.uiState.setState({
      isResizing: false,
      focusedTimespan: null
    })
    this.removeZeroDurationTimespans()
    this.props.plannerFlow.roundTimespans()
    this.saveDaysWithTimespanPiece(timespanPiece)
  }

  getDaysAroundTimespanPiece(timespanPiece) {
    const day = timespanPiece.lastTimespan.list.day
    const prevDay = timespanPiece.prevTimespanPiece?.lastTimespan.list.day
    const nextDay = timespanPiece.nextTimespanPiece?.firstTimespan.list.day
    const days = [prevDay, day, nextDay].filter(day => day)
    return days
  }

  saveDaysWithTimespanPiece(timespanPiece) {
    // @todo optimize will require 
    // this.resizeState.startDuration to get resize direction 
    // and figure out days affected
    const days = this.getDaysAroundTimespanPiece(timespanPiece)
    days.map(day => day.save())
  }

  removeZeroDurationTimespans() {
    this.plannerFlow.timespans
        .filter(ts => ts.duration === 0)
        .forEach(ts => {
          this.plannerFlow.removeTimespan(ts)
        })
  }

  getHeightByRef(ref) {
    return parseFloat(ref.style.height.replace('px', ''))
  }

  onClickTimespan = ({ timespan }) => {
    const { actionPlannerStore } = this.props
    actionPlannerStore.setFocusedTimespan(timespan)
  }

  calcWeekPlannerHeight() {
    const { weekPlanner } = this.props
    const config = WeekPlannerConfig[weekPlanner.isUiCollapsed ? 'collapsed' : 'default']
    const { durationStep, durationHeightPerInterval, durationInterval } = config
    const heightPerMin = durationStep * durationHeightPerInterval/durationInterval
    const height = heightPerMin * (60*24)
    return height
  }
  
  render() {
    const { 
      items, itemsDue, actionPlannerStore, plannerUtils, 
      onTaskDurationWillChange, onTaskDurationChange, onTaskDurationUpdate, onTaskPress, 
      onOrderChange, onContextMenu, 
      showHeader, showTimespanTitle, showDueToday, showRealtimeIndicator,
      plannerOpacityPercent
    } = this.props
    const { dayTimespans } = actionPlannerStore
    const { isResizing, focusedTimespan } = this.uiState
    const { isviewingCurrentDay, plannerFlow } = this
    const weekPlannerHeight = this.calcWeekPlannerHeight()
    console.log('render', { plannerFlow, items, dayTimespans, isResizing, focusedTimespan })
    return (
      <div className="action-planner-body">
        {
          showDueToday ? (
            <DueToday items={itemsDue} label={this.props.dueTodayLabel} />
          ) : null
        }
        {
          showHeader ? (
            <ActionPlannerHeader actionPlannerStore={actionPlannerStore} isFloating={false} />
          ) : null
        }
        {
          showRealtimeIndicator && isviewingCurrentDay ? (
            <RealtimeIndicator 
              scrollIntoView={true}
              plannerUtils={plannerUtils}
              actionPlanner={actionPlannerStore}
              dayTimespans={dayTimespans}
            />
          ) : null
        }

        <div className="planner-items-container" style={{ height: weekPlannerHeight }}>

          <WeekCalendarEventsFlow
            plannerFlow={plannerFlow}
            actionPlannerStore={actionPlannerStore}
            plannerUtils={plannerUtils}
            stackOffset={5}
            maxStacks={5}
            maxWidth={(100/7) + '% - 7px'}
            widthOffset={0}
            onTaskOffset={5}
          />

          <ActionPlannerStickyTasks
            actionPlannerStore={actionPlannerStore}
            plannerFlow={plannerFlow}
          />

          {
            plannerFlow.timespanFlowSplitAtDay.map((pieceSplit) => {
              const { group: timespanPiece } = pieceSplit
              const height = plannerUtils.getHeightFromDuration(pieceSplit.duration)
              const timespanStyle = {
                backgroundColor: 'transparent',
                overflow: 'visible',
                height,
                position: 'relative'
              }
              const timespanIsResizing = isResizing && focusedTimespan === timespanPiece
              // compare same point on planner
              const timespanIsFocused = timespanPiece.startDuration === actionPlannerStore.focusedTimespan?.startDuration
              return (
                <Resizable
                  key={pieceSplit.uid}
                  data-id={pieceSplit.uid}
                  data-piece={timespanPiece.uid}
                  data-duration={pieceSplit.duration}
                  data-start-duration={pieceSplit.startDuration}
                  className={
                    "action-planner-items" 
                    + (timespanIsResizing ? ' resizing' : '') 
                    + (timespanIsFocused ? ' focused' : '')
                    + (timespanPiece.isTasksOverflow ? ' overflow' : '')
                  }
                  resizeHandleClassName={'timespan-resize-handle'}
                  // handle creates a dom update on each render. TaskResizeHandle doesn't. Why?
                  //Handle={props => <TimespanResizeHandle {...props} timespan={timespan} />}
                  style={timespanStyle}
                  resize={'vertical'}
                  onResize={({ height }) => this.onResize(pieceSplit, { height })}
                  onResizeStart={({ height }) => this.onResizeStart(pieceSplit)}
                  onResizeEnd={({ height }) => this.onResizeEnd(pieceSplit)}
                  onClick={() => this.onClickTimespan({ timespan: timespanPiece })}
                >
                  {
                    showTimespanTitle ? <TimespanTitle timespan={timespanPiece} /> : null
                  }
                  <CalendarTimespan
                    key={pieceSplit.uid}
                    plannerOpacityPercent={plannerOpacityPercent}
                    plannerFlow={plannerFlow}
                    actionPlannerStore={actionPlannerStore}
                    plannerUtils={plannerUtils}
                    timespan={pieceSplit.timespanProps}
                    timespanPiece={pieceSplit}
                    isResizing={isResizing}
                    focusedTimespan={focusedTimespan}
                    onOrderChange={onOrderChange}
                    onTaskDurationWillChange={onTaskDurationWillChange}
                    onTaskDurationChange={onTaskDurationChange}
                    onTaskDurationUpdate={onTaskDurationUpdate}
                    onTaskPress={onTaskPress}
                    onContextMenu={onContextMenu}
                    showTimespanColor={true}
                  />
                </Resizable>
              )
            })
          }
        </div>

      </div>
    )
  }
}

export default observer(WeekCalendarFlow)
