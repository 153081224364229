import React from 'react'
import { observer } from 'mobx-react'
import UserAvatar from 'theme/Avatar/UserAvatar'
import { fieldIsEnabled } from './utils'

export const AssignedUserField = observer(({ item, fields }) => {
  const { assignedUser } = item
  return (
    fieldIsEnabled(fields, 'assigned_user') ? (
      <div className="attribute attribute-assigned-user">
        {
          assignedUser && assignedUser.id
            ? <UserAvatar user={assignedUser} size={18} />
            : null
        }
        <div className="asiggned-user-name">
          {assignedUser ? assignedUser.name : null}
        </div>
      </div>
    ) : null
  )
})