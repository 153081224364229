import { observable } from "mobx";
import { Item } from "Stores/Lists";

export class MenuItem extends Item {

  @observable name:string

  @observable path:string

  @observable icon:string

  @observable slug:string

  @observable onClick:Function

  @observable Component:Function

  @observable SubMenu:Function

}