import React from 'react'
import { observer } from 'mobx-react'
import './GoalDetailFooter.scss'
import PressableIcon from 'theme/Pressable/PressableIcon';

const debug = require('debug')('treks:goal:footer')

class GoalDetailFooter extends React.Component {

  static defaultProps = {
    size: { 
      width: '100%'
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  render() {
    const { size, pos, item } = this.props
    debug('render', { size, pos, item })
    return (
      <footer
        id="goal-detail-footer"
        className="main-pane-footer"
        style={{
          ...size,
          ...pos
        }}
      >
        <PressableIcon icon="trash" size="xs" style={{marginLeft: -10 }} />
        <PressableIcon icon="print" size="xs" />
        <PressableIcon icon="attach_file" size="xs" />
        <PressableIcon icon="url" size="xs" />
        <PressableIcon icon="settings" size="xs" style={{marginLeft: 'auto' }} />
      </footer>
    )
  }
}

export default observer(GoalDetailFooter)
