import { ModelI } from "Stores/Model/Type/Model"
import ModelApiMiddleware from "./ModelApiMiddleware"

export default class ModelApiMiddlewareStack {

  model = null
  next = null
  middleware = null

  constructor(model:ModelI) {
    if (!model) throw new TypeError('Model is required')
    this.model = model
  }

  use(fn:Function):ModelApiMiddlewareStack {
    if (!fn) throw new TypeError('Function is required')
    const stack = new ModelApiMiddlewareStack(this.model)
    stack.middleware = new ModelApiMiddleware(fn)
    stack.next = (action, json) => this.call(action, json)
    return stack
  }

  async call(action:string, json:any):Promise<any> {
    if (!this.middleware) return json
    json = await this.middleware.call(this.model, action, json, this.next)
    return json
  }

  static factory(middlewares:Array<Function>, model:ModelI):ModelApiMiddlewareStack {
    let stack = new ModelApiMiddlewareStack(model)
    middlewares.forEach(middleware => {
      stack = stack.use(middleware)
    })
    return stack
  }

}