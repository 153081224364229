import React from 'react'
import Resizable from './Resizable'

const debug = require('debug')('treks:module:ResizableWithScroll')

export class ResizableWithScroll extends React.Component {

  static defaultProps = {
    scrollElSel: 'body'
  }

  uiState = {
    scrollPos: { top: 0, left: 0, topStart: 0, leftStart: 0 },
    resizeDims: { height: 0, width: 0 }
  }

  getScrollOffset() {
    const { scrollPos } = this.uiState
    const offset = {
      top: scrollPos.top - scrollPos.topStart,
      left: scrollPos.left - scrollPos.leftStart
    }
    debug('getScrollOffset', offset)
    return offset
  }

  getResizeDimsWithScroll({ height, width }) {
    const scrollOffset = this.getScrollOffset()
    const dims = {
      height: height + scrollOffset.top,
      width: width + scrollOffset.left
    }
    debug('getResizeDimsWithScroll', dims)
    return dims
  }

  getScrollEl() {
    return document.querySelector(this.props.scrollElSel)
  }

  // always called after onResizeStart which sets this.uiState.resizeDims
  onScroll = () => {
    const scrollEl = this.getScrollEl()
    if (!scrollEl) throw 'scrollEl not found'
    Object.assign(this.uiState.scrollPos, {
      top: scrollEl.scrollTop,
      left: scrollEl.scrollLeft
    })
    debug('scrolled', this.uiState)
    const scrollPos = this.getResizeDimsWithScroll(this.uiState.resizeDims)
    this.props.onResize(scrollPos)
  }

  onResizeStart = ({ height, width }) => {
    const scrollEl = this.getScrollEl()
    Object.assign(this.uiState, {
      scrollPos: {
        top: scrollEl.scrollTop,
        left: scrollEl.scrollLeft,
        topStart: scrollEl.scrollTop,
        leftStart: scrollEl.scrollLeft
      },
      resizeDims: {
        height,
        width
      }
    })
    scrollEl.addEventListener('scroll', this.onScroll)
    debug('onResizeStart', this.uiState, { height, width })
    this.props.onResizeStart({
      height,
      width
    })
  }

  onResize = ({ height, width }) => {
    debug('onResize', { height, width })
    Object.assign(this.uiState.resizeDims, {
      height,
      width
    })
    const resizeDims = this.getResizeDimsWithScroll({ height, width })
    return this.props.onResize(resizeDims)
  }

  onResizeEnd = ({ height, width }) => {
    const scrollEl = this.getScrollEl()
    scrollEl.removeEventListener('scroll', this.onScroll)
    const resizeDims = this.getResizeDimsWithScroll({ height, width })
    this.props.onResizeEnd(resizeDims)
  }

  render() {
    const { 
      scrollElSel, children, onResize, onResizeStart, onResizeEnd, 
      ...resizableProps } = this.props

    return (
      <Resizable
        onResize={this.onResize}
        onResizeStart={this.onResizeStart}
        onResizeEnd={this.onResizeEnd}
        {...resizableProps}
      >
        {
          children
        }
      </Resizable>
    )
  }
}