import React from 'react'
import { observer } from 'mobx-react'
import GoalDetail from './GoalDetail'

const debug = require('debug')('treks:component:goal')


class Goal extends React.Component {

  render() {
    const { goal, goalList } = this.props
    debug('render', { goal, goalList })
    return (
      <GoalDetail goalList={goalList} goal={goal} />
    )
  }
}

export default observer(Goal)
