import { override } from 'mobx'
import TaskItem, { TaskList } from 'Stores/Task';
import { stores } from 'Stores';
import { hasMany } from 'Relationships/RelationshipDecorators';
import { TaskLabelList } from './TaskLabelList';

export class LabelGroupTaskList extends TaskList {

  get ModelType(): TaskItem {
    return stores.TaskItem
  }

  @hasMany(() => TaskLabelList, (labelList: TaskLabelList) => labelList.taskList)
  taskLabelList: TaskLabelList

  @override get parent() {
    return this.label
  }

  async createFetchPageRequest(start: number, count: number): Promise<any> {
    console.log('Todo: fetch label group task list')
    return { data: { start, count, items: [] } }
  }

  @override async fetch() {
    console.log('fetch labeltasklist')
    const resp = await  this.fetchState.get('task/label/task/list', { labelId: this.parent.id })
    return this.fromJSON(resp?.data)
  }

}