import React from "react";
import { observer } from "mobx-react";
import { PlanningBarGraph } from "../graph/planning/PlanningBarGraph";
import { ExecutionPieGraph } from "../graph/execution/ExecutionPieGraph";
import { TextTag } from "theme/Tag/TextTag";

export class PlanTab extends React.Component {
  render() {
    const { pfaScoreStore }  = this.props
    return (
      <div className="pfa-plan-tab">
        <div className="pfa-plan-graph">
          <h4 className="graph-header">
            Planning - 
            <TextTag backgroundColor={'#D19490'}>
              {pfaScoreStore.planScore.planScorePercent}
            </TextTag>
          </h4>
          <PlanningBarGraph pfaPlanScoreStore={pfaScoreStore.planScore} />
        </div>
        <div className="pfa-plan-graph">
          <h4 className="graph-header">
            Execution - 
            <TextTag backgroundColor={'#57BB66'}>
              {100 - pfaScoreStore.planScore.thrashScorePercent}
            </TextTag>
          </h4>
          <ExecutionPieGraph pfaPlanScoreStore={pfaScoreStore.planScore} />
        </div>
      </div>
    );
  }
}

export default observer(PlanTab)
