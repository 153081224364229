import { computed, observable, override } from "mobx";
import ActionPlanner from "Stores/ActionPlanner/ActionPlanner";
import { PlannerFlow } from "../PlannerFlow";
import { DatedItemFlow } from "./DatedItemFlow";
import List from "Stores/Lists";
import { PlannerEventsFlow } from "../PlannerEventsFlow";
import { CalendarEvent } from "Stores/Calendar";
import TaskItem from "Stores/Task";

export class DatedItemListFlow extends List {

  @observable actionPlanner: ActionPlanner = null

  @observable plannerFlow: PlannerFlow = null

  @observable eventsFlow: PlannerEventsFlow = null

  get ModelType(): typeof DatedItemFlow {
    return DatedItemFlow
  }

  itemsMap = new Map()

  @override get items(): DatedItemFlow[] {
    const plannerEvents = [
      ...this.actionPlanner.eventList.visibleEvents,
      ...this.actionPlanner.stickyTasks
    ]
    return this.createDatedItems(plannerEvents)
  }

  @override get visibleItems(): DatedItemFlow[] {
    const plannerEvents = [
      ...this.actionPlanner.visibleEvents,
      ...this.actionPlanner.stickyTasks
    ]
    return this.createDatedItems(plannerEvents)
  }

  createDatedItems(plannerEvents: (CalendarEvent|TaskItem)[]): DatedItemFlow[] {
    const { actionPlanner, plannerFlow, eventsFlow } = this
    const flowEvents = plannerEvents.map(event => {

      const uid = 'event-flow-' + event.uid

      let eventFlow = this.itemsMap.get(uid)

      if (!eventFlow) {
        eventFlow = DatedItemFlow.fromProps({
          datedItem: event,
          uid, // ensure new model
          actionPlanner,
          plannerFlow,
          eventsFlow
        }) as DatedItemFlow

        this.itemsMap.set(uid, eventFlow)
      }
      return eventFlow
    })
    return flowEvents
  }

  createItemProps(props?: any) {
    return { ...props, plannerFlow: this.plannerFlow }
  }

  @computed get eventsOrderedByStartDuration(): DatedItemFlow[] {
    const sorted = [ ...this.items ]
    sorted.sort((a, b) => {
      if (a.startDuration > b.startDuration) return 1
      if (a.startDuration < b.startDuration) return -1
      return 0
    })
    return sorted
  }

}