import React from 'react'
import 'theme/style/settings.css'
import './AccountLogin.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'theme/Button'
import Form from 'react-bootstrap/Form'
import { withRouter } from 'react-router-dom'
import AccountLoginFooter from './AccountLoginFooter'
import { Link } from 'react-router-dom'
import { getAppNotifications } from 'AppNotifications'
import { observer } from 'mobx-react'
import { getStore, models } from 'Stores'

const AccountLoginLabel = 'Login to your account'
const AccountLoginButtonText = 'Login to your account'
const emailPlaceholder = 'name@company.com'
const accountLoginInfoMsg = 'Please enter your email and password to login to your Treks account.'
const invalidEmailMsg = 'Please enter a valid email address'
const failedLoginMsg = 'Login failed. Please try again.'

const debug = require('debug')('treks:component:account:login')

export default withRouter(observer(class AccountLoginComponent extends React.Component {

  inputRefs = {
    name: null,
    email: null,
  }
  sesionStore = getStore(models.Session)

  static defaultProps = {
    returnPath: '/planner'
  }

  componentDidMount() {
    window.setTimeout(() => this.inputRefs.email && this.inputRefs.email.focus())
  }

  setRef = ref => {
    if (ref) this.inputRefs[ref.type] = ref
  }

  validateEmail(email) {
    return email && email.toString().match(/.+@.+\..+/);
  }

  onClick = event => {
    event.preventDefault()
    this.loginAccount()
  }

  getInputValue(name) {
    return this.inputRefs[name].value
  }

  loginAccount() {
    const email = this.getInputValue('email')
    const password = this.getInputValue('password')
    if (!this.validateEmail(email)) {
      return getAppNotifications().error(invalidEmailMsg)
    }
    return this.sesionStore.login({ email, password })
      .then(() => this.redirectToComplete())
      .catch(error => {
        getAppNotifications().error(failedLoginMsg)
        throw error
      })
  }

  redirectToComplete() {
    const { returnPath } = this.props
    debug('redirecting', returnPath)
    this.props.history.push(returnPath)
  }

  onPasswordKeyUp = event => {
    if (event.key === 'Enter') {
      this.loginAccount()
    }
  }

  render() {
    const busy = this.sesionStore.isAuthReqPending
    return (
      <Container className="account-login settings">
        <Row>
          <Col>
            <header className="header">
              {AccountLoginLabel}
            </header>
            <p className="info">
              {accountLoginInfoMsg}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formPrimaryEmail">
                <Form.Label>Primary Email</Form.Label>
                <Form.Control
                type="email"
                placeholder={emailPlaceholder}
                ref={this.setRef}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                type="password"
                placeholder={'****'}
                ref={this.setRef}
                onKeyUp={this.onPasswordKeyUp} 
              />
              </Form.Group>
              <Form.Group>
                <Button 
                  id="formLoginBtn"
                  onClick={this.onClick} 
                  variant="primary"
                  disabled={busy}
                >
                  {AccountLoginButtonText}
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Label style={infoLabel}>Forgot password? <Link to="/account/password/reset">Reset password</Link></Form.Label>
                <Form.Label style={infoLabel}>Don't have a Treks Account? <Link to="/account/register">Register Account</Link></Form.Label>
              </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccountLoginFooter />
          </Col>
        </Row>
      </Container>
    )
  }
}))

const infoLabel = {
  marginLeft: 10,
  display: 'block'
}