import { observable, action, override } from 'mobx'
import Model from 'Stores/Model';

const debug = require('debug')('treks:store:calendar')

export default class Calendar extends Model {

  /**
   * @property {string} External Calendar Id
   */
  @observable calendarId = null

  /**
   * @property {string}
   */
  @observable category = null

  /**
   * Show automatically on planner, or manual or not
   */
  @observable actionPlanner: 'automatic' | 'manual' | null = null

  /**
   * Show on the calendar view
   */
  @observable showOnCalendarView = false

  /**
   * @property {string}
   */
  @observable title = null

  /**
   * @property {string}
   */
  @observable description = null

  /**
   * @property {string}
   */
  @observable timezone = null

  /**
   * @property {string} (gmail|ical)
   */
  @observable backgroundColor = null

  /**
   * @property {string} (gmail|ical)
   */
  @observable foregroundColor = null

  /**
   * @property {CalendarAccount} Calendar Account
   */
  @observable calendarAccount = null

  @action setCategory(category) {
    debug('set category', category)
    this.setProps({ category })
  }

  @action setActionPlanner(actionPlanner) {
    debug('set actionPlanner', actionPlanner)
    this.setProps({ actionPlanner })
  }

  @override save() {
    return this.saveState.post('calendar/save?email=' + this.calendarAccount.email, this.toJSON())
  }

  toJSON() {
    const {
      id,
      calendarId,
      calendarAccount,
      category,
      actionPlanner,
      title,
      description,
      timezone,
      backgroundColor,
      foregroundColor
    } = this
    return {
      id,
      calendarId,
      calendarAccountId: calendarAccount.id,
      category,
      actionPlanner,
      title,
      description,
      timezone,
      backgroundColor,
      foregroundColor
    }
  }

}
