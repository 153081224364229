import React from 'react'
import { components } from 'react-select'
import Icon from '../../Icon';

const debug = require('debug')('treks:select:DropdownIndicator')

export default class DropdownIndicator extends React.Component {

  filterOptions(filterFn, options, searchValue) {
    return options.filter(option => {
      const fixedOption = { ...option, data: {} } // @fix for "react-select": "^5.4.0",
      return filterFn(fixedOption, searchValue)
    })
  }

  render() {
    const { props } = this
    const { filterOption, options, inputValue, menuIsOpen, hasAddOption, addOptionIsActive } = props.selectProps
    const filteredOptions = this.filterOptions(filterOption, options, inputValue)
    const emptyFilteredOptions = filteredOptions.length === 1 && filteredOptions[0].__isNew__
    debug('render', { props, filteredOptions })
    return (
      <components.DropdownIndicator {...props}>
      {
        (hasAddOption && addOptionIsActive && menuIsOpen) || (hasAddOption && menuIsOpen && emptyFilteredOptions)
          ? (
            <Icon set="fa" icon="plus" />
          )
          : (
            menuIsOpen
              ? <Icon set="fa" icon="search" />
              : <Icon set="fa" icon="chevron-down" />
          )
      }
      </components.DropdownIndicator>
    );
  }
}