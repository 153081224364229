import Model from "Stores/Model"

export default class AppToasterPosition extends Model {

  static BOTTOM_LEFT = Symbol('bottom left')
  static BOTTOM_RIGHT = Symbol('bottom right')
  static TOP_RIGHT = Symbol('top left')
  static TOP_LEFT = Symbol('top left')
  static BOTTOM = Symbol('bottom')
  static TOP = Symbol('top')

}
      