import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:goal:title')

class GoalTitle extends React.Component {

  inputRef = null

  constructor(props) {
    super(props)
    const { goal } = props
    debug('component created', goal)
  }

  onInputRef = ref => {
    this.inputRef = ref
  }

  onChange = event => {
    debug('title changed', event)
    this.props.goal.setTitle(event.target.value) 
    this.props.goal.save()
  }

  render() {
    const { goal } = this.props
    debug('render', goal)
    return (
      <input 
        ref={this.onInputRef}
        className={"title title-input input-borderless "} 
        value={ goal.title } 
        onChange={this.onChange}
      />
    )
  }
}

export default observer(GoalTitle)
