import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import PopOver from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
import { getAppNotifications } from '../../AppNotifications'
import './TaskDueDate/TaskDueDate.scss'
import './TaskEarmarked/TaskEarmarked.scss'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import DatePicker from 'Modules/DatePicker'

const debug = require('debug')('treks:task:earmarked-date')

export default observer(class TaskEarmarked extends React.Component {

  inputRef = null
  containerRef = null

  static defaultProps = {
    iconClearDate: 'times',
    iconClearDateColor: '#808285',
    inputLabel: 'Set earmarked date'
  }

  state = {
    isInputChanged: false,
    showTooltip: false,
    inputValue: null,
  }

  hideTooltip() {
    this.setState({ showTooltip: false })
  }

  setInputValue(inputValue) {
    this.setState({ inputValue })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset state when new item
    if (nextProps.item !== this.props.item) {
      this.setState({
        isInputChanged: false,
        inputValue: null,
        showTooltip: false
      });
    }
  }

  componentDidUpdate() {
    if (this.state.showTooltip) {
      document.addEventListener('click', this.onClickApp)
    } else {
      document.removeEventListener('click', this.onClickApp)
    }
  }

  onClickApp = event => {
    if (!event.path.includes(this.containerRef) 
      && !event.path.includes(document.querySelector('.bs-popover-bottom'))) {
      debug('path', event.path)
      this.hideTooltip()
    }
  }

  onInputRef = ref => this.inputRef = ref

  onInputChange = event => {
    this.setInputValue(event.target.value)
    this.setState({ isInputChanged: true })
  }

  onInputFocus = () => {
    this.setState({ showTooltip: true })
  }

  onInputBlur = () => {
    if (this.state.isInputChanged) {
      this.setState({ isInputChanged: false })
      this.onChange(this.state.inputValue)
    }
  }

  onChangeDate = earmarkedDate => {
    const { item, list } = this.props
    const time = moment(item.earmarkedDate).isValid() 
      ? list.getTimeInMinutesFromDate(item.earmarkedDate) : 0
    debug('earmarked date changed', earmarkedDate, time, item.earmarkedDate)
    earmarkedDate = new Date(earmarkedDate.setHours(0, time))
    this.onChange(earmarkedDate)
  }

  onChange = earmarkedDate => {
    if (this.state.inputValue && !moment(earmarkedDate).isValid()) {
      return getAppNotifications().warn({
        message: 'Please enter a valid date',
      })
    }
    this.syncDateAndInputValue(earmarkedDate)
    debug('earmarked date or time changed', earmarkedDate)
    this.hideTooltip()
  }

  syncDateAndInputValue(earmarkedDate) {
    const { item } = this.props
    const inputValue = this.formatDate(earmarkedDate)
    item.setProp('earmarkedDate', earmarkedDate)
    item.save()
    this.setInputValue(inputValue)
  }

  // @todo implement in UI
  clearDateAndInputValue() {
    this.props.item.setProp('earmarkedDate', null)
    this.props.item.save()
    this.setInputValue('')
  }

  onClickContainer = event => {
    debug('clicked', event)
    event.stopPropagation()
    event.preventDefault()
  }

  onContainerRef = ref => {
    this.containerRef = ref
  }

  onShowTimePicker = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ showTime : true })
  }

  onHideTimePicker = event => {
    const { earmarkedDate } = this.props.item
    event.preventDefault()
    event.stopPropagation()
    if (this.isDateValid(earmarkedDate)) {
      this.syncDateAndInputValue(this.removeTimeFromDate(earmarkedDate))
    }
    this.setState({ showTime : false })
  }

  removeTimeFromDate(date) {
    return new Date(date.setHours(0, 0, 0, 0))
  }

  isDateValid(date) {
    return moment(date).isValid()
  }

  onClickClearDate = event => {
    event.preventDefault()
    event.stopPropagation()
    this.clearDateAndInputValue()
  }

  formatDate(date) {
    return date && moment(date).isValid() 
      ? moment(date).format('dddd').substring(0, 3) 
      : ''
  }

  render() {
    const { item, iconClearDate, iconClearDateColor, inputLabel } = this.props
    const { earmarkedDate } = item
    const earmarkedDateIsValid = this.isDateValid(earmarkedDate)
    const earmarkedDateFormatted = earmarkedDateIsValid ? this.formatDate(earmarkedDate) : ''
    const value = this.state.inputValue !== null ? this.state.inputValue : earmarkedDateFormatted
    const overDueClassName = item.isEarmarkedOverdue ? ' overdue' : ''
    const hasValueClassName = value ? ' has-value' : ''
    debug('render', { item, earmarkedDate })
    return (
      <div className="task-earmarked-date" onClick={this.onClickContainer} ref={this.onContainerRef}>
        <input
          ref={this.onInputRef}
          className={"earmarked-date-input input-borderless" + hasValueClassName + overDueClassName}
          value={value}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          placeholder={inputLabel}
        />
        {
          earmarkedDate && (
            <Pressable onClick={this.onClickClearDate} className="row-icon task-clear-earmarkedDate-icon">
              <Icon set="fa" name={iconClearDate} color={iconClearDateColor} />
            </Pressable>
          )
        }
        <Overlay target={this.inputRef} show={!!this.state.showTooltip} placement="bottom">
          {props => (
            <PopOver {...props} show="true">
              <DatePicker
                selected={earmarkedDateIsValid ? earmarkedDate : new Date()}
                onChange={this.onChangeDate}
                inline
              />
            </PopOver>
          )}
        </Overlay>
      </div>
    )
  }
})