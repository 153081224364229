import React from 'react'
import { observer } from 'mobx-react'
import ProjectCommentItem from './ProjectCommentItem'
import ProjectCommentReply from './ProjectCommentReply'
import PropTypes from 'prop-types'
import CommentList from 'Stores/Comment/CommentList'
import './ProjectCommentList.scss'
import Followers from 'theme/Followers'
import ProjectCommentNotify from './ProjectCommentNotify'
import users from 'Stores/User/items'
import { UserList } from 'Stores/User'
import ProjectAddReply from './ProjectAddReply'
import { ProjectItem } from 'Stores/Project'

const userList = UserList.fromItems(users)

class ProjectCommentList extends React.Component {

  static propTypes = {
    comments: PropTypes.objectOf(() => CommentList).isRequired,
    project: PropTypes.objectOf(() => ProjectItem).isRequired,
  }

  onAddReply = (comment, reply) => {
    const { account, project } = this.props
    const newComment = comment.replies.addItem({
      text: reply.text,
      user: account,
      commentDate: new Date(),
      replyTo: comment,
      project 
    })
    newComment.save()
  }

  onAddFollower = (comment, user) => {
    comment.followers.addItem({ user, comment })
  }

  render() {
    const { comments } = this.props
    if (!comments.items.length) return null
    console.log('comments', comments)
    return (
      <div className="project-comment-list-container">
        {comments.items.map(comment => (
          <div  key={comment.id}>
            <div className="container comment-list">
              <ProjectCommentItem comment={comment} />
              {comment.replies.items.length ? (
                <div className="comment-reply-list">
                  {
                    comment.replies.items.map(comment => (
                      <ProjectCommentReply key={comment.id} comment={comment} />
                    ))
                  }
                </div>
              ) : null}
            </div>
            <div className="container-add-reply">
              <ProjectAddReply onAddReply={reply => this.onAddReply(comment, reply)} />
            </div>
            <div className="container container-follow">
              <div className="row-flex">
                <Followers
                  followers={comment.followers}
                  users={userList}
                  onAddFollower={user => this.onAddFollower(comment, user)}
                />
                <ProjectCommentNotify comment={comment} />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default observer(ProjectCommentList)