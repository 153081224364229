import { observable, override } from 'mobx'
import { hasOne } from 'Relationships/RelationshipDecorators';
import { Item } from '../Lists';
import GoalItem from './GoalItem';

export default class GoalTemplate extends Item {
  
  @observable title:string

	@hasOne(() => GoalItem)
  goal:GoalItem

  @override async fetch() {
    return this.fetchState.get('goal/template/' + this.id)
      .then(resp => this.fromJSON(resp.data))
  }

}
