import React from 'react';
import { observer } from 'mobx-react';
import moment from 'Modules/moment';
import Select from 'theme/Form/Select';
import Modal from 'react-bootstrap/Modal';
import HabitInstanceEdit from './HabitInstanceEdit';

const debug = require('debug')('treks:habit:budget');

class HabitInstance extends React.Component {
    inputRef = null;

    constructor(props) {
        super(props);
        const { instance } = props;
        debug('component created', instance);
        this.state = {
            showPlan: false,
        };
    }

    onInputRef = (ref) => {
        this.inputRef = ref;
    };

    onTitleChange = (event) => {
        debug('title changed', event);
        this.props.instance.title = event.target.value;
        this.props.instance.save();
    };

    onDurationChange = (event) => {
        debug('duration changed', event);
        this.props.instance.duration = event.target.value;
        this.props.instance.save();
    };

    onTimeChange = (option) => {
        console.log('time change', option.value);
        this.props.instance.time = parseInt(option.value);
        this.props.instance.save();
    };

    openPlan = () => {
        console.log('opening');
        this.setState({
            showPlan: true,
        });
    };

    render() {
        const { instance } = this.props;
        debug('render', instance);
        return (
            <div className="habit-detail-instance">
                <Modal
                    show={this.state.showPlan}
                    size="lg"
                    centered
                    dialogClassName="modal-size-80"
                    onHide={() => {
                        this.setState({ showPlan: false });
                    }}
                >
                    <HabitInstanceEdit instance={instance} />
                </Modal>

                <input
                    ref={this.onInputRef}
                    className={'input-borderless'}
                    value={instance.title}
                    onChange={this.onTitleChange}
                />

                <div style={{ flexGrow: 1, paddingTop: 5 }}>
                    <TimePicker val={parseInt(instance.time)} onChange={this.onTimeChange} />
                </div>
                <div>
                    <input
                        ref={this.onInputRef}
                        style={{ textAlign: 'right', marginRight: 3 }}
                        className={'input-borderless'}
                        value={instance.duration}
                        onChange={this.onDurationChange}
                    />
                    <span>minutes</span>
                </div>
                <div style={{ marginLeft: 15, cursor: 'pointer' }} onClick={this.openPlan}>
                    {' '}
                    {'>'}{' '}
                </div>
            </div>
        );
    }
}

export default observer(HabitInstance)

export function TimePicker({ onChange, val }) {
	const INTERVAL = 5;

	let options = [];

	let timeValue = moment('12:00 AM', 'h:mm A');
	options.push({ label: timeValue.format('h:mm A'), value: parseInt(timeValue.unix()) });
	let i = 0;
	while (i < 24 * (60 / INTERVAL) - 1) {
		timeValue = moment(timeValue, 'h:mm A').add(INTERVAL, 'minutes');
		options.push({ label: timeValue.format('h:mmA'), value: parseInt(timeValue.unix()) });

		i++;
	}

	return (
		<>
			<Select
				options={options}
				value={{ label: moment.unix(val).format('h:mm A'), value: val }}
				onChange={onChange}
			></Select>
		</>
	);
}
