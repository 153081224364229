import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'
import FormControlInput from './FormControlInput'

export default observer(function FormField(props) {
  let { label, name, Component, errors } = props
  if (!Component) Component = FormControlInput
  return (
    <Form.Group controlId={`form-group-${name}`}>
      <Form.Label>{label}</Form.Label>
      <Component
        {...props}
      />
      {
        errors && (
          <Form.Control.Feedback type="invalid">
            {errors}
          </Form.Control.Feedback>
        )
      }
    </Form.Group>
  )
})