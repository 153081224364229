import Icon from '../../../theme/Icon'

export default function MainPaneGroupItemHeader({ pane, headerStyle, icon, onClickClose, }) {
  return (
    <header className="main-pane-header draggable noselect" style={headerStyle}>
      {
        (icon && <Icon name={icon} size={20} />)
      }
      <div className="title">{ pane.name }</div>
      <a href="#/close" className="main-pane-item-close" onMouseDown={onClickClose}>
        <Icon set="fa" name="times" color={'#808284'} />
      </a>
    </header>
  )
}