import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import ProjectFileItem from './ProjectFileItem'

const debug = require('debug')('treks:project:files:list')

class ProjectFileList extends React.Component {

  state = observable({
    showModal: false
  })

  setState(state) {
    Object.assign(this.state, state)
  }

  render() {
    const { fileList } = this.props
    debug('render', { fileList })
    return (
      <div className="project-file-list">
        {
          fileList.items.map(fileItem => {
            return <ProjectFileItem key={fileItem.hash} fileItem={fileItem} />
          })
        }
      </div>
    )
  }
}

export default observer(ProjectFileList)