import { Component } from "react";
import { CalendarEvent } from "Stores/Calendar";
import { observer } from "mobx-react";

type EventTimezoneProps = {
  event: CalendarEvent;
  placeholder?: string;
}

class EventTimezone extends Component<EventTimezoneProps> {

  static defaultProps = {
    placeholder: "Add Location"
  }

  isChanged = false

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { event } = this.props
    const value = e.target.value
    console.log('change', value)
    event.setProps({ location: value })
    this.isChanged = true
  }
  
  onBlur = () => {
    if (this.isChanged) {
      this.props.event.willSave()
    }
  }

  render() {
    const { event, placeholder } = this.props
    return (
      <input
        value={event.location}
        placeholder={placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
        style={{
          border: 0,
          backgroundColor: 'transparent'
        }}
      />
    )
  }
}

export default observer(EventTimezone)