import { observable, action } from 'mobx';
import Model from 'Stores/Model';

/**
 * Main UI Modal
 */
export default class Modal extends Model {

  /**
   * @property {React.Component}
   */
  @observable component = null

  /**
   * @property {bool}
   */
  @observable show = false

  /**
   * @property {string}
   */
  @observable title = null

  /**
   * @property {string}
   */
  @observable icon = null

  @action setModalComponent(component) {
    this.component = component
  }

  /**
   * @deprecated use setProps()
   * @param {any} state 
   */
  setState(state) {
    this.setProps(state)
  }

}
