import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Pressable from '../../theme/Pressable'
import Icon from '../../theme/Icon'
import Model from 'Stores/Model'
import { getStore, models } from 'Stores'

const debug = require('debug')('treks:task:checkbox')

class OnPlannerIcon extends React.Component {

  static propTypes = {
    item: PropTypes.objectOf(() => Model),
  }

  static defaultProps = {
    icon: 'action_planner',
    iconSize: 20
  }

  onClick = event => {
    const { item } = this.props
    debug('toggle check', item)
    event.preventDefault()
    event.stopPropagation()
    const plannerTaskEl = document.querySelector(`.action-planner [data-id="${item.uid}"]`)
    if (plannerTaskEl) {
      plannerTaskEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      getStore(models.ActionPlanner).setFocusedTask(item)
    }
  }

  render() {
    const { item, iconSize, icon, color } = this.props
    debug('render', item)
    const iconColor = color || (!item.onPlanner ? '#dcdddf' : '#808285')
    const onPlannerClassName = item.onPlanner ? ' is-on-planner' : ''
    return (
      <Pressable onClick={this.onClick} className={'row-icon on-planner-icon' + onPlannerClassName}>
        <Icon name={icon} size={iconSize} color={iconColor} />
      </Pressable>
    )
  }
}

export default observer(OnPlannerIcon)