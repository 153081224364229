import React from 'react'
import Avatar from 'theme/Avatar'
import Icon from 'theme/Icon'

const ProfilePhotoFormControl = ({ src, link, info }) => {
  return (
    <div className="form-control-profile-photo">
      {
        src
          ? <Avatar src={src} />
          : <Icon icon="my_account" size={38} />
      }
      <div className="profile-photo-info-container">
        <a className="upload-photo-link" href="#upload-photo">{link}</a>
        <div className="upload-photo-info">{info}</div>
      </div>
    </div>
  )
}

export default ProfilePhotoFormControl