import { getStore } from "Stores";
import Model from "Stores/Model";
import { action, observable } from "mobx";

const debug = require('debug')('treks:MouseClickState')

export class MouseClickState extends Model {

  @observable mouseEvent?: MouseEvent

  @action setClick(event: MouseEvent) {
    this.mouseEvent = event
    debug('setClick', event)
  }

}

export const getMouseClickState = (): MouseClickState => {
  return getStore(MouseClickState as any)
}