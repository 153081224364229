import { override } from 'mobx';
import { ProjectItem } from '.';
import MeetingList from 'Stores/Meeting/MeetingList';
import { hasOne } from 'Relationships/RelationshipDecorators';

export default class ProjectMeetingList extends MeetingList {

	@hasOne(() => ProjectItem)
  project:ProjectItem

  @override async fetch() {
    const projectId = this.project.id
    return this.fetchState.get('project/meeting/list/' + projectId)
      .then(json => this.fromJSON(json.data))
  }

}