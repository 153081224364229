import React from 'react'
import './Pressable.css'

export default class Pressable extends React.Component {

  onClick = event => {
    const { onClick, to } = this.props
    if (!to) {
      event.preventDefault()
    } else {
      window.location.href = to
    }
    onClick && onClick(event)
  }

  onBlur = event => {
    const { onBlur } = this.props
    onBlur && onBlur(event)
  }

  onFocus = event => {
    const { onFocus } = this.props
    onFocus && onFocus(event)
  }

  render() {
    const { children, className, style, title } = this.props
    const { onClick, onFocus, onBlur } = this
    const customProps = (({ to, ...props }) => props)(this.props) // eslint-disable-line no-unused-vars
    return (
      <button
        {...customProps}
        onClick={onClick} 
        onFocus={onFocus} 
        onBlur={onBlur} 
        className={'btn btn-pressable' + (className ? ` ${className}` : '')}
        style={style}
        title={title}
      >
        {children}
      </button>
    )
  }
}