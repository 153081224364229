import React from 'react'
import { observer } from 'mobx-react'
import Select from 'theme/Form/Select'
import ArbitraryModel from 'Stores/Model/ArbitraryModel';
import AddUserDropdown from 'Components/ui/AddUserDropdown';
import PropTypes from 'prop-types'
import { UserList } from 'Stores/User';
import { MemberList, MemberItem } from 'Stores/Member';
import { computed } from 'mobx';

const debug = require('debug')('treks:people:title')

class DetailSelectMember extends React.Component {

  static propTypes = {
    userList: PropTypes.objectOf(() => UserList).isRequired,
    memberList: PropTypes.objectOf(() => MemberList).isRequired,
    member: PropTypes.objectOf(() => MemberItem).isRequired,
  }

  static defaultProps = {
    selectMemberPlaceholder: 'Select Member',
    addMemberPlaceholder: 'Add Member',
  }

  uiStore = ArbitraryModel.fromProps({
    isAddingUser: false,
    name: null,
    email: null
  })

  onAddUser = () => {
    const { name, email } = this.uiStore
    const { userList, onAddUser } = this.props
    const newUser = userList.addItem({ name, email })
    onAddUser && onAddUser(newUser)
    this.uiStore.setState({ name: null, email: null, isAddingUser: false })
    debug('onCreateOption', { newUser })
  }

  onCreateOption = name => {
    this.uiStore.setState({ name, isAddingUser: true })
  }

  onChange = option => {
    const { userList, onChangeUser } = this.props
    const user = option && userList.getItemById(option.value)
    onChangeUser && onChangeUser(user)
    debug('set user', user)
  }

  onHideAddUser = () => {
    this.uiStore.setState({ isAddingUser: false })
  }

  @computed get options() {
    const { userList, memberList } = this.props
    const memberIds = memberList.items.map(member => parseInt(member.user.id, 10))
    const options = userList.items
      .filter(user => !memberIds.includes(parseInt(user.id, 10))) // not already selected
      .map(({ name, id }) => ({ 
        label: name, 
        value: id 
      }))
    return options
  }

  render() {
    const { user, selectMemberPlaceholder, className } = this.props
    const { isAddingUser } = this.uiStore
    const { options } = this
    const value = user 
      ? options.find(({value}) => value === user.id) 
      : null
    debug('render', { user, isAddingUser, options, value })
    return (
      <div className={'member-assign ' + className}>
        <Select 
          isClearable
          hasAddOption
          createOptionPosition={'first'}
          placeholder={selectMemberPlaceholder}
          onChange={this.onChange}
          onCreateOption={this.onCreateOption}
          options={options} 
          value={value}
        />
        {
          isAddingUser && (
            <AddUserDropdown
              uiStore={this.uiStore}
              onHide={this.onHideAddUser}
              onAddUser={this.onAddUser}
            />
          )
        }
      </div>
    )
  }
}

export default observer(DetailSelectMember)