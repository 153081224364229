import { observer } from 'mobx-react'
import Icon from '../../../../theme/Icon'

export default observer(function MainPaneItemTabsHeader({ pane, onClickClose, onClickTabBtn }) {

  return (
    <header className="main-pane-header tabs-header draggable noselect">
      <div className="main-pane-tab-btns">
        {
          pane.tabs.map(tab => {
            const focusedClass = tab === pane.focusedTab ? ' focused-tab-btn' : ''
            return (
              <a 
                className={"main-pane-tab-btn" + focusedClass}
                href={'#' + tab.tabElementId} key={tab.uid}
                onClick={event => onClickTabBtn(event, tab)}
              >
                { tab.icon && <Icon name={tab.icon} size={20} />}
                <span className="title">{ tab.name }</span>
              </a>
            )
          })
        }
      </div>
      <a href="#/close-tab" className="main-pane-item-close" onClick={onClickClose}>
        <Icon set="fa" name="times" color={'#808284'} />
      </a>
    </header>
  )
})