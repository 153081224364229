import React from "react";
import { action, autorun, computed, intercept, observe, when } from "mobx";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { getAppNotifications } from "AppNotifications";
import { TaskDetailPane } from "Stores/MainPaneGroup/data/MainPaneItems";
import ArbitraryModel from "Stores/Model/ArbitraryModel";
import PlannerTaskContextMenu from "./ContextMenu/PlannerTaskContextMenu";
import StickyTimespanTitles from "./StickyTimespanTitles";
import TaskItem from "Stores/Task/TaskItem";
import { getStore, models } from "Stores";
import WeekPlannerTimes from "Components/ActionPlanner/ActionPlannerTimes/WeekPlannerTimes";
import { WeekDayLabels } from "Components/ActionPlanner/DayLabel/WeekDayLabels";
import { WeekPlannerConfig } from "Stores/ActionPlanner/config/WeekPlannerConfig";
import { MultiDayPlannerConfig } from "Stores/ActionPlanner/config/MultiDayPlannerConfig";
import { WeekDueToday } from "Components/ActionPlanner/DueToday/WeekDueToday";
import { IS_DEV } from "env";
import { getPFAScoreStore } from "Containers/PFA/stores/PFAScoreStore";
import { setIdleTimeout } from "lib/utils/IdleTimeout";
import DayPlannerFlow from "Components/ActionPlanner/DayPlanner/DayPlannerFlow";
import WeekPlannerFlow from "Components/ActionPlanner/WeekPlanner/WeekPlannerFlow";
import ActionPlannerUtils, {
  toStartOfDayDate,
} from "Stores/ActionPlanner/ActionPlannerUtils";
import DayPlannerFlowHeader from "Components/ActionPlanner/DayPlanner/Header/DayPlannerFlowHeader";
import WeekPlannerFlowHeader from "Components/ActionPlanner/WeekPlanner/Header/WeekPlannerFlowHeader";
import ActionPlannerLoadingIndicator from "Components/ActionPlanner/ActionPlannerLoadingIndicator";
import {
  getWeekPlanner,
  WeekPlanner,
} from "Stores/ActionPlanner/Planner/WeekPlanner";
import {
  DayPlanner,
  getDayPlanner,
} from "Stores/ActionPlanner/Planner/DayPlanner";
import { getMQTTState } from "uiState/MQTTState";
import { AppUiState, getAppUiState } from "uiState/AppUiState";
import {
  CalendarAccountList,
  CalendarEvent
} from "Stores/Calendar";
import ActionPlanner from "Stores/ActionPlanner";
import { ActionPlannerList } from "Stores/ActionPlanner/ActionPlannerList";
import { Session } from "Stores/Session";
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";
import { getActionPlannerUiState } from "./models/ActionPlannerUiState";
import TaskToEvent from "./TaskToEvent";

const debug = require("debug")("treks:container:planner");

class ActionPlannerContainer extends React.Component<{
  uiState?: ArbitraryModel & { showCalendarModal: boolean };
  snapOnDurationUpdate?: boolean;
  dueTodayLabel?: string;
  eventsFetchErrMsg?: string;
  calendarAccountsFetchErrMsg?: string;
  tasksFetchErrMsg?: string;
  defaultFetchErrMsg?: string;
  view?: string;
}> {
  mainPaneGroupStore = getStore(models.MainPaneGroup) as MainPaneGroup;
  actionPlannerStore = getStore(models.ActionPlanner) as ActionPlanner;
  actionPlannerList = getStore(models.ActionPlannerList) as ActionPlannerList;
  calendarAccountList = getStore(
    models.CalendarAccountList
  ) as CalendarAccountList;
  weekPlanner = getWeekPlanner() as WeekPlanner;
  weekPlannerUtils = this.weekPlanner.utils;
  dayPlanner = getDayPlanner() as DayPlanner;
  dayPlannerUtils = this.dayPlanner.utils;
  dayPlannerFlow = this.dayPlanner.plannerFlow;
  weekPlannerFlow = this.weekPlanner.plannerFlow;
  appUiState = getAppUiState() as AppUiState;

  disposePfaScoreObserver = null;

  get session(): Session {
    return getStore(models.Session) as Session;
  }

  @computed get plannerFlow(): PlannerFlow {
    return this.props.view === "week"
      ? this.weekPlannerFlow
      : this.dayPlannerFlow;
  }

  @computed get plannerUtils(): ActionPlannerUtils {
    return this.dayPlannerUtils;
  }

  disableCalendar = IS_DEV ? !localStorage.enableCal : localStorage.disableCal;
  disableMqtt = IS_DEV ? !localStorage.enableMqtt : localStorage.disableMqtt;

  static defaultProps = {
    snapOnDurationUpdate: true,
    dueTodayLabel: "Due Today",
    eventsFetchErrMsg: "Unable to fetch your events at this time",
    calendarAccountsFetchErrMsg: "Unable to fetch your calendar accounts",
    tasksFetchErrMsg: "Unable to fetch your tasks at this time",
    defaultFetchErrMsg: "An error occurred while loading your planner",
  };

  uiState = getActionPlannerUiState()

  _focusedTaskDisposer = null;

  handleKeyDown = (event) => {
    this.uiState.shiftKey = event.shiftKey;
    if (event.keyCode === 27) {
      // escape key
      this.onHideContextMenu();
    }
  };

  handleKeyUp = (event) => {
    this.uiState.shiftKey = event.shiftKey;
  };

  handleScroll = () => {
    this.onHideContextMenu();
  };

  getNextDayDate(date) {
    return new Date(new Date(date).setHours(24));
  }

  initWeekDays() {
    const startDate = this.actionPlannerList.startOfWeekDate;
    this.actionPlannerList.setItems([]);
    for (let i = 0; i <= 6; i++) {
      const nextDayDate = new Date(new Date(startDate).setHours(24 * i));
      const planner = models.ActionPlanner.fromProps({
        focusedDate: nextDayDate,
      });
      this.actionPlannerList.addItem(planner);
    }
  }

  initDayPlanner() {
    this.actionPlannerStore.setOpts(MultiDayPlannerConfig.default);
    const { match } = this.props;
    if (match && match.params.focusedDate) {
      this.actionPlannerStore.setFocusedDate(
        new Date(parseInt(match.params.focusedDate, 10))
      );
    }
  }

  initWeekPlanner() {
    this.initWeekDays();
    this.actionPlannerStore.setOpts(WeekPlannerConfig.default);
    //this.mockDate()

    this.scrollTo8am();
  }

  scrollTo8am() {
    const el = document.querySelector(".hour-8");
    if (el) {
      el.scrollIntoView();
    } else {
      setTimeout(() => this.scrollTo8am(), 50);
    }
  }

  mockDate() {
    const actionPlanner = this.actionPlannerStore;
    actionPlanner.mockCurrentDate(actionPlanner.dayList.items[2].dayDate);
  }

  mockPlannerTask(actionPlanner, title) {
    const timespan = actionPlanner.dayTimespans.items[2];
    const task = actionPlanner.addEmptyItem({ title });
    debug("mockPlannerTask", { task, timespan });
  }

  async authenticateUser(session) {
    const isAuthenticated = await session.isAuthenticated();
    if (isAuthenticated) {
      this.uiState.setProps({ isAuthenticated: true });
    } else {
      this.props.history.push("/account/login");
    }
  }

  trackPFAScore() {
    const pfaScoreStore = getPFAScoreStore();
    let saveTimer,
      saveWaitMs = 1000;
    return observe(pfaScoreStore, "pfaScore", (change) => {
      clearTimeout(saveTimer);
      saveTimer = setTimeout(() => pfaScoreStore.save(), saveWaitMs);
    });
  }

  componentDidUnMount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
    StickyTimespanTitles.destroy();
    if (this.disposePfaScoreObserver) {
      this.disposePfaScoreObserver();
    }
  }

  @action async componentDidMount() {
    const { session } = this;

    // only one planner flow per browser context
    this.appUiState.plannerFlow = this.plannerFlow;

    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);

    await this.authenticateUser(session);

    when(
      () => session.isLoggedIn,
      () => {
        debug("is authenticated", session.isLoggedIn);
        this.onAuthenticated();
      }
    );

    this.handleFocusedTask();

    // reload planner if inactive for 30mins
    setIdleTimeout(() => {
      window.location.reload();
    }, 30 * 60 * 1000);
  }

  handleFocusedTask() {
    const { mainPaneGroupStore, actionPlannerStore } = this;

    mainPaneGroupStore.on("removeItem", (pane) => {
      if (pane.slug === "task-detail") {
        actionPlannerStore.setFocusedTask(null);
      }
    });

    let focusedTaskChangeDisposer;
    if (this._focusedTaskDisposer) this._focusedTaskDisposer();
    this._focusedTaskDisposer = observe(
      actionPlannerStore,
      "focusedTask",
      (change) => {
        debug("focused task change", change);

        if (focusedTaskChangeDisposer) focusedTaskChangeDisposer();
        if (change.newValue) {
          const focusedTask = change.newValue;
          focusedTaskChangeDisposer = intercept(focusedTask, (change) => {
            debug("focused task not on planner", change);
            // removed task from planner
            if (change.name === "onPlanner" && change.newValue === false) {
              const nextTask = this.getNextTask(focusedTask);
              actionPlannerStore.setFocusedTask(nextTask);
              //mainPaneGroupStore.removeItem(TaskDetailPane)
            }
            // deleted task
            if (change.name === "trashed" && change.newValue === true) {
              actionPlannerStore.setFocusedTask(null);
              //mainPaneGroupStore.removeItem(TaskDetailPane)
            }
            return change;
          });
        }
      }
    );
  }

  getNextTask(task) {
    if (!task) return null;
    const { taskPiecesFlow } = this.plannerFlow;
    const index = taskPiecesFlow.findIndex((p) => p.task.uid === task.uid);
    const nextPiece = index === -1 ? null : taskPiecesFlow[index + 1];
    const nextTask = nextPiece?.task;
    debug("nextTask index", {
      task: task?.title,
      index,
      nextTask: nextTask?.title,
      nextPiece,
    });
    return nextTask;
  }

  async onAuthenticated() {
    const { defaultFetchErrMsg, view } = this.props;
    const startTime = Date.now();

    // initialize week view
    if (view === "week") {
      this.initWeekPlanner();
    } else {
      this.initDayPlanner();
    }

    // fetch tasks
    const tasksFetch =
      view === "week" ? this.fetchWeekTasks() : this.fetchInitialDayTasks();

    const timespansFetch = this.syncTimespans();

    const calendarAccountsFetch = this.fetchCalanderAccounts();
    const eventsFetch =
      view === "week"
        ? this.fetchCalendarWeekEvents()
        : this.fetchCalanderEvents();

    await Promise.all([
      calendarAccountsFetch,
      tasksFetch,
      timespansFetch,
      eventsFetch,
    ]).catch((error) => {
      debug(defaultFetchErrMsg, error);
      console.error(error);
      getAppNotifications().error(defaultFetchErrMsg);
    });

    this.uiState.setProps({ isFetched: true });
    const endTime = Date.now();
    debug("Planner fetched", `${endTime - startTime}ms`);

    if (!this.disableMqtt) {
      debug("mqtt is enabled");
      this.listenForMQTTUpdates();
    } else {
      debug("mqtt is disabled");
    }

    // run after tasks fetched and rendered
    setTimeout(() => {
      this.disposePfaScoreObserver = this.trackPFAScore();
      if (view !== "week") {
        window.setTimeout(() => StickyTimespanTitles.create(), 500);
      }
    }, 500);
  }

  @action async fetchFocusedTask() {
    const { actionPlannerStore } = this;
    return actionPlannerStore.focusedItem.fetch().then(() => {
      const { item } = actionPlannerStore.focusedItem;
      if (item && item.id) {
        this.updateFocusedTask(item);
      }
    });
  }

  async fetchInitialDayTasks() {
    await this.fetchDayTasks();
    this.syncDayBusy = true;
    setTimeout(() => {
      this.syncDayBusy = false;
    }, 1000);
    this.syncDayTasks();
  }

  @action async fetchDayTasks() {
    const { actionPlannerStore } = this;
    return Promise.all([
      actionPlannerStore.fetchOrder(),
      // optimize when custom fetched() takes focusedDate into account
      actionPlannerStore.fetch(),
    ])
      .then(() => this.fetchFocusedTask())
      .catch((error) => {
        debug(this.props.tasksFetchErrMsg, error);
        throw error;
      });
  }

  syncDayBusy = false;
  syncDayDispose = null;

  @action syncDayTasks() {
    debug("sync day tasks");
    if (this.syncDayDispose) {
      this.syncDayDispose();
    }
    this.syncDayDispose = autorun(() => {
      const { focusedDate, startDate, endDate } = this.dayPlannerFlow;
      if (this.syncDayBusy === true) {
        debug("is syncing planner day...");
        return;
      }
      this.syncDayBusy = true;
      debug("sync planner", { focusedDate, startDate, endDate });
      this.fetchDayTasks()
        .catch((e) => console.error("error sync", e))
        .finally(() => {
          this.syncDayBusy = false;
          debug("synced planner done");
        });
    });
  }

  @action async fetchWeekTasks() {
    const { actionPlannerStore } = this;
    return Promise.all([
      actionPlannerStore.fetchOrder(),
      actionPlannerStore.fetchWeek(),
    ]).catch((error) => {
      debug(this.props.tasksFetchErrMsg, error);
      throw error;
    });
  }

  async syncTimespans() {
    // @see (WeekPlannerFlow|DayPlannerFlow).syncTimespanSettings()
  }

  @action async fetchCalanderAccounts() {
    if (this.disableCalendar) return;
    return; // @now remove
    return this.calendarAccountList.fetch().catch((error) => {
      debug(this.props.calendarAccountsFetchErrMsg, error);
      throw error;
    });
  }

  @action async fetchCalanderEvents() {
    if (this.disableCalendar) return;
    return this.actionPlannerStore.fetchEvents().catch((error) => {
      debug(this.props.eventsFetchErrMsg, error);
      throw error;
    });
  }

  @action async fetchCalendarWeekEvents() {
    if (this.disableCalendar) return;
    return this.actionPlannerStore.fetchWeekEvents().catch((error) => {
      debug(this.props.eventsFetchErrMsg, error);
      throw error;
    });
  }

  @action listenForMQTTUpdates() {
    const mqttSyncClient = getMQTTState().getClient();
    mqttSyncClient.on("update", (data) => {
      console.log("mqtt received update", data);
      this.onModelUpdate(data);
    });
    mqttSyncClient.on("create", (data) => {
      console.log("mqtt received create", data);
      this.onModelUpdate(data);
    });
  }

  @action onModelUpdate(data) {
    const { Task, CalendarEvent, TaskListOrder } = data.model;
    if (Task) {
      this.onMqttTaskUpdate(Task);
    }
    if (CalendarEvent) {
      this.onMqttCalendarEventUpdate(CalendarEvent);
    }
    if (TaskListOrder) {
      this.onMqttPlannerOrderUpdate(TaskListOrder);
    }
  }

  @action async onMqttTaskUpdate(task) {
    const { session, actionPlannerStore } = this;
    const { uid, id, updateDeviceUid } = task;
    if (updateDeviceUid === session.deviceUid) {
      debug("mqtt task update from same device", { updateDeviceUid, session });
      return;
    }
    const taskModel = TaskItem.fromProps({ uid, id }) as TaskItem;
    debug("mqtt updating task", { task, taskModel });
    await taskModel.fromJSON(task);
    if (!actionPlannerStore.hasItem(taskModel)) {
      debug("mqtt re-fetching action planner tasks");
      actionPlannerStore.addItem(taskModel);
    }
  }

  @action onMqttCalendarEventUpdate(calendarEvent) {
    const { session, actionPlannerStore } = this;
    const { updateDeviceUid } = calendarEvent;
    if (updateDeviceUid === session.deviceUid) {
      console.log("mqtt calendar event update from same device", {
        updateDeviceUid,
        session,
      });
    } else {
      const event = CalendarEvent.fromJSON(calendarEvent);
      console.log("MQTT Calendar Event", event);
      if (!actionPlannerStore.eventList.hasItem(event)) {
        console.log("adding event to planner", event);
        actionPlannerStore.eventList.addItem(event);
      }
    }
  }

  @action fetchEvents() {
    // @todo re-fetch events since updating is a bit complex
    this.actionPlannerStore
      .fetchEvents()
      .catch((err) => {
        console.error('error fetching events', err)
        getAppNotifications().error({
          message: 'Failed to sync your events'
        })
      });
  }

  @action async onMqttPlannerOrderUpdate(taskListOrder) {
    const { session, actionPlannerStore } = this;
    const { updateDeviceUid } = taskListOrder;
    if (updateDeviceUid === session.deviceUid) {
      debug("mqtt planner order update from same device", {
        updateDeviceUid,
        session,
      });
      return;
    }
    try {
      const order = JSON.parse(taskListOrder.order);
      actionPlannerStore.setOrder(order);
    } catch (error) {
      console.error("Failed to set order from mqtt", error);
    }
  }

  // resize start
  @action onTaskDurationWillChange = ({ task }) => {
    this.actionPlannerStore.setFocusedItem(task);
  };

  // resize
  @action onTaskDurationUpdate = ({ task, duration }) => {
    const { snapOnDurationUpdate } = this.props;
    debug("set duration", {
      task,
      duration,
      uiState: this.uiState.toJSON(),
      snapOnDurationUpdate,
    });
    if (this.uiState.shiftKey && snapOnDurationUpdate) {
      task.setSnappyDuration(duration, 5);
    } else {
      task.setDuration(duration);
    }
    task.willSave();
  };

  // resize end
  @action onTaskDurationChange = ({ task, duration }) => {
    const stickyDuration = this.plannerUtils.getStickyDuration(duration);
    debug("duration change", { task, stickyDuration });
    if (this.uiState.shiftKey) {
      task.setSnappyDuration(duration, 5);
    }
    task.save();
  };

  @action onOrderChange = (order) => {
    debug("onOrderChange", { order });
    // no-op deprecated
  };

  @action updateFocusedTask = (task) => {
    const { mainPaneGroupStore, actionPlannerStore } = this;
    debug("focusing on task", task);
    actionPlannerStore.setFocusedItem(task);
    mainPaneGroupStore.updateItem(TaskDetailPane);
  };

  @action setFocusedTask = (task) => {
    const { mainPaneGroupStore, actionPlannerStore } = this;
    debug("focusing on task", task);
    actionPlannerStore.setFocusedItem(task);
    mainPaneGroupStore.addOrUpdateItem(TaskDetailPane);
  };

  @action onTaskPress = ({ task }) => {
    this.setFocusedTask(task);
  };

  @action onShowContextMenu = (event, task) => {
    if (localStorage.noContextMenu) return; // disable in dev
    event.preventDefault();
    event.stopPropagation();
    this.setFocusedTask(task);
    const { nativeEvent } = event;
    const { clientX, clientY } = nativeEvent;
    const plannerBody = document.querySelector(".action-planner-body");
    plannerBody && plannerBody.addEventListener("scroll", this.handleScroll);
    this.uiState.setProps({
      showContextMenu: true,
      contextMenuPos: { left: clientX, top: clientY },
    });
    debug("showing context menu on task", { task, nativeEvent });
  };

  @action onHideContextMenu = () => {
    const plannerBody = document.querySelector(".action-planner-body");
    plannerBody && plannerBody.removeEventListener("scroll", this.handleScroll);
    this.uiState.setProps({
      showContextMenu: false,
      contextMenuPos: null,
    });
  };

  onRef = (ref) => {
    if (!ref) return;
    ref.parentNode.addEventListener("scroll", () => {
      const floatHeader = document.querySelector(
        ".action-planner-header.float"
      );
      const inlineHeader = document.querySelector(
        ".action-planner-header.inline"
      );
      const floatTop = floatHeader && floatHeader.getBoundingClientRect().top;
      const inlineTop =
        inlineHeader && inlineHeader.getBoundingClientRect().top;
      this.uiState.isScrollingNextDay = inlineTop <= floatTop;
    });
  };

  onHideCalendarModal = () => {
    this.uiState.showCalendarModal = false;
  };

  onShowCalendarModal = (task: TaskItem) => {
    this.uiState.showCalendarModal = true;
    this.uiState.selectedTask = task
  };

  renderDayView() {
    const { actionPlannerStore, plannerUtils } = this;
    const { dueTodayLabel } = this.props;
    const { dayTimespans, itemsDue, focusedItem } = actionPlannerStore;
    const { showContextMenu, contextMenuPos } = this.uiState;
    const { opts } = actionPlannerStore;
    debug("render", { actionPlannerStore, focusedItem, opts });

    return (
      <div className="action-planner day-view" ref={this.onRef}>
        <div className="planner-header-container day-header-container">
          <DayPlannerFlowHeader
            minutesPerRow={opts.minutesPerRow}
            plannerFlow={this.dayPlannerFlow}
            actionPlannerStore={actionPlannerStore}
            plannerUtils={plannerUtils}
            isFloating={true}
            showPFA={true}
          />
        </div>
        <div className="action-planner-body-container">
          <DayPlannerFlow
            plannerFlow={this.dayPlannerFlow}
            showHeader={false}
            showTimeLabels={true}
            showTimespanTitle={true}
            minutesPerRow={opts.minutesPerRow}
            showDueToday={true}
            dueTodayLabel={dueTodayLabel}
            showRealtimeIndicator={true}
            dayTimespans={dayTimespans}
            plannerUtils={plannerUtils}
            actionPlannerStore={actionPlannerStore}
            itemsDue={itemsDue}
            onOrderChange={this.onOrderChange}
            onTaskDurationChange={this.onTaskDurationChange}
            onTaskDurationUpdate={this.onTaskDurationUpdate}
            onTaskPress={this.onTaskPress}
            onContextMenu={this.onShowContextMenu}
          />
          {showContextMenu ? (
            <PlannerTaskContextMenu
              task={actionPlannerStore.focusedItem.item}
              plannerFlow={this.plannerFlow}
              contextMenuPos={contextMenuPos}
              onHideContextMenu={this.onHideContextMenu}
              onShowCalendarModal={this.onShowCalendarModal}
            />
          ) : null}
        </div>
      </div>
    );
  }

  onFocusDate = (date) => {
    this.actionPlannerList.setFocusedDate(date);
  };

  onFocusWeekTimespan = (timespan) => {
    if (!timespan?.startDate)
      throw new TypeError("timespan must have a startDate");
    this.actionPlannerList.setFocusedDate(toStartOfDayDate(timespan.startDate));
  };

  onFocusWeekEvent = (event) => {
    if (!event?.startDate) throw new TypeError("event must have a startDate");
    this.actionPlannerList.setFocusedDate(toStartOfDayDate(event.startDate));
  };

  renderWeekView() {
    const { actionPlannerStore, actionPlannerList } = this;
    const { dueTodayLabel } = this.props;
    const { dayTimespans, itemsDue, focusedItem } = actionPlannerStore;
    const { showContextMenu, contextMenuPos } = this.uiState;
    const { opts } = actionPlannerStore;
    debug("render", { actionPlannerStore, focusedItem });

    return (
      <div className="action-planner week-view" ref={this.onRef}>
        <div className="planner-header-container week-header-container">
          <WeekPlannerFlowHeader
            actionPlannerStore={actionPlannerStore}
            plannerFlow={this.weekPlannerFlow}
          />
          <WeekDayLabels
            actionPlannerList={actionPlannerList}
            onFocusDate={this.onFocusDate}
          />
          <WeekDueToday actionPlannerStore={actionPlannerStore} />
        </div>
        <div className="action-planner-body-container">
          <WeekPlannerTimes
            minutesPerRow={opts.minutesPerRow}
            showTimeLabels={true}
            dayTimespans={dayTimespans}
            plannerUtils={this.weekPlannerUtils}
          />
          <WeekPlannerFlow
            plannerFlow={this.weekPlannerFlow}
            minutesPerRow={opts.minutesPerRow}
            key={actionPlannerStore.uid}
            showHeader={false}
            showTimeLabels={false}
            showDayLabel={true}
            dueTodayLabel={dueTodayLabel}
            showRealtimeIndicator={false}
            plannerUtils={this.weekPlannerUtils}
            actionPlannerStore={actionPlannerStore}
            itemsDue={itemsDue}
            onOrderChange={this.onOrderChange}
            onTaskDurationWillChange={this.onTaskDurationWillChange}
            onTaskDurationChange={this.onTaskDurationChange}
            onTaskDurationUpdate={this.onTaskDurationUpdate}
            onTaskPress={this.onTaskPress}
            onFocusTimespan={this.onFocusWeekTimespan}
            onFocusEvent={this.onFocusWeekEvent}
            onContextMenu={this.onShowContextMenu}
          />
        </div>
        {showContextMenu ? (
          <PlannerTaskContextMenu
            task={actionPlannerStore.focusedItem.item}
            plannerFlow={this.plannerFlow}
            contextMenuPos={contextMenuPos}
            onHideContextMenu={this.onHideContextMenu}
            onShowCalendarModal={this.onShowCalendarModal}
          />
        ) : null}
      </div>
    );
  }

  render() {
    const { view } = this.props;
    const { isAuthenticated, selectedTask, showCalendarModal } = this.uiState;
    debug("render view: ", {
      view,
      showCalendarModal
    });
    if (!isAuthenticated) return null;

    const classSuffix = this.actionPlannerStore.resizingTask
      ? " resizing-task"
      : "";
    return (
      <div className={"planner-container" + classSuffix}>
        {this.actionPlannerStore.fetchState.isBusy && (
          <ActionPlannerLoadingIndicator />
        )}
        {
          view === "week" 
            ? this.renderWeekView() 
            : this.renderDayView()
        }
        {
          showCalendarModal && (
            <TaskToEvent
              actionPlannerStore={this.actionPlannerStore}
              plannerFlow={this.plannerFlow}
              selectedTask={selectedTask}
              onHideCalendarModal={this.onHideCalendarModal}
            />
          )
        }
      </div>
    );
  }
}

export default withRouter(observer(ActionPlannerContainer));
