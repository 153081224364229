export class StorageSerializerJson {

  unserialize(data: string) {
    try {
      return JSON.parse(data)
    } catch(error) {
      return data
    }
  }

  serialize(data: any) {
    try {
      return JSON.stringify(data)
    } catch(error) {
      return data
    }
  }
}