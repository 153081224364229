import React from 'react'
import { observer } from 'mobx-react'
import Form from 'react-bootstrap/Form'

const debug = require('debug')('treks:form:control:checkbox')

class FormControlCheckBox extends React.Component {

  ref = null

  render() {
    let { onChange, autoComplete, id, checked, type, label, placeholder, disabled, onKeyPress, style } = this.props
    debug('render', this)
    return (
      <Form.Check 
        {...{ id, checked, type, label, autoComplete, placeholder, disabled, onKeyPress, style }}
        onChange={event => onChange && onChange(event.target.checked)}
      />
    )
  }
}

export default observer(FormControlCheckBox)