import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { importDashboard } from 'Stores/Dashboard/Dashboard';
import Tooltip from 'react-bootstrap/Tooltip';
import { observable } from 'mobx';

class DashboardTitle extends React.Component {
    static propTypes = {
        item: PropTypes.instanceOf(importDashboard()),
    };
    uiState = observable({
        isBusy: null,
    });
    inputRef = null;

    onInputRef = (ref) => {
        this.inputRef = ref;
    };
    onChange = (event) => {
        const { item } = this.props;
        console.log('title changed', event);
        item.name = event.target.value;
        item.save();
    };
    render() {
        const { item, disabled, busyMsg } = this.props;
        const { isBusy } = this.uiState;
        console.log('render', item);
        const tooltipStyle = {
            left: 'calc( 50% - 100px )',
            bottom: -20,
        };
        return (
            <>
                <input
                    ref={this.onInputRef}
                    className={'input-borderless '}
                    style={{
                        padding: 10,
                        fontSize: 18,
                        fontFamily: 'Myriad Pro',
                        fontWeight: 600,
                    }}
                    value={item.name}
                    onChange={this.onChange}
                    // onKeyDown={this.onKeyDown}
                    // onFocus={this.onFocus}
                    // onBlur={this.onBlur}
                    disabled={disabled || isBusy ? 'disabled' : ''}
                />
                {isBusy ? (
                    <Tooltip placement="bottom" tooltipStyle={tooltipStyle}>
                        {busyMsg}
                    </Tooltip>
                ) : null}
            </>
        );
    }
}

export default observer(DashboardTitle)
