import React from 'react'
import Calendar from './Calendar'
import CalendarSettingsModal from './CalendarSettingsModal'
import { Route } from 'react-router-dom'
import ArbitraryModel from 'Stores/Model/ArbitraryModel'
import { observer } from 'mobx-react'

const modalState = ArbitraryModel.fromProps({
  showSettings: true
})

// @note why /calendar/settings doesnt work 
// but /calendar-week/settings works!!
const CalendarSettings = observer(() => (
  <CalendarSettingsModal
    handleClose={() => {
      console.log('close modal')
      modalState.setState({ showSettings: false })
    }}
    show={modalState.showSettings}
  />
))

const CalendarRoutes = ({ view }) => (
  <>
    <Route path="/calendar" component={() => <Calendar view={view || 'week'} />} />
    <Route path="/calendar-week" component={() => <Calendar view={"week"} />} />   
    <Route path="/calendar-month" component={() => <Calendar view={"month"} />} />   
    <Route path="/calendar/settings" component={CalendarSettings} />
    <Route path="/calendar-week/settings" component={CalendarSettings} />
  </>
)

export default observer(CalendarRoutes)