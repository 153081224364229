import React from 'react'

export const EventAllDay = ({ allDayEvent = false, label = '', onChange }) => {
  const className = allDayEvent ? ' hasValue' : ''
  const labelDefault = 'All day'
  return (
    <span className={'event-isAllDay' +  className} onClick={() => onChange(!allDayEvent)}>
      {label || labelDefault}
    </span>
  )
}
export default EventAllDay