import { FormControlDropdown, InfoTextFormControl } from 'theme/Form'

const fields = [
  {
    name: 'infoText',
    info: 'If you would like to collaborate with others at work, fill out the following:',
    Component: InfoTextFormControl,
    enumerable: false
  },
  {
    name: 'workEmail',
    label: 'Work Email',
    placeholder: 'name@company.com',
    value: null,
  },
  {
    name: 'companySize',
    label: 'Company Size',
    placeholder: 'Select Company Size',
    Component: FormControlDropdown,
    value: null,
    items: [
      {
        label: 'Just me',
        value: '1',
      },
      '2-5',
      '6-15',
      '16-25',
      '26-50',
      '51-249',
      '250+',
    ]
  },
  {
    name: 'companyRole',
    label: 'Your Role',
    placeholder: 'Select Your Role',
    value: null,
    Component: FormControlDropdown,
    items: [
      'CEO/Owner/Founder',
      'Consultant',
      'Creative/Design',
      'Engineering',
      'Finance/Accounting',
      'HR',
      'IT',
      'Legal',
      'Marketing',
      'Operations',
      'Purchasing',
      'Sales',
      'Other',
    ],
  },
  {
    name: 'industry',
    label: 'Industry',
    placeholder: 'Select Your Company’s Industry',
    Component: FormControlDropdown,
    value: null,
    items: [
      'Aerospace',
      'Agriculture/Mining',
      'Banking/Finance',
      'Chemical',
      'Computer',
      'Construction',
      'Defense',
      'Energy',
      'Engineering/Design',
      'Entertainment',
      'Fitness',
      'Food',
      'Health Care',
      'Hospitality',
      'Information Technology',
      'Insurance',
      'Manufacturing',
      'Marketing',
      'Mass Media',
      'Professional and Business Services',
      'Real Estate',
      'Religious/Non-Profit',
      'Repair/Maintenance',
      'Retail/Wholesale',
      'Telecommunications',
      'Transportation',
      'Other',
    ],
  },
]

export default fields