import { getAppNotifications } from "AppNotifications";
import { getUserCalendarService } from "Services/Calendar/UserCalendarService";
import TaskItem from "Stores/Task";
import { createTasktoEventState } from "./models/TaskToEventState";
import Calendar from "Stores/Calendar";
import ActionPlanner from "Stores/ActionPlanner";
import { PlannerFlow } from "Stores/ActionPlanner/PlannerFlow/PlannerFlow";
import { observer } from "mobx-react";
import { CalendarsModal } from "./CalendarsModal/CalendarsModal";
import React from "react";
import { withRouter } from "react-router-dom";

const debug = require("debug")("treks:task2event");

class TaskToEvent extends React.Component<{
  actionPlannerStore: ActionPlanner,
  plannerFlow: PlannerFlow,
  history: any,
  selectedTask: TaskItem,
  onHideCalendarModal: () => void;
}> {

  uiState = createTasktoEventState()
  
  onChooseCalendar = async (calendar: Calendar) => {

    console.log('chose calendar', calendar)

    const { actionPlannerStore, selectedTask, onHideCalendarModal, history } = this.props
    // test
    const task = selectedTask
    const calendarEvent = this.createEventFromTask(task, calendar)
    task.trash()
    console.log('Adding calendar event from task ', { calendarEvent, task })
    
    this.uiState.showCalendarModal = false

    try {
      await getUserCalendarService().getCalendarAccounts()
      debug('Saved calendar event, deleting task')
      task.trash()
      await Promise.all([
        calendarEvent.save(),
        task.save()
      ])
      getAppNotifications().show({
        message: 'Event created from task successfully'
      })
    } catch(err) {
      console.error('Error saving calendar event', err)

      // revert changes
      try {
        actionPlannerStore.eventList.removeItem(calendarEvent)
        task.unTrash()
        await task.save()
      } catch(error) {
        getAppNotifications().error({
          message: 'Unable to revert task changes'
        })
        console.error('Unable to revert changes', error)
      }

      if (err.id === 'google.auth.fail') {
        getAppNotifications().error({
          message: 'Please re-authentiate your calendar'
        })
        // open google auth window
        window.open(err.authUrl)
      } else {
        getAppNotifications().error({
          message: 'Error saving calendar event'
        })
      }
    } finally {
      onHideCalendarModal()
    }
  }

  createEventFromTask = (task: TaskItem, calendar: Calendar) => {
    if (!task) throw new Error('Task must be defined')
    if (!calendar) throw new Error('Calendar must be defined')
    const calendarEvent = this.props.plannerFlow.createEventFromTask(task, calendar)
    debug('Created event from task', {
      task,
      calendarEvent
    })
    return calendarEvent
  }

  render() {
    return (
      <div className="task-to-event">
        <CalendarsModal
          onHideCalendarModal={this.props.onHideCalendarModal}
          showCalendarModal={true}
          onChooseCalendar={this.onChooseCalendar}
        />
      </div>
    )
  }

}

export default withRouter(observer(TaskToEvent))