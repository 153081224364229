import React from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { observable } from 'mobx'

const debug = require('debug')('treks:theme:WithToolTip')

class WithToolTip extends React.Component {

  componentDidMount() {
    this.ref = ReactDOM.findDOMNode(this)
    this.ref.addEventListener('mouseover', this.onFocus)
    this.ref.addEventListener('mouseout', this.onBlur)
  }

  componentWillUnmount() {
    this.ref.removeEventListener('mouseover', this.onFocus)
    this.ref.removeEventListener('mouseout', this.onBlur)
  }

  static defaultProps = {
    placement: 'top',
    title: ''
  }

  ref = null
  show = observable(null)

  onRef = ref => {
    this.ref = ref
  }

  onFocus = () => {
    this.show.set(true)
  }

  onBlur = () => {
    this.show.set(false)
  }

  render() {
    const { placement, title, children } = this.props
    debug('render', this.props, this)
    return (
      <>
        {children}
        <Overlay target={this.ref} show={this.show.get()} placement={placement}>
          {props => <Tooltip {...props} show="true">{title}</Tooltip>}
        </Overlay>
      </>
    )
  }
}

export default observer(WithToolTip)