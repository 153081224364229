import { ModelPropsI } from "Stores/Model/Type/Model"
import { TaskPieceFlow } from "./TaskPieceFlow"

export const getTaskPieceUid = (taskPiece: ModelPropsI<TaskPieceFlow>): string => {
  return taskPiece.task.uid + '-' + taskPiece.pieceIndex
}

export const toTaskPiece = (props: ModelPropsI<TaskPieceFlow>): TaskPieceFlow => {
  // @note creating a model is slow
  return {
    uid: getTaskPieceUid(props),
    ...props
  } as TaskPieceFlow
  // const taskPiece = this.getAttribute(uid, () => TaskPieceFlow.fromProps(props))
  // taskPiece.setProps(props)
  // return taskPiece
}