import React from 'react'

export default class AccountLoginFooter extends React.Component {
  render() {
    return (
      <footer className="footer">
        By logging in you agree to the <a href="#terms">Treks terms of service</a> and <a href="#privacy">privacy policy</a>.
      </footer>
    )
  }
}