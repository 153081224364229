import React from 'react'
import { observer } from 'mobx-react'
import Icon from 'theme/Icon'
import TaskRemoveFromPlannerIcon from '../../TaskDetail/TaskRemoveFromPlannerIcon';
import { getAppNotifications } from '../../../AppNotifications';
import EventDoneCheckbox from './EventDoneCheckbox'

const debug = require('debug')('treks:EventHeader')

class EventHeader extends React.Component {

  saveTimer = null

  static defaultProps = {
    removeEventStartMsg: null,
    removeEventFailMsg: 'Failed to remove event from your action planner',
    removeEventSuccessMsg: 'The Event has been removed from your action planner',
    deleteEventStartMsg: null,
    deleteEventFailMsg: 'Failed to delete event',
    deleteEventSuccessMsg: 'The Event has been deleted',
    saveTimeout: 1000
  }

  onRemoveFromPlanner = () => {
    const { event, removeEventStartMsg, removeEventFailMsg, removeEventSuccessMsg } = this.props
    if (removeEventStartMsg) getAppNotifications().show(removeEventStartMsg)
    event.removeFromPlanner()
      .then(() => getAppNotifications().warn(removeEventSuccessMsg))
      .catch(error => getAppNotifications().error(error.message || removeEventFailMsg))
  }

  onTitleChange = event => {
    debug('onTitleChange', event.target.value)
    this.props.event.title = event.target.value
    clearTimeout(this.saveTimer)
    this.saveTimer = setTimeout(() => this.props.event.save(), this.props.saveTimeout)
  }

  render() {
    const { event, isHovered, isFocused } = this.props
    const isFetched = event.calendarAccountList?.fetchState.isFetched
    debug('render', event, { isHovered, isFocused })
    return (
      <header>
        <Icon name={'drag'} size={10} />
        {
          (isHovered)
            ? <EventDoneCheckbox event={event} />
            : <Icon icon={'calendar'} size={15} />
        }
        <div className={'title' + (event.done ? ' done' : '')}>
          <input
            className="input-borderless"
            name={'event-title'}
            value={event.title}
            onChange={this.onTitleChange}
            onMouseDown={event => event.stopPropagation()}
          />  
        </div>
        {
          isFetched
            ? (
              <TaskRemoveFromPlannerIcon
                item={event}
                onClick={this.onRemoveFromPlanner}
              />
            )
            : null
        }
      </header>
    )
  }
}

export default observer(EventHeader)