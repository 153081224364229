import React from 'react'
import { observer } from 'mobx-react'
import Dropdown from 'react-bootstrap/Dropdown'

const debug = require('debug')('treks:goal:tasks:sort')

class GoalTasksSort extends React.Component {

  setOrder = order => {
    debug("order is", order)
  }

  render() {
    const { item, list } = this.props
    debug('render', { item, list })
    return (
      <div className="tab-sort">
        <span>Sort</span>
        <Dropdown>
          <Dropdown.Toggle size="sm" variant="outline-dark" id="menu-sub-tasks">
            Order
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.setOrder('asc')}>
              Asc
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.setOrder('desc')}>
              Desc
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export default observer(GoalTasksSort)
