import React from 'react'
import Habit from './HabitContainer'
import { Route, Switch } from 'react-router-dom'

const HabitRoutes = (props) => (
  <Switch>
    <Route path="/habit/detail/:habitId" component={Habit} />
		<Route path="/habit/detail/:habitId/template/:templateId" component={Habit} />
    <Route path="/habit/tab/:habitTab" component={Habit} />
    <Route path="/:page?" render={() => <Habit {...props} />} />
  </Switch>
)

export default HabitRoutes