import React from 'react'
import Pressable from 'theme/Pressable'
import { RemoveIcon } from './RemoveIcon'
import './RemoveBtn.scss'

export const RemoveBtn = ({ onPress, isActive, style }) => {
  return (
    <Pressable
      style={style}
      onClick={onPress}
      className={"btn-remove" + (isActive ? ' active' : ' inactive')}
    >
      <RemoveIcon />
    </Pressable>
  )
}