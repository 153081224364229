import { StorageOpts } from "./types/StorageOpts"

/**
 * Stores and retrieves JSON from LocalStorage
 * @note throws on non JSON serializable (cyclic structures)
 */
export default class LocalJSONStorage {

  public serialize:boolean = true
  public namespace:string = 'local'

  private serializer:any = {
    serialize: (value:any) => JSON.stringify(value),
    unserialize: (value:any) => {
      try {
        return JSON.parse(value)
      } catch(error) {
        return undefined
      }
    }
  }

  constructor({ serialize, serializer, namespace }:StorageOpts = {}) {
    if (serialize) this.serialize = serialize
    if (namespace) this.namespace = namespace
    if (serializer) this.serializer = serializer
  }

  public getItem(key:string):any {
    return this._unserialize(localStorage.getItem(this._getKey(key)))
  }

  public setItem(key:string, item:any):void {
    return localStorage.setItem(this._getKey(key), this._serialize(item))
  }

  public clear():void {
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf(this.namespace + ':') === 0) {
        delete localStorage[key]
      }
    })
  }

  private _serialize(value:any) {
    return this.serialize ? this.serializer.serialize(value) : value
  }

  private _unserialize(value:any) {
    return this.serialize ? this.serializer.unserialize(value) : value
  }

  private _getKey(key:string):string {
    return this.namespace + ':' + key
  }

}