/**
 * Handles styling for react-select
 */
export const styles = {
  control: styles => {
    return {
      ...styles,
      borderColor: 'transparent',
      //'box-shadow': 'none',
      minHeight: 25,
      //height: 30,
      marginTop: -5,
      marginLeft: 0,
      ':hover': {
        //'border-width': 1,
        //'background-color': '#f1ecc7'
      },
      cursor: 'pointer',
      alignItems: 'flex-start'
    }
  },
  container: styles => {
    return {
      ...styles,
      flex: 1
    }
  },
  option: (styles) => {
    return {
      ...styles,
      //backgroundColor: state.isFocused ? '#f1ecc7' : 'transparent',
      color: '#000',
      cursor: 'pointer',
    }
  },
  dropdownIndicator: styles => ({
    ...styles,
    padding: '0 8px'
  }),
  clearIndicator: (styles, state) => ({
    ...styles,
    padding: '0 8px',
    display: (state.selectProps.addOptionIsActive || !state.selectProps.menuIsOpen) && 'none'
  })
}

export function composeStyles(...stylesList) {
  return stylesList.reduce((composed, styleFns) => Object.entries(styleFns || {})
    .reduce((next, [ key, fn ]) => ({
      ...next,
      [key]: (...args) => {
        const prevFn = next[key]
        return key in next ? fn(prevFn(...args)) : fn(...args)
      }
    }), composed),
    {})
}

export default styles