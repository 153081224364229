import React from 'react'
import { observer } from 'mobx-react'

const debug = require('debug')('treks:task:detail:completion')

class TaskCompletion extends React.Component {
  render() {
    const { isPercentCompleteOnTime, percentComplete, onPlanner, subTasksList } = this.props.item
    const label = 'complete'
    const onPlannerClass = onPlanner ? 'on-planner' : 'not-on-planner'
    const completeStatusClass = isPercentCompleteOnTime ? 'on-time' : 'not-on-time'
    debug('render', { percentComplete })
    if (subTasksList.duration === 0) return null
    return (
      <div className={'row completion-state ' + onPlannerClass + ' ' + completeStatusClass}>
        <div
          className="completion-percent"
        >
          {percentComplete}%
        </div>
        <div className="completion-label">
          {label}
        </div>
      </div>
    )
  }
}

export default observer(TaskCompletion)